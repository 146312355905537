import useCenterPoint from '@hooks/useCenterPoint';
import useFacetSearchInventory from '@hooks/useFacetSearchInventory';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';
import checkRoles from '@ui/utils/checkRoles';
import { emptyInitResults, initPagedResults } from '@utils/algoliaSearch';
import { PagedResults, UseFilterSearchOptions } from '@utils/algoliaSearch';
import { useEffect, useMemo,useRef, useState } from 'react';

import useRouter from './useRouter';

export default function useProductFilterSearch(userContext: UserContext, appContext: AppContext, options: UseFilterSearchOptions<guesthouse.AlgoliaInventory> = {
  useRouterQuery: true,
  extraFacetFilters: {},
}) {
  const router = useRouter();
  const [inventory, setInventory] = useState<PagedResults<guesthouse.AlgoliaInventory>>(initPagedResults());
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(48);
  const [appliedFilters, setAppliedFilters] = useState(0);
  
  const [categoriesOpen, setCategoriesOpen] = useState(!!router.query.category?.toString());
  
  const endOfContainerElement = useRef<HTMLDivElement>();

  const [coords, radius] = useCenterPoint();

  const { extraFacetFilters } = options;

  const {
    setQuery,
    results,
    filters,
    startDate,
    setStartDate,
  } = useFacetSearchInventory({
    query: router.query,
    initResults: emptyInitResults,
    searchOptions: {
      page: page,
      hitsPerPage: perPage,
      aroundLatLng: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? coords?.join(',') : undefined,
      aroundRadius: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? radius : undefined,
    },
    extraFacetFilters,
    useRouter: options.useRouterQuery,
  });

  const startDateObject = useMemo(() => {
    if (startDate) {
      return new Date(Number(startDate?.toString()) * 1000);
    }
  }, [startDate]);

  const clearFilters = () => {
    Object.keys(filters).map(filter => {
      filters[filter].setter(undefined);
    });
    setStartDate(null);
  };

  useEffect(() => {
    if (!options.useRouterQuery) {
      setQuery(options?.queryString);
    } else {
      setQuery(router.query.q?.toString());
    }
  }, [options.useRouterQuery, router.query?.q, options.queryString]);

  useEffect(() => {
    const page = results.page;
    const end = results.page >= (results.nbPages - 1);

    setInventory(prevState => ({
      ...prevState,
      [page]: {
        results: results.hits,
        status: 'complete',
        end: end,
      }
    }) as PagedResults<guesthouse.AlgoliaInventory>);
  }, [results]);

  useEffect(() => {
    let tempCount = 0;

    Object.keys(filters).map(filter => {
      filters[filter].values ? tempCount += filters[filter].values?.length : null;
    });
    if (startDate) {
      tempCount += 1;
    }
    setAppliedFilters(tempCount);
  }, [filters, startDate]);

  const getPage = () => {
    if (inventory[page] && inventory[page].status === 'loading') {
      return;
    }

    if (inventory[page] && inventory[page].end === true) {
      return;
    }

    const nextPage = page + 1;

    setInventory(prevState => ({
      ...prevState,
      [nextPage]: {
        status: 'loading',
      },
    }));
    setPage(nextPage);
  };

  useInfiniteScroll(endOfContainerElement, getPage, {
    rootMargin: '400px 0px',
    threshold: 0,
  });

  return {
    startDate,
    setStartDate,
    categoriesOpen,
    setCategoriesOpen,
    page,
    setPage,
    perPage,
    setPerPage,
    inventory,
    setInventory,
    filters,
    clearFilters,
    appliedFilters,
    results,
    startDateObject,
    endOfContainerElement,
  };
}
