import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import { formatInventoryType } from '@components/cards/RoomSetCard';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import RoomSetFormDialog from '@components/dialogs/RoomSetFormDialog';
import MoodboardGrid from '@components/moodboards/MoodboardGrid';
import PhotoGrid from '@components/photo-grid/PhotoGrid';
import RoomLink from '@components/room-link/RoomLink';
import RoomSetInventoryTable from '@components/roomsets/RoomSetInventoryTable';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import { ROOM_TYPES } from '@data';
import useCollection from '@hooks/useCollection';
import useDocument from '@hooks/useDocument';
import useRouter from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import IconDelete from '@mui/icons-material/Delete';
import IconEdit from '@mui/icons-material/Edit';
import { Grid, IconButton, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import useCommonStyles from '@styles/common.style';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import LeftChevron from '@ui/icons/imaterial/arrows/Arrow Left.svg';
import theme from '@ui/theme';
import formatDate from '@ui/utils/formatDate';
import getDataFromCollection from '@ui/utils/getDataFromCollection';
import toJsDate from '@ui/utils/toJsDate';
import { roomSetPath } from '@utils/paths';
import sortHomesByDate from '@utils/sortHomesByDate';
import { collection, collectionGroup, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';

import { DefaultFirestoreConverter } from '../../../types/DefaultFirestoreConverter';
import useStyles from './RoomSet.style';
interface RoomSetParams {
  id: string;
}

const RoomSet = () => {
  const router = useRouter<RoomSetParams>();
  const roomSetId = router.params.id;
  const { doc: roomSetDoc } = useDocument(`roomsets/${roomSetId}`);
  const [stagedRooms, setStagedRooms] = useState<guesthouse.Room[]>();
  const roomSet = roomSetDoc?.data() as guesthouse.RoomSet;
  const roomSetType = ROOM_TYPES.find(roomType => roomType.id === roomSet?.roomType);

  const { classes: common } = useCommonStyles();
  const { classes } = useStyles();
  const { md } = useBreakpoints();
  const notificationContext = useContext(NotificationContext);
  const userContext = useContext(UserContext);
  
  const { collection: roomSetInventoryCollection } = useCollection<guesthouse.ProductInventory>(
    collection(database, `roomsets/${roomSetId}/roomset_inventory`).withConverter(new DefaultFirestoreConverter<guesthouse.ProductInventory>())
  );

  useEffect(() => {
    const getStagedRooms = async () => {
      const stagedRoomsDocs = await getDocs(query(collectionGroup(database, 'rooms'), where('docID', 'in', roomSet?.stagedRooms)));

      const stagedRoomsArray = stagedRoomsDocs.docs.map(doc => {
        return doc.data() as guesthouse.Room;
      });

      setStagedRooms(stagedRoomsArray);
    };

    if (roomSet?.stagedRooms?.length > 0) {
      getStagedRooms();
    }

  }, [roomSet?.stagedRooms?.length]);

  const inventory = getDataFromCollection(roomSetInventoryCollection);
  
  return (
    <div className={common.contentSpacing}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          className={classes.gridContainer}
        >
          {!md && (
            <IconButton
              size="small"
              aria-label="go back"
              className={classes.iconButtonBackChevron}
              onClick={() => router.goBack()}
            >
              <LeftChevron className={classes.iconBackChevron} />
            </IconButton>
          )}
          <Typography
            component="h1"
            variant="h3Alt"
            data-test="roomset-page-title"
            display="inline"
            className={classes.title}
          >
            {roomSet?.title}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
        >
          <div className={classes.mobileHorizontalScrollWrapper}>
            <div className={classes.mobileHorizontalScrollContainer}>
              <Tooltip title="Edit">
                <span style={{ marginRight: theme.spacing(1) }}>
                  <IconButton
                    className={classes.editButton}
                    aria-label="edit"
                    onClick={() => {
                      asyncRender(RoomSetFormDialog, {
                        userContext,
                        initialValues: roomSet,
                        tite: 'Edit Room Set',
                        submitText: 'Update Room Set',
                        onSubmit: async (values) => {
                          return await updateDoc(doc(collection(database, 'roomsets'), roomSetId), values)
                            .then(() => {
                              router.replace(roomSetPath(values));
                            });
                        }
                      }).catch((e) => {
                        if (e) {
                          Sentry.captureException(e);
                          notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                        }
                      });
                    }}
                  >
                    <IconEdit />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Permanently delete">
                <span style={{ marginRight: theme.spacing(1) }}>
                  <IconButton
                    aria-label="permanently delete"
                    className={classes.editButton}
                    onClick={() => {
                      asyncRender(ConfirmDialog, {
                        title: 'Delete room set',
                        content: 'Are you sure want to delete this room set? This action cannot be undone.',
                        confirmText: 'Yes, Delete',
                      })
                        .then(() => {
                          router.push('/roomsets');
                        })
                        .then(() => {
                          return deleteDoc(roomSetDoc.ref);
                        })
                        .then(() => {
                          notificationContext.setContext({ open: true, message: 'Room set deleted' });
                        })
                        .catch((e) => {
                          if (e) {
                            Sentry.captureException(e);
                            notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                          }
                        });
                    }}
                  >
                    <IconDelete />
                  </IconButton>
                </span>
              </Tooltip>

            </div>
          </div>
        </Grid>

        <Grid
          container
          spacing={4}
          style={{
            marginBottom: theme.spacing(2),
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            style={{ marginBottom: theme.spacing(3) }}
          >

            <List dense>
              <ListItem>
                <ListItemText
                  primary="Room Type"
                  secondary={roomSetType?.title}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary="Set Number"
                  secondary={roomSet?.setNumber}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary="Assigned Warehouse"
                  secondary={roomSet?.assignedWarehouse.name}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary="Inventory Type"
                  secondary={formatInventoryType(roomSet?.inventoryType)}
                />
              </ListItem>

              {!!stagedRooms?.length &&
                <ListItem>
                  <ListItemText
                    primary="Past Homes"
                    secondary={(
                      <>
                        {stagedRooms?.filter((room) => toJsDate(room.home.deinstall_date) < new Date())
                          .sort((a, b) => sortHomesByDate(a.home, b.home, 'deinstall_date', 'asc'))
                          .map((room, i) => {
                            const home = room.home;
                            
                            if (!home?.docID && !room?.docID) {
                              return null;
                            }

                            return (
                              <div
                                key={home.docID + '__' + i}
                                style={{ display: 'flex', alignItems: 'center' }}
                              >
                                <RoomLink
                                  room={room}
                                  home={home}
                                />
                                {home.deinstall_date && (<Chip
                                  size="small"
                                  style={{ marginLeft: theme.spacing(1) }}
                                  label={toJsDate(home.deinstall_date).getTime() < Date.now() ? `Deinstalled on ${formatDate(home.deinstall_date)}` : `Deinstalling on ${formatDate(home.deinstall_date)}`}
                                />)}
                              </div>
                            );
                          })}
                      </>
                    )}
                  />
                </ListItem>
              }

              {!!stagedRooms?.length &&
              <ListItem>
                <ListItemText
                  primary="Current Homes"
                  secondary={(
                    <>
                      {stagedRooms?.filter((room) => (!room.home.deinstall_date || toJsDate(room.home.deinstall_date) > new Date()))
                        .sort((a, b) => sortHomesByDate(a.home, b.home, 'deinstall_date', 'asc'))
                        .map((room, i) => {
                          const home = room.home;

                          if (!home?.docID && !room?.docID) {
                            return null;
                          }

                          return (
                            <div
                              key={home.docID + '__' + i}
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <RoomLink
                                room={room}
                                home={home}
                              />
                              {home.deinstall_date && (<Chip
                                size="small"
                                style={{ marginLeft: theme.spacing(1) }}
                                label={toJsDate(home.deinstall_date).getTime() < Date.now() ? `Deinstalled on ${formatDate(home.deinstall_date)}` : `Deinstalling on ${formatDate(home.deinstall_date)}`}
                              />)}
                            </div>
                          );
                        })}
                    </>
                  )}
                />
              </ListItem>
              }

            </List>
          </Grid>
        </Grid>
      </Grid>

      {!!inventory?.length &&
        <RoomSetInventoryTable
          inventory={inventory}
          roomSetId={roomSetId}
        />
      }

      <Typography
        gutterBottom
        variant="h5Alt"
        className={classes.sectionHeader}
      >
        Photos
      </Typography>

      <PhotoGrid
        uploadRoles={['admin', 'design_manager']}
        collection={`roomsets/${roomSetId}/photos`}
        roomSet={roomSet}
        allowPrimaryPhoto={true}
        allowPrimaryPhotoDelete={true}
        showProductTags={false}
      />

      <Typography
        gutterBottom
        variant="h5Alt"
        className={classes.sectionHeader}
      >
        Moodboards
      </Typography>
      
      <MoodboardGrid
        roomSet={roomSet}
        queryPath={`/roomsets/${roomSetId}/moodboards`}
      />
    </div>
  );
};

export default React.memo(RoomSet);
