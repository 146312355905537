import { database } from '@app/firebase';
import UserSearch from '@components/user-search/UserSearch';
import UserContext from '@context/UserContext';
import useCenterPoint from '@hooks/useCenterPoint';
import useGeoCollection from '@hooks/useGeoCollection';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import ProductOfferSchema, { initialValues as _initialValues } from '@schema/ProductOfferSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import FormErrors, { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import Protected from '@ui/components/protected/Protected';
import { useStyles } from '@ui/utils/makeStyles';
import { collection, query } from 'firebase/firestore';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useContext, useMemo } from 'react';

import { DefaultFirestoreConverter } from '../../types/DefaultFirestoreConverter';
import TextFieldPrice from '../components/textfield-price/TextFieldPrice';

const ProductOfferForm = ({ onSubmit, initialValues = _initialValues, submitText = 'Continue' }) => {
  const { theme } = useStyles();
  const userContext = useContext<UserContext>(UserContext);

  const [coords, radius] = useCenterPoint(userContext.activeRegion?.centerPoint);

  const { docs: warehouses, loading: warehousesLoading } = useGeoCollection<guesthouse.Warehouse>(
    query(collection(database, 'warehouses').withConverter(new DefaultFirestoreConverter<guesthouse.Warehouse>())),
    {
      center: coords,
      radius,
    }
  );

  const warehouseData = useMemo(() => {
    if (warehouses) {
      return warehouses.map(w => w.data());
    }
    return [];
  }, [warehousesLoading]);

  return (
    <Formik
      validationSchema={ProductOfferSchema}
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={onSubmit}
    >
      {({
        errors,
        values,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => {

        return (
          <form onSubmit={handleSubmit}>
            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              <UserSearch
                autoFocus={true}
                defaultValue={values.soldBy}
                useCompany={true}
                filterRoles={['maker']}
                label="Sold By"
                style={{
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(1),
                }}
                onChange={(_, value) => {
                  setFieldValue('soldBy', value, true);
                  setFieldTouched('soldBy', true);
                }}
              />
            </Protected>
            <Field
              fullWidth
              data-test="price"
              name="price"
              label="Price"
              type="text"
              component={TextFieldPrice}
              margin="normal"
              variant="outlined"
              customInput={TextField}
              defaultValue={values.price}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                )
              }}
              onValueChange={(values) => {
                const { value } = values;

                setFieldValue('price', Number(value));
              }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={values.sale}
                  name="sale"
                  color="primary"
                  onChange={(_, value) => {
                    setFieldValue('sale', value);
                    setFieldTouched('sale');
                  }}
                />
              }
              label="Sale"
            />
            {values.sale ?
              <Field
                fullWidth
                name="salePrice"
                label="Sale Price"
                type="text"
                component={TextFieldPrice}
                margin="normal"
                variant="outlined"
                customInput={TextField}
                defaultValue={values.salePrice}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  )
                }}
                onValueChange={(values) => {
                  const { value } = values;

                  setFieldValue('salePrice', Number(value));
                }}
              /> : null}

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.type && Boolean(errors.type)}
            >
              <InputLabel id="type-label">
                Classification
              </InputLabel>
              <Select
                data-test="classification"
                labelId="classification-label"
                id="classification"
                value={values.classification}
                label="Classification"
                onChange={(e) => {
                  setFieldTouched('classification', true);
                  setFieldValue('classification', e.target.value, true);
                  if (e.target.value === 'internal') {
                    setFieldValue('pickupOnly', true);
                  } else if (e.target.value === 'dibs') {
                    setFieldValue('pickupOnly', true);
                  } else if (e.target.value === 'dropship') {
                    setFieldValue('dropship', true);
                    setFieldValue('type', 'new');
                    setFieldValue('condition', 'excellent');
                  }
                }}
              >
                <MenuItem
                  key="internal"
                  data-test="internal"
                  value="internal"
                >
                  Internal
                </MenuItem>

                <MenuItem
                  key="dropship"
                  data-test="dropship"
                  value="dropship"
                >
                  Dropship
                </MenuItem>

                <MenuItem
                  key="dibs"
                  data-test="dibs"
                  value="dibs"
                >
                  Dibs
                </MenuItem>
              </Select>
            </FormControl>

            {!values.dropship &&
              (
                <>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={touched.type && Boolean(errors.type)}
                  >
                    <InputLabel id="type-label">
                      Type
                    </InputLabel>
                    <Select
                      data-test="type"
                      labelId="type-label"
                      id="type"
                      value={values.type}
                      label="Type"
                      onChange={(e) => {
                        setFieldTouched('type', true);
                        setFieldValue('type', e.target.value, true);
                      }}
                    >
                      <MenuItem
                        key="new"
                        data-test="new"
                        value="new"
                      >
                        New
                      </MenuItem>

                      <MenuItem
                        key="used"
                        data-test="used"
                        value="used"
                      >
                        Used
                      </MenuItem>

                      <MenuItem
                        key="damaged"
                        data-test="damaged"
                        value="damaged"
                      >
                        Damaged
                      </MenuItem>

                      <MenuItem
                        key="refurbished"
                        data-test="refurbished"
                        value="refurbished"
                      >
                        Refurbished
                      </MenuItem>
                    </Select>
                    {touched.type && Boolean(errors.type) && (
                      <FormHelperText error>
                        {errors.type}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={touched.condition && Boolean(errors.condition)}
                  >
                    <InputLabel id="condition-label">
                      Condition
                    </InputLabel>
                    <Select
                      data-test="condition"
                      labelId="condition-label"
                      id="condition"
                      value={values.condition}
                      label="Condition"
                      onChange={(e) => {
                        setFieldTouched('condition', true);
                        setFieldValue('condition', e.target.value, true);
                      }}
                    >
                      <MenuItem
                        key="excellent"
                        data-test="excellent"
                        value="excellent"
                      >
                        Excellent
                      </MenuItem>

                      <MenuItem
                        key="good"
                        data-test="good"
                        value="good"
                      >
                        Good
                      </MenuItem>

                      <MenuItem
                        key="fair"
                        data-test="fair"
                        value="fair"
                      >
                        Fair
                      </MenuItem>

                      <MenuItem
                        key="poor"
                        data-test="poor"
                        value="poor"
                      >
                        Poor
                      </MenuItem>
                    </Select>
                    {touched.condition && Boolean(errors.condition) && (
                      <FormHelperText error>
                        {errors.condition}
                      </FormHelperText>
                    )}
                  </FormControl>
                </>
              )
            }

            <Field
              fullWidth
              multiline
              data-test="notes"
              name="notes"
              label="Notes"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
              rows={3}
            />

            {values.pickupOnly && (
              <Autocomplete
                fullWidth
                id="warehouse"
                defaultValue={initialValues?.pickupLocation}
                options={warehouseData}
                getOptionLabel={(option: guesthouse.Location) => option.name || ''}
                style={{ marginBottom: theme.spacing(2) }}
                renderInput={(params) => {
                  return (
                    <Field
                      {...params}
                      fullWidth
                      name="warehouse"
                      label="Warehouse"
                      type="text"
                      component={TextField}
                      variant="outlined"
                    />
                  );
                }}
                onChange={(e, value) => {
                  setFieldValue('pickupLocation', value, true);
                }}
              />
            )}

            {
              !values.pickupOnly && (
                <Field
                  fullWidth
                  data-test="shippingPricePerProduct"
                  name="shippingPricePerProduct"
                  label="Shipping Price (Per Product)"
                  type="text"
                  component={TextFieldPrice}
                  margin="normal"
                  variant="outlined"
                  customInput={TextField}
                  defaultValue={values.shippingPricePerProduct}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title="This price will be applied once for each item in the cart"
                        >
                          <HelpOutlineIcon style={{ color: 'rgba(0,0,0,0.54)', height: 16, width: 16 }} />
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                  onValueChange={(values) => {
                    const { value } = values;

                    setFieldValue('shippingPricePerProduct', Number(value));
                  }}
                />
              )
            }

            {
              !values.pickupOnly && (
                <Field
                  fullWidth
                  data-test="shippingPricePerOrder"
                  name="shippingPricePerOrder"
                  label="Shipping Price (Per Order)"
                  type="text"
                  component={TextFieldPrice}
                  margin="normal"
                  variant="outlined"
                  customInput={TextField}
                  defaultValue={values.shippingPricePerOrder}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title="This price will be applied one time per order that includes any amount of this product"
                        >
                          <HelpOutlineIcon style={{ color: 'rgba(0,0,0,0.54)', height: 16, width: 16 }} />
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                  onValueChange={(values) => {
                    const { value } = values;

                    setFieldValue('shippingPricePerOrder', Number(value));
                  }}
                />
              )
            }

            <FormErrors
              errors={errors}
              touched={touched}
            />

            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                data-test="product-offer-form-submit"
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length)}
                aria-label={submitText}
                tooltip={makeErrors(errors, touched)?.length && (
                  <TooltipErrors
                    errors={errors}
                    touched={touched}
                  />
                )}
              >
                {submitText}
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik >
  );
};

export default React.memo(ProductOfferForm);
