import parseAddress from '@ui/utils/parseAddress';
import { homePath, roomPath } from '@utils/paths';
import React from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

interface RoomLinkProps {
  home: guesthouse.HomeLite;
  room?: guesthouse.Room;
}

const RoomLink : React.FC<RoomLinkProps> = (props : RoomLinkProps) => {
  const { home, room } = props;
  const { short } = parseAddress(home.address);

  if (home?.docID && room?.docID) {
    return (
      <Link to={roomPath(home, room)}>
        {short}
        {' '}
        (
        {room.title}
        )
      </Link>
    );
  }

  if (home?.docID) {
    return (
      <Link to={homePath(home)}>
        {short}
      </Link>
    );
  }

  return null;
};

export const makeRoomLink = (props: RoomLinkProps) => {
  const { home, room } = props;

  if (!home) {
    return;
  }

  const { short } = parseAddress(home.address);

  if (home?.docID && room?.docID) {
    return {
      title: `${short} (${room.title})`,
      url: generatePath(roomPath(home, room)),
    };
  }

  if (home?.docID) {
    return {
      title: short,
      url: homePath(home)
    };
  }
};

export default RoomLink;
