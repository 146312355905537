import { database, functions } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import EmailDialog from '@components/dialogs/EmailDialog';
import PayoutDialog, { disabledMessagePayOut } from '@components/dialogs/PayoutDialog';
import RefundDialog from '@components/dialogs/RefundDialog';
import ShippingDialog from '@components/dialogs/ShippingDialog';
import { RouterLink } from '@components/link-behavior/LinkBehavior';
import PrimaryImage from '@components/primary-image/PrimaryImage';
import UserWidget from '@components/user-widget/UserWidget';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import useDocument from '@hooks/useDocument';
import useRouter from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useCommonStyles from '@styles/common.style';
import Address from '@ui/components/address/Address';
import PickupInfo from '@ui/components/pickup-info/PickupInfo';
import ProductOffer from '@ui/components/product-offer/ProductOffer';
import { orderProductTotal } from '@ui/utils/offerPrice';
import { MAKER_PAYOUT } from '@ui/utils/splitPayments';
import toJsDate from '@ui/utils/toJsDate';
import userFullName from '@ui/utils/userFullName';
import { productPath } from '@utils/paths';
import properCase from '@utils/properCase';
import trackingUrl from '@utils/trackingUrl';
import format from 'date-fns/format';
import { collection, collectionGroup, doc, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import formatCurrency from 'format-currency';
import round from 'lodash.round';
import React, { useContext, useRef, useState } from 'react';
import Stripe from 'stripe';

import { DefaultFirestoreConverter } from '../../../types/DefaultFirestoreConverter';
import useStyles from './Order.style';

const sendOrderReceipt = httpsCallable(functions, 'http-sendOrderReceipt');
const transferPayment = httpsCallable(functions, 'http-transferPayment');

interface MakerOrderParams {
  id: string;
}


const MakerOrder = () => {
  const { classes: common } = useCommonStyles();
  const { classes, theme } = useStyles();
  const userContext = useContext<UserContext>(UserContext);
  const router = useRouter<MakerOrderParams>();

  const { id } = router.params;

  const { collection: orderProducts, loading } = useCollection(
    query(collectionGroup(database, 'orderProducts')
      ,where('product.owner.docID', '==', userContext.user.uid)
      ,where('orderId', '==', id)
      ,orderBy('created', 'desc'))
  );

  return (
    <div className={common.contentSpacing}>
      <Typography
        component="h1"
        variant="h3Alt"
        style={{ marginBottom: theme.spacing(3) }}
      >
        Order detail
      </Typography>

      <Grid
        container
        spacing={4}
        alignItems="center"
      >
        {
          !loading
            ? orderProducts?.docs?.length
              ?
              orderProducts.docs.map((orderProduct, i) => {
                const orderProductData = orderProduct.data() as guesthouse.OrderProduct;
                const { shippingAddress, tracking } = orderProductData;

                return (
                  <React.Fragment key={i}>
                    <Grid
                      item
                      xs={12}
                      md={2}
                    >
                      <Box
                        display="flex"
                        alignItems="flex-end"
                      >
                        <PrimaryImage
                          item={orderProductData.product}
                          className={classes.productImage}
                          alt={orderProductData.product.title}
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={8}
                    >
                      <Typography variant="h5Alt">
                        <Link
                          component={RouterLink}
                          to={productPath(orderProductData.product)}
                        >
                          {orderProductData.product.title}
                        </Link>
                      </Typography>

                      <TableContainer>
                        <Table aria-label="orders">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ width: '40%' }}>
                                {orderProductData.offer?.pickupOnly ? 'Pick Up Info' : 'Ship To'}
                              </TableCell>

                              <TableCell style={{ width: '15%' }}>
                                Price
                              </TableCell>

                              <TableCell style={{ width: '15%' }}>
                                Quantity
                              </TableCell>

                              <TableCell style={{ width: '15%' }}>
                                Subtotal
                              </TableCell>

                              <TableCell style={{ width: '15%' }}>
                                Status
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            <TableRow key={orderProduct.id}>
                              <TableCell>
                                {
                                  orderProductData.offer?.pickupOnly
                                    ? <PickupInfo offer={orderProductData.offer} />
                                    : <Address address={shippingAddress} />
                                }
                              </TableCell>

                              <TableCell>
                                <ProductOffer offer={orderProductData.offer} />
                              </TableCell>

                              <TableCell>
                                {orderProductData.quantity}
                              </TableCell>

                              <TableCell>
                                <ProductOffer
                                  offer={orderProductData.offer}
                                  quantity={orderProductData.quantity}
                                />
                              </TableCell>

                              <TableCell>
                                {orderProductData.status}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        width="100%"
                      >
                        {
                          orderProductData.status === 'SHIPPED' && orderProductData.tracking?.carrier && orderProductData.tracking?.trackingNumber
                          && (
                            <Button
                              fullWidth
                              href={trackingUrl(orderProductData.tracking.carrier, orderProductData.tracking.trackingNumber)}
                              target="_blank"
                              variant="contained"
                              color="primary"
                              style={{ marginTop: theme.spacing(.5), marginBottom: theme.spacing(.5) }}
                            >
                              Track Package
                            </Button>
                          )
                        }

                        {
                          orderProductData.status === 'PROCESSING'
                          && (
                            <>
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{ marginTop: theme.spacing(.5), marginBottom: theme.spacing(.5) }}
                                onClick={() => {
                                  asyncRender(ShippingDialog, {
                                    initialValues: {
                                      carrier: tracking?.carrier,
                                      trackingNumber: tracking?.trackingNumber,
                                    },
                                    onSubmit: (tracking) => {
                                      const updatedProduct: Partial<guesthouse.OrderProduct> = {
                                        tracking: tracking,
                                        status: 'SHIPPED',
                                      };

                                      return updateDoc(orderProduct.ref, updatedProduct);
                                    },
                                    title: 'Add Tracking Information',
                                    saveText: 'Save',
                                    cancelText: 'Cancel',
                                  });
                                }}
                              >
                                Mark as shipped
                              </Button>

                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{ marginTop: theme.spacing(.5), marginBottom: theme.spacing(.5) }}
                                onClick={() => {
                                  asyncRender(ConfirmDialog, {
                                    title: 'Mark item as delivered?',
                                    confirmText: 'Save',
                                    cancelText: 'Cancel',
                                  })
                                    .then(() => {
                                      const updatedProduct: Partial<guesthouse.OrderProduct> = {
                                        status: 'DELIVERED',
                                      };

                                      return updateDoc(orderProduct.ref, updatedProduct);
                                    })
                                    .catch((e) => {
                                      Sentry.captureException(e);
                                    });
                                }}
                              >
                                Mark as delivered
                              </Button>
                            </>
                          )
                        }
                      </Box>
                    </Grid>
                  </React.Fragment>
                );
              })
              : (<></>) : (<></>)
        }
      </Grid>
    </div>
  );
};

const ORDER_ACTIONS = ['Send Order Receipt'];

interface AdminOrderParams {
  id: string;
}

const AdminOrder = () => {
  const { classes: common } = useCommonStyles();
  const { theme } = useStyles();
  const router = useRouter<AdminOrderParams>();
  const { classes } = useStyles();
  const notificationContext = useContext<NotificationContext>(NotificationContext);

  const [orderActions, setOrderActionsOpen] = useState(false);
  const orderActionsButton = useRef<HTMLButtonElement>();


  const { id } = router.params;

  const orderRef = doc(collection(database, 'orders'), id);

  const { collection: orderProducts, loading } = useCollection<guesthouse.OrderProduct>(
    query(collection(orderRef, 'orderProducts').withConverter(new DefaultFirestoreConverter<guesthouse.OrderProduct>())
      ,orderBy('created', 'desc'))
  );

  const { collection: makerTransfers } = useCollection(
    query<Stripe.Transfer & guesthouse.Auditable>(collection(orderRef, 'transfers').withConverter(new DefaultFirestoreConverter<Stripe.Transfer & guesthouse.Auditable>())
      ,orderBy('metadata.maker_id', 'desc')
      ,orderBy('created', 'desc'))
  );

  const { collection: charges } = useCollection(
    query<guesthouse.Charge>(collection(orderRef, 'charges').withConverter(new DefaultFirestoreConverter<guesthouse.Charge>())
      ,orderBy('created', 'desc'))
  );

  const { doc: order } = useDocument(`orders/${id}`);
  let orderData: guesthouse.Order;

  if (order) {
    orderData = order.data() as guesthouse.Order;
  }

  console.log('orderData', orderData);
  console.log('orderProducts', orderProducts?.docs?.map(op => [op.id, op.data()]));

  return (
    <div className={common.contentSpacing}>
      <Typography
        component="h1"
        variant="h3Alt"
        style={{ marginBottom: theme.spacing(3) }}
      >
        Order detail
      </Typography>

      <Typography
        component="h2"
        variant="h5Alt"
        style={{ marginBottom: theme.spacing(3) }}
      >
        Customer Info
      </Typography>

      {orderData?.user?.docID && (
        <div style={{ marginBottom: theme.spacing(3) }}>
          <UserWidget userId={orderData.user.docID} />
        </div>
      )}

      <Grid
        item
        xs={12}
        md={12}
        style={{ marginBottom: theme.spacing(3) }}
      >
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="flex-end"
          flexDirection="column"
          style={{ height: '100%' }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
          >

            <Button
              ref={orderActionsButton}
              variant="contained"
              color="secondary"
              size="small"
              aria-controls="order-actions"
              aria-haspopup="true"
              style={{ marginLeft: theme.spacing(1) }}
              onClick={() => setOrderActionsOpen(true)}
            >
              Order Actions
            </Button>

            <Menu
              id="order-actions"
              anchorEl={orderActionsButton.current}
              open={orderActions}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={() => setOrderActionsOpen(false)}
            >
              {ORDER_ACTIONS
                .map((action) => {
                  const disabled = false;

                  return (
                    <MenuItem
                      key={action}
                      disabled={disabled}
                      onClick={() => {
                        setOrderActionsOpen(false);

                        asyncRender(EmailDialog, {
                          title: 'Send order receipt',
                          confirmText: 'Yes, Send',
                          initialValues: {
                            email: order.data()?.user?.email,
                          },
                          onSubmit: (email) => sendOrderReceipt({ orderID: order.id, email })
                        })
                          .then(() => notificationContext.setContext({ open: true, message: 'Succesfully sent order receipt' }))
                          .then(() => {
                            notificationContext.setContext({ severity: 'success', message: 'Order receipt sent' });
                          });
                      }}
                    >
                      Send Order Receipt
                    </MenuItem>
                  );
                })}
            </Menu>

          </Box>
        </Box>

      </Grid>

      <Typography
        component="h2"
        variant="h5Alt"
        style={{ marginBottom: theme.spacing(3) }}
      >
        Products
      </Typography>

      <Grid
        container
        spacing={4}
        alignItems="center"
      >

        {
          !loading
            ? orderProducts?.docs?.length
              ?
              orderProducts.docs.map((orderProduct) => {
                const orderProductData = orderProduct.data() as guesthouse.OrderProduct;
                const { shippingAddress, tracking } = orderProductData;
                const { productTotal, shippingTotal } = orderProductTotal(orderProductData);

                return (
                  <React.Fragment key={orderProductData.product.docID}>
                    <Grid
                      item
                      xs={12}
                      md={2}
                    >
                      <Box
                        display="flex"
                        alignItems="flex-end"
                      >
                        <PrimaryImage
                          item={orderProductData.product}
                          className={classes.productImage}
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={8}
                    >
                      <Typography variant="h5Alt">
                        <Link
                          component={RouterLink}
                          to={productPath(orderProductData.product)}
                        >
                          {orderProductData.product.title}
                        </Link>
                      </Typography>

                      <TableContainer>
                        <Table aria-label="orders">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ width: '40%' }}>
                                {orderProductData.offer?.pickupOnly ? 'Pick Up Info' : 'Ship To'}
                              </TableCell>

                              <TableCell style={{ width: '15%' }}>
                                Price
                              </TableCell>

                              <TableCell style={{ width: '15%' }}>
                                Quantity
                              </TableCell>

                              <TableCell style={{ width: '15%' }}>
                                Subtotal
                              </TableCell>

                              <TableCell style={{ width: '15%' }}>
                                Status
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            <TableRow key={orderProduct.id}>
                              <TableCell>
                                {
                                  orderProductData.offer?.pickupOnly
                                    ? <PickupInfo offer={orderProductData.offer} />
                                    : <Address address={shippingAddress} />
                                }
                              </TableCell>

                              <TableCell>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                >
                                  <ProductOffer offer={orderProductData.offer} />
                                </Box>
                              </TableCell>

                              <TableCell>
                                {orderProductData.quantity}
                              </TableCell>

                              <TableCell>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                >
                                  <ProductOffer
                                    offer={orderProductData.offer}
                                    quantity={orderProductData.quantity}
                                  />
                                </Box>
                              </TableCell>

                              <TableCell>
                                {properCase(orderProductData.status)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        width="100%"
                      >
                        {
                          orderProductData.status === 'SHIPPED' && orderProductData.tracking?.carrier && orderProductData.tracking?.trackingNumber
                          && (
                            <Button
                              fullWidth
                              href={trackingUrl(orderProductData.tracking.carrier, orderProductData.tracking.trackingNumber)}
                              target="_blank"
                              variant="contained"
                              color="primary"
                              style={{ marginTop: theme.spacing(.5), marginBottom: theme.spacing(.5) }}
                            >
                              Track Package
                            </Button>
                          )
                        }

                        {
                          orderProductData.status === 'PROCESSING' && orderProductData.offer?.dropship
                          && (
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              style={{ marginTop: theme.spacing(.5), marginBottom: theme.spacing(.5) }}
                              onClick={() => {
                                asyncRender(ConfirmDialog, {
                                  title: 'Mark item as ordered?',
                                  confirmText: 'Save',
                                  cancelText: 'Cancel',
                                })
                                  .then(() => {
                                    const updatedProduct: Partial<guesthouse.OrderProduct> = {
                                      status: 'ORDERED',
                                    };

                                    // @ts-ignore https://github.com/firebase/firebase-js-sdk/issues/5853
                                    return updateDoc(orderProduct.ref, updatedProduct);
                                  })
                                  .catch((e) => {
                                    Sentry.captureException(e);
                                  });
                              }}
                            >
                              Mark as ordered
                            </Button>
                          )
                        }

                        {
                          (orderProductData.status === 'PROCESSING' || orderProductData.status === 'ORDERED')
                          && (
                            <>
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{ marginTop: theme.spacing(.5), marginBottom: theme.spacing(.5) }}
                                onClick={() => {
                                  asyncRender(ShippingDialog, {
                                    initialValues: {
                                      carrier: tracking?.carrier,
                                      trackingNumber: tracking?.trackingNumber,
                                    },
                                    onSubmit: (tracking) => {
                                      const updatedProduct: Partial<guesthouse.OrderProduct> = {
                                        tracking: tracking,
                                        status: 'SHIPPED',
                                      };

                                      // @ts-ignore https://github.com/firebase/firebase-js-sdk/issues/5853
                                      return updateDoc(orderProduct.ref, updatedProduct);
                                    },
                                    title: 'Add Tracking Information',
                                    saveText: 'Save',
                                    cancelText: 'Cancel',
                                  });
                                }}
                              >
                                Mark as shipped
                              </Button>

                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{ marginTop: theme.spacing(.5), marginBottom: theme.spacing(.5) }}
                                onClick={() => {
                                  asyncRender(ConfirmDialog, {
                                    title: 'Mark item as delivered?',
                                    confirmText: 'Save',
                                    cancelText: 'Cancel',
                                  })
                                    .then(() => {
                                      const updatedProduct: Partial<guesthouse.OrderProduct> = {
                                        status: 'DELIVERED',
                                      };

                                      // @ts-ignore https://github.com/firebase/firebase-js-sdk/issues/5853
                                      return updateDoc(orderProduct.ref, updatedProduct);
                                    })
                                    .catch((e) => {
                                      Sentry.captureException(e);
                                    });
                                }}
                              >
                                Mark as delivered
                              </Button>
                            </>
                          )
                        }

                        {
                          orderProductData.product.owner && !orderProductData.offer?.dropship
                          && (
                            <Tooltip title={!orderProductData.product?.owner?.paymentAccountId ? disabledMessagePayOut : ''}>
                              <span style={{ display: 'block', width: '100%' }}>
                                <Button
                                  fullWidth
                                  disabled={!orderProductData.product?.owner?.paymentAccountId}
                                  variant="outlined"
                                  style={{ marginTop: theme.spacing(.5), marginBottom: theme.spacing(.5) }}
                                  onClick={() => asyncRender(PayoutDialog, {
                                    eligibleForPayout: productTotal,
                                    calculationType: 'percent',
                                    calculationAmount: MAKER_PAYOUT,
                                    defaultPaymentDescription: `Maker transfer to ${userFullName(orderProductData.product.owner, true)} for ${orderProductData.product.title}`,
                                    userData: orderProductData.product.owner,
                                  })
                                    .then(({
                                      userPayout,
                                      paymentDescription,
                                      userData,
                                    }) => {
                                      return transferPayment({
                                        amount: (userPayout + shippingTotal) * 100,
                                        orderId: orderData.docID,
                                        orderProductId: orderProduct.id,
                                        paymentAccountId: userData?.paymentAccountId,
                                        paymentDescription: paymentDescription,
                                        transfer_type: 'maker_payout',
                                        metadata: {
                                          type: 'Maker Payout',
                                          order_id: orderData.docID,
                                          order_product_id: orderProduct.id,
                                          product_id: orderProductData.product.docID,
                                          maker_id: userData.docID,
                                        }
                                      });
                                    })
                                    .catch((e) => {
                                      if (e) {
                                        Sentry.captureException(e);
                                      }
                                    })
                                  }
                                >
                                  Pay Maker
                                </Button>
                              </span>
                            </Tooltip>
                          )
                        }

                        {orderProductData.status !== 'CANCELLED' && (
                          <Button
                            fullWidth
                            variant="outlined"
                            style={{ marginTop: theme.spacing(.5), marginBottom: theme.spacing(.5) }}
                            onClick={() => {
                              asyncRender(RefundDialog, {
                                eligibleForRefund: orderData.total,
                                calculationType: 'fixed',
                                calculationAmount: 0,
                              })
                                .then((result) => {
                                  const { userRefund } = result;

                                  const updatedOrderProduct: Partial<guesthouse.OrderProduct> = {
                                    status: 'CANCELLED',
                                    refundAmount: round(userRefund, 2),
                                  };
                                  
                                  // @ts-ignore https://github.com/firebase/firebase-js-sdk/issues/5853
                                  updateDoc(orderProduct.ref, updatedOrderProduct);
                                })
                                .catch((e) => {
                                  if (e) {
                                    Sentry.captureException(e);
                                  }
                                });
                            }}
                          >
                            {orderProductData.status === 'PROCESSING' && 'Cancel & Refund'}

                            {orderProductData.status === 'SHIPPED' && 'Refund Customer'}

                            {orderProductData.status === 'DELIVERED' && 'Refund Customer'}
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </React.Fragment>
                );
              })
              : (<></>) : (<></>)
        }

        {orderData && (
          <>
            <Grid
              item
              xs={12}
              md={4}
            >
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
            >
              <Box
                display="flex"
                justifyContent="flex-end"
                width="100%"
              >
                <TableContainer>
                  <Table aria-label="orders-totals">
                    <TableRow>
                      <TableCell>
                        <Typography>
                          Subtotal
                        </Typography>
                      </TableCell>

                      <TableCell align="right">
                        {formatCurrency(orderData.subtotal, { format: '%s%v', symbol: '$' })}
                      </TableCell>
                    </TableRow>

                    {
                      (orderData.sale > 0) && (
                        <TableRow>
                          <TableCell>
                            <Typography>
                              Sale
                            </Typography>
                          </TableCell>

                          <TableCell align="right">
                            (
                            {formatCurrency(orderData.sale, { format: '%s%v', symbol: '$' })}
                            )
                          </TableCell>
                        </TableRow>
                      )
                    }

                    <TableRow>
                      <TableCell>
                        <Typography>
                          Shipping
                        </Typography>
                      </TableCell>

                      <TableCell align="right">
                        {formatCurrency(orderData.shippingTotal, { format: '%s%v', symbol: '$' })}
                      </TableCell>
                    </TableRow>

                    {!!orderData.couponDiscount && orderData.coupon &&
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Typography>
                            Discounts
                          </Typography>
                          <Typography className={classes.promoDescription}>
                            {
                              orderData.coupon.description
                                ? orderData.coupon.description : (
                                  <span>
                                    {formatCurrency(orderData.couponDiscount, { format: '%s%v', symbol: '$' })}
                                    {' '}
                                    off your order
                                  </span>
                                )
                            }
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          (
                          {formatCurrency(orderData.couponDiscount, { format: '%s%v', symbol: '$' })}
                          )
                        </TableCell>
                      </TableRow>
                    }

                    <TableRow>
                      <TableCell>
                        <Typography>
                          Tax
                        </Typography>
                      </TableCell>

                      <TableCell align="right">
                        {formatCurrency(orderData.taxTotal, { format: '%s%v', symbol: '$' })}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography>
                          Total
                        </Typography>
                      </TableCell>

                      <TableCell align="right">
                        {formatCurrency(orderData.total, { format: '%s%v', symbol: '$' })}
                      </TableCell>
                    </TableRow>

                  </Table>
                </TableContainer>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
            >
            </Grid>
          </>
        )}
      </Grid>

      <Divider style={{ margin: theme.spacing(2, 0), width: '100%' }} />

      <Typography
        component="h2"
        variant="h5Alt"
        display="block"
        style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(1), width: '100%' }}
      >
        Seller Payouts
      </Typography>

      {
        makerTransfers && !!makerTransfers.docs.length
          ? (
            <TableContainer>
              <Table aria-label="order-transfers">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Transfer
                    </TableCell>

                    <TableCell>
                      Date
                    </TableCell>

                    <TableCell>
                      Amount
                    </TableCell>

                    <TableCell>
                      Description
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    makerTransfers.docs.map(transfer => {
                      const transferData = transfer.data();

                      return (
                        <TableRow key={transfer.id}>
                          <TableCell
                            component="th"
                            scope="order"
                          >
                            {transfer.id}
                          </TableCell>

                          <TableCell>
                            {format(toJsDate(transferData.created), 'MM/dd/yyyy')}
                          </TableCell>

                          <TableCell>
                            {formatCurrency(transferData.amount / 100, { format: '%s%v', symbol: '$' })}
                          </TableCell>

                          <TableCell>
                            {transferData.description}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography
              display="block"
              style={{ marginTop: theme.spacing(3) }}
            >
              No payouts have been processed for for this order.
            </Typography>
          )}

      <Divider style={{ margin: theme.spacing(2, 0), width: '100%' }} />

      <Typography
        component="h2"
        variant="h5Alt"
        display="block"
        style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(1), width: '100%' }}
      >
        Customer Charges
      </Typography>

      {charges && !!charges.docs.length
        ? (
          <TableContainer>
            <Table aria-label="order-charges">
              <TableHead>
                <TableRow>
                  <TableCell>
                    Charge
                  </TableCell>

                  <TableCell>
                    Gateway
                  </TableCell>

                  <TableCell>
                    Status
                  </TableCell>

                  <TableCell>
                    Date
                  </TableCell>

                  <TableCell>
                    Amount
                  </TableCell>

                  <TableCell>
                    Refunded
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {
                  charges.docs.map(charge => {
                    const chargeData = charge.data();

                    return (
                      <TableRow key={charge.id}>
                        <TableCell
                          component="th"
                          scope="order"
                        >
                          {charge.id}
                        </TableCell>

                        <TableCell>
                          {properCase(chargeData.gateway)}
                        </TableCell>

                        <TableCell>
                          {properCase(chargeData.status)}
                        </TableCell>

                        <TableCell>
                          {format(toJsDate(chargeData.created), 'MM/dd/yyyy')}
                        </TableCell>

                        <TableCell>
                          {formatCurrency(chargeData.amount / 100, { format: '%s%v', symbol: '$' })}
                        </TableCell>

                        <TableCell>
                          {chargeData.gateway === 'stripe' && formatCurrency((chargeData.amount_refunded || 0) / 100, { format: '%s%v', symbol: '$' })}
                          {chargeData.gateway === 'affirm' && formatCurrency((chargeData.refunded_amount || 0) / 100, { format: '%s%v', symbol: '$' })}
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography
            display="block"
            style={{ marginTop: theme.spacing(3) }}
          >
            No charges to show.
          </Typography>
        )}
    </div>
  );
};

const Order = (props) => {
  const userContext = useContext<UserContext>(UserContext);

  if (userContext?.roles?.admin || userContext?.roles?.design_manager || userContext?.roles?.customer_support) {
    return <AdminOrder {...props} />;
  }

  if (userContext?.roles?.maker) {
    return <MakerOrder {...props} />;
  }

  return null;
};

export default React.memo(Order);
