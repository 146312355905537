import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    border: '1px solid #EBEBEB',
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    height: 198,
    backgroundBlendMode: 'darken',
    borderRadius: 5,
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      height: 312,
    }
  },
  image: {
    objectFit: 'cover',
    overflow: 'hidden',
  }
}));
