import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import HomeOrdersTab from '@pages/home/HomeOrdersTab';
import IconReceipt from '@ui/icons/imaterial/base/List In a Box Round.svg';
import makeStyles from '@ui/utils/makeStyles';
import shortAddress from '@ui/utils/shortAddress';
import React, { useState } from 'react';


const useStyles = makeStyles()({
  dialogPaper: {
    background: '#f7f7f7',
  },
});

const OrdersQuickView: React.FC<{ home: guesthouse.Home }> = (props) => {
  const { home } = props;
  const [open, setOpen] = useState(false);
  const { classes } = useStyles();
  const address = shortAddress(home?.address);

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <>
      <IconButton
        style={{
          padding: 5,
        }}
        aria-label="view orders"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
        }}
      >
        <IconReceipt 
          style={{ 
            width: 15, 
            height: 15 
          }}
        />
      </IconButton>
      <Dialog
        fullWidth
        aria-labelledby="timeline-quick-view"
        open={open}
        maxWidth="md"
        classes={{
          paper: classes.dialogPaper
        }}
        onClose={handleClose}
      >
        <DialogTitle id="timeline-quick-view">
          Orders
          {' '}
          -
          {' '}
          {address}
        </DialogTitle>
        <DialogContent>
          <HomeOrdersTab
            home={home}
            homeID={home.docID}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OrdersQuickView;
