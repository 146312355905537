import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import MoodboardReviewDialog from '@components/dialogs/MoodboardReviewDialog';
import ImageUploader from '@components/image-uploader/ImageUploader';
import NotificationContext from '@context/NotificationContext';
import UploadContext from '@context/UploadContext';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import Sentry from '@integrations/Sentry';
import IconDelete from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Protected from '@ui/components/protected/Protected';
import IconCheckmark from '@ui/icons/imaterial/base/Checkmark.svg';
import formatDate from '@ui/utils/formatDate';
import getDataFromCollection from '@ui/utils/getDataFromCollection';
import { useStyles } from '@ui/utils/makeStyles';
import newId from '@utils/newId';
import { collection, deleteDoc, doc as firestoreDoc, getDocs, query, where, writeBatch } from 'firebase/firestore';
import React, { useContext } from 'react';

import MoodboardPreview from './MoodboardPreview';

type MoodboardGridProps = {
  home?: guesthouse.Home;
  roomSet?: guesthouse.RoomSet;
  queryPath: string;
}

const MoodboardGrid: React.FC<MoodboardGridProps> = (props) => {
  const { home, roomSet, queryPath } = props;
  const { theme } = useStyles();
  const uploadContext = useContext<UploadContext>(UploadContext);
  const userContext = useContext<UserContext>(UserContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const { collection: moodboards } = useCollection(collection(database, queryPath));


  const onDrop = (files: File[]) => {
    files?.forEach((file) => {
      const id = newId();
      const reader = new FileReader();

      reader.addEventListener('load', function () {
        const state: UploadState = {
          preview: reader.result,
          status: 'Pending',
          collection: queryPath,
          file: file,
          file_type: file.type,
        };

        if (home) {
          state['home'] = home;
        }

        if (roomSet) {
          state['roomSet'] = roomSet;
        }

        uploadContext.setContext({
          key: id,
          state
        });
      }, false);

      if (file) {
        reader.readAsDataURL(file);
      }
    });
  };

  let moodboardClickHandler: () => void;

  return (
    <>
      {moodboards?.docs?.length > 0 && moodboards.docs.map(doc => {
        const moodboardData = doc.data() as guesthouse.Moodboard;

        if (home?.client?.docID === userContext?.user?.uid && !moodboardData.approved) {
          moodboardClickHandler = () => {
            asyncRender(MoodboardReviewDialog, {
              home,
              moodboard: moodboardData,
              userContext,
            })
              .then(async () => {
                const batch = writeBatch(database);
                const timelineItemDocs = await getDocs(query(collection(database, `homes/${home.docID}/timeline`), where('name', '==', 'review_moodboards')));

                if (timelineItemDocs.docs.length) {
                  const timelineItems = getDataFromCollection(timelineItemDocs) as guesthouse.TimelineItem[];

                  for (const timelineItem of timelineItems) {

                    if (!timelineItem.completed_date) {
                      batch.update(firestoreDoc(
                        collection(firestoreDoc(collection(database, 'homes'), home.docID), 'timeline'),
                        timelineItem.docID
                      ), {
                        owner: userContext.data,
                        completed_date: new Date(),
                        completed_by: userContext.user.uid,
                      });
                    }
                  }
                }

                const updatedMoodboard: Partial<guesthouse.Moodboard> = {
                  approved: true,
                  approved_by: userContext.user.uid,
                };

                batch.update(
                  firestoreDoc(
                    collection(firestoreDoc(collection(database, 'homes'),home.docID),'moodboards'),
                    moodboardData.docID
                  ),
                  updatedMoodboard
                );

                return batch.commit();
              })
              .catch((e) => {
                if (e) {
                  Sentry.captureException(e);
                }
              });
          };
        }

        return (
          <Box
            key={doc.id}
            style={{ margin: theme.spacing(0, 0, 8) }}
          >
            <Box
              display="flex"
              alignItems="center"
            >
              <Typography
                component="h2"
                variant="h5Alt"
                style={{ marginRight: theme.spacing(2) }}
              >
                {moodboardData.filename}
              </Typography>
              {moodboardData.approved && (
                <Chip
                  size="small"
                  style={{
                    background: '#9fb9bc',
                  }}
                  icon={<IconCheckmark />}
                  label="Approved"
                  color="primary"
                />
              )}
            </Box>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {formatDate(moodboardData.created)}
            </Typography>
            <MoodboardPreview
              moodboard={moodboardData}
              onPageClick={moodboardClickHandler}
            />
            <Protected allowedRoles={['admin', 'design_manager', 'designer']}>
              <Box
                style={{ margin: theme.spacing(1) }}
                display="flex"
              >

                <Tooltip title="Delete mood board">
                  <span style={{ marginLeft: 'auto', }}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        asyncRender(ConfirmDialog, {
                          title: 'Are you sure you want to delete this mood board?',
                          confirmText: 'Yes, Delete',
                        })
                          .then(() => deleteDoc(doc.ref))
                          .then(() => notificationContext.setContext({ open: true, message: 'Mood board deleted' }))
                          .catch((e) => {
                            if (e) {
                              Sentry.captureException(e);
                              notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                            }
                          });

                      }}
                    >
                      <IconDelete fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Protected>
          </Box>
        );
      })}
      <Protected
        allowedRoles={['admin', 'design_manager', 'designer', 'website_manager', 'customer_support']}
        renderUnauthorized={() => {
          if (!moodboards?.docs?.length) {
            return (
              <Typography>
                There&apos;s nothing here yet.
              </Typography>
            );
          }
        }}
      >
        <ImageUploader
          multiple
          buttonText="Add Mood Boards"
          accept=".pdf"
          style={{ height: 100, minHeight: 0, borderRadius: theme.shape.borderRadius, marginBottom: theme.spacing(2) }}
          onDrop={onDrop}
        />
      </Protected>
    </>
  );
};

export default MoodboardGrid;
