import asyncRender from '@components/async/asyncRender';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import PrimaryImage from '@components/primary-image/PrimaryImage';
import NotificationContext from '@context/NotificationContext';
import Sentry from '@integrations/Sentry';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import IconDelete from '@ui/icons/imaterial/base/Delete Forever.svg';
import theme from '@ui/theme';
import simpleLocation from '@ui/utils/simpleLocation';
import { productPath } from '@utils/paths';
import properCase from '@utils/properCase';
import { database } from 'app/firebase';
import { collection, deleteDoc, deleteField,doc, updateDoc } from 'firebase/firestore';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

type RoomSetInventoryTableProps = {
  inventory: guesthouse.ProductInventory[];
  roomSetId: string;
}

const RoomSetInventoryTable = (props: RoomSetInventoryTableProps) => {
  const { inventory, roomSetId } = props;
  const notificationContext = useContext(NotificationContext);

  const onDeleteHandler = async (inventory: guesthouse.ProductInventory) => {
    try {
      await deleteDoc(doc(collection(database, `roomsets/${roomSetId}/roomset_inventory`), inventory.docID));
  
      await updateDoc(doc(collection(database, `products/${inventory.product.docID}/inventory`), inventory.docID), {
        roomSetId: deleteField()
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <>
      <Typography
        variant="h5Alt"
        style={{ marginBottom: theme.spacing(3) }}
      >
        Inventory
      </Typography>

      <TableContainer>
        <Table
          aria-label="inventory table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>
              </TableCell>
              <TableCell>
                Product Title
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Warehouse
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventory.sort((a, b) => a.product?.title.localeCompare(b.product?.title)).map(inventory => {
              return (
                <TableRow
                  key={inventory.docID}
                >
                  <TableCell>
                    <PrimaryImage
                      item={inventory.product}
                      style={{
                        height: 40,
                        width: 40
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Link
                      to={productPath(inventory.product)}
                    >
                      {inventory.product.title}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {properCase(inventory.status, '_')}
                  </TableCell>
                  <TableCell>
                    {inventory?.offer?.dropship ? 'Dropship' : simpleLocation(inventory.warehouse)}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        asyncRender(ConfirmDialog, {
                          title: 'Are you sure you want to remove this inventory?',
                          confirmText: 'Yes, Delete',
                        })
                          .then(async () => await onDeleteHandler(inventory))
                          .then(() => notificationContext.setContext({ open: true, message: 'Inventory removed' }))
                          .catch((e) => {
                            if (e) {
                              Sentry.captureException(e);
                              notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                            }
                          });
          
                      }}
                    >
                      <IconDelete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RoomSetInventoryTable;
