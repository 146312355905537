import Sentry from '@integrations/Sentry';
import { Channel } from 'stream-chat';

export default async function removeStreamUserFromChannel(user: guesthouse.User, channel: Channel) {
  try {
    return await channel.removeMembers([user.docID], { text: `${user.firstname + ' ' + user.lastname} has been removed from the chat.` });
  } catch (e) {
    Sentry.captureException(e);
  }
}
