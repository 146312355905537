import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  profileMenu: {
    '@media print': {
      display: 'none',
    }
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
