import LocationSearch from '@components/location-search/LocationSearch';
import HomeStatus from '@enum/HomeStatus';
import HomeType from '@enum/HomeType';
import Sentry from '@integrations/Sentry';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import HomeSchema, { initialValues as _initialValues } from '@schema/HomeSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import parseAddress from '@ui/utils/parseAddress';
import { isError } from '@ui/utils/typescriptHelpers';
import debouncePromise from '@utils/debouncePromise';
import { checkSlug } from '@utils/scannerNextChar';
import { Field, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import React from 'react';
import slugify from 'slugify';
import { $enum } from 'ts-enum-util';

import TextFieldPrice from '../components/textfield-price/TextFieldPrice';
const debounceCheckSlug = debouncePromise(checkSlug, 300);

const HomeForm = ({ onSubmit, initialValues = _initialValues, error, submitText='Continue', location=true }) => {
  return (
    <Formik
      validationSchema={HomeSchema}
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched
      }) => {
        const updateSlug = async (address, unit) => {
          const { short } = parseAddress(address);
          
          const title = `${short}${unit ? `, Unit ${unit}` : ''}`;
          const slug = slugify(title, {
            strict: true,
            lower: true,
          });

          let existingSlugs = [];

          try {
            existingSlugs = await debounceCheckSlug(slug, 'homes');
          } catch (e) {
            Sentry.captureException(e);
            return;
          }
          
          
          if (existingSlugs.length) {
            let n = 1;
            let incrememtedSlug = slug;

            while (existingSlugs.indexOf(incrememtedSlug) > -1) {
              incrememtedSlug = `${slug}-${n}`;
              n += 1;
            }

            setFieldValue('slug', incrememtedSlug);
          } else {
            setFieldValue('slug', slug);
          }
        };

        return (
          <form onSubmit={handleSubmit}>
            {location && (
              <LocationSearch
                disabled={isSubmitting}
                label="Address"
                defaultValue={initialValues.address}
                onLocationChange={(location) => {
                  setFieldValue('address', location.address, true);
                  setFieldValue('place_id', location.place_id, true);
                  setFieldValue('_geoloc', location._geoloc, true);
                  setFieldValue('vicinity', location.vicinity, true);
                  setFieldValue('geohash', location.geohash, true);
                  updateSlug(location.address, values.unit);
                }}
              />
            )}


            <Field
              fullWidth
              data-test="unit"
              name="unit"
              label="Unit"
              type="text"
              component={FormikTextField}
              margin="normal"
              variant="outlined"
              onChange={async (e) => {
                setFieldValue('unit', e.target.value);
                setFieldTouched('unit', true);
                updateSlug(values.address, e.target.value);
              }}
            />
            
            <Field
              fullWidth
              disabled
              data-test="slug"
              name="slug"
              label="URL"
              type="text"
              component={FormikTextField}
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <span>
                    {'https://www.guesthouseshop.com/shop/homes/'}
                    &lrm;
                  </span>
                ),
              }}
            />

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.type && Boolean(errors.type)}
            >
              <InputLabel id="type-label">
                Type
              </InputLabel>
              <Select
                disabled={isSubmitting}
                defaultValue={initialValues.type}
                labelId="type-label"
                id="type"
                data-test="type"
                label="Type"
                onChange={(e) => {
                  setFieldValue('type', e.target.value);
                }}
              >
                <MenuItem value="">
                  <em>
&nbsp;
                  </em>
                </MenuItem>
                {$enum(HomeType).map((v, k) => (
                  <MenuItem
                    key={k}
                    value={k}
                  >
                    {v}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Field
              fullWidth
              data-test="listing_price"
              name="listing_price"
              label="Listing Price"
              type="text"
              component={TextFieldPrice}
              margin="normal"
              variant="outlined"
              customInput={TextField}
              defaultValue={initialValues.listing_price}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                )
              }}
              onValueChange={(values) => {
                const { value } = values;

                setFieldValue('listing_price', Number(value));
              }}
            />
            <Field
              fullWidth
              data-test="title"
              name="title"
              label="Title"
              type="text"
              component={FormikTextField}
              margin="normal"
              variant="outlined"
            />
            <Field
              fullWidth
              data-test="beds"
              name="beds"
              label="Beds"
              type="number"
              component={FormikTextField}
              margin="normal"
              variant="outlined"
            />
            <Field
              fullWidth
              data-test="baths"
              name="baths"
              label="Baths"
              type="number"
              component={FormikTextField}
              margin="normal"
              variant="outlined"
            />
            <Field
              fullWidth
              data-test="sqft"
              name="sqft"
              label="Square feet"
              type="number"
              component={FormikTextField}
              margin="normal"
              variant="outlined"
            />
            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.status && Boolean(errors.status)}
            >
              <InputLabel id="status-label">
                Status
              </InputLabel>
              <Select
                disabled={isSubmitting}
                defaultValue={initialValues.status}
                labelId="status-label"
                id="status"
                data-test="status"
                label="Status"
                onChange={(e) => {
                  setFieldValue('status', e.target.value);
                }}
              >
                <MenuItem value="">
                  <em>
&nbsp;
                  </em>
                </MenuItem>
                {$enum(HomeStatus).map((v, k) => (
                  <MenuItem
                    key={k}
                    value={k}
                  >
                    {v}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Field
              fullWidth
              multiline
              data-test="description"
              name="description"
              label="Description"
              type="text"
              component={FormikTextField}
              margin="normal"
              variant="outlined"
              rows={3}
            />
            <Field
              fullWidth
              data-test="zillow_url"
              name="zillow_url"
              label="Zillow URL"
              type="text"
              component={FormikTextField}
              margin="normal"
              variant="outlined"
            />
            {error && (
              <>
                <FormHelperText error>
                  {isError(error) ? error.message : error}                
                </FormHelperText>
                <FormHelperText>
                  Need help? Contact 
                  {' '}
                  <Link href="mailto:support@guesthouseshop.com">
                    support@guesthouseshop.com
                  </Link>
                </FormHelperText>
              </>
            )}
            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                data-test="home-submit-button"
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length)}
                aria-label={submitText}
                tooltip={makeErrors(errors, touched)?.length && (
                  <TooltipErrors
                    errors={errors}
                    touched={touched}
                  />
                )}
              >
                {submitText}
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(HomeForm);
