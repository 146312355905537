import PrimaryImage from '@components/primary-image/PrimaryImage';
import { Chip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import useStyles from './RoomSetCard.style';
interface RoomSetCardProps {
  roomSet: Partial<guesthouse.RoomSet>;
  imageHeight?: string | number;
  style?: CSSProperties;
  className?: string;
  onClick?: (roomSet: Partial<guesthouse.RoomSet>) => void;
  href?: (roomSet: Partial<guesthouse.RoomSet>) => string;
}

export function formatInventoryType(inventoryType?: guesthouse.RoomSetInventory) {
  switch (inventoryType) {
    case 'rental_cort':
      return 'Rental - CORT';
    case 'guesthouse':
      return 'Guest House';
    default:
      return inventoryType || '';
  }
}

const Card: React.FC<RoomSetCardProps> = (props) => {
  const {
    className,
    roomSet,
    onClick,
    style,
    imageHeight,
  } = props;
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(classes.container, className)}
      style={style}
      onClick={() => typeof onClick === 'function' && onClick(roomSet)}
    >
      <div
        className={classes.imageWrapper}
        tabIndex={0}
        style={{
          height: imageHeight,
        }}
      >
        <PrimaryImage
          item={roomSet}
          className={classes.image}
        />
      </div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>
          {roomSet?.title}
        </Typography>
      </Box>
      {
        roomSet?.inventoryType && (
          <Box>
            <Chip
              label={formatInventoryType(roomSet.inventoryType)}
              size="small"
              style={{
                fontWeight: 'bold'
              }}
            />
          </Box>
        )
      }
    </div>
  );

};

const RoomsetCard: React.FC<RoomSetCardProps> = (props) => {
  const {
    className,
    roomSet,
    onClick,
    style,
    imageHeight,
    href,
  } = props;

  if (typeof href === 'function') {
    return (
      <Link 
        to={href(roomSet)}
        style={{
          textDecoration: 'none',
          display: 'block',
        }}
      >
        <Card 
          className={className}
          roomSet={roomSet}
          style={style}
          imageHeight={imageHeight}
          onClick={onClick}
        />
      </Link>

    );
  }
  return (
    <Card 
      className={className}
      roomSet={roomSet}
      style={style}
      imageHeight={imageHeight}
      onClick={onClick}
    />
  );
};

export default RoomsetCard;
