import { SearchOptions, SearchResponse } from '@algolia/client-search';
import Sentry from '@integrations/Sentry';
import getPlatform from '@utils/getPlatform';
import { SearchIndex } from 'algoliasearch/lite';
import { useState } from 'react';

import useDebounceEffect from './useDebounceEffect';

export interface UseAlgoliaSearchOptions extends Pick<SearchOptions, 'filters' | 'numericFilters' | 'facetFilters'> {
  index?: SearchIndex;
  query?: string | null;
  aroundLatLng?: string;
  aroundRadius?: number;
  page?: number;
  perPage?: number;
  debounce?: number;
}

export default function useAlgoliaSearch<T = {}>(options : UseAlgoliaSearchOptions) {
  const {
    index,
    query,
    filters,
    numericFilters,
    facetFilters,
    aroundLatLng,
    aroundRadius,
    page = 0,
    // TODO: revert back after long-term fix to nested data issue in Algolia
    perPage = 12,
    debounce = 250,
  } = options;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [results, setResults] = useState<SearchResponse<T>>();
  const currentPlatform = getPlatform();

  const searchParams =  {
    filters: filters,
    numericFilters: numericFilters,
    facetFilters: facetFilters,
    aroundLatLng,
    aroundRadius,
    page: page,
    hitsPerPage: perPage,
    analyticsTags: [currentPlatform]
  };

  const searchFn = () => index.search<T>(query, searchParams);

  useDebounceEffect(() => {
    setLoading(true);

    if (query) {
      try {
        window.analytics?.track('Products Searched', {
          query
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    }

    searchFn()
      .then((results) => {
        setResults(results);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  }, debounce, [
    query,
    filters,
    JSON.stringify(searchParams),
    JSON.stringify(numericFilters),
    JSON.stringify(facetFilters)
  ]);

  return { results, loading, error };
}
