import Sentry from '@integrations/Sentry';
import routes from '@routes';
import slugOrID from '@ui/utils/slugOrID';
import { generatePath } from 'react-router-dom';
import { Channel } from 'stream-chat';

export const productPath = (product: Partial<guesthouse.Product>, tab?: string ): string => {
  try {
    return generatePath(routes.product.path, { id: slugOrID(product), tab: tab });
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        product
      }
    });
  }

  return '';
};

export const homePath = (home?: Partial<guesthouse.Home>, tab?: string): string => {
  try {
    if (!home) {
      return generatePath(routes.homes.path);
    } else if (!tab) {
      return generatePath(routes.home.path, { id: slugOrID(home) });
    } else {
      return generatePath(routes.homeTab.path, { id: slugOrID(home), tab: tab });
    }
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        home
      }
    });
  }

  return '';
};

export const roomPath = (home: Partial<guesthouse.Home> | guesthouse.HomeLite, room: Partial<guesthouse.Room>, tab?: string ): string => {
  try {
    if (tab) {
      return generatePath(routes.roomTab.path, { homeId: slugOrID(home), id: slugOrID(room), tab: tab });
    } else {
      return generatePath(routes.room.path, { homeId: slugOrID(home), id: slugOrID(room) });
    }
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        home,
        room
      }
    });
  }

  return '';
};


export const userPath = (user: Partial<guesthouse.User>, tab?: string): string => {
  try {
    return generatePath(routes.user.path, { id: slugOrID(user), tab: tab });
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        user
      }
    });
  }

  return '';
};

export const roomSetPath = (roomSet: Partial<guesthouse.RoomSet>, tab?: string): string => {
  try {
    return generatePath(routes.roomSet.path, { id: slugOrID(roomSet), tab: tab });
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        roomSet
      }
    });
  }

  return '';
};

export const channelPath = (channel: Channel): string => {
  try {
    return generatePath(routes.channel.path, { channelID: channel.data.cid as string });
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        channel: channel.data,
      }
    });
  }
};
