import makeStoreUrl from '@ui/utils/makeStoreUrl';

export const CATEGORIES : guesthouse.Category[] = [
  {
    id: 'seating',
    title: 'Seating',
    public: true,
    image: makeStoreUrl('/images/categories/Seating.png'),
    imageContrastColor: '#ffffff',
    subcategories: [
      {
        id: 'sofas',
        title: 'Sofas',
        public: true
      },
      {
        id: 'loveseats',
        title: 'Loveseats',
        public: true
      },
      {
        id: 'sectional-sofas',
        title: 'Sectional Sofas',
        public: true
      },
      {
        id: 'accent-chairs',
        title: 'Accent Chairs',
        public: true
      },
      {
        id: 'dining-chairs',
        title: 'Dining Chairs',
        public: true
      },
      {
        id: 'desk-chairs',
        title: 'Desk Chairs',
        public: true
      },
      {
        id: 'bar-stools',
        title: 'Bar Stools',
        public: true
      },
      {
        id: 'counter-stools',
        title: 'Counter Stools',
        public: true
      },
      {
        id: 'benches',
        title: 'Benches',
        public: true
      },
      {
        id: 'ottomans-and-poufs',
        title: 'Ottomans & Poufs',
        public: true
      }
    ]
  },
  {
    id: 'tables',
    title: 'Tables',
    public: true,
    image: makeStoreUrl('/images/categories/Tables.png'),
    imageContrastColor: '#ffffff',
    subcategories: [
      {
        id: 'desks',
        title: 'Desks',
        public: true
      },
      {
        id: 'dining',
        title: 'Dining Tables',
        public: true
      },
      {
        id: 'bistro-tables',
        title: 'Bistro Tables',
        public: true
      },
      {
        id: 'coffee-tables',
        title: 'Coffee Tables',
        public: true
      },
      {
        id: 'bar-carts',
        title: 'Bar Carts',
        public: true
      },
      {
        id: 'dressers',
        title: 'Dressers',
        public: true
      },
      {
        id: 'console-tables',
        title: 'Console Tables',
        public: true
      },
      {
        id: 'accent-tables',
        title: 'Accent Tables',
        public: true
      },
      {
        id: 'nightstands',
        title: 'Nightstands',
        public: true
      },
    ]
  },
  {
    id: 'beds-and-headboards',
    title: 'Beds & Headboards',
    public: false,
    image: makeStoreUrl('/images/categories/Beds.png'),
    imageContrastColor: '#ffffff',
    subcategories: [
      {
        id: 'king-beds-and-headboards',
        title: 'King Beds and Headboards',
        public: true
      },
      {
        id: 'king-matresses',
        title: 'King Matresses',
        public: true
      },
      {
        id: 'queen-beds-and-headboards',
        title: 'Queen Beds and Headboards',
        public: true
      },
      {
        id: 'queen-matresses',
        title: 'Queen Matresses',
        public: true
      },
      {
        id: 'twin-beds-and-headboards',
        title: 'Twin Beds and Headboards',
        public: true
      },
      {
        id: 'twin-matresses',
        title: 'Twin Matresses',
        public: true
      },
      {
        id: 'daybeds',
        title: 'Daybeds',
        public: true
      },
      {
        id: 'cribs',
        title: 'Cribs',
        public: true
      },
    ]
  },
  {
    id: 'lighting',
    title: 'Lighting',
    public: true,
    image: makeStoreUrl('/images/categories/Lighting.png'),
    imagePosition: '50% 35%',
    imageContrastColor: '#ffffff',
    subcategories: [
      {
        id: 'floor-lighting',
        title: 'Floor Lighting',
        public: true
      },
      {
        id: 'table-lighting',
        title: 'Table Lighting',
        public: true
      }
    ]
  },
  {
    id: 'rugs',
    title: 'Rugs',
    public: true,
    image: makeStoreUrl('/images/categories/Rugs.png'),
    imagePosition: '50% 100%',
    imageContrastColor: '#ffffff',
    subcategories: [
      {
        id: 'large',
        title: 'Large (8x10\'+)',
        public: true
      },
      {
        id: 'medium',
        title: 'Medium (6x9\')',
        public: true
      },
      {
        id: 'small',
        title: 'Small (5x7\')',
        public: true
      },
      {
        id: 'accent-rugs',
        title: 'Accent Rugs',
        public: true
      }
    ]
  },
  {
    id: 'decor',
    title: 'Decor',
    public: true,
    image: makeStoreUrl('/images/categories/Decor.png'),
    imagePosition: '50% 50%',
    imageContrastColor: '#ffffff',
    subcategories: [
      {
        id: 'decorative-accents',
        title: 'Decorative Accents',
        public: true
      },
      {
        id: 'pillows-and-throw-blankets',
        title: 'Pillows & Throw Blankets',
        public: true
      }
    ]
  },
  {
    id: 'wall-decor',
    title: 'Wall Decor',
    public: true,
    image: makeStoreUrl('/images/categories/Artwork.png'),
    imageContrastColor: '#ffffff',
    metaTitle: 'Local Wall Art and Décor from Guest House',
    metaDescription: 'Our mission is to bring amazing local wall art and décor to every house we stage, and now you can shop the collection.',
    subcategories: [
      {
        id: 'framed-prints-and-artwork',
        title: 'Framed Prints & Artwork',
        public: true
      },
      {
        id: 'large-paintings',
        title: 'Large Paintings',
        public: true
      },
      {
        id: 'small-paintings',
        title: 'Small Paintings',
        public: true
      },
      {
        id: 'wall-hangings-and-textiles',
        title: 'Wall Hangings & Textiles',
        public: true
      },
      {
        id: 'mirrors',
        title: 'Mirrors',
        public: true
      }
    ]
  },
  {
    id: 'patio',
    title: 'Patio',
    public: false,
    image: makeStoreUrl('/images/categories/Patio.png'),
    imageContrastColor: '#ffffff',
    subcategories: [
      {
        id: 'outdoor-sofas',
        title: 'Outdoor Sofas',
        public: false
      },
      {
        id: 'outdoor-accent-chairs',
        title: 'Outdoor Accent Chairs',
        public: true
      },
      {
        id: 'outdoor-lounge-chairs',
        title: 'Outdoor Lounge Chairs',
        public: true
      },
      {
        id: 'outdoor-coffee-tables',
        title: 'Outdoor Coffee Tables',
        public: true
      },
      {
        id: 'outdoor-dining-sets',
        title: 'Outdoor Dining Sets',
        public: true
      },
      {
        id: 'outdoor-dining-chairs',
        title: 'Outdoor Dining Chairs',
        public: true
      },
      {
        id: 'outdoor-dining-tables',
        title: 'Outdoor Dining Tables',
        public: true
      },
      {
        id: 'outdoor-rugs',
        title: 'Outdoor Rugs',
        public: true
      },
      {
        id: 'outdoor-accessories',
        title: 'Outdoor Accessories',
        public: true
      },
    ]
  },
  {
    id: 'other',
    title: 'Other',
    public: false,
    image: makeStoreUrl('/images/categories/Other.png'),
    imageContrastColor: '#ffffff',
    subcategories: [
      {
        id: 'other-furniture',
        title: 'Other Furniture',
        public: false
      }
    ]
  }
];
