import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  count: {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    fontSize: '14px !important',
    lineHeight: 1,
    color: theme.palette.common.white,
    background: '#A3403D',
  },
  filtersContainer: {
    borderLeft: '0px solid',
    borderColor: '#DDDDDD',
    position: 'fixed',
    width: 'calc(100% - 88px)',
    maxWidth: 1512,
    height: 'calc(100% - 144px)',
    backgroundColor: '#fff',
    transform: 'translateY(0px)',
    transition: 'transform .2s',
    marginBottom: theme.spacing(24),
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0px 0px 10px 0px',
    paddingRight: theme.spacing(2)
  },
  filtersContainerOpen: {
    transform: 'translateY(0px)',
  },
  filtersContainerInner: {
    flex: 1,
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(3.5),
      paddingBottom: theme.spacing(10)
    }
  },
  filtersContainerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  filterContainerButtons: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    textAlign: 'center',
    padding: theme.spacing(1, 2, 2),
    borderRadius: '0px 0px 10px 0px'
  },
  filterButtonBase: {
    fontFamily: theme.gh_vars.circular,
    fontWeight: 600,
    lineHeight: 1.75,
    fontSize: 15,
    margin: 0,
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 3),
    }
  },
  chevronIcon: {
    transition: 'transform .2s',
  },
  chevronIconFlipped: {
    transform: 'rotateZ(180deg)'
  },
  subfilterButtonBase: {
    margin: 0,
    justifyContent: 'flex-start',
    width: '100%',
    padding: theme.spacing(.5, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(.5, 3),
    }
  },
  subcategoryText: {
    fontSize: 12,
    lineHeight: 1.1,
    fontWeight: 400,
    color: '#666',
    width: '100%',
  },
  subcategoryCollapseWrapper: {
    padding: theme.spacing(0, 3)
  },
  filterText: {
    fontFamily: theme.gh_vars.circular,
    fontWeight: 600,
    lineHeight: 1.75,
    fontSize: 15,
    margin: 0
  },
  filterSwitch: {
    position: 'relative',
    left: theme.spacing(1),
  },
  subFilterText: {
    fontFamily: theme.gh_vars.circular,
    fontSize: 14,
    lineHeight: 1.25,
    fontWeight: 400,
    width: '100%',
    textAlign: 'left',
  },
  formControlRoot: {
    width: '100%',
    maxHeight: theme.spacing(4),
  },
  formControlLabel: {
    width: '100%',
  },
  searchFilterBox: {
    position: 'relative',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  searchTextField: {
    width: '100%',
    flex: 1,
  },
  filterButton: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    width: '100%',
    margin: theme.spacing(1, 0, 0, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 0, 0, 1),
    },
  },
  searchFieldInput: {
    borderRadius: '50px',
    borderColor: '#92a0ab',
    backgroundColor: '#fff',
    fontFamily: theme.gh_vars.mabryMono,
    '& input': {
      fontSize: 13,
      padding: theme.spacing(1, 2),
    }
  },
  searchFieldIcon: {
    fill: '#666666',
    height: 18,
    width: 18,
    '& path': {
      stroke: '#666666'
    }
  },
  title: {
    marginLeft: 20,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 36,
    },
  },
  close: {
    color: theme.palette.common.black,
    [theme.breakpoints.up('sm')]: {
      marginRight: 16,
    },
  },
}));
