import { database } from '@app/firebase';
import TextFieldPrice from '@components/textfield-price/TextFieldPrice';
import UserSearch from '@components/user-search/UserSearch';
import UserContext from '@context/UserContext';
import { CATEGORIES, COLORS, STYLES } from '@data';
import useCollection from '@hooks/useCollection';
import useDebounceEffect from '@hooks/useDebounceEffect';
import useMuiMenuHooks from '@hooks/useMuiMenuHooks';
import Sentry from '@integrations/Sentry';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ProductSchema, { initialValues as _initialValues, ProductFormValues } from '@schema/ProductSchema';
import ProductSchemaMaker from '@schema/ProductSchemaMaker';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import Protected from '@ui/components/protected/Protected';
import theme from '@ui/theme';
import toJsDate from '@ui/utils/toJsDate';
import { isError } from '@ui/utils/typescriptHelpers';
import debouncePromise from '@utils/debouncePromise';
import { checkSlug } from '@utils/scannerNextChar';
import convert from 'convert';
import { endOfDay } from 'date-fns';
import { collection } from 'firebase/firestore';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import slugify from 'slugify';


const debounceCheckSlug = debouncePromise(checkSlug, 300);

const convertDimensionalUnitToAbbreviation = (type: string): 'ft' | 'in' | 'cm' | 'm' => {
  switch (type) {
    case 'feet':
      return 'ft';
    case 'centimeters':
      return 'cm';
    case 'meters':
      return 'm';
    default:
      return 'in';
  }
};

export type ProductFormAdditionalValues = {
  price: number;
  purchasePrice: number;
  dropship: boolean;
  arrivalDate?: guesthouse.Date;
  purchaseDate: guesthouse.Date;
  warehouse?: guesthouse.Warehouse;
  quantity?: number;
  shippingPricePerOrder?: number;
  shippingPricePerProduct?: number;
}

interface ProductFormProps {
  initialValues: ProductFormValues;
  onSubmit: (product: Partial<guesthouse.Product>, additionalValues: ProductFormAdditionalValues) => void;
  error: Error | string | boolean;
  submitText: string;
}

const ProductForm = ({ onSubmit, initialValues = _initialValues, error, submitText = 'Continue' }: ProductFormProps) => {
  const userContext = useContext<UserContext>(UserContext);
  const [slug, setSlug] = useState('');

  const [selectedDimensionalUnits, setSelectedDimensionalUnits] = useState<'in' | 'ft' | 'cm' | 'm'>(convertDimensionalUnitToAbbreviation(initialValues.dimensional_units));

  const {
    anchorEl: dimensionsAnchorEl,
    open: dimensionsOpen,
    handleClick: dimensionsHandleClick,
    handleClose: dimensionsHandleClose,
  } = useMuiMenuHooks();
  const { collection: warehouses, loading: warehousesLoading } = useCollection(collection(database, 'warehouses'));

  const warehouseData = useMemo(() => {
    if (warehouses) {
      return warehouses.docs.map(w => w.data());
    }
    return [];
  }, [warehouses, warehousesLoading]);

  const [product, setProduct] = useState<Partial<guesthouse.Product>>({
    archived: false,
    published: false,
    inventory: 0,
    inventoryInternal: 0,
    inventoryTotal: 0,
    available: 0,
    availableInternal: 0,
    availableTotal: 0,
  });
  const [purchasePrice, setPurchasePrice] = useState<number>(null);
  const [price, setPrice] = useState<number>(null);
  const [dropship, setDropship] = useState<boolean>(false);
  const [arrivalDate, setArrivalDate] = useState<guesthouse.Date>();
  const [purchaseDate, setPurchaseDate] = useState<guesthouse.Date>(endOfDay(new Date()));
  const [warehouse, setWarehouse] = useState<guesthouse.Warehouse>(userContext.data?.preferred_warehouse);
  const [quantity, setQuantity] = useState<number>(0);
  const [shippingPricePerOrder, setShippingPricePerOrder] = useState<number>(0);
  const [shippingPricePerProduct, setShippingPricePerProduct] = useState<number>(0);

  return (
    <Formik
      validationSchema={userContext?.roles?.maker ? ProductSchemaMaker : ProductSchema}
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={() => onSubmit(product, { price, dropship, purchasePrice, arrivalDate, purchaseDate, warehouse, quantity, shippingPricePerOrder, shippingPricePerProduct })}
    >
      {({
        errors,
        values,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (values.title) {
            const slug = slugify(values.title, {
              strict: true,
              lower: true,
            });

            checkSlug(slug, 'products')
              .then(existingSlugs => {

                if (initialValues.slug?.length && initialValues.slug === slug && !existingSlugs?.length) {
                  setFieldValue('slug', slug);
                  return;
                }

                if (existingSlugs?.length) {
                  let n = 1;
                  let incrememtedSlug = slug;

                  while (existingSlugs.indexOf(incrememtedSlug) > -1) {
                    incrememtedSlug = `${slug}-${n}`;
                    n += 1;
                  }
                  setFieldValue('slug', incrememtedSlug);
                  setSlug(incrememtedSlug);
                }
              });
          }
        }, []);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (userContext.data?.preferred_warehouse) {
            initialValues.warehouse = userContext.data.preferred_warehouse.name;
          }
        }, [userContext.data?.preferred_warehouse]);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (dropship) {
            if (values?.owner?.shipping_policy?.length) {
              setFieldValue('shippingPolicy', values.owner.shipping_policy);
              setFieldTouched('shippingPolicy');
            }
            if (values?.owner?.return_policy?.length) {
              setFieldValue('returnPolicy', values.owner.return_policy);
              setFieldTouched('returnPolicy');
            }
            if (values?.owner?.shipping_price_per_order) {
              setFieldValue('shipping_price_per_order', values.owner.shipping_price_per_order);
              setFieldTouched('shipping_price_per_order');
            }
            if (values?.owner?.shipping_price_per_product) {
              setFieldValue('shipping_price_per_product', values.owner.shipping_price_per_product);
              setFieldTouched('shipping_price_per_product');
            }
          }

          if (!dropship || !values?.owner) {
            setFieldTouched('shippingPolicy', false);
            setFieldValue('shippingPolicy', '');
            setFieldTouched('returnPolicy', false);
            setFieldValue('returnPolicy', '');
            setFieldValue('shipping_price_per_order', '');
            setFieldTouched('shipping_price_per_order', false);
            setFieldValue('shipping_price_per_product', '');
            setFieldTouched('shipping_price_per_product', false);
          }

          if (!values.owner?.shipping_policy) {
            setFieldTouched('shippingPolicy', false);
            setFieldValue('shippingPolicy', '');
          }

          if (!values.owner?.return_policy) {
            setFieldTouched('returnPolicy', false);
            setFieldValue('returnPolicy', '');

          }
          if (!values.owner?.shipping_price_per_order) {
            setFieldTouched('shipping_price_per_order', false);
            setFieldValue('shipping_price_per_order', '');

          }
          if (!values.owner?.shipping_price_per_product) {
            setFieldTouched('shipping_price_per_product', false);
            setFieldValue('shipping_price_per_product', '');

          }
        }, [values?.owner, dropship]);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useDebounceEffect(() => setProduct(product => {
          const newVals = { ...values };

          delete newVals.price;
          delete newVals.dropship;
          delete newVals.purchase_date;
          delete newVals.quantity;
          delete newVals.warehouse;
          delete newVals.shipping_price_per_order;
          delete newVals.shipping_price_per_product;

          if (dropship) {
            newVals.dropshippable = true;
          }

          return { ...product, ...newVals } as guesthouse.Product;
        }), 200, [values]);

        return (
          <form onSubmit={handleSubmit}>
            <Field
              autoFocus
              fullWidth
              data-test="title"
              name="title"
              label="Title *"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
              onChange={async (e) => {
                setFieldValue('title', e.target.value);
                setFieldTouched('title', true);

                const slug = slugify(e.target.value, {
                  strict: true,
                  lower: true,
                });

                if (initialValues.slug?.length && initialValues.slug === slug) {
                  setFieldValue('slug', slug);
                  return;
                }

                let existingSlugs = [];

                try {
                  existingSlugs = await debounceCheckSlug(slug, 'products');
                } catch (e) {
                  Sentry.captureException(e);
                  return;
                }

                if (existingSlugs.length) {
                  let n = 1;
                  let incrememtedSlug = slug;

                  while (existingSlugs.indexOf(incrememtedSlug) > -1) {
                    incrememtedSlug = `${slug}-${n}`;
                    n += 1;
                  }

                  setFieldValue('slug', incrememtedSlug);
                } else {
                  setFieldValue('slug', slug);
                }
              }}
            />

            <Field
              fullWidth
              disabled
              data-test="slug"
              name="slug"
              label="URL"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
              value={slug || values.slug}
              InputProps={{
                startAdornment: (
                  <span>
                    {'https://www.guesthouseshop.com/shop/products/'}
                    &lrm;
                  </span>
                ),
              }}
            />

            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              <Field
                fullWidth
                name="vendor_url"
                label="Vendor URL"
                type="text"
                component={TextField}
                margin="normal"
                variant="outlined"
              />
            </Protected>

            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              <UserSearch
                defaultValue={values.owner}
                useCompany={true}
                filterRoles={['maker']}
                label="Brand / Maker"
                style={{
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(1),
                }}
                onChange={(_, value) => {
                  setFieldValue('owner', value);
                  setFieldTouched('owner');
                }}
              />
            </Protected>
            <Field
              fullWidth
              multiline
              data-test="description"
              name="description"
              label="Description"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
              rows={3}
            />

            <Field
              fullWidth
              data-test="price"
              name="price"
              label="Guest House Price *"
              type="text"
              component={TextFieldPrice}
              margin="normal"
              variant="outlined"
              customInput={TextField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                )
              }}
              onChange={(e) => {
                const { value } = e.target;
                const newPrice = parseInt(value.replace(/,/g, ''));

                setPrice(newPrice);
                setFieldValue('price', newPrice);
              }}
            />

            <Field
              fullWidth
              data-test="msrp"
              name="msrp"
              label="MSRP *"
              type="text"
              component={TextFieldPrice}
              margin="normal"
              variant="outlined"
              customInput={TextField}
              defaultValue={values.msrp}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                )
              }}
              onValueChange={(values) => {
                const { value } = values;

                setFieldValue('msrp', Number(value.replace(/,/g, '')));
              }}
            />

            <Field
              fullWidth
              data-test="purchase_price"
              name="purchase_price"
              label="Purchase Price"
              type="text"
              component={TextFieldPrice}
              margin="normal"
              variant="outlined"
              customInput={TextField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                )
              }}
              onValueChange={(values) => {
                const { value } = values;
                const newPurchasePrice = Number(value.replace(/,/g, ''));

                newPurchasePrice ? setPurchasePrice(newPurchasePrice) : setPurchasePrice(null);
              }}
            />

            <Field
              fullWidth
              multiline
              data-test="manufacturer_sku"
              name="manufacturer_sku"
              label="SKU"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Arrival Date"
                value={
                  arrivalDate ?
                    toJsDate(arrivalDate || null)
                    : null
                }
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    margin: 'normal',
                    helperText: '',
                    style: { width: '100%' },
                    inputProps: {
                      ['data-test']: 'arrival_date'
                    }
                  }
                }}
                onChange={(date) => {
                  if (date === null) {
                    setArrivalDate(undefined);
                  } else {
                    setArrivalDate(endOfDay(date));
                  }
                }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Purchase Date"
                value={
                  purchaseDate || initialValues?.purchase_date ?
                    toJsDate(purchaseDate || initialValues?.purchase_date || null)
                    : null
                }
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    margin: 'normal',
                    helperText: '',
                    style: { width: '100%' },
                    inputProps: {
                      ['data-test']: 'purchase_date'
                    }
                  }
                }}
                onChange={(date) => {
                  if (date === null) {
                    setPurchaseDate(undefined);
                  } else {
                    setPurchaseDate(endOfDay(date));
                  }
                }}
              />
            </LocalizationProvider>

            <Field
              fullWidth
              data-test="dimensions_visible"
              name="dimensions_visible"
              label="Dimensions (Visible on Store)"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
            />

            <Box
              display="flex"
              justifyContent="space-between"
            >
              <Field
                fullWidth
                data-test="width"
                name="width"
                label="Width"
                type="number"
                component={TextField}
                margin="normal"
                variant="outlined"
                value={values.width ? Number(convert(Number(values.width), 'in').to(selectedDimensionalUnits).toFixed(2)) : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {selectedDimensionalUnits}
                    </InputAdornment>
                  )
                }}
                style={{ marginRight: theme.spacing(1) }}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  const inches = convert(value, selectedDimensionalUnits).to('in').toFixed(2);

                  setFieldTouched('width', true);
                  setFieldValue('width', inches);
                }}
              />
              <Field
                fullWidth
                data-test="length"
                name="length"
                label="Length / Depth"
                type="number"
                component={TextField}
                margin="normal"
                variant="outlined"
                value={values.length ? Number(convert(Number(values.length), 'in').to(selectedDimensionalUnits).toFixed(2)) : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {selectedDimensionalUnits}
                    </InputAdornment>
                  )
                }}
                style={{ marginRight: theme.spacing(1) }}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  const inches = convert(value, selectedDimensionalUnits).to('in').toFixed(2);

                  setFieldTouched('length', true);
                  setFieldValue('length', inches);
                }}
              />
              <Field
                fullWidth
                data-test="height"
                name="height"
                label="Height"
                type="number"
                component={TextField}
                margin="normal"
                variant="outlined"
                value={values.height ? Number(convert(Number(values.height), 'in').to(selectedDimensionalUnits).toFixed(2)) : ''}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        {selectedDimensionalUnits}
                      </InputAdornment>
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="change dimensional units"
                          edge="end"
                          onClick={dimensionsHandleClick}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </InputAdornment>
                    </>
                  )
                }}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  const inches = convert(value, selectedDimensionalUnits).to('in').toFixed(2);

                  setFieldTouched('height', true);
                  setFieldValue('height', inches);
                }}
              />
            </Box>

            <Field
              fullWidth
              data-test="weight"
              name="weight"
              label="Weight"
              type="number"
              component={TextField}
              margin="normal"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    lb
                  </InputAdornment>
                )
              }}
            />

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.category && Boolean(errors.category)}
            >
              <InputLabel id="category-label">
                Category *
              </InputLabel>
              <Select
                data-test="category"
                labelId="category-label"
                id="category"
                value={values.category || []}
                label="Category"
                onChange={(e) => {
                  const categoryArray = [];

                  if (e.target.value) {
                    categoryArray.push(e.target.value);
                  }
                  setFieldTouched('category', true);
                  setFieldValue('category', categoryArray);
                }}
              >
                <MenuItem value="">
                  <em>
                    &nbsp;
                  </em>
                </MenuItem>
                {CATEGORIES.map(category => (
                  <MenuItem
                    key={category.id}
                    value={category.id}
                  >
                    {category.title}
                  </MenuItem>
                ))}
              </Select>
              {touched.category && Boolean(errors.category) && (
                <FormHelperText error>
                  {errors.category}
                </FormHelperText>
              )}
            </FormControl>

            {CATEGORIES.map(category => {
              if (values.category.includes(category.id) && category.subcategories) {
                return (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={touched.category2 && Boolean(errors.category2)}
                  >
                    <InputLabel id="category2">
                      Category 2 *
                    </InputLabel>
                    <Select
                      data-test="category2"
                      labelId="category2"
                      id="category2"
                      value={values.category2 || []}
                      name="category2"
                      label="Category 2"
                      onChange={(e) => {
                        const { value: cat2 } = e.target;

                        if (cat2?.length) {
                          setFieldValue('category2', [cat2], false);
                          setFieldTouched('category2', true, false);
                          delete errors.category2;
                        } else {
                          setFieldValue('category2', []);
                        }
                      }}
                    >
                      <MenuItem value="">
                        <em>
                          &nbsp;
                        </em>
                      </MenuItem>
                      {category.subcategories.map(category2 => {
                        return (
                          <MenuItem
                            key={category2.id}
                            value={category2.id}
                          >
                            {category2.title}
                          </MenuItem>
                        );
                      })}

                    </Select>
                    {touched.category2 && Boolean(errors.category2) && (
                      <FormHelperText error>
                        {errors.category2}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }
            })}

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.style && Boolean(errors.style)}
            >
              <InputLabel id="style-label">
                Styles
              </InputLabel>
              <Select
                multiple
                data-test="style"
                labelId="style-label"
                id="style"
                value={values.style || []}
                label="Styles"
                renderValue={selected => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      {(selected as string[]).map(value => (
                        <Chip
                          key={value}
                          label={value}
                          style={{ margin: 2 }}
                        />
                      ))}
                    </div>
                  );
                }}
                onChange={(e) => {
                  setFieldTouched('style', true);
                  setFieldValue('style', e.target.value);
                }}
              >
                {STYLES.map(style => (
                  <MenuItem
                    key={style.id}
                    value={style.id}
                  >
                    {style.title}
                  </MenuItem>
                ))}
              </Select>
              {touched.style && Boolean(errors.style) && (
                <FormHelperText error>
                  {errors.style}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.color && Boolean(errors.color)}
            >
              <InputLabel id="color-label">
                Colors
              </InputLabel>
              <Select
                multiple
                data-test="color"
                labelId="color-label"
                id="color"
                value={values.color || []}
                label="Colors"
                renderValue={selected => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      {(selected as string[]).map(value => (
                        <Chip
                          key={value}
                          label={value}
                          style={{ margin: 2 }}
                        />
                      ))}
                    </div>
                  );
                }}
                onChange={(e) => {
                  setFieldTouched('color', true);
                  setFieldValue('color', e.target.value);
                }}
              >
                {COLORS.map(color => (
                  <MenuItem
                    key={color.id}
                    value={color.id}
                  >
                    {color.title}
                  </MenuItem>
                ))}
              </Select>
              {touched.color && Boolean(errors.color) && (
                <FormHelperText error>
                  {errors.color}
                </FormHelperText>
              )}
            </FormControl>

            <FormControlLabel
              control={
                <Switch
                  checked={values.dropship}
                  name="dropship"
                  color="primary"
                  data-test="dropship"
                  onChange={(_, value) => {
                    setDropship(value);
                  }}
                />
              }
              label="Dropship"
            />

            {dropship && (
              <>
                <Field
                  fullWidth
                  multiline
                  data-test="shippingPolicy"
                  name="shippingPolicy"
                  label="Shipping Policy"
                  type="text"
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />

                <Field
                  fullWidth
                  multiline
                  data-test="returnPolicy"
                  name="returnPolicy"
                  label="Return Policy"
                  type="text"
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />

                <Field
                  fullWidth
                  multiline
                  data-test="shipping_price_per_product"
                  name="shipping_price_per_product"
                  label="Shipping Price Per Product"
                  type="text"
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => {
                    const { value } = e.target;
                    const newPricePerProduct = Number(value.replace(/,/g, ''));

                    setShippingPricePerProduct(newPricePerProduct);
                    setFieldValue('shipping_price_per_product', newPricePerProduct);
                    setFieldTouched('shipping_price_per_product');
                  }}
                />

                <Field
                  fullWidth
                  multiline
                  data-test="shipping_price_per_order"
                  name="shipping_price_per_order"
                  label="Shipping Price Per Order"
                  type="text"
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => {
                    const { value } = e.target;
                    const newPricePerOrder = Number(value.replace(/,/g, ''));

                    setShippingPricePerOrder(newPricePerOrder);
                    setFieldValue('shipping_price_per_order', newPricePerOrder);
                    setFieldTouched('shipping_price_per_order');
                  }}
                />
              </>
            )}

            <Field
              fullWidth
              data-test="quantity"
              name="quantity"
              label="Quantity"
              type="number"
              component={TextField}
              margin="normal"
              variant="outlined"
              onChange={(e) => {
                const { value } = e.target;

                if (value) {
                  setQuantity(Number(value.replace(/,/g, '')));
                  setFieldValue('quantity', Number(value.replace(/,/g, '')));
                  setFieldTouched('quantity');
                } else {
                  setQuantity(0);
                  setFieldValue('quantity', null);
                }
              }}
            />

            {warehouseData.length &&
              <Protected allowedRoles={['admin', 'design_manager']}>
                <Autocomplete
                  fullWidth
                  id="warehouse"
                  defaultValue={userContext?.data?.preferred_warehouse || null}
                  options={warehouseData}
                  getOptionLabel={(option: Partial<guesthouse.Warehouse>) => option.name || ''}
                  renderInput={(params) => {
                    return (
                      <Field
                        {...params}
                        fullWidth
                        defaultValue={userContext?.data?.preferred_warehouse?.name || null}
                        touched={true}
                        name="warehouse"
                        label="Warehouse"
                        type="text"
                        component={TextField}
                        margin="dense"
                        variant="outlined"
                        data-test="warehouse"
                      />
                    );
                  }}
                  onChange={(_, value: guesthouse.Warehouse) => {
                    setFieldValue('warehouse', value?.name);
                    setWarehouse(value);
                  }}
                />
              </Protected>
            }


            {error && (
              <>
                <FormHelperText error>
                  {isError(error) ? error.message : error}
                </FormHelperText>
                <FormHelperText>
                  Need help? Contact
                  {' '}
                  <Link href="mailto:support@guesthouseshop.com">
                    support@guesthouseshop.com
                  </Link>
                </FormHelperText>
              </>
            )}
            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                data-test="product-form-submit"
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length)}
                aria-label={submitText}
                tooltip={makeErrors(errors, touched)?.length && (
                  <TooltipErrors
                    errors={errors}
                    touched={touched}
                  />
                )}
              >
                {submitText}
              </SubmitButton>
            </div>

            <Menu
              anchorEl={dimensionsAnchorEl}
              open={dimensionsOpen}
              onClose={dimensionsHandleClose}
              onClick={dimensionsHandleClose}
            >
              <MenuItem
                onClick={() => {
                  setFieldValue('dimensional_units', 'inches');
                  setSelectedDimensionalUnits('in');
                }}
              >
                Inches
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFieldValue('dimensional_units', 'feet');
                  setSelectedDimensionalUnits('ft');
                }}
              >
                Feet
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFieldValue('dimensional_units', 'centimeters');
                  setSelectedDimensionalUnits('cm');
                }}
              >
                Centimeters
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFieldValue('dimensional_units', 'meters');
                  setSelectedDimensionalUnits('m');
                }}
              >
                Meters
              </MenuItem>
            </Menu>
          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(ProductForm);
