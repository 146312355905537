import useCenterPoint from '@hooks/useCenterPoint';
import useFacetSearch from '@hooks/useFacetSearchProducts';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';
import checkRoles from '@ui/utils/checkRoles';
import { emptyInitResults, initPagedResults } from '@utils/algoliaSearch';
import { PagedResults, UseFilterSearchOptions } from '@utils/algoliaSearch';
import { useEffect, useMemo, useRef, useState } from 'react';

import useRouter from './useRouter';
export default function useProductFilterSearch(userContext: UserContext, appContext: AppContext, options: UseFilterSearchOptions<guesthouse.Product> = {
  setSearchContext: true,
  useRouterQuery: true,
  extraFacetFilters: {},
}) {
  const router = useRouter();
  const [products, setProducts] = useState<PagedResults<guesthouse.Product>>(initPagedResults());
  const [page, setPage] = useState(0);
  // TODO: revert back after long-term fix to nested data issue in Algolia
  const [perPage, setPerPage] = useState(12);
  const [numResults, setNumResults] = useState<number>(undefined);
  const [appliedFilters, setAppliedFilters] = useState(0);

  const query = options.useRouterQuery
    ? router.query
    : { q: options.queryString };
  
  const [inventoryOpen, setInventoryOpen] = useState(!!query.inventoryExternal?.toString() || !!query.inventoryInternal?.toString());
  const [categoriesOpen, setCategoriesOpen] = useState(!!query.category?.toString());
  const [colorsOpen, setColorsOpen] = useState(!!query.color?.toString());
  const [stylesOpen, setStylesOpen] = useState(!!query.style?.toString());
  
  const endOfContainerElement = useRef<HTMLDivElement>();

  const [coords, radius] = useCenterPoint();

  const { extraFacetFilters } = options;

  if (checkRoles(['maker'], userContext.roles)) {
    extraFacetFilters['owner.docID'] = userContext.user.uid;
  }

  const {
    setQuery,
    results,
    filters,
    startDate,
    setStartDate,
    setAvailableTotal,
    published,
    setPublished,
    inventoryExternal,
    setInventoryExternal,
    inventoryInternal,
    setInventoryInternal,
    dibs,
    setDibs,
    dropshippable,
    setDropshippable
  } = useFacetSearch({
    query,
    initResults: emptyInitResults,
    searchOptions: {
      page: page,
      hitsPerPage: perPage,
      aroundLatLng: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? coords?.join(',') : undefined,
      aroundRadius: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? radius : undefined,
    },
    extraFacetFilters,
    useRouter: options.useRouterQuery,
  });

  const startDateObject = useMemo(() => {
    if (startDate) {
      return new Date(Number(startDate?.toString()) * 1000);
    }
  }, [startDate]);

  const clearFilters = () => {
    Object.keys(filters).map(filter => {
      filters[filter].setter(undefined);
    });
    setInventoryExternal(undefined);
    setInventoryInternal(undefined);
    setAvailableTotal(undefined);
    setStartDate(null);
    setPublished('');
    setDibs('');
    setDropshippable('');
  };

  useEffect(() => {
    // TODO: validation on router.query.q potential string[] to string
    setQuery(query.q?.toString());
  }, [query.q]);

  useEffect(() => {
    setNumResults(undefined);
    setPage(0);
  }, [JSON.stringify(query)]);

  useEffect(() => {
    const resultPage = results?.page ?? 0;
    const end = resultPage >= ((results?.nbPages ?? 0) - 1);
    const hits = results?.hits;

    // not actually completed yet, dont update products
    if (hits === undefined) {
      return;
    }

    // nbHits gives results from page [n, end], is only equal to the total on n=0
    if (resultPage === 0) {
      setNumResults(results.nbHits);
    }
    setProducts((prevState: PagedResults<guesthouse.Product>): PagedResults<guesthouse.Product> => {
      const newPage: PagedResults<guesthouse.Product> = {
        [resultPage]: {
          results: hits,
          status: 'complete',
          end: end,
        }
      };

      // if we're fetching a new set of results, clear the whole results
      return (resultPage === 0)
        ? newPage
        : { ...prevState, ...newPage };
    });
  }, [results]);

  useEffect(() => {
    let tempCount = 0;

    Object.keys(filters).map(filter => {
      filters[filter].values ? tempCount += filters[filter].values?.length : null;
    });
    if (inventoryExternal) {
      tempCount += 1;
    }
    if (inventoryInternal) {
      tempCount += 1;
    }
    if (startDate) {
      tempCount += 1;
    }
    if (published) {
      tempCount += 1;
    }
    setAppliedFilters(tempCount);
  }, [filters, inventoryExternal, inventoryInternal, startDate, published]);

  const getNextPage = () => {
    if (products[page] && products[page].status === 'loading') {
      return;
    }

    if (products[page] && products[page].end === true) {
      return;
    }

    const nextPage = page + 1;

    setProducts(prevState => ({
      ...prevState,
      [nextPage]: {
        status: 'loading',
      },
    }));
    setPage(nextPage);
  };

  useInfiniteScroll(endOfContainerElement, getNextPage, {
    rootMargin: '400px 0px',
    threshold: 0,
  });

  return {
    startDate,
    setStartDate,
    setAvailableTotal,
    published,
    setPublished,
    inventoryOpen,
    setInventoryOpen,
    inventoryInternal,
    setInventoryInternal,
    inventoryExternal,
    setInventoryExternal,
    categoriesOpen,
    setCategoriesOpen,
    page,
    setPage,
    perPage,
    setPerPage,
    products,
    setProducts,
    filters,
    clearFilters,
    stylesOpen,
    setStylesOpen,
    colorsOpen,
    setColorsOpen,
    appliedFilters,
    results,
    numResults,
    startDateObject,
    endOfContainerElement,
    dibs,
    setDibs,
    dropshippable,
    setDropshippable
  };
}
