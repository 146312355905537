import UserContext from '@context/UserContext';
import { useContext, useMemo } from 'react';


/**
 * Radius here is represented in meters
 */
export default function useCenterPoint(location?: guesthouse.Location): [number[], number] {
  const userContext = useContext<UserContext>(UserContext);

  const [centerPoint, aroundRadius] = useMemo(() => {
    if (userContext.activeRegion) {
      return [userContext.activeRegion.centerPoint, userContext.activeRegion.aroundRadius * 1000];
    }

    // in the case where an admin is viewing and no region is selected, try to focus around another location
    if (location) {
      return [location, 1000 * 1000];
    }
    return [];

  }, [location, userContext.activeRegion, userContext.activeRegion === null]);

  const [coords, radius] = useMemo(() => {
    if (centerPoint?._geoloc?.lat && centerPoint?._geoloc?.lng) {
      return [
        [centerPoint._geoloc.lat, centerPoint._geoloc.lng],
        aroundRadius,
      ];
    }
    return [];
  }, [centerPoint]);

  return [coords, radius];
}
