import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  registrationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '100vw',
    minHeight: '100vh',
    background: '#F7F7F7',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top 20px left 0px',
    backgroundSize: '50%',
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'auto',
      backgroundPosition: 'bottom left',
    }
  },
  registrationContent: {
    maxWidth: 1240,
    width: '100%',
  }
}));
