import * as Yup from 'yup';

declare module 'yup' {
  interface ArraySchema<T> {
      uniqueSkus(): ArraySchema<T>
  }
}

/*
  input: array of strings e.g. [0 => 'abc', 1 => 'def', 2 => 'abc']
  output: map of string => list of indexes e.g. {'abc' => [0, 2], 'def' => [1]}
*/
function mapIndexes(a: Array<string>): Record<string, number[]> {
  const indexes = {};

  for (let i = 0; i < a.length; i++) {
    const s = a[i];

    if (!indexes[s]) {
      indexes[s] = [];
    }
    indexes[s].push(i);
  }

  return indexes;
}

Yup.addMethod(Yup.array, 'uniqueSkus', function (message) {
  return this.test('uniqueSkus', message, function (list) {
    const skuIndexes = mapIndexes(list.map(r => r.hs_sku));

    const errors = [];

    Object.values(skuIndexes).forEach( (indexes) => {
      if (indexes.length > 1) {
        indexes.forEach(index => {
          const error = this.createError({ path: `${this.path}[${index}].hs_sku`, message: 'SKU must be unique' });

          errors.push(error);
        });
      }
    });

    return new Yup.ValidationError(errors);
  });
});

export const PricingTierFormSchema = Yup.object().shape({
  min_distance: Yup.number().min(0, 'Min Distance must be at least 0'),
  max_distance: Yup.number().min(Yup.ref('min_distance'), 'Max Distance must be at least Min Distance'),
  min_sqft: Yup.number()
    .required('Min Sq Feet Required')
    .min(0, 'Min Sq Feet must be at least 0'),
  max_sqft: Yup.number()
    .required('Max Sq Feet Required')
    .min(Yup.ref('min_sqft'), 'Max Sq Ft must be at least Min Sq Ft'),
  base_price: Yup.number()
    .required('Base Price Required')
    .min(0, 'Base Price must be at least 0'),
  amount_monthly_percent: Yup.number()
    .required('Amount Monthly Required')
    .min(0, 'Amount Monthly (%) must be at least 0')
    .max(100, 'Amount Monthly (%) must be at most 100'),
  amount_fifteen_day_interval_percent: Yup.number()
    .required('Amount 15 Day Interval Required')
    .min(0, 'Amount 15 Day Interval (%) must be at least 0')
    .max(100, 'Amount 15 Day Interval (%) must be at most 100'),
  rooms: Yup.array(Yup.object({
    name: Yup.string().required('Room Name is required'),
    hs_sku: Yup.string().required('Room SKU is required'),
    price: Yup.number().required('Room Price is required').min(0, 'Room Price must be at least 0'),
    default: Yup.boolean()
  }))
    .required('Rooms Required')
    .uniqueSkus()
});
