import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import { InventoryDialog } from '@components/dialogs/InventoryDialog';
import { InventoryDialogMaker } from '@components/dialogs/InventoryDialogMaker';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import Sentry from '@integrations/Sentry';
import IconMoreHoriz from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import multiEditDefault from '@utils/multiEditDefault';
import { QueryDocumentSnapshot, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';

type InventoryActionsBatchProps = {
  product: QueryDocumentSnapshot<guesthouse.Product>
  inventory: QueryDocumentSnapshot<guesthouse.ProductInventory>[];
}

const InventoryActionsBatch: React.FC<InventoryActionsBatchProps> = (props) => {
  const { inventory, product } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const userContext = useContext<UserContext>(UserContext);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        edge="end"
        aria-label="actions"
        aria-haspopup="true"
        size="large"
        onClick={handleClick}
      >
        <IconMoreHoriz />
      </IconButton>

      <Menu
        keepMounted
        id="profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >

        <MenuItem
          onClick={() => {
            const initialValues = multiEditDefault(inventory.map(i => i.data() as guesthouse.ProductInventory));
            const Dialog = userContext?.roles?.maker ? InventoryDialogMaker : InventoryDialog;

            asyncRender(Dialog, {
              userContext,
              initialValues: initialValues,
              product: product,
              title: 'Edit Inventory',
              showQuantity: false,
            })
              .then(values => {
                const batch = writeBatch(database);

                for (const doc of inventory) {
                // @ts-ignore https://github.com/firebase/firebase-js-sdk/issues/5853
                  batch.update(doc.ref, values);
                }

                return batch.commit();
              })
              .then(() => notificationContext.setContext({ open: true, message: 'Inventory updated' }))
              .catch((e) => {
                if (e) {
                  Sentry.captureException(e);
                  notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                }
              });

            handleClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            asyncRender(ConfirmDialog, {
              title: 'Are you sure you want to delete this inventory?',
              confirmText: 'Yes, Delete',
            })
              .then(() => {
                const batch = writeBatch(database);

                for (const doc of inventory) {
                  batch.delete(doc.ref);
                }

                return batch.commit();
              })
              .then(() => notificationContext.setContext({ open: true, message: 'Inventory deleted' }))
              .catch((e) => {
                if (e) {
                  Sentry.captureException(e);
                  notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                }
              });

            handleClose();
          }}
        >
          Remove
        </MenuItem>
        <MenuItem
          onClick={async () => {
            const batch = writeBatch(database);

            for (const doc of inventory) {
              batch.update(doc.ref, { status: 'IN_STOCK' });
            }

            try {
              await batch.commit();
            } catch (e) {
              Sentry.captureException(e);
            }

            notificationContext.setContext({ open: true, message: 'Inventory delivered' });
            handleClose();
          }}
        >
          Mark Delivered
        </MenuItem>
        <MenuItem
          onClick={async () => {
            const batch = writeBatch(database);

            for (const doc of inventory) {
              batch.update(doc.ref, { status: 'IN_TRANSIT' });
            }

            try {
              await batch.commit();
            } catch (e) {
              Sentry.captureException(e);
            }

            notificationContext.setContext({ open: true, message: 'Inventory in transit' });
            handleClose();
          }}
        >
          Mark In Transit
        </MenuItem>
      </Menu>
    </div>
  );
};

export default InventoryActionsBatch;
