export const STAGING_TIMELINE_ITEMS: Partial<guesthouse.TimelineItem>[] = [
  {
    sku: 'STA',
    title: 'Staging install',
    name: 'install_date',
  },
  {
    sku: 'PHO',
    title: 'Photoshoot',
    name: 'photoshoot_date',
  },
  {
    sku: 'PHO',
    title: 'Photo & asset delivery',
    name: 'photo_asset_delivery',
  },
  {
    sku: 'STA',
    title: 'Staging de-install',
    name: 'deinstall_date',
  },
  {
    sku: 'STA',
    title: 'Review mood boards',
    name: 'review_moodboards',
  },
  {
    sku: 'STA',
    title: 'Upload mood boards',
    name: 'upload_moodboards',
  },
  {
    sku: 'STA',
    title: 'Add lockbox information',
    name: 'add_lockbox_info'
  },
  {
    sku: 'CON',
    title: 'Consultation',
    name: 'consult_date',
  },
  {
    sku: 'DRO',
    title: 'Drone footage',
    name: 'drone_footage',
  },
  {
    sku: 'VID',
    title: 'Video tour',
    name: 'video_tour',
  },
  {
    sku: 'FLO',
    title: 'Floorplan',
    name: 'floorplan',
  },
  {
    sku: 'MAT',
    title: 'Matterport (3D Tour)',
    name: 'matterport',
  },
  {
    sku: 'TWI',
    title: 'Twilight Photos',
    name: 'twilight_photos'
  },
  {
    sku: 'TPE',
    title: 'Virtual Twilight Photography Edit (5-pack)',
    name: 'twilight_photos_edit'
  },
  {
    sku: 'OTH',
    title: 'Other',
    name: 'other',
  },
];

export const CLIENT_TIMELINE_ITEMS: guesthouse.TimelineProductNames[] = [
  'add_lockbox_info',
  'review_moodboards'
];

export const DESIGNER_TIMELINE_ITEMS: guesthouse.TimelineProductNames[] = [
  'install_date',
  'photoshoot_date',
  'photo_asset_delivery',
  'consult_date',
  'deinstall_date',
  'upload_moodboards',
  'drone_footage',
  'video_tour',
  'floorplan',
  'matterport',
  'twilight_photos',
  'other'
];

// Timeline items that map to dates on the home
export const HOME_DATE_TIMELINE_ITEMS: guesthouse.TimelineProductNames[] = [
  'install_date',
  'deinstall_date',
  'consult_date',
  'photoshoot_date',
];
