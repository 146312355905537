import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import NoteDialog from '@components/dialogs/NoteDialog';
import Error from '@components/error/Error';
import Notes from '@components/notes/Notes';
import PhotoGrid from '@components/photo-grid/PhotoGrid';
import RoomSetSearchAddToRoom from '@components/roomset-search/RoomSetSearchAddToRoom';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import useDocumentBySlugOrID from '@hooks/useDocumentBySlugOrID';
import useRouter from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import IconPlus from '@mui/icons-material/Add';
import IconChevronLeft from '@mui/icons-material/ChevronLeft';
import IconDelete from '@mui/icons-material/Delete';
import IconEdit from '@mui/icons-material/Edit';
import IconShare from '@mui/icons-material/Share';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useCommonStyles from '@styles/common.style';
import Nl2br from '@ui/components/nl2br/Nl2br';
import Protected from '@ui/components/protected/Protected';
import useNavigiatorShareSupported from '@ui/hooks/useNavigatorShareSupported';
import checkRoles from '@ui/utils/checkRoles';
import getDataFromCollection from '@ui/utils/getDataFromCollection';
import slugOrID from '@ui/utils/slugOrID';
import makeAppUrl from '@utils/makeAppUrl';
import newId from '@utils/newId';
import { homePath, roomPath } from '@utils/paths';
import { addDoc, collection, collectionGroup, CollectionReference, deleteDoc, doc, Query, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import React, { useContext, useState } from 'react';

import RoomDialog from '../../components/dialogs/EditRoomDialog';
import RequestedProductRow from './RequestedProductRow';
import RequestedRoomSetRow from './RequestedRoomSetRow';
import useStyles from './Room.style';

interface RoomParams {
  id: string;
  homeId: string;
  tab: string;
}

const Room = () => {
  const router = useRouter<RoomParams>();
  const { classes, cx, theme } = useStyles();
  const { classes: common } = useCommonStyles();
  const userContext = useContext<UserContext>(UserContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const navigatorShareSupported = useNavigiatorShareSupported();
  const [addToRoomSetDialogOpen, setAddToRoomSetDialogOpen] = useState(false);

  const { id: roomSlug, homeId: homeSlug, tab } = router.params;

  const { id: homeId, doc: home } = useDocumentBySlugOrID<guesthouse.Home>(homeSlug, 'homes');

  const { id, loading, doc: room, exists: roomDocExists } = useDocumentBySlugOrID<guesthouse.Room>(roomSlug, `homes/${homeId}/rooms`);

  const heroStyle = {};

  let activeTab = 0;

  if (tab === 'roomsets') {
    activeTab = 1;
  } else if (tab === 'photos') {
    activeTab = 2;
  }

  const { collection: requests } = useCollection(
    query(collectionGroup(database, 'requests')
      ,where('room.docID', '==', id)
      ,where('controller', '==', true)) as CollectionReference<guesthouse.ProductRequest>,
    id
  );

  const { collection: roomSetsCollection } = useCollection(
    query(collection(database, 'roomsets'),
      where('stagedRooms', 'array-contains', id)) as CollectionReference<guesthouse.RoomSet>,
    id
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, tab: number) => {
    switch (tab) {
      case 0:
        activeTab = 0;
        router.push(roomPath(homeData, roomData));
        break;
      case 1:
        activeTab = 1;
        router.push(roomPath(homeData, roomData, 'roomsets'));
        break;
      case 2:
        activeTab = 2;
        router.push(roomPath(homeData, roomData, 'photos'));
        break;
    }
  };

  // TODO proper error and skeleton
  if (loading) {
    return null;
  }

  if (!roomDocExists) {
    return <Error statusCode={'404'} />;
  }

  const roomData = room?.data() as guesthouse.Room;
  const homeData = home?.data() as guesthouse.Home;
  const roomSets = getDataFromCollection(roomSetsCollection) as guesthouse.RoomSet[];

  return (
    <>
      <div
        className={classes.hero}
        style={heroStyle}
      >
        <div className={cx(common.contentSpacing, classes.heroContent)}>
          <div className={classes.heroBack}>
            <Button
              data-test="room-back-to-home-button"
              color="secondary"
              variant="contained"
              size="small"
              startIcon={<IconChevronLeft />}
              onClick={() => router.push(homePath(homeData, 'rooms'))}
            >
              Back to rooms
            </Button>
          </div>

          <Grid
            container
            className={classes.heroInfo}
          >
            <Grid
              item
              xs={12}
              md={5}
            >
              <Typography
                gutterBottom
                data-test="room-page-title"
                component="h1"
                variant="h3Alt"
              >
                {roomData?.title}
              </Typography>

              {roomData?.sqft && (
                <Typography
                  component="h2"
                  variant="h5Alt"
                >
                  {roomData?.sqft}
                  {' '}
                  SQFT
                </Typography>
              )}

              {roomData?.description && (
                <div style={{ marginTop: theme.spacing(2) }}>
                  <Typography style={{ color: theme.palette.common.black }}>
                    <Nl2br text={roomData?.description} />
                  </Typography>
                </div>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
            />

            <Grid
              item
              xs={12}
              md={5}
            >


              <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support', 'media_manager']}>
                <div style={{ marginBottom: theme.spacing(3) }}>
                  <Notes
                    id={homeId}
                    collection="homes"
                    subCollParentID={id}
                    subCollection="rooms"
                  />
                </div>
              </Protected>


              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >

                <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support', 'media_manager']}>
                  <Button
                    className={classes.heroButton}
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<IconPlus />}
                    onClick={() => {
                      asyncRender(NoteDialog)
                        .then((text: string) => {
                          if (text) {
                            const note: guesthouse.Note = {
                              created: Timestamp.fromDate(new Date()),
                              text,
                              owner: userContext.data,
                              docID: newId()
                            };

                            return addDoc(collection(database, `homes/${homeId}/rooms/${id}/notes`), note);
                          }
                        })
                        .catch((e) => {
                          if (e) {
                            Sentry.captureException(e);
                            notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                          }
                        });
                    }}

                  >
                    Add note
                  </Button>
                </Protected>

                <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support', 'media_manager']}>
                  <Dialog
                    fullWidth
                    open={addToRoomSetDialogOpen}
                    maxWidth="lg"
                    PaperProps={{
                      style: { height: '100%', maxHeight: '90vh' }
                    }}
                    onClose={() => setAddToRoomSetDialogOpen(false)}
                  >
                    <DialogContent>
                      <RoomSetSearchAddToRoom 
                        home={homeData}
                        room={roomData}
                        user={userContext.data}
                        setAddToRoomSetDialogOpen={setAddToRoomSetDialogOpen}
                      />
                    </DialogContent>
                  </Dialog>

                  <Button
                    className={classes.heroButton}
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<IconPlus />}
                    onClick={() => setAddToRoomSetDialogOpen(true)}
                  >
                    Add room set
                  </Button>
                </Protected>

                <Protected allowedRoles={['admin', 'design_manager', 'website_manager', () => userContext.data.verified]}>

                  <Tooltip
                    title={
                      roomData.published
                        ? 'Unpublish from marketplace'
                        : 'Publish to marketplace'
                    }
                  >
                    <span style={{ marginRight: theme.spacing(1) }}>
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        aria-label={
                          roomData.published
                            ? 'Unpublish from marketplace'
                            : 'Publish to marketplace'
                        }
                        onClick={() => {
                          const published = !roomData.published;
                          const message = published
                            ? 'Room published'
                            : 'Room unpublished';

                          updateDoc(room.ref, { published })
                            .then(() => notificationContext.setContext({ open: true, message }))
                            .catch((e) => {
                              Sentry.captureException(e);
                              notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                            });
                        }}
                      >
                        {roomData.published ? 'Published' : 'Publish'}
                      </Button>
                    </span>
                  </Tooltip>
                </Protected>

                <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
                  <Tooltip title="Edit room">
                    <IconButton
                      className={classes.heroButton}
                      onClick={() => {
                        asyncRender(RoomDialog, {
                          userContext,
                          initialValues: roomData,
                          homeID: homeId,
                          homeSlug: roomData?.home.slug || homeId,
                          onSubmit: async (values) => {
                            return await updateDoc(room.ref, values)
                              .then(() => {
                                if (slugOrID(roomData) !== slugOrID(values)) {
                                  router.replace(roomPath(homeData, values));
                                }
                              });
                          }
                        });
                      }}

                    >
                      <IconEdit />
                    </IconButton>
                  </Tooltip>
                </Protected>

                <Protected allowedRoles={['admin', 'designer', 'design_manager']}>
                  <Tooltip title="Permanently delete">
                    <IconButton
                      className={classes.heroButton}
                      onClick={() => {
                        asyncRender(ConfirmDialog, {
                          title: 'Delete Room',
                          content: 'Are you sure want to delete this room? This action cannot be undone.',
                          confirmText: 'Yes, Delete',
                        })
                          .then(async () => {
                            await deleteDoc(doc(collection(database, `homes/${homeId}/rooms`), id));
                          })
                          .then(() => router.push(homePath(homeData)))
                          .then(() => notificationContext.setContext({ open: true, message: 'Room deleted' }))
                          .catch((e) => {
                            Sentry.captureException(e);
                            notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                          });
                      }}
                    >
                      <IconDelete />
                    </IconButton>
                  </Tooltip>
                </Protected>

                {navigatorShareSupported && (
                  <Tooltip title="Share this page">
                    <span style={{ marginRight: theme.spacing(1) }}>
                      <IconButton
                        size="small"
                        aria-label="share"
                        className={classes.heroButton}
                        onClick={() => {
                          return navigator.share({
                            title: document.title,
                            url: makeAppUrl(roomPath(homeData, roomData)),
                          })
                            .catch(Sentry.captureException);
                        }}
                      >
                        <IconShare />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>

      <Grid
        container
        style={{ flex: 1 }}
      >
        <Grid
          item
          xs={12}
        >
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
          >
            <Tab label="Products" />

            <Tab label="Room sets" />

            <Tab label="Photos" />
          </Tabs>

          <div className={cx([common.contentSpacing, classes.tableWrapper])}>
            {activeTab === 0 && (
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 50 }}></TableCell>

                    <TableCell>
                      Name
                    </TableCell>

                    <TableCell>
                      Quantity
                    </TableCell>

                    <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
                      <TableCell>
                        Source
                      </TableCell>
                    </Protected>

                    <TableCell>
                      Maker
                    </TableCell>

                    <Protected
                      allowedRoles={['admin', 'designer', 'design_manager']}
                      renderUnauthorized={() => {
                        return (
                          <TableCell
                            align="right"
                            style={{ width: 200 }}
                          >
                            Status
                          </TableCell>
                        );
                      }}
                    >
                      <TableCell
                        align="right"
                        style={{ width: 200 }}
                      >
                        Price Sheet
                      </TableCell>

                      <TableCell align="right" >
                        Status
                      </TableCell>

                      <TableCell>

                      </TableCell>
                    </Protected>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    requests && requests.docs.map((request, i) => {
                      return (
                        <RequestedProductRow
                          key={i}
                          request={request}
                        />
                      );
                    })
                  }
                </TableBody>
              </Table>
            )}

            {activeTab === 1 && (
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 50 }}></TableCell>

                    <TableCell>
                      Title
                    </TableCell>

                    <TableCell>
                      Set Number
                    </TableCell>

                    <TableCell>

                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {roomSets?.map((roomSet, i) => {
                    const roomSetRequests = requests.docs.filter(request => (request?.data().inventory.roomSetId === roomSet.docID && request?.data().controller === true));

                    return (
                      <RequestedRoomSetRow
                        key={i}
                        room={room.data()}
                        roomSet={roomSet}
                        requests={roomSetRequests}
                      />
                    );
                  })
                  }
                </TableBody>
              </Table>
            )}

            {activeTab === 2 && (
              <PhotoGrid
                allowPrimaryPhoto
                uploadRoles={[]}
                home={homeData}
                room={roomData}
                enableTagging={checkRoles(['admin', 'designer', 'design_manager', 'website_manager', 'customer_support', 'media_manager'], userContext.roles)}
                query={
                  query(collection(doc(collection(database, 'homes'), homeId), 'photos')
                    , where('room.docID', '==', id)) as Query<guesthouse.Photo>
                }
                showHomeInfo={false}
                allowPrimaryPhotoDelete={false}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(Room);
