import { database } from '@app/firebase';
import useCollection from '@hooks/useCollection';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Autocomplete, Checkbox, FormControlLabel, IconButton, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import useCommonStyles from '@styles/common.style';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import newId from '@utils/newId';
import { collection, doc, query, setDoc } from 'firebase/firestore';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { omit } from 'lodash';
import React from 'react';

import { DefaultFirestoreConverter } from '../../../types/DefaultFirestoreConverter';
import { PricingTierFormSchema } from './PricingTierFormSchema';

interface PricingTierFormProps {
  pricingTier?: guesthouse.DbPricingTier
  resolve: (pricingTier?: guesthouse.DbPricingTier) => void
  reject: (e: Error) => void;
}

interface UIRoom extends guesthouse.PricingTierRoom {
  key: string // for uniqueness in the Table component
}

interface PricingTierFormValues extends guesthouse.DbPricingTier {
  rooms: UIRoom[]
  amount_monthly_percent: number
  amount_fifteen_day_interval_percent: number
}

const getInitialFormValues = (pricingTier: guesthouse.DbPricingTier | undefined): PricingTierFormValues => {
  const defaultFormValues = {
    docID: newId(),
    warehouse: null,
    min_distance: undefined,
    max_distance: undefined,
    min_sqft: undefined,
    max_sqft: undefined,
    base_price: undefined,
    amount_monthly: undefined,
    amount_monthly_percent: undefined,
    amount_fifteen_day_interval: undefined,
    amount_fifteen_day_interval_percent: undefined,
    rooms: []
  };

  if (!pricingTier) {
    return defaultFormValues;
  }

  return {
    ...omit(pricingTier, 'rooms'),
    rooms: pricingTier.rooms.map(x => ({ ...x, key: newId() })),
    amount_monthly_percent: pricingTier.amount_monthly * 100,
    amount_fifteen_day_interval_percent: pricingTier.amount_fifteen_day_interval * 100
  };
};


const PricingTierEditForm: React.FC<PricingTierFormProps> = ({ pricingTier, resolve }) => {
  const { theme } = useCommonStyles();

  const { collection: warehousesCollection } = useCollection(query(
    collection(database, 'warehouses')
      .withConverter(new DefaultFirestoreConverter<guesthouse.DbPricingTier>())
  ));
  const warehouses = warehousesCollection?.docs.map(snap => snap.data()) ?? [];

  const initialValues = getInitialFormValues(pricingTier);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={PricingTierFormSchema}
        onSubmit={async (values: PricingTierFormValues) => {
          const dbRooms = values.rooms.map(x => omit(x, 'key'));
          const updatedPricingTier = {
            ...omit(values, 'rooms', 'amount_monthly_percent', 'amount_fifteen_day_interval_percent'),
            rooms: dbRooms
          };
          const ref = doc(collection(database, 'pricing_tiers'), pricingTier.docID);

          await setDoc(ref, updatedPricingTier, { merge: true });
          resolve(updatedPricingTier);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* WAREHOUSE */ }
            <Autocomplete
              fullWidth
              id="warehouse"
              options={warehouses}
              disabled={pricingTier !== undefined}
              defaultValue={pricingTier?.warehouse}
              getOptionLabel={(option: Partial<guesthouse.Warehouse>) => option.name || ''}
              renderInput={(params) => {
                return (
                  <Field
                    {...params}
                    fullWidth
                    name="warehouse"
                    label="Warehouse"
                    type="text"
                    component={TextField}
                    margin="dense"
                    variant="outlined"
                    data-test="warehouse"
                  />
                );
              }}
              onChange={(_, value: guesthouse.Warehouse) => {
                setFieldValue('warehouse', value);
              }}
            />

            {/* MIN DISTANCE */}
            <Field
              fullWidth
              type="number"
              name="min_distance"
              label="Min Distance"
              margin="normal"
              value={values.min_distance}
              component={TextField}
              InputProps={{
                endAdornment:(
                  <InputAdornment position="end">
                    mi
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {/* MAX DISTANCE */}
            <Field
              fullWidth
              type="number"
              name="max_distance"
              label="Max Distance"
              margin="normal"
              value={values.max_distance}
              component={TextField}
              InputProps={{
                endAdornment:(
                  <InputAdornment position="end">
                    mi
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {/* MIN SQFT */}
            <Field
              fullWidth
              type="number"
              name="min_sqft"
              label="Min Sq Ft"
              margin="normal"
              value={values.min_sqft}
              component={TextField}
              InputProps={{
                endAdornment:(
                  <InputAdornment position="end">
                    sq. ft
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {/* MAX SQFT */}
            <Field
              fullWidth
              type="number"
              name="max_sqft"
              label="Max Sq Ft"
              margin="normal"
              value={values.max_sqft}
              component={TextField}
              InputProps={{
                endAdornment:(
                  <InputAdornment position="end">
                    mi
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {/* BASE PRICE */}
            <Field
              fullWidth
              type="number"
              name="base_price"
              label="Base Price"
              margin="normal"
              value={values.base_price}
              component={TextField}
              InputProps={{
                startAdornment:(
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {/* MONTHLY */}
            <Field
              fullWidth
              type="number"
              name="amount_monthly_percent"
              label="Amount Monthly"
              margin="normal"
              value={values.amount_monthly_percent}
              component={TextField}
              InputProps={{
                endAdornment:(
                  <InputAdornment position="end">
                    %
                  </InputAdornment>
                ),
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const percentValue = e.target.value || undefined;

                setFieldValue('amount_monthly_percent', percentValue);
                if (percentValue) {
                  setFieldValue('amount_monthly', Number(e.target.value) / 100);
                }
              }}
              onBlur={handleBlur}
            />

            {/* 15 DAYS */}
            <Field
              fullWidth
              type="number"
              name="amount_fifteen_day_interval_percent"
              label="Amount 15 Day Interval"
              margin="normal"
              component={TextField}
              InputProps={{
                endAdornment:(
                  <InputAdornment position="end">
                    %
                  </InputAdornment>
                ),
              }}
              value={values.amount_fifteen_day_interval_percent}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const percentValue = e.target.value || undefined;

                setFieldValue('amount_fifteen_day_interval_percent', percentValue);
                if (percentValue) {
                  setFieldValue('amount_fifteen_day_interval', Number(e.target.value) / 100);
                }
              }}
              onBlur={handleBlur}
            />

            {/* ROOMS */}
            <Typography
              id="pricing-tiers-edit-rooms-header"
              // TODO how to get this to be bold etc?
              component="h3"
              style={{ marginTop: theme.spacing(2) }}
            >
              Rooms
            </Typography>

            <Table style={{ marginBottom: theme.spacing(2) }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    SKU
                  </TableCell>
                  <TableCell>
                    Price
                  </TableCell>
                  <TableCell>
                    Default
                  </TableCell>
                  <TableCell id="create-delete-room-column-header">
                    <IconButton
                      aria-label="create room"
                      size="small"
                      onClick={() => {
                        const defaultRoom: UIRoom = {
                          hs_sku: undefined,
                          price: undefined,
                          name: undefined,
                          default: false,
                          key: newId()
                        };
                        // prepend so it shows up first in the for easy editing
                        const rooms = [defaultRoom, ...values.rooms];

                        setFieldValue('rooms', rooms);
                      }}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.rooms.map((room, roomIndex) => {
                  return (
                    <TableRow key={room.key}>
                      <TableCell>
                        <Field
                          name={`rooms[${roomIndex}].name`}
                          value={values.rooms[roomIndex].name}
                          component={TextField}
                          helperText={' '}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {room.name}
                        </Field>
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`rooms[${roomIndex}].hs_sku`}
                          value={values.rooms[roomIndex].hs_sku}
                          component={TextField}
                          helperText={' '}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {room.hs_sku}
                        </Field>
                      </TableCell>
                      <TableCell>
                        <Field
                          type="number"
                          name={`rooms[${roomIndex}].price`}
                          value={values.rooms[roomIndex].price}
                          component={TextField}
                          helperText={' '}
                          InputProps={{
                            startAdornment:(
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {room.price}
                        </Field>
                      </TableCell>
                      <TableCell>
                        <Field
                          type="checkbox"
                          name={`rooms[${roomIndex}].default`}
                          as={FormControlLabel}
                          control={<Checkbox/>}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="delete room"
                          size="small"
                          onClick={() => {
                            const rooms = values.rooms;

                            rooms.splice(roomIndex, 1);
                            setFieldValue('rooms', rooms);
                          }}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {/* Save */}
            {/* TODO: can we move this outside the form to look better with Cancel? */}
            <SubmitButton
              type="submit"
              disabled={Object.keys(errors).length > 0 || isSubmitting}
            >
              Save
            </SubmitButton>
            {isSubmitting && <CircularProgress />}
          </form>
        )}
      </Formik>
    </>
  );
};

export default PricingTierEditForm;
