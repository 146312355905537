import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import { InventoryDialog } from '@components/dialogs/InventoryDialog';
import { InventoryDialogMaker } from '@components/dialogs/InventoryDialogMaker';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import Sentry from '@integrations/Sentry';
import IconPlus from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Protected from '@ui/components/protected/Protected';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import toJsDate from '@ui/utils/toJsDate';
import newId from '@utils/newId';
import { collection, doc, QueryDocumentSnapshot, Timestamp, writeBatch } from 'firebase/firestore';
import React, { useContext, useMemo, useState } from 'react';

import InventoryActionsBatch from './InventoryActionsBatch';
import InventoryRow, { RenderActionsProps } from './InventoryRow';
import useStyles from './InventoryTable.style';


type InventoryTableProps = {
  product: QueryDocumentSnapshot<guesthouse.Product>
  inventory: QueryDocumentSnapshot<guesthouse.ProductInventory>[];
  style?: React.CSSProperties
  renderActions?: (props: RenderActionsProps) => React.ReactElement
}

const InventoryTable: React.FC<InventoryTableProps> = (props) => {
  const { product, inventory, style, renderActions } = props;
  const { theme } = useStyles();
  const userContext = useContext<UserContext>(UserContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const [selected, setSelected] = useState([]);
  const { md } = useBreakpoints();

  const selectedInventory = useMemo(() => {
    return inventory.filter(i => selected.indexOf(i.id) > -1);
  }, [selected]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(inventory.map((i) => i.id));
      return;
    }
    setSelected([]);
  };

  const handleSelectRowClick = (event, checked, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  return (
    <>
      <Toolbar
        style={{
          paddingLeft: 0,
          marginBottom: theme.spacing(3),
          paddingRight: 0,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent={md ? '' : 'space-between'}
        >
          <Typography
            variant="h5Alt"
            style={{ marginRight: theme.spacing(2) }}
          >
            Inventory
          </Typography>

          {(md || selected.length === 0) && (
            <Button
              data-test="product-add-inventory"
              variant="contained"
              color="secondary"
              size="small"
              aria-label="add inventory"
              style={{ marginRight: theme.spacing(2) }}
              startIcon={<IconPlus />}
              onClick={() => {
                const Dialog = userContext?.roles?.maker ? InventoryDialogMaker : InventoryDialog;

                asyncRender(Dialog, { userContext, product })
                  .then(async (values) => {
                    const productData = product.data();
                    const created = Timestamp.fromDate(new Date());
                    const batch = writeBatch(database);
                    const collectionRef = collection(database, `products/${product.id}/inventory`);
                    let documentData: Partial<guesthouse.ProductInventory>;

                    delete productData.pastHomes;
                    delete productData.currentHomes;
                    delete productData.futureHomes;

                    let status: guesthouse.ProductInventoryStatus = 'IN_STOCK';

                    if (values.offer?.dropship) {
                      status = 'DROPSHIP';
                    }

                    if (values.arrival_date && toJsDate(values.arrival_date) >= new Date()) {
                      status = 'IN_TRANSIT';
                    }

                    if (values.warehouse) {
                      documentData = {
                        created,
                        offer: values.offer,
                        status,
                        product: productData,
                        warehouse: values.warehouse,
                        arrival_date: values.arrival_date,
                        purchase_date: values.purchase_date,
                        purchase_price: values.purchase_price
                      };
                    } else {
                      const owner = productData.owner ? productData.owner : userContext.data;

                      documentData = {
                        created,
                        offer: values.offer,
                        status,
                        product: productData,
                        warehouse: {
                          ...owner?.location,
                          guesthouseManaged: false,
                        },
                        arrival_date: values.arrival_date,
                        purchase_date: values.purchase_date,
                        purchase_price: values.purchase_price
                      };
                    }

                    new Array(values.quantity)
                      .fill(0)
                      .forEach(() => {
                        const id = newId();

                        batch.set(doc(collectionRef, id), { docID: id, ...documentData });
                      });

                    return batch.commit();
                  })
                  .catch((e) => {
                    if (e) {
                      Sentry.captureException(e);
                      notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                    }
                  });
              }}
            >
              Add inventory
            </Button>
          )}

          {
            selected.length > 0 && (
              <Box
                display="flex"
                alignItems="center"
              >
                <Typography style={{ marginRight: theme.spacing(1), fontSize: 14, fontFamily: theme.gh_vars.circular }}>
                  {selected.length}
                  {' '}
                  selected:
                </Typography>
                <InventoryActionsBatch
                  inventory={selectedInventory}
                  product={product}
                />
              </Box>
            )
          }
        </Box>
      </Toolbar>

      {
        inventory?.length
          ? (
            <TableContainer>
              <Table
                aria-label="inventory table"
                style={style}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={selected.length > 0 && selected.length < inventory.length}
                        checked={inventory.length > 0 && selected.length === inventory.length}
                        inputProps={{ 'aria-label': 'select all inventory' }}
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    <TableCell>
                      Status
                    </TableCell>
                    <TableCell>
                      Warehouse
                    </TableCell>
                    <TableCell>
                      Arrival Date
                    </TableCell>
                    <TableCell>
                      Home
                    </TableCell>
                    <TableCell>
                      Room
                    </TableCell>
                    <TableCell>
                      Schedule Date
                    </TableCell>
                    <TableCell>
                      Purchase Date
                    </TableCell>
                    <TableCell>
                      Purchase Price
                    </TableCell>
                    <TableCell>
                      Price
                    </TableCell>
                    <TableCell>
                      Room Set
                    </TableCell>
                    <TableCell align="center">
                      History
                    </TableCell>
                    {props.renderActions && (
                      <Protected allowedRoles={['designer', 'admin', 'design_manager', 'website_manager', 'customer_support', 'maker']}>
                        <TableCell align="center">
                          Actions
                        </TableCell>
                      </Protected>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inventory
                    .sort((a, b) => {
                      const _a = a.data() as guesthouse.ProductInventory;
                      const _b = b.data() as guesthouse.ProductInventory;

                      if (!_a.warehouse) {
                        return -1;
                      }

                      if (_a.warehouse?.address < _b.warehouse?.address) {
                        return -1;
                      }
                      if (_a.warehouse?.address > _b.warehouse?.address) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((inventoryItem) => {
                      return (
                        <InventoryRow
                          key={inventoryItem.id}
                          handleSelectRowClick={handleSelectRowClick}
                          selected={selected.indexOf(inventoryItem.id) !== -1}
                          inventory={inventoryItem}
                          product={product}
                          renderActions={renderActions}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography style={style}>
              This product has no available inventory.
            </Typography>
          )
      }
    </>
  );
};

export default InventoryTable;
