import PrimaryImage from '@components/primary-image/PrimaryImage';
import useDocument from '@hooks/useDocument';
import useTimeline from '@hooks/useTimeline';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import OrdersQuickView from '@pages/homes/OrdersQuickView';
import TimelineQuickView from '@pages/homes/TimelineQuickView';
import HomeStatusChip from '@ui/components/cards/HomeStatusChip';
import Protected from '@ui/components/protected/Protected';
import UserAvatar from '@ui/components/user-avatar/UserAvatar';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import formatDate from '@ui/utils/formatDate';
import parseAddress from '@ui/utils/parseAddress';
import staticMap from '@ui/utils/staticMap';
import userFullName from '@ui/utils/userFullName';
import calculateDaysOnMarket from '@utils/calculateDaysOnMarket';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import HomeRowCardProps from './HomeRowCard.props';
import useStyles from './HomeRowCard.style';
import { HomeRowCardColumnWidth } from './HomeRowCardColumnWidth';

const HomeRowCard: React.FC<HomeRowCardProps> = (props) => {
  const { renderActions, href, onClick, home: homeAlgolia, style } = props;
  const { classes, cx, theme } = useStyles();
  const { short, parsed } = parseAddress(homeAlgolia.address);
  const { md, lg } = useBreakpoints();
  const { city, state } = parsed;

  const timelineData = useTimeline(homeAlgolia.docID);
  const { upcoming, complete } = timelineData;

  const timelineItemsComplete = complete.length;
  const timelineItemsLength = upcoming.length + complete.length;

  const { doc: homeDoc } = useDocument(`homes/${homeAlgolia.docID}`);

  const home = useMemo(() => homeDoc?.data() as guesthouse.Home, [homeDoc]);

  const daysOnMarket = calculateDaysOnMarket(homeAlgolia);

  if (!md) {
    return (
      <Link
        to={typeof href === 'function' ? href(homeAlgolia) : null}
        style={{
          textDecoration: 'none',
        }}
      >
        <Box
          display="flex"
          width="100%"
          className={classes.smCardWrapper}
          style={style}
          onClick={() => typeof onClick === 'function' && onClick(homeAlgolia)}
        >
          <div className={classes.imageAvatarWrapper}>
            <div className={classes.imageWrapper}>
              <PrimaryImage
                style={{
                  objectFit: 'cover',
                }}
                item={home}
                fallback={staticMap(homeAlgolia.address, {
                  zoom: 16,
                  size: '300x300'
                })}
              />
              <div
                style={{
                  position: 'absolute',
                  top: theme.spacing(.75),
                  right: theme.spacing(1),
                }}
              >
                <HomeStatusChip
                  status={homeAlgolia.status}
                />
              </div>
            </div>
            <div className={classes.avatarWrapper}>
              {home?.client && (
                <Tooltip title={`${userFullName(home?.client, false)}, client`}>
                  <div
                    className={classes.avatar}
                  >
                    <UserAvatar
                      user={home?.client}
                      size={24}
                      className={classes.avatar}
                      avatarStyle={{
                        fontSize: 11
                      }}
                    />
                  </div>
                </Tooltip>
              )}
              {home?.realtor && (
                <Tooltip title={`${userFullName(home?.realtor, false)}, realtor`}>
                  <div
                    className={classes.avatar}
                  >
                    <UserAvatar
                      user={home?.realtor}
                      size={24}
                      className={classes.avatar}
                      avatarStyle={{
                        fontSize: 11
                      }}
                    />
                  </div>
                </Tooltip>
              )}
              {home?.designer && (
                <Tooltip title={`${userFullName(home?.designer, false)}, designer`}>
                  <div
                    className={classes.avatar}
                  >
                    <UserAvatar
                      user={home?.designer}
                      size={24}
                      className={classes.avatar}
                      avatarStyle={{
                        fontSize: 11
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <div className={cx(classes.smAddressActionStatusWrapper, classes.addresses)}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                component="div"
                fontFamily={theme.gh_vars.circular}
                className={cx(classes.shortAddress, classes.addresses)}
              >
                {short}
                {homeAlgolia.unit && `, Unit ${homeAlgolia.unit}`}
              </Typography>
              {typeof renderActions === 'function' && (
                <div
                  style={{
                    position: 'relative',
                    top: -2,
                  }}
                >
                  {renderActions(homeAlgolia, timelineData)}
                </div>
              )}
            </Box>
            <Typography
              component="div"
              color="textSecondary"
              fontFamily={theme.gh_vars.circular}
              className={cx(classes.cityState, classes.addresses)}
            >
              {city && state && (
                <>
                  {`${city}, ${state}`}
                </>
              )}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="left"
              flexWrap="wrap"
              flex={1.2}
              height={24}
              width="100%"
              style={{
                marginBottom: theme.spacing(1.5),
              }}
            >
              <div
                className={classes.installDate}
              >
                {homeAlgolia.install_date && (
                  `${formatDate(homeAlgolia.install_date, 'P')}`
                )}
                {homeAlgolia.deinstall_date && (
                  ` - ${formatDate(homeAlgolia.deinstall_date, 'P')}`
                )}
              </div>
              <Protected allowedRoles={['admin', 'design_manager', 'designer', 'website_manager', 'customer_support']}>
                <div>
                  <TimelineQuickView home={homeAlgolia} />
                  <OrdersQuickView home={homeAlgolia} />
                </div>
              </Protected>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              height={24}
            >
              <LinearProgress
                variant="determinate"
                value={timelineItemsLength ? 100 / timelineItemsLength * timelineItemsComplete : 0}
                style={{
                  width: '100%',
                  height: 2,
                }}
              />
              <div
                style={{
                  marginLeft: theme.spacing(2),
                  color: '#171E1F',
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                {`${timelineItemsComplete}/${timelineItemsLength}`}
              </div>
            </Box>
          </div>
        </Box>
      </Link>
    );
  } else {
    return (
      <Link
        to={typeof href === 'function' ? href(homeAlgolia) : null}
        className={classes.lgCardContainer}
        style={{
          textDecoration: 'none',
          display: 'flex',
          ...style,
        }}
      >

        <div
          style={{
            display: 'flex',
            height: '100%',
            flex: HomeRowCardColumnWidth.ADDRESS,
          }}
        >
          <div className={classes.imageWrapper}>
            <PrimaryImage
              style={{
                objectFit: 'cover',

              }}
              item={homeAlgolia}
              fallback={staticMap(homeAlgolia.address, {
                zoom: 16,
                size: '300x300'
              })}
            />
          </div>
          <div
            className={classes.lgAddressesWrapper}
          >
            <Typography
              component="div"
              fontFamily={theme.gh_vars.circular}
              className={cx(classes.shortAddress, classes.addresses)}
            >
              {short}
            </Typography>
            <Typography
              component="div"
              fontFamily={theme.gh_vars.circular}
              color="textSecondary"
              className={cx(classes.cityState, classes.addresses)}
            >

              {city && state && (
                <>
                  {`${city}, ${state}`}
                </>
              )}
            </Typography>
          </div>
        </div>
        <div
          style={{
            flex: HomeRowCardColumnWidth.TEAM,
            height: '100%',
            display: 'inline-flex',
            overflow: 'auto',
          }}
        >
          {home?.client && (
            <Tooltip title={`${userFullName(home?.client, false)}, client`}>
              <div
                className={classes.avatar}
              >
                <UserAvatar
                  user={home?.client}
                  size={!lg ? 26 : 32}
                  avatarStyle={{
                    fontSize: !lg ? 11 : 12
                  }}
                />
              </div>
            </Tooltip>
          )}
          {home?.realtor && (
            <Tooltip title={`${userFullName(home?.realtor, false)}, realtor`}>
              <div
                className={classes.avatar}
              >
                <UserAvatar
                  user={home?.realtor}
                  size={!lg ? 26 : 32}
                  avatarStyle={{
                    fontSize: !lg ? 11 : 12
                  }}
                />
              </div>
            </Tooltip>
          )}
          {home?.designer && (
            <Tooltip title={`${userFullName(home?.designer, false)}, designer`}>
              <div
                className={classes.avatar}
              >
                <UserAvatar
                  user={home?.designer}
                  size={!lg ? 26 : 32}
                  avatarStyle={{
                    fontSize: !lg ? 11 : 12
                  }}
                />
              </div>
            </Tooltip>
          )}
        </div>

        <div
          style={{
            flex: HomeRowCardColumnWidth.DATES,
            height: '100%',
          }}
        >
          <div
            className={classes.verticalCenter}
          >
            {homeAlgolia.install_date && (
              `${formatDate(homeAlgolia.install_date, 'P')}`
            )}
          </div>
        </div>

        <div
          style={{
            flex: HomeRowCardColumnWidth.DATES,
            height: '100%',
          }}
        >
          <div
            className={classes.verticalCenter}
          >
            {homeAlgolia.deinstall_date && (
              `${formatDate(homeAlgolia.deinstall_date, 'P')}`
            )}
          </div>
        </div>

        <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
          <div
            style={{
              flex: HomeRowCardColumnWidth.DATES,
              height: '100%',
            }}
          >
            <div
              className={classes.verticalCenter}
            >
              {daysOnMarket === 0 ? 'Install upcoming' : daysOnMarket}
            </div>
          </div>
        </Protected>

        {typeof renderActions === 'function' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '100%',
              flex: HomeRowCardColumnWidth.ACTION_ITEMS
            }}
          >
            {renderActions(homeAlgolia, timelineData)}
          </div>
        )}
      </Link>
    );
  }
};

export default HomeRowCard;
