import isEqual from 'lodash.isequal';
import uniqBy from 'lodash.uniqby';
import uniqWith from 'lodash.uniqwith';

export default function multiEditDefault<T = Record<string, any>>(rows : Array<T>) : Partial<T> {
  const initialValues = {};
  let keys = [];

  for (const r in rows) {
    for (const key in rows[r]) {
      keys.push(key);
    }
  }

  keys = uniqBy(keys, i => i);

  for (const key of keys) {
    const rowValues = rows.map(r => r[key]);
    const uniqueRows = uniqWith(rowValues, isEqual);

    if (uniqueRows.length === 1) {
      initialValues[key] = uniqueRows[0];
    }
  }

  return initialValues;
}
