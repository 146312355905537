import { Timestamp } from 'firebase/firestore';

const toJsDate = (date: string | Date | Timestamp): Date | undefined => {
  if (date) {
    if (date instanceof Date) {
      return date;
    } else if (typeof date === 'string' && /\d{2}-\d{2}-\d{4}/g.test(date)) {
      return new Date(date.replace(/-/g, '/'));
    } else if (typeof date === 'string') {
      return new Date(date);
    } else if (typeof date === 'object' && typeof date.seconds === 'number' && typeof date.nanoseconds === 'number') {
      return new Timestamp(date.seconds, date.nanoseconds).toDate();
      // @ts-ignore
    } else if (typeof date === 'object' && typeof date._seconds === 'number' && typeof date._nanoseconds === 'number') {
      // @ts-ignore
      return new Timestamp(date._seconds, date._nanoseconds).toDate();
    } else if (typeof date === 'object' && typeof date.toDate === 'function') {
      return date.toDate();
    }
  }

  return undefined;
};

export default toJsDate;
