import { Theme } from '@mui/material';

export default (theme: Theme) => `
  @import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono:300,400,500,700|Raleway:300,400,500,600,700,800,900');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

  html, body {
    font-family: ${theme.typography.fontFamily};
    color: ${theme.palette.text.primary};
    padding: 0;
    margin: 0;
    transition: background 1s;
    min-width: 100vw;
    min-height: 100vh;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  a {
    color: ${theme.palette.common.black};
  }

  #main {
    position: absolute; 
    top: 0;
    right: 0;
    bottom: 0; 
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
  }

  .fade-in {
    animation-name: fade-in;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .print-frame {
    opacity: 0;
    position: fixed;
    z-index: -9999;
  }

  @media print {
    .print-frame {
      opacity: 1;
    }
  }
`;
