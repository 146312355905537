import useDocument from '@hooks/useDocument';
import useRouter from '@hooks/useRouter';
import SvgGuesthouseLogoIconColor from '@images/guesthouse-logo-icon-color';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import StagingOrder from '@ui/components/staging-quote/StagingOrder';
import { useStyles } from '@ui/utils/makeStyles';
import { unparseAddress } from '@ui/utils/shortAddress';
import React, { } from 'react';
import { GlobalStyles } from 'tss-react/GlobalStyles';

interface ProductParams {
  orderID: string;
}

export default function StagingReceipt() {
  const { theme } = useStyles();
  const router = useRouter<ProductParams>();
  const { doc, loading } = useDocument(`orders/${router.params.orderID}`);

  const order = doc?.exists() && doc.data() as guesthouse.StagingOrder;

  if (loading) {
    return (
      <Box>
        <Box
          display="flex"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  if (order) {
    const billingInfo = unparseAddress({
      firstname: order.address.billing_firstname,
      lastname: order.address.billing_lastname,
      phone: order.address.billing_phone,
      company: order.address.billing_company,
      address_1: order.address.billing_address_1,
      address_2: order.address.billing_address_2,
      city: order.address.billing_city,
      state: order.address.billing_state,
      country: order.address.billing_country,
      postal_code: order.address.billing_postal_code,
    });

    return (
      <>
        <GlobalStyles
          styles={{
            '#price-sheet-print-button': {
              display: 'none',
            },
            '#profile-menu': {
              display: 'none',
            }
          }}
        />
        <Box padding={4}>
          <Box
            maxWidth={550}
            marginLeft="auto"
            marginRight="auto"
          >
            <SvgGuesthouseLogoIconColor
              style={{
                width: 55,
                height: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                marginBottom: theme.spacing(4)
              }}
            />
            <Typography
              component="h1"
              variant="h4Alt"
              align="center"
              style={{ marginBottom: theme.spacing(2) }}
            >
              Guest House Order Receipt
            </Typography>
          </Box>

          <Divider

            style={{
              margin: theme.spacing(4, 0, 4, 0)
            }}
          />

          <Box
            maxWidth={550}
            marginLeft="auto"
            marginRight="auto"
          >
            <Typography
              color="textSecondary"
              style={{ fontSize: 12, fontFamily: theme.gh_vars.circular, margin: theme.spacing(4, 0, 1) }}
            >
              Order #
              {order.deal?.id}
              <br />
              {order.address.billing_firstname}
              {' '}
              {order.address.billing_lastname}
              <br />
              {billingInfo}
            </Typography>

            <Typography style={{ margin: theme.spacing(2, 0), fontSize: 22, fontFamily: theme.gh_vars.circular, fontWeight: 500, color: theme.palette.common.black }}>
              {order.deal.properties?.staging_address}
            </Typography>

            {order &&
              <StagingOrder
                order={order}
              />
            }
          </Box>
        </Box>
      </>
    );
  }

  return null;
}
