import CONFIG from '@config';

type CheckFlagPermissions = (flags: guesthouse.Flags) => boolean;

export type AllowedFlags = Array<string | CheckFlagPermissions>;

export default function checkFlags(allowedFlags: AllowedFlags = [], userFlags: guesthouse.Flags = {}) : boolean {
  const authFlags = userFlags;
  const flags = Object.keys(authFlags).filter(k => authFlags[k]);

  if (CONFIG?.flagOverrides) {
    const overrides = Object.keys(CONFIG.flagOverrides).filter(k => CONFIG.flagOverrides[k]);
    const overridden = overrides.some(r => allowedFlags.includes(r));

    if (overridden) {
      return true;
    }
  }

  const fns = allowedFlags.filter(f => typeof f === 'function');

  for (const fn of fns) {
    if (typeof fn === 'function' && fn(authFlags)) {
      return true;
    }
  }

  return flags.some(r=> allowedFlags.includes(r));
}
