import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import theme from '../../theme';
import useStyles from './NotificationPreferences.style';

interface NotificationPreferencesProps {
  handleNotificationPreferences: (preferences: guesthouse.NotificationPreferences) => Promise<unknown>;
  user: Partial<guesthouse.User>;
}

const NotificationPreferences = (props: NotificationPreferencesProps) => {
  const { handleNotificationPreferences, user } = props;
  const { classes } = useStyles();
  const [error, setError] = useState<Error>();

  const setNotificationPreference = (notificationType: string, preference: string) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPreferences = {
      ...user.notificationPreferences,
      [notificationType]:
      {
        ...user.notificationPreferences[notificationType],
        [preference]: event.target.checked
      }
    };

    try {
      await handleNotificationPreferences(newPreferences);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <div>

      {error &&
        <Typography
          color="error"
          fontSize="12"
        >
          {error.message}
        </Typography>
      }
      <Typography
        variant="h5Alt"
      >
        Messages
      </Typography>

      <Typography
        color="textSecondary"
        style={{ fontSize: 13, marginTop: theme.spacing(1) }}
      >
        Receive important messages from project managers on the Guest House team to help keep your staging project on track.
      </Typography>

      <div className={classes.notificationPreference}>
        <Typography>
          Emails
        </Typography>
        <Switch
          value={user?.notificationPreferences?.messages?.email}
          checked={user?.notificationPreferences?.messages?.email}
          onChange={setNotificationPreference('messages', 'email')}
        />
      </div>

      <div className={classes.notificationPreference}>
        <Typography>
          Text Messages
        </Typography>
        <Switch
          value={user?.notificationPreferences?.messages?.sms}
          checked={user?.notificationPreferences?.messages?.sms}
          onChange={setNotificationPreference('messages', 'sms')}
        />
      </div>

      <Typography
        variant="h5Alt"
        style={{ marginTop: theme.spacing(4) }}
      >
        Timeline reminders
      </Typography>

      <Typography
        color="textSecondary"
        style={{ fontSize: 13, marginTop: theme.spacing(1) }}
      >
        Receive schedule reminders, requests to complete important tasks, payment notifications and other communication related to your project.
      </Typography>

      <div className={classes.notificationPreference}>
        <Typography>
          Emails
        </Typography>
        <Switch
          value={user?.notificationPreferences?.timeline_reminders?.email}
          checked={user?.notificationPreferences?.timeline_reminders?.email}
          onChange={setNotificationPreference('timeline_reminders', 'email')}
        />
      </div>

      <div className={classes.notificationPreference}>
        <Typography>
          Text Messages
        </Typography>
        <Switch
          value={user?.notificationPreferences?.timeline_reminders?.sms}
          checked={user?.notificationPreferences?.timeline_reminders?.sms}
          onChange={setNotificationPreference('timeline_reminders', 'sms')}
        />
      </div>

    </div>
  );
};

export default NotificationPreferences;
