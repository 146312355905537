import ProductCard from '@components/cards/ProductCard';
import PDFPreview from '@components/pdf-previews/PDFPreview';
import useDocument from '@hooks/useDocument';
import makeStoreUrl from '@ui/utils/makeStoreUrl';
import slugOrID from '@ui/utils/slugOrID';
import React, { useMemo } from 'react';
import { Attachment, AttachmentProps } from 'stream-chat-react';

import useStyles from './CustomAttachment.styles';

const CustomAttachment: React.FC<AttachmentProps> = (props) => {
  const { attachments } = props;
  const { classes } = useStyles();

  const { doc } = useDocument(`products/${attachments?.[0]?.docID}`);

  const product: guesthouse.Product = useMemo(()=>{
    const ret = doc?.data() as guesthouse.Product;

    return ret;
  },[doc]);

  if (attachments[0]?.type === 'product') {
    return (
      <ProductCard 
        product={product}
        className={classes.productCard}
        externalLink={makeStoreUrl(`/shop/products/${slugOrID(product)}`)}
        showMaker={true}
      />
    );
  }

  if (attachments[0]?.mime_type === 'application/pdf') {
    const pdfAttachment = attachments[0];

    return (
      <PDFPreview
        pdfUrl={pdfAttachment.asset_url} 
        pdfTitle={pdfAttachment.title}
        className={classes.pdf}
      />
    );
  }
  return <Attachment {...props} />;
};

export default React.memo(CustomAttachment);
