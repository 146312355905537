import AppContext from '@context/AppContext';
import UserContext from '@context/UserContext';
import { CATEGORIES } from '@data';
import useInventoryFilterSearch from '@hooks/useInventoryFilterSearch';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StopIcon from '@mui/icons-material/Stop';
import { Checkbox, FormControlLabel, IconButton, Switch } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import { addFilter, initPagedResults, removeFilter, removeSubcategoryFilters } from '@utils/algoliaSearch';
import properCase from '@utils/properCase';
import { startOfDay } from 'date-fns';
import { isEqual } from 'lodash';
import React, { useContext, useEffect,useState } from 'react';

import useStyles from './Inventory.style';

const InventoryPage = () => {
  const { classes, cx, theme } = useStyles();
  const { md } = useBreakpoints();
  
  const userContext = useContext(UserContext);
  const appContext = useContext<AppContext>(AppContext);

  const [categoryMap, setCategoryMap] = useState<Record<string, number>>({});
  const [openFilters, setOpenFilters] = useState(false);

  const {
    startDate,
    setStartDate,
    categoriesOpen,
    setCategoriesOpen,
    setPage,
    setInventory,
    filters,
    results,
  } = useInventoryFilterSearch(userContext, appContext);

  useEffect(() => {
    if (Object.keys(filters?.category2?.counts || {})?.length) {
      const newMap = {
        ...filters?.category2?.counts,
        ...categoryMap,
      };

      if (!isEqual(newMap, categoryMap)) {
        setCategoryMap(() => newMap);
      }
    }
  }, [filters?.category2?.counts]);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', height: '100%' }}
    >
      <div
        className={cx({
          [classes.filtersContainer]: true,
          [classes.filtersContainerOpen]: openFilters && !md,
        })}
      >
        {!md ?
          <div className={classes.filtersContainerHeader}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                style={{ color: theme.palette.common.black }}
                size="large"
                onClick={() => setOpenFilters(false)}
              >
                <CloseIcon style={{ height: 30, width: 30 }} />
              </IconButton>
              <Typography
                fontFamily={theme.gh_vars.circular}
                style={{ fontSize: 24, display: 'inline-block' }}
              >
                Filter results
              </Typography>
            </div>
            <Typography
              fontFamily={theme.gh_vars.circular}
              style={{ fontSize: 16, marginTop: theme.spacing(2), marginRight: theme.spacing(2) }}
            >
              {results.nbHits}
              {' '}
              Items
            </Typography>
          </div>
          : null
      }
        <div className={classes.filtersContainerInner}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{ padding: md ? theme.spacing(0.5, 3) : theme.spacing(0.5, 2), marginBottom: theme.spacing(2) }}
          >
            <Typography
              className={classes.filterText}
            >
              Date needed
            </Typography>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {startDate ?
                <IconButton
                  style={{ color: theme.palette.common.black, padding: 0, marginRight: md ? theme.spacing(1) : theme.spacing(1) }}
                  size="large"
                  onClick={() => {
                    setStartDate(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>
                : null}

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disablePast
                  value={new Date(Number(startDate?.toString()) * 1000) || null}
                  data-test="install_date"
                  slotProps={{
                    inputAdornment: { position: 'start' },
                    textField: {
                      style:{
                        backgroundColor: '#fff',
                        width: md ? 160 : 130,
                        borderRadius: theme.shape.borderRadius,
                        margin: 0
                      },
                      variant: 'outlined',
                      margin: 'dense',
                      helperText: '',
                      placeholder: '__/__/____',
                      size: 'small'
                    }
                  }}
                  onChange={(date) => {
                    if (date) {
                      setStartDate(String(startOfDay(date).getTime() / 1000));
                    } else {
                      setStartDate(null);
                    }
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <ButtonBase
            className={classes.filterButtonBase}
            onClick={() => setCategoriesOpen(!categoriesOpen)}
          >
            Categories
            <KeyboardArrowDownIcon
              className={cx({
                [classes.chevronIcon]: true,
                [classes.chevronIconFlipped]: categoriesOpen
              })}
            />
          </ButtonBase>

          <Collapse in={categoriesOpen}>
            {CATEGORIES.map((category, i) => {
              return (
                <div key={i}>
                  <ButtonBase
                    className={classes.subfilterButtonBase}
                    onClick={() => {
                      if (filters.category.values?.includes(category.id)) {
                        removeFilter(filters.category.setter, filters.category.values, category.id);
                        removeSubcategoryFilters(filters.category, filters.category2, category.id);
                      } else {
                        setPage(0);
                        setInventory(initPagedResults());

                        addFilter(filters.category.setter, filters.category.values, category.id);
                      }
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Typography className={classes.subFilterText}>
                        {category.title}
                      </Typography>

                      <Switch
                        className={classes.filterSwitch}
                        checked={!!filters.category.values?.includes(category.id)}
                        value={!!filters.category.values?.includes(category.id)}
                        name="verified"
                        color="primary"
                      />
                    </Box>
                  </ButtonBase>


                  <Collapse in={filters.category.values?.includes(category.id)}>
                    <div className={classes.subcategoryCollapseWrapper}>

                      {filters.category.values?.includes(category.id) && category.subcategories?.map((subcategory, i) => {
                        return (
                          <FormControlLabel
                            key={i}
                            style={{ width: '100%', marginRight: 0 }}
                            classes={{
                              root: classes.formControlRoot,
                              label: classes.formControlLabel,
                            }}
                            control={
                              <Checkbox
                                checked={!!filters.category2.values?.includes(subcategory.id)}
                                checkedIcon={
                                  <StopIcon
                                    style={{
                                      color: theme.palette.tertiary.main,
                                      backgroundColor: theme.palette.tertiary.main,
                                      transform: 'scale(0.75)',
                                      borderRadius: 4
                                    }}
                                  />
                                }
                                style={{
                                  color: '#83a5b0',
                                }}
                                onChange={() => {
                                  if (filters.category2.values?.includes(subcategory.id)) {
                                    removeFilter(filters.category2.setter, filters.category2.values, subcategory.id);
                                  } else {
                                    setPage(0);
                                    setInventory(initPagedResults());

                                    addFilter(filters.category2.setter, filters.category2.values, subcategory.id);
                                  }
                                }}
                              />
                            }
                            label={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Typography
                                  className={classes.subcategoryText}
                                  style={{ width: '100%', flex: 1, }}
                                >
                                  {properCase(subcategory.id, '-', true)}
                                </Typography>
                                <Typography
                                  className={classes.subcategoryText}
                                  style={{ position: 'relative', right: -11, width: 'auto' }}
                                >
                                  {' '}
                                  (
                                  {filters?.category2?.counts?.[subcategory?.id] ? filters.category2.counts[subcategory.id] : categoryMap[subcategory.id] ?? 0}
                                  )
                                  {' '}
                                </Typography>
                              </Box>
                            }
                          />
                        );
                      })}
                    </div>
                  </Collapse>
                </div>
              );
            })}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default React.memo(InventoryPage);
