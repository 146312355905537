import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import StagingAgreementDialog from '@components/dialogs/StagingAgreementDialog';
import StagingSubscription from '@components/staging-subscription/StagingSubscription';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import Typography from '@mui/material/Typography';
import routes from '@routes';
import Protected from '@ui/components/protected/Protected';
import StagingOrder from '@ui/components/staging-quote/StagingOrder';
import checkRoles from '@ui/utils/checkRoles';
import getDataFromCollection from '@ui/utils/getDataFromCollection';
import { useStyles } from '@ui/utils/makeStyles';
import makeAppUrl from '@utils/makeAppUrl';
import { collection, query, where } from 'firebase/firestore';
import React, { useContext } from 'react';
import { generatePath } from 'react-router';

import StagingOrderActions from './StagingOrderActions';
interface HomeOrdersTabProps {
  home: guesthouse.Home;
  homeID: string;
}

const HomeOrdersTab: React.FC<HomeOrdersTabProps> = (props) => {
  const { homeID } = props;
  const userContext = useContext<UserContext>(UserContext);
  const { theme } = useStyles();

  let ordersQuery = query(collection(database, 'orders')
    ,where('home.docID', '==', homeID)
    ,where('status', '==', 'COMPLETE'));

  if (!checkRoles(['admin', 'design_manager', 'designer', 'customer_support'], userContext.roles)) {
    ordersQuery = query(collection(database, 'orders')
      ,where('home.docID', '==', homeID)
      ,where('status', '==', 'COMPLETE')
      ,where('user.docID', '==', userContext.user.uid));
  }

  const { collection: ordersCollection } = useCollection(ordersQuery);
  const orders = getDataFromCollection(ordersCollection) as guesthouse.StagingOrder[];

  return (
    <>
      {
        orders?.length > 0 ? (
          <>
            {orders.map((orderData, i) => {
              return (
                <div key={i}>
                  {orderData.type === 'staging' &&
                    <Protected allowedRoles={['admin', 'design_manager', 'customer_support']}>
                      <StagingSubscription
                        subscriptionID={orderData.subscriptionID}
                        order={orderData}
                        style={{
                          borderRadius: theme.shape.borderRadius,
                          marginBottom: theme.spacing(1),
                          backgroundColor: theme.palette.background.paper,
                          padding: theme.spacing(2)
                        }}
                      />
                    </Protected>
                  }
                  <StagingOrder
                    billingRoles={['admin', 'design_manager', 'customer_support', 'realtor']}
                    order={orderData}
                    style={{
                      borderRadius: theme.shape.borderRadius,
                      marginBottom: theme.spacing(1),
                      backgroundColor: theme.palette.background.paper,
                      padding: theme.spacing(2)
                    }}
                    renderActions={
                      <Protected allowedRoles={['admin', 'design_manager', 'customer_support']}>
                        <StagingOrderActions orderData={orderData} />
                      </Protected>
                    }
                    stagingAgreementOnClick={() => {
                      asyncRender(StagingAgreementDialog, {
                        submitText: 'Close',
                      });
                    }}
                    showReceipt={true}
                    receiptPath={makeAppUrl(generatePath(routes.receiptStaging.path, { orderID: orderData.docID }))}
                  />
                </div>
              );
            })}
          </>
        ) : (
          <Typography
            style={{
              marginBottom: theme.spacing(4)
            }}
          >
            No order history to show.
          </Typography>
        )
      }
    </>
  );
};

export default HomeOrdersTab;
