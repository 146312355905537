import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import DateTimeDialog from '@components/dates/DateTimeModal';
import { InventoryDialog } from '@components/dialogs/InventoryDialog';
import RoomGrid from '@components/room-grid/RoomGrid';
import { renderActionProps } from '@components/room-grid/RoomGrid.props';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import Sentry from '@integrations/Sentry';
import IconMoreHoriz from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { batchDeleteRequests, batchUpdateRequestInventory, batchUpdateRequests } from '@utils/batchUpdateProductRequests';
import { doc, getDoc,QueryDocumentSnapshot, Timestamp } from 'firebase/firestore';
import React, { useContext, useState } from 'react';

const RoomDialog = ({ resolve, reject, request }) => {
  const [open, setOpen] = useState(true);

  const requestData = request.data() as guesthouse.ProductRequest;
  const home = requestData.home;

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="lg"
      onClose={() => {
        resolve();
        setOpen(false);
      }}
    >
      <DialogTitle>
        Change Room
      </DialogTitle>

      <DialogContent>
        <RoomGrid
          home={home}
          allowAdd={false}
          renderAction={({ home, room }: renderActionProps) => {
            return (
              <Button
                disabled={room.docID === requestData.room.docID}
                variant="contained"
                color="secondary"
                onClick={() => {
                  batchUpdateRequests(request, { home, room })
                    .then(() => resolve(room))
                    .catch(reject)
                    .then(() => setOpen(false));
                }}
              >
                {room.docID === requestData.room.docID ? 'Current Room' : 'Move Here'}
              </Button>
            );
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            resolve();
            setOpen(false);
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};


type RequestedProductActionsProps = {
  request: QueryDocumentSnapshot<guesthouse.ProductRequest>
}

const RequestedProductActions: React.FC<RequestedProductActionsProps> = ({ request }) => {
  const requestData = request.data() as guesthouse.ProductRequest;
  const [anchorEl, setAnchorEl] = useState(null);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const userContext = useContext<UserContext>(UserContext);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        edge="end"
        aria-label="actions"
        aria-haspopup="true"
        size="large"
        onClick={handleClick}
      >
        <IconMoreHoriz />
      </IconButton>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        {!([
          'NEEDS_PICKUP',
          'RECEIVED',
        ].indexOf(requestData.status) > -1) && (
        <MenuItem
          onClick={async () => {
            const updatedRequest: Partial<guesthouse.ProductRequest> = {
              status: 'RECEIVED',
              deliveredDate: Timestamp.fromDate(new Date()),
            };

            let chooseWarehouse = false;
            let inventory;

            if (requestData.inventory?.docID) {
              inventory = await getDoc(doc(database, `products/${requestData.product.docID}/inventory/${requestData.inventory.docID}`));
              const inventoryData = inventory.data() as guesthouse.ProductInventory;

              if (!inventoryData.warehouse?.guesthouseManaged) {
                chooseWarehouse = true;
              }
            }

            if (chooseWarehouse) {
              const productDoc = await getDoc(doc(database, `products/${requestData.product.docID}`));

              asyncRender(InventoryDialog, {
                userContext,
                title: 'Which warehouse should product be stored on de-install?',
                showQuantity: false,
                product: productDoc,
              })
                .then(values => {
                  return batchUpdateRequestInventory(request, values);
                })
                .then(() => batchUpdateRequests(request, updatedRequest))
                .then(() => notificationContext.setContext({ open: true, message: 'Item has been marked as received' }))
                .catch((e) => {
                  if (e) {
                    Sentry.captureException(e);
                    notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                  }
                });
            } else {
              batchUpdateRequests(request, updatedRequest)
                .then(() => notificationContext.setContext({ open: true, message: 'Item has been marked as received' }))
                .catch(Sentry.captureException);
            }

            handleClose();
          }}
        >
          Mark as Received
        </MenuItem>
        )}

        {!([
          'PENDING',
          'NEEDS_PICKUP',
        ].indexOf(requestData.status) > -1) && (
        <MenuItem
          onClick={() => {
            handleClose();
            asyncRender(DateTimeDialog, {
              title: 'Request Pickup',
              copy: 'The maker will be notified of the pickup request',
            })
              .then((time: Date) => {
                const updatedRequest: Partial<guesthouse.ProductRequest> = {
                  status: 'NEEDS_PICKUP',
                  pickupDate: Timestamp.fromDate(time),
                };

                batchUpdateRequests(request, updatedRequest)
                  .then(() => {
                    notificationContext.setContext({ open: true, message: 'The maker will be notified' });
                  })
                  .catch(Sentry.captureException);
              })
              .catch((e) => {
                if (e) {
                  Sentry.captureException(e);
                  notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                }
              });
          }}
        >
          Request Pickup
        </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            handleClose();
            asyncRender<guesthouse.Room>(RoomDialog, { request })
              .then((room) => {
                if (room?.title) {
                  return notificationContext.setContext({ open: true, message: `${requestData?.product?.title} moved to ${room.title}` });
                }
              })
              .catch((e) => {
                if (e) {
                  Sentry.captureException(e);
                  notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                }
              });
          }}
        >
          Change Room
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            asyncRender(DateTimeDialog, {
              title: 'Change Schedule Time',
              copy: 'The maker will be notified of the change in scheduling'
            })
              .then((time: Date) => {
                const updatedRequest: Partial<guesthouse.ProductRequest> = {
                  status: 'PENDING',
                  scheduleDate: Timestamp.fromDate(time),
                };

                batchUpdateRequests(request, updatedRequest)
                  .then(() => {
                    notificationContext.setContext({ open: true, message: 'The maker will be notified' });
                  })
                  .catch(Sentry.captureException);
              })
              .catch((e) => {
                if (e) {
                  Sentry.captureException(e);
                  notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                }
              });
          }}
        >
          Change Schedule Time
        </MenuItem>

        <MenuItem
          onClick={async () => {
            try {
              await batchDeleteRequests(request);
              handleClose();
            } catch (e) {
              Sentry.captureException(e);
            }
          }}
        >
          Remove
        </MenuItem>
      </Menu>
    </div>
  );
};

export default RequestedProductActions;
