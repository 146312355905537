import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  document: {
    width: '100%', 
  },
  page: {
    marginBottom: theme.spacing(3),
    border: '1px solid #C4C4C4',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
}));
