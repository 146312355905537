import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot } from 'firebase/firestore';

export class DefaultFirestoreConverter<T> implements FirestoreDataConverter<T> {
  toFirestore(modelObject): DocumentData {
    return { ...modelObject };
  }

  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): T {
    return snapshot.data() as T;
  }
}
