import { ParsedUrlQuery } from 'querystring';

const strToBool = (s : string) => {
  if (s === '1') {
    return true;
  } else if (s === '0') {
    return false;
  }
};

const makeBooleanFilter = (obj: ParsedUrlQuery): string => {
  const facetFilters = Object.keys(obj)
    .filter((k) => Array.isArray(obj[k]) ? obj[k].length : obj[k])
    .map((k) => {
      const v = obj[k];

      if (Array.isArray(v)) {
        return `${v.map(i => `${k}:${strToBool(i)}`).join(' AND ')}`;
      } else {
        return `${k}:${strToBool(v)}`;
      }
    })
    .join(' AND ');

  return facetFilters;
};

export default makeBooleanFilter;
