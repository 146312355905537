import Sentry from '@integrations/Sentry';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SendTo from '@ui/icons/imaterial/base/Send to.svg';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import useStyles from './PDFPreview.styles';
import PdfPreviewModal from './PDFPreviewModal';

type PdfPreviewProps = {
  pdfUrl: string;
  pdfTitle: string;
  className?: string;
  numPagesToPreview?: number;
  onPageClick?: (i: number) => void;
  modalStyle?: React.CSSProperties;
  modalClassName?: string;
}

const PdfPreview: React.FC<PdfPreviewProps> = (props) => {
  const { className, pdfUrl, pdfTitle, numPagesToPreview, modalStyle, modalClassName, onPageClick } = props;
  const [numPages, setNumPages] = useState(null);
  const [open, setOpen] = useState(false);
  const { classes, theme } = useStyles();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <Document
        // ! Document component does not respect style prop
        className={className || classes.document}
        file={pdfUrl}
        loading={<CircularProgress
          color="inherit"
          size={50}
          thickness={2}
        />}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={Sentry.captureException}
      >
        {
          [...new Array(numPagesToPreview > numPages ? numPages : numPagesToPreview).fill(0)].map((_, i) => {
            return (
              <Page
                key={i}
                pageNumber={i + 1}
                className={classes.page}
                height={350}
                aria-label="Enlarge"
                onClick={() => {
                  if (typeof onPageClick === 'function') {
                    onPageClick(i);
                  } else {
                    setOpen(true);
                  }
                }}
              />
            );
          })
        }
      </Document>
      <Dialog
        fullWidth
        open={open}
        maxWidth="xl"
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>
          <span style={{ marginRight: theme.spacing(2) }}>
            {pdfTitle}
          </span>
          <IconButton
            style={{
              color: theme.palette.common.black,
              position: 'absolute',
              top: 8,
              right: 15,
            }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon style={{ height: 25, width: 25 }} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            style={{ marginBottom: theme.spacing(1) }}
          >
            <Typography
              component="h3"
              variant="h6Alt"
              style={{ fontSize: 14 }}
            >
              {numPages}
              {' '}
              pages
            </Typography>

            <Button
              style={{ fontWeight: 'bold' }}
              endIcon={<SendTo />}
              href={pdfUrl}
              target="_blank"
            >
              Open New Tab
            </Button>

          </Box>

          <PdfPreviewModal
            pdfUrl={pdfUrl}
            style={modalStyle}
            className={modalClassName}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

PdfPreview.defaultProps = {
  numPagesToPreview: 1,
  modalStyle: {},
};

export default PdfPreview;
