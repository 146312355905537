type RenderActionProps = {
  room: guesthouse.Room; 
  roomSet: guesthouse.RoomSet;
}

type RoomSearchCompleteInfo = {
  roomSet: guesthouse.RoomSet;
}

type Props = {
    roomSet?: guesthouse.AlgoliaRoomSet;
    product?: guesthouse.Product;
    renderAction?: (props: RenderActionProps) => JSX.Element | null | string;
    onComplete?: (info: RoomSearchCompleteInfo) => void;
}

export const defaultProps: Partial<Props> = {
  renderAction: () => null,
};

export default Props;
