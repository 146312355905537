import Box from '@mui/material/Box';
import React, { CSSProperties } from 'react';

import useStyles from './HomeStatusChip.style';

interface HomeStatusChipProps {
  status: guesthouse.HomeStatus;
  className?: string;
  style?: CSSProperties;
}

const textOptions = {
  'planning': {
    text: 'Planning',
    color: '#D3E0E2'
  },
  'fully_planned': {
    text: 'Planned',
    color: '#F7F7F7'
  },
  'on_market': {
    text: 'Market',
    color: '#E0BFB0'
  },
  'sold': {
    text: 'Sold',
    color: '#E3F1D1'
  },
};

const HomeStatusChip: React.FC<HomeStatusChipProps> = (props) => {
  const { status, className, style } = props;
  const { classes, cx } = useStyles();

  if (textOptions[status]) {
    return (
      <div
        className={cx(classes.chipWrapper, className)}
        style={{
          backgroundColor: textOptions[status].color,
          ...style
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          className={classes.chipText}
        >
          {textOptions[status].text}
        </Box>
      </div>
    );
  }

  return null;
};

HomeStatusChip.defaultProps = {
  style: {},
};

export default HomeStatusChip;
