import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  messagesButton: {
    height: 36,
    width: 36,
    fontSize: 27,
    padding: 5,
    marginTop: 'auto',
    marginBottom: 'auto',
    borderRadius: 10,
    backgroundColor: '#fff',
    border: '1.2px solid #080808',
    marginRight: theme.spacing(.75),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    }
  },
  badge: {
    backgroundColor: theme.palette.pink.dark,
    color: 'white'
  }
}));
