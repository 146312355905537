
import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  productPaper: {
    position: 'relative',
    overflow: 'hidden',
    boxShadow: 'none !important',
    height: 125,
    [theme.breakpoints.up('md')]: {
      height: 200,
    },
    [theme.breakpoints.up('lg')]: {
      height: 250,
    }
  },
  productImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  productTitle: {
    marginTop: theme.spacing(2),
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 1.2,
  },
  gridItem: {
    '&:empty': {
      display: 'none'
    },
  }
}));
