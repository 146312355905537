import { database } from '@app/firebase';
import { RouterLink } from '@components/link-behavior/LinkBehavior';
import PaginationController from '@components/pagination-controller/PaginationController';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import usePaginatedFirebaseQuery from '@hooks/usePaginatedFirebaseQuery';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ROUTES from '@routes';
import useCommonStyles from '@styles/common.style';
import ProductOffer from '@ui/components/product-offer/ProductOffer';
import formatDate from '@ui/utils/formatDate';
import { orderProductTotal } from '@ui/utils/offerPrice';
import properCase from '@utils/properCase';
import { collectionGroup, orderBy,query, where } from 'firebase/firestore';
import formatCurrency from 'format-currency';
import React, { useContext, useMemo } from 'react';
import { generatePath } from 'react-router';

const AdminOrders = () => {
  const { classes: common, theme } = useCommonStyles();
  const orderProductQuery = useMemo(() => collectionGroup(database, 'orderProducts'), []);
  const { collection: orderProducts, loading, page, setPage, setFetchAction } = usePaginatedFirebaseQuery(
    orderProductQuery,
  );


  return (
    <div className={common.contentSpacing}>
      <Typography
        component="h1"
        variant="h3Alt"
        style={{ marginBottom: theme.spacing(3) }}
      >
        All orders
      </Typography>

      {
        orderProducts?.docs?.length
          ? (
            <>
              <PaginationController
                page={page}
                setPage={setPage}
                setFetchAction={setFetchAction}
                loading={loading}
              />

              <TableContainer>
                <Table aria-label="orders">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Num
                      </TableCell>

                      <TableCell>
                        Order ID
                      </TableCell>

                      <TableCell>
                        Fulfillment
                      </TableCell>

                      <TableCell>
                        Product
                      </TableCell>

                      <TableCell>
                        Date
                      </TableCell>

                      <TableCell>
                        Price
                      </TableCell>

                      <TableCell>
                        Quantity
                      </TableCell>

                      <TableCell>
                        Subtotal
                      </TableCell>

                      <TableCell>
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {
                      orderProducts.docs.map((orderProduct, i) => {
                        const orderProductData = orderProduct.data() as guesthouse.OrderProduct;
                        const { total } = orderProductTotal(orderProductData);

                        const orderId = orderProduct.ref.parent.parent.id;

                        return (
                          <TableRow key={`${orderId}_${orderProduct.id}`}>
                            <TableCell
                              component="th"
                              scope="order"
                            >
                              {i + 1}
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="order"
                            >
                              <Link
                                component={RouterLink}
                                to={generatePath(ROUTES.order.path, { id: orderId })}
                              >
                                {orderId}
                              </Link>
                            </TableCell>

                            <TableCell>
                              {orderProductData.offer?.dropship &&
                                <Chip
                                  label={'Dropship'}
                                  size="small"
                                  style={{ background: '#E3EADA' }}
                                />
                              }
                              {orderProductData.offer?.pickupOnly &&
                                <Chip
                                  label={'Pickup'}
                                  size="small"
                                  style={{ background: '#EBD4D4' }}
                                />
                              }
                              {!orderProductData.offer?.dropship && !orderProductData.offer?.pickupOnly &&
                                <Chip
                                  label={'Ship'}
                                  size="small"
                                  style={{ background: '#B1B0A0' }}
                                />
                              }
                            </TableCell>

                            <TableCell>
                              {orderProductData.product?.title}
                            </TableCell>

                            <TableCell>
                              {formatDate(orderProductData.created)}
                            </TableCell>

                            <TableCell>
                              <ProductOffer offer={orderProductData.offer} />
                            </TableCell>

                            <TableCell>
                              {orderProductData.quantity}
                            </TableCell>

                            <TableCell>
                              {formatCurrency(total, { format: '%s%v', symbol: '$' })}
                            </TableCell>

                            <TableCell>
                              {properCase(orderProductData.status)}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Typography>
              No orders yet.
            </Typography>
          )
      }
    </div>
  );
};

const MakerOrders = () => {
  const { classes: common, theme } = useCommonStyles();
  const userContext = useContext<UserContext>(UserContext);

  const { collection: orderProducts, loading } = useCollection(
    query(collectionGroup(database, 'orderProducts')
      ,where('product.owner.docID', '==', userContext.user.uid)
      ,orderBy('created', 'desc'))
  );

  return (
    <div className={common.contentSpacing}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Box
            display="flex"
            alignItems="flex-end"
          >
            <Typography
              component="h1"
              variant="h3Alt"
            >
              Your orders
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
        >
        </Grid>
      </Grid>

      {
        !loading
          ? orderProducts?.docs?.length
            ? (
              <TableContainer>
                <Table aria-label="orders">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Order ID
                      </TableCell>

                      <TableCell>
                        Product
                      </TableCell>

                      <TableCell>
                        Date
                      </TableCell>

                      <TableCell>
                        Price
                      </TableCell>

                      <TableCell>
                        Quantity
                      </TableCell>

                      <TableCell>
                        Subtotal
                      </TableCell>

                      <TableCell>
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {
                      orderProducts.docs.map(orderProduct => {
                        const orderProductData = orderProduct.data() as guesthouse.OrderProduct;
                        const { total } = orderProductTotal(orderProductData);

                        const orderId = orderProduct.ref.parent.parent.id;

                        return (
                          <TableRow key={`${orderId}_${orderProduct.id}`}>
                            <TableCell
                              component="th"
                              scope="order"
                            >
                              <Link
                                component={RouterLink}
                                to={generatePath(ROUTES.order.path, { id: orderId })}
                              >
                                {orderId}
                              </Link>
                            </TableCell>

                            <TableCell>
                              {orderProductData.product.title}
                            </TableCell>

                            <TableCell>
                              {formatDate(orderProductData.created)}
                            </TableCell>

                            <TableCell>
                              <ProductOffer offer={orderProductData.offer} />
                            </TableCell>

                            <TableCell>
                              {orderProductData.quantity}
                            </TableCell>

                            <TableCell>
                              {formatCurrency(total, { format: '%s%v', symbol: '$' })}
                            </TableCell>

                            <TableCell>
                              {properCase(orderProductData.status)}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>
                No orders yet.
              </Typography>
            ) : (
              <></>
          )
      }
    </div>
  );
};

const Orders = (props) => {
  const userContext = useContext<UserContext>(UserContext);

  if (userContext?.roles?.admin || userContext?.roles?.design_manager || userContext?.roles?.customer_support) {
    return <AdminOrders {...props} />;
  }

  if (userContext?.roles?.maker) {
    return <MakerOrders {...props} />;
  }

  return null;
};

export default React.memo(Orders);
