import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  fab: {
    margin: theme.spacing(1),
    padding: `0 ${theme.spacing(3)} 0 ${theme.spacing(2)} !important`,
    textTransform: 'none',
  },
  fabIcon: {
    marginRight: theme.spacing(1),
    width: '15px !important',
    height: '15px !important',
  },
  hero: {
    width: '100%',
    minHeight: 400,
    background: theme.palette.tertiary.main,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'relative',
  },
  heroContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(5),
  },
  heroInfo: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
  },
  heroBack: {},
  notesBox: {
    padding: theme.spacing(2),
    background: theme.palette.tertiary.light,
    boxShadow: 'none',
    borderRadius: 8,
    marginBottom: theme.spacing(2),
  },
  heroButton: {
    marginRight: `${theme.spacing(1)} !important`,
    '&:last-of-type': {
      marginRight: '0 !important',
    }
  },
  tableWrapper: {
    overflow: 'scroll',
    maxWidth: '100vw',
  },
  table: {
    minWidth: 700,
  },
  select: {
    '&:before': {
      border: 'none',
    },
    '&:hover:before': {
      border: 'none',
    }
  }
}));
