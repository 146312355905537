import { database } from '@app/firebase';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useStyles } from '@ui/utils/makeStyles';
import userFullName from '@ui/utils/userFullName';
import { collection,doc, getDoc } from 'firebase/firestore';
import formatCurrency from 'format-currency';
import React, { useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';

type RefundDialogReturnValue = {
  calculationType: 'percent' | 'fixed';
  calculationAmount: number;
  userRefund: number;
  refundDescription: string;
  userData: guesthouse.User
}

type RefundDialogArgs = {
  title?: JSX.Element | string;
  content?: JSX.Element | string;
  cancelText?: string;
  confirmText?: string;
  eligibleForRefund: number;
  calculationType?: 'percent' | 'fixed';
  calculationAmount?: number;
  defaultRefundDescription?: string;
  userData: guesthouse.User;
  resolve: (value: RefundDialogReturnValue) => void;
  reject: (e?: Error | boolean) => void;
}


const RefundDialog: React.FC<RefundDialogArgs> = ({
  resolve,
  reject,
  title = 'Confirm Refund',
  content = '',
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  eligibleForRefund = 0,
  calculationType = 'percent',
  calculationAmount = 0,
  defaultRefundDescription = '',
  userData,
}) => {
  const { theme } = useStyles();

  const [open, setOpen] = useState(true);
  const [productRefundPercentage, setProductRefundPercentage] = useState(calculationType === 'percent' ? calculationAmount : calculationAmount / eligibleForRefund);
  const [userRefund, setUserRefund] = useState(calculationType === 'fixed' ? calculationAmount : calculationAmount * eligibleForRefund);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [refundDescription, setRefundDescription] = useState(defaultRefundDescription);
  const [user, setUser] = useState<guesthouse.User>();

  const percentDifference = useMemo(() => {
    return Number((userRefund / eligibleForRefund) * 100).toFixed(2);
  }, [eligibleForRefund, userRefund]);

  useEffect(() => {
    const fetchUser = async (userData: guesthouse.User) => {
      const userDoc = await getDoc(doc(collection(database, 'users'), userData.docID));

      if (userDoc.exists()) {
        setUser(userDoc.data() as guesthouse.User);
      }
    };

    fetchUser(userData);
  }, [userData]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        {content}

        <Alert
          icon={<InfoOutlinedIcon
            style={{
              height: 22,
              width: 22,
            }}
          />}
          style={{ margin: theme.spacing(0, 0, 2) }}
        >
          Total eligible for refund:
          {' '}
          <strong>
            {formatCurrency(eligibleForRefund, { format: '%s%v', symbol: '$' })}
          </strong>
        </Alert>

        <FormControl
          fullWidth
          margin="dense"
        >
          <NumberFormat
            fullWidth
            id="amount"
            label="Refund Amount"
            variant="outlined"
            value={userRefund}
            customInput={TextField}
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix="$"
            onValueChange={(e) => {
              const newAmount = Number(e?.floatValue?.toFixed(2));

              setUserRefund(newAmount);
              if (productRefundPercentage) {
                setProductRefundPercentage(newAmount / eligibleForRefund);
              }
            }}
          />
        </FormControl>

        <FormControl
          fullWidth
          margin="dense"
        >
          <NumberFormat
            fullWidth
            id="percentage"
            label="Refund Percentage"
            variant="outlined"
            customInput={TextField}
            suffix="%"
            decimalScale={2}
            value={productRefundPercentage * 100}
            onValueChange={(e) => {
              const newPercentage = e.floatValue / 100;

              setProductRefundPercentage(newPercentage);
              setUserRefund(eligibleForRefund * newPercentage);
            }}
          />
        </FormControl>

        <FormControl
          fullWidth
          margin="dense"
        >
          <TextField
            multiline
            fullWidth
            id="refundDescription"
            label="Refund Description"
            rows="2"
            variant="outlined"
            value={refundDescription}
            onChange={(e) => {
              setRefundDescription(e.target.value);
            }}
          />
        </FormControl>

        <Alert
          severity="info"
          style={{ margin: theme.spacing(2, 0, 0) }}
        >
          This action will pay
          {' '}
          {userFullName(user)}
          {' '}
          a sum of
          {' '}
          <strong>
            {formatCurrency(userRefund, { format: '%s%v', symbol: '$' })}
            {' '}
            (
            {!isNaN(userRefund) && percentDifference}
            %)
          </strong>
        </Alert>

        <Typography style={{ marginBottom: theme.spacing(3) }}>

        </Typography>

        {error && (
          <FormHelperText error>
            {error.message}
          </FormHelperText>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            setOpen(false);
            reject(false);
          }}
        >
          {cancelText}
        </Button>

        <Button
          size="small"
          variant="contained"
          color="secondary"
          disabled={loading || !userRefund || userRefund <= 0}
          onClick={() => {
            setLoading(true);
            setError(undefined);
            setOpen(false);
            resolve({
              calculationType,
              calculationAmount,
              userRefund,
              refundDescription,
              userData: user,
            });
          }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RefundDialog;
