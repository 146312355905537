import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  unreadDot: {
    height: 8,
    width: 8,
    backgroundColor: '#AD6556',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: theme.spacing(2)
  },
  nameAndText: {
    fontSize: 12,
    fontFamily: theme.gh_vars.circular,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 150,
    fontWeight: 400,
    [theme.breakpoints.up('md')]: {
      width: 100,
    },
    [theme.breakpoints.up('lg')]: {
      width: 150
    }
  },
  avatar: {
    height: 36,
    width: 36,
    margin: theme.spacing(0, 1)
  },
  newMessage: {
    width: '100%',
    border: '1px solid black',
    borderRadius: 10,
    height: 56,
    padding: theme.spacing(0, 1, 0, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));
