import shortAddress from '../shortAddress';

export default function homeMetaData(home: guesthouse.Home): guesthouse.MetaData {
  const _shortAddress = home?.address && shortAddress(home.address);
  const _shortAddressWithUnit = home.unit ? `${_shortAddress}, Unit ${home.unit}` : _shortAddress;

  const titleFallback = `Professional Home Staging | ${_shortAddressWithUnit}`;
  const metaData: guesthouse.MetaData = {
    h1: home?.metadata?.h1 || _shortAddress,
    pageTitle: home?.metadata?.pageTitle || titleFallback,
    metaTitle: home?.metadata?.metaTitle || titleFallback,
    metaDescription: home?.metadata?.metaDescription || `Take a look at the professional home staging we did at ${_shortAddressWithUnit} in ${home.vicinity}. Shop anything we used to stage the home or visit the home.`,
    image: home?.primaryPhoto?.medium_url || 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Fguesthouse-social-default.jpg?alt=media',
  };

  return metaData;
}
