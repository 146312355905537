import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()({
  facebookPreview: {
    border: 'none',
    display: 'flex',
    overflowX: 'auto',
    maxWidth: 527,
    WebkitOverflowScrolling: 'touch',
  },
  facebookPreviewContent: {
    display: 'flex',
    maxWidth: '100%',
    backgroundColor: '#f2f3f5',
    flexDirection: 'column',
    minWidth: '100%'
  },
  facebookPreviewBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 12px',
    border: '1px solid #dadde1',
    overflow: 'hidden',
    fontFamily: 'Helvetica, Arial, sans-serif',
    height: 'auto',
    maxHeight: 100,
  },
  facebookPreviewDescription: {
    color: '#606770',
    fontSize: 14,
    lineHeight: '20px',
    overflowY: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
  facebookPreviewUrl: {
    color: '#606770',
    fontSize: 12,
    textTransform: 'uppercase',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginBottom: 5,
  },
  facebookPreviewImage: {
    maxHeight: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'hidden',
    '& img': {
      height: 'auto',
      width: '100%',
      maxWidth: 527,
    }
  },
  facebookPreviewTitle: {
    marginBottom: 1,
    fontWeight: 'bold',
    fontSize: 15,
  },

});
