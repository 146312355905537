import HomeRowCardSkeleton from '@components/cards/HomeRowCard.skeleton';
import HomeGridSkeleton from '@components/home-grid/HomeGrid.skeleton';
import UserContext from '@context/UserContext';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useStyles } from '@ui/utils/makeStyles';
import React, { useContext } from 'react';

const HomesSkeleton = () => {
  const { theme } = useStyles();
  const userContext = useContext<UserContext>(UserContext);

  return (
    <>
      <Skeleton
        width={250}
        height={30}
        style={{ marginTop: theme.spacing(1) }}
      />

      {
        (userContext?.data?.app_preferences?.views?.home_card || 'grid') === 'grid'
          ? (
            <Box
              style={{
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(5)
              }}
            >
              <HomeGridSkeleton
                style={{
                  marginBottom: theme.spacing(10)
                }}
              />
            </Box>
          )
          :
          (
            <Box style={{ marginTop: theme.spacing(7) }}>
              {[...new Array(6).fill(0)].map((_, x) => {
                return (
                  <HomeRowCardSkeleton
                    key={x}
                    style={{
                      marginBottom: theme.spacing(1)
                    }}
                  />
                );
              })}
            </Box>
          )
      }

    </>
  );
};

export default React.memo(HomesSkeleton);
