import FormHelperText from '@mui/material/FormHelperText';
import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import { useMemo } from 'react';


export function makeErrors<T>(errors : FormikErrors<T>, touched : FormikTouched<T>, requireTouched  = false) : string[] {
  if (Object.keys(errors).length) {
    return Object.keys(errors).map(e => {
      if (requireTouched && touched[e]) {
        if (typeof errors[e] === 'object') {
          return makeErrors(errors[e], touched, requireTouched);
        }
  
        return errors[e];
      } else {
        if (typeof errors[e] === 'object') {
          return makeErrors(errors[e], touched, requireTouched);
        }
  
        return errors[e];
      }
    });
  }

  return [];
}


interface TooltipErrorsProps<T> {
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
  requireTouched?: boolean;
}

export const TooltipErrors : React.FC<TooltipErrorsProps<object>> = (props) => {
  const { errors, touched, requireTouched } = props;

  const errorArray : string[] = useMemo(() => {
    return makeErrors(errors, touched, requireTouched);
  }, [errors, touched, requireTouched]);

  if (Object.keys(errors).length) {
    return (
      <div style={{ textAlign: 'center' }}>
        {errorArray.map((e, i) => (
          <div key={i}>
            {e}
          </div>
        ))}
      </div>
    );
  }
  
  return null;
};

TooltipErrors.defaultProps = {
  errors: {},
  touched: {},
  requireTouched: false,
};

type FormErrorsProps<T> = {
  errors: FormikErrors<T>,
  touched: FormikTouched<T>,
  requireTouched?: boolean;
}

const FormErrors : React.FC<FormErrorsProps<object>> = (props) => {
  const { errors, touched, requireTouched } = props;

  const errorArray : string[] = useMemo(() => {
    return makeErrors(errors, touched, requireTouched);
  }, [errors, touched, requireTouched]);

  return (
    <>
      {errorArray.map((e, i) => {
        return (
          <FormHelperText
            key={i}
            error
          >
            {e}
          </FormHelperText>
        );
      })}
    </>
  );
};

FormErrors.defaultProps = {
  errors: {},
  touched: {},
  requireTouched: true,
};

export default FormErrors;
