import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import Error from '@components/error/Error';
import NotificationContext from '@context/NotificationContext';
import useCollection from '@hooks/useCollection';
import Sentry from '@integrations/Sentry';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconEdit from '@mui/icons-material/Edit';
import PageviewIcon from '@mui/icons-material/Pageview';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useCommonStyles from '@styles/common.style';
import { collection as firestoreCollection, CollectionReference } from 'firebase/firestore';
import { uniq } from 'lodash';
import React, { useContext } from 'react';

import PricingSkeleton from './Pricing.skeleton';
import PricingTierDeleteDialog from './PricingTierDeleteDialog';
import PricingTierDialog from './PricingTierDialog';

const rowColors = ['#e9e5dd', '#f4efe6'];

const pricingTierComparator = (a: guesthouse.PricingTier, b: guesthouse.PricingTier) => {
  const warehouseNameCompare = a.warehouse?.name.localeCompare(b.warehouse?.name);
  const minDistCompare = a.min_distance - b.min_distance;
  const maxDistCompare = a.max_distance - b.max_distance;
  const minSqftCompare = a.min_sqft - b.min_sqft;
  const maxSqftCompare = a.max_sqft - b.max_sqft;

  return warehouseNameCompare || minDistCompare || maxDistCompare || minSqftCompare || maxSqftCompare;
};

const Pricing: React.FC = () => {
  const { classes: common, theme } = useCommonStyles();
  const query = firestoreCollection(database, 'pricing_tiers') as CollectionReference<guesthouse.DbPricingTier>;
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const { collection: pricingTierCollection, loading, error } = useCollection<guesthouse.DbPricingTier>(query);

  if (error) {
    return (
      <div className={common.contentSpacing}>
        <Error />
      </div>
    );
  }

  if (loading) {
    return (
      <div className={common.contentSpacing}>
        <PricingSkeleton />
      </div>
    );
  }

  const pricingTiers = pricingTierCollection?.docs
    .map(snap => snap.data())
    .filter(pt => pt.base_price)
    .sort(pricingTierComparator);

  const warehouseNames = uniq(pricingTiers.map(pt => pt.warehouse?.name));
  const warehouseColors = {};

  warehouseNames.forEach((name, i) => {
    warehouseColors[name] = rowColors[i % rowColors.length];
  });

  return (
    <div className={common.contentSpacing}>
      <Box
        display="flex"
      >
        <Typography
          component="h1"
          variant="h3Alt"
          style={{ marginRight: theme.spacing(1) }}
        >
          Pricing Tiers
        </Typography>
      </Box>

      <TableContainer>
        <Table aria-label="pricing table">
          <TableHead>
            <TableRow>
              <TableCell>
                Warehouse
              </TableCell>
              <TableCell>
                Min Distance
              </TableCell>
              <TableCell>
                Max Distance
              </TableCell>
              <TableCell>
                Min Sq Ft
              </TableCell>
              <TableCell>
                Max Sq Ft
              </TableCell>
              <TableCell>
                Base Price
              </TableCell>
              <TableCell>
                Amount Monthly
              </TableCell>
              <TableCell>
                Amount Fifteen Day Interval
              </TableCell>
              <TableCell>
                Room Pricing
              </TableCell>
              <TableCell id="edit-icon-column-header"/>
              <TableCell id="create-delete-icon-column-header">
                <IconButton
                  aria-label="create pricing tier"
                  size="small"
                  onClick={() => {
                    asyncRender(PricingTierDialog, {
                      resolve: () => {
                        notificationContext.setContext({ open: true, message: 'Succesfully created pricing tier' });
                      },
                      reject: (e) => {
                        if (e) {
                          Sentry.captureException(e);
                        }
                        notificationContext.setContext({ open: true, message: 'Error creating pricing tier', severity: 'error' });
                      }
                    });
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pricingTiers.length > 0
              ? pricingTiers.map((pricingTier) => {
                const rowColor = warehouseColors[pricingTier.warehouse.name];

                return (
                  <TableRow
                    key={pricingTier.docID}
                    style={{ backgroundColor: rowColor }}
                  >
                    <TableCell>
                      {pricingTier.warehouse?.name}
                    </TableCell>
                    <TableCell>
                      {pricingTier.min_distance}
                    </TableCell>
                    <TableCell>
                      {pricingTier.max_distance}
                    </TableCell>
                    <TableCell>
                      {pricingTier.min_sqft}
                    </TableCell>
                    <TableCell>
                      {pricingTier.max_sqft}
                    </TableCell>
                    <TableCell>
                      {pricingTier.base_price}
                    </TableCell>
                    <TableCell>
                      {`${pricingTier.amount_monthly * 100}%`}
                    </TableCell>
                    <TableCell>
                      {`${pricingTier.amount_fifteen_day_interval * 100}%`}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="view rooms"
                        size="small"
                        onClick={() => {
                          asyncRender(PricingTierDialog, {
                            pricingTier,
                            resolve: () => {
                              notificationContext.setContext({ open: true, message: 'Succesfully updated pricing tier' });
                            },
                            reject: (e) => {
                              if (e) {
                                Sentry.captureException(e, { extra: { pricingTier } });
                              }
                              notificationContext.setContext({ open: true, message: 'Error updating pricing tier', severity: 'error' });
                            }
                          });
                        }}
                      >
                        <PageviewIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit pricing tier"
                        size="small"
                        onClick={() => {
                          asyncRender(PricingTierDialog, {
                            pricingTier,
                            resolve: () => {
                              notificationContext.setContext({ open: true, message: 'Succesfully updated pricing tier' });
                            },
                            reject: (e) => {
                              if (e) {
                                Sentry.captureException(e, { extra: { pricingTier } });
                              }
                              notificationContext.setContext({ open: true, message: 'Error updating pricing tier', severity: 'error' });
                            }
                          });
                        }}
                      >
                        <IconEdit />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete pricing tier"
                        size="small"
                        onClick={() => {
                          asyncRender(PricingTierDeleteDialog, {
                            pricingTier,
                            resolve: () => {
                              notificationContext.setContext({ open: true, message: 'Succesfully deleted pricing tier' });
                            },
                            reject: (e) => {
                              if (e) {
                                Sentry.captureException(e, { extra: { pricingTier } });
                              }
                              notificationContext.setContext({ open: true, message: 'Error deleted pricing tier', severity: 'error' });
                            }
                          });
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
              : (
                <TableRow>
                  <TableCell colSpan={11}>
                    <Typography>
                      There&apos;s nothing here yet.
                    </Typography>
                  </TableCell>
                  <TableCell id="edit-icon-column-header"/>
                </TableRow>
              )
          }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Pricing;
