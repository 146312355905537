import { database } from '@app/firebase';
import LocationSearch from '@components/location-search/LocationSearch';
import TextFieldPhoneNumber from '@components/textfield-phone-number/TextFieldPhoneNumber';
import TextFieldPrice from '@components/textfield-price/TextFieldPrice';
import { CATEGORIES } from '@data';
import useCollection from '@hooks/useCollection';
import Sentry from '@integrations/Sentry';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import UserSchema, { initialValues as _initialValues } from '@schema/UserSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import Protected from '@ui/components/protected/Protected';
import IconEyeClosed from '@ui/icons/imaterial/base/Eye Crossed.svg';
import IconEyeOpen from '@ui/icons/imaterial/base/Eye Open.svg';
import { isError } from '@ui/utils/typescriptHelpers';
import userFullName from '@ui/utils/userFullName';
import debouncePromise from '@utils/debouncePromise';
import { checkSlug } from '@utils/scannerNextChar';
import PhoneNumber from 'awesome-phonenumber';
import { collection } from 'firebase/firestore';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useMemo, useState } from 'react';
import slugify from 'slugify';

const debounceCheckSlug = debouncePromise(checkSlug, 300);

export const UserForm = ({ onSubmit, initialValues = _initialValues, error, submitText = 'Continue', showPassword = true, showEmail = true }) => {
  const { collection: regions } = useCollection(collection(database, 'regions'));
  const { collection: warehouses, loading: warehousesLoading } = useCollection(collection(database, 'warehouses'));
  const [_showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!_showPassword);

  const warehouseData = useMemo(() => {
    if (warehouses) {
      return warehouses.docs.map(w => w.data());
    }
    return [];
  }, [warehouses, warehousesLoading]);

  return (
    <Formik
      validationSchema={UserSchema}
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldTouched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => {
        if (values.phone?.startsWith('+1')) {
          const pn = new PhoneNumber(values.phone, 'US');

          values.phone = pn.getNumber('significant');
        }

        const setFlag = (flag: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('flags', { ...values.flags, [flag]: event.target.checked });
        };

        const setRegion = (region: guesthouse.Region) => (event: React.ChangeEvent<HTMLInputElement>) => {
          let newRegions = values.regions;

          if (!newRegions || !newRegions.length) {
            newRegions = [];
          }

          if (event.target.checked) {
            newRegions.push(region.docID);
          } else {
            const index = newRegions.indexOf(region.docID);

            if (index > -1) {
              newRegions.splice(index, 1);
            }
          }

          setFieldValue('regions', newRegions);
        };

        const setRole = (role: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('roles', { ...values.roles, [role]: event.target.checked });
        };

        const setNotificationPreference = (notificationType: string, preference: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('notificationPreferences',
            {
              ...values?.notificationPreferences,
              [notificationType]:
              {
                ...values?.notificationPreferences?.[notificationType],
                [preference]: event.target.checked
              }
            }
          );
        };

        const updateSlug = async (firstname, lastname, company) => {
          const username = userFullName({ firstname, lastname, company }, true);

          const slug = slugify(username, {
            strict: true,
            lower: true,
          });

          let existingSlugs = [];

          try {
            existingSlugs = await debounceCheckSlug(slug, 'users');
          } catch (e) {
            Sentry.captureException(e);
            return;
          }


          if (existingSlugs.length) {
            let n = 1;
            let incrememtedSlug = slug;

            while (existingSlugs.indexOf(incrememtedSlug) > -1) {
              incrememtedSlug = `${slug}-${n}`;
              n += 1;
            }

            setFieldValue('slug', incrememtedSlug);
          } else {
            setFieldValue('slug', slug);
          }
        };

        return (
          <form onSubmit={handleSubmit}>
            <Field
              fullWidth
              data-test="firstname"
              name="firstname"
              label="First name"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
              onChange={async (e) => {
                setFieldValue('firstname', e.target.value);
                setFieldTouched('firstname', true);
                updateSlug(e.target.value, values.lastname, values.company);
              }}
            />

            <Field
              fullWidth
              data-test="lastname"
              name="lastname"
              label="Last name"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
              onChange={async (e) => {
                setFieldValue('lastname', e.target.value);
                setFieldTouched('lastname', true);
                updateSlug(values.firstname, e.target.value, values.company);
              }}
            />

            {showEmail && (
              <Field
                fullWidth
                data-test="email"
                name="email"
                label="Email"
                type="email"
                component={TextField}
                margin="normal"
                variant="outlined"
              />
            )}

            {showPassword && (
              <Field
                fullWidth
                data-test="password"
                name="password"
                label="Password"
                type={_showPassword ? 'text' : 'password'}
                component={TextField}
                margin="normal"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword}
                      >
                        {_showPassword ? <IconEyeClosed /> : <IconEyeOpen />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            )}

            <Field
              fullWidth
              data-test="phone"
              name="phone"
              label="Phone Number"
              type="text"
              component={TextFieldPhoneNumber}
              margin="normal"
              variant="outlined"
              customInput={TextField}
              defaultValue={values.phone}
              onBlur={() => {
                setFieldTouched('phone', true);
              }}
              onValueChange={(values) => {
                setFieldValue('phone', values.value);
              }}
            />
            {touched.phone && errors.phone && 
              <FormHelperText error />
            }

            <Field
              fullWidth
              data-test="company"
              name="company"
              label="Company"
              type="company"
              component={TextField}
              margin="normal"
              variant="outlined"
              onChange={async (e) => {
                setFieldValue('company', e.target.value);
                setFieldTouched('company', true);
                updateSlug(values.firstname, values.lastname, e.target.value);
              }}
            />

            <Field
              fullWidth
              disabled
              data-test="slug"
              name="slug"
              label="URL"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <span>
                    {'https://www.guesthouseshop.com/shop/makers/'}
                    &lrm;
                  </span>
                ),
              }}
            />

            <LocationSearch
              margin="normal"
              disabled={isSubmitting}
              name="location"
              label="Location"
              defaultValue={values.location?.address}
              autocompleteRequestOptions={{
                types: ['(cities)'],
                componentRestrictions: {
                  country: 'us'
                }
              }}
              onLocationChange={(location) => {
                setFieldValue('location', location);
              }}
            />

            <Field
              fullWidth
              data-test="website"
              name="website"
              label="Website"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
            />

            <Field
              fullWidth
              data-test="instagram_handle"
              name="instagram_handle"
              label="Instagram Handle"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    @
                  </InputAdornment>
                )
              }}
            />

            <FormControl
              margin="normal"
              component="fieldset"
              style={{ display: 'block' }}
            >
              <FormLabel component="legend">
                Notifications
              </FormLabel>

              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked={values?.notificationPreferences?.messages?.email || false}
                      data-test="receive-emails"
                      value="Message Emails"
                      onChange={setNotificationPreference('messages', 'email')}
                    />}
                    label="Message Emails"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked={values?.notificationPreferences?.messages?.sms || false}
                      data-test="receive-message-texts"
                      value="Message Texts"
                      onChange={setNotificationPreference('messages', 'sms')}
                    />}
                    label="Message Texts"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked={values?.notificationPreferences?.timeline_reminders?.email || false}
                      data-test="receive-timeline-reminder-emails"
                      value="Timeline Reminder Emails"
                      onChange={setNotificationPreference('timeline_reminders', 'email')}
                    />}
                    label="Timeline Reminder Emails"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked={values?.notificationPreferences?.timeline_reminders?.sms || false}
                      data-test="receive-timeline-reminder-texts"
                      value="Timeline Reminder Texts"
                      onChange={setNotificationPreference('timeline_reminders', 'sms')}
                    />}
                    label="Timeline Reminder Texts"
                  />
                </Grid>
              </Grid>
              
            </FormControl>

            <FormControl
              margin="normal"
              component="fieldset"
              style={{ display: 'block' }}
            >
              <FormLabel component="legend">
                Feature Flags
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox
                    defaultChecked={values.flags?.calendars}
                    value="Calendars"
                    onChange={setFlag('calendars')}
                  />}
                  label="Calendars"
                />
              </FormGroup>
            </FormControl>

            {regions && regions.docs && regions.docs.length ?
              <FormControl
                margin="normal"
                component="fieldset"
                style={{ display: 'block' }}
              >
                <FormLabel component="legend">
                  Regions
                </FormLabel>

                <Grid container>
                  {
                    regions.docs.map((region) => {
                      const regionData = region.data() as guesthouse.Region;
                      const isChecked = values?.regions?.length > 0 && values?.regions?.indexOf(regionData.docID) > -1;

                      return (
                        <Grid
                          key={regionData.docID}
                          item
                          xs={6}
                        >
                          <FormControlLabel
                            control={<Checkbox
                              defaultChecked={isChecked}
                              data-test="region"
                              value={regionData.name}
                              onChange={setRegion(regionData)}
                            />}
                            label={regionData.name}
                          />
                        </Grid>
                      );
                    })
                  }
                </Grid>
              </FormControl>
              : null}

            {warehouseData.length &&
              <Protected allowedRoles={['admin', 'design_manager']}>
                <Autocomplete
                  fullWidth
                  id="warehouse"
                  defaultValue={initialValues?.preferred_warehouse}
                  options={warehouseData}
                  getOptionLabel={(option: Partial<guesthouse.Warehouse>) => option.name || ''}
                  renderInput={(params) => {
                    return (
                      <Field
                        {...params}
                        fullWidth
                        name="warehouse"
                        label="Preferred Warehouse"
                        type="text"
                        component={TextField}
                        margin="dense"
                        variant="outlined"
                      />
                    );
                  }}
                  onChange={(e, value) => {
                    setFieldValue('preferred_warehouse', value);
                  }}
                />
              </Protected>
            }

            <FormControl
              margin="normal"
              component="fieldset"
              style={{ display: 'block' }}
            >
              <FormLabel component="legend">
                Role
              </FormLabel>

              <Grid container>
                <Grid
                  item
                  xs={6}
                >
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked={values.roles?.realtor}
                      data-test="realtor"
                      value="Realtor"
                      onChange={setRole('realtor')}
                    />}
                    label="Realtor"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked={values.roles?.maker}
                      data-test="maker"
                      value="Maker"
                      onChange={setRole('maker')}
                    />}
                    label="Maker"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked={values.roles?.designer}
                      data-test="designer"
                      value="Designer"
                      onChange={setRole('designer')}
                    />}
                    label="Designer"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >

                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked={values.roles?.design_manager}
                      data-test="design manager"
                      value="Design Manager"
                      onChange={setRole('design_manager')}
                    />}
                    label="Design Manager"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked={values.roles?.website_manager}
                      data-test="website manager"
                      value="Website Manager"
                      onChange={setRole('website_manager')}
                    />}
                    label="Website Manager"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked={values.roles?.customer_support}
                      data-test="customer support"
                      value="Customer Support"
                      onChange={setRole('customer_support')}
                    />}
                    label="Customer Support"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked={values.roles?.media_manager}
                      data-test="media manager"
                      value="Media Manager"
                      onChange={setRole('media_manager')}
                    />}
                    label="Media Manager"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >

                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked={values.roles?.pricing}
                      data-test="pricing"
                      value="Pricing"
                      onChange={setRole('pricing')}
                    />}
                    label="Pricing"
                  />
                </Grid>
                <Protected allowedRoles={['*']}>
                  <Grid
                    item
                    xs={6}
                  >
                    <FormControlLabel
                      control={<Checkbox
                        defaultChecked={values.roles?.admin}
                        data-test="admin"
                        value="Admin"
                        onChange={setRole('admin')}
                      />}
                      label="Admin"
                    />
                  </Grid>
                </Protected>
              </Grid>
            </FormControl>

            {values.roles?.maker && (
              <FormControl
                fullWidth
                variant="outlined"
                margin="normal"
                error={touched.productCategory && Boolean(errors.productCategory)}
              >
                <InputLabel id="productCategory-label">
                  Product Categories
                </InputLabel>

                <Select
                  multiple
                  data-test="productCategory"
                  labelId="productCategory-label"
                  id="productCategory"
                  value={values.productCategory || []}
                  label="Product Categories"
                  renderValue={selected => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}
                      >
                        {(selected as string[]).map(value => (
                          <Chip
                            key={value}
                            label={value}
                            style={{ margin: 2 }}
                          />
                        ))}
                      </div>
                    );
                  }}
                  onChange={(e) => {
                    setFieldTouched('productCategory', true);
                    setFieldValue('productCategory', e.target.value);
                  }}
                >
                  {CATEGORIES.map(category => (
                    <MenuItem
                      key={category.id}
                      value={category.id}
                    >
                      {category.title}
                    </MenuItem>
                  ))}
                </Select>

                {touched.productCategory && Boolean(errors.productCategory) && (
                  <FormHelperText error>
                    {errors.productCategory}
                  </FormHelperText>
                )}
              </FormControl>
            )}

            {values.roles?.maker && (
              <Field
                fullWidth
                multiline
                data-test="shippingPolicy"
                name="shipping_policy"
                label="Shipping Policy"
                type="text"
                component={TextField}
                margin="normal"
                variant="outlined"
                onChange={async (e) => {
                  setFieldValue('shipping_policy', e.target.value);
                  setFieldTouched('shipping_policy', true);
                }}
              />
            )}

            {values.roles?.maker && (
              <Field
                fullWidth
                multiline
                data-test="returnPolicy"
                name="return_policy"
                label="Return Policy"
                type="text"
                component={TextField}
                margin="normal"
                variant="outlined"
                onChange={async (e) => {
                  setFieldValue('return_policy', e.target.value);
                  setFieldTouched('return_policy', true);
                }}
              />
            )}

            {values.roles?.maker && (
              <Field
                fullWidth
                data-test="shippingPricePerProduct"
                name="shipping_price_per_product"
                label="Shipping Price (Per Product)"
                type="text"
                component={TextFieldPrice}
                margin="normal"
                variant="outlined"
                customInput={TextField}
                defaultValue={values.shipping_price_per_product}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="This price will be applied once for each item in the cart that belongs to this maker."
                      >
                        <HelpOutlineIcon style={{ color: 'rgba(0,0,0,0.54)', height: 16, width: 16 }} />
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
                onValueChange={(values) => {
                  const { value } = values;

                  setFieldValue('shipping_price_per_product', Number(value));
                }}
              />
            )}

            {values.roles?.maker && (
              <Field
                fullWidth
                data-test="shippingPricePerOrder"
                name="shipping_price_per_order"
                label="Shipping Price (Per Order)"
                type="text"
                component={TextFieldPrice}
                margin="normal"
                variant="outlined"
                customInput={TextField}
                defaultValue={values.shipping_price_per_order}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="This price will be applied one time per order if the order contains this maker's products."
                      >
                        <HelpOutlineIcon style={{ color: 'rgba(0,0,0,0.54)', height: 16, width: 16 }} />
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
                onValueChange={(values) => {
                  const { value } = values;

                  setFieldValue('shipping_price_per_order', Number(value));
                }}
              />
            )}

            {error && (
              <FormHelperText error>
                {isError(error) ? error.message : error}
              </FormHelperText>
            )}

            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length)}
                aria-label={submitText}
                data-test="add-user-submit"
                tooltip={makeErrors(errors, touched)?.length && (
                  <TooltipErrors
                    errors={errors}
                    touched={touched}
                  />
                )}
              >
                {submitText}
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(UserForm);
