import React, { SVGProps } from 'react';

const SvgGuesthouseLogoIconColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 28"
    {...props}
  >
    <path
      d="M19 1a5 5 0 10.001 10.001 5 5 0 000-10.001"
      fill="#ede4e2"
    />
    <path
      d="M1 8.706v10.588c0 4.169 4.475 7.59 10 7.706V1C5.475 1.115 1 4.537 1 8.706"
      fill="#cb8e83"
    />
    <path
      d="M14 27c5.527-.12 10-3.711 10-8.087V14H14v13z"
      fill="#83a5b0"
    />
  </svg>
);

export default SvgGuesthouseLogoIconColor;
