import makeStyles from '../../utils/makeStyles';

export default makeStyles()((theme) => ({
  iconGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      flex: 'auto',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      flex: 'auto',
      marginRight: theme.spacing(5),
      flexDirection: 'row',
      marginBottom: 'auto',
      justifyContent: 'center',
    }
  },
  iconSvg: {
    height: 25,
    width: 'auto',
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      height: 25,
      marginRight: theme.spacing(2),
      marginBottom: 'auto',
    }
  },
  iconText: {
    fontSize: 14,
    fontFamily: theme.gh_vars.circular,
    fontWeight: 600,
  }
}));
