import FormHelperText from '@mui/material/FormHelperText';
import MetadataSchema, { initialValues as _initialValues } from '@schema/MetadataSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors } from '@ui/components/form-errors/FormErrors';
import { isError } from '@ui/utils/typescriptHelpers';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';


const MetadataForm = ({ onSubmit, initialValues = _initialValues, error, submitText = 'Submit' }) => {

  return (
    <Formik
      validationSchema={MetadataSchema}
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        handleSubmit,
        isSubmitting,
      }) => {

        return (
          <form onSubmit={handleSubmit}>
            <div>

              <Field
                fullWidth
                name="h1"
                label="Page H1"
                type="text"
                component={TextField}
                margin="normal"
                variant="outlined"
              />

              <Field
                fullWidth
                name="pageTitle"
                label="Page Title"
                type="text"
                component={TextField}
                margin="normal"
                variant="outlined"
              />

              <Field
                fullWidth
                name="metaTitle"
                label="Metadata Title"
                type="text"
                component={TextField}
                margin="normal"
                variant="outlined"
              />

              <Field
                fullWidth
                multiline
                rows={3}
                name="metaDescription"
                label="Metadata Description"
                type="text"
                component={TextField}
                margin="normal"
                variant="outlined"
              />

              {error && (
                <FormHelperText error>
                  {isError(error) ? error.message : error}
                </FormHelperText>
              )}
              <div style={{ paddingTop: 20 }}>
                <SubmitButton
                  fullWidth
                  isSubmitting={isSubmitting}
                  disabled={Boolean(Object.keys(errors).length)}
                  aria-label={submitText}
                  tooltip={!!Object.keys(errors).length && (
                    <div style={{ textAlign: 'center' }}>
                      {makeErrors(errors, touched, false).map((e, i) => (
                        <div key={i}>
                          {e}
                        </div>
                      ))}
                    </div>
                  )}
                >
                  {submitText}
                </SubmitButton>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default MetadataForm;
