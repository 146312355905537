import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  wrapper: {
    width: '100%',
    fontFamily: theme.gh_vars.mabryMono,
    fontSize: 13,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(3),
      maxWidth: 320,
      width: '100%',
    },
  },
}));
