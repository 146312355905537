import Skeleton from '@mui/material/Skeleton';
import React from 'react';

type HomeRowCardSkeletonProps = {
  className?: string;
  style?: React.CSSProperties;
}

const HomeRowCardSkeleton: React.FC<HomeRowCardSkeletonProps> = (props) => {
  const { className, style } = props;

  return (
    <Skeleton
      variant="rectangular"
      width="100%"
      height={80}
      style={style}
      className={className}
    />
  );
};

export default HomeRowCardSkeleton;
