import { GridProps } from '@mui/material/Grid';
import React, { SyntheticEvent } from 'react';

type RoomSetProps = {
  className?: string;
  style?: React.CSSProperties;
  spacing?: number;
  afterGrid?: () => JSX.Element | null;
  gridItemProps?: GridProps;
  gridProps?: GridProps;
  roomSets: guesthouse.RoomSet[] | guesthouse.AlgoliaRoomSet[];
  onRoomsetClick?: (e: SyntheticEvent<HTMLDivElement>, roomSet: guesthouse.AlgoliaRoomSet) => void;
  href?: (roomSet: Partial<guesthouse.RoomSet>) => string;
  imageHeight?: number | string;
}

export const defaultProps: Partial<RoomSetProps> = {
  spacing: 12,
  gridProps: {},
  gridItemProps: {},
  roomSets: [],
};

export default RoomSetProps;
