import { auth } from '@app/firebase';
import { RouterLink } from '@components/link-behavior/LinkBehavior';
import EmailForm from '@forms/Email';
import useRouter from '@hooks/useRouter';
import GuestHouseLogoFull from '@images/guesthouse-logo-full';
import GuestHouseLogoIcon from '@images/guesthouse-logo-icon';
import Sentry from '@integrations/Sentry';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import routes from '@routes';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import { signInWithEmailLink } from 'firebase/auth';
import React from 'react';

import useStyles from './ConfirmEmail.style';

const ConfirmEmail = () => {
  const { classes } = useStyles();
  const { md } = useBreakpoints();
  const router = useRouter();

  return (
    <div className={classes.wrapper}>
      <Grid
        container
        spacing={1}
        className={classes.top}
      >
        <Grid
          item
          xs={12}
          md={4}
        >
          {
            md
              ? <GuestHouseLogoFull className={classes.logo} />
              : <GuestHouseLogoIcon className={classes.logo} />
          }
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          style={{ textAlign: 'right' }}
        >
          {md && (
            <a
              href="#"
              className={classes.register}
              onClick={() => router.push(routes.register.path)}
            >
              <h3 className={classes.h3}>
                Sign Up
              </h3>
            </a>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={12}
          lg={6}
        >
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          className={classes.gridForm}
        >
          <Paper className={classes.paper}>
            <Typography
              variant="h3Alt"
              component="h1"
              className={classes.formTitle}
            >
              Confirm Email
            </Typography>

            <Typography
              align="center"
              color="textSecondary"
              display="block"
            >
              Please provide your email for confirmation
            </Typography>

            <EmailForm
              onSubmit={(email) => {
                return signInWithEmailLink(auth, email, window.location.href)
                  .then(() => {
                    window.localStorage.removeItem('emailForSignIn');

                    if (router.location.state?.next) {
                      router.push(router.location.state.next);
                    } else {
                      router.push(routes.feed.path);
                    }
                  })
                  .catch(Sentry.captureException);
              }}
            />

            {!md && (
              <div style={{ paddingTop: 20, textAlign: 'center' }}>
                <Link
                  to={routes.register.path}
                  className={classes.register}
                  component={RouterLink}
                >
                  <h3 className={classes.h3}>
                    Sign Up
                  </h3>
                </Link>
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(ConfirmEmail);
