import { functions } from '@app/firebase';
import { RouterLink } from '@components/link-behavior/LinkBehavior';
import EmailForm from '@forms/Email';
import useRouter from '@hooks/useRouter';
import GuestHouseLogoFull from '@images/guesthouse-logo-full';
import GuestHouseLogoIcon from '@images/guesthouse-logo-icon';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Routes from '@routes';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import { validateEmailExists } from '@ui/utils/validators';
import makeAppUrl from '@utils/makeAppUrl';
import { httpsCallable } from 'firebase/functions';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';

import useStyles from './ResetPassword.style';

const sendResetPasswordLink = httpsCallable(functions, 'http-sendResetPasswordLink');

const validateEmailExistsDebounce = debounce(validateEmailExists, 500);

const ResetPassword = () => {
  const { classes } = useStyles();
  const { md } = useBreakpoints();
  const router = useRouter();
  const [resetPasswordSent, setResetPasswordSent] = useState(false);

  return (
    <div className={classes.wrapper}>
      <Grid
        container
        spacing={1}
        className={classes.top}
      >
        <Grid
          item
          xs={12}
          md={4}
        >
          {
            md
              ? <GuestHouseLogoFull className={classes.logo} />
              : <GuestHouseLogoIcon className={classes.logo} />
          }
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          style={{ textAlign: 'right' }}
        >
          {md && (
            <Link
              to={Routes.register.path}
              className={classes.register}
              component={RouterLink}
            >
              <h3 className={classes.h3}>
                Sign Up
              </h3>
            </Link>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={12}
          lg={6}
        >
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          className={classes.gridForm}
        >
          <Paper className={classes.paper}>
            <Typography
              variant="h3Alt"
              component="h1"
              className={classes.formTitle}
            >
              Reset Password
            </Typography>

            {
              resetPasswordSent
                ? (
                  <Typography
                    align="center"
                    color="textSecondary"
                    display="block"
                  >
                    Please check your email to complete your password reset.
                  </Typography>
                )
                : (
                  <EmailForm
                    validate={validateEmailExistsDebounce}
                    onSubmit={(email) => {
                      return sendResetPasswordLink({
                        email: email,
                        next: router.location.state?.next || makeAppUrl('/'),
                      })
                        .then(() => setResetPasswordSent(true));
                    }}
                  />
                )
            }


            {!md && (
              <div style={{ paddingTop: 20, textAlign: 'center' }}>
                <Link
                  to={Routes.register.path}
                  className={classes.register}
                  component={RouterLink}
                >
                  <h3 className={classes.h3}>
                    Sign Up
                  </h3>
                </Link>
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(ResetPassword);
