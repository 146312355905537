
import { database } from '@app/firebase';
import UserContext from '@context/UserContext';
import useCenterPoint from '@hooks/useCenterPoint';
import useCollection from '@hooks/useCollection';
import useGeoCollection from '@hooks/useGeoCollection';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import FormErrors, { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import Protected from '@ui/components/protected/Protected';
import toJsDate from '@ui/utils/toJsDate';
import makeOfferLabel from '@utils/makeOfferLabel';
import endOfDay from 'date-fns/endOfDay';
import { collection } from 'firebase/firestore';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';

import { DefaultFirestoreConverter } from '../../../types/DefaultFirestoreConverter';
import { InventoryDialogProps } from './InventoryDialog.props';


const InventorySchema = Yup.object().shape({
  quantity: Yup.number()
    .min(1, 'Quantity must be greater than 0')
    .max(50, 'Quantity must be less than or equal to 50')
    .typeError('Quantity must be a valid number')
    .positive('Value must be a positive number'),
  offer: Yup.object().nullable(),
  warehouse: Yup.object().nullable(),
});

export const InventoryDialog: React.FC<InventoryDialogProps> = (props) => {
  const {
    resolve,
    reject,
    userContext,
    product,
    title,
    initialValues,
    showQuantity,
  } = props;

  const [open, setOpen] = useState(true);
  const [coords, radius] = useCenterPoint(userContext.activeRegion?.centerPoint);
  const [offer, setOffer] = useState<guesthouse.ProductOffer>();
  const [arrivalDate, setArrivalDate] = useState<guesthouse.Date>();
  const [purchaseDate, setPurchaseDate] = useState<guesthouse.Date>();

  const { docs: warehouses, loading: warehousesLoading } = useGeoCollection<guesthouse.Warehouse>(
    collection(database, 'warehouses').withConverter(new DefaultFirestoreConverter<guesthouse.Warehouse>()),
    {
      center: coords,
      radius,
    }
  );

  const { collection: offersCollection, loading: offersLoading } = useCollection<guesthouse.ProductOffer>(
    collection(database, `products/${product.id}/offers`).withConverter(new DefaultFirestoreConverter<guesthouse.ProductOffer>())
  );

  const offersData = useMemo(() => {
    if (offersCollection?.docs?.length) {
      return offersCollection.docs.map(p => p.data());
    }
    return [];
  }, [offersLoading, offersCollection?.docs?.length]);


  const warehouseData = useMemo(() => {
    if (warehouses) {
      return warehouses.map(w => w.data());
    }
    return [];
  }, [warehousesLoading]);

  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        onClose={() => {
          setOpen(false);
          reject();
        }}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            validationSchema={InventorySchema}
            initialValues={initialValues}
            onSubmit={(values) => {
              resolve(values);
              setOpen(false);
            }}
          >
            {({
              errors,
              touched,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <Form>
                  <Autocomplete
                    fullWidth
                    id="offer"
                    defaultValue={initialValues?.offer}
                    options={offersData}
                    getOptionLabel={makeOfferLabel}
                    renderInput={(params) => {
                      return (
                        <Field
                          {...params}
                          fullWidth
                          name="offer"
                          label="Offer"
                          type="text"
                          component={TextField}
                          margin="dense"
                          variant="outlined"
                        />
                      );
                    }}
                    onChange={(e, value) => {
                      setFieldValue('offer', value);
                      setOffer(value as guesthouse.ProductOffer);
                    }}
                  />

                  {showQuantity && (!offer?.dropship) && (
                    <Field
                      fullWidth
                      id="quantity"
                      name="quantity"
                      label="Quantity"
                      type="number"
                      component={TextField}
                      margin="dense"
                      variant="outlined"
                    />
                  )}

                  <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
                    {!offer?.dropship && (
                      <>
                        <Autocomplete
                          fullWidth
                          id="warehouse"
                          defaultValue={initialValues?.warehouse}
                          options={warehouseData}
                          getOptionLabel={(option: Partial<guesthouse.Warehouse>) => option.name || ''}
                          renderInput={(params) => {
                            return (
                              <Field
                                {...params}
                                fullWidth
                                name="warehouse"
                                label="Warehouse"
                                type="text"
                                component={TextField}
                                margin="dense"
                                variant="outlined"
                              />
                            );
                          }}
                          onChange={(e, value) => {
                            setFieldValue('warehouse', value);
                          }}
                        />

                        <Field
                          fullWidth
                          id="pickLocation"
                          name="pickLocation"
                          label="Pick Location"
                          type="string"
                          component={TextField}
                          margin="dense"
                          variant="outlined"
                        />
                      </>
                    )}

                  </Protected>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Arrival Date"
                      value={
                        arrivalDate || initialValues?.arrival_date ?
                          toJsDate(arrivalDate || initialValues?.arrival_date || null)
                          : null
                      }
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          margin: 'normal',
                          helperText: '',
                          style: {
                            width: '100%'
                          }
                        }
                      }}
                      onChange={(date) => {
                        if (date === null) {
                          setFieldValue('arrival_date', undefined);
                          setArrivalDate(undefined);
                        } else {
                          setFieldValue('arrival_date', endOfDay(date));
                          setArrivalDate(endOfDay(date));
                        }
                      }}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Purchase Date"
                      value={
                        purchaseDate || initialValues?.purchase_date ?
                          toJsDate(purchaseDate || initialValues?.purchase_date || null)
                          : null
                      }
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          margin: 'normal',
                          helperText: '',
                          style: {
                            width: '100%'
                          }
                        }
                      }}
                      onChange={(date) => {
                        if (date === null) {
                          setFieldValue('purchase_date', undefined);
                          setPurchaseDate(undefined);
                        } else {
                          setFieldValue('purchase_date', endOfDay(date));
                          setPurchaseDate(endOfDay(date));
                        }
                      }}
                    />
                  </LocalizationProvider>

                  <Field
                    fullWidth
                    id="purchase_price"
                    name="purchase_price"
                    label="Purchase Price"
                    type="number"
                    component={TextField}
                    margin="dense"
                    variant="outlined"
                  />

                  <FormErrors
                    errors={errors}
                    touched={touched}
                  />

                  <div style={{ paddingTop: 20 }}>
                    <SubmitButton
                      fullWidth
                      data-test="product-add-inventory-submit"
                      isSubmitting={isSubmitting}
                      disabled={Boolean(Object.keys(errors).length)}
                      aria-label="Confirm"
                      tooltip={makeErrors(errors, touched)?.length && (
                        <TooltipErrors
                          errors={errors}
                          touched={touched}
                        />
                      )}
                    >
                      Confirm
                    </SubmitButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            size="small"
            onClick={() => {
              setOpen(false);
              reject(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};

InventoryDialog.defaultProps = {
  title: 'Add Product Inventory',
  showQuantity: true,
  initialValues: { quantity: 1 } as guesthouse.ProductInventory,
};
