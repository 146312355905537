import { database } from '@app/firebase';
import { collection as firestoreCollection,getDocs, query, where } from 'firebase/firestore';

/**
 * Returns the end string to use in a "starts with" style look up fro Firestore db.
 * 
 * See: https://stackoverflow.com/a/46574143/2253270
 * 
 * @param str 
 */
export const scannerNextChar = (str: string): string => {
  const strlength = str.length;
  const strFrontCode = str.slice(0, strlength - 1);
  const strEndCode = str.slice(strlength - 1, str.length);

  return strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
};

/**
 * Take a slug and return all possible collisions
 */
export const checkSlug = async (slug : string , collection: string, key = 'slug') : Promise<string[]> => {

  const endStr = scannerNextChar(slug);
  const c = await getDocs(query(firestoreCollection(database, collection)
    ,where(key, '>=', slug)
    ,where(key, '<', endStr)));

  const slugs = c?.docs.map(p => p.data().slug) || [];

  return slugs;
};
