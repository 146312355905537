
import * as Yup from 'yup';

export type WarehouseFormValues = {
  address?: string;
  place_id?: string;
  sqft?: number;
  guesthouseManaged?: boolean;
  deinstallRequestEmailRecipients?: guesthouse.User[];
  googleCalendarId?: string;
};

export const initialValues: WarehouseFormValues = {};

const WarehouseSchema = Yup.object().shape({
  address: Yup.string()
    .nullable(),
  place_id: Yup.string()
    .nullable()
    .required('Address is required'),
  sqft: Yup.number()
    .typeError('Square footage must be a valid number')
    .positive('Square footage must be a positive number')
    .required('Square footage is required'),
  guesthouseManaged: Yup.boolean(),
  deinstallRequestEmailRecipients: Yup.array()
    .required('Deinstall notification recipients required'),
  googleCalendarId: Yup.string()
    .nullable()
});

export default WarehouseSchema;
