import Typography from '@mui/material/Typography';
import Protected from '@ui/components/protected/Protected';
import { useStyles } from '@ui/utils/makeStyles';
import React from 'react';

import { HomeRowCardColumnWidth } from './HomeRowCardColumnWidth';

type HomeRowCardHeadersProps = {
  renderActionsHeader?: boolean;
}

const HomeRowCardHeaders: React.FC<HomeRowCardHeadersProps> = (props) => {
  const { theme } = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(0, 1),
      }}
    >
      <Typography
        component="div"
        color="textSecondary"
        display="block"
        style={{
          fontSize: 14,
          fontFamily: theme.gh_vars.circular,
          flex: HomeRowCardColumnWidth.ADDRESS,
          textAlign: 'left'
        }}
      >
        Address
      </Typography>

      <Typography
        component="div"
        color="textSecondary"
        display="block"
        style={{
          fontSize: 14,
          fontFamily: theme.gh_vars.circular,
          flex: HomeRowCardColumnWidth.TEAM,
          textAlign: 'left',
        }}
      >
        Team
      </Typography>

      <Typography
        component="div"
        color="textSecondary"
        display="block"
        style={{
          fontSize: 14,
          fontFamily: theme.gh_vars.circular,
          flex: HomeRowCardColumnWidth.DATES,
          textAlign: 'left',
        }}
      >
        Install date
      </Typography>
      
      <Typography
        component="div"
        color="textSecondary"
        display="block"
        style={{
          fontSize: 14,
          fontFamily: theme.gh_vars.circular,
          flex: HomeRowCardColumnWidth.DATES,
          textAlign: 'left',
        }}
      >
        Deinstall date
      </Typography>

      <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
        <Typography
          component="div"
          color="textSecondary"
          display="block"
          style={{
            fontSize: 14,
            fontFamily: theme.gh_vars.circular,
            flex: HomeRowCardColumnWidth.DATES,
            textAlign: 'left',
          }}
        >
          Days on Market
        </Typography>
      </Protected>

      {props.renderActionsHeader && (
        <Typography
          component="div"
          color="textSecondary"
          display="block"
          style={{
            fontSize: 14,
            fontFamily: theme.gh_vars.circular,
            flex: HomeRowCardColumnWidth.ACTION_ITEMS,
            textAlign: 'right',
          }}
        >
          Action items
        </Typography>
      )}
    </div>
  );
};

export default HomeRowCardHeaders;
