import LocationSearch from '@components/location-search/LocationSearch';
import UserSearch from '@components/user-search/UserSearch';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import WarehouseSchema, { initialValues as _initialValues } from '@schema/WarehouseSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import Protected from '@ui/components/protected/Protected';
import theme from '@ui/theme';
import { isError } from '@ui/utils/typescriptHelpers';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';

const WarehouseForm = ({ onSubmit, initialValues = _initialValues, error, submitText = 'Continue' }) => {

  return (
    <Formik
      validationSchema={WarehouseSchema}
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
      }) => {

        return (
          <form onSubmit={handleSubmit}>

            <LocationSearch
              disabled={isSubmitting}
              label="Address"
              defaultValue={initialValues.address}
              onLocationChange={(location) => {
                setFieldValue('address', location.address, true);
                setFieldValue('place_id', location.place_id, true);
                setFieldValue('_geoloc', location._geoloc, true);
                setFieldValue('vicinity', location.vicinity, true);
                setFieldValue('geohash', location.geohash, true);
              }}
            />

            <Field
              fullWidth
              name="name"
              label="Name"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
            />

            <Field
              fullWidth
              name="sqft"
              label="Square feet"
              type="number"
              component={TextField}
              margin="normal"
              variant="outlined"
            />

            <UserSearch
              multiple={true}
              label="Deinstall Notification Recipients"
              filterRoles={['admin', 'design_manager']}
              defaultValue={values?.deinstallRequestEmailRecipients}
              style={{ marginTop: theme.spacing(1.5) }}
              onChange={(e, value: guesthouse.User[]) => {
                setFieldValue('deinstallRequestEmailRecipients', value);
              }}
            />

            <Field
              fullWidth
              name="googleCalendarId"
              label="Google Calendar ID"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
            />

            <Protected allowedRoles={['designer', 'admin', 'design_manager', 'website_manager', 'customer_support']}>
              <FormControl
                margin="normal"
                component="fieldset"
                style={{ display: 'block' }}
              >
                <FormGroup row>
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked={values.guesthouseManaged}
                      value="Guest House Managed"
                      onChange={(e) => setFieldValue('guesthouseManaged', e.target.checked)}
                    />}
                    label="Guest House Managed"
                  />
                  <FormHelperText>
                    Guest House managed warehouses will be available for all designers.
                  </FormHelperText>
                </FormGroup>
              </FormControl>
            </Protected>

            {error && (
              <FormHelperText error>
                {isError(error) ? error.message : error}
              </FormHelperText>
            )}
            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length)}
                aria-label={submitText}
                tooltip={makeErrors(errors, touched)?.length && (
                  <TooltipErrors
                    errors={errors}
                    touched={touched}
                  />
                )}
              >
                {submitText}
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(WarehouseForm);
