import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import MoodboardReviewDialog from '@components/dialogs/MoodboardReviewDialog';
import NoteDialog from '@components/dialogs/NoteDialog';
import TimelineItemDialog from '@components/dialogs/TimelineItemDialog';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import useTimeline from '@hooks/useTimeline';
import Sentry from '@integrations/Sentry';
import Typography from '@mui/material/Typography';
import Protected from '@ui/components/protected/Protected';
import CheckmarkIcon from '@ui/icons/imaterial/base/Checkmark.svg';
import checkRoles from '@ui/utils/checkRoles';
import formatDate from '@ui/utils/formatDate';
import { useStyles } from '@ui/utils/makeStyles';
import userFullName from '@ui/utils/userFullName';
import newId from '@utils/newId';
import { collection, deleteDoc, doc, setDoc, Timestamp, updateDoc, writeBatch } from 'firebase/firestore';
import React, { useContext, useMemo } from 'react';

import TimelineItem from './TimelineItem';

type TimelineProps = {
  home: guesthouse.Home;
}

type ClientReviewRet = {
  /**
   * Title for the client timeline item section
   */
  clientReviewTitle: string;
  /**
   * Message displayed when the client's timeline items are complete
   */
  clientReviewComplete: string;

}

const Timeline: React.FC<TimelineProps> = (props) => {
  const { home } = props;
  const { theme } = useStyles();
  const userContext = useContext<UserContext>(UserContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);

  const { clientReview, internalReview, upcoming, complete, unapprovedMoodboards } = useTimeline(home.docID);

  const timelineItemDeleteHandler = (timelineItem: guesthouse.TimelineItem) => {
    asyncRender(ConfirmDialog, {
      title: 'Are you sure you want to delete this task?',
      confirmText: 'Yes, Delete',
    })
      .then(async () => await deleteDoc(doc(collection(doc(collection(database, 'homes'), home.docID), 'timeline'), timelineItem.docID)))
      .then(() => notificationContext.setContext({ open: true, message: 'Task deleted' }))
      .catch((e) => {
        if (e) {
          Sentry.captureException(e);
          notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
        }
      });
  };

  const { clientReviewTitle, clientReviewComplete } = useMemo((): ClientReviewRet => {
    if (userContext.roles?.realtor) {
      return {
        clientReviewTitle: 'Ready for review',
        clientReviewComplete: '👍 You\'re all caught up!'
      };
    }
    return {
      clientReviewTitle: 'Client review',
      clientReviewComplete: `👍 ${userFullName(home?.realtor || home?.client) || 'Client'} is all caught up!`
    };
  }, [userContext.roles, home?.realtor, home?.client]);

  const internalUser = checkRoles(['admin', 'design_manager', 'designer'], userContext.roles);

  return (
    <>
      <Protected allowedRoles={['realtor', 'admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
        {clientReview.length > 0 ? (
          <div
            style={{
              marginBottom: theme.spacing(4)
            }}
          >
            <Typography
              component="div"
              variant="h6Alt"
              style={{
                fontSize: 14,
                marginBottom: theme.spacing(2)
              }}
            >
              {clientReviewTitle}
            </Typography>
            {
              clientReview.map(tli => {
                if (tli.name === 'review_moodboards') {
                  return unapprovedMoodboards.map((moodboard) => {
                    return (
                      <TimelineItem
                        key={tli.docID}
                        timelineItem={tli}
                        title={tli.title}
                        subtitle={tli.due_date ? formatDate(tli.due_date, 'Pp') : ''}
                        style={{
                          marginBottom: theme.spacing(1),
                          backgroundColor: theme.palette.background.paper,
                        }}
                        onDelete={internalUser ? timelineItemDeleteHandler : undefined}
                        onClick={() => {
                          asyncRender(MoodboardReviewDialog, {
                            home,
                            moodboard: moodboard,
                            userContext,
                          })
                            .then(async () => {
                              const updatedTimelineItem: Partial<guesthouse.TimelineItem> = {
                                owner: userContext.data,
                                completed_date: new Date(),
                                completed_by: userContext.user.uid,
                              };

                              const updatedMoodboard: Partial<guesthouse.Moodboard> = {
                                approved: true,
                                approved_by: userContext.user.uid,
                              };
                              const p1 = await updateDoc(
                                doc(
                                  collection(doc(collection(database, 'homes'), home.docID), 'timeline'),
                                  tli.docID)
                                , updatedTimelineItem);
                                  
                              const p2 = await updateDoc(
                                doc(
                                  collection(doc(collection(database, 'homes'),home.docID),'moodboards'),
                                  moodboard.docID
                                )
                                , updatedMoodboard);

                              return Promise.all([p1, p2]);
                            })
                            .catch(e => {
                              if (e) {
                                Sentry.captureException(e);
                              }
                            });
                        }}
                      />
                    );
                  });
                }

                return (
                  <TimelineItem
                    key={tli.docID}
                    timelineItem={tli}
                    title={tli.title}
                    subtitle={tli.due_date ? formatDate(tli.due_date, 'Pp') : ''}
                    style={{
                      marginBottom: theme.spacing(1),
                      backgroundColor: theme.palette.background.paper,
                    }}
                    onDelete={internalUser ? timelineItemDeleteHandler : undefined}
                    onClick={() => {
                      asyncRender(TimelineItemDialog, {
                        userContext,
                        initialValues: tli,
                        title: `${tli.title}`,
                      })
                        .then(async (value) => {
                          const timelineItem: Partial<guesthouse.TimelineItem> = {
                            due_date: value?.due_date || null,
                            completed_date: value?.completed_date || null,
                            completed_by: value?.completed_date && userContext.user?.uid ? userContext.user.uid : null,
                            ...value
                          };

                          return await setDoc(doc(collection(doc(collection(database, 'homes'), home.docID), 'timeline'), timelineItem.docID), timelineItem, { merge: true });
                        })
                        .catch(e => {
                          if (e) {
                            Sentry.captureException(e);
                          }
                        });
                    }}
                  />
                );
              })
            }
          </div>
        ) : (
          <div
            style={{
              marginBottom: theme.spacing(4)
            }}
          >
            <Typography
              component="div"
              variant="h6Alt"
              style={{
                fontSize: 14,
                marginBottom: theme.spacing(2)
              }}
            >
              {clientReviewTitle}
            </Typography>
            <Typography
              component="div"
              style={{
                fontSize: 13,
                fontFamily: theme.gh_vars.mabryMono
              }}
            >
              {clientReviewComplete}
            </Typography>
          </div>
        )}
      </Protected>

      <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
        {internalReview.length > 0 ? (
          <div
            style={{
              marginBottom: theme.spacing(4)
            }}
          >
            <Typography
              component="div"
              variant="h6Alt"
              style={{
                fontSize: 14,
                marginBottom: theme.spacing(2)
              }}
            >
              Ready for review
            </Typography>
            {
              internalReview.map(tli => {
                return (
                  <TimelineItem
                    key={tli.docID}
                    timelineItem={tli}
                    title={tli.title}
                    subtitle={tli.due_date ? formatDate(tli.due_date, 'Pp') : ''}
                    style={{
                      marginBottom: theme.spacing(1),
                      backgroundColor: theme.palette.background.paper,
                    }}
                    onDelete={internalUser ? timelineItemDeleteHandler : undefined}
                    onClick={() => {
                      asyncRender(TimelineItemDialog, {
                        userContext,
                        initialValues: tli,
                        title: `${tli.title}`,
                      })
                        .then(async (value) => {
                          const timelineItem: Partial<guesthouse.TimelineItem> = {
                            due_date: value?.due_date || null,
                            completed_date: value?.completed_date || null,
                            completed_by: value?.completed_date && userContext.user?.uid ? userContext.user.uid : null,
                            ...value
                          };

                          return await setDoc(doc(collection(doc(collection(database, 'homes'), home.docID), 'timeline'), timelineItem.docID), timelineItem, { merge: true });
                        })
                        .catch(e => {
                          if (e) {
                            Sentry.captureException(e);
                          }
                        });
                    }}
                  />
                );
              })
            }
          </div>
        ) : (
          <div
            style={{
              marginBottom: theme.spacing(4)
            }}
          >
            <Typography
              component="div"
              variant="h6Alt"
              style={{
                fontSize: 14,
                marginBottom: theme.spacing(2)
              }}
            >
              Ready for review
            </Typography>
            <Typography
              component="div"
              style={{
                fontSize: 13,
                fontFamily: theme.gh_vars.mabryMono
              }}
            >
              &#128077; You&apos;re all caught up!
            </Typography>
          </div>
        )}
      </Protected>

      <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support', 'realtor']}>
        {upcoming.length > 0 && (
          <div
            style={{
              marginBottom: theme.spacing(4)
            }}
          >
            <Typography
              component="div"
              variant="h6Alt"
              style={{
                fontSize: 14,
                marginBottom: theme.spacing(2)
              }}
            >
              Upcoming
            </Typography>
            {upcoming.map(tli => {
              if (tli.name === 'add_lockbox_info' && userContext.roles.realtor) {
                return (
                  <TimelineItem
                    key={tli.docID}
                    timelineItem={tli}
                    title={tli.title}
                    subtitle={tli.due_date ? formatDate(tli.due_date, 'Pp') : ''}
                    style={{
                      marginBottom: theme.spacing(1),
                      backgroundColor: theme.palette.background.paper,
                    }}
                    onDelete={internalUser ? timelineItemDeleteHandler : undefined}
                    onClick={() => {
                      asyncRender(NoteDialog, {
                        userContext,
                        initialValues: tli,
                        title: `${tli.title}`,
                      })
                        .then(async (text: string) => {
                          const batch = writeBatch(database);

                          if (text) {
                            const note: guesthouse.Note = {
                              created: Timestamp.fromDate(new Date()),
                              text,
                              owner: userContext.data,
                              type: 'lockbox',
                              docID: newId()
                            };

                            const updatedTimelineItem: Partial<guesthouse.TimelineItem> = {
                              owner: userContext.data,
                              completed_date: new Date(),
                              completed_by: userContext.user.uid,
                            };

                            batch.update(doc(collection(doc(collection(database, 'homes'), home.docID), 'timeline'), tli.docID), updatedTimelineItem);
                            batch.set(doc(collection(doc(collection(database, 'homes'), home.docID), 'notes')), note);

                            return await batch.commit();
                          }
                        })
                        .catch(e => {
                          if (e) {
                            Sentry.captureException(e);
                          }
                        });
                    }}
                  />
                );
              }
              return (
                <TimelineItem
                  key={tli.docID}
                  timelineItem={tli}
                  title={tli.title}
                  subtitle={tli.due_date ? formatDate(tli.due_date, 'Pp') : ''}
                  style={{
                    marginBottom: theme.spacing(1),
                    backgroundColor: theme.palette.background.paper,
                    cursor: !userContext.roles.realtor ? 'pointer' : 'default'
                  }}
                  onDelete={internalUser ? timelineItemDeleteHandler : undefined}
                  onClick={() => {
                    if (userContext.roles.realtor && !userContext.roles.admin) {
                      return null;
                    }
                    asyncRender(TimelineItemDialog, {
                      userContext,
                      initialValues: tli,
                      title: `${tli.title}`,
                    })
                      .then(async (value) => {
                        const timelineItem: Partial<guesthouse.TimelineItem> = {
                          due_date: value?.due_date || null,
                          completed_date: value?.completed_date || null,
                          completed_by: value?.completed_date && userContext.user?.uid ? userContext.user.uid : null,
                          ...value
                        };

                        return await setDoc(doc(collection(doc(collection(database, 'homes'), home.docID), 'timeline'), tli.docID), timelineItem, { merge: true });
                      })
                      .catch(e => {
                        if (e) {
                          Sentry.captureException(e);
                        }
                      });
                  }}
                />
              );
            })}
          </div>
        )}
      </Protected>
      <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support', 'realtor']}>
        {complete.length > 0 && (
          <div
            style={{
              marginBottom: theme.spacing(4)
            }}
          >
            <Typography
              component="div"
              variant="h6Alt"
              style={{
                fontSize: 14,
                marginBottom: theme.spacing(2)
              }}
            >
              Completed
            </Typography>
            {complete.map(tli => {
              return (
                <TimelineItem
                  key={tli.docID}
                  timelineItem={tli}
                  title={tli.title}
                  subtitle={formatDate(tli.completed_date, 'Pp')}
                  style={{
                    marginBottom: theme.spacing(1),
                    backgroundColor: '#EBEBEB',
                    cursor: !userContext.roles.realtor ? 'pointer' : 'default'
                  }}
                  icon={<CheckmarkIcon style={{ width: 20, height: 20 }} />}
                  onDelete={internalUser ? timelineItemDeleteHandler : undefined}
                  onClick={() => {
                    if (userContext.roles.realtor) {
                      return null;
                    }
                    asyncRender(TimelineItemDialog, {
                      userContext,
                      initialValues: tli,
                      title: `${tli.title}`,
                    })
                      .then(async (value) => {
                        const timelineItem: Partial<guesthouse.TimelineItem> = {
                          due_date: value?.due_date || null,
                          completed_date: value?.completed_date || null,
                          completed_by: value?.completed_date && userContext.user?.uid ? userContext.user.uid : null,
                          ...value
                        };

                        return await setDoc(doc(collection(doc(collection(database, 'homes'), home.docID), 'timeline'), tli.docID), timelineItem, { merge: true });
                      })
                      .catch(e => {
                        if (e) {
                          Sentry.captureException(e);
                        }
                      });
                  }}
                />
              );
            })}
          </div>
        )}
      </Protected>
    </>
  );
};

export default Timeline;
