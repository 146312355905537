import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()({
  searchPreview: {
    display: 'block',
    border: '1px solid var( --color-neutral-0 )',
    fontFamily: 'arial, sans-serif',
    padding: '10px 20px',
    wordWrap: 'break-word',
  },
  searchPreviewTitle: {
    color: '#1a0dab',
    fontSize: 20,
    lineHeight: '26px',
    maxWidth: 616,
    marginBottom: 7,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  searchPreviewUrl: {
    color: '#3c4043',
    fontSize: 14,
    lineHeight: '18.2px',
    maxWidth: 616,
    marginBottom: 8,
  },
  searchPreviewDescription: {
    color: '#3c4043',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22.12px',
    maxWidth: 616,
  }
});
