import { database } from '@app/firebase';
import getLocationKeys from '@utils/getLocationKeys';
import { collection, doc, setDoc, Timestamp } from 'firebase/firestore';

import newId from './newId';

type InventoryType = 'internal' | 'external';
type RequestType = 'roomset' | 'individual';

const inventoryToProductRequest = (inventoryType: InventoryType, requestType: RequestType,  home: guesthouse.Home, room: guesthouse.Room, selectedInventory: guesthouse.ProductInventory[], scheduled: Date, user: guesthouse.User): guesthouse.ProductRequest[] => {
  const created = new Date();
  const batchID = newId();
  const quantity = selectedInventory.length;
  const requests: guesthouse.ProductRequest[] = [];
  const status: 'RECEIVED' | 'PENDING' = inventoryType === 'internal' ? 'RECEIVED' : 'PENDING';
  let i = 0;
  let roomSetRequest = false;

  if (requestType === 'roomset') {
    roomSetRequest = true;
  }

  for (const inventory of selectedInventory) {
    const requestDocID = newId();
    const productRequest: guesthouse.ProductRequest = {
      docID: requestDocID,
      created: Timestamp.fromDate(created),
      batchID,
      controller: i === 0,
      inventory,
      quantity,
      product: inventory.product,
      home,
      room,
      user,
      status,
      scheduleDate: Timestamp.fromDate(scheduled),
      pricesheet: true,
      productDeinstalled: false,
      roomSetRequest,
      ...getLocationKeys(home),
    };

    i++;
    requests.push(productRequest);
  }

  return requests;
};

const createProductRequests = async (inventoryType: InventoryType, requestType: RequestType, home: guesthouse.Home, room: guesthouse.Room, selectedInventory: guesthouse.ProductInventory[], scheduled: Date, user: guesthouse.User ) => {
  const productRequests = inventoryToProductRequest(inventoryType, requestType, home, room, selectedInventory, scheduled, user);
  const promises = [];

  for (const productRequest of productRequests) {
    const inventory = productRequest.inventory;

    promises.push(setDoc(doc(collection(database, `products/${inventory.product.docID}/inventory/${inventory.docID}/requests`), productRequest.docID), productRequest));
  }

  return Promise.all(promises);
};

export default createProductRequests;
