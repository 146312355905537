import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import NotificationContext from '@context/NotificationContext';
import useEditPromoHandler from '@hooks/useEditPromoHandler';
import Sentry from '@integrations/Sentry';
import IconMoreHoriz from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Protected from '@ui/components/protected/Protected';
import { collection, deleteDoc, doc } from 'firebase/firestore';
import React, { useContext, useState } from 'react';

type PromoCodeActionsProps = {
  docID: string;
}

const PromoCodeActions: React.FC<PromoCodeActionsProps> = ({ docID }) => {
  const editPromoHandler = useEditPromoHandler();
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="promo code actions"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <IconMoreHoriz />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
          <MenuItem
            onClick={() => {
              editPromoHandler(docID);
              handleClose();
            }}
          >
            Edit
          </MenuItem>
        </Protected>
        <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
          <MenuItem
            onClick={() => {
              asyncRender(ConfirmDialog, {
                title: 'Are you sure you want to delete this promo code?',
                confirmText: 'Yes, Delete',
              })
                .then(() => deleteDoc(doc(collection(database, 'coupons'), docID)))
                .then(() => notificationContext.setContext({ open: true, message: 'Promo Code deleted' }))
                .catch((e) => {
                  if (e) {
                    Sentry.captureException(e);
                    notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                  }
                });

              handleClose();
            }}
          >
            Remove
          </MenuItem>
        </Protected>
      </Menu>
    </>
  );
};

export default React.memo(PromoCodeActions);
