import { auth } from '@app/firebase';
import Sentry from '@integrations/Sentry';
import routes from '@routes';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { useEffect } from 'react';

import useRouter from './useRouter';


export default function useFirebaseEmailHandler() {
  const router = useRouter();
  
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');

      if (!email && router.location.pathname !== routes.confirmEmail.path) {
        router.push({ pathname: routes.confirmEmail.path, search: router.location.search, state: { confirmEmail: true, next: router.location.state?.next } });
      } else {
        signInWithEmailLink(auth, email, window.location.href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');

            if (router.location.state?.next) {
              router.push(router.location.state.next);
            } else {
              router.push(routes.feed.path);
            }
          })
          .catch(Sentry.captureException);
      }
    } else if (searchParams.get('mode')) {
      const emailAction = {
        mode: searchParams.get('mode'),
        lang: searchParams.get('lang'),
        oobCode: searchParams.get('oobCode'),
        apiKey: searchParams.get('apiKey'),
        continueUrl: searchParams.get('continueUrl'),
      };

      switch (searchParams.get('mode')) {
        case 'resetPassword':
          router.push({
            pathname: routes.newPassword.path,
            state: {
              emailAction,
            }
          });
          break;
      }
    }
  }, []);
}
