import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import Error from '@components/error/Error';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import Sentry from '@integrations/Sentry';
import IconPlus from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useCommonStyles from '@styles/common.style';
import newId from '@utils/newId';
import { collection as firestoreCollection, CollectionReference, doc, setDoc, Timestamp } from 'firebase/firestore';
import React, { useContext } from 'react';

import WarehouseActions from './WarehouseActions';
import { WarehouseDialog } from './WarehouseDialog';
import WarehousesSkeleton from './Warehouses.skeleton';

const Warehouses = () => {
  const { classes: common, theme } = useCommonStyles();
  const userContext = useContext<UserContext>(UserContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);

  const query = firestoreCollection(database, 'warehouses') as CollectionReference<guesthouse.Warehouse>;

  const { collection, loading, error } = useCollection<guesthouse.Warehouse>(query);

  if (error) {
    return (
      <div className={common.contentSpacing}>
        <Error />
      </div>
    );
  }

  if (loading) {
    return (
      <div className={common.contentSpacing}>
        <WarehousesSkeleton />
      </div>
    );
  }

  const docs = collection && collection.docs;

  let title = '0 Warehouses';

  if (docs && docs.length) {
    title = `${docs.length} Warehouse${docs.length > 1 ? 's' : ''}`;
  }

  return (
    <div className={common.contentSpacing}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Box
            display="flex"
            alignItems="center"
          >
            <Typography
              component="h1"
              variant="h3Alt"
              style={{ marginRight: theme.spacing(1) }}
            >
              {title}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{ marginLeft: theme.spacing(2) }}
              startIcon={<IconPlus />}
              onClick={() => {
                asyncRender(WarehouseDialog, { userContext })
                  .then((result: guesthouse.Warehouse) => {
                    if (result) {
                      const docID = newId();
                      const warehouse: guesthouse.Warehouse = {
                        ...result,
                        docID,
                        created: Timestamp.fromDate(new Date()),
                      };

                      return setDoc(
                        doc(firestoreCollection(database, 'warehouses'), docID),
                        warehouse
                      );
                    }
                  })
                  .catch((e) => {
                    if (e) {
                      Sentry.captureException(e);
                      notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                    }
                  });
              }}
            >
              Add warehouse
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >

        </Grid>
      </Grid>

      {docs?.length
        ? (
          <TableContainer>


            <Table aria-label="warehouses table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    Address
                  </TableCell>
                  <TableCell align="right">
                    Sqft
                  </TableCell>
                  <TableCell align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {docs.map((doc) => {
                  const wh = doc.data() as guesthouse.Warehouse;

                  return (
                    <TableRow key={wh.name}>
                      <TableCell>
                        {wh.name}
                      </TableCell>
                      <TableCell>
                        {wh.address}
                      </TableCell>
                      <TableCell align="right">
                        {wh.sqft}
                      </TableCell>
                      <TableCell
                        width="100"
                        height="60"
                        align="center"
                      >
                        <WarehouseActions warehouse={doc} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )
        : (
          <Typography>
            There&apos;s nothing here yet.
          </Typography>
        )}
    </div>
  );
};

export default React.memo(Warehouses);
