import HomeCardSkeleton from '@components/cards/HomeCard.skeleton';
import Grid from '@mui/material/Grid';
import React from 'react';

import Props, { defaultProps } from './HomeGrid.props';

const HomeGridSkeleton: React.FC<Props> = (props: Props) => {
  const { gridItemProps, gridProps, className, style } = props;
  const numItems = 9;

  return (
    <Grid
      container
      className={className}
      style={style}
      spacing={4} 
      {...gridProps}
    >
      {new Array(numItems).fill(0).map((_, i) => (
        <Grid
          key={i}
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
          {...gridItemProps}
        >
          <HomeCardSkeleton />
        </Grid>
      ))}
    </Grid>
  );
};

HomeGridSkeleton.defaultProps = defaultProps;

export default HomeGridSkeleton;
