import LayersIcon from '@ui/icons/imaterial/base/Layers.svg';
import TableIcon from '@ui/icons/imaterial/editor/Table 11.svg';
import KingBedIcon from '@ui/icons/imaterial/home/King Bed.svg';
import EditIcon from '@ui/icons/imaterial/media/Brush.svg';
import CameraIcon from '@ui/icons/imaterial/media/Camera 01.svg';
import FilmIcon from '@ui/icons/imaterial/media/Film Play.svg';
import WheelIcon from '@ui/icons/imaterial/summer-and-beach/Wheel.svg';

export const STAGING_PRODUCTS: guesthouse.StagingProduct[] = [
  {
    sku: 'FLO',
    title: 'Floorplan',
    description: 'Whole home floorplan, under 6000 sqft',
    image: TableIcon,
  },
  {
    sku: 'FLO6000',
    title: 'Floorplan',
    description: 'Whole home floorplan, over 6000 sqft',
    image: TableIcon,
  },
  {
    sku: 'STA',
    title: 'Staging',
    description: 'Premium furnishings for your home',
    image: KingBedIcon,
  },
  {
    sku: 'DRO',
    title: 'Drone Footage',
    description: 'Aerial drone footage of your home',
    image: WheelIcon,
  },
  {
    sku: 'PHO',
    title: 'Photography',
    description: 'Photos of your home',
    image: CameraIcon
  },
  {
    sku: 'TWI',
    title: 'Twilight Photos',
    description: 'Twilight photos of your home',
    image: CameraIcon
  },
  {
    sku: 'TPE',
    title: 'Virtual Twilight Photography Edit (5-pack)',
    description: 'Virtual Twilight Photography Edit (5-pack)',
    image: EditIcon
  },
  {
    sku: 'VID',
    title: 'Video Tour',
    description: 'Whole home video tour',
    image: FilmIcon,
  },
  {
    sku: 'CON',
    title: 'Consultation',
    description: undefined,
    image: KingBedIcon,
  },
  {
    sku: 'MAT',
    title: 'Matterport (3D Tour)',
    description: '3D virtual tour, under 2000 sqft',
    image: LayersIcon,
  },
  {
    sku: 'MAT6000',
    title: 'Matterport (3D Tour)',
    description: '3D virtual tour, over 6000 sqft',
    image: LayersIcon,
  },
  {
    sku: 'MAT4000',
    title: 'Matterport (3D Tour)',
    description: '3D virtual tour, 4000-5999 sqft',
    image: LayersIcon,
  },
  {
    sku: 'MAT2000',
    title: 'Matterport (3D Tour)',
    description: '3D virtual tour, 2000-3999 sqft',
    image: LayersIcon,
  }
];
