import toJsDate from '@ui/utils/toJsDate';
import format from 'date-fns/format';
import { Timestamp } from 'firebase/firestore';

const formatDate = (date: string | Date | Timestamp, dateFormat='PP', options = {}) : string => {
  const jsDate = toJsDate(date);

  if (jsDate) {
    return format(jsDate, dateFormat, options);
  }

  return '';
};

export default formatDate;
