
import * as Yup from 'yup';

export type HomeFormValues = {
  address?: string;
  unit?: string;
  title?: string;
  slug?: string;
  place_id?: string;
  type?: guesthouse.HomeType;
  beds?: number;
  baths?: number;
  sqft?: number;
  status?: guesthouse.HomeStatus;
  notes?: string;
  install_date?: Date;
  deinstall_date?: Date;
  consult_date?: Date;
  photoshoot_date?: Date;
  zillow_url?: string;
  listing_price?: number;
};

export const initialValues: HomeFormValues = {};

const HomeSchema = Yup.object().shape({
  address: Yup.string()
    .nullable(),
  place_id: Yup.string()
    .nullable()
    .required('Address is required'),
  type: Yup.string()
    .required('Type is required'),
  listing_price: Yup.number()
    .typeError('Listing price must be a valid number')
    .min(0, 'Listing price should be greater than 0'),
  beds: Yup.number()
    .typeError('Number of bedrooms must be a valid number')
    .min(0, 'Number of bedrooms should be greater than 0'),
  baths: Yup.number()
    .typeError('Number of baths must be a valid number')
    .min(0, 'Number of baths should be greater than 0'),
  sqft: Yup.number()
    .typeError('Sqft must be a valid number')
    .min(0, 'Sqft should be greater than 0'),
  notes: Yup.string(),
  zillow_url: Yup.string()
    .nullable()
    .url('Please enter a valid URL, including http:// or https://')
});

export default HomeSchema;
