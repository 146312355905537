import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import UserAvatar from '@ui/components/user-avatar/UserAvatar';
import IconX from '@ui/icons/imaterial/base/X.svg';
import React from 'react';

import useStyles from './TimelineItem.style';

interface TimelineItemProps {
  className?: string;
  style?: React.CSSProperties;
  title: string;
  timelineItem: guesthouse.TimelineItem;
  subtitle?: string;
  icon?: React.ReactElement;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onDelete?: (timelineItem: guesthouse.TimelineItem) => void;
  renderButton?: () => React.ReactElement;
}


const TimelineItem: React.FC<TimelineItemProps> = (props) => {
  const { className, style, timelineItem, onDelete, title, subtitle, icon, onClick, renderButton } = props;
  const { classes, cx, theme } = useStyles({});

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      className={cx(classes.timelineItem, className)}
      style={{
        cursor: typeof onClick === 'function' ? 'pointer' : 'default',
        ...style,
      }}
      onClick={(e) => typeof onClick === 'function' && onClick(e)}
    >
      {icon && (
        <div
          style={{
            marginRight: theme.spacing(1),
            display: 'flex',
          }}
        >
          {icon}
        </div>
      )}

      {timelineItem.owner && (
        <UserAvatar
          user={timelineItem.owner}
          size={30}
          style={{
            marginRight: theme.spacing(1),
          }}
          avatarStyle={{
            fontSize: 14,
          }}
        />
      )}

      <div>
        {title && (
          <Typography
            component="div"
            variant="body2"
            style={{
              top: 0,
              left: 0,
              color: '#080808',
              fontSize: 14,
              lineHeight: 1.4,
              fontWeight: 600,
              fontFamily: theme.gh_vars.circular,
            }}
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            style={{
              top: 0,
              left: 0,
              color: '#666666',
              fontSize: 12,
              lineHeight: 1.4,
              fontWeight: 600,
              fontFamily: theme.gh_vars.circular,
            }}
          >
            {subtitle}
          </Typography>
        )}
      </div>

      {typeof renderButton === 'function' && renderButton()}

      {typeof onDelete === 'function' && (
        <div className={classes.deleteButton}>
          <Tooltip title={'Delete task'}>
            <IconButton
              size="small"
              aria-label="Delete task"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDelete(timelineItem);
              }}
            >
              <IconX />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </Box>
  );
};

TimelineItem.defaultProps = {
  style: {},
};

export default TimelineItem;
