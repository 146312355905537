import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import { RouterLink } from '@components/link-behavior/LinkBehavior';
import ProductGrid from '@components/product-grid/ProductGrid';
import NotificationContext from '@context/NotificationContext';
import useCollection from '@hooks/useCollection';
import useEditPromoHandler from '@hooks/useEditPromoHandler';
import useRouter from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import IconDelete from '@mui/icons-material/Delete';
import IconEdit from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import useCommonStyles from '@styles/common.style';
import CheckIcon from '@ui/icons/imaterial/base/Checkmark.svg';
import theme from '@ui/theme';
import formatDate from '@ui/utils/formatDate';
import newId from '@utils/newId';
import { productPath } from '@utils/paths';
import { collection, deleteDoc, doc, query, where } from 'firebase/firestore';
import formatCurrency from 'format-currency';
import React, { useContext, useMemo } from 'react';

import useStyles from './Promo.style';


interface PromoParams {
  code: string;
}

const couponColumnData: GridColDef[] = [
  {
    flex: 1,
    field: 'userID',
    headerName: 'User',
    renderCell: (params) => {
      return (
        <Link
          component={RouterLink}
          to={`/users/${params.getValue(params.id, 'userID')}`}
        >
          {params.getValue(params.id, 'userID')}
        </Link>
      );
    }
  },
  {
    flex: 1,
    field: 'orderDate',
    headerName: 'Used Date',
    type: 'date',
    filterable: false,
    valueFormatter: (params: GridValueFormatterParams) => {
      // @ts-ignore
      return formatDate(params.value);
    }
  },
  {
    flex: 1,
    field: 'orderID',
    headerName: 'Order',
    type: 'text',
    filterable: false,
    renderCell: (params) => {
      return (
        <Link
          component={RouterLink}
          to={`/orders/${params.getValue(params.id, 'orderID')}`}
        >
          {params.getValue(params.id, 'orderID')}
        </Link>
      );
    }
  },
  {
    width: 100,
    field: 'orderCompleted',
    headerName: ' ',
    filterable: false,
    sortable: false,
    hideSortIcons: true,
    resizable: false,
    headerClassName: 'v-hidden',
    renderCell: (params) => {
      if (params.getValue(params.id, 'orderID')) {
        return (
          <CheckIcon style={{ height: 20, width: 20 }} />
        );
      }
    }
  },
];

const Promo: React.FC = () => {
  const router = useRouter<PromoParams>();
  const { code } = router.params;
  const { classes } = useStyles();
  const { classes: common } = useCommonStyles();
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const editPromoHandler = useEditPromoHandler();

  const { collection: promos } = useCollection(query(collection(database, 'coupons'), where('code', '==', code)));
  const { promoData } = useMemo(() => {
    let promoData;

    if (promos?.docs?.length) {
      promoData = promos.docs[0].data() as guesthouse.Coupon;
    }

    return { promoData };
  }, [promos]);

  const { collection: orders } = useCollection(collection(database, 'orders'), where('coupon.code', '==', promoData?.docID));
  const { collection: usersIncluded } = useCollection(collection(database, `coupons/${promoData?.docID}/usersIncluded`), promoData);
  const { collection: productsIncluded } = useCollection(collection(database, `coupons/${promoData?.docID}/productsIncluded`), promoData);

  const productsIncludedData = useMemo(() => {
    let ret = [];

    if (productsIncluded?.docs?.length) {
      ret = productsIncluded.docs.map(product => product.data());
    }

    return ret;
  }, [productsIncluded]);

  const couponRowData = useMemo(() => {
    const couponDocs = [];

    if (usersIncluded?.docs?.length) {
      orders?.forEach(order => {
        const orderData = order.data();

        couponDocs.push({
          id: newId(),
          orderID: orderData.docID,
          orderDate: orderData.created,
          orderCompleted: true,
          userID: orderData.user.docID
        });
      });

      usersIncluded.docs.forEach(doc => {
        const userData = doc.data() as guesthouse.User;
        const userOrders = couponDocs.filter(doc => doc.userID === userData.docID);

        if (!userOrders.length) {
          couponDocs.push({
            id: newId(),
            userID: userData.docID
          });
        }
      });
    }

    if (!usersIncluded?.docs?.length && orders?.docs.length) {
      orders?.docs.map(order => {
        const orderData = order.data();

        couponDocs.push({
          id: orderData.user.docID,
          orderID: orderData.docID,
          orderDate: orderData.created,
          orderCompleted: true
        });
      });
    }
    return couponDocs;
  }, [orders, usersIncluded]);

  return (
    <div className={common.contentSpacing}>
      <Grid container>

        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Typography
            component="h1"
            variant="h3Alt"
            style={{ marginRight: theme.spacing(2) }}
            display="inline"
          >
            {promoData?.description}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <div className={classes.mobileHorizontalScrollWrapper}>
            <div className={classes.mobileHorizontalScrollContainer}>

              <Tooltip title="Edit">
                <span style={{ marginRight: theme.spacing(1) }}>
                  <IconButton
                    className={classes.editButton}
                    aria-label="edit"
                    onClick={() => editPromoHandler(promoData?.docID)}
                  >
                    <IconEdit />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Permanently delete">
                <span style={{ marginRight: theme.spacing(1) }}>
                  <IconButton
                    aria-label="permanently delete"
                    className={classes.editButton}
                    onClick={() => {
                      asyncRender(ConfirmDialog, {
                        title: 'Delete promotion',
                        content: 'Are you sure want to delete this promotion? This action cannot be undone.',
                        confirmText: 'Yes, Delete',
                      })
                        .then(() => {
                          router.goBack();
                        })
                        .then(() => {
                          return deleteDoc(doc(collection(database, 'coupons'), promoData?.docID));
                        })
                        .then(() => {
                          notificationContext.setContext({ open: true, message: 'Promotion deleted' });
                        })
                        .catch((e) => {
                          if (e) {
                            Sentry.captureException(e);
                            notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                          }
                        });
                    }}
                  >
                    <IconDelete />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </div>
        </Grid>

        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
            md={6}
            style={{ marginBottom: theme.spacing(3) }}
          >
            <List dense>
              <ListItem>
                <ListItemText
                  primary="Code"
                  secondary={promoData?.code}
                />
              </ListItem>

              {promoData?.retail_discount_amount > 0 &&
                <ListItem>
                  <ListItemText
                    primary="Retail Discount Amount"
                    secondary={
                      promoData?.retail_discount_type === 'fixed' ? formatCurrency(promoData?.retail_discount_amount, { format: '%s%v', symbol: '$' })
                        : `${promoData?.retail_discount_amount * 100}%`
                    }
                  />
                </ListItem>
              }

              {promoData?.staging_discount_amount > 0 &&
                <ListItem>
                  <ListItemText
                    primary="Retail Discount Amount"
                    secondary={
                      promoData?.staging_discount_type === 'fixed' ? formatCurrency(promoData?.staging_discount_amount, { format: '%s%v', symbol: '$' })
                        : `${promoData?.staging_discount_amount * 100}%`
                    }
                  />
                </ListItem>
              }

              <ListItem>
                <ListItemText
                  primary="Order Application"
                  secondary={promoData?.order_application}
                />
              </ListItem>

              {promoData?.expires &&
                <ListItem>
                  <ListItemText
                    primary="Expiration Date"
                    secondary={formatDate(promoData?.expires)}
                  />
                </ListItem>
              }

              <ListItem>
                <ListItemText
                  primary="Maximum Uses"
                  secondary={promoData?.max_uses === Infinity ? 'Unlimited' : promoData?.max_uses}
                />
              </ListItem>

            </List>
          </Grid>
        </Grid>
      </Grid>

      {!!couponRowData?.length &&
        <div className={common.overflowSection}>
          <DataGrid
            disableSelectionOnClick
            autoHeight
            rows={couponRowData}
            columns={couponColumnData}
          />
        </div>
      }

      {!!productsIncludedData?.length &&
        <div>
          <Typography
            style={{ margin: theme.spacing(3, 0) }}
          >
            Available Products
          </Typography>
          <Grid container>
            <ProductGrid
              products={productsIncludedData}
              href={productPath}
            />
          </Grid>
        </div>
      }

    </div>
  );
};

export default Promo;
