import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useStyles } from '@ui/utils/makeStyles';
import React from 'react';

import Props, { defaultProps } from './ProductGrid.props';

interface ProductGridSkeletonProps extends Props {
  numItems?: number;
}


const ProductGridSkeleton: React.FC<ProductGridSkeletonProps> = (props: ProductGridSkeletonProps) => {
  const { gridItemProps, gridProps, numItems } = props;
  const { theme } = useStyles();

  return (
    <Grid
      container
      spacing={4}
      style={{
        marginBottom: theme.spacing(props.spacing),
        marginTop: 0 
      }}
      {...gridProps}
    >
      {new Array(numItems).fill(0).map((_, i) => (
        <Grid
          key={i}
          item
          xs={6}
          sm={6}
          md={4}
          lg={3}
          xl={2}
          {...gridItemProps}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height={312}
            style={{ 
              margin: 0, 
              borderRadius: theme.shape.borderRadius
            }}
          />

        </Grid>
      ))}
    </Grid>
  );
};

ProductGridSkeleton.defaultProps = {
  ...defaultProps,
  numItems: 6,
};

export default ProductGridSkeleton;
