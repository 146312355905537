import { database, functions } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import UserDialog from '@components/dialogs/UserDialog';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import Sentry from '@integrations/Sentry';
import formatPhone from '@ui/utils/formatPhone';
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useContext } from 'react';

const setClaims = httpsCallable<guesthouse.functions.SetClaimsRequest, guesthouse.functions.SetClaimsResponse>(functions, 'http-setClaims');
const updateEmail = httpsCallable(functions, 'http-updateEmail');

export default function useEditUserHandler() {
  const userContext = useContext<UserContext>(UserContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);

  return (userId) => new Promise<guesthouse.User>((resolve, reject) => {
    getDoc(doc(database, `users/${userId}`))
      .then((snap) => snap.data())
      .then((user: guesthouse.User) => {
        const initialValues = {
          ...user,
          password: 'DELETEME', // validation hack
        };

        return asyncRender(UserDialog, {
          userContext,
          userFormProps: {
            showPassword: false,
            showEmail: true,
          },
          title: 'Update user',
          initialValues,
          onSubmit: async (values) => {
            const { flags, regions, roles, ...userFormData } = values;

            if (values.email?.length && values.email !== values.email?.toLocaleLowerCase()) {
              values.email = values.email.toLocaleLowerCase();
            }

            if (values.phone) {
              try {
                userFormData.phone = formatPhone(values.phone);
              } catch (e) {
                Sentry.captureException(e);
                notificationContext.setContext({ open: true, severity: 'error', message: 'There was an error updating the users phone number' });
                return;
              }
            }

            if (values.email !== user.email) {
              try {
                await updateEmail({ uid: user.docID, email: values.email });
              } catch (e) {
                Sentry.captureException(e);
                notificationContext.setContext({ open: true, severity: 'error', message: 'There was an error updating the users email address' });
                return;
              }
            }

            // TODO - These will require a more complex update flow.
            //
            delete userFormData.password;
            delete userFormData.email;

            try {
              // Claims values will be set on user data in cloud function
              const claimsResponse = await setClaims({ claims: { flags, regions, roles }, userId: user.docID });
              const { status } = claimsResponse.data;

              if (status !== 'success') {
                notificationContext.setContext({ open: true, severity: 'error', message: 'There was an error updating the user claims' });
                return;
              }
            } catch (e) {
              Sentry.captureException(e);
            }

            try {
              await updateDoc(doc(collection(database, 'users'), user.docID), userFormData)
                .then(() => {
                  notificationContext.setContext({ open: true, message: `User ${values.firstname} ${values.lastname} updated` });
                });
              resolve(values);
            } catch (e) {
              Sentry.captureException(e);
              reject(e);
            }
          }
        });
      });
  });
}
