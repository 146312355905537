import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  eventTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  eventRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5)
  },
  eventIcon: {
    height: 24,
    width: 24,
    marginRight: theme.spacing(1.5)
  }
}));
