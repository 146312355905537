import { database } from '@app/firebase';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import DefaultImage from '@images/product-default.png';
import IconClear from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import routes from '@routes';
import TaggedProduct from '@ui/components/tagged-product/TaggedProduct';
import IconTag from '@ui/icons/imaterial/base/Tag.svg';
import IconWarning from '@ui/icons/imaterial/base/Warning.svg';
import checkRoles from '@ui/utils/checkRoles';
import makeStoreUrl from '@ui/utils/makeStoreUrl';
import slugOrID from '@ui/utils/slugOrID';
import makeAppUrl from '@utils/makeAppUrl';
import { collectionGroup, deleteDoc, query, where } from 'firebase/firestore';
import React, { useContext } from 'react';
import { generatePath } from 'react-router';

import useStyles from './TaggedProducts.style';

type TaggedProductsProps = {
  photo: guesthouse.Photo;
  setSelectedProductTag: React.Dispatch<React.SetStateAction<guesthouse.ProductTag>>
}

const TaggedProducts: React.FC<TaggedProductsProps> = (props) => {
  const { photo, setSelectedProductTag } = props;
  const { classes, theme } = useStyles();
  const userContext = useContext<UserContext>(UserContext);

  const { collection } = useCollection(
    photo?.docID && query(collectionGroup(database, 'productTags')
      ,where('photo.docID', '==', photo?.docID)),
    photo?.docID,
  );

  const length = collection?.docs?.length || 0;

  const tagggedProductHref = (product) => {
    if (checkRoles(['admin', 'designer', 'design_manager', 'website_manager', 'customer_support', 'media_manager'], userContext.roles)) {
      return makeAppUrl(generatePath(routes.product.path, { id: product.docID }));
    }

    return makeStoreUrl(`/shop/products/${slugOrID(product)}`);
  };

  if (length) {
    return (
      <Box>
        <Typography
          className={classes.wrapper}
        >
          {`${length} tagged ${length === 1 ? 'product' : 'products'}`}
        </Typography>
        {collection.docs.map(doc => {
          const productTag = doc.data() as guesthouse.ProductTag;
          const { product, positionX, positionY } = productTag;

          return (
            <TaggedProduct
              key={doc.id}
              defaultProductImage={DefaultImage}
              style={{
                marginBottom: theme.spacing(1),
              }}
              product={product as guesthouse.Product}
              href={tagggedProductHref}
              renderActions={() => {
                return (
                  <Box>
                    {(!positionX || !positionY) ? (
                      <Tooltip title="Photo location missing, click to re-tag">
                        <span>
                          <IconButton
                            size="xs"
                            aria-label="re-tag photo"
                            color="error"
                            onClick={() => setSelectedProductTag(productTag)}
                          >
                            <IconWarning style={{ width: 16, height: 'auto' }} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Change tag location">
                        <span>
                          <IconButton
                            size="xs"
                            aria-label="re-tag photo"
                            onClick={() => setSelectedProductTag(productTag)}
                          >
                            <IconTag style={{ width: 16, height: 'auto' }} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}


                    <Tooltip title="Delete product tag">
                      <span>
                        <IconButton
                          size="xs"
                          aria-label="delete photo tag"
                          onClick={() => deleteDoc(doc.ref)}
                        >
                          <IconClear />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                );
              }}
            />
          );
        })}
      </Box>
    );
  }

  return (
    <Typography
      className={classes.wrapper}
    >
      No tagged products
    </Typography>
  );
};

export default TaggedProducts;
