import Sentry from '@integrations/Sentry';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import PasswordSchema, { initialValues } from '@schema/PasswordSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import IconEyeClosed from '@ui/icons/imaterial/base/Eye Crossed.svg';
import IconEyeOpen from '@ui/icons/imaterial/base/Eye Open.svg';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react';

interface PasswordFormProps {
  onSubmit?: (password?: string) => void;
}

const PasswordForm : React.FC<PasswordFormProps> = (props) => {
  const { onSubmit } = props;
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Formik
      validationSchema={PasswordSchema}
      initialValues={initialValues}
      onSubmit={async (values: PasswordForm) => {
        setError(false);
        const { password } = values;

        try {
          if (typeof onSubmit === 'function') {
            await onSubmit(password);
          }
        } catch (e) {
          Sentry.captureException(e);
          setError(e.message);
        }
      }}
    >
      {({
        errors,
        handleSubmit,
        isSubmitting
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field
              fullWidth
              name="password"
              label="Password"
              data-test="password-field"
              type={showPassword ? 'text' : 'password'}
              component={TextField}
              margin="dense"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <IconEyeClosed /> : <IconEyeOpen />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Field
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              component={TextField}
              margin="dense"
              variant="outlined"
              data-test="password-field-confirm"
            />

            {error && (
              <FormHelperText error>
                {error}
              </FormHelperText>
            )}

            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length)}
                aria-label="Continue"
                data-test="password-continue"
              >
                Continue
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default PasswordForm;
