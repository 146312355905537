import makeStyles from '@ui/utils/makeStyles';

export default makeStyles<{ isAdminView: boolean}>()((theme, { isAdminView }) => ({
  container: {
    display: 'flex',
    height: '100%',
  },
  channelListContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px #DDDDDD solid',
    flex: 1,
    maxHeight: isAdminView ? 'calc(100vh  - 65px)' : null,
  },
  channelListHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 100,
    padding: theme.spacing(0, 4),
  },
  adminChannelListHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 100,
    padding: theme.spacing(0, 4),
  },
  channelListContent: {
    flex: 1,
    overflow: 'auto',
    height: isAdminView ? 'calc(100% - 285px)' : '100%'
  },
  messagesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  messagesHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 73,
    borderBottom: '1px solid #DDDDDD',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6)
    }
  },
  messagesContent: {
    flex: 1,
    overflow: 'auto'
  },
  userSearchContainer: {
    flex: 1,
    height: 40,
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 1.5),
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      width: 360
    }
  },
  groupChatCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
    borderRadius: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  newMessage: {
    border: '1px solid black',
    borderRadius: 10,
    margin: theme.spacing(1.5, 4),
    height: 56,
    padding: theme.spacing(0, 1, 0, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));
