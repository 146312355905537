import { database } from '@app/firebase';
import { AsyncComponentProps } from '@components/async/asyncRender';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { collection, deleteDoc, doc } from 'firebase/firestore';
import React, { useState } from 'react';

interface PricingTierDeleteDialogArgs extends AsyncComponentProps<guesthouse.DbPricingTier>  {
  pricingTier: guesthouse.DbPricingTier
  resolve: () => void;
}

const PricingTierDeleteDialog: React.FC<PricingTierDeleteDialogArgs> = ({ pricingTier, resolve }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Delete Pricing Tier
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this pricing tier? This cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              const ref = doc(collection(database, 'pricing_tiers'), pricingTier.docID);

              await deleteDoc(ref);
              handleClose();
              resolve();
            }}
          >
            Yes
          </Button>
          <Button onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PricingTierDeleteDialog;
