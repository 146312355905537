
import * as Yup from 'yup';

export type RegionFormValues = {
  docID?: string;
  name?: string;
  centerPoint?: {
    address: string;
    place_id: string;
    vicinity: string;
    _geoloc: guesthouse.LatLng;
  }
};

export const initialValues: RegionFormValues = {};

const RegionSchema = Yup.object().shape({
  docID: Yup.string()
    .required('Required')
    .nullable(),
  name: Yup.string()
    .required('Region name is required'),
  vicinity: Yup.array().of(Yup.string()),
  centerPoint: Yup.object({
    address: Yup.string().nullable(),
    place_id: Yup.string().nullable().required('Location is required'),
    vicinity: Yup.string().nullable(),
    _geoloc: Yup.object().shape({
      lat: Yup.number(),
      lng: Yup.number(),
    }).nullable()
  })
    .required('Location is required'),
  aroundRadius: Yup.number()
    .required('Center point radius is required')
});

export default RegionSchema;
