import AppContext from '@context/AppContext';
import UserContext from '@context/UserContext';
import useRouter from '@hooks/useRouter';
import { Typography } from '@mui/material';
import theme from '@ui/theme';
import checkRoles from '@ui/utils/checkRoles';
import { getUnreadChannelsAdmin } from '@utils/getUnreadChannelsAdmin';
import React, { useContext, useEffect, useState } from 'react';
import { Channel, ChannelFilters } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';

import ChannelListContainerSkeleton from './ChannelListContainer.skeleton';


type ChannelListContainerProps = {
  setTriggerGetChannels: React.Dispatch<React.SetStateAction<boolean>>,
  triggerGetChannels: boolean,
}

const ChannelListContainer = () => (props: ChannelListContainerProps) => (HOCProps) => {
  const {
    setTriggerGetChannels,
    triggerGetChannels,
  } = props;
  const {
    children,
    loading,
    error,
  } = HOCProps;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userContext = useContext<UserContext>(UserContext);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const appContext = useContext<AppContext>(AppContext);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { client, channel } = useChatContext();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [channels, setChannels] = useState<Channel[]>();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [adminUnreadChannels, setAdminUnreadChannels] = useState<Channel[]>();

  const getChannels = async () => {
    if (userContext?.isAdminView) {
      await getUnreadChannelsAdmin(client, setAdminUnreadChannels);
      setTriggerGetChannels(false);
    } else {
      let filters: ChannelFilters;
      
      if (checkRoles(['admin'], userContext?.roles)) {
        filters = {
          $or: [
            { type: 'home' },
            { members: { $in: [userContext?.user?.uid], }, },
          ],
        };

      } else {
        filters = {
          members: {
            $in: [userContext?.user?.uid]
          },
        };
      }

      const channels = await client.queryChannels(filters);

      setChannels(channels);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    client.on('channel.deleted', getChannels);
    client.on('channel.hidden', getChannels);
    client.on('channel.truncated', getChannels);
    client.on('channel.updated', getChannels);
    client.on('channel.visible', getChannels);


    return () => {
      client.off('channel.deleted', getChannels);
      client.off('channel.hidden', getChannels);
      client.off('channel.truncated', getChannels);
      client.off('channel.updated', getChannels);
      client.off('channel.visible', getChannels);

    };
  }, [client]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (router.pathname?.includes('/messages')) {
      getChannels();
    }
  }, [JSON.stringify(userContext?.roles), JSON.stringify(userContext?.streamUser), JSON.stringify(`${channel?.data}`), triggerGetChannels, router.pathname]);

  if (appContext.skeleton || loading) {
    return (
      <ChannelListContainerSkeleton />
    );
  }

  if (error) {
    return (
      <Typography
        component="div"
        fontFamily={theme.gh_vars.circular}
        fontSize={14}
      >
        An unexpected error has occured
      </Typography>
    );
  }

  if (!channels?.length && !userContext?.isAdminView) {
    return (
      <Typography
        component="div"
        fontFamily={theme.gh_vars.circular}
        fontSize={14}
      >
        You aren&apos;t in any channels yet
      </Typography>
    );
  }

  if (!adminUnreadChannels?.length && userContext?.isAdminView) {
    return (
      <Typography
        component="div"
        fontFamily={theme.gh_vars.circular}
        fontSize={14}
      >
        No channels currently need attention
      </Typography>
    );
  }

  return (
    <div style={{ overflowY: 'scroll' }}>
      {children}
    </div>
  );

};

export default ChannelListContainer;
