
import UserContext from '@context/UserContext';
import RoomForm from '@forms/Room';
import Sentry from '@integrations/Sentry';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { RoomFormValues } from '@schema/RoomSchema';
import React, { useState } from 'react';


type RoomDialogArgs = {
  title: string;
  homeID: string;
  homeSlug: string;
  userContext: UserContext;
  initialValues: RoomFormValues;
  onSubmit: (values: guesthouse.Room) => Promise<unknown>;
  resolve: (values: RoomFormValues) => void;
  reject: (e?: Error | boolean) => void;
}

const RoomDialog: React.FC<RoomDialogArgs> = ({
  resolve,
  reject,
  userContext,
  title = 'Update room information',
  onSubmit,
  initialValues,
  homeID,
  homeSlug
}) => {
  const [open, setOpen] = useState(true);
  const [error, setError] = useState(null);

  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          {title}
        </DialogTitle>

        <DialogContent>
          <RoomForm
            initialValues={initialValues}
            error={error}
            submitText="Save"
            homeSlug={homeSlug}
            homeID={homeID}
            onSubmit={(values) => {
              return onSubmit(values)
                .then((values) => {
                  setOpen(false);
                  return values;
                })
                .then(resolve)
                .catch(e => {
                  setError(e.message);
                  Sentry.captureException(e);
                });
            }}
          />
        </DialogContent>

        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            size="small"
            onClick={() => {
              setOpen(false);
              reject(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};

export default RoomDialog;
