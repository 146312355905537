import UserContext from '@context/UserContext';
import WarehouseForm from '@forms/Warehouse';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';

export const WarehouseDialog = ({ resolve, reject, userContext }) => {
  const [open, setOpen] = useState(true);

  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        onClose={() => {
          resolve();
          setOpen(false);
        }}
      >
        <DialogTitle>
          Add warehouse
        </DialogTitle>
        <DialogContent>
          <WarehouseForm
            error={false}
            initialValues={{}}
            onSubmit={(warehouse: guesthouse.Warehouse) => {
              if (warehouse.googleCalendarId) {
                warehouse.googleCalendarId = warehouse.googleCalendarId?.replace(/\s+/g, ' ').trim();
              }
              
              setOpen(false);
              resolve(warehouse);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              reject();
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};
