import { SearchOptions, SearchResponse } from '@algolia/client-search';
import { CATEGORIES } from '@data';
import useRouter from '@hooks/useRouter';
import algolia from '@integrations/Algolia';
import Sentry from '@integrations/Sentry';
import { Filters } from '@utils/algoliaSearch';
import { algoliaSearchInventory } from '@utils/algoliaSearch';
import { UseFacetSearchOptions } from '@utils/algoliaSearch';
import makeBooleanFilter from '@utils/makeBooleanFilter';
import makeFacetFilters from '@utils/makeFacetFilters';
import makeIndexName from '@utils/makeIndexName';
import makeNumericFilter from '@utils/makeNumericFilter';
import qs from 'querystring';
import { useEffect, useState } from 'react';

import useDebounceEffect from './useDebounceEffect';

const index = algolia.initIndex(makeIndexName('inventory'));

const categories2 = [];

CATEGORIES.map(category => {
  category.subcategories && category.subcategories.map(category2 => {
    categories2.push(category2);
  });
});

export default function useFacetSearchInventory(options: UseFacetSearchOptions<guesthouse.AlgoliaInventory>) {
  const router = useRouter();

  const [results, setResults] = useState<SearchResponse<guesthouse.AlgoliaInventory>>(options.initResults);
  const [q, setQuery] = useState<string>(options.query?.q?.toString());
  const [startDate, setStartDate] = useState<string | string[]>(options.query.startDate);
  const [category, setCategory] = useState<string | string[]>(options.query.category);
  const [category2, setCategory2] = useState<string | string[]>(options.query.category2);


  const doSearch = () => {
    const obj = {
      q,
      category,
      category2,
      startDate,
    };

    for (const k in obj) {
      if (!obj[k] || (Array.isArray(obj[k]) && !obj[k].length)) {
        delete obj[k];
      }
    }

    const search = qs.stringify(obj);

    const url = Object.keys(obj).length ? `${router.pathname}?${search}` : router.pathname;

    if (options.useRouter) {
      router.push(url);
    }

    const facetFilters = makeFacetFilters({
      'product.category': category,
      'product.category2': category2,
      ...options.extraFacetFilters,
    });


    const numericFilters = makeNumericFilter({

    }) as unknown as (string | string[])[];

    if (startDate) {
      numericFilters.push([`availabilityStart_timestamp > ${startDate}`]);
    }

    const booleanFilters = makeBooleanFilter({
    });

    const searchOptions: SearchOptions = {
      facetingAfterDistinct: true,
      distinct: 1,
      facetFilters,
      numericFilters: numericFilters,
      filters: booleanFilters,
      facets: [
        'product.category',
        'product.category2'
      ],
      ...options.searchOptions,
    };

    algoliaSearchInventory(index, q, searchOptions)
      .then(setResults)
      .catch(Sentry.captureException);
  };

  useEffect(doSearch, []);
  useDebounceEffect(doSearch, 1000, [
    JSON.stringify(options.searchOptions),
    q,
    category,
    category2,
    startDate,
  ]);

  const filters: Filters = {
    category: {
      id: 'category',
      title: 'Categories',
      options: CATEGORIES,
      values: category,
      counts: results.facets?.['product.category'],
      setter: setCategory,
    },
    category2: {
      id: 'category2',
      title: 'Categories2',
      options: categories2,
      values: category2,
      counts: results.facets?.['product.category2'],
      setter: setCategory2,
    }
  };

  return {
    results,
    filters,
    query: q,
    setQuery,
    category,
    category2,
    startDate,
    setStartDate,
  };
}
