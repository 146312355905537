import Sentry from '@integrations/Sentry';
import newId from '@utils/newId';
import { channelPath } from '@utils/paths';
import { useState } from 'react';
import { useChatContext } from 'stream-chat-react';

import useRouter from './useRouter';

function useChatMembersHandler() {
  const [error, setError] = useState<Error | boolean>(false);
  const { client, setActiveChannel } = useChatContext();
  const router = useRouter();

  const setChannel = async (channelMemberDocIDs: string[], chatName: string = null) => {
    try {
      const channels = await client.queryChannels({
        type: 'messaging',
        members: {
          $eq: channelMemberDocIDs
        }
      }, {}, { limit: 1 });
      let channel;

      if (channels.length) {
        channel = channels[0];
      } else if (chatName) {
        channel = client.channel('messaging', newId(), {
          name: chatName,
          last_sent_by_external_user: false,
          resolved_gh_sla: false,
        });
      } else {
        channel = client.channel('messaging', newId(), {
          last_sent_by_external_user: false,
          resolved_gh_sla: false,
        });
      }

      await channel.watch();
      await channel.addMembers(channelMemberDocIDs);
      setActiveChannel(channel);

      router.replace(channelPath(channel));

    } catch (e) {
      Sentry.captureException(e);
      setError(e);
    }
  };

  return {
    error,
    setChannel
  };
}

export default useChatMembersHandler;
