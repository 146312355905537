export const COLORS: guesthouse.Color[] = [
  {
    id: 'white',
    title: 'White',
  },
  {
    id: 'black',
    title: 'Black',
  },
  {
    id: 'beige',
    title: 'Beige',
  },
  {
    id: 'brown',
    title: 'Brown',
  },
  {
    id: 'blue',
    title: 'Blue',
  },
  {
    id: 'red',
    title: 'Red',
  },
  {
    id: 'green',
    title: 'Green',
  },
  {
    id: 'gray',
    title: 'Gray'
  },
  {
    id: 'gold',
    title: 'Gold'
  },
  {
    id: 'silver',
    title: 'Silver'
  },
  {
    id: 'pink',
    title: 'Pink'
  },
  {
    id: 'purple',
    title: 'Purple'
  },
  {
    id: 'multicolor',
    title: 'Multicolor'
  }
];
