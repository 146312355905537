import { GridProps } from '@mui/material/Grid';
import { SyntheticEvent } from 'react';

type Props = {
  spacing?: number;
  afterGrid?: () => JSX.Element | null;
  gridItemProps?: GridProps;
  gridProps?: GridProps;
  products?: guesthouse.Product[];
  showMaker?: boolean;
  showLocation?: boolean;
  href?: (product: guesthouse.Product) => string;
  onProductClick?: (e: SyntheticEvent<HTMLDivElement>, product: guesthouse.Product) => void;
  renderOverlay?: (product: guesthouse.Product) => JSX.Element | null;
  stagingDate?: Date;
  // If dateNeededFilter is true, we do not want to display product cards in the grid with zero available quantity.
  // This is a client-side filter since the Algolia fields for 'available' are a summary of the product's inventory
  // and do not take warehouse location into consideration 
  dateNeededFilter?: boolean;
}

const defaultProps: Partial<Props> = {
  spacing: 12,
  gridProps: {},
  gridItemProps: {},
  products: [],
  showMaker: true,
  showLocation: true,
};

export default Props;
export { defaultProps };
