import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import IconArrowLeft from '@ui/icons/imaterial/arrows/Arrow Left.svg';
import IconArrowRight from '@ui/icons/imaterial/arrows/Arrow Right.svg';
import theme from '@ui/theme';
import React from 'react';

type fetchAction = 'initial' | 'next' | 'prev';

type PaginationControllerProps = {
  page: number;
  loading: boolean;
  setPage: (value: React.SetStateAction<number>) => void;
  setFetchAction: React.Dispatch<React.SetStateAction<fetchAction>>;
}

const PaginationController = (props: PaginationControllerProps) => {
  const { page, loading, setPage, setFetchAction } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(3)
      }}
    >
      <Typography>
        Page
        {' '}
        {page + 1}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <IconButton 
          style={{
            height: 30,
            width: 30,
            color: '#000'
          }}
          disabled={loading || page === 0}
          onClick={() => {
            setPage(page - 1);
            setFetchAction('prev');
          }}
        >
          <IconArrowLeft />
        </IconButton>

        <IconButton 
          style={{
            height: 30,
            width: 30,
            color: '#000'
          }}
          disabled={loading}
          onClick={() => {
            setPage(page + 1);
            setFetchAction('next');
          }}
        >
          <IconArrowRight />
        </IconButton>
      </div>
    </div>
  );
};

export default PaginationController;
