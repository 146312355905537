import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  toolbarGrid: {
    marginBottom: theme.spacing(1), 
  },
  count: {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    fontSize: '14px !important',
    lineHeight: 1,
    color: theme.palette.common.white,
    background: '#A3403D',
  },
  filtersContainer: {
    border: '1px solid',
    borderLeft: '0px solid',
    borderColor: '#DDDDDD',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#f7f7f7',
    transform: 'translateY(100%)',
    transition: 'transform .2s',
    marginBottom: theme.spacing(24),
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(0px)',
      maxWidth: 350,
      width: '100%',
      height: 'calc(100vh - 64px)',
      overflowY: 'auto',
    }
  },
  filtersContainerOpen: {
    transform: 'translateY(0px)',
  },
  filtersContainerInner: {
    flex: 1,
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(3.5),
      paddingBottom: theme.spacing(10)
    }
  },
  filtersContainerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  filterButtonBase: {
    fontFamily: theme.gh_vars.circular,
    fontWeight: 600,
    lineHeight: 1.75,
    fontSize: 15,
    margin: 0,
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 3),
    }
  },
  filtersResults: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 350
    }
  },
  chevronIcon: {
    transition: 'transform .2s',
  },
  chevronIconFlipped: {
    transform: 'rotateZ(180deg)'
  },
  subfilterButtonBase: {
    margin: 0,
    justifyContent: 'flex-start',
    width: '100%',
    padding: theme.spacing(.5, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(.5, 3),
    }
  },
  filterText: {
    fontFamily: theme.gh_vars.circular,
    fontWeight: 600,
    lineHeight: 1.75,
    fontSize: 15,
    margin: 0
  },
  filterSwitch: {
    position: 'relative',
    left: theme.spacing(1),
  },
  subFilterText: {
    fontFamily: theme.gh_vars.circular,
    fontSize: 14,
    lineHeight: 1.25,
    fontWeight: 400,
    width: '100%',
    textAlign: 'left',
  },
  formControlRoot: {
    width: '100%',
    maxHeight: theme.spacing(4),
  },
  formControlLabel: {
    width: '100%',
  }
}));
