import React from 'react';

import useStyles from './SignupIntroText.style';

const SignupIntroText: React.FC = () => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.callout}>
        <h3 className={classes.h3}>
          Manage your stagings in one place.
        </h3>

        <p>
          View mood boards, project timelines, outstanding tasks and more.
        </p>
      </div>

      <div className={classes.callout}>
        <h3 className={classes.h3}>
          Get beautiful photography.
        </h3>

        <p>
          Download imagery of your homes or products instantly.
        </p>
      </div>

      <div className={classes.callout}>
        <h3 className={classes.h3}>
          Chat directly with the Guest House team.
        </h3>

        <p>
          Get in touch with your designer or our customer service team.
        </p>
      </div>
    </>
  );
};

export default SignupIntroText;
