import { database } from '@app/firebase';
import Sentry from '@integrations/Sentry';
import IconMoreHoriz from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { batchDeleteRequests } from '@utils/batchUpdateProductRequests';
import { collection, deleteDoc, doc, getDocs, query, QueryDocumentSnapshot, updateDoc, where } from 'firebase/firestore';
import React, { useState } from 'react';

type RequestedRoomSetActionsProps = {
  roomSet: guesthouse.RoomSet;
  room: guesthouse.Room;
  requests: QueryDocumentSnapshot<guesthouse.ProductRequest>[];
}

const RequestedRoomSetActions: React.FC<RequestedRoomSetActionsProps> = ({ roomSet, room, requests }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeRoomFromRoomSetStagedRooms = async () => {
    const stagedRooms = roomSet.stagedRooms;
    const index = stagedRooms.indexOf(room.docID);

    stagedRooms.splice(index, 1);
    await updateDoc(doc(collection(database, 'roomsets'), roomSet.docID), { stagedRooms });
  };

  const deleteMoodboardsFromHome = async (home: guesthouse.Home | guesthouse.HomeLite, roomSet: guesthouse.RoomSet) => {
    const moodboardDocs = await getDocs(query(collection(database, `homes/${home.docID}/moodboards`), where('roomSet.docID', '==', roomSet.docID)));

    for (const doc of moodboardDocs.docs) {
      await deleteDoc(doc.ref);
    }
  };

  return (
    <div>
      <IconButton
        edge="end"
        aria-label="actions"
        aria-haspopup="true"
        size="large"
        onClick={handleClick}
      >
        <IconMoreHoriz />
      </IconButton>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <MenuItem
          onClick={async () => {
            try {
              for (const request of requests) {
                await batchDeleteRequests(request);
              }
              await removeRoomFromRoomSetStagedRooms();
              await deleteMoodboardsFromHome(room.home, roomSet);
              handleClose();
            } catch (e) {
              Sentry.captureException(e);
            }
          }}
        >
          Remove
        </MenuItem>
      </Menu>
    </div>
  );
};

export default RequestedRoomSetActions;
