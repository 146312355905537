import SupplierAgreementDoc from '@legal/SupplierAgreement.md';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useStyles } from '@ui/utils/makeStyles';
import React, { useState } from 'react';

type SupplierAgreementDialogArgs = {
  resolve: () => void;
}

const SupplierAgreementDialog: React.FC<SupplierAgreementDialogArgs> = ({ resolve }) => {
  const [open, setOpen] = useState(true);
  const { theme } = useStyles();

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="lg"
    >

      <DialogContent>
        <SupplierAgreementDoc />
      </DialogContent>

      <DialogActions style={{ paddingTop: theme.spacing(2) }}>
        <Button
          fullWidth
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => {
            resolve();
            setOpen(false);
          }}
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SupplierAgreementDialog;
