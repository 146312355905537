import { database, functions } from '@app/firebase';
import PaginationController from '@components/pagination-controller/PaginationController';
import NotificationContext from '@context/NotificationContext';
import usePaginatedFirebaseQuery from '@hooks/usePaginatedFirebaseQuery';
import Sentry from '@integrations/Sentry';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@ui/utils/makeStyles';
import toJsDate from '@ui/utils/toJsDate';
import getTimeZone from '@utils/getTimeZone';
import properCase from '@utils/properCase';
import { formatToTimeZone } from 'date-fns-timezone';
import { collection as firestoreCollection, query, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import React, { useContext, useMemo, useState } from 'react';

import EmailTemplates from '../../../functions/src/enum/EmailTemplates';

const useStyles = makeStyles()({
  emailDialogPaper: {
    width: '100%',
  }
});

type PreviewEmailRequest = { emailID: string };
type PreviewEmailResponse = { html: string };

const previewEmail = httpsCallable<PreviewEmailRequest, PreviewEmailResponse>(functions, 'http-previewEmail');

interface EmailHistoryProps {
  userID: string;
  className?: string;
  style?: React.CSSProperties;
}

const EmailHistory: React.FC<EmailHistoryProps> = (props: EmailHistoryProps) => {
  const { userID, className, style } = props;
  const [emailData, setEmailData] = useState<guesthouse.EmailSend | null>();
  const { classes, theme } = useStyles();
  const [emailHTML, setEmailHTML] = useState<string>();
  const [emailHTMLLoading, setEmailHTMLLoading] = useState<boolean>();
  const [emailDataDialogOpen, setEmailDataDialogOpen] = useState(false);
  const notificationContext = useContext<NotificationContext>(NotificationContext);

  const emailQuery = useMemo(() => query(firestoreCollection(database, 'email_sends'), where('userID', '==', userID)), [userID]);

  const { 
    collection, 
    loading,     
    page,
    setPage,
    setFetchAction 
  } = usePaginatedFirebaseQuery(
    emailQuery,
    10
  );

  if (collection?.docs?.length) {
    return (
      <>
        <div
          className={className}
          style={style}
        >
          <PaginationController 
            page={page}
            setPage={setPage}
            setFetchAction={setFetchAction}
            loading={loading}
          />
          
          <TableContainer>
            <Table
              aria-label="User Email History"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    Date
                  </TableCell>
                  <TableCell>
                    Template
                  </TableCell>
                  <TableCell>
                    Email Address
                  </TableCell>
                  <TableCell>
                    ID
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collection.docs
                  .filter(doc => doc.data()?.template_id)
                  .map((doc) => {
                    const emailSend = doc.data() as guesthouse.EmailSend;

                    let templateName = '';

                    try {
                      // @ts-ignore
                      templateName = Object.keys(EmailTemplates)[Object.values(EmailTemplates).indexOf(emailSend.template_id)];
                    } catch (e) {
                      Sentry.captureException(e);
                    }

                    return (
                      <TableRow key={doc.id}>
                        <TableCell>
                          {formatToTimeZone(toJsDate(emailSend.created), 'MM/DD/YYYY h:mm A (z)', { timeZone: getTimeZone(), })}
                        </TableCell>
                        <TableCell>
                          {properCase(templateName, '_')}
                        </TableCell>
                        <TableCell>
                          {emailSend.to}
                        </TableCell>
                        <TableCell>
                          {emailSend.template_id}
                        </TableCell>

                        <TableCell align="right">
                          <Button
                            variant="text"
                            size="small"
                            color="secondary"
                            onClick={() => {
                              setEmailHTMLLoading(true);
                              setEmailDataDialogOpen(true);
                              setEmailHTML('');
                              setEmailData(null);

                              previewEmail({ emailID: doc.id })
                                .then(({ data }) => {
                                  const { html } = data;

                                  setEmailHTMLLoading(false);
                                  setEmailDataDialogOpen(true);
                                  setEmailHTML(html);
                                  setEmailData(emailSend);
                                })
                                .catch((e) => {
                                  notificationContext.setContext({ message: e.message, severity: 'error', open: true });

                                  setEmailHTMLLoading(false);
                                  setEmailDataDialogOpen(false);
                                  setEmailHTML('');
                                  setEmailData(null);
                                });

                              setEmailDataDialogOpen(true);
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Dialog
          aria-labelledby="email-data-dialog-title"
          open={emailDataDialogOpen}
          maxWidth="md"
          classes={{
            paper: classes.emailDialogPaper,
          }}
          onClose={() => setEmailDataDialogOpen(false)}
        >

          <DialogTitle
            id="email-data-dialog-title"
            style={{ paddingBottom: theme.spacing(0) }}
          >
            Email Preview
          </DialogTitle>

          <DialogContent style={{ paddingBottom: 0 }}>
            {emailData?.subject && (
              <Typography style={{ marginBottom: theme.spacing(1) }}>
                <strong>
                  Subject:
                </strong>
                {' '}
                {emailData?.subject}
              </Typography>
            )}

            <div style={{ margin: theme.spacing(0, -3, 0) }}>
              <Divider style={{ marginTop: theme.spacing(2) }} />
              {
                emailHTMLLoading ?
                  (
                    <Box
                      style={{ padding: theme.spacing(4) }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: emailHTML
                      }}
                    />
                  )
              }
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return (
    <div
      className={className}
      style={style}
    >
      <Typography>
        No email history available.
      </Typography>
    </div>
  );
};


export default EmailHistory;
