import { useEffect, useRef } from 'react';

type UseDebounceEffectOptionsMode = 'leading' | 'trailing';

function useDebounceEffect(callback, delay, deps = [], mode : UseDebounceEffectOptionsMode = 'trailing') {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (mode === 'leading' && firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  },
  [delay, ...deps],
  );
}

export default useDebounceEffect;
