export default function chunkArray<T>(arr: T[], chunkSize = 500): T[][] {
  const arrayLength = arr.length;
  const ret = [];
  
  for (let index = 0; index < arrayLength; index += chunkSize) {
    const chunk = arr.slice(index, index + chunkSize);
  
    ret.push(chunk);
  }
  
  return ret;
}
