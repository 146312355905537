import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  noteWrapper: {
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    }
  },
  bubble: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: 0,
    position: 'relative',
  },

  bubbleActions: {
    position: 'absolute',
    padding: theme.spacing(1),
    bottom: 0,
    right: 0,
  }
}));
