import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import { InventoryDialog } from '@components/dialogs/InventoryDialog';
import { InventoryDialogMaker } from '@components/dialogs/InventoryDialogMaker';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import Sentry from '@integrations/Sentry';
import IconMoreHoriz from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Protected from '@ui/components/protected/Protected';
import { collection, deleteDoc,getDocs, query,QueryDocumentSnapshot, updateDoc, where,writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';

type InventoryActionsProps = {
  product: QueryDocumentSnapshot<guesthouse.Product>
  inventory: QueryDocumentSnapshot<guesthouse.ProductInventory>
}

const InventoryActions: React.FC<InventoryActionsProps> = (props) => {
  const { inventory, product } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const userContext = useContext<UserContext>(UserContext);
  const inventoryData = inventory.data();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        edge="end"
        aria-label="actions"
        aria-haspopup="true"
        size="large"
        onClick={handleClick}
      >
        <IconMoreHoriz />
      </IconButton>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
          <MenuItem
            onClick={() => {
              const Dialog = userContext?.roles?.maker ? InventoryDialogMaker : InventoryDialog;

              asyncRender(Dialog, {
                userContext,
                initialValues: inventoryData,
                product: product,
                title: 'Edit Inventory',
                showQuantity: false,
              })
                // @ts-ignore https://github.com/firebase/firebase-js-sdk/issues/5853
                .then(values => updateDoc(inventory.ref, values))
                .then(() => notificationContext.setContext({ open: true, message: 'Inventory updated' }))
                .catch((e) => {
                  if (e) {
                    Sentry.captureException(e);
                    notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                  }
                });

              handleClose();
            }}
          >
            Edit
          </MenuItem>
        </Protected>

        {
          inventoryData.status === 'IN_TRANSIT' && inventoryData.offer?.classification !== 'dropship' && (userContext?.roles?.maker ? !inventoryData.warehouse?.guesthouseManaged : true) && (
            <MenuItem
              onClick={() => {
                const updatedInventory: Partial<guesthouse.ProductInventory> = {
                  status: 'IN_STOCK'
                };

                // @ts-ignore https://github.com/firebase/firebase-js-sdk/issues/5853
                updateDoc(inventory.ref, updatedInventory);
                handleClose();
              }}
            >
              Mark Delivered
            </MenuItem>
          )
        }

        {
          inventoryData.status !== 'IN_TRANSIT' && inventoryData.offer?.classification !== 'dropship' && (userContext?.roles?.maker ? !inventoryData.warehouse?.guesthouseManaged : true) && (
            <MenuItem
              onClick={() => {
                const updatedInventory: Partial<guesthouse.ProductInventory> = {
                  status: 'IN_TRANSIT'
                };

                // @ts-ignore https://github.com/firebase/firebase-js-sdk/issues/5853
                updateDoc(inventory.ref, updatedInventory);
                handleClose();
              }}
            >
              Mark In Transit
            </MenuItem>
          )
        }

        {
          inventoryData.status !== 'SOLD' && inventoryData.offer?.classification !== 'dropship' && (userContext?.roles?.maker ? !inventoryData.warehouse?.guesthouseManaged : true) && (
            <MenuItem
              onClick={() => {
                const updatedInventory: Partial<guesthouse.ProductInventory> = {
                  status: 'SOLD'
                };

                // @ts-ignore https://github.com/firebase/firebase-js-sdk/issues/5853
                updateDoc(inventory.ref, updatedInventory);
                handleClose();
              }}
            >
              Mark Sold
            </MenuItem>
          )
        }

        {
          inventoryData.status !== 'IN_STOCK' && inventoryData.offer?.classification !== 'dropship' && (userContext?.roles?.maker ? !inventoryData.warehouse?.guesthouseManaged : true) && (
            <MenuItem
              onClick={() => {
                const updatedInventory: Partial<guesthouse.ProductInventory> = {
                  status: 'IN_STOCK'
                };

                // @ts-ignore https://github.com/firebase/firebase-js-sdk/issues/5853
                updateDoc(inventory.ref, updatedInventory);
                handleClose();
              }}
            >
              Mark In Stock
            </MenuItem>
          )
        }

        {
          inventoryData.status !== 'UNAVAILABLE' && inventoryData.offer?.classification !== 'dropship' && (userContext?.roles?.maker ? !inventoryData.warehouse?.guesthouseManaged : true) && (
            <MenuItem
              onClick={() => {
                const updatedInventory: Partial<guesthouse.ProductInventory> = {
                  status: 'UNAVAILABLE'
                };

                // @ts-ignore https://github.com/firebase/firebase-js-sdk/issues/5853
                updateDoc(inventory.ref, updatedInventory);
                handleClose();
              }}
            >
              Mark Unavailable
            </MenuItem>
          )
        }

        {
          inventoryData.warehouse?.guesthouseManaged && (
            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              <MenuItem
                onClick={() => {
                  const productData = product.data();
                  const owner = productData.owner ? productData.owner : userContext.data;

                  const updatedInventory: Partial<guesthouse.ProductInventory> = {
                    warehouse: {
                      address: owner.location.address,
                      place_id: owner.location.place_id,
                      vicinity: owner.location.vicinity,
                      _geoloc: owner.location._geoloc,
                      geohash: owner.location.geohash,
                      guesthouseManaged: false,
                    }
                  };

                  // @ts-ignore https://github.com/firebase/firebase-js-sdk/issues/5853
                  updateDoc(inventory.ref, updatedInventory);

                  getDocs(query(collection(inventory.ref, 'requests')
                    ,where('status', '==', 'NEEDS_PICKUP')))
                    .then(collection => {
                      const batch = writeBatch(database);
                      const updatedStatus: Partial<guesthouse.ProductRequest> = { status: 'RETURNED_TO_MAKER' };

                      if (collection?.docs?.length) {
                        collection.docs.forEach(doc => {
                          batch.update(doc.ref, updatedStatus);
                        });
                      }
                      return batch;
                    })
                    .then(batch => batch.commit());

                  handleClose();
                }}
              >
                Return to Maker
              </MenuItem>
            </Protected>
          )
        }

        {(userContext?.roles?.maker ? !inventoryData.warehouse?.guesthouseManaged : true) && (
          <MenuItem
            onClick={() => {
              asyncRender(ConfirmDialog, {
                title: 'Are you sure you want to delete this inventory?',
                confirmText: 'Yes, Delete',
              })
                .then(() => deleteDoc(inventory.ref))
                .then(() => notificationContext.setContext({ open: true, message: 'Inventory deleted' }))
                .catch((e) => {
                  if (e) {
                    Sentry.captureException(e);
                    notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                  }
                });

              handleClose();
            }}
          >
            Delete
          </MenuItem>)
        }
      </Menu>
    </div>
  );
};

export default InventoryActions;
