import userFullName from '../userFullName';

export default function makerMetaData(maker: guesthouse.User): guesthouse.MetaData {
  const metaData: guesthouse.MetaData = {
    h1: maker?.metadata?.h1 || userFullName(maker, true).toLowerCase().startsWith('shop') ? userFullName(maker, true) : `Shop ${userFullName(maker, true)}`,
    pageTitle: maker?.metadata?.pageTitle || `Shop ${userFullName(maker, true)} | Home Staging You Can Shop | Guest House`,
    metaTitle: maker?.metadata?.pageTitle || `Shop ${userFullName(maker, true)} | Home Staging You Can Shop | Guest House`,
    metaDescription: maker?.metadata?.metaDescription || `Shop furnishings by ${userFullName(maker, true)} as displayed in our beautifully staged homes. `,
    image: maker?.coverURL || maker?.photoURL || 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Fguesthouse-social-default.jpg?alt=media',
  };

  return metaData;
}
