import Typography from '@mui/material/Typography';
import React from 'react';

import { useStyles } from '../../utils/makeStyles';

const Address: React.FC<{ address: guesthouse.Address }> = ({ address }) => {
  const { theme } = useStyles();

  return (
    <>
      <Typography style={{ fontSize: 12, color: theme.palette.common.black }}>
        {address.firstname}
        {' '}
        {address.lastname}
      </Typography>
  
      <Typography
        component="address"
        style={{ fontSize: 12, fontStyle: 'normal' }}
      >
        {address.address_1}
        {' '}
        {address.address_2}
        <br />
  
        {address.city}
        ,
        {' '}
        {address.state}
        {' '}
        {address.postal_code}
      </Typography>
    </>
  );
};
  
export default Address;
