import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import theme from '@ui/theme';
import { motion } from 'framer-motion';
import React from 'react';

const CalendarLoading = () => {

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Typography
          variant="h2"
          component="h1"
          style={{ marginBottom: theme.spacing(4.5) }}
        >
          Fetching Calendar...
        </Typography>

        <motion.div
          style={{ fontSize: 60, scale: 1 }}

          animate={{
            scale: [1, 2, 2, 1, 1],
            rotate: [0, 0, 270, 270, 0],
            borderRadius: ['20%', '20%', '50%', '50%', '20%'],
          }}
          
          transition={{ ease: 'easeOut', duration: 2, repeat: Infinity }}
        >
          🗓
        </motion.div>
      </Box>
    </div>
  );
};

export default CalendarLoading;
