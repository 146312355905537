import { auth } from '@app/firebase';
import { RouterLink } from '@components/link-behavior/LinkBehavior';
import PasswordForm from '@forms/Password';
import useRouter from '@hooks/useRouter';
import GuestHouseLogoFull from '@images/guesthouse-logo-full';
import GuestHouseLogoIcon from '@images/guesthouse-logo-icon';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import routes from '@routes';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import { browserLocalPersistence, confirmPasswordReset, setPersistence, signInWithEmailAndPassword, verifyPasswordResetCode } from 'firebase/auth';
import React from 'react';

import useStyles from './NewPassword.style';

const NewPassword = () => {
  const { classes } = useStyles();
  const { md } = useBreakpoints();
  const router = useRouter();

  return (
    <div className={classes.wrapper}>
      <Grid
        container
        spacing={1}
        className={classes.top}
      >
        <Grid
          item
          xs={12}
          md={4}
        >
          {
            md
              ? <GuestHouseLogoFull className={classes.logo} />
              : <GuestHouseLogoIcon className={classes.logo} />
          }
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          style={{ textAlign: 'right' }}
        >
          {md && (
            <Link
              to={routes.register.path}
              className={classes.register}
              component={RouterLink}
            >
              <h3 className={classes.h3}>
                Sign Up
              </h3>
            </Link>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={12}
          lg={6}
        >
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          className={classes.gridForm}
        >
          <Paper className={classes.paper}>
            <Typography
              variant="h3Alt"
              component="h1"
              className={classes.formTitle}
            >
              New Password
            </Typography>

            <PasswordForm
              onSubmit={async (password) => {
                const { emailAction } = router.location.state;

                const email = await verifyPasswordResetCode(auth, emailAction.oobCode);

                await confirmPasswordReset(auth, emailAction.oobCode, password);
                await setPersistence(auth, browserLocalPersistence);
                await signInWithEmailAndPassword(auth, email, password);

                if (router.location.state?.next) {
                  router.push(router.location.state.next);
                } else {
                  router.push(routes.feed.path);
                }
              }}
            />

            {!md && (
              <div style={{ paddingTop: 20, textAlign: 'center' }}>
                <Link
                  to={routes.register.path}
                  className={classes.register}
                  component={RouterLink}
                >
                  <h3 className={classes.h3}>
                    Sign Up
                  </h3>
                </Link>
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(NewPassword);
