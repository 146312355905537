import { RouterLink } from '@components/link-behavior/LinkBehavior';
import CONFIG from '@config';
import Sentry from '@integrations/Sentry';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import routes from '@routes';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import InternalErrorIcon from '@ui/icons/error/internal-error.svg';
import PageNotFoundIcon from '@ui/icons/error/page-not-found.svg';
import { useStyles } from '@ui/utils/makeStyles';
import React from 'react';
import { generatePath } from 'react-router';

type ErrorProps = {
  title?: string;
  message?: string;
  error?: Error;
  statusCode?: string;
}

const Error: React.FC<ErrorProps> = (props: ErrorProps) => {
  const { theme } = useStyles();
  const { error, statusCode } = props;
  const { sm } = useBreakpoints();


  let message = 'Looks like you’ve hit a snag. We’re working on fixing this as soon as possible.';
  let title = 'Internal error';

  if (error) {
    Sentry.captureException(error);
  }

  const icons = {
    404: {
      IconComponent: PageNotFoundIcon,
      style: {
        width: 154,
        height: 120,
        marginRight: 10,
      }
    },
    500: {
      IconComponent: InternalErrorIcon,
      style: {
        width: 110,
        height: 96,
        marginRight: 28,
      }
    }
  };

  let { IconComponent, style } = icons[500];

  if (statusCode === '404') {
    message = 'Whoops! We’ve searched everywhere and can’t seem to find the page you’re looking for.';
    title = 'Page not found';
    IconComponent = icons[404].IconComponent;
    style = icons[404].style;
  }

  if (props.title) {
    title = props.title;
  }

  if (props.message) {
    message = props.message;
  }

  return (
    <div style={{ margin: theme.spacing(10, 0) }}>
      <div style={{ textAlign: 'center' }}>
        <div style={{ marginBottom: theme.spacing(3) }}>
          <IconComponent style={style} />
        </div>

        <Typography
          variant="h3Alt"
          component="h1"
          style={{ marginBottom: theme.spacing(2), fontSize: 36 }}
        >
          {title}
        </Typography>

        <Typography style={{ width: sm ? 450 : '100%', margin: 'auto', lineHeight: 1.75 }}>
          {message}
        </Typography>

        {statusCode === '404' ?
          <Button
            variant="contained"
            color="secondary"
            component={RouterLink}
            to={generatePath(routes.feed.path)}
            style={{ marginTop: theme.spacing(6), width: 140 }}
          >
            Back to home
          </Button>
          :
          <Button
            variant="contained"
            color="secondary"
            href={'mailto:support@guesthouseshop.com'}
            style={{ marginTop: theme.spacing(6), width: 160 }}
          >
            Contact support
          </Button>
        }
      </div>

      {error && CONFIG.debug.stacktrace && (
        <div style={{ padding: theme.spacing(2), overflow: 'auto' }}>
          <pre style={{ background: '#f7f7f7', padding: theme.spacing(2), borderRadius: theme.shape.borderRadius, overflow: 'auto' }}>
            {error.stack}
          </pre>
        </div>
      )}
    </div>
  );
};

export default Error;
