import CONFIG from '@config';
import querystring from 'query-string';

const staticMap = (center, options = {}) => {
  const defaultOptions = {
    center,
    size: '640x640',
    zoom: 15,
    key: CONFIG.firebase.store.apiKey,
    maptype: 'roadmap',
    markers: `size:tiny|color:red|${center}`,
    scale: 4,
    style: [
      'feature:road.highway|element:labels|visibility:off',
      'feature:poi|element:labels|visibility:off',
      'feature:administrative|element:labels|visibility:off',
    ]
  };
  const query = querystring.stringify({
    ...defaultOptions,
    ...options,
  });
  const url = `https://maps.googleapis.com/maps/api/staticmap?${query}`;

  return url;
};

export default staticMap;
