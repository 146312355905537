import { auth } from '@app/firebase';
import UserContext from '@context/UserContext';
import Sentry from '@integrations/Sentry';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LoginSchema, { initialValues } from '@schema/LoginSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import IconEyeClosed from '@ui/icons/imaterial/base/Eye Crossed.svg';
import IconEyeOpen from '@ui/icons/imaterial/base/Eye Open.svg';
import getUserData from '@utils/getUserData';
import { browserLocalPersistence, setPersistence, signInWithEmailAndPassword, UserCredential } from 'firebase/auth';
import { updateDoc } from 'firebase/firestore';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useContext, useState } from 'react';

const LoginForm = ({ onLoginSuccess = () => null }) => {
  const userContext = useContext<UserContext>(UserContext);
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Formik
      validationSchema={LoginSchema}
      initialValues={initialValues}
      onSubmit={(values: LoginForm) => {
        setError(false);
        const { email, password } = values;


        return setPersistence(auth, browserLocalPersistence)
          .then(() => signInWithEmailAndPassword(auth, email, password))
          .then(async (result: UserCredential) => {
            const { user } = result;
            const { data, flags, regions, roles, ref } = await getUserData(user);

            userContext.setContext({ ...userContext, user, data, flags, regions, roles });

            return ref;
          })
          .then(async (ref) => {
            if (typeof onLoginSuccess === 'function') {
              try {
                onLoginSuccess();
              } catch (e) {
                Sentry.captureException(e);
              }
            }

            try {
              await updateDoc(ref, { last_login: new Date() });
            } catch (e) {
              Sentry.captureException(e);
            }
          })
          .catch((e) => {
            Sentry.captureException(e);
            setError(e.message);
          });
      }}
    >
      {({
        errors,
        touched,
        handleSubmit,
        isSubmitting
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field
              fullWidth
              name="email"
              label="Email"
              type="email"
              component={TextField}
              margin="dense"
              variant="outlined"
            />

            <Field
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              component={TextField}
              margin="dense"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <IconEyeClosed /> : <IconEyeOpen />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            {error && (
              <FormHelperText error>
                {error}
              </FormHelperText>
            )}

            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length)}
                aria-label="Continue"
                tooltip={makeErrors(errors, touched)?.length && (
                  <TooltipErrors
                    errors={errors}
                    touched={touched}
                  />
                )}
              >
                Continue
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
