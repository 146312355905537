import { database } from '@app/firebase';
import HomeRowCard from '@components/cards/HomeRowCard';
import HomeRowCardHeaders from '@components/cards/HomeRowCardHeaders';
import ErrorComponent from '@components/error/Error';
import HomeGrid from '@components/home-grid/HomeGrid';
import { LinkBehavior } from '@components/link-behavior/LinkBehavior';
import AppContext from '@context/AppContext';
import UserContext from '@context/UserContext';
import useAlgoliaSearch from '@hooks/useAlgoliaSearch';
import useCenterPoint from '@hooks/useCenterPoint';
import { TimelineData } from '@hooks/useTimeline';
import algolia from '@integrations/Algolia';
import Sentry from '@integrations/Sentry';
import IconChevronLeft from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import useCommonStyles from '@styles/common.style';
import Protected from '@ui/components/protected/Protected';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import IconGrid from '@ui/icons/imaterial/base/Grid.svg';
import IconListRound from '@ui/icons/imaterial/base/List Round.svg';
import checkRoles from '@ui/utils/checkRoles';
import makeIndexName from '@utils/makeIndexName';
import { homePath } from '@utils/paths';
import { collection, doc, updateDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';

import useStyles from './Homes.style';
import HomesSkeleton from './HomesAll.skeleton';
import OrdersQuickView from './OrdersQuickView';
import TimelineQuickView from './TimelineQuickView';


const homesInstallDateDescIndex = algolia.initIndex(makeIndexName('homes_install_date_desc'));


const renderActions = (home: guesthouse.Home, timelineData: TimelineData) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { md, lg } = useBreakpoints();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { theme } = useStyles();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userContext = useContext<UserContext>(UserContext);
  const internalUser = checkRoles(['admin', 'design_manager', 'designer'], userContext.roles);

  const todo = internalUser ? timelineData.internalReview : timelineData.clientReview;

  if (todo.length > 0) {
    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {md && (
          <Typography
            component="div"
            style={{
              fontFamily: 'MabryMono, monospace',
              fontSize: 11,
              marginRight: 4
            }}
          >
            {`${todo.length} new ${todo.length > 1 ? 'tasks' : 'task'}`}
          </Typography>
        )}
        <div
          style={{
            width: 20,
            minWidth: 20,
            height: 20,
            minHeight: 20,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(.5),
            borderRadius: '50%',
            backgroundColor: '#AD6556',
            textAlign: 'center',
            color: '#fff',
            lineHeight: '20px',
          }}
        >
          {todo.length}
        </div>
        {
          lg && (
            <Protected allowedRoles={['admin', 'design_manager', 'designer', 'website_manager', 'customer_support']}>
              <TimelineQuickView home={home} />
              <OrdersQuickView home={home} />
            </Protected>
          )
        }
      </Box>
    );
  } else if (timelineData.upcoming.length > 0) {
    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {
          md && (
            <Typography
              component="div"
              style={{
                fontFamily: 'MabryMono, monospace',
                fontSize: 11,
                marginRight: 4
              }}
            >
              {timelineData.upcoming.length}
              {' '}
              upcoming
            </Typography>
          )
        }
        {
          lg && (
            <Protected allowedRoles={['admin', 'design_manager', 'designer', 'website_manager', 'customer_support']}>
              <TimelineQuickView home={home} />
              <OrdersQuickView home={home} />
            </Protected>
          )
        }
      </Box>
    );
  } else {
    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {
          md
            ? (
              <Typography
                component="div"
                style={{
                  fontFamily: 'MabryMono, monospace',
                  fontSize: 11,
                  marginRight: 4
                }}
              >
                You&apos;re all caught up &#128077;
              </Typography>
            ) : (
              <div
                style={{
                  position: 'relative',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                &#128077;
              </div>
            )
        }
        {
          lg && (
            <Protected allowedRoles={['admin', 'design_manager', 'designer', 'website_manager', 'customer_support']}>
              <TimelineQuickView home={home} />
              <OrdersQuickView home={home} />
            </Protected>
          )
        }
      </Box>
    );
  }
};

const HomesAll = () => {
  const { classes: common } = useCommonStyles();
  const { classes, theme } = useStyles();
  const userContext = useContext<UserContext>(UserContext);
  const appContext = useContext<AppContext>(AppContext);
  const { md } = useBreakpoints();
  const [coords, radius] = useCenterPoint();

  const [myHomes, setMyHomes] = useState(Boolean(userContext?.data?.app_preferences?.views?.my_homes_only));

  useEffect(() => {
    updateDoc(doc(collection(database, 'users'), userContext.user.uid),
      { 'app_preferences.views.my_homes_only': myHomes })
      .catch(Sentry.captureException);
  }, [myHomes]);

  const externalUser = checkRoles(['realtor', 'maker'], userContext.roles);

  const { results: allHomes, loading: l1, error: e1 } = useAlgoliaSearch<guesthouse.Home>({
    index: homesInstallDateDescIndex,
    filters: [
      (myHomes || externalUser) && `members:${userContext.user.uid}`,
    ].filter(Boolean).join(' AND '),
    numericFilters: '',
    facetFilters: '',
    query: '',
    aroundLatLng: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? coords?.join(',') : undefined,
    aroundRadius: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? radius : undefined,
    perPage: 500,
    debounce: 0,
  });

  const loading = l1;
  const error = e1;

  const homes = allHomes?.hits || [];

  if (error) {
    return (
      <div className={common.contentSpacing}>
        <ErrorComponent />
      </div>
    );
  }

  if (loading || appContext.skeleton) {
    return (
      <div className={common.contentSpacing}>
        <HomesSkeleton />
      </div>
    );
  }

  return (
    <div className={common.contentSpacing}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Box
            display="flex"
            alignItems="flex-end"
          >
            <IconButton
              aria-label="Go back"
              component={LinkBehavior}
              href="/homes"
              style={{
                position: 'relative',
                top: 5,
                marginRight: theme.spacing(1),
              }}
            >
              <IconChevronLeft />
            </IconButton>

            <Typography
              component="h1"
              variant="h3Alt"
              style={{ marginRight: theme.spacing(2) }}
            >
              All Homes
              {' '}
              (
              {homes.length}
              )
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              className={classes.justMyHomes}
            >
              <Box
                display="flex"
                alignItems="center"
              >
                <Typography
                  component="div"
                  fontFamily={theme.gh_vars.circular}
                  style={{
                    marginRight: theme.spacing(1.25),
                    paddingBottom: 2,
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                >
                  Just my homes
                </Typography>
                <Switch
                  checked={myHomes}
                  onChange={() => {
                    setMyHomes(!myHomes);
                  }}
                />
              </Box>

              <Box
                display="flex"
                alignItems="center"
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    updateDoc(doc(collection(database, 'users'), userContext.user.uid),
                      { 'app_preferences.views.home_card': 'list' })
                      .catch(Sentry.captureException);
                  }}
                >
                  <IconListRound />
                </IconButton>

                <IconButton
                  size="small"
                  onClick={() => {
                    updateDoc(doc(collection(database, 'users'), userContext.user.uid),
                      { 'app_preferences.views.home_card': 'grid' })
                      .catch(Sentry.captureException);
                  }}
                >
                  <IconGrid />
                </IconButton>
              </Box>
            </Box>
          </Protected>
        </Grid>
      </Grid>

      {
        (userContext?.data?.app_preferences?.views?.home_card || 'grid') === 'grid'
          ? (
            <>
              {homes.length
                ? (
                  <HomeGrid
                    homes={homes}
                    href={homePath}
                    showSubscription={checkRoles(['admin', 'design_manager', 'designer', 'website_manager', 'customer_support'], userContext.roles)}
                  />
                ) : (
                  <Typography>
                    There&apos;s nothing here yet.
                  </Typography>
                )}
            </>
          ) : (
            <>
              {md && (
                <HomeRowCardHeaders
                  renderActionsHeader={typeof renderActions === 'function'}
                />
              )}

              {homes.map(home => {
                return (
                  <HomeRowCard
                    key={home.docID}
                    home={home}
                    href={(home) => homePath(home)}
                    renderActions={renderActions}
                    style={{
                      marginBottom: theme.spacing(1)
                    }}
                  />
                );
              })}
            </>
          )
      }
    </div>
  );
};

export default React.memo(HomesAll);
