import useStyles from '@components/social-previews/FacebookPreview.style';
import { compact } from 'lodash';
import React from 'react';

import { firstValid, hardTruncation, shortEnough, stripHtmlTags } from './helpers';

const TITLE_LENGTH = 80;
const DESCRIPTION_LENGTH = 200;

const baseDomain = (url) =>
  url &&
  url
    .replace(/^[^/]+[/]*/, '') // strip leading protocol
    .replace(/\/.*$/, ''); // strip everything after the domain

const facebookTitle = firstValid(shortEnough(TITLE_LENGTH), hardTruncation(TITLE_LENGTH));

const facebookDescription = firstValid(
  shortEnough(DESCRIPTION_LENGTH),
  hardTruncation(DESCRIPTION_LENGTH)
);

interface FacebookPreviewProps {
  style?: React.CSSProperties;
  className?: string;
  url?: string;
  type?: string;
  title?: string;
  description?: string;
  image?: string;
  author?: string;
}

const FacebookPreview : React.FC<FacebookPreviewProps> = (props) => {
  const { url, title, image, author, description, style, className } = props;
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(classes.facebookPreview, className )}
      style={style}
    >
      <div className={classes.facebookPreviewContent}>
        <div className={classes.facebookPreviewImage}>
          {image && <img
            alt="Facebook Preview Thumbnail"
            src={image}
          />}
        </div>
        <div className={classes.facebookPreviewBody}>
          <div className={classes.facebookPreviewUrl}>
            {compact([baseDomain(url), author]).join(' | ')}
          </div>
          <div className={classes.facebookPreviewTitle}>
            {facebookTitle(title || '')}
          </div>
          <div className={classes.facebookPreviewDescription}>
            {facebookDescription(stripHtmlTags(description))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacebookPreview;
