import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  editButton: {
    marginRight: `${theme.spacing(1)} !important`,
    '&:last-of-type': {
      marginRight: '0 !important',
    }
  },
  mobileHorizontalScrollWrapper: {
    [theme.breakpoints.down(undefined)]: {
      marginLeft: theme.spacing(-4),
      marginRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      width: '100vw',
    },
    marginTop: theme.spacing(2),
    overflow: 'scroll',
    WebkitOverflowScrolling: 'touch'
  },
  mobileHorizontalScrollContainer: {
    display: 'flex',
    marginRight: theme.spacing(4),
    justifyContent: 'flex-start',
    '& > span:last-child': {
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
    },
  }
}));
