import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@ui/theme';
import { createMuiCache } from '@utils/createMuiCache';
import React from 'react';
import { createRoot } from 'react-dom/client';

export interface AsyncComponentProps<T = unknown> {
  resolve: (value?: T) => void;
  reject: (reason?: any) => void;
  [key: string]: any;
}

const asyncRender = <T extends {}>(Component: React.FC<AsyncComponentProps<T>>, props: any = {}) => new Promise<T>((resolve, reject) => {
  const container = document.createElement('div');

  const cleanup = () => {
    setTimeout(() => {
      if (container) {
        try {
          document.body.removeChild(container);
        } catch (e) {
          // do nothing
        }
      }
    }, 3000);
  };

  document.body.appendChild(container);

  const root = createRoot(container);

  root.render(
    <CacheProvider value={createMuiCache()}>
      <ThemeProvider theme={theme}>
        <Component
          resolve={(v) => {
            resolve(v);
            cleanup();
          }}
          reject={(v) => {
            reject(v);
            cleanup();
          }}
          {...props}
        />
      </ThemeProvider>
    </CacheProvider>
  );
});

export default asyncRender;
