import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  container: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: 400,
  },
  list: {
    maxHeight: 300,
    overflow: 'auto',
  },
  photo: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 300, // width of the medium image size
  }
}));
