import { functions } from '@app/firebase';
import Sentry from '@integrations/Sentry';
import { httpsCallable } from 'firebase/functions';
import { Channel } from 'stream-chat';

const createStreamUser = httpsCallable(functions, 'http-createStreamUser');

export default async function addStreamUserToChannel(user: guesthouse.User, channel: Channel, notificationContext: NotificationContext) {
  try {
    return await channel.addMembers([{ user_id: user.docID, channel_role: 'channel_member' }], { text: `${user.firstname + ' ' + user.lastname} has been added to the chat.` });
  } catch (e) {
    if (e.code === 4 && e.status === 400) {
      try {
        await createStreamUser(user);
        return channel.addMembers([{ user_id: user.docID, channel_role: 'channel_member' }], { text: `${user.firstname + ' ' + user.lastname} has been added to the chat.` });
      } catch (e) {
        Sentry.captureException(e);
        notificationContext.setContext({ open: true, message: 'Failed to add user to chat, please contact support@guesthouseshop.com', severity: 'error' });
      }
    } else {
      Sentry.captureException(e);
      notificationContext.setContext({ open: true, message: 'Failed to add user to chat, please contact support@guesthouseshop.com', severity: 'error' });
    }
  }
}
