import { database } from '@app/firebase';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import FormErrors, { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import makeOfferLabel from '@utils/makeOfferLabel';
import { collection } from 'firebase/firestore';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';

import { DefaultFirestoreConverter } from '../../../types/DefaultFirestoreConverter';
import { InventoryDialogProps } from './InventoryDialog.props';


const InventorySchema = Yup.object().shape({
  quantity: Yup.number()
    .min(1, 'Quantity must be greater than 0')
    .max(50, 'Quantity must be less than or equal to 50')
    .typeError('Quantity must be a valid number')
    .positive('Value must be a positive number'),
  offer: Yup.object()
    .required('Offer selection is required'),
  warehouse: Yup.object().nullable(),
});

export const InventoryDialogMaker : React.FC<InventoryDialogProps> = (props) => {
  const {
    resolve,
    reject,
    userContext,
    product,
    title,
    initialValues,
    showQuantity,
  } = props;

  const [open, setOpen] = useState(true);

  const { collection: offersCollection, loading: offersLoading } = useCollection<guesthouse.ProductOffer>(
    collection(database, `products/${product.id}/offers`).withConverter(new DefaultFirestoreConverter<guesthouse.ProductOffer>())
  );

  const offersData = useMemo(() => {
    if (offersCollection?.docs?.length) {
      return offersCollection.docs.map(p => p.data());
    }
    return [];
  }, [offersLoading, offersCollection?.docs?.length]);

  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        onClose={() => {
          setOpen(false);
          reject();
        }}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <Formik
            validationSchema={InventorySchema}
            initialValues={initialValues}
            onSubmit={(values) => {
              resolve(values);
              setOpen(false);
            }}
          >
            {({
              errors,
              touched,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <Form>
                  {showQuantity && (
                  <Field
                    fullWidth
                    id="quantity"
                    name="quantity"
                    label="Quantity"
                    type="number"
                    component={TextField}
                    margin="dense"
                    variant="outlined"
                  />
                  )}
                  <Autocomplete
                    fullWidth
                    id="offer"
                    defaultValue={initialValues?.offer}
                    options={offersData}
                    getOptionLabel={makeOfferLabel}
                    renderInput={(params) => {
                      return (
                        <Field
                          {...params}
                          fullWidth
                          name="offer"
                          label="Offer"
                          type="text"
                          component={TextField}
                          margin="dense"
                          variant="outlined"
                        />
                      );
                    }}
                    onChange={(e, value) => {
                      setFieldValue('offer', value);
                    }}
                  />
                  <FormErrors
                    errors={errors}
                    touched={touched}
                  />

                  <div style={{ paddingTop: 20 }}>
                    <SubmitButton
                      fullWidth
                      data-test="product-add-inventory-submit"
                      isSubmitting={isSubmitting}
                      disabled={Boolean(Object.keys(errors).length)}
                      aria-label="Confirm"
                      tooltip={makeErrors(errors, touched)?.length && (
                      <TooltipErrors
                        errors={errors}
                        touched={touched}
                      />
                      )}
                    >
                      Confirm
                    </SubmitButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            size="small"
            onClick={() => {
              setOpen(false);
              reject(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};

InventoryDialogMaker.defaultProps = {
  title: 'Add Product Inventory',
  showQuantity: true,
  initialValues: { quantity: 1 } as guesthouse.ProductInventory,
};
