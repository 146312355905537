import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  searchWrapper: {
    marginBottom: theme.spacing(3)
  },
  searchField: {
    marginRight: `${theme.spacing(2)} !important`,
    maxWidth: 600,
  },
  searchFieldInput: {
    borderRadius: '50px !important',
    borderColor: '#92a0ab',
  },
  searchFieldIcon: {
    fill: '#92a0ab !important',
  },
  roomControls: {
    margin: theme.spacing(2, 0, 0),
  },
  roomCount: {
    margin: theme.spacing(0, 1),
    fontSize: 36,
    lineHeight: 1,
    position: 'relative',
    bottom: 1,
    width: 30,
    textAlign: 'center',
  },
}));
