import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  searchField: {
    marginBottom: theme.spacing(1.5),
    marginRight: `${theme.spacing(2)} !important`,
    maxWidth: 600,
  },
  searchFieldInput: {
    borderRadius: '50px !important',
    borderColor: '#92a0ab',
  },
  searchFieldIcon: {
    fill: '#92a0ab !important',
  },
  loadingIndicatorContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
}));
