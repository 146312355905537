import NotificationContext from '@context/NotificationContext';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { useStyles } from '@ui/utils/makeStyles';
import toJsDate from '@ui/utils/toJsDate';
import { addDays, isBefore } from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import isWeekend from 'date-fns/isWeekend';
import React, { useContext, useState } from 'react';

export type DeinstallRequestDialogResolveArgs = {
  date: guesthouse.Date;
  notes: string;
}

interface DeinstallRequestDialogArgs {
  title?: string;
  date?: guesthouse.Date;
  notes?: string;
  resolve: (values: DeinstallRequestDialogResolveArgs) => void;
  reject: (e?: Error | boolean) => void;
}

const DeinstallRequestDialog: React.FC<DeinstallRequestDialogArgs> = ({ resolve, reject, title = 'Request a De-install', notes = '' }) => {
  const { theme } = useStyles();
  const [note, setNote] = useState(notes);
  const [open, setOpen] = useState(true);
  const [selectedDate, setSelectedDate] = useState<guesthouse.Date>(null);
  const notificationContext = useContext<NotificationContext>(NotificationContext);

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      onClose={() => {
        reject(false);
        setOpen(false);
      }}
    >
      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        <Alert
          severity="info"
          style={{ margin: theme.spacing(2, 0, 0) }}
        >
          De-install dates aren&apos;t final until approved by our team.
        </Alert>
      </DialogContent>

      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <DatePicker
              label="Preferred Date"
              value={selectedDate}
              slotProps={{
                textField: {
                  margin: 'normal',
                  style: {
                    width: '100%'
                  },
                  helperText: 'De-installs require at least 5 days of lead time'
                },
              }}
              shouldDisableDate={(day: Date) => {
                const today = new Date();

                if (isWeekend(day)) {
                  return true;
                } else if (differenceInDays(day, today) < 5) {
                  return true;
                }
                return false;
              }}
              onChange={setSelectedDate}
            />
          </Box>
        </LocalizationProvider>
      </DialogContent>

      <DialogContent>
        <TextField
          multiline
          fullWidth
          value={note}
          placeholder="Any notes on the deinstallation..."
          variant="outlined"
          rows={3}
          onChange={e => setNote(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button
          size="small"
          onClick={() => {
            reject(false);
            setOpen(false);
          }}
        >
          Cancel
        </Button>

        <SubmitButton
          size="small"
          disabled={isBefore(toJsDate(selectedDate), addDays(new Date(), 4)) || isWeekend(toJsDate(selectedDate))}
          tooltip={isBefore(toJsDate(selectedDate), addDays(new Date(), 4)) || isWeekend(toJsDate(selectedDate)) ? 'Please select a date at least 5 days out' : ''}
          onClick={() => {
            const fiveDaysOut = addDays(new Date(), 4);

            if (isBefore(toJsDate(selectedDate), fiveDaysOut) || isWeekend(toJsDate(selectedDate))) {
              return notificationContext.setContext({ open: true, message: 'You must select a date at least 5 days out', severity: 'error' });
            }
            resolve({
              date: selectedDate,
              notes: note,
            });
            setOpen(false);
          }}
        >
          Request
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeinstallRequestDialog;
