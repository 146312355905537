import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  productListItem: {
    minHeight: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    backgroundColor: '#ffffff',
    borderRadius: theme.shape.borderRadius,
    textDecoration: 'none',
    width: '100%',
    '& a': {
      WebkitTapHighlightColor: 'transparent'
    }
  },
  productImageContainer: {
    margin: '1px 0 1px 6px',
    height: 68,
    width: 68,
    '& img': {
      borderRadius: 5,
    },
    overflow: 'hidden',
  },
  productInfoContainer: {
    width: '100%',
    paddingRight: 10,
    height: 60,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    marginLeft: theme.spacing(2)
  },
  productTitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
    paddingTop: 2,
    marginBottom: 2,
    marginRight: 10,
  },
  offerPrice: {
    fontFamily: theme.gh_vars.mabryMono,
    fontSize: 11,
    marginRight: 5,
  },
  company: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    color: '#666666',
  },
  actions: {

  }
}));
