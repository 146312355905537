import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import PromoCodeDialog from '@components/dialogs/PromoCodeDialog';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import Sentry from '@integrations/Sentry';
import toJsDate from '@ui/utils/toJsDate';
import newId from '@utils/newId';
import { collection, doc, getDoc, getDocs, setDoc, writeBatch } from 'firebase/firestore';
import { useContext } from 'react';

export default function useEditPromoHandler() {
  const userContext = useContext<UserContext>(UserContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);

  return (docID) => new Promise<guesthouse.Coupon>((resolve, reject) => {
    getDoc(doc(database, `coupons/${docID}`))
      .then((snap) => snap.data())
      .then(async (coupon: guesthouse.Coupon) => {

        const { docs: usersIncludedDocs } = await getDocs(collection(database, `coupons/${coupon.docID}/usersIncluded`));
        const { docs: usersExcludedDocs } = await getDocs(collection(database, `coupons/${coupon.docID}/usersExcluded`));
        const { docs: productsIncludedDocs } = await getDocs(collection(database, `coupons/${coupon.docID}/productsIncluded`));
        const { docs: productsExcludedDocs } = await getDocs(collection(database, `coupons/${coupon.docID}/productsExcluded`));

        const usersIncludedData: Pick<guesthouse.User, 'docID' | 'firstname' | 'lastname'>[] = [];
        const usersExcludedData: Pick<guesthouse.User, 'docID' | 'firstname' | 'lastname'>[] = [];
        const productsIncludedData: guesthouse.ProductLite[] = [];
        const productsExcludedData: guesthouse.ProductLite[] = [];

        if (usersIncludedDocs?.length) {
          for (const doc of usersIncludedDocs) {
            usersIncludedData.push(doc.data() as Pick<guesthouse.User, 'docID' | 'firstname' | 'lastname'>);
          }
        }

        if (usersExcludedDocs?.length) {
          for (const doc of usersExcludedDocs) {
            usersExcludedData.push(doc.data() as Pick<guesthouse.User, 'docID' | 'firstname' | 'lastname'>);
          }
        }

        if (productsIncludedDocs?.length) {
          for (const doc of productsIncludedDocs) {
            productsIncludedData.push(doc.data() as guesthouse.ProductLite);
          }
        }

        if (productsExcludedDocs?.length) {
          for (const doc of productsExcludedDocs) {
            productsExcludedData.push(doc.data() as guesthouse.ProductLite);
          }
        }

        const initialValues = {
          ...coupon,
          users_included: usersIncludedData,
          users_excluded: usersExcludedData,
          products_included: productsIncludedData,
          products_excluded: productsExcludedData
        };

        if (initialValues.expires) {
          initialValues.expires = toJsDate(initialValues.expires);
        }

        if (initialValues.retail_discount_type === 'percent') {
          initialValues.retail_discount_amount *= 100;
        }

        if (initialValues.staging_discount_type === 'percent') {
          initialValues.staging_discount_amount *= 100;
        }

        return asyncRender(PromoCodeDialog, {
          userContext,
          title: 'Update promo code',
          initialValues,
          onSubmit: async (values) => {
            try {
              const batch = writeBatch(database);

              values.updated = new Date();

              if (!values.max_uses || values.max_uses <= 0) {
                values.max_uses = Infinity;
              }

              if (values.users_included) {
                const tempUsers = [];

                for (const user of values.users_included) {
                  const docRef = doc(collection(doc(collection(database, 'coupons'), docID), 'usersIncluded'), user.docID);

                  batch.set(docRef, user);
                  tempUsers.push(user.docID);
                }

                const usersIncludedSnapshot = await getDocs(collection(database, `coupons/${docID}/usersIncluded`));

                for (const doc of usersIncludedSnapshot.docs) {
                  if (!tempUsers.includes(doc.data().docID)) {
                    batch.delete(doc.ref);
                  }
                }

                delete values.users_included;
              }

              if (values.users_excluded) {
                const tempUsers = [];

                for (const user of values.users_excluded) {
                  const docRef = doc(collection(doc(collection(database, 'coupons'), docID), 'usersExcluded'), user.docID);

                  batch.set(docRef, user);
                  tempUsers.push(user.docID);
                }

                const usersExcludedSnapshot = await getDocs(collection(database, `coupons/${docID}/usersExcluded`));

                for (const doc of usersExcludedSnapshot.docs) {
                  if (!tempUsers.includes(doc.data().docID)) {
                    batch.delete(doc.ref);
                  }
                }
                delete values.users_excluded;
              }

              if (values.products_included) {
                const tempProducts = [];

                for (const product of values.products_included) {
                  if (!product.docID) {
                    product.docID = newId();
                  }
                  const docRef = doc(collection(doc(collection(database, 'coupons'), docID), 'productsIncluded'), product.docID);

                  batch.set(docRef, product);
                  tempProducts.push(product.docID);
                }

                const productsIncludedSnapshot = await getDocs(collection(database, `coupons/${docID}/productsIncluded`));

                for (const doc of productsIncludedSnapshot.docs) {
                  if (!tempProducts.includes(doc.data().docID)) {
                    batch.delete(doc.ref);
                  }
                }
                delete values.products_included;
              }

              if (values.products_excluded) {
                const tempProducts = [];

                for (const product of values.products_excluded) {
                  if (!product.docID) {
                    product.docID = newId();
                  }
                  const docRef = doc(collection(doc(collection(database, 'coupons'), docID), 'productsExcluded'), product.docID);

                  batch.set(docRef, product);
                  tempProducts.push(product.docID);
                }

                const productsExcludedSnapshot = await getDocs(collection(database, `coupons/${docID}/productsExcluded`));

                for (const doc of productsExcludedSnapshot.docs) {
                  if (!tempProducts.includes(doc.data().docID)) {
                    batch.delete(doc.ref);
                  }
                }
                delete values.products_excluded;
              }

              if (values.staging_discount_type === 'percent') {
                values.staging_discount_amount /= 100;
              }

              if (values.retail_discount_type === 'percent') {
                values.retail_discount_amount /= 100;
              }

              await batch.commit();

              await setDoc(
                doc(collection(database, 'coupons'), docID),
                values,
                { merge: true }
              )
                .then(() => {
                  notificationContext.setContext({ open: true, message: `Promo code ${values.code} updated` });
                });
              resolve(values);
            } catch (e) {
              Sentry.captureException(e);
              reject(e);
            }
          }
        });
      });
  });
}
