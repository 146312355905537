import { GridProps } from '@mui/material/Grid';
import React, { SyntheticEvent } from 'react';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  spacing?: number;
  afterGrid?: () => JSX.Element | null;
  gridItemProps?: GridProps;
  gridProps?: GridProps;
  homes?: guesthouse.Home[] | guesthouse.AlgoliaHome[];
  onHomeClick?: (e: SyntheticEvent<HTMLDivElement>, home: guesthouse.Home) => void;
  href?: (home: Partial<guesthouse.Home>) => string;
  imageHeight?: number | string;
  showSubscription?: boolean;
}

const defaultProps: Partial<Props> = {
  spacing: 12,
  gridProps: {},
  gridItemProps: {},
  homes: [],
  showSubscription: false,
};

export default Props;
export { defaultProps };
