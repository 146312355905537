import makeStyles from '@ui/utils/makeStyles';

export default makeStyles<
  {},
  'deleteButton'
>()((theme, _params, classes) => {
  const deleteButton = {
    display: 'none',
    marginRight: theme.spacing(1),
  } as const;

  return {
    deleteButton,
    root: {
      width: '100%',
      height: 60,
      [`&:hover .${classes.deleteButton}`]: {
        display: 'flex',
      }
    },
    avatarTextWrapper: {
      display: 'inline-block',
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      marginRight: 'auto',
    },
    nameWrapper: {
      position: 'relative',
      marginLeft: theme.spacing(1.5),
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    text: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.4,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontFamily: theme.gh_vars.circular
    },
    overline: {
      fontSize: 10,
      lineHeight: 1.8,
      fontWeight: 500,
      fontFamily: theme.gh_vars.circular
    }
  };
});
