import UserContext from '@context/UserContext';
import AddCalendarForm from '@forms/AddCalendar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';

type CalendarAddDialogArgs = {
  resolve: (values?: guesthouse.Calendar) => void;
  reject: (e?: Error | boolean) => void;
  userContext: UserContext;
  title?: string;
  submitText?: string;
  cancelText?: string;
  initialValues?: object;
}

const CalendarAddDialog: React.FC<CalendarAddDialogArgs> = (props) => {
  const { 
    resolve, 
    reject, 
    userContext, 
    initialValues,
    submitText
  } = props;
  
  const [open, setOpen] = useState(true);
  
  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        onClose={() => {
          resolve();
          setOpen(false);
        }}
      >
        <DialogTitle>
          Add Calendar
        </DialogTitle>
        <DialogContent>
          <AddCalendarForm
            error={false}
            initialValues={initialValues || {}}
            submitText={submitText}
            onSubmit={(calendar: guesthouse.Calendar) => {
              calendar.googleCalendarId = calendar.googleCalendarId.replace(/\s+/g, ' ').trim();
              setOpen(false);
              resolve(calendar);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              reject();
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};

export default CalendarAddDialog;
