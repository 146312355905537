import UserContext from '@context/UserContext';
import React, { useContext } from 'react';

import checkFlags, { AllowedFlags } from '../../utils/checkFlags';

type FlaggedProps = {
  allowedFlags?: AllowedFlags;
  renderUnauthorized?: (isLoggedIn: boolean) => JSX.Element | string;
  children?: React.ReactNode;
}

const Flagged: React.FC<FlaggedProps> = (props) => {
  const { allowedFlags, children, renderUnauthorized } = props;
  const userContext = useContext<UserContext>(UserContext);
  const authFlags = userContext?.flags || {};

  if (allowedFlags.includes('*')) {
    return (
      <>
        {children}
      </>
    );
  }

  const authorized = checkFlags(allowedFlags, authFlags);

  if (authorized) {
    return (
      <>
        {children}
      </>
    );
  }

  if (renderUnauthorized) {
    return (
      <>
        {renderUnauthorized(!!userContext.user)}
      </>
    );
  }

  return null;
};

Flagged.defaultProps = {
  allowedFlags: [],
};

export default Flagged;
