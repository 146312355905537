import UserContext from '@context/UserContext';
import { useContext } from 'react';


function useUserInfo() {
  const userContext = useContext<UserContext>(UserContext);
  const userData = userContext.data;

  if (userData) {
    const { firstname, lastname, company } = userData;
    const photoURL = userContext.user.photoURL;
    let initials = '';

    try {
      initials = (firstname[0] + lastname[0]) || '';
    } catch (e) {
      // do nothing
    }

    return { firstname, lastname, company, initials, photoURL };
  }
  return {};
}

export default useUserInfo;
