export default function debouncePromise<T extends(...args: any[]) => any>(f: T, interval: number): (...funcArgs: Parameters<T>) => Promise<ReturnType<T>> {
  let timer = null;
  
  return (...args: Parameters<T>): Promise<ReturnType<T>> => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = setTimeout(
        () => resolve(f(...args)),
        interval,
      );
    });
  };
}
