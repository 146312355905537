import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import EditOfferDialog from '@components/dialogs/EditOfferDialog';
import { OfferSelectDialog } from '@components/dialogs/OfferSelectDialog';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import Sentry from '@integrations/Sentry';
import IconMoreHoriz from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import checkRoles from '@ui/utils/checkRoles';
import { collection,deleteDoc,deleteField,getDocs,query,QueryDocumentSnapshot, updateDoc, where, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';

type ProductOfferActionsProps = {
  product: QueryDocumentSnapshot<guesthouse.Product>
  offer: QueryDocumentSnapshot<guesthouse.ProductOffer>
}

const ProductOfferActions: React.FC<ProductOfferActionsProps> = ({ offer, product }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const userContext = useContext<UserContext>(UserContext);
  const offerData = offer.data();
  const productData = product.data();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const internal = checkRoles(['admin', 'design_manager', 'designer', 'website_manager', 'customer_support'], userContext.roles);
  const owner = offerData.soldBy?.docID === userContext.user.uid;

  if (internal || owner) {
    return (
      <div>
        <IconButton
          edge="end"
          aria-label="actions"
          aria-haspopup="true"
          size="large"
          onClick={handleClick}
        >
          <IconMoreHoriz />
        </IconButton>

        <Menu
          keepMounted
          id="profile-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleClose}
        >

          <MenuItem
            onClick={() => {
              asyncRender(EditOfferDialog, { userContext, initialValues: offerData })
                // @ts-ignore https://github.com/firebase/firebase-js-sdk/issues/5853
                .then((values) => updateDoc(offer.ref, values))
                .then(() => notificationContext.setContext({ open: true, message: 'Offer updated' }))
                .catch((e) => {
                  if (e) {
                    Sentry.captureException(e);
                    notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                  }
                });

              handleClose();
            }}
          >
            Edit
          </MenuItem>

          <MenuItem
            onClick={() => {
              const reassignInventory = offerData.stockQuantity > 0;
              const otherOffers = productData.offers?.length > 1;

              asyncRender(ConfirmDialog, {
                title: 'Delete Offer',
                content: 'Are you sure want to delete this offer? This action cannot be undone.',
                confirmText: 'Yes, Delete',
              })
                .then(() => {
                  if (reassignInventory && otherOffers) {
                    return asyncRender(OfferSelectDialog, {
                      userContext,
                      offer,
                      product,
                      title: 'Re-assign Inventory',
                      content: (
                        <Typography>
                          This offer is currently assigned to
                          {' '}
                          {offerData?.stockQuantity}
                          {' '}
                          inventory item
                          {offerData?.stockQuantity > 1 ? 's' : ''}
                          , please select a new offer for this inventory or leave the price field empty to continue
                        </Typography>
                      )
                    })
                      .then(newOffer => {
                        return getDocs(query(collection(product.ref, 'inventory')
                          ,where('offer.docID', '==', offer.id)))
                          .then((collection) => {
                            if (collection?.docs?.length) {
                              const updatedInventory: Partial<guesthouse.ProductInventory> = {
                                offer: newOffer,
                              };
                              const batch = writeBatch(database);

                              collection.docs.forEach((doc) => batch.update(doc.ref, updatedInventory));

                              return batch.commit();
                            }
                          });
                      });
                  } else {
                    return getDocs(query(collection(product.ref, 'inventory')
                      ,where('offer.docID', '==', offer.id)))
                      .then((collection) => {
                        if (collection?.docs?.length) {
                          const batch = writeBatch(database);

                          collection.docs.forEach((doc) => batch.update(doc.ref, { offer: deleteField() }));

                          return batch.commit();
                        }
                      });
                  }
                })
                .then(() => deleteDoc(offer.ref))
                .then(() => notificationContext.setContext({ open: true, message: 'Offer deleted' }))
                .catch((e) => {
                  if (e) {
                    Sentry.captureException(e);
                    notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                  }
                });

              handleClose();
            }}
          >
            Remove
          </MenuItem>
        </Menu>
      </div>
    );
  }

  return null;
};

export default ProductOfferActions;
