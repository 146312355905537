import { SxProps, Theme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React, { useMemo } from 'react';

type UserAvatarProps = {
  user: Partial<guesthouse.User>;
  size?: number | string;
  className?: string;
  style?: React.CSSProperties;
  avatarStyle?: React.CSSProperties;
  avatarSX?: SxProps<Theme>;
  fallback?: string;
}

// Odd DOM nesting here and styles are so the avatar can be responsive inside of a grid... 
//
const UserAvatar = (props : UserAvatarProps) => {
  const { user, size, style, avatarStyle, avatarSX, className } = props;

  let initials = '';
  
  let photoURL = user?.photoURL;
  const firstname = user?.firstname;
  const lastname = user?.lastname;

  if (firstname && lastname) {
    initials = (firstname[0] + lastname[0]);
  }

  if (!photoURL && !initials) {
    photoURL = props.fallback;
  }

  const brandLogoSize = useMemo(() => {
    if (typeof size === 'string') {
      return '25%';
    }

    return size / 4.1;
  }, [size]);

  return (
    <div
      style={{ ...style, width: size, height: size }}
      className={className}
    >
      <div style={{ position: 'relative', paddingTop: '100%' }}>
        {user?.brandLogoURL && (
          <Avatar
            src={user.brandLogoURL}
            style={{ 
              width: brandLogoSize, 
              height: brandLogoSize, 
              position: 'absolute', 
              top: 0, 
              right: 0, 
              zIndex: 2,
              ...avatarStyle
            }}
            sx={avatarSX}
            alt=""
          >
            {initials}
          </Avatar>
        )}
        <Avatar
          src={photoURL}
          style={{ 
            width: size, 
            height: size, 
            position: 'absolute', 
            top: 0, 
            left: 0, 
            ...avatarStyle
          }}
          sx={avatarSX}
          alt=""
        >
          {initials}
        </Avatar>
      </div>
    </div>
  );
};

UserAvatar.defaultProps = {
  style: {},
};

export default UserAvatar;
