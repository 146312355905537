import { database } from '@app/firebase';
import { collection as firestoreCollection, DocumentSnapshot, limit, query, QueryDocumentSnapshot, where } from 'firebase/firestore';
import { useMemo } from 'react';

import { DefaultFirestoreConverter } from '../../types/DefaultFirestoreConverter';
import useCollection from './useCollection';
import useDocument from './useDocument';

interface DocumentBySlugOrIDReturn<T> {
  id: string
  loading: boolean
  doc: DocumentSnapshot<T> | QueryDocumentSnapshot<T> | undefined
  exists: boolean
}

function useDocumentBySlugOrID<T>(slug : string, collectionPath : string): DocumentBySlugOrIDReturn<T>  {
  const { doc: docRef, loading: docLoading } = useDocument<T>(`${collectionPath}/${slug}`);
  const { collection , loading: collectionLoading } = useCollection<T>(
    query(firestoreCollection(database, collectionPath).withConverter(new DefaultFirestoreConverter<T>())
      ,where('slug', '==', slug)
      ,limit(1)), 
    slug + collectionPath
  );

  const { id, loading, doc, exists } = useMemo(() => {
    return {
      id: doc?.id || collection?.docs[0]?.id || slug,
      loading: docLoading || collectionLoading,
      doc: (docRef?.exists() && docRef) || collection?.docs[0],
      exists: docRef?.exists() || collection?.docs[0]?.exists(),
    };
  }, [slug, docRef, collection, docLoading, collectionLoading]);

  return { id, loading, doc, exists };
}

export default useDocumentBySlugOrID;
