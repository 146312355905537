import { auth, database, storage } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import PasswordDialog from '@components/dialogs/PasswordDialog';
import ImageUploader from '@components/image-uploader/ImageUploader';
import LocationSearch from '@components/location-search/LocationSearch';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import { CATEGORIES } from '@data';
import useCollection from '@hooks/useCollection';
import Sentry from '@integrations/Sentry';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import ProfileSchema from '@schema/ProfileSchema';
import useCommonStyles from '@styles/common.style';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors } from '@ui/components/form-errors/FormErrors';
import NotificationPreferences from '@ui/components/notification-preferences/NotificationPreferences';
import Protected from '@ui/components/protected/Protected';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import formatPhone from '@ui/utils/formatPhone';
import { isError } from '@ui/utils/typescriptHelpers';
import userFullName from '@ui/utils/userFullName';
import debouncePromise from '@utils/debouncePromise';
import newId from '@utils/newId';
import { checkSlug } from '@utils/scannerNextChar';
import PhoneNumber from 'awesome-phonenumber';
import { updateEmail, updateProfile } from 'firebase/auth';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { createRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import slugify from 'slugify';

import TextFieldPhoneNumber from '../../components/textfield-phone-number/TextFieldPhoneNumber';
import useStyles from './Profile.style';


const debounceCheckSlug = debouncePromise(checkSlug, 300);

const getExt = (file: File) => {
  if (!file.type.includes('image/')) {
    throw new Error(`Sorry, ${file.type} is not supported. Please use jpeg or png.`);
  }

  switch (file.type) {
    case 'image/jpeg':
      return 'jpg';
    case 'image/png':
      return 'png';
    default:
      return file.type.replace('image/', '');
  }
};


const Profile = () => {
  const { classes } = useStyles();
  const { classes: common } = useCommonStyles();
  const [error, setError] = useState(false);
  const [photoURL, setPhotoURL] = useState<string>();
  const [coverURL, setCoverURL] = useState<string>();
  const [coverPosition, setCoverPosition] = useState<[number, number]>([50, 50]);
  const [photoUploadProgress, setPhotoUploadProgress] = useState<number>();
  const [coverUploadProgress, setCoverUploadProgress] = useState<number>();
  const coverImageRef = useRef<HTMLImageElement>();
  const photoFileInputRef = createRef<HTMLInputElement>();
  const coverFileInputRef = createRef<HTMLInputElement>();
  const { sm, md, lg } = useBreakpoints();
  const avatarSize = 200;
  const coverSize = 200;

  const userContext = useContext<UserContext>(UserContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const { theme } = useStyles();

  const { collection: warehouses, loading: warehousesLoading } = useCollection(collection(database, 'warehouses'));

  const warehouseData = useMemo(() => {
    if (warehouses) {
      return warehouses.docs.map(w => w.data());
    }
    return [];
  }, [warehouses, warehousesLoading]);

  useEffect(() => {
    setPhotoURL(userContext.user?.photoURL);
  }, [userContext.user?.photoURL]);

  useEffect(() => {
    setCoverURL(userContext.data?.coverURL);
  }, [userContext.data?.coverURL]);

  useEffect(() => {
    setCoverPosition(userContext.data?.coverPosition);
  }, [userContext.data?.coverPosition]);

  const uploadProfilePhoto = (userId: string, file: File) => {
    let ext;

    try {
      ext = getExt(file);
    } catch (e) {
      notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
      return;
    }

    const uploadId = newId();
    const imageRef = ref(storage, `images/profile/${userId}/${uploadId}.${ext}`);
    const uploadTask = uploadBytesResumable(imageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        setPhotoUploadProgress(progress);
      },
      (error) => {
        Sentry.captureException(error);
      },
      () => {
        getDownloadURL(imageRef).then(url => {
          userContext.setContext({
            ...userContext,
            user: {
              ...userContext.user,
              photoURL: url,
            }
          });
          setPhotoUploadProgress(0);
        });
      }
    );
  };

  const onProfilePhotoDrop = (files: File[]) => {
    if (files[0]) {
      const [file] = files;

      uploadProfilePhoto(userContext.user?.uid, file);
    }
  };

  const uploadCoverPhoto = (userId: string, file: File) => {
    let ext;

    try {
      ext = getExt(file);
    } catch (e) {
      notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
      return;
    }

    const uploadId = newId();
    const imageRef = ref(storage, `images/cover/${userId}/${uploadId}.${ext}`);
    const uploadTask = uploadBytesResumable(imageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        setCoverUploadProgress(progress);
      },
      (error) => {
        Sentry.captureException(error);
      },
      () => {
        getDownloadURL(imageRef).then(url => {
          userContext.setContext({
            ...userContext,
            data: {
              ...userContext.data,
              coverURL: url,
            }
          });
          setCoverUploadProgress(0);
        });
      }
    );
  };

  const onCoverPhotoDrop = (files: File[]) => {
    if (files[0]) {
      const [file] = files;

      uploadCoverPhoto(userContext.user?.uid, file);
    }
  };

  const startPositionDrag = (mousedown) => {
    mousedown.preventDefault();
    const x = mousedown.clientX;
    const y = mousedown.clientY;

    const [originalCoverX, originalCoverY] = coverPosition || [50, 50];

    let newX = originalCoverX;
    let newY = originalCoverY;

    const onMouseMove = (mousemove) => {
      const mousemoveX = mousemove.clientX;
      const mousemoveY = mousemove.clientY;

      const xDiff = (mousemoveX - x) / 2;
      const yDiff = (mousemoveY - y) / 2;

      newX = originalCoverX - xDiff;
      newY = originalCoverY - yDiff;

      if (newX < 0) {
        newX = 0;
      }
      if (newX > 100) {
        newX = 100;
      }
      if (newY < 0) {
        newY = 0;
      }
      if (newY > 100) {
        newY = 100;
      }

      coverImageRef.current.style.objectPosition = `${newX}% ${newY}%`;
    };

    const onMouseUp = async () => {
      const newPosition: [number, number] = [newX, newY];
      const updatedUser: Partial<guesthouse.User> = {
        coverPosition: newPosition
      };

      setCoverPosition(newPosition);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);

      await updateDoc(doc(collection(database, 'users')
        ,userContext.user?.uid)
      ,updatedUser);
    };


    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const handleNotificationPreferences = (notificationPreferences: guesthouse.NotificationPreferences) => {
    return updateDoc(doc(collection(database, 'users'), userContext.user?.uid), {
      notificationPreferences
    }).then(() => {
      notificationContext.setContext({ open: true, message: 'Notification preferences updated' });
    }).catch((e) => {
      notificationContext.setContext({ open: true, severity: 'error', message: e.message });
      Sentry.captureException(e);
    }
    );
  };

  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={ProfileSchema}
        initialValues={userContext.data}
        onSubmit={async (values: guesthouse.User | undefined) => {
          setError(false);

          if (values?.email !== userContext.user?.email) {
            asyncRender(PasswordDialog, { userContext, title: 'Password is required to update your email address' })
              .then(() => updateEmail(auth.currentUser, values?.email))
              .then(() => updateDoc(doc(collection(database, 'users'), userContext.user?.uid), { email: values?.email }))
              .catch((e) => {
                if (e) {
                  Sentry.captureException(e);
                  notificationContext.setContext({ open: true, severity: 'error', message: 'There was an error updating your email' });
                  setError(e.message);
                }
              });
          }

          if (values?.phone) {
            try {
              values.phone = formatPhone(values?.phone);
            } catch (e) {
              Sentry.captureException(e);
            }
          }

          // email updating is handled above as a special process
          //
          const updateValues = { ...values };

          delete updateValues.email;

          return updateDoc(
            doc(collection(database, 'users'), userContext.user?.uid),
            updateValues
          )
            .then(() => {
              notificationContext.setContext({ open: true, message: 'Your profile has been updated' });
            })
            .catch((e) => {
              if (e) {
                Sentry.captureException(e);
                notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                setError(e.message);
              }
            });
        }}
      >
        {({
          errors,
          values,
          touched,
          setFieldTouched,
          setFieldValue,
          handleSubmit,
          isSubmitting,
        }) => {

          if (values?.phone?.startsWith('+1')) {
            const pn = new PhoneNumber(values?.phone, 'US');

            values.phone = pn.getNumber('significant');
          }

          const updateSlug = async (firstname, lastname, company) => {
            const username = userFullName({ firstname, lastname, company }, true);

            const slug = slugify(username, {
              strict: true,
              lower: true,
            });

            let existingSlugs = [];

            try {
              existingSlugs = await debounceCheckSlug(slug, 'users');
            } catch (e) {
              Sentry.captureException(e);
              return;
            }


            if (existingSlugs.length) {
              let n = 1;
              let incrememtedSlug = slug;

              while (existingSlugs.indexOf(incrememtedSlug) > -1) {
                incrememtedSlug = `${slug}-${n}`;
                n += 1;
              }

              setFieldValue('slug', incrememtedSlug);
            } else {
              setFieldValue('slug', slug);
            }
          };

          return (
            <Grid container>
              <Grid
                item
                xs={12}
                lg={6}
                className={common.contentSpacing}
                style={{ borderRight: '1px #DDDDDD solid' }}
              >

                <Box className={classes.coverBox}>
                  {
                    coverURL
                      ? (
                        <>
                          <img
                            ref={coverImageRef}
                            src={coverURL}
                            style={{
                              height: coverSize,
                              width: '100%',
                              borderRadius: theme.shape.borderRadius,
                              objectFit: 'cover',
                              display: 'block',
                              objectPosition: Array.isArray(coverPosition) ? `${coverPosition[0]}% ${coverPosition[1]}%` : 'center center',
                            }}
                            className={classes.coverImg}
                            onMouseDown={startPositionDrag}
                          />

                          <input
                            ref={coverFileInputRef}
                            type="file"
                            style={{ display: 'none', }}
                            onChange={(e) => {
                              const file = e.target.files[0];

                              uploadCoverPhoto(userContext.user?.uid, file);
                            }}
                          />

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            style={{
                              position: 'absolute',
                              top: theme.spacing(1),
                              right: theme.spacing(1),
                            }}
                          >

                            <IconButton
                              className={classes.actionButton}
                              style={{ margin: theme.spacing(0, 1, 0, 0) }}
                              size="large"
                              onClick={() => {
                                coverFileInputRef.current.click();
                              }}
                            >
                              <EditIcon
                                className={classes.actionIcon}
                                fontSize="small"
                              />
                            </IconButton>

                            <IconButton
                              className={classes.actionButton}
                              size="large"
                              onClick={() => {
                                updateDoc(doc(collection(database, 'users'), userContext.user?.uid), { coverURL: null }).then(() => {
                                  userContext.setContext({
                                    ...userContext,
                                    data: {
                                      ...userContext.data,
                                      coverURL: null,
                                      coverPosition: [50, 50],
                                    }
                                  });
                                }).catch(Sentry.captureException);
                              }}
                            >
                              <DeleteIcon
                                className={classes.actionIcon}
                                fontSize="small"
                              />
                            </IconButton>
                          </Box>
                        </>
                      )
                      : (
                        <ImageUploader
                          buttonText="Add Cover Image"
                          style={{
                            height: coverSize,
                            width: '100%',
                            borderRadius: theme.shape.borderRadius,
                          }}
                          onDrop={onCoverPhotoDrop}
                        />
                      )
                  }

                  {!!coverUploadProgress && <LinearProgress
                    variant="determinate"
                    value={coverUploadProgress}
                    className={classes.coverProgress}
                  />}
                </Box>

                <Box
                  display="flex"
                  justifyContent={sm ? 'flex-start' : 'center'}
                  style={{ marginLeft: sm ? theme.spacing(3) : 0, marginBottom: md ? theme.spacing(20) : theme.spacing(24) }}
                >
                  <Box
                    textAlign="center"
                    className={classes.avatarBox}
                  >
                    {!!photoUploadProgress && <CircularProgress
                      size={avatarSize}
                      thickness={.5}
                      variant="determinate"
                      value={photoUploadProgress}
                      className={classes.avatarProgress}
                    />}

                    {
                      photoURL
                        ? (
                          <>
                            <Avatar
                              src={photoURL}
                              style={{ width: avatarSize, height: avatarSize }}
                            />

                            <input
                              ref={photoFileInputRef}
                              type="file"
                              style={{ display: 'none', }}
                              onChange={(e) => {
                                const file = e.target.files[0];

                                uploadProfilePhoto(userContext.user?.uid, file);
                              }}
                            />

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >

                              <IconButton
                                style={{ marginTop: 10 }}
                                size="large"
                                onClick={() => {
                                  photoFileInputRef.current.click();
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>

                              <IconButton
                                style={{ marginTop: 10 }}
                                size="large"
                                onClick={() => {
                                  Promise.all([
                                    updateProfile(auth.currentUser, { photoURL: null, }),
                                    updateDoc(doc(collection(database, 'users'), userContext.user?.uid), { photoURL: null })
                                  ]).then(() => {
                                    userContext.setContext({
                                      ...userContext,
                                      user: {
                                        ...userContext.user,
                                        photoURL: null,
                                      }
                                    });
                                  }).catch((e) => {
                                    if (e) {
                                      Sentry.captureException(e);
                                      notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                                    }
                                  });
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          </>
                        )
                        : (
                          <ImageUploader
                            buttonText="Add Profile Image"
                            style={{
                              borderRadius: '50%',
                              minHeight: avatarSize,
                              backgroundColor: theme.palette.tertiary.light
                            }}
                            boxShadow={2}
                            onDrop={onProfilePhotoDrop}
                          />
                        )
                    }
                  </Box>
                </Box>

                <form onSubmit={handleSubmit}>
                  <Field
                    fullWidth
                    name="firstname"
                    label="First name"
                    type="text"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    onChange={async (e) => {
                      setFieldValue('firstname', e.target.value);
                      setFieldTouched('firstname', true);
                      updateSlug(e.target.value, values?.lastname, values?.company);
                    }}
                  />

                  <Field
                    fullWidth
                    name="lastname"
                    label="Last name"
                    type="text"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    onChange={async (e) => {
                      setFieldValue('lastname', e.target.value);
                      setFieldTouched('lastname', true);
                      updateSlug(values?.firstname, e.target.value, values?.company);
                    }}
                  />

                  <Field
                    fullWidth
                    disabled
                    name="slug"
                    label="URL"
                    type="text"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <span>
                          {'https://www.guesthouseshop.com/shop/makers/'}
                        </span>
                      ),
                    }}
                  />

                  <Field
                    fullWidth
                    name="email"
                    label="Email"
                    type="email"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />

                  <Field
                    fullWidth
                    name="phone"
                    label="Phone"
                    type="text"
                    component={TextFieldPhoneNumber}
                    margin="normal"
                    variant="outlined"
                    customInput={TextField}
                    defaultValue={values?.phone}
                    onValueChange={(values) => {
                      setFieldValue('phone', values?.value);
                    }}
                  />

                  <Field
                    fullWidth
                    name="company"
                    label="Company"
                    type="company"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    onChange={async (e) => {
                      setFieldValue('company', e.target.value);
                      setFieldTouched('company', true);
                      updateSlug(values?.firstname, values?.lastname, e.target.value);
                    }}
                  />

                  <Field
                    fullWidth
                    multiline
                    name="bio"
                    label="Bio"
                    type="text"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />

                  <LocationSearch
                    name="location"
                    margin="normal"
                    disabled={isSubmitting}
                    label="Location"
                    defaultValue={values?.location?.address}
                    autocompleteRequestOptions={{
                      types: ['(cities)'],
                      componentRestrictions: {
                        country: 'us'
                      }
                    }}
                    onLocationChange={(location) => {
                      setFieldValue('location', location);
                    }}
                  />

                  <Field
                    fullWidth
                    name="website"
                    label="Website"
                    type="text"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                  />

                  <Field
                    fullWidth
                    name="instagram_handle"
                    label="Instagram Handle"
                    type="text"
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          @
                        </InputAdornment>
                      )
                    }}
                  />

                  {warehouseData.length &&
                    <Protected allowedRoles={['admin', 'design_manager']}>
                      <Autocomplete
                        fullWidth
                        id="warehouse"
                        defaultValue={userContext?.data?.preferred_warehouse}
                        options={warehouseData}
                        getOptionLabel={(option: Partial<guesthouse.Warehouse>) => option.name || ''}
                        renderInput={(params) => {
                          return (
                            <Field
                              {...params}
                              fullWidth
                              name="warehouse"
                              label="Preferred Warehouse"
                              type="text"
                              component={TextField}
                              margin="dense"
                              variant="outlined"
                            />
                          );
                        }}
                        onChange={(e, value) => {
                          setFieldValue('preferred_warehouse', value);
                        }}
                      />
                    </Protected>
                  }

                  <Protected allowedRoles={['maker']}>

                    <Typography
                      variant="h3Alt"
                      style={{ margin: theme.spacing(2, 0, 1) }}
                    >
                      Store Front
                    </Typography>


                    <FormControl
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      error={touched.productCategory && Boolean(errors.productCategory)}
                    >
                      <InputLabel id="productCategory-label">
                        Product Categories
                      </InputLabel>

                      <Select
                        multiple
                        labelId="productCategory-label"
                        id="productCategory"
                        value={values?.productCategory || []}
                        label="Product Categories"
                        renderValue={selected => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                            >
                              {(selected as string[]).map(value => (
                                <Chip
                                  key={value}
                                  label={value}
                                  style={{ margin: 2 }}
                                />
                              ))}
                            </div>
                          );
                        }}
                        onChange={(e) => {
                          setFieldTouched('productCategory', true);
                          setFieldValue('productCategory', e.target.value);
                        }}
                      >
                        {CATEGORIES.map(category => (
                          <MenuItem
                            key={category.id}
                            value={category.id}
                          >
                            {category.title}
                          </MenuItem>
                        ))}
                      </Select>

                      {touched.productCategory && Boolean(errors.productCategory) && (
                        <FormHelperText error>
                          {errors.productCategory}
                        </FormHelperText>
                      )}
                    </FormControl>


                    <Field
                      fullWidth
                      multiline
                      name="shipping_policy"
                      label="Shipping Policy"
                      type="text"
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                    />

                    <Field
                      fullWidth
                      multiline
                      name="return_policy"
                      label="Return Policy"
                      type="text"
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                    />

                  </Protected>

                  {error && (
                    <FormHelperText error>
                      {isError(error) ? error.message : error}
                    </FormHelperText>
                  )}

                  <div style={{ paddingTop: 20 }}>
                    <SubmitButton
                      fullWidth
                      data-test="profile-submit-button"
                      isSubmitting={isSubmitting}
                      disabled={Boolean(Object.keys(errors).length)}
                      aria-label="Save Profile"
                      tooltip={!!Object.keys(errors).length && (
                        <div style={{ textAlign: 'center' }}>
                          {makeErrors(errors, touched, false).map((e, i) => (
                            <div key={i}>
                              {e}
                            </div>
                          ))}
                        </div>
                      )}
                    >
                      Save Profile
                    </SubmitButton>
                  </div>
                </form>
              </Grid>

              <Grid
                item
                xs={12}
                lg={6}
                className={common.contentSpacing}
              >
                <div style={{ position: lg ? 'fixed' : 'initial', overflow: 'auto', top: 96, bottom: 0, paddingRight: md ? theme.spacing(5) : 0 }}>
                  <Typography
                    variant="h4Alt"
                    style={{ marginBottom: theme.spacing(4.5) }}
                  >
                    Notifications
                  </Typography>
                  <NotificationPreferences
                    handleNotificationPreferences={handleNotificationPreferences}
                    user={userContext.data}
                  />
                </div>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </div>
  );
};

export default React.memo(Profile);
