import React, { useMemo } from 'react';

import AccountBackground1 from '!!file-loader!@images/account-bg-1.svg';
import AccountBackground2 from '!!file-loader!@images/account-bg-2.svg';
import AccountBackground3 from '!!file-loader!@images/account-bg-3.svg';
import AccountBackground4 from '!!file-loader!@images/account-bg-4.svg';

import useStyles from './Registration.style';

const map = [
  AccountBackground1,
  AccountBackground2,
  AccountBackground3,
  AccountBackground4
];

type RegistrationProps = {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

const Registration : React.FC<RegistrationProps> = (props) => {
  const { className, style, children } = props;
  const { classes, cx } = useStyles();
  const backgroundImage = useMemo(() => map[Math.floor(Math.random() * 4)], []);

  return (
    <div
      className={cx(classes.registrationContainer, className)}
      style={style}
      data-bg={backgroundImage}
    >
      <div className={classes.registrationContent}>
        {children}
      </div>
    </div>
  );
};

export default Registration;
