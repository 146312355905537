/**
 * Handles adding "included" prop to list of line items for display on order previews and receipts.
 * 
 * @param lineItems Partial<HubSpot.LineItem>[]
 * @returns Partial<HubSpot.LineItem>[]
 */
export default function filterSortLineItems(lineItems: Partial<HubSpot.LineItem>[] = []) {
  const _lineItems = [...lineItems];

  return _lineItems.sort((a, b) => a.properties.hs_sku?.localeCompare(b.properties.hs_sku))
    .reduce((acc, cur) => {
      const baseItem = ['STA_LIV', 'STA_DIN', 'STA_KIT', 'STA_BED', 'STA_BAT'].indexOf(cur.properties.hs_sku) > -1;
      const firstOfType = !acc.find(li => li.properties.hs_sku === cur.properties.hs_sku);

      if (baseItem && firstOfType) {
        cur.properties.included = 'true';
      }

      acc.push(cur);
      return acc;
    }, [] as Partial<HubSpot.LineItem>[])
    .filter(li => li.properties.hs_sku !== 'BASE');
}
