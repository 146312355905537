import Typography from '@mui/material/Typography';
import React from 'react';

import { useStyles } from '../../utils/makeStyles';
import userFullName from '../../utils/userFullName';

const PickupInfo: React.FC<{ offer: guesthouse.ProductOffer }> = ({ offer }) => {
  const { theme } = useStyles();

  return (
    <>
      <Typography style={{ fontSize: 12, color: theme.palette.common.black }}>
        {userFullName(offer.soldBy, true)}
      </Typography>
      
      <Typography style={{ fontSize: 12, color: theme.palette.common.black }}>
        {offer.soldBy.email}
      </Typography>
      <Typography style={{ fontSize: 12, color: theme.palette.common.black }}>
        {offer.soldBy.phone}
      </Typography>
  
      <Typography
        component="address"
        style={{ fontSize: 12, fontStyle: 'normal' }}
      >
        {offer.pickupLocation?.address?.replace(', USA', '')}
      </Typography>
    </>
  );
};
  
export default PickupInfo;
