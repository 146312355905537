import makeStyles from '@ui/utils/makeStyles';

export default makeStyles<{ swatchColor: string }>()((theme, { swatchColor }) => ({
  wrapper: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    height: 101,
    width: 95,
    [theme.breakpoints.up('xl')]: {
      height: 101,
      width: 120,
    },
  },
  dot: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    background: swatchColor,
    borderRadius: '50%',
    marginRight: theme.spacing(.5),
  },
}));
