import { RouterLink } from '@components/link-behavior/LinkBehavior';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Protected from '@ui/components/protected/Protected';
import UserAvatar from '@ui/components/user-avatar/UserAvatar';
import { useStyles } from '@ui/utils/makeStyles';
import simpleLocation from '@ui/utils/simpleLocation';
import React from 'react';

import Props, { defaultProps } from './UserGrid.props';


const UserGrid: React.FC<Props> = (props: Props) => {
  const { theme } = useStyles();

  const UserCard = (user: Partial<guesthouse.User>) => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <UserAvatar
        user={user}
        size="100%"
      />
      <Typography
        variant="h5Alt"
        style={{ marginTop: theme.spacing(2), fontWeight: 500, fontSize: 18 }}
        align="center"
      >
        {user.company ? user.company : `${user.firstname} ${user.lastname}`}
      </Typography>
      <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
        <Rating
          readOnly
          size="small"
          value={user.rating}
          precision={.5}
        />
      </Protected>
      {user.location && (
        <Typography
          variant="caption1"
          align="center"
          style={{ display: 'block' }}
        >
          {simpleLocation(user.location)}
        </Typography>
      )}
    </Box>
  );

  return (
    <Grid
      container
      spacing={4}
      style={{ marginBottom: theme.spacing(props.spacing) }}
      {...props.gridProps}
    >
      {props.users.map((user) => {

        return (
          <Grid
            key={user.docID}
            item
            xs={6}
            sm={4}
            md={3}
            lg={2}
            xl={2}
            style={{
              cursor: props.onUserClick ? 'pointer' : undefined,
            }}
            onClick={(e) => {
              if (props.onUserClick) {
                props.onUserClick(e, user);
              }
            }}
            {...props.gridItemProps}
          >
            {typeof props.href === 'function' ?
              <Link
                component={RouterLink}
                to={props.href(user)}
              >
                <UserCard {...user} />
              </Link>
              :
              <UserCard {...user} />
            }
          </Grid>
        );
      })}
      {props.afterGrid && (
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
        >
          {props.afterGrid()}
        </Grid>
      )}
    </Grid>
  );
};

UserGrid.defaultProps = defaultProps;

export default UserGrid;
