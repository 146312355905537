
import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  pdf: {
    display: 'flex',
    width: 'calc(100vw - 100px)',
    overflowX: 'auto', 
    WebkitOverflowScrolling: 'touch', 
    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  },
  productCard: {
    width: 230,
    height: 247,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      height: 299,
    },
    [theme.breakpoints.up('lg')]: {
      width: 260,
    },
  }
}));
