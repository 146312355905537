import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  sectionHeader: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
    }
  },
  justMyHomes: {
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    }
  },
}));
