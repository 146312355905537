
import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  customSystemMessage: {
    fontFamily: theme.gh_vars.circular,
    fontSize: 14,
    color: '#666',
    width: '100%',
    borderBottom: '1px solid #EBEBEB',
  }
}));
