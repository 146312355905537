enum EmailTemplates {
  STAGING_RENEWAL_PAYMENT = 'd-45356d8c74e842ffae7b1965948571fc',
  CONSULT_UPCOMING = 'd-f8b471e6fb254abf91f3cee6614e5dd8',
  PHOTOSHOOT_UPCOMING = 'd-8585ed66a6684ac296ffd9e64c1c2b37',
  PASSWORD_RESET = 'd-38ef65d5495a4c2f99c658f6908e988e',
  LOG_IN_LINK = 'd-822ec1cce4964856908476f33da472f7',
  RESET_PASSWORD = 'd-5dd1b320c9a94fd08842c2eca9ecfa69',
  DEINSTALL_UPCOMING = 'd-c8fb8b7214f6434f8b5f2debd2d72150',
  INSTALL_UPCOMING = 'd-0abc7ab7ac044c4389765a2ee4f48d85',
  INSTALL_COMPLETE = 'd-be551931f0544ef0b66718adaec23d8f',
  MOOD_BOARDS = 'd-0c9db08a38604eb38d2d5d1fddaae9c1',
  DESIGN_TEAM_OPEN_HOUSE = 'd-17d0041ea791427d89fbd02719d23db9',
  DEFAULT_NEW_MESSAGE = 'd-d8c26cb4254e4a3b9b74c01f8e3d5587',
  HOME_ASSIGNED = 'd-b5d4d17517964ed3be1d87f8b5596af6',
  HOME_NEW_PHOTOS = 'd-d0e0507912ff460aaeeaf13224964fd3',
  HOME_OPEN_HOUSE = 'd-0ce74f020f474c12abf5fce4685902fb',
  HOME_NEW_MESSAGE = 'd-6ff29535d8054b2e8faca95d7164e75c',
  ORDER_PRODUCT_CANCELLED_ADMIN = 'd-dd02a10a59bd45ba965dfb1b729d380c',
  ORDER_PRODUCT_CANCELLED = 'd-1cd4b7953dff42bab839fd4fe3005b01',
  ORDER_RECEIPT_ADMIN = 'd-7ebaf8ede77845308dd8c321ec9b09aa',
  ORDER_RECEIPT = 'd-1d4a89e07b8f4df185b3e40fac0b09e6',
  UPCOMING_PAYMENT_AFFIRM = 'd-45356d8c74e842ffae7b1965948571fc',
  PRODUCT_PICKUP = 'd-af0a11f9b5d44e87bf4109a9182eefdf',
  PRODUCT_RECEIVED = 'd-37420eb72d474a308d573b96e44df05d',
  PRODUCT_REQUEST_ACCEPTED = 'd-f45357f65efa48d1a9b4c2ad62a577b1',
  PRODUCT_REQUEST_DENIED = 'd-9fdc96f4af9c4ad5bb0289e1e537f518',
  PRODUCT_REQUESTED = 'd-fb1fa0940ce34c169b952131cd040dab',
  PRODUCT_SCHEDULED = 'd-409c773cd7ef4237a1100cb8f3ef51ab',
  PRODUCT_RESCHEDULED = 'd-4155e393723f488a8dfc4bf53a00cf5a',
  PRODUCT_SOLD = 'd-6621fff66cf94305b7b2efc807126ee7',
  PRODUCT_SHIPPED = 'd-1e7a3462c71a42ce81dc5d937c3c79ed',
  ROOM_NEW_PHOTOS = 'd-4d9b56e7571d4db58663b2d41216aa79',
  UPCOMING_PAYMENT = 'd-c21f0ec1b73e4ac78f2c31ac2103d122',
  DEINSTALL_SCHEDULED = 'd-853b06448b7b45f995a508b65ed6d227',
  RECURRING_PAYMENT_RECEIPT = 'd-1e03ac7a3ae344bbb8dd4c66ba3a94a2',
  PAYMENT_RECEIPT = 'd-c3642bc08b814ccca6a5d946c498472e',
  PAYMENT_RECEIPT_ADDONS_ONLY = 'd-8e66f003ae404e6687f2ecfd7e4a8f7c',
  PAYMENT_RECEIPT_ADDONS_ONLY_ADMIN = 'd-dfdd6c3970704366a711282844000761',
  LOCKBOX_UPCOMING = 'd-e7f8fd97a3b6421aba5bf7dfe156237a',
  TASK_DUE = 'd-dcb4c4b4462a4f5baed9e1e6bbac39fa',
  TASK_OVERDUE = 'd-80f44d1983bc4a8cb9747d3f1b8331be',
  HOME_SHOPPABLE = 'd-f6cacfad78ec4cd1aed18ad819ca7f14',
  DEINSTALL_REQUESTED = 'd-0ad8a68fff95447ca0011dd597f8f1b2'
}

export default EmailTemplates;
