import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Protected from '@ui/components/protected/Protected';
import formatCurrency from 'format-currency';
import React from 'react';

import filterSortLineItems from '../../utils/filterSortLineItems';
import formatDate from '../../utils/formatDate';
import LineItem from '../line-item/LineItem';
import LineItemPrice from '../line-item/LineItemPrice';
import useStyles from './StagingOrder.style';

type StagingOrderProps = {
  className?: string;
  style?: React.CSSProperties;
  order: guesthouse.StagingOrder;
  billingRoles?: string[];
  showHomeAddress?: boolean;
  renderActions?: React.ReactElement;
  showReceipt?: boolean;
  receiptPath?: string;
  stagingAgreementOnClick?: () => void;
}

const rooms = [
  {
    key: 'living_or_family_rooms',
    label: 'Living Rooms',
  },
  {
    key: 'dining_rooms',
    label: 'Dining Rooms',
  },
  {
    key: 'kitchens',
    label: 'Kitchens',
  },
  {
    key: 'bedrooms',
    label: 'Bedrooms',
  },
  {
    key: 'bathrooms',
    label: 'Bathrooms',
  },
  {
    key: 'other_rooms',
    label: 'Other Rooms',
  },
];

const StagingOrder: React.FC<StagingOrderProps> = (props) => {
  const { classes, cx, theme } = useStyles();
  const { className, style, order, billingRoles, showHomeAddress, renderActions, showReceipt, receiptPath, stagingAgreementOnClick } = props;
  const {
    docID,
    status,
    deal,
    created,
    addons = [],
    lineItems = [],
    subtotal,
    sale,
    couponDiscount,
    coupon,
    home,
  } = order;

  const title = deal ? 'Order summary' : 'Add-ons';
  const includeStagingAgreementDialog = typeof stagingAgreementOnClick === 'function';

  return (
    <div
      className={cx(classes.orderContainer, className)}
      style={style}
    >
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          style={{ textAlign: 'left' }}
        >
          <Typography
            component="div"
            variant="h5Alt"
            className={classes.orderTitle}
          >
            {title}
          </Typography>

          {showHomeAddress &&
          <Typography
            component="div"
            variant="body1"
            style={{
              color: '#7B7B7B',
              fontSize: 13,
            }}
          >
            {home.address}
          </Typography>
          }

          {created && (
          <Typography
            component="div"
            variant="body1"
            style={{
              color: '#7B7B7B',
              fontSize: 13,
              marginBottom: theme.spacing(2),
            }}
          >
            {formatDate(created)}
          </Typography>
          )}

          <Typography
            component="div"
            variant="h5Alt"
            className={classes.orderSubtitle}
          >
            Staging term
          </Typography>

          <Typography
            component="div"
            variant="body1"
            style={{
              color: '#7B7B7B',
              fontSize: 13,
              marginBottom: theme.spacing(2),
            }}
          >
            {deal?.properties?.staging_term || 30}
            {' '}
            Days
          </Typography>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: theme.spacing(1)
            }}
          >
            {includeStagingAgreementDialog && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{
                marginBottom: theme.spacing(2),
              }}
              onClick={stagingAgreementOnClick}
            >
              Staging Agreement
            </Button>
            )}

            {showReceipt && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{
                marginBottom: theme.spacing(2),
              }}
              onClick={() => {
                window.open(receiptPath, '_blank');
              }}
            >
              View Receipt
            </Button>
            )}
          </div>

        </Box>

        {renderActions && (
        <Box style={{ position: 'relative', top: -5, right: 5 }}>
          {renderActions}
        </Box>
        )}
      </Box>

      <ul className={classes.itemizedList}>
        {deal && rooms.map(room => {
          if (deal.properties[room.key] && Number(deal.properties[room.key])) {
            return (
              <LineItem
                key={room.key}
                style={{ padding: theme.spacing(1, 0) }}
                lineItem={{
                  name: room.label,
                }}
                showIcon={false}
                renderButton={() => {
                  return (
                    <span>
                      {deal.properties[room.key]}
                    </span>
                  );
                }}
              />
            );
          }

          return null;
        })}
        {
          filterSortLineItems(lineItems || [])
            .map((lineItem, i) => {
              return (
                <LineItem
                  key={i}
                  style={{ padding: theme.spacing(1, 0) }}
                  lineItem={lineItem.properties}
                  showIcon={false}
                  renderButton={() => {
                    return (
                      <Typography variant="body2">
                        x
                        {lineItem.properties.quantity || '1'}
                      </Typography>
                    );
                  }}
                />
              );
            })}

        {addons?.length > 0 && addons
          .sort((a, b) => (a.name > b.name) ? 1 : -1)
          .map((addon, i) => {
            return (
              <LineItem
                key={i}
                style={{ padding: theme.spacing(1, 0) }}
                lineItem={addon}
                renderButton={() => {
                  return (
                    <Protected allowedRoles={billingRoles}>
                      <LineItemPrice lineItem={addon} />
                    </Protected>
                  );
                }}
              />
            );
          })}

        {status === 'COMPLETE' && (
          <div
            style={{
              marginTop: theme.spacing(2)
            }}
          >
            <Protected allowedRoles={billingRoles}>
              {sale > 0 && (
                <>
                  <li
                    className={classes.saleListItem}
                  >
                    <Typography
                      component="div"
                      variant="h5Alt"
                      className={classes.itemSubTotal}
                    >
                      Subtotal:
                    </Typography>

                    <Typography
                      component="div"
                      variant="h5Alt"
                      className={classes.itemSubTotalDollar}
                    >
                      {formatCurrency(subtotal, { format: '%s%v', symbol: '$', minFraction: 2, maxFraction: 2 })}
                    </Typography>
                  </li>

                  <li
                    className={classes.itemizedListItem}
                  >
                    <Typography
                      component="div"
                      variant="h5Alt"
                      className={classes.itemSubTotal}
                    >
                      Sale:
                    </Typography>

                    <Typography
                      component="div"
                      variant="h5Alt"
                      className={classes.itemSubTotalDollar}
                    >
                      {`(${formatCurrency(sale, { format: '%s%v', symbol: '$', minFraction: 2, maxFraction: 2 })})`}
                    </Typography>
                  </li>
                </>
              )}

              {coupon && couponDiscount > 0 && (
                <li
                  className={classes.itemizedListItem}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      component="div"
                      variant="h5Alt"
                      className={classes.itemSubTotal}
                    >
                      Discounts:
                    </Typography>
                    <Typography
                      className={classes.couponDescription}
                      style={{
                        top: 1,
                        position: 'relative',
                        fontWeight: 'bold'
                      }}
                    >
                      {
                        coupon.description
                          ? coupon.description : (
                            <span>
                              {formatCurrency(couponDiscount, { format: '%s%v', symbol: '$' })}
                              {' '}
                              off your order
                            </span>
                          )
                      }
                    </Typography>
                  </div>

                  <Typography
                    component="div"
                    variant="h5Alt"
                    className={classes.itemSubTotalDollar}
                  >
                    {`(${formatCurrency(couponDiscount, { format: '%s%v', symbol: '$', minFraction: 2, maxFraction: 2 })})`}
                  </Typography>
                </li>
              )}

              <li
                className={classes.itemizedListItem}
                style={{
                  marginTop: theme.spacing(.5)
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Typography
                    component="div"
                    variant="h5Alt"
                    className={classes.itemTotalTitle}
                  >
                    Total paid:
                  </Typography>
                </Box>


                <Typography
                  component="div"
                  variant="h5Alt"
                  className={classes.itemDollarTotal}
                >
                  {formatCurrency(order.total, { format: '%s%v', symbol: '$', minFraction: 2, maxFraction: 2 })}
                </Typography>
              </li>
            </Protected>
          </div>
        )}

        {status !== 'COMPLETE' && (
          <>
            <li
              className={classes.itemizedListItem}
              style={{
                marginTop: theme.spacing(2)
              }}
            >
              <Protected allowedRoles={billingRoles}>
                <Typography
                  component="div"
                  variant="h5Alt"
                  className={classes.itemTotalTitle}
                >
                  Total due:
                </Typography>

                <Typography
                  component="div"
                  variant="h5Alt"
                  className={classes.itemDollarTotal}
                >
                  {formatCurrency(order.total, { format: '%s%v', symbol: '$', minFraction: 2, maxFraction: 2 })}
                </Typography>
              </Protected>
            </li>
          </>
        )}
      </ul>

      <Typography
        component="div"
        variant="body1"
        color="textSecondary"
        style={{
          fontSize: 10,
        }}
      >
        Reference
        {' '}
        <span data-test="order-doc-id">
          {docID}
        </span>
        {order.deal?.id && '/'}
        <span data-test="order-deal-id">
          {order.deal?.id}
        </span>
      </Typography>
    </div>
  );
};

StagingOrder.defaultProps = {
  billingRoles: ['*']
};

export default StagingOrder;
