import { functions } from '@app/firebase';
import CalendarLoading from '@components/calendar-loading/CalendarLoading';
import CalendarEventDialog from '@components/dialogs/CalendarEventDialog';
import { googleCalendarColors } from '@data';
import CalendarEventForm from '@forms/CalendarEvent';
import dayGridPlugin from '@fullcalendar/daygrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import useDocument from '@hooks/useDocument';
import useRouter from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import theme from '@ui/theme';
import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useMemo, useState } from 'react';

type CalendarEventFrontend = any;
type FetchCalendarRequest = { calendarId: string };
type FetchCalendarResponse = { events: CalendarEventFrontend[], timeZone: string };

const addCalendarEvent = httpsCallable(functions, 'http-addCalendarEvent');
const editCalendarEvent = httpsCallable(functions, 'http-editCalendarEvent');
const deleteCalendarEvent = httpsCallable(functions, 'http-deleteCalendarEvent');
const fetchCalendarEvents = httpsCallable<FetchCalendarRequest, FetchCalendarResponse>(functions, 'http-fetchCalendarEvents');

interface CalendarParams {
  id: string;
}

const CalendarPage = () => {
  const { md } = useBreakpoints();
  const router = useRouter<CalendarParams>();

  const [open, setOpen] = useState<boolean>(false);
  const [openEvent, setOpenEvent] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [error, setError] = useState<Error>(null);
  const [calendarError, setCalendarError] = useState<Error>(null);
  const [events, setEvents] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState(null);
  const [loadingCalendar, setLoadingCalendar] = useState<boolean>(true);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [initalFetchComplete, setInitialFetchComplete] = useState<boolean>(false);
  const [calendarTimeZone, setCalendarTimeZone] = useState<string>();
  const [checkedColors, setCheckedColors] = useState<number[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const { id } = router.params;

  const { doc: calendarDoc } = useDocument(`calendars/${id}`);

  const calendarData = useMemo(() => calendarDoc?.data() as guesthouse.Calendar, [calendarDoc]);

  const handleClickOpen = (clickType: string) => {
    if (clickType === 'event') {
      setOpenEvent(true);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEvent(false);
  };

  const dialogSuccess = () => {
    setRefetch(true);
    setOpen(false);

    if (error) {
      setError(null);
    }
  };

  useEffect(() => {
    if (calendarData && (!initalFetchComplete || refetch)) {
      fetchCalendarEvents({ calendarId: calendarData?.googleCalendarId })
        .then(({ data }) => {
          setEvents(data.events);
          setCalendarTimeZone(data.timeZone);
          setInitialFetchComplete(true);
          setRefetch(false);
          setLoadingCalendar(false);
        })
        .catch((e) => {
          setCalendarError(e);
          Sentry.captureException(e);
        });
    }
  }, [refetch, calendarData]);

  const updateEventState = (type: 'delete', eventId: string) => {
    const index = events.findIndex((event) => event.publicId === eventId.toString());

    if (type === 'delete') {
      if (index > -1 ) {
        events.splice(index, 1);
        setEvents(events);
      }
    }
  };

  if (calendarError) {
    throw new Error(calendarError.message);
  }

  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenFilterMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  if (loadingCalendar) {
    return (
      <CalendarLoading />
    );
  }

  return (
    <>
      <div
        style={{
          padding: md ? theme.spacing(3) : theme.spacing(0.5)
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          size="small"
          style={{
            marginBottom: md ? theme.spacing(3): theme.spacing(1.5),
            width: md ? 'auto' : '100%'
          }}
          onClick={handleOpenFilterMenu}
        >
          Filter Events
        </Button>

        <Menu
          id="filter-menu"
          anchorEl={anchorEl}
          open={openMenu}
          sx={
            { mt: '1px', '& .MuiMenu-paper': 
              { width: md ? 'auto' : '100%' }, 
            }}
          
          onClose={handleCloseFilterMenu}
        >
          <FormGroup  >
            {googleCalendarColors.map((color, i) => {
              if (!color.eventType) {
                return; 
              }

              return (
                <FormControlLabel
                  key={i}
                  style={{
                    textTransform: 'capitalize'
                  }}
                  label={color.eventType}
                  control={
                    <Checkbox
                      sx={{
                        color: color.hexColor,
                        '&.Mui-checked': {
                          color: color.hexColor,
                        },
                      }}
                      checked={checkedColors.includes(color.googleColorId)}
                      value={color}
                      onChange={(e) => {
                        const updatedColors = [...checkedColors];
    
                        if (e.target.checked) {
                          updatedColors.push(color.googleColorId);
    
                        } else {
                          const index = updatedColors.indexOf(color.googleColorId);
    
                          if (index > -1) { 
                            updatedColors.splice(index, 1);
                          }
                        }
    
                        setCheckedColors(updatedColors);
    
                        const updatedEvents = events.filter((event) => updatedColors.includes(Number(event.extendedProps.googleColorId)));
    
                        setFilteredEvents(updatedEvents);
                      }}
                    />
                  }
                />
              );
            }
            )}
          </FormGroup>
        </Menu>

        <FullCalendar
          plugins={[ dayGridPlugin, googleCalendarPlugin, interactionPlugin, timeGridPlugin ]}
          initialView={md ? 'timeGridWeek' : 'timeGridDay'}
          events={ checkedColors?.length ? { events: filteredEvents } : { events }}
          eventClick={(event) => {
            event.jsEvent.preventDefault();
            handleClickOpen('event');
            setSelectedEvent(event);
            setSelectedDate(new Date (event.event._def.extendedProps?.startTime));
          }}
          dateClick={(dateInfo) => {
            handleClickOpen('date');
            setSelectedDate(dateInfo.date);
          }}
          headerToolbar={{
            left: md ? 'prev,next' : '',
            center: 'title',
            right: md ? 'timeGridDay,timeGridWeek,dayGridMonth' : ''
          }}
          footerToolbar={{
            left: md ? '' : 'prev,next',
            right: md ? '' : 'timeGridDay,timeGridWeek,dayGridMonth'
          }}
          displayEventEnd={true}
          eventDisplay={'auto'}
          handleWindowResize={true}
          contentHeight={md ? '70vh' : '62.5vh'}
          showNonCurrentDates={false}
        />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <div
          style={{
            padding: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            style={{
              marginBottom: theme.spacing(1.5)
            }}
          >
            {selectedDate?.toDateString()}
          </Typography>
          <CalendarEventForm
            selected_date={selectedDate}
            initialValues={{}}
            error={error}
            onSubmit={async (values) => {
              const formattedValues = {
                ...values,
                calendarId: calendarData?.googleCalendarId,
                timeZone: calendarTimeZone,
              };

              try {
                await addCalendarEvent(formattedValues);
                dialogSuccess();
              } catch (e) {
                setError(e.message);
                if (e) {
                  Sentry.captureException(e);
                }
              }
            }}
          />
        </div>
      </Dialog>


      <Dialog
        open={openEvent}
        onClose={handleClose}
      >
        <CalendarEventDialog
          calendarId={calendarData?.googleCalendarId}
          event={selectedEvent}
          selectedDate={selectedDate}
          calendarTimeZone={calendarTimeZone}
          deleteCalendarEvent={deleteCalendarEvent}
          editCalendarEvent={editCalendarEvent}
          closeHandler={handleClose}
          setRefetch={setRefetch}
          updateEventState={updateEventState}
        />
      </Dialog>
    
    </>

  );
};

export default React.memo(CalendarPage);
