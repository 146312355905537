import * as Yup from 'yup';

export type PromoCodeBatchValues = {
  created?: guesthouse.Coupon['created'];
  updated?: guesthouse.Coupon['updated'];
  docID?:  string;
  description?: string;
  code?: string;
  expires?: Date;
  max_uses_type?: guesthouse.Coupon['max_uses_type']
  max_uses?: number;
  min_order_amount?: guesthouse.Coupon['min_order_amount'];
  retail_discount_amount?: number;
  retail_discount_type?: guesthouse.Coupon['retail_discount_type'];
  staging_discount_amount?: number;
  staging_discount_type?: guesthouse.Coupon['staging_discount_type'];
  order_application?: guesthouse.Coupon['order_application'];
  products_included?: guesthouse.Product[];
  products_excluded?: guesthouse.Product[];
  number_of_codes?: number;
};

const PromoCodeBatchSchema = Yup.object().shape({
  number_of_codes: Yup.number()
    .positive()
    .required('Please enter the number of codes to create'),
  description: Yup.string(),
  min_order_amount: Yup.number(),
  retail_discount_amount: Yup.number(),
  retail_discount_type: Yup.string(),
  staging_discount_amount: Yup.number(),
  staging_discount_type: Yup.string(),
  order_application: Yup.string()
    .required('Please select an order application type'),
  expires: Yup.date(),
  max_uses_type: Yup.string(),
  max_uses: Yup.number()
});

export const initialValues: PromoCodeBatchValues = {
  min_order_amount: 0,
  retail_discount_amount: 0,
  retail_discount_type: 'fixed',
  staging_discount_amount: 0,
  staging_discount_type: 'fixed',
  order_application: 'no_shipping',
  max_uses_type: 'global'
};


export default PromoCodeBatchSchema;
