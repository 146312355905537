import { database } from '@app/firebase';
import UserContext from '@context/UserContext';
import ProductMakerForm from '@forms/ProductMaker';
import useRouter from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { initialValues, ProductMakerFormValues } from '@schema/ProductSchemaMaker';
import useCommonStyles from '@styles/common.style';
import newId from '@utils/newId';
import { productPath } from '@utils/paths';
import { collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import React, { useContext, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ProductCreate = () => {
  const { classes: common } = useCommonStyles();
  const router = useRouter();
  const [error, setError] = useState<string | boolean>();
  const userContext = useContext<UserContext>(UserContext);

  const uid = useRef(newId());
  const location = useLocation();
  const duplicateProduct = location.state as guesthouse.Product;

  if (duplicateProduct) {
    delete duplicateProduct.docID;
    delete duplicateProduct.currentHomes;
    delete duplicateProduct.futureHomes;
    delete duplicateProduct.pastHomes;
    delete duplicateProduct.stagedHomes;
    delete duplicateProduct.availability;
    delete duplicateProduct.offers;
    duplicateProduct.featured = false;
    duplicateProduct.inStock = false;
  }

  return (
    <div className={common.contentSpacing}>
      <Typography
        component="h1"
        variant="h3Alt"
      >
        Add Product
      </Typography>

      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <ProductMakerForm
            submitText="Submit"
            initialValues={duplicateProduct ? { ...duplicateProduct } :
              {
                ...initialValues,
                owner: userContext.roles.maker ? userContext.data : undefined,
              }}
            error={error}
            onSubmit={async (values: ProductMakerFormValues) => {
              setError(false);
              let inferredOwner: guesthouse.User = null;

              if (userContext?.roles?.maker) {
                inferredOwner = userContext.data;
              }

              const product: guesthouse.Product = {
                archived: false,
                published: false,
                docID: uid.current,
                created: Timestamp.fromDate(new Date()),
                // @ts-ignore
                owner: (values.owner || inferredOwner) as guesthouse.User,
                inventory: 0,
                inventoryInternal: 0,
                inventoryTotal: 0,
                available: 0,
                availableInternal: 0,
                availableTotal: 0,
                dibs: false,
                offers: [],
                lowestOfferPrice: undefined,
                weight: undefined,
                featured: undefined,
                stagedHomes: [],
                currentHomes: [],
                futureHomes: [],
                pastHomes: [],
                availability: [],
                availablilityStart: undefined,
                availablilityEnd: undefined,
                _geoloc: [],
                ...values,
              };
              
              return await setDoc(doc(collection(database, 'products'), product.docID), product)
                .then(() => router.push(productPath(product)))
                .catch((e) => {
                  Sentry.captureException(e);
                  setError(e.message);
                });
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
        >
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(ProductCreate);
