
import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  document: {
    display: 'flex',
    width: '100%', 
    overflowX: 'auto', 
    WebkitOverflowScrolling: 'touch',
  },
  page: {
    marginRight: theme.spacing(1),
    flex: 1,
    border: '1px solid #C4C4C4',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    cursor: 'pointer',
  },
}));
