import ItemCount from '@components/item-count/ItemCount';
import { LinkBehavior } from '@components/link-behavior/LinkBehavior';
import ProductGrid from '@components/product-grid/ProductGrid';
import ProductGridSkeleton from '@components/product-grid/ProductGrid.skeleton';
import AppContext from '@context/AppContext';
import UserContext from '@context/UserContext';
import { CATEGORIES, COLORS, STYLES } from '@data';
import { ScrollElement } from '@hooks/useInfiniteScroll';
import useProductFilterSearch from '@hooks/useProductFilterSearch';
import IconPlus from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StopIcon from '@mui/icons-material/Stop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import routes from '@routes';
import useCommonStyles from '@styles/common.style';
import Protected from '@ui/components/protected/Protected';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import checkRoles from '@ui/utils/checkRoles';
import { addFilter, initPagedResults, removeFilter, removeSubcategoryFilters } from '@utils/algoliaSearch';
import { productPath } from '@utils/paths';
import properCase from '@utils/properCase';
import startOfDay from 'date-fns/startOfDay';
import { isEqual } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

import IconFilters from '../../icons/iconFilters';
import useStyles from './Products.style';

const Products = () => {
  const { classes: common } = useCommonStyles();
  const { md } = useBreakpoints();
  const { classes, cx, theme } = useStyles();

  const appContext = useContext<AppContext>(AppContext);
  const userContext = useContext<UserContext>(UserContext);

  const [categoryMap, setCategoryMap] = useState<Record<string, number>>({});
  const [openFilters, setOpenFilters] = useState(false);
  const [dateNeededFilter, setDateNeededFilter] = useState<boolean>(false);

  const {
    startDate,
    setStartDate,
    setAvailableTotal,
    published,
    setPublished,
    inventoryOpen,
    setInventoryOpen,
    inventoryInternal,
    setInventoryInternal,
    inventoryExternal,
    setInventoryExternal,
    categoriesOpen,
    setCategoriesOpen,
    setPage,
    products,
    setProducts,
    filters,
    clearFilters,
    stylesOpen,
    setStylesOpen,
    colorsOpen,
    setColorsOpen,
    appliedFilters,
    numResults,
    startDateObject,
    endOfContainerElement,
    dibs,
    setDibs,
    dropshippable,
    setDropshippable
  } = useProductFilterSearch(userContext, appContext);

  useEffect(() => {
    if (Object.keys(filters?.category2?.counts || {})?.length) {
      const newMap = {
        ...filters?.category2?.counts,
        ...categoryMap,
      };

      if (!isEqual(newMap, categoryMap)) {
        setCategoryMap(() => newMap);
      }
    }
  }, [filters?.category2?.counts]);

  useEffect(() => {
    if (startDate) {
      setDateNeededFilter(true);
    } else {
      setDateNeededFilter(false);
    }
  }, [startDate]);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', height: '100%' }}
    >
      <div
        className={cx({
          [classes.filtersContainer]: true,
          [classes.filtersContainerOpen]: openFilters && !md,
        })}
      >
        {!md ?
          <div className={classes.filtersContainerHeader}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                style={{ color: theme.palette.common.black }}
                size="large"
                onClick={() => setOpenFilters(false)}
              >
                <CloseIcon style={{ height: 30, width: 30 }} />
              </IconButton>
              <Typography
                fontFamily={theme.gh_vars.circular}
                style={{ fontSize: 24, display: 'inline-block' }}
              >
                Filter results
              </Typography>
            </div>
            <ItemCount numResults={numResults}/>
          </div>
          : null
        }
        <div className={classes.filtersContainerInner}>
          <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: md ? theme.spacing(0.5, 3) : theme.spacing(0.5, 2), marginBottom: theme.spacing(2) }}
            >
              <Typography
                className={classes.filterText}
              >
                Date needed
              </Typography>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {startDate ?
                  <IconButton
                    style={{ color: theme.palette.common.black, padding: 0, marginRight: md ? theme.spacing(1) : theme.spacing(1) }}
                    size="large"
                    onClick={() => {
                      setStartDate(null);
                      setAvailableTotal(undefined);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  : null}

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disablePast
                    value={startDateObject || null}
                    slotProps={{
                      inputAdornment: { position: 'start' },
                      textField: {
                        style:{
                          backgroundColor: '#fff',
                          width: md ? 160 : 130,
                          borderRadius: theme.shape.borderRadius,
                          margin: 0
                        },
                        variant: 'outlined',
                        margin: 'dense',
                        helperText: '',
                        placeholder: '__/__/____',
                        size: 'small',
                        inputProps: {
                          ['data-test']:'install_date'
                        }
                      }
                    }}
                    onChange={(date) => {
                      if (date) {
                        setAvailableTotal('1');
                        setStartDate(String(startOfDay(date).getTime() / 1000));
                      } else {
                        setAvailableTotal(undefined);
                        setStartDate(null);
                      }
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: md ? theme.spacing(0.5, 3) : theme.spacing(0.5, 2) }}
            >
              <Tooltip title={published ? 'Show all products' : 'Only show published products'}>
                <Typography style={{ fontFamily: theme.gh_vars.circular, fontSize: 14, fontWeight: 600 }}>
                  Published
                </Typography>
              </Tooltip>
              <Switch
                className={classes.filterSwitch}
                checked={Boolean(Number(published))}
                value={Boolean(Number(published))}
                name="verified"
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.checked) {
                    setPublished('1');
                  } else {
                    setPublished('');
                  }
                }}
              />
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: md ? theme.spacing(0.5, 3) : theme.spacing(0.5, 2) }}
            >
              <Tooltip title={dibs ? 'Show all products' : 'Only show dibs products'}>
                <Typography style={{ fontFamily: theme.gh_vars.circular, fontSize: 14, fontWeight: 600 }}>
                  Dibs
                </Typography>
              </Tooltip>
              <Switch
                className={classes.filterSwitch}
                checked={Boolean(Number(dibs))}
                value={Boolean(Number(dibs))}
                name="verified"
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.checked) {
                    setDibs('1');
                  } else {
                    setDibs('');
                  }
                }}
              />
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: md ? theme.spacing(0.5, 3) : theme.spacing(0.5, 2) }}
            >
              <Tooltip title={dibs ? 'Show all products' : 'Only show products available for dropship'}>
                <Typography style={{ fontFamily: theme.gh_vars.circular, fontSize: 14, fontWeight: 600 }}>
                  Dropship
                </Typography>
              </Tooltip>
              <Switch
                className={classes.filterSwitch}
                checked={Boolean(Number(dropshippable))}
                value={Boolean(Number(dropshippable))}
                name="verified"
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.checked) {
                    setDropshippable('1');
                  } else {
                    setDropshippable('');
                  }
                }}
              />
            </Box>
          </Protected>

          <Protected allowedRoles={['admin', 'design_manager', 'designer', 'website_manager', 'customer_support']}>
            <ButtonBase
              className={classes.filterButtonBase}
              onClick={() => setInventoryOpen(!inventoryOpen)}
            >
              Inventory
              <KeyboardArrowDownIcon
                className={cx({
                  [classes.chevronIcon]: true,
                  [classes.chevronIconFlipped]: inventoryOpen
                })}
              />
            </ButtonBase>

            <Collapse in={inventoryOpen}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={classes.subfilterButtonBase}
              >
                <Typography className={classes.subFilterText}>
                  Guest House
                </Typography>
                <Switch
                  className={classes.filterSwitch}
                  checked={!!inventoryInternal}
                  value={!!inventoryInternal}
                  name="verified"
                  color="primary"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      setInventoryInternal('1');
                    } else {
                      setInventoryInternal(undefined);
                    }
                  }}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={classes.subfilterButtonBase}
              >
                <Typography className={classes.subFilterText}>
                  Maker
                </Typography>
                <Switch
                  className={classes.filterSwitch}
                  checked={!!inventoryExternal}
                  value={!!inventoryExternal}
                  name="verified"
                  color="primary"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      setInventoryExternal('1');
                    } else {
                      setInventoryExternal(undefined);
                    }
                  }}
                />
              </Box>
            </Collapse>
          </Protected>

          <ButtonBase
            className={classes.filterButtonBase}
            onClick={() => setCategoriesOpen(!categoriesOpen)}
          >
            Categories
            <KeyboardArrowDownIcon
              className={cx({
                [classes.chevronIcon]: true,
                [classes.chevronIconFlipped]: categoriesOpen
              })}
            />
          </ButtonBase>

          <Collapse in={categoriesOpen}>
            {CATEGORIES.map((category, i) => {
              return (
                <div key={i}>
                  <ButtonBase
                    className={classes.subfilterButtonBase}
                    onClick={() => {
                      if (filters.category.values?.includes(category.id)) {
                        removeFilter(filters.category.setter, filters.category.values, category.id);
                        removeSubcategoryFilters(filters.category, filters.category2, category.id);
                      } else {
                        setPage(0);
                        setProducts(initPagedResults());

                        addFilter(filters.category.setter, filters.category.values, category.id);
                      }
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Typography className={classes.subFilterText}>
                        {category.title}
                      </Typography>

                      <Switch
                        className={classes.filterSwitch}
                        checked={!!filters.category.values?.includes(category.id)}
                        value={!!filters.category.values?.includes(category.id)}
                        name="verified"
                        color="primary"
                      />
                    </Box>
                  </ButtonBase>


                  <Collapse in={filters.category.values?.includes(category.id)}>
                    <div className={classes.subcategoryCollapseWrapper}>

                      {filters.category.values?.includes(category.id) && category.subcategories?.map((subcategory, i) => {
                        return (
                          <FormControlLabel
                            key={i}
                            style={{ width: '100%', marginRight: 0 }}
                            classes={{
                              root: classes.formControlRoot,
                              label: classes.formControlLabel,
                            }}
                            control={
                              <Checkbox
                                checked={!!filters.category2.values?.includes(subcategory.id)}
                                checkedIcon={
                                  <StopIcon
                                    style={{
                                      color: theme.palette.tertiary.main,
                                      backgroundColor: theme.palette.tertiary.main,
                                      transform: 'scale(0.75)',
                                      borderRadius: 4
                                    }}
                                  />
                                }
                                style={{
                                  color: '#83a5b0',
                                }}
                                onChange={() => {
                                  if (filters.category2.values?.includes(subcategory.id)) {
                                    removeFilter(filters.category2.setter, filters.category2.values, subcategory.id);
                                  } else {
                                    setPage(0);
                                    setProducts(initPagedResults());

                                    addFilter(filters.category2.setter, filters.category2.values, subcategory.id);
                                  }
                                }}
                              />
                            }
                            label={
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                <Typography
                                  className={classes.subcategoryText}
                                  style={{ width: '100%', flex: 1, }}
                                >
                                  {properCase(subcategory.id, '-', true)}
                                </Typography>
                                <Typography
                                  className={classes.subcategoryText}
                                  style={{ position: 'relative', right: -11, width: 'auto' }}
                                >
                                  {' '}
                                  (
                                  {filters.category2.counts && filters.category2?.counts[subcategory.id] ? filters.category2?.counts[subcategory.id] : categoryMap[subcategory.id] ?? 0}
                                  )
                                  {' '}
                                </Typography>
                              </Box>
                            }
                          />
                        );
                      })}
                    </div>
                  </Collapse>
                </div>
              );
            })}
          </Collapse>

          <ButtonBase
            className={classes.filterButtonBase}
            onClick={() => setStylesOpen(!stylesOpen)}
          >
            Style
            <KeyboardArrowDownIcon
              className={cx({
                [classes.chevronIcon]: true,
                [classes.chevronIconFlipped]: stylesOpen
              })}
            />
          </ButtonBase>

          <Collapse in={stylesOpen}>
            {STYLES.map((style, i) => {
              return (
                <Box
                  key={i}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  className={classes.subfilterButtonBase}
                >
                  <Typography className={classes.subFilterText}>
                    {style.title}
                  </Typography>
                  <Switch
                    className={classes.filterSwitch}
                    checked={!!filters.style.values?.includes(style.id)}
                    value={!!filters.style.values?.includes(style.id)}
                    name="verified"
                    color="primary"
                    onChange={() => {
                      if (filters.style.values?.includes(style.id)) {
                        removeFilter(filters.style.setter, filters.style.values, style.id);
                      } else {
                        setPage(0);
                        setProducts(initPagedResults());

                        addFilter(filters.style.setter, filters.style.values, style.id);
                      }
                    }}
                  />
                </Box>
              );
            })}
          </Collapse>

          <ButtonBase
            className={classes.filterButtonBase}
            onClick={() => setColorsOpen(!colorsOpen)}
          >
            Color
            <KeyboardArrowDownIcon
              className={cx({
                [classes.chevronIcon]: true,
                [classes.chevronIconFlipped]: colorsOpen
              })}
            />
          </ButtonBase>

          <Collapse in={colorsOpen}>
            {COLORS.map((color, i) => {
              return (
                <Box
                  key={i}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  className={classes.subfilterButtonBase}
                >
                  <Typography className={classes.subFilterText}>
                    {color.title}
                  </Typography>
                  <Switch
                    className={classes.filterSwitch}
                    checked={!!filters.color.values?.includes(color.id)}
                    value={!!filters.color.values?.includes(color.id)}
                    name="verified"
                    color="primary"
                    onChange={() => {
                      if (filters.color.values?.includes(color.id)) {
                        removeFilter(filters.color.setter, filters.color.values, color.id);
                      } else {
                        setPage(0);
                        setProducts(initPagedResults());

                        addFilter(filters.color.setter, filters.color.values, color.id);
                      }
                    }}
                  />
                </Box>
              );
            })}
          </Collapse>
        </div>

        {!md && openFilters ?
          <div className={classes.filterContainerButtons}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              style={{ width: '100%', marginBottom: theme.spacing(2), border: '2px solid' }}
              onClick={() => {
                clearFilters();
              }}
            >
              <Typography
                fontFamily={theme.gh_vars.circular}
                style={{ fontSize: 14, lineHeight: 2 }}
              >
                Clear filters
              </Typography>
            </Button>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              style={{ width: '100%', marginBottom: theme.spacing(6), border: '2px solid' }}
              onClick={() => setOpenFilters(false)}
            >
              <Typography
                fontFamily={theme.gh_vars.circular}
                style={{ fontSize: 14, lineHeight: 2 }}
              >
                Apply filters
              </Typography>
            </Button>
          </div>
          : null
        }
      </div>

      <div
        style={{ width: '100%' }}
        className={cx([common.contentSpacing, classes.filtersResults])}
      >
        <Grid container>

          <Grid
            item
            xs={12}
            style={{ marginBottom: md ? theme.spacing(3) : theme.spacing(1) }}
          >
            <Box
              display="flex"
              alignItems={md ? 'flex-end' : 'flex-start'}
              justifyContent={md ? 'flex-start' : 'space-between'}
              flexDirection="row"
            >
              <Typography
                component="h1"
                variant="h3Alt"
                style={{ marginRight: theme.spacing(2) }}
              >
                {checkRoles(['maker'], userContext.roles) ? 'Your Products' : 'Products'}
              </Typography>

              <Protected allowedRoles={['maker', 'designer', 'admin', 'design_manager', 'website_manager', 'customer_support']}>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  component={LinkBehavior}
                  href={checkRoles(['maker'], userContext.roles) ? routes.productCreateMaker.path : routes.productCreate.path}
                  startIcon={<IconPlus />}
                  aria-label="add product"
                  data-test="add-product"
                >
                  Add product
                </Button>
              </Protected>

              {
                md ?
                  <ItemCount numResults={numResults}/>
                  : null
              }
            </Box>
          </Grid>

          {!md ?
            <Box
              display={md ? 'flex' : 'grid'}
              gap={theme.spacing(1)}
              gridTemplateColumns="1fr 1fr"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(3) }}
            >
              <Button
                size="small"
                variant="outlined"
                style={{ width: '100%' }}
                onClick={() => setOpenFilters(true)}
              >
                <Grid
                  container
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography
                      fontFamily={theme.gh_vars.circular}
                      style={{ fontSize: 14 }}
                    >
                      Filter
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{ marginTop: theme.spacing(0.5) }}
                  >
                    {appliedFilters ?
                      <div className={classes.count}>
                        {appliedFilters}
                      </div>
                      : <IconFilters />
                    }
                  </Grid>
                </Grid>
              </Button>

            </Box>
            : null
          }

          {!md ?
            <ItemCount numResults={numResults} />
            : null}

        </Grid>

        {
          products.hasOwnProperty('0') &&
            products[0].status === 'complete' &&
            products[0].results.length === 0
            ? (
              <Typography>
                {checkRoles(['maker'], userContext.roles) ? 'You haven\'t added any products yet.' : 'No products found'}
              </Typography>
            ) : Object.keys(products).map(n => {
              const page = products[n];

              if (appContext.skeleton || page.status === 'loading') {
                return (
                  <ProductGridSkeleton
                    key={n}
                    numItems={24}
                    spacing={2}
                  />
                );
              } else if (page.status === 'complete' && page.results?.length) {
                return (
                  <ProductGrid
                    key={n}
                    href={productPath}
                    products={page.results}
                    showMaker={!checkRoles(['maker'], userContext.roles)}
                    showLocation={!checkRoles(['maker'], userContext.roles)}
                    spacing={2}
                    stagingDate={startDateObject}
                    dateNeededFilter={dateNeededFilter}
                  />
                );
              } else {
                return null;
              }
            })
        }

        <ScrollElement ref={endOfContainerElement} />
      </div>
    </div>
  );
};

export default React.memo(Products);
