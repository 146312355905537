import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import PromoCodeDialog from '@components/dialogs/PromoCodeDialog';
import Error from '@components/error/Error';
import { RouterLink } from '@components/link-behavior/LinkBehavior';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import Sentry from '@integrations/Sentry';
import IconPlus from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridValueFormatterParams } from '@mui/x-data-grid';
import { PromoCodeBatchValues } from '@schema/PromoCodeBatchSchema';
import { PromoCodeValues } from '@schema/PromoCodeSchema';
import useCommonStyles from '@styles/common.style';
import chunkArray from '@ui/utils/chunkArray';
import formatDate from '@ui/utils/formatDate';
import formatDiscount from '@ui/utils/formatDiscount';
import { useStyles } from '@ui/utils/makeStyles';
import { generatePromoCode } from '@utils/generatePromoCode';
import newId from '@utils/newId';
import { collection, doc, DocumentSnapshot, orderBy, query, setDoc, writeBatch } from 'firebase/firestore';
import React, { useContext, useMemo, useRef, useState } from 'react';

import PromoActionsBatch from './PromoActionsBatch';
import PromoCodeActions from './PromoCodeActions';
import Skeleton from './Promos.skeleton';

interface PromoCodeRow {
  id: string;
  created: guesthouse.Coupon['created'];
  description: guesthouse.Coupon['description'];
  code: guesthouse.Coupon['code'];
  order_application: guesthouse.Coupon['order_application'];
  expires: guesthouse.Coupon['expires'];
  max_uses: guesthouse.Coupon['max_uses'];
  retail_discount: string;
  staging_discount: string;
}

const columns: GridColDef[] = [
  {
    flex: 1,
    field: 'code',
    headerName: 'Code',
    renderCell: (params) => {
      return (
        <Link
          component={RouterLink}
          to={`/promos/${params.value}`}
        >
          {params.value}
        </Link>
      );
    }
  },
  {
    flex: 1,
    field: 'description',
    headerName: 'Description',
  },
  {
    flex: 1,
    field: 'staging_discount',
    headerName: 'Staging Discount',
  },
  {
    flex: 1,
    field: 'retail_discount',
    headerName: 'Retail Discount',
  },
  {
    flex: 1,
    field: 'order_application',
    headerName: 'Order Application',
  },
  {
    flex: 1,
    field: 'max_uses',
    headerName: 'Maximum Uses',
  },
  {
    flex: 1,
    field: 'expires',
    headerName: 'Expiration Date',
    type: 'date',
    filterable: false,
    valueFormatter: (params: GridValueFormatterParams) => {
      // @ts-ignore
      return formatDate(params.value);
    }
  },
  {
    flex: 1,
    field: 'created',
    headerName: 'Created Date',
    type: 'date',
    filterable: false,
    valueFormatter: (params: GridValueFormatterParams) => {
      // @ts-ignore
      return formatDate(params.value);
    }
  },
  {
    width: 100,
    field: 'id',
    headerName: 'Actions',
    filterable: false,
    sortable: false,
    hideSortIcons: true,
    resizable: false,
    disableExport: true,
    renderCell: (params) => {
      return (
        <PromoCodeActions
          docID={String(params.value)}
        />
      );
    }
  },
];

const Promos: React.FC = () => {
  const { classes: common } = useCommonStyles();
  const { theme } = useStyles();
  const userContext = useContext<UserContext>(UserContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const [selection, setSelection] = useState([]);


  const { collection: promotions, loading, error } = useCollection(
    query(collection(database, 'coupons')
      ,orderBy('created', 'desc'))
  );

  const selectionDocs = useMemo(() => {
    if (selection.length && promotions?.docs?.length) {
      const selectionObject = selection.reduce((checkObj, promoDocID) => (checkObj[promoDocID] = true, checkObj), {});
      const selectedDocs = promotions.docs?.filter(promo => selectionObject[promo.id]);

      return selectedDocs as DocumentSnapshot<guesthouse.Coupon>[];
    }

    return [];
  }, [selection, promotions?.docs]);

  const promoCodeRows: PromoCodeRow[] = useMemo(() => {
    if (promotions?.docs) {
      return promotions.docs
        .map(doc => {
          const promoCodeData = doc.data() as guesthouse.Coupon;

          return {
            id: doc.id,
            created: promoCodeData.created,
            staging_discount: formatDiscount(promoCodeData.staging_discount_amount, promoCodeData.staging_discount_type),
            retail_discount: formatDiscount(promoCodeData.retail_discount_amount, promoCodeData.retail_discount_type),
            description: promoCodeData.description,
            code: promoCodeData.code,
            max_uses: promoCodeData.max_uses,
            expires: promoCodeData.expires,
            order_application: promoCodeData.order_application,
          };
        });
    }

    return [];
  }, [promotions?.docs]);

  if (error) {
    return (
      <div className={common.contentSpacing}>
        <Error />
      </div>
    );
  }

  if (loading) {
    return (
      <div className={common.contentSpacing}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div className={common.contentSpacing}>
      <Grid
        container
        style={{ marginBottom: theme.spacing(2) }}
      >
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(1) }}
        >
          <Box
            display="flex"
            alignItems="center"
            height="100%"
          >
            <Typography
              component="h1"
              variant="h3Alt"
              style={{ marginRight: theme.spacing(2) }}
            >
              Promotions
            </Typography>

            <Button
              size="small"
              variant="contained"
              color="secondary"
              aria-label="add user"
              data-test="add-user"
              startIcon={<IconPlus />}
              onClick={() => {
                asyncRender(PromoCodeDialog, {
                  userContext,
                  onSubmit: async (values: PromoCodeValues) => {
                    const docID = newId();

                    if (!values.max_uses || values.max_uses <= 0) {
                      values.max_uses = Infinity;
                    }

                    if (values.users_included) {
                      for (const user of values.users_included) {
                        await setDoc(
                          doc(
                            collection(doc(collection(database, 'coupons'), docID), 'usersIncluded'),
                            user.docID
                          ),
                          user
                        );
                      }
                      delete values.users_included;
                    }

                    if (values.users_excluded) {
                      for (const user of values.users_excluded) {
                        await setDoc(
                          doc(
                            collection(doc(collection(database, 'coupons'), docID), 'usersExcluded'),
                            user.docID
                          ),
                          user
                        );
                      }
                      delete values.users_excluded;
                    }

                    if (values.products_included) {
                      for (const product of values.products_included) {
                        if (!product.docID) {
                          product.docID = newId();
                        }
                        await setDoc(
                          doc(
                            collection(doc(collection(database, 'coupons'), docID), 'productsIncluded'),
                            product.docID
                          ),
                          product
                        );
                      }
                      delete values.products_included;
                    }

                    if (values.products_excluded) {
                      for (const product of values.products_excluded) {
                        if (!product.docID) {
                          product.docID = newId();
                        }
                        await setDoc(
                          doc(
                            collection(doc(collection(database, 'coupons'), docID), 'productsExcluded'),
                            product.docID
                          ),
                          product
                        );
                      }
                      delete values.products_excluded;
                    }

                    if (values.retail_discount_type === 'percent') {
                      values.retail_discount_amount /= 100;
                    }

                    if (values.staging_discount_type === 'percent') {
                      values.staging_discount_amount /= 100;
                    }

                    values = {
                      ...values,
                      docID: docID,
                      code: values.code,
                      updated: new Date(),
                      created: new Date()
                    };

                    try {
                      return setDoc(
                        doc(collection(database, 'coupons'), docID),
                        values
                      );
                    } catch (e) {
                      Sentry.captureException(e);
                      notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                    }
                  }
                });
              }}
            >
              Add Promotion
            </Button>

            <Button
              ref={anchorRef}
              size="small"
              variant="outlined"
              color="primary"
              style={{ marginLeft: theme.spacing(1), minWidth: 44 }}
              aria-controls={open ? 'batch-create-coupons-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="batch create coupon"
              aria-haspopup="menu"
              onClick={() => setOpen(true)}
            >
              <ArrowDropDownIcon />
            </Button>

            <Popper
              transition
              disablePortal
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              style={{ zIndex: 10 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                      <MenuList >
                        <MenuItem

                          aria-label="batch create coupons"
                          onClick={() => {
                            asyncRender(PromoCodeDialog, {
                              userContext,
                              batch: 'create',
                              title: 'Batch Create Promotions',
                              onSubmit: async (values: PromoCodeBatchValues) => {
                                const promoCodeIDs = [];

                                for (let count = 0; count < values.number_of_codes; count++) {
                                  const id = await generatePromoCode();

                                  promoCodeIDs.push(id);
                                }

                                if (values.retail_discount_type === 'percent') {
                                  values.retail_discount_amount /= 100;
                                }

                                if (values.staging_discount_type === 'percent') {
                                  values.staging_discount_amount /= 100;
                                }

                                if (values.number_of_codes) {
                                  delete values.number_of_codes;
                                }

                                if (promoCodeIDs) {
                                  const chunks = chunkArray(promoCodeIDs);

                                  for (const chunk of chunks) {
                                    const batch = writeBatch(database);

                                    for (const id of chunk) {
                                      const couponDocID = newId();
                                      const couponRef = doc(collection(database, 'coupons'), couponDocID);

                                      if (!values.max_uses || values.max_uses <= 0) {
                                        values.max_uses = Infinity;
                                      }

                                      if (values.products_included) {
                                        for (const product of values.products_included) {
                                          if (!product.docID) {
                                            product.docID = newId();
                                          }
                                          const docRef = doc(collection(doc(collection(database, 'coupons'), couponDocID), 'productsIncluded'), product.docID);

                                          batch.set(docRef, product);
                                        }
                                      }

                                      if (values.products_excluded) {
                                        for (const product of values.products_excluded) {
                                          if (!product.docID) {
                                            product.docID = newId();
                                          }
                                          const docRef = doc(collection(doc(collection(database, 'coupons'), couponDocID), 'productsExcluded'), product.docID);


                                          batch.set(docRef, product);
                                        }
                                      }

                                      const updatedValues = {
                                        ...values,
                                        code: id,
                                        docID: couponDocID,
                                        updated: new Date(),
                                        created: new Date(),
                                      };

                                      delete updatedValues.products_included;
                                      delete updatedValues.products_excluded;

                                      batch.set(couponRef, updatedValues);
                                    }

                                    try {
                                      await batch.commit();
                                    } catch (e) {
                                      Sentry.captureException(e.message);
                                    }
                                  }
                                }
                              }
                            });
                          }}
                        >
                          Create coupon batch
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Grid>

      </Grid>

      <div className={common.overflowSection}>
        <DataGrid
          disableSelectionOnClick
          hideFooterSelectedRowCount
          checkboxSelection
          autoHeight
          selectionModel={selection}
          rows={promoCodeRows}
          columns={columns}
          components={{
            Toolbar: function CustomToolbar() {
              return (
                <GridToolbarContainer style={{ justifyContent: 'space-between', marginRight: theme.spacing(1) }}>
                  <div>
                    {selection.length > 0 && (
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography
                          style={{
                            marginRight: theme.spacing(1),
                            marginLeft: theme.spacing(1),
                            fontSize: 14,
                            fontFamily: theme.gh_vars.circular
                          }}
                        >
                          {selection.length}
                          {' '}
                          selected:
                        </Typography>
                        <PromoActionsBatch
                          promoDocs={selectionDocs}
                        />
                      </Box>
                    )}
                  </div>
                  <GridToolbarExport
                    style={{ color: theme.palette.common.black }}
                    csvOptions={{
                      fields: [
                        'code',
                        'description',
                        'staging_discount',
                        'retail_discount',
                        'order_application',
                        'max_uses',
                        'expires',
                        'created',
                      ]
                    }}

                  />
                </GridToolbarContainer>
              );
            }
          }}
          onSelectionModelChange={(selected) => setSelection(selected)}
        />
      </div>
    </div>
  );
};

export default React.memo(Promos);
