import { ParsedUrlQuery } from 'querystring';

const makeFacetFilters = (obj: ParsedUrlQuery): ReadonlyArray<readonly string[]> => {
  const facetFilters = Object.keys(obj)
    .filter((k) => Array.isArray(obj[k]) ? obj[k].length : obj[k])
    .map((k) => {
      const v = obj[k];

      if (Array.isArray(v)) {
        return v.map(i => `${k}:${i}`);
      } else {
        return [`${k}:${v}`];
      }
    });

  return facetFilters;
};

export default makeFacetFilters;
