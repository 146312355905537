import ShippingForm from '@forms/Shipping';
import Sentry from '@integrations/Sentry';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';


type ShippingDialogArgs = {
  title?: string;
  saveText?: string;
  cancelText?: string;
  initialValues?: object;
  onSubmit: (values: guesthouse.OrderProduct) => Promise<unknown>;
  resolve: (values: unknown) => void;
  reject: (e?: Error | boolean) => void;
}

const ShippingDialog: React.FC<ShippingDialogArgs> = ({
  resolve,
  reject,
  title = 'Add Tracking Information',
  initialValues,
  onSubmit,
  saveText = 'Save',
  cancelText = 'Cancel',
}) => {
  const [open, setOpen] = useState(true);
  const [error, setError] = useState(null);

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        <ShippingForm
          error={error}
          submitText={saveText}
          initialValues={initialValues}
          onSubmit={(values) => {
            return onSubmit(values)
              .then((values) => {
                setOpen(false);
                return values;
              })
              .then(resolve)
              .catch(e => {
                setError(e.message);
                Sentry.captureException(e);
              });
          }}
        />
      </DialogContent>

      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          size="small"
          onClick={() => {
            setOpen(false);
            reject(false);
          }}
        >
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShippingDialog;
