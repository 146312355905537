import { database } from '@app/firebase';
import CalendarEventForm from '@forms/CalendarEvent';
import { EventClickArg } from '@fullcalendar/core';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import IconDelete from '@ui/icons/imaterial/base/Delete Forever.svg';
import IconEdit from '@ui/icons/imaterial/base/Pen 01.svg';
import IconPin from '@ui/icons/imaterial/base/Pin 01.svg';
import IconUser from '@ui/icons/imaterial/communication/User.svg';
import IconDescription from '@ui/icons/imaterial/editor/Align.svg';
import IconClock from '@ui/icons/imaterial/time-and-date/Clock.svg';
import theme from '@ui/theme';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { HttpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react';

import useStyles from './CalendarEventDialog.style';

type CalendarDialogProps = {
  calendarId: string;
  title?: string;
  event: EventClickArg;
  selectedDate: Date;
  calendarTimeZone: string;
  closeHandler?: () => void;
  deleteCalendarEvent: HttpsCallable;
  editCalendarEvent: HttpsCallable;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  updateEventState: (type: 'delete' | 'patch', eventId: string, event?) => void;
}

const CalendarDialog = (props: CalendarDialogProps) => {
  const { calendarId, event, selectedDate, editCalendarEvent, deleteCalendarEvent, closeHandler: parentCloseHandler, setRefetch, updateEventState } = props;
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>();
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [error, setError] = useState<Error>(null);
  const [attendeeUserData, setAttendeeUserData] = useState<Partial<guesthouse.User>[]>();

  const eventDef = event?.event?._def;
  const eventAttendees = eventDef?.extendedProps?.attendees;

  useEffect(() => {
    const userDataArray: Partial<guesthouse.User>[] = [];

    const getAttendeeData = async (attendee: string) => {
      const userDocs = await getDocs(query(collection(database, 'users'), where('email', '==', attendee)));
  
      if (userDocs?.docs?.length) {
        for (const doc of userDocs.docs) {
          userDataArray.push(doc.data() as guesthouse.User);
        }
      } else {
        userDataArray.push({
          email: attendee
        });
      }
    };
  
    if (eventAttendees) {
      for (const attendee of eventAttendees) {
        getAttendeeData(attendee['email']);
      }
      
      setAttendeeUserData(userDataArray);
    }

  }, [eventAttendees]);

  const handleDelete = async () => {
    setLoading(true);
    setDisabled(true);

    await deleteCalendarEvent({ calendarId, eventId: eventDef?.extendedProps.publicId });

    updateEventState('delete', eventDef?.extendedProps.publicId);

    setLoading(false);
  };
  
  return (
    <>
      <DialogTitle className={classes.eventTitleContainer}>
        {eventDef?.title}
        {loading ?
          <CircularProgress 
            style={{
              height: 20,
              width: 20
            }}
          />
          :
          <div>
            <IconButton
              style={{
                color: '#000',
              }}
              onClick={() => {
                setOpenEditForm(!openEditForm);
              }}
            >
              <IconEdit 
                style={{
                  height: 20,
                  width: 20
                }}
              />
            </IconButton>

            {openEditForm &&
            <IconButton
              style={{
                color: '#000',
              }}
              onClick={async () => {
                await handleDelete();
                parentCloseHandler();
              }}
            >
              <IconDelete 
                style={{
                  height: 20,
                  width: 20
                }}
              />
            </IconButton>
            }
          </div>
        }
      </DialogTitle>

      <DialogContent
        style={{
          padding: theme.spacing (3),
          display: 'flex',
          flexDirection: 'column',
          alignItems: openEditForm ? 'center' : 'flex-start'
        }}
      >
        {openEditForm ?    
          <CalendarEventForm
            selected_date={selectedDate}
            error={error}
            submitText="Edit Event"
            initialValues={{
              summary: eventDef?.title,
              location: eventDef?.extendedProps?.location,
              description: eventDef?.extendedProps?.description,
              start_time: new Date(eventDef?.extendedProps?.startTime),
              end_time: new Date(eventDef?.extendedProps?.endTime),
              attendees: attendeeUserData,
              colorId: eventDef?.extendedProps?.googleColorId
            }}
            disabled={disabled}
            onSubmit={async (values) => {
              try {
                await editCalendarEvent({ calendarId, eventId: eventDef?.extendedProps.publicId, ...values });
                setRefetch(true);
                parentCloseHandler();
              } catch (e) {
                setError(e);
              }
            }}
          />
          :
          <>
            {eventDef?.extendedProps?.location &&
            <div className={classes.eventRowContainer}>
              <IconPin className={classes.eventIcon} />

              <Typography>
                {eventDef?.extendedProps?.location}
              </Typography>
            </div>
          }

            {eventDef?.extendedProps?.description &&
            <div className={classes.eventRowContainer}>
              <IconDescription className={classes.eventIcon} />

              <Typography>
                {eventDef?.extendedProps?.description}
              </Typography>
            </div>
        } 

            <div className={classes.eventRowContainer}>
              <IconClock className={classes.eventIcon} />
          
              <Typography>
                {new Date(eventDef?.extendedProps?.startTime).toLocaleTimeString()}
                {' - '}
                {new Date(eventDef?.extendedProps?.endTime).toLocaleTimeString()}
              </Typography>
            </div>

            {eventAttendees?.length > 0 &&
            <div className={classes.eventRowContainer}>
              <IconUser className={classes.eventIcon} />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {eventAttendees.map((attendee) => {
                  return (
                    <Typography
                      key={attendee['email']}
                    >
                      {attendee['email']}
                    </Typography>
                  );
                }
                )}

              </div>
            </div>
        }
          </>
      }
      </DialogContent>


    </>
  );
};

export default CalendarDialog;
