import formatCurrency from 'format-currency';
import { animate } from 'framer-motion';
import React, { useEffect, useRef } from 'react';

import usePrevious from '../../hooks/usePrevious';

type FormatOptions = {
  format: string;
  symbol?: string;
  minFraction?: number;
  maxFraction?: number;
}

interface CounterProps {
  to: number;
  className?: string;
  style?: React.CSSProperties;
  format?: FormatOptions;
}

const Counter : React.FC<CounterProps> = (props) => {
  const { to, className, style } = props;
  let { format } = props;
  const from = usePrevious(to);
  const nodeRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;

    if (!format) {
      format = { format: '%s%v', symbol: '$', minFraction: 0, maxFraction: 0 };
    }

    const controls = animate(from || 0, to || 0, {
      duration: .3,
      onUpdate(value) {
        if (node) {
          try {
            // @ts-ignore
            node.textContent = formatCurrency(value, format);
          } catch (e) {
            console.error(e);
          }
        }
      }
    });

    return () => controls.stop();
  }, [from, to]);

  return (
    <span
      ref={nodeRef}
      className={className}
      style={style}
    />
  );
};

Counter.defaultProps = {
  to: 1,
};

export default React.memo(Counter);
