import { parseAddress as parse } from 'parse-address';

const parseAddress = (address) => {
  try {
    let parsed = parse(address.replace(', USA', ''));
    let short;

    if (parsed.type && parsed.sec_unit_type) {
      parsed.type = parsed.type + ',';
    } else if (!parsed.type && parsed.sec_unit_type) {
      parsed.sec_unit_type = parsed.sec_unit_type + ',';
    } else if (parsed.type) {
      parsed.type = parsed.type + ',';
    } else if (parsed.street) {
      parsed.street = parsed.street + ',';
    } else if (parsed.number) {
      parsed.number = parsed.number + ',';
    }

    if (parsed) {
      short = [
        parsed.number,
        parsed.prefix,
        parsed.street,
        parsed.type,
        parsed.sec_unit_type,
        parsed.sec_unit_num,
      ].filter(i => i).join(' ');
    } else {
      short = address;
      parsed = {};
    }

    return { 
      short,
      parsed,
    };
  } catch (e) {
    return { 
      short: address,
      parsed: {},
    };
  }
};

export default parseAddress;
