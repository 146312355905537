import '@ui/yup/Yup';

import * as Yup from 'yup';

const ProfileSchema = Yup.object().shape({
  firstname: Yup.string()
    .required('Firstname is required'),
  lastname: Yup.string()
    .required('Lastname is required'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email address is required'),
  location: Yup.object({
    address: Yup.string().nullable(),
    place_id: Yup.string().nullable().required('Location is required'),
    vicinity: Yup.string().nullable(),
    _geoloc: Yup.object().shape({
      lat: Yup.number(),
      lng: Yup.number(),
    }).nullable()
  })
    .required('Location is required'),
  website: Yup.string().url('Please enter a valid URL, including http:// or https://'),
  instagram_handle: Yup.string().nullable().instagramHandle(),
  phone: Yup.string().nullable().phone(),
  company: Yup.string().nullable(),
  productCategory: Yup.array(Yup.string())
});

export default ProfileSchema;
