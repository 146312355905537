import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  dropzone: {
    background: theme.palette.common.white,
    width: '100%',
    height: '100%',
    minHeight: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fab: {
    margin: theme.spacing(1),
    padding: `0 ${theme.spacing(3)} 0 ${theme.spacing(2)} !important`,
    textTransform: 'none',
  },
  fabIcon: {
    marginRight: theme.spacing(1),
    width: '15px !important',
    height: '15px !important',
  },
}));
