import Sentry from '@integrations/Sentry';
import { DocumentData, endBefore, getDocs,limit as queryLimit, limitToLast,orderBy, Query, query as firestoreQuery,QuerySnapshot, startAfter } from 'firebase/firestore';
import { useEffect, useState } from 'react';

type fetchAction = 'initial' | 'next' | 'prev';

function usePaginatedFirebaseQuery<T = Object>(query: Query<T>, limit = 25) {
  const [error, setError] = useState<Error | boolean>(false);
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState<QuerySnapshot<DocumentData>> ();
  const [page, setPage] = useState<number>(0);
  const [fetchAction, setFetchAction] = useState<fetchAction>('initial');

  useEffect(() => {
    if (query) {
      setLoading(true);
      try {
        const firstDoc = docs?.docs[0];
        const lastDoc = docs?.docs[docs?.docs?.length - 1];

        const preparePageParams = (query: Query<T>, action: fetchAction) : Query<T> => {
          if (action === 'initial') {
            return firestoreQuery(query
              ,orderBy('created', 'desc')
              ,queryLimit(limit));
          } else if (action === 'next') {
            return firestoreQuery(query
              ,orderBy('created', 'desc')
              ,startAfter(lastDoc)
              ,queryLimit(limit));
          } else if (action === 'prev') {
            return firestoreQuery(query
              ,orderBy('created', 'desc')
              ,endBefore(firstDoc)
              ,limitToLast(limit));
          }
        };

        const fetchHandler = async (query: Query<T>, action: fetchAction) => {
          const paginatedQuery = preparePageParams(query, action);
          const results = await getDocs(paginatedQuery);
            
          setDocs(results);
          setLoading(false);
        };

        fetchHandler(query, fetchAction);
      } catch (e) {
        Sentry.captureException(e);
        setError(e);
      }
    }
  }, [docs?.docs, fetchAction, limit, page, query]
  );

  return {
    error,
    loading,
    collection: docs,
    page,
    setPage,
    setFetchAction
  };
}

export default usePaginatedFirebaseQuery;
