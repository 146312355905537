import Typography from '@mui/material/Typography';
import { useStyles } from '@ui/utils/makeStyles';
import React from 'react';

export interface OnOffProps {
  className?: string;
  style?: React.CSSProperties;
  on?: boolean;
  direction?: 'rtl' | 'ltr';
  onLabel?: string;
  offLabel?: string;
}

const OnOff: React.FC<OnOffProps> = (props) => {
  const { className, style, on, direction, onLabel, offLabel } = props;
  const { theme } = useStyles();

  return (
    <div
      className={className}
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: direction === 'ltr'  ? 'row' : 'row-reverse',
        ...style,
      }}
    >
      <Typography
        component="div"
        color="textSecondary"
        style={{
          fontSize: 'inherit',
          marginRight: direction === 'ltr'  ? theme.spacing(1) : 0,
        }}
      >
        {on ? onLabel : offLabel}
      </Typography>
      
      <div
        style={{
          height: 8,
          width: 8,
          borderRadius: '50%',
          backgroundColor: on ? '#5DCE8A' : '#A9A9A9',
          fontSize: 'inherit',
          marginRight: direction === 'ltr'  ? 0 : theme.spacing(1) ,
        }}
      />
    </div>
  );
};

OnOff.defaultProps = {
  style: {},
  on: false,
  onLabel: 'On',
  offLabel: 'Off',
  direction: 'ltr',
};

export default OnOff;
