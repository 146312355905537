import qrIcon from '@images/qr-icon.png';
import { useStyles } from '@ui/utils/makeStyles';
import * as qr from 'qrcode';
import React, { useEffect, useRef } from 'react';

type QRCodeProps = {
  text: string;
  size: number;
  icon?: boolean;
  options?: any;
  style?: React.CSSProperties;
  className?: string;
}

const loadImage = (url: string) => new Promise<HTMLImageElement>((resolve, reject) => {
  const img = document.createElement('img');

  img.onload = () => resolve(img);
  img.onerror = (e) => reject(e);
  img.src = url;
});

const QRCode: React.FC<QRCodeProps> = (props) => {
  const { text, options, style, className, size, icon } = props;
  const { theme } = useStyles();

  const canvas = useRef<HTMLCanvasElement>();

  useEffect(() => {
    if (!canvas?.current) {
      return;
    }

    qr.toCanvas(canvas.current, text, {
      width: size,
      margin: 0,
      color: {
        dark: theme.palette.common.black,
        light: '#ffffffff',
      },
      ...options,
    // @ts-ignore
    }).then(() => {
      if (icon) {
        // @ts-ignore
        loadImage(qrIcon)
          .then(image => {
            const context = canvas.current.getContext('2d');

            const iconWidth = size / 4;
            const iconPos = (size - iconWidth) / 2;


            context.drawImage(image, iconPos, iconPos, iconWidth, iconWidth);
          });
      }
    });

  }, [text, canvas?.current, JSON.stringify(options)]);

  return (
    <canvas
      ref={canvas}
      width={size}
      height={size}
      style={{ ...style, width: size, height: size }}
      className={className}
    />
  );
};

QRCode.defaultProps = {
  text: '',
  size: 200,
  options: {},
  style: {},
  icon: false,
};

export default QRCode;
