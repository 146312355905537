import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { isError } from '@ui/utils/typescriptHelpers';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import * as Yup from 'yup';

const ShippingSchema = Yup.object().shape({
  carrier: Yup.string(),
  trackingNumber: Yup.string()
});

const ShippingForm = ({ onSubmit, initialValues = {}, error, submitText = 'Mark Shipped' }) => {
  return (
    <Formik
      validationSchema={ShippingSchema}
      initialValues={{
        carrier: '',
        trackingNumber: '',
        ...initialValues,
      }}
      enableReinitialize={false}
      onSubmit={onSubmit}
    >
      {({
        values,
        setFieldValue,
        errors,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
            >
              <InputLabel id="carrier-label">
                Carrier
              </InputLabel>

              <Select
                labelId="carrier-label"
                id="carrier"
                value={values.carrier}
                label="Carrier"
                onChange={(e) => {
                  setFieldValue('carrier', e.target.value);
                }}
              >
                <MenuItem value="UPS">
                  UPS
                </MenuItem>

                <MenuItem value="USPS">
                  USPS
                </MenuItem>

                <MenuItem value="FedEx">
                  FedEx
                </MenuItem>

                <MenuItem value="DHL">
                  DHL
                </MenuItem>
              </Select>
            </FormControl>

            <Field
              fullWidth
              name="trackingNumber"
              label="Tracking Number"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
            />

            {error && (
              <FormHelperText error>
                {isError(error) ? error.message : error}
              </FormHelperText>
            )}

            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length)}
                aria-label={submitText}
              >
                {submitText}
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(ShippingForm);
