import { database } from '@app/firebase';
import useCollection from '@hooks/useCollection';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import makeStyles from '@ui/utils/makeStyles';
import { collection as firestoreCollection, orderBy, query as firestoreQuery } from 'firebase/firestore';
import React, { useMemo, useState } from 'react';

interface RoomSelectProps {
  home: guesthouse.Home;
  defaultRoom?: guesthouse.Room;
  onRoomChange?: (room?: guesthouse.Room) => void;
  className?: string;
  style?: React.CSSProperties;
}

const useStyles = makeStyles()((theme) => ({
  select: {
    fontSize: 12,
    '.MuiOutlinedInput-notchedOutline': {
      border: 0,
    }
  },
  input: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    border: 0,
  }
}));

const RoomSelect: React.FC<RoomSelectProps> = (props) => {
  const { home, defaultRoom, onRoomChange, className, style } = props;
  const { classes } = useStyles();
  const [room, setRoom] = useState(defaultRoom);

  const { collection } = useCollection(
    home?.docID && firestoreQuery(firestoreCollection(database
      ,`homes/${home?.docID}/rooms`)
    ,orderBy('created', 'desc')),
    home?.docID,
  );

  const rooms = useMemo(() => {
    return (collection?.docs || []).map(room => room.data() as guesthouse.Room);
  }, [collection?.docs]);

  return (
    <Box
      className={className}
      style={style}
    >
      <Select
        fullWidth
        className={classes.select}
        inputProps={{
          className: classes.input,
        }}
        margin="dense"
        labelId="room-select-label"
        id="room-select"
        value={room?.docID}
        label="Room"
        variant="outlined"
        onChange={(event: SelectChangeEvent) => {
          const nextRoom = rooms.find(room => room.docID === event.target.value as string);

          setRoom(nextRoom);
          onRoomChange(nextRoom);
        }}
      >
        <MenuItem
          value={undefined}
          style={{
            fontSize: 10
          }}
        />
        {
          rooms.map(room => {
            return (
              <MenuItem
                key={room.docID}
                value={room.docID}
                style={{
                  fontSize: 10
                }}
              >
                {room.title}
              </MenuItem>
            );
          })
        }
      </Select>
    </Box>
  );
};

export default RoomSelect;
