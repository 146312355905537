import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import RoomSetFormDialog from '@components/dialogs/RoomSetFormDialog';
import ItemCount from '@components/item-count/ItemCount';
import RoomSetGrid from '@components/roomset-grid/RoomSetGrid';
import AppContext from '@context/AppContext';
import UserContext from '@context/UserContext';
import {  ROOM_TYPES } from '@data';
import { ScrollElement } from '@hooks/useInfiniteScroll';
import useRoomSetFilterSearch from '@hooks/useRoomSetFilterSearch';
import useRouter from '@hooks/useRouter';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, ButtonBase, Collapse, IconButton, Switch, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { DatePicker,LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useCommonStyles from '@styles/common.style';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import theme from '@ui/theme/theme';
import { addFilter,initPagedResults, removeFilter } from '@utils/algoliaSearch';
import newId from '@utils/newId';
import { roomSetPath } from '@utils/paths';
import { startOfDay } from 'date-fns';
import { doc, setDoc } from 'firebase/firestore';
import React, { useContext, useState } from 'react';

import IconFilters from '../../icons/iconFilters';
import useStyles from './RoomSets.style';

const RoomSets = () => {
  const { classes: common } = useCommonStyles();
  const { classes, cx } = useStyles();
  const { md } = useBreakpoints();
  const router = useRouter();
  const appContext = useContext<AppContext>(AppContext);
  const userContext = useContext(UserContext);
  const [openFilters, setOpenFilters] = useState(false);

  const {
    startDate,
    setStartDate,
    roomTypesOpen,
    setRoomTypesOpen,
    setPage,
    setRoomSets,
    filters,
    results,
    roomSets,
    endOfContainerElement,
    appliedFilters,
    numResults,
  } = useRoomSetFilterSearch(userContext, appContext);
  
  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', height: '100%' }}
    >
      <div 
        className={cx({
          [classes.filtersContainer]: true,
          [classes.filtersContainerOpen]: openFilters && !md,
        })}
      >
        {!md &&
          <div className={classes.filtersContainerHeader}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                style={{ color: theme.palette.common.black }}
                size="large"
                onClick={() => setOpenFilters(false)}
              >
                <CloseIcon style={{ height: 30, width: 30 }} />
              </IconButton>
              <Typography
                fontFamily={theme.gh_vars.circular}
                style={{ fontSize: 24, display: 'inline-block' }}
              >
                Filter results
              </Typography>
            </div>
            <Typography
              fontFamily={theme.gh_vars.circular}
              style={{ fontSize: 16, marginTop: theme.spacing(2), marginRight: theme.spacing(2) }}
            >
              {results.nbHits}
              {' '}
              Items
            </Typography>
          </div>
        }

        <div className={classes.filtersContainerInner}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{ padding: md ? theme.spacing(0.5, 3) : theme.spacing(0.5, 2), marginBottom: theme.spacing(2) }}
          >
            <Typography
              className={classes.filterText}
            >
              Date needed
            </Typography>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {startDate ?
                <IconButton
                  style={{ color: theme.palette.common.black, padding: 0, marginRight: md ? theme.spacing(1) : theme.spacing(1) }}
                  size="large"
                  onClick={() => {
                    setStartDate(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>
                : null}

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disablePast
                  value={new Date(Number(startDate?.toString()) * 1000)}
                  data-test="install_date"
                  slotProps={{
                    inputAdornment: { position: 'start' },
                    textField: {
                      style:{
                        backgroundColor: '#fff',
                        width: md ? 160 : 130,
                        borderRadius: theme.shape.borderRadius,
                        margin: 0
                      },
                      variant: 'outlined',
                      margin: 'dense',
                      helperText: '',
                      placeholder: '__/__/____',
                      size: 'small',
                      error: false
                    }
                  }}
                  onChange={(date) => {
                    if (date) {
                      setStartDate(String(startOfDay(date).getTime() / 1000));
                    } else {
                      setStartDate(null);
                    }
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <ButtonBase
            className={classes.filterButtonBase}
            onClick={() => setRoomTypesOpen(!roomTypesOpen)}
          >
            Room Types
            <KeyboardArrowDownIcon 
              className={cx({
                [classes.chevronIcon]: true,
                [classes.chevronIconFlipped]: roomTypesOpen
              })}
            />
          </ButtonBase>

          <Collapse in={roomTypesOpen}>
            {ROOM_TYPES.map((roomType, i) => {
              return (
                <div key={i}>
                  <ButtonBase
                    className={classes.subfilterButtonBase}
                    onClick={() => {
                      if (filters.roomType.values?.includes(roomType.id)) {
                        removeFilter(filters.roomType.setter, filters.roomType.values, roomType.id);
                      } else {
                        setPage(0);
                        setRoomSets(initPagedResults());

                        addFilter(filters.roomType.setter, filters.roomType.values, roomType.id);
                      }
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Typography className={classes.subFilterText}>
                        {roomType.title}
                      </Typography>

                      <Switch
                        className={classes.filterSwitch}
                        checked={!!filters.roomType?.values?.includes(roomType.id)}
                        value={!!filters.roomType?.values?.includes(roomType.id)}
                        name="verified"
                        color="primary"
                      />
                    </Box>
                  </ButtonBase>
                </div>
              );
            })}
          </Collapse>
        </div>
      </div>

      <div
        style={{ width: '100%' }}
        className={cx([common.contentSpacing, classes.filtersResults])}
      >
        <Grid container>

          <Grid
            item
            xs={12}
            style={{ marginBottom: md ? theme.spacing(3) : theme.spacing(1) }}
          >
            <Box
              display="flex"
              alignItems={md ? 'flex-end' : 'flex-start'}
              justifyContent={md ? 'flex-start' : 'space-between'}
              flexDirection="row"
            >
              <Button
                size="small"
                variant="contained"
                color="secondary"
                aria-label="add room set"
                data-test="add-room-set"
                onClick={() => {
                  asyncRender(RoomSetFormDialog, 
                    { 
                      userContext,
                      router,
                      title: 'Add Room Set',
                      submitText: 'Create Room Set',
                      onSubmit: async (values) => {
                        const roomSetData: guesthouse.RoomSet = {  
                          docID: newId(),
                          title: values.title,
                          setNumber: values.setNumber,
                          assignedWarehouse: values.assignedWarehouse,
                          roomType: values.roomType,
                          inventoryType: values.inventoryType
                        };

                        return await setDoc(doc(database, `roomsets/${roomSetData.docID}`), roomSetData)
                          .then(() => {
                            router.replace(roomSetPath(roomSetData));
                          });
                      }
                    });
                }}
              >
                Add room set
              </Button>
              
              {
                md ?
                  <ItemCount numResults={numResults}/>
                  : null
              }
            </Box>
          </Grid>

          {!md ?
            <Box
              display={md ? 'flex' : 'grid'}
              gap={theme.spacing(1)}
              gridTemplateColumns="1fr 1fr"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(3) }}
            >
              <Button
                size="small"
                variant="outlined"
                style={{ width: '100%' }}
                onClick={() => setOpenFilters(true)}
              >
                <Grid
                  container
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography
                      fontFamily={theme.gh_vars.circular}
                      style={{ fontSize: 14 }}
                    >
                      Filter
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{ marginTop: theme.spacing(0.5) }}
                  >
                    {appliedFilters ?
                      <div className={classes.count}>
                        {appliedFilters}
                      </div>
                      : <IconFilters />
                    }
                  </Grid>
                </Grid>
              </Button>

            </Box>
            : null
          }

          {!md ?
            <ItemCount numResults={numResults} />
            : null}

        </Grid>

        {
          roomSets.hasOwnProperty('0') &&
            roomSets[0].status === 'complete' &&
            roomSets[0].results.length === 0
            ? (
              <Typography>
                No room sets found
              </Typography>
            ) : Object.keys(roomSets).map(n => {
              const page = roomSets[n];

              if (page.status === 'complete' && page.results?.length) {
                return (
                  <RoomSetGrid
                    key={n}
                    roomSets={page.results}
                    href={roomSetPath}
                  />
                );
              } else {
                return null;
              }
            })
        }

        <ScrollElement ref={endOfContainerElement} />
      </div>
    </div>
  );
};

export default React.memo(RoomSets);
