import SvgGuesthouseLogoIconColor from '@images/guesthouse-logo-icon-color';
import StagingAgreementDoc from '@legal/StagingAgreement.md';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useStyles } from '@ui/utils/makeStyles';
import React, { useState } from 'react';

type StagingAgreementDialogArgs = {
  resolve: () => void;
  reject: (e?: Error) => void;
  submitText?: string
}

const StagingAgreementDialog: React.FC<StagingAgreementDialogArgs> = ({
  resolve,
  reject,
  submitText = 'Accept',
}) => {
  const [open, setOpen] = useState(true);
  const { theme } = useStyles();

  return (


    <Dialog
      fullWidth
      open={open}
      maxWidth="lg"
      onClose={() => {
        setOpen(false);
        reject();
      }}
    >
      <DialogContent style={{ fontFamily: theme.gh_vars.mabryMono }}>
        <SvgGuesthouseLogoIconColor
          style={{
            width: 55,
            height: 'auto',
            display: 'block',
            margin: theme.spacing(3, 0),
          }}
        />
        <StagingAgreementDoc />
      </DialogContent>

      <DialogActions style={{ paddingTop: theme.spacing(2) }}>
        <Button
          fullWidth
          data-test="staging-agreement-dialog-accept"
          variant="contained"
          color="secondary"
          onClick={() => {
            resolve();
            setOpen(false);
          }}
        >
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>

  );
};

export default StagingAgreementDialog;
