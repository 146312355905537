import { LinkBehavior } from '@components/link-behavior/LinkBehavior';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import Sentry from '@integrations/Sentry';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import routes from '@routes';
import ProductOffer from '@ui/components/product-offer/ProductOffer';
import Protected from '@ui/components/protected/Protected';
import formatDate from '@ui/utils/formatDate';
import simpleLocation from '@ui/utils/simpleLocation';
import toJsDate from '@ui/utils/toJsDate';
import { homePath, roomPath } from '@utils/paths';
import properCase from '@utils/properCase';
import { collection, QueryDocumentSnapshot } from 'firebase/firestore';
import formatCurrency from 'format-currency';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';

import useStyles from './InventoryTable.style';

const sortStagingHistoryByInstallDate = (a: guesthouse.ProductRequest, b: guesthouse.ProductRequest) => {
  const { home: h1 } = a;
  const { home: h2 } = b;

  if (h1.install_date && h2.install_date) {
    try {
      return toJsDate(h1.install_date).valueOf() - toJsDate(h2.install_date).valueOf();
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  return 0;
};

export type RenderActionsProps = {
  product: QueryDocumentSnapshot<guesthouse.Product>
  inventory: QueryDocumentSnapshot<guesthouse.ProductInventory>
}

export type InventoryRowProps = {
  product: QueryDocumentSnapshot<guesthouse.Product>
  inventory: QueryDocumentSnapshot<guesthouse.ProductInventory>
  renderActions: (props: RenderActionsProps) => React.ReactElement
  selected: boolean;
  handleSelectRowClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, id: string) => void;
}

const InventoryRow: React.FC<InventoryRowProps> = (props) => {
  const { classes, cx } = useStyles();
  const { inventory, product, selected, handleSelectRowClick } = props;
  const userContext = useContext<UserContext>(UserContext);
  const [open, setOpen] = useState(false);
  const inventoryData = inventory.data() as guesthouse.ProductInventory;
  const [currentRequest, setCurrentRequest] = useState<guesthouse.ProductRequest>();

  const { collection: allRequests } = useCollection(
    collection(inventory.ref, 'requests')
  );

  const hasRequests = useMemo(() => {
    return allRequests && !!allRequests.docs?.length;
  }, [allRequests?.docs?.length]);

  useEffect(() => {
    allRequests?.docs.map((request) => {
      const requestData = request.data() as guesthouse.ProductRequest;

      if (!requestData.home.deinstall_date || toJsDate(requestData.home.deinstall_date) > new Date()) {
        return setCurrentRequest(requestData);
      }
    });

  }, [allRequests]);

  return (
    <>
      <TableRow
        key={inventory.id}
        className={cx({ [classes.rowHasRequests]: hasRequests })}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={selected}
            inputProps={{ 'aria-labelledby': `inventory-checkbox-${inventory.id}` }}
            onChange={(event, checked) => handleSelectRowClick(event, checked, inventory.id)}
          />
        </TableCell>
        <TableCell>
          {properCase(inventoryData.status, '_')}
        </TableCell>
        <TableCell>
          {inventoryData?.offer?.dropship ? 'Dropship' : simpleLocation(inventoryData.warehouse)}
        </TableCell>
        <TableCell>
          {formatDate(inventoryData.arrival_date)}
        </TableCell>
        <TableCell>
          {currentRequest && 
          <Link
            component={LinkBehavior}
            href={homePath(currentRequest.home)}
          >
            {simpleLocation(currentRequest.home)}
          </Link>
          }
        </TableCell>
        <TableCell>
          {currentRequest &&
            <Link
              component={LinkBehavior}
              href={roomPath(currentRequest.home, currentRequest.room)}
            >
              {currentRequest.room.title}
            </Link>
          }
        </TableCell>
        <TableCell>
          {currentRequest &&
            formatDate(currentRequest.scheduleDate)
          }
        </TableCell>
        <TableCell>
          {formatDate(inventoryData?.purchase_date)}
        </TableCell>
        <TableCell>
          {formatCurrency(inventoryData?.purchase_price, { format: '%s%v', symbol: '$' })}
        </TableCell>
        <TableCell>
          <ProductOffer offer={inventoryData.offer} />
        </TableCell>
        <TableCell 
          align="center"
        >
          {inventoryData?.roomSetId &&
            <Link 
              component={LinkBehavior}
              href={generatePath(routes.roomSet.path, { id: inventoryData.roomSetId })}
            >
              <CheckCircleIcon />
            </Link>
          }
        </TableCell>
        <TableCell
          width="100"
          align="center"
        >
          {hasRequests && (
            <IconButton
              aria-label="expand product staging history"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        {props.renderActions && (
          <Protected allowedRoles={['designer', 'admin', 'design_manager', 'website_manager', 'customer_support', 'maker']}>
            <TableCell
              width="100"
              height="60"
              align="center"
            >
              {userContext?.roles?.maker && inventoryData?.warehouse?.guesthouseManaged ? '' : props.renderActions({ inventory, product })}
            </TableCell>
          </Protected>
        )}
      </TableRow>
      {hasRequests && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={10}
          >
            <Collapse
              unmountOnExit
              in={open}
              timeout="auto"
            >
              <Box
                margin={3}
                borderRadius="50px"
                className={classes.historyBox}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  margin={[0, 0, 2, 0]}
                >
                  <Typography
                    variant="h6Alt"
                    component="div"
                    style={{ marginRight: 8 }}
                  >
                    Product Staging History
                  </Typography>
                </Box>


                <Table
                  size="small"
                  aria-label="purchases"
                >
                  <TableHead>
                    <TableRow className={classes.historyRow}>
                      <TableCell>
                        Install Date
                      </TableCell>
                      <TableCell>
                        De-install Date
                      </TableCell>
                      <TableCell>
                        Status
                      </TableCell>
                      <TableCell>
                        Schedule Date
                      </TableCell>
                      <TableCell>
                        Pickup Date
                      </TableCell>
                      <TableCell>
                        Home
                      </TableCell>
                      <TableCell>
                        Room
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      allRequests?.docs
                        ? allRequests.docs
                          .map(doc => doc.data() as guesthouse.ProductRequest)
                          .sort(sortStagingHistoryByInstallDate)
                          .map(request => {
                            return (
                              <TableRow
                                key={request.docID}
                                className={classes.historyRow}
                              >
                                <TableCell>
                                  {formatDate(toJsDate(request.home.install_date))}
                                </TableCell>
                                <TableCell>
                                  {formatDate(toJsDate(request.home.deinstall_date))}
                                </TableCell>
                                <TableCell>
                                  {properCase(request.status, '_')}
                                </TableCell>
                                <TableCell>
                                  {formatDate(toJsDate(request.scheduleDate))}
                                </TableCell>
                                <TableCell>
                                  {formatDate(toJsDate(request.pickupDate))}
                                </TableCell>
                                <TableCell>
                                  <Link
                                    component={LinkBehavior}
                                    href={homePath(request.home)}
                                  >
                                    {simpleLocation(request.home)}
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  <Link
                                    component={LinkBehavior}
                                    href={roomPath(request.home, request.room)}
                                  >
                                    {request.room.title}
                                  </Link>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        : null
                    }
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default InventoryRow;
