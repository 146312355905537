import { Timestamp } from 'firebase/firestore';
import * as Yup from 'yup';

export type TimelineItemValues = {
  sku?: guesthouse.TimelineProductSKU;
  title?: string;
  name?: guesthouse.TimelineProductNames;
  owner?: guesthouse.User;
  due_date?: Timestamp;
  completed_by?: string;
  completed_date?: Timestamp;
  docID?: string;
}

export const initialValues: TimelineItemValues = {};

const TimelineItemSchema = Yup.object().shape({
  
});

export default TimelineItemSchema;
