import { database } from '@app/firebase';
import useCollection from '@hooks/useCollection';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { collection as firestoreCollection } from 'firebase/firestore';
import { fit } from 'object-fit-math';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import useRefRect from '../../hooks/useRefRect';
import IconTag from '../../icons/imaterial/base/Tag.svg';
import { hasDropshippableOffer } from '../../utils/hasDropshippableOffer';
import useStyles from './TaggedProductsImage.style';

interface TaggedProductsImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  photo: guesthouse.Photo;
  photoTagsPath: string;
  onlyDropshippable?: boolean;
  TagIcon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  renderTooltip: (product: guesthouse.ProductTag) => React.ReactElement | string;
  onTagClick?: (product: guesthouse.ProductTag) => void;
}

const TaggedProductsImage: React.FC<TaggedProductsImageProps> = (props) => {
  const { photo, photoTagsPath, onlyDropshippable, TagIcon = IconTag, renderTooltip, onTagClick, ...rest } = props;
  const { theme, classes } = useStyles();
  const imageRef = useRef<HTMLImageElement>();
  const [fittedSize, setFittedSize] = useState({ width: 0, height: 0 });
  const [offset, setOffset] = useState({ offsetX: 0, offsetY: 0 });

  const { collection } = useCollection(
    photoTagsPath && firestoreCollection(database, photoTagsPath),
    photoTagsPath,
  );

  const productTags: guesthouse.ProductTag[] = useMemo(() => {
    if (collection?.docs?.length) {
      if (onlyDropshippable) {
        return collection.docs.map(pt => pt.data() as guesthouse.ProductTag).filter((productTag: guesthouse.ProductTag) => hasDropshippableOffer(productTag.product?.offers));
      }

      return collection.docs.map(pt => pt.data() as guesthouse.ProductTag);
    } else {
      return [];
    }
  }, [collection, onlyDropshippable]);

  const imageRect = useRefRect(imageRef.current);

  useEffect(() => {
    if (imageRef?.current) {
      const image = imageRef.current;
  
      const parentSize = { width: imageRect.width, height: imageRect.height };
      const childSize = { width: image.naturalWidth, height: image.naturalHeight };
  
      const fitted = fit(parentSize, childSize, 'contain');
  
      const offsetX = (parentSize.width - fitted.width) / 2;
      const offsetY = (parentSize.height - fitted.height) / 2;

      setFittedSize(fitted);
      setOffset({ offsetX, offsetY });
    }
  }, [imageRect, imageRef?.current?.naturalWidth, imageRef?.current?.naturalHeight]);

  return (
    <>
      <>
        {productTags.map((pt, i) => {
          if (!pt.positionX || !pt.positionX) {
            return null;
          }

          return (
            <Box
              key={i}
              zIndex={3}
              style={{
                position: 'absolute',
                left: offset?.offsetX + (pt.positionX * fittedSize.width),
                top: offset?.offsetY + (pt.positionY * fittedSize.height),
              }}
              onClick={() => {
                if (typeof onTagClick === 'function') {
                  onTagClick(pt);
                }
              }}
            >
              <Box
                className={classes.tag}
                style={{
                  transform: 'translate(-50%, -50%)',
                  background: typeof onTagClick === 'function' ? 'transparent' : theme.palette.common.white,
                }}
              >
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                  title={typeof renderTooltip === 'function' && renderTooltip(pt)}
                >
                  <Box
                    component="span"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <TagIcon
                      style={{
                        width: 20,
                        height: 'auto',
                        cursor: typeof onTagClick === 'function' ? 'pointer' : 'default',
                        fill: typeof onTagClick === 'function' ? 'f7f7f7' : '',
                        color: typeof onTagClick === 'function' ? 'f7f7f7' : '',
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          );
        })}
      </>
      <img
        ref={imageRef}
        src={photo?.large_url}
        {...rest}
      />
    </>
  );

};

export default TaggedProductsImage;
