import HomeGrid from '@components/home-grid/HomeGrid';
import HomeGridSkeleton from '@components/home-grid/HomeGrid.skeleton';
import ProductGrid from '@components/product-grid/ProductGrid';
import ProductGridSkeleton from '@components/product-grid/ProductGrid.skeleton';
import SearchResults from '@components/search-results/SearchResults';
import UserGrid from '@components/user-grid/UserGrid';
import UserGridSkeleton from '@components/user-grid/UserGrid.skeleton';
import UserContext from '@context/UserContext';
import useRouter from '@hooks/useRouter';
import client from '@integrations/Algolia';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useCommonStyles from '@styles/common.style';
import Protected from '@ui/components/protected/Protected';
import checkRoles from '@ui/utils/checkRoles';
import makeFacetFilters from '@utils/makeFacetFilters';
import makeIndexName from '@utils/makeIndexName';
import { homePath, productPath, userPath } from '@utils/paths';
import React, { useContext, useMemo } from 'react';

const homes = client.initIndex(makeIndexName('homes'));
const products = client.initIndex(makeIndexName('products'));
const users = client.initIndex(makeIndexName('users'));


const Search = () => {
  const { classes: common, theme } = useCommonStyles();
  const router = useRouter();
  const userContext = useContext<UserContext>(UserContext);

  const homeFacetFilters: Record<string, any> = {};
  const userFacetFilters: Record<string, any> = {};
  const productFacetFilters: Record<string, any> = {};

  const maker = checkRoles(['maker'], userContext.roles);
  const client = checkRoles(['realtor'], userContext.roles);

  if (!maker) {
    productFacetFilters.achived = false;
    userFacetFilters.achived = false;
  }

  if (maker || client) {
    homeFacetFilters.members = userContext.user.uid;
  }

  if (maker) {
    productFacetFilters.owner = { docID: userContext.user.uid };
  }


  const [aroundLatLng, aroundRadius] = useMemo(() => {
    if (userContext?.activeRegion?.centerPoint?._geoloc?.lat && userContext?.activeRegion?.centerPoint?._geoloc?.lng) {
      return [
        `${userContext.activeRegion?.centerPoint?._geoloc.lat},${userContext.activeRegion?.centerPoint?._geoloc.lng}`,
        userContext.activeRegion?.aroundRadius * 1000
      ];
    }
    return [];
  }, [userContext.activeRegion]);

  const query = router.query.q?.toString();

  if (!query) {
    return (
      <div className={common.contentSpacing}>
        <Typography>
          Please enter a search query
        </Typography>
      </div>
    );
  }

  return (
    <div className={common.contentSpacing}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Box
            display="flex"
            alignItems="flex-end"
          >
            <Typography
              component="h1"
              variant="h3Alt"
            >
              Search:
              {' '}
              {query}
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
        >
        </Grid>
      </Grid>

      <Protected allowedRoles={['admin', 'design_manager', 'designer', 'customer_support', 'website_manager', 'maker']}>
        <SearchResults
          index={products}
          query={query}
          facetFilters={makeFacetFilters(productFacetFilters)}
          aroundLatLng={aroundLatLng}
          aroundRadius={aroundRadius}
          renderResults={({ hits: results }) => {
            if (!results.length) {
              return null;
            }
            return (
              <ProductGrid
                products={results}
                href={productPath}
              />
            );
          }}
          renderNoResults={() => null}
          renderLoading={() => {
            return (
              <ProductGridSkeleton />
            );
          }}
        />
      </Protected>

      <SearchResults
        index={homes}
        query={query}
        facetFilters={makeFacetFilters(homeFacetFilters)}
        aroundLatLng={aroundLatLng}
        aroundRadius={aroundRadius}
        renderResults={({ hits: results }) => {
          if (!results.length) {
            return null;
          }
          return (
            <HomeGrid
              homes={results}
              href={homePath}
            />
          );
        }}
        renderNoResults={() => null}
        renderLoading={() => {
          return (
            <HomeGridSkeleton />
          );
        }}
      />


      <Protected allowedRoles={['admin', 'design_manager', 'designer', 'customer_support', 'website_manager']}>
        <SearchResults
          index={users}
          query={query}
          facetFilters={makeFacetFilters(userFacetFilters)}
          renderResults={({ hits: results }) => {
            if (!results.length) {
              return null;
            }
            return (
              <UserGrid
                users={results}
                href={userPath}
              />
            );
          }}
          renderNoResults={() => null}
          renderLoading={() => {
            return (
              <UserGridSkeleton />
            );
          }}
        />
      </Protected>
    </div>
  );
};

export default React.memo(Search);
