import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

type LinkBehaviorProps = Omit<RouterLinkProps, 'to' | 'href'> & {
  href: RouterLinkProps['to']
}

const LinkBehavior = React.forwardRef<any, LinkBehaviorProps>((props, ref) => {
  const { href, ...rest } = props;
  
  return (
    <RouterLink
      ref={ref}
      to={href}
      {...rest}
    />
  );
});

export { LinkBehavior, RouterLink };
