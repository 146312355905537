import { SearchOptions, SearchResponse } from '@algolia/client-search';
import { CATEGORIES } from '@data';
import useRouter from '@hooks/useRouter';
import algolia from '@integrations/Algolia';
import Sentry from '@integrations/Sentry';
import { Filters } from '@utils/algoliaSearch';
import { algoliaSearchRoomSets } from '@utils/algoliaSearch';
import { UseFacetSearchOptions } from '@utils/algoliaSearch';
import makeBooleanFilter from '@utils/makeBooleanFilter';
import makeFacetFilters from '@utils/makeFacetFilters';
import makeIndexName from '@utils/makeIndexName';
import makeNumericFilter from '@utils/makeNumericFilter';
import qs from 'querystring';
import { useEffect, useState } from 'react';

import useDebounceEffect from './useDebounceEffect';

const index = algolia.initIndex(makeIndexName('roomsets'));

export default function useFacetSearchRoomSets(options: UseFacetSearchOptions<guesthouse.AlgoliaRoomSet>) {
  const router = useRouter();

  const [results, setResults] = useState<SearchResponse<guesthouse.AlgoliaRoomSet>>(options.initResults);
  const [q, setQuery] = useState<string>(options.query?.q?.toString());
  const [startDate, setStartDate] = useState<string | string[]>(options.query.startDate);
  const [roomType, setRoomType] = useState<string | string[]>(options.query.roomType);

  const doSearch = () => {
    const obj = {
      q,
      roomType,
      startDate,
    };

    for (const k in obj) {
      if (!obj[k] || (Array.isArray(obj[k]) && !obj[k].length)) {
        delete obj[k];
      }
    }

    const search = qs.stringify(obj);

    const url = Object.keys(obj).length ? `${router.pathname}?${search}` : router.pathname;

    if (options.useRouter) {
      router.push(url);
    }

    const facetFilters = makeFacetFilters({
      'roomType': roomType,
      ...options.extraFacetFilters,
    });


    const numericFilters = makeNumericFilter({

    }) as unknown as (string | string[])[];

    if (startDate) {
      numericFilters.push([`availabilityStart_timestamp < ${startDate}`]);
    }

    const booleanFilters = makeBooleanFilter({
    });

    const searchOptions: SearchOptions = {
      facetingAfterDistinct: true,
      distinct: 1,
      facetFilters,
      numericFilters: numericFilters,
      filters: booleanFilters,
      facets: [
        'roomType',
      ],
      ...options.searchOptions,
    };

    algoliaSearchRoomSets(index, q, searchOptions)
      .then(setResults)
      .catch(Sentry.captureException);
  };

  useEffect(doSearch, []);
  useDebounceEffect(doSearch, 1000, [
    JSON.stringify(options.searchOptions),
    q,
    roomType,
    startDate,
  ]);

  const filters: Filters = {
    roomType: {
      id: 'roomType',
      title: 'Room Type',
      options: CATEGORIES,
      values: roomType,
      counts: results.facets?.['roomType'],
      setter: setRoomType,
    }
  };

  return {
    results,
    filters,
    query: q,
    setQuery,
    roomType,
    setRoomType,
    startDate,
    setStartDate,
  };
}
