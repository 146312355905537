import { database } from '@app/firebase';
import getDataFromCollection from '@ui/utils/getDataFromCollection';
import toJsDate from '@ui/utils/toJsDate';
import { collection } from 'firebase/firestore';
import { useCallback, useMemo } from 'react';

import useCollection from './useCollection';

interface GroupedTimelineItems {
  clientReview: guesthouse.TimelineItem[];
  internalReview: guesthouse.TimelineItem[];
  upcoming: guesthouse.TimelineItem[];
  complete: guesthouse.TimelineItem[];
}

interface GroupedMoodboards {
  approvedMoodboards: guesthouse.Moodboard[];
  unapprovedMoodboards: guesthouse.Moodboard[];
}

export type TimelineData = GroupedTimelineItems & GroupedMoodboards;

const customSort : guesthouse.TimelineProductNames[] = [
  'consult_date',
  'upload_moodboards',
  'review_moodboards',
  'install_date',
  'photoshoot_date',
  'photo_asset_delivery',
  'drone_footage',
  'twilight_photos',
  'twilight_photos_edit',
  'video_tour',
  'floorplan',
  'matterport',
  'deinstall_date',
  'add_lockbox_info'
];

const customLookup = customSort.reduce(function (r, a, i) {
  r[a] = ('000' + i).slice(-4); 
  return r;
}, {});

const sortTimelineItems = (a : guesthouse.TimelineItem, b : guesthouse.TimelineItem) => {
  try {
    return (customLookup[a.name] || a.name).localeCompare(customLookup[b.name] || b.name);
  } catch (e) {
    return 0;
  }
};

const getTimelineItemByName = (timelineData : guesthouse.TimelineItem[], name: guesthouse.TimelineProductNames) : guesthouse.TimelineItem => {
  return timelineData.find(tli => tli.name === name);
};

export default function useTimeline(homeID: string) : TimelineData {
  const { collection: timelineCollection } = useCollection(collection(database, `homes/${homeID}/timeline`));
  const { collection: moodboardCollection } = useCollection(collection(database, `homes/${homeID}/moodboards`));

  const timeline = getDataFromCollection(timelineCollection) as guesthouse.TimelineItem[];
  const moodboards = getDataFromCollection(moodboardCollection) as guesthouse.Moodboard[];

  const approvedMoodboards = useMemo<guesthouse.Moodboard[]>(() => moodboards.filter(i => i.approved === true), [moodboards]);
  const unapprovedMoodboards = useMemo<guesthouse.Moodboard[]>(() => moodboards.filter(i => i.approved !== true), [moodboards]);

  const buildTimeline = useCallback((timeline: guesthouse.TimelineItem[]): GroupedTimelineItems => {
    const init: GroupedTimelineItems = {
      clientReview: [],
      internalReview: [],
      upcoming: [],
      complete: [],
    };

    const timelineData = timeline
      .sort(sortTimelineItems);

    return timelineData.reduce((acc, timelineItemData) => {
      if (timelineItemData?.completed_date) {
        acc.complete.push(timelineItemData);
        return acc;
      }

      if (timelineItemData.name === 'review_moodboards' && unapprovedMoodboards.length) {
        acc.clientReview.push(timelineItemData);
      } else if (timelineItemData.name === 'upload_moodboards' && moodboards.length < 2) {
        acc.internalReview.push(timelineItemData);
      } else if (timelineItemData.name === 'add_lockbox_info' && getTimelineItemByName(timelineData, 'add_lockbox_info')?.completed_date) {
        acc.internalReview.push(timelineItemData);
      } else {
        acc.upcoming.push(timelineItemData);
      }

      return acc;
    }, init);
  }, [moodboards, approvedMoodboards, unapprovedMoodboards]);

  const { clientReview, internalReview, upcoming, complete } = useMemo<GroupedTimelineItems>(() => {
    const groupedTimelineItems = buildTimeline(timeline);

    groupedTimelineItems.upcoming.sort((a, b) => {
      if (a.due_date && b.due_date) {
        return toJsDate(a.due_date)?.getTime() - toJsDate(b.due_date)?.getTime();
      }
      if (!a.due_date) {
        return 1;
      }
      if (!b.due_date) {
        return -1;
      }
    });

    groupedTimelineItems.complete.sort((a, b) => {
      if (a.completed_date && b.completed_date) {
        return toJsDate(a.completed_date)?.getTime() - toJsDate(a.completed_date)?.getTime();
      }
      if (!a.completed_date) {
        return 1;
      }
      if (!b.completed_date) {
        return -1;
      }
    });

    return groupedTimelineItems;
  }, [timeline, buildTimeline]);

  return { clientReview, internalReview, upcoming, complete, approvedMoodboards, unapprovedMoodboards };
}
