import { database } from '@app/firebase';
import HomeGridSkeleton from '@components/home-grid/HomeGrid.skeleton';
import RoomSetGrid from '@components/roomset-grid/RoomSetGrid';
import SearchResults from '@components/search-results/SearchResults';
import UserContext from '@context/UserContext';
import useCenterPoint from '@hooks/useCenterPoint';
import useProductAvailability from '@hooks/useProductAvailability';
import algolia from '@integrations/Algolia';
import Sentry from '@integrations/Sentry';
import IconSearch from '@mui/icons-material/Search';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import makeIndexName from '@utils/makeIndexName';
import { collection, doc, getDoc, writeBatch } from 'firebase/firestore';
import React, { useContext, useMemo,useState } from 'react';

import Props, { defaultProps } from './RoomSetSearch.props';
import useStyles from './RoomSetSearch.style';
import RoomSetSearchTable from './RoomSetSearchTable';

const roomSetIndex = algolia.initIndex(makeIndexName('roomsets'));

const RoomSetSearch: React.FC<Props> = (props: Props) => {
  const { product, onComplete } = props;
  const { classes, theme } = useStyles();
  const userContext = useContext(UserContext);
  const [query, setQuery] = useState('');
  const [roomSet, setRoomSet] = useState<guesthouse.AlgoliaRoomSet>(props.roomSet);
  const [selectedInternal, setSelectedInternal] = useState<guesthouse.ProductInventory[]>([]);
  const [selectedExternal, setSelectedExternal] = useState<guesthouse.ProductInventory[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [assignedWarehouse, setAssignedWarehouse] = useState<guesthouse.Warehouse>();
  const [coords, radius] = useCenterPoint(assignedWarehouse);
  const { availableInternal, availableExternal } = useProductAvailability(new Date(), product.docID, coords, radius, true);

  const [aroundLatLng, aroundRadius] = useMemo(() => {
    if (userContext?.activeRegion?.centerPoint?._geoloc?.lat && userContext?.activeRegion?.centerPoint?._geoloc?.lng) {
      return [
        `${userContext.activeRegion?.centerPoint?._geoloc.lat},${userContext.activeRegion?.centerPoint?._geoloc.lng}`,
        userContext.activeRegion?.aroundRadius * 1000
      ];
    }
    return [];
  }, [userContext.activeRegion]);

  const submitHandler = async () => {
    setIsSubmitting(true);
    const batch = writeBatch(database);
    const roomSetDoc = await getDoc(doc(collection(database, 'roomsets'), roomSet.docID));

    const updateInventory = (inventory: guesthouse.ProductInventory) => {
      const updatedInventory: guesthouse.ProductInventory = {
        ...inventory,
        roomSetId: roomSetDoc.id
      };

      batch.update(doc(collection(database, `products/${inventory.product.docID}/inventory`), inventory.docID), { roomSetId: roomSetDoc.id });
      batch.set(doc(collection(database, `roomsets/${roomSetDoc.id}/roomset_inventory`), inventory.docID), updatedInventory);
    };

    for (const inventory of selectedInternal) {
      updateInventory(inventory);
    }

    for (const inventory of selectedExternal) {
      updateInventory(inventory);
    }

    await batch.commit();
    setIsSubmitting(false);
    onComplete({ roomSet });
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
      >
        {
          roomSet
            ? (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                style={{
                  marginTop: theme.spacing(2),
                }}
              >
                <Typography
                  variant="h4Alt"
                  component="h2"
                  style={{
                    marginRight: theme.spacing(2),
                  }}
                >
                  Request product for room set
                </Typography>
                <Chip
                  label={roomSet.title}
                  onDelete={() => setRoomSet(undefined)}
                />
              </Box>
            )
            : (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                style={{
                  marginTop: theme.spacing(2),
                }}
              >
                <Typography
                  variant="h4Alt"
                  component="h2"
                >
                  Add product to room set
                </Typography>
              </Box>
            )
        }
      </Box>
      {!roomSet && (
        <Alert
          severity="info"
          style={{ margin: theme.spacing(2, 0) }}
        >
          Please select a room set to continue
        </Alert>
      )}

      {!roomSet && (
        <TextField
          fullWidth
          className={classes.searchField}
          placeholder="Search room sets"
          variant="outlined"
          margin="dense"
          InputProps={{
            className: classes.searchFieldInput,
            startAdornment: (
              <InputAdornment position="start">
                <IconSearch className={classes.searchFieldIcon} />
              </InputAdornment>
            ),
          }}
          value={query}
          data-test="room-set-search-text-field"
          onChange={(e) => {
            setQuery(e.target.value);
            setRoomSet(undefined);
          }}
        />
      )}

      {!roomSet && (
        <Box margin={theme.spacing(2, 0, 0, 0)}>
          <SearchResults
            index={roomSetIndex}
            query={query}
            aroundLatLng={aroundLatLng}
            aroundRadius={aroundRadius}
            renderResults={({ hits: results }) => {
              if (!results.length) {
                return null;
              }

              return (

                <RoomSetGrid
                  spacing={4}
                  roomSets={results as guesthouse.AlgoliaRoomSet[]}
                  onRoomsetClick={async (_, roomSet) => {
                    try {
                      const warehouseDoc = await getDoc(doc(collection(database, 'warehouses'), roomSet.assignedWarehouseId));
                      const warehouse = warehouseDoc.data() as guesthouse.Warehouse; 
  
                      setAssignedWarehouse(warehouse);
                      setRoomSet(roomSet);
                      setQuery('');
                    } catch (e) {
                      Sentry.captureException(e);
                    }
                  }}
                />

              );
            }}
            renderNoResults={() => {
              return (
                <>
                  <div style={{ marginBottom: theme.spacing(4) }}>
                    <Typography>
                      No room sets matched your search
                    </Typography>
                  </div>
                </>
              );
            }}
            renderLoading={() => {
              return (
                <HomeGridSkeleton />
              );
            }}
          />
        </Box>
      )}

      {roomSet &&
      <>
        {!!availableInternal?.length && (
          <RoomSetSearchTable
            inventory={availableInternal.filter(inventory => (!inventory.roomSetId && inventory.warehouse?.docID === roomSet?.assignedWarehouseId))}
            inventoryType={'internal'}
            isSubmitting={isSubmitting}
            selectedInventory={selectedInternal}
            setSelectedInventory={setSelectedInternal}
          />
        )}

        {!!availableExternal?.length && (
          <RoomSetSearchTable
            inventory={availableExternal.filter(inventory => (!inventory.roomSetId && inventory.warehouse?.docID === roomSet?.assignedWarehouseId))}
            inventoryType={'external'}
            isSubmitting={isSubmitting}
            selectedInventory={selectedExternal}
            setSelectedInventory={setSelectedExternal}
          />
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: theme.spacing(3)
          }}
        >
          <SubmitButton
            data-test="room-set-search-add-to-room-set-button"
            variant={(!selectedExternal.length && !selectedInternal.length) ? 'text' : 'contained'}
            color="secondary"
            size="small"
            disabled={!selectedExternal.length && !selectedInternal.length || isSubmitting}
            isSubmitting={isSubmitting}
            onClick={submitHandler}
          >
            Add inventory to room set
          </SubmitButton>
        </div>
      </>
      }

    </>
  );
};

RoomSetSearch.defaultProps = defaultProps;

export default RoomSetSearch;
