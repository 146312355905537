import { GridProps } from '@mui/material/Grid';
import { Query } from 'firebase/firestore';

type CheckRolePermissions = (roles: guesthouse.Roles) => boolean;

type Props = {
  useInfiniteScroll?: boolean;
  afterGrid?: (photos: guesthouse.Photo[]) => JSX.Element | null;
  perPage?: number;
  uploadRoles?: Array<guesthouse.RoleTypes | CheckRolePermissions>;
  collection?: string;
  query?: Query<guesthouse.Photo>;
  gridItemProps?: GridProps;
  gridProps?: GridProps;
  showSkeleton?: boolean;
  allowPrimaryPhoto?: boolean;
  homeId?: string;
  renderZeroMessage?: () => JSX.Element | 'string';
  documentPhotoPath?: string;
  home?: guesthouse.Home;
  room?: guesthouse.Room;
  roomSet?: guesthouse.RoomSet;
  product?: guesthouse.Product;
  showHomeInfo?: boolean;
  showProductTags?: boolean;
  enableTagging?: boolean;
  openPhotoDialog?: boolean;
  allowPrimaryPhotoDelete?: boolean;
}

const defaultProps: Partial<Props> = {
  useInfiniteScroll: false,
  perPage: 100,
  uploadRoles: ['admin', 'design_manager', 'designer', 'website_manager', 'customer_support', 'media_manager'],
  gridProps: {},
  gridItemProps: {},
  showSkeleton: true,
  allowPrimaryPhoto: false,
  renderZeroMessage: () => null,
  showHomeInfo: true,
  showProductTags: true,
  enableTagging: false,
  openPhotoDialog: true,
  allowPrimaryPhotoDelete: true
};

export default Props;
export { defaultProps };
