import { LocationDescriptorObject,LocationState } from 'history';
import queryString from 'query-string';
import { useMemo } from 'react';
import { Location, NavigateOptions, To, useLocation, useNavigate, useParams } from 'react-router-dom';

export interface GHRouter<Params, State> {
  push: (to: string | LocationDescriptorObject<State>, state?: LocationState) => void,
  replace: (to: string | LocationDescriptorObject<State>, state?: LocationState) => void,
  goBack: () => void,
  pathname: string,
  query: queryString.ParsedQuery,
  params: Partial<Params>,
  location: TypedLocation<State>
}

export interface DefaultLocationState {
  next?: string;
  cancelScroll?: boolean;
  confirmEmail?: boolean;
  emailAction?: {
    mode: string;
    lang: string;
    oobCode: string;
    apiKey: string;
    continueUrl: string;
  }
}

interface NavigateArgs {
  to: To
  opts: NavigateOptions
}

function historyV5toV6<S>(to: string | LocationDescriptorObject<S>, state: LocationState = {}): NavigateArgs {
  if (typeof to === 'string') {
    return { to, opts: { state: state } };
  } else {
    to = to as LocationDescriptorObject<S>;
    const navigateTo: To = {
      pathname: to.pathname,
      search: to.search,
      hash: to.hash
    };

    return { to: navigateTo, opts: { state: to.state } };
  }
}

interface TypedLocation<T = DefaultLocationState> extends Location {
  state: T
}

function useRouter<Params extends { [K in keyof Params]?: string | undefined } = {}, State extends DefaultLocationState = DefaultLocationState>(): GHRouter<Params, State> {
  // this cast is safe because useParams returns an optional type depending on the input
  // type, and a Record<string, string> type returns a Partial<Params>
  const params: Partial<Params> = useParams<Extract<keyof Params, string>>() as Partial<Params>;
  const location: TypedLocation<State> = useLocation();
  const navigate = useNavigate();
  const query = queryString.parse(location.search);

  return useMemo(() => {
    return {
      push: (to: string | LocationDescriptorObject<State>, state: LocationState = {}) => {
        const { to: navigateTo, opts } = historyV5toV6<State>(to, state);

        navigate(navigateTo, opts);
      },
      replace: (to: string | LocationDescriptorObject<State>, state: LocationState = {}) => {
        const { to: navigateTo, opts } = historyV5toV6<State>(to, state);

        navigate(navigateTo, { ...opts, replace: true });
      },
      goBack: () => {
        navigate(-1);
      },
      pathname: location.pathname,
      query,
      params,
      location
    };
  }, [params, location, query]);
}

export default useRouter;
