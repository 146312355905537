import UserContext from '@context/UserContext';
import WarehouseForm from '@forms/Warehouse';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';

type WarehouseDialogArgs = {
  resolve: (values?: guesthouse.Warehouse) => void;
  reject: (e?: Error | boolean) => void;
  userContext: UserContext;
  title?: string;
  saveText?: string;
  cancelText?: string;
  initialValues?: object;
}


export const WarehouseDialog : React.FC<WarehouseDialogArgs> = (args) => {
  const { 
    resolve, 
    reject, 
    userContext, 
    title = 'Add Warehouse',
    initialValues,
    saveText = 'Save',
    cancelText = 'Cancel',
  } = args;

  const [open, setOpen] = useState(true);

  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        onClose={() => {
          resolve();
          setOpen(false);
        }}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <WarehouseForm
            error={false}
            submitText={saveText}
            initialValues={initialValues}
            onSubmit={(region) => {
              setOpen(false);
              resolve(region);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => {
              reject();
              setOpen(false);
            }}
          >
            {cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};
