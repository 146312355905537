import { Box, Button,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ProductOffer from '@ui/components/product-offer/ProductOffer';
import theme from '@ui/theme';
import properCase from '@utils/properCase';
import React from 'react';

type RoomSetSearchTableProps = {
  inventory: guesthouse.ProductInventory[];
  inventoryType: 'internal' | 'external'
  isSubmitting: boolean;
  selectedInventory: guesthouse.ProductInventory[];
  setSelectedInventory: React.Dispatch<React.SetStateAction<guesthouse.ProductInventory[]>>;
}

const RoomSetSearchTable = (props: RoomSetSearchTableProps) => {
  const { inventory, inventoryType, isSubmitting, selectedInventory, setSelectedInventory } = props;

  return  (
    <>
      <Box
        display="flex"
        alignItems="center"
        style={{ margin: theme.spacing(2, 0, 1) }}
      >
        <Typography
          variant="h6Alt"
          style={{ marginRight: theme.spacing(1) }}
        >
          {inventoryType === 'internal' ? 'Select Guest House inventory' : 'Select external inventory'}
        </Typography>
      </Box>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableCell width="110">
              Status
            </TableCell>
            <TableCell>
              Warehouse
            </TableCell>
            <TableCell width="100">
              Type
            </TableCell>
            <TableCell width="100">
              Condition
            </TableCell>
            <TableCell width="120">
              Price
            </TableCell>
            <TableCell width="100">
              Notes
            </TableCell>
            <TableCell width="100">

            </TableCell>
          </TableHead>
          <TableBody>
            {inventory.map((inventoryItem, i) => {
              return (
                <TableRow key={inventoryItem.docID}>
                  <TableCell>
                    {properCase(inventoryItem.status, '_')}
                  </TableCell>
                  <TableCell>
                    {inventoryItem?.warehouse?.address}
                  </TableCell>
                  <TableCell>
                    {inventoryItem?.offer?.type && properCase(inventoryItem.offer.type)}
                  </TableCell>
                  <TableCell>
                    {inventoryItem?.offer?.condition && properCase(inventoryItem.offer.condition)}
                  </TableCell>
                  <TableCell>
                    {inventoryItem?.offer && (
                      <ProductOffer offer={inventoryItem.offer} />
                    )}
                  </TableCell>
                  <TableCell>
                  </TableCell>
                  <TableCell>
                    <Button
                      data-test={`room-search-{${inventoryType}}-select-${i}`}
                      color="secondary"
                      size="small"
                      disabled={isSubmitting}
                      variant={selectedInventory.find(si => inventoryItem.docID === si.docID) ? 'outlined' : 'contained'}
                      onClick={() => {
                        if (selectedInventory.find(si => inventoryItem.docID === si.docID)) {
                          setSelectedInventory(selectedInventory.filter(si => si.docID !== inventoryItem.docID));
                        } else {
                          setSelectedInventory([...selectedInventory, inventoryItem]);
                        }
                      }}
                    >
                      {
                      selectedInventory.find(si => inventoryItem.docID === si.docID)
                        ? 'Remove'
                        : 'Select'
                    }
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RoomSetSearchTable;
