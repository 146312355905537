import React, { useMemo } from 'react';

import useStyles from './PhotoDots.style';

interface PhotoDotsProps {
  totalDots: number;
  selectedDotIndex: number;
  className?: string;
  style?: React.CSSProperties;
}

const PhotoDots: React.FC<PhotoDotsProps> = (props) => {
  const { totalDots, selectedDotIndex, className, style } = props;

  const { classes, cx } = useStyles();

  const dots = useMemo(() => {
    return [...new Array(totalDots).fill(0)];
  }, [totalDots]);

  return (
    <div
      className={cx(classes.dotWrapper, className)}
      style={style}
    >
      {dots.map((_, i) => {
        return (
          <div
            key={i}
            className={cx({
              [classes.dot]: true,
              [classes.dotSelected]: i === selectedDotIndex,
              [classes.dotMinus4]: i === selectedDotIndex - 4,
              [classes.dotMinus3]: i === selectedDotIndex - 3,
              [classes.dotMinus2]: i === selectedDotIndex - 2,
              [classes.dotMinus1]: i === selectedDotIndex - 1,
              [classes.dotPlus1]: i === selectedDotIndex + 1,
              [classes.dotPlus2]: i === selectedDotIndex + 2,
              [classes.dotPlus3]: i === selectedDotIndex + 3,
              [classes.dotPlus4]: i === selectedDotIndex + 4
            })}
          />
        );
      })}
    </div>
  );
};

export default PhotoDots;
