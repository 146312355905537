import Sentry from '@integrations/Sentry';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import ZoomMapOut from '@ui/icons/imaterial/arrows/Zoom Map Out.svg';
import IconCheckmark from '@ui/icons/imaterial/base/Checkmark.svg';
import SendTo from '@ui/icons/imaterial/base/Send to.svg';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import MoodboardFull from './MoodboardFull';
import useStyles from './MoodboardPreview.style';

type MoodboardPreviewProps = {
  className?: string;
  style?: React.CSSProperties;
  moodboard: guesthouse.Moodboard;
  onPageClick?: (i: number) => void;
}

const MoodboardPreview: React.FC<MoodboardPreviewProps> = (props) => {
  const { moodboard, onPageClick, className, style } = props;
  const [numPages, setNumPages] = useState(null);
  const [open, setOpen] = useState(false);
  const { classes, theme } = useStyles();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <Box
        className={className}
        style={style}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          style={{ marginBottom: theme.spacing(1) }}
        >
          <Typography
            component="h3"
            variant="h6Alt"
            style={{ fontSize: 14 }}
          >
            {numPages}
            {' '}
            pages
          </Typography>

          <Button
            style={{ fontWeight: 'bold' }}
            endIcon={<ZoomMapOut />}
            href={moodboard.download_url}
            target="_blank"
          >
            Click to enlarge
          </Button>
        </Box>

        <Document
          className={classes.document}
          style={style}
          file={moodboard.download_url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={Sentry.captureException}
        >
          {
            [...new Array(numPages).fill(0)].map((_, i) => {
              return (
                <Page
                  key={i}
                  pageNumber={i + 1}
                  className={classes.page}
                  height={350}
                  aria-label="Enlarge"
                  onClick={() => {
                    if (typeof onPageClick === 'function') {
                      onPageClick(i);
                    } else {
                      setOpen(true);
                    }
                  }}
                />
              );
            })
          }
        </Document>
      </Box>
      <Dialog
        fullWidth
        open={open}
        maxWidth="xl"
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>
          <span style={{ marginRight: theme.spacing(2) }}>
            {moodboard.filename}
          </span>

          {moodboard.approved && (
            <Chip
              size="small"
              style={{
                background: '#9fb9bc',
              }}
              icon={<IconCheckmark style={{ fill: 'white', }} />}
              label="Approved"
              color="primary"
            />
          )}
        </DialogTitle>

        <DialogContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            style={{ marginBottom: theme.spacing(1) }}
          >
            <Typography
              component="h3"
              variant="h6Alt"
              style={{ fontSize: 14 }}
            >
              {numPages}
              {' '}
              pages
            </Typography>

            <Button
              style={{ fontWeight: 'bold' }}
              endIcon={<SendTo />}
              href={moodboard.download_url}
              target="_blank"
            >
              Open New Tab
            </Button>

          </Box>

          <MoodboardFull moodboard={moodboard} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MoodboardPreview;
