import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

import Props, { defaultProps } from './RoomGrid.props';

const RoomGridSkeleton: React.FC<Props> = (props: Props) => {
  const { gridItemProps, gridProps } = props;
  const numItems = 4;

  return (
    <Grid
      container
      spacing={4}
      {...gridProps}
    >
      {new Array(numItems).fill(0).map((_, i) => {
        return (
          <Grid
            key={i}
            item
            xs={12}
            md={6}
            lg={6}
            xl={4}
            {...gridItemProps}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height={300}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

RoomGridSkeleton.defaultProps = defaultProps;

export default RoomGridSkeleton;
