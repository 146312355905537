import UserContext from '@context/UserContext';
import RoomSetForm from '@forms/RoomSetForm';
import { DefaultLocationState,GHRouter } from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';

type RoomSetFormDialogProps = {
  resolve: (values?: guesthouse.Calendar) => void;
  reject: (e?: Error | boolean) => void;
  userContext: UserContext;
  router: GHRouter<{}, DefaultLocationState>;
  title?: string;
  submitText?: string;
  initialValues?: object;
  onSubmit: (values: guesthouse.RoomSet) => Promise<unknown>;
}

const RoomSetFormDialog: React.FC<RoomSetFormDialogProps> = (props) => {
  const { 
    resolve, 
    reject, 
    userContext,
    initialValues,
    submitText,
    title,
    onSubmit
  } = props;
  
  const [open, setOpen] = useState(true);
  const [error, setError] = useState<string | boolean>();
  
  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        onClose={() => {
          resolve();
          setOpen(false);
        }}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <RoomSetForm
            error={error}
            initialValues={initialValues || {}}
            submitText={submitText}
            userContext={userContext}
            onSubmit={async (values) => {
              return await onSubmit(values)
                .then((values) => {
                  setOpen(false);
                  return values;
                })
                .then(resolve)
                .catch(e => {
                  setError(e.message);
                  Sentry.captureException(e);
                });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              reject();
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};

export default RoomSetFormDialog;
