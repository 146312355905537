import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import AvailableForStaging from '@components/available-for-staging/AvailableForStaging';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import EditOfferDialog from '@components/dialogs/EditOfferDialog';
import ProductDialog from '@components/dialogs/EditProductDialog';
import NoteDialog from '@components/dialogs/NoteDialog';
import Error from '@components/error/Error';
import InventoryActions from '@components/inventory-table/InventoryActions';
import InventoryTable from '@components/inventory-table/InventoryTable';
import { LinkBehavior } from '@components/link-behavior/LinkBehavior';
import Notes from '@components/notes/Notes';
import OffersTable from '@components/offers-table/OffersTable';
import PhotoGrid from '@components/photo-grid/PhotoGrid';
import RoomLink from '@components/room-link/RoomLink';
import RoomSearch from '@components/room-search/RoomSearch';
import RoomSetSearch from '@components/roomset-search/RoomSetSearch';
import SocialPreviews from '@components/social-previews/SocialPreviews';
import UserWidget from '@components/user-widget/UserWidget';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import useCenterPoint from '@hooks/useCenterPoint';
import useCollection from '@hooks/useCollection';
import useDocumentBySlugOrID from '@hooks/useDocumentBySlugOrID';
import useProductAvailability from '@hooks/useProductAvailability';
import useRouter from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import IconPlus from '@mui/icons-material/Add';
import IconAdd from '@mui/icons-material/Add';
import IconRoomSet from '@mui/icons-material/AddHome';
import ArchiveIcon from '@mui/icons-material/Archive';
import IconDelete from '@mui/icons-material/Delete';
import IconEdit from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconOpenInNew from '@mui/icons-material/OpenInNew';
import IconShare from '@mui/icons-material/Share';
import IconStar from '@mui/icons-material/Star';
import IconStarBorder from '@mui/icons-material/StarBorder';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import MetadataDialog from '@pages/metadata/MetadataDialog';
import routes from '@routes';
import useCommonStyles from '@styles/common.style';
import Nl2br from '@ui/components/nl2br/Nl2br';
import Protected from '@ui/components/protected/Protected';
import UserAvatar from '@ui/components/user-avatar/UserAvatar';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import useNavigiatorShareSupported from '@ui/hooks/useNavigatorShareSupported';
import LeftChevron from '@ui/icons/imaterial/arrows/Arrow Left.svg';
import checkRoles from '@ui/utils/checkRoles';
import formatDate from '@ui/utils/formatDate';
import makeStoreUrl from '@ui/utils/makeStoreUrl';
import productMetaData from '@ui/utils/metadata/productMetaData';
import simpleLocation from '@ui/utils/simpleLocation';
import slugOrID from '@ui/utils/slugOrID';
import toJsDate from '@ui/utils/toJsDate';
import userFullName from '@ui/utils/userFullName';
import { batchUpdateRequests } from '@utils/batchUpdateProductRequests';
import makeAppUrl from '@utils/makeAppUrl';
import newId from '@utils/newId';
import { productPath } from '@utils/paths';
import properCase from '@utils/properCase';
import sortHomesByDate from '@utils/sortHomesByDate';
import format from 'date-fns/format';
import { addDoc, collection, collectionGroup,deleteDoc,doc,Query, query,QueryDocumentSnapshot, setDoc, Timestamp, updateDoc, where } from 'firebase/firestore';
import formatCurrency from 'format-currency';
import flatten from 'lodash.flatten';
import React, { useContext, useState } from 'react';
import { generatePath } from 'react-router';

import useStyles from './Product.style';
import ProductOfferActions from './ProductOfferActions';

type ProductRequestProps = {
  doc: QueryDocumentSnapshot<guesthouse.ProductRequest>
}

const ProductRequest: React.FC<ProductRequestProps> = ({ doc }) => {
  const requestData = doc.data() as guesthouse.ProductRequest;
  const { address, unit } = requestData.home;

  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const { theme } = useStyles();

  const onAccept = () => {
    const updatedRequest: Partial<guesthouse.ProductRequest> = { status: 'ACCEPTED' };

    batchUpdateRequests(doc, updatedRequest)
      .then(() => {
        notificationContext.setContext({ open: true, message: 'You have accepted the request' });
      });
  };

  const onDecline = () => {
    const updatedRequest: Partial<guesthouse.ProductRequest> = { status: 'DECLINED' };

    batchUpdateRequests(doc, updatedRequest)
      .then(() => {
        notificationContext.setContext({ open: true, message: 'You have declined the request' });
      });
  };

  return (
    <Card style={{ marginBottom: theme.spacing(2) }}>
      <CardContent>
        <Box
          display="flex"
          alignItems="flex-start"
        >
          <UserAvatar
            user={requestData.user}
            size={60}
          />
          <div>
            <Typography style={{ marginLeft: theme.spacing(2), color: theme.palette.common.black }}>
              {
                requestData.user
                  ? (
                    <strong>
                      {requestData.user.firstname}
                      {' '}
                      {requestData.user.lastname}
                      {' '}
                      has requested this product
                    </strong>
                  ) : (
                    <strong>
                      This product has been requested
                    </strong>
                  )
              }
            </Typography>
            <List dense>
              <ListItem>
                <ListItemText
                  primary="Address"
                  secondary={(
                    <span>
                      {address}
                      {unit && ` (Unit ${unit})`}
                    </span>
                  )}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Date"
                  secondary={(
                    <span>
                      {format(toJsDate(requestData.scheduleDate), 'PPp')}
                    </span>
                  )}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Quantity"
                  secondary={(
                    <span>
                      {requestData.quantity}
                    </span>
                  )}
                />
              </ListItem>
            </List>
          </div>
        </Box>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <Button
          size="small"
          onClick={onDecline}
        >
          Decline
        </Button>
        <Button
          size="small"
          onClick={onAccept}
        >
          Accept
        </Button>
      </CardActions>
    </Card>
  );
};

interface ProductParams {
  id: string;
}

const Product = () => {
  const { classes } = useStyles();
  const { classes: common } = useCommonStyles();
  const router = useRouter<ProductParams>();
  const { theme } = useStyles();
  const userContext = useContext<UserContext>(UserContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const navigatorShareSupported = useNavigiatorShareSupported();
  const [addToHomeDialogOpen, setAddToHomeDialogOpen] = useState(false);
  const [addToRoomSetDialogOpen, setAddToRoomSetDialogOpen] = useState(false);
  const { md } = useBreakpoints();
  const { id: slug } = router.params;
  const { id, loading, doc: productDoc, exists: productDocExists } = useDocumentBySlugOrID<guesthouse.Product>(slug, 'products');

  const { collection: pendingRequests } = useCollection(
    query(collectionGroup(database, 'requests')
      ,where('product.docID', '==', id)
      ,where('controller', '==', true)
      ,where('status', '==', 'PENDING')) as Query<guesthouse.ProductRequest>,
    id
  );


  const [coords, radius] = useCenterPoint();
  const { availableInternal, availableExternal, inventoryInternal, inventoryExternal } = useProductAvailability(new Date(), id, coords, radius);


  const { collection: inventoryCollection } = useCollection<guesthouse.ProductInventory>(
    collection(database, `products/${id}/inventory`) as unknown as Query<guesthouse.ProductInventory>,
    id
  );

  const { collection: offersCollection } = useCollection<guesthouse.ProductOffer>(
    collection(database, `products/${id}/offers`) as unknown as Query<guesthouse.ProductOffer>,
    id
  );

  const taggedPhotosQuery = query(collectionGroup(database, 'productTags')
    ,where('product.docID', '==', id)) as Query<guesthouse.Photo>;

  // TODO proper error and skeleton
  if (loading) {
    return null;
  }

  if (!productDocExists) {
    return <Error statusCode={'404'} />;
  }

  const product = productDoc.data() as guesthouse.Product;

  const dimensions = {
    w: product.width,
    l: product.length,
    h: product.height,
  };

  for (const d in dimensions) {
    if (!dimensions[d]) {
      delete dimensions[d];
    }
  }

  const isOwner = () => product.owner?.docID === userContext.user.uid;

  return (
    <div className={common.contentSpacing}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
          }}
        >
          {!md && (
            <IconButton
              size="small"
              aria-label="go back"
              style={{
                padding: 0
              }}
              onClick={() => router.goBack()}
            >
              <LeftChevron
                style={{
                  fontSize: 25,
                  marginRight: theme.spacing(1),
                  color: 'black'
                }}
              />
            </IconButton>
          )}
          <Typography
            component="h1"
            variant="h3Alt"
            style={{
              marginRight: theme.spacing(2),
            }}
            data-test="product-page-title"
            display="inline"
          >
            {product.title}
          </Typography>
          {product.dibs && (
            <Chip
              style={{ position: 'relative', top: -8, marginRight: theme.spacing(2) }}
              label="Dibs"
            />
          )}
          {product.featured && (
            <Chip
              style={{ position: 'relative', top: -8, marginRight: theme.spacing(2) }}
              label="Featured"
            />
          )}
          {product.archived && (
            <Chip
              style={{ position: 'relative', top: -8, marginRight: theme.spacing(2) }}
              label="Archived"
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <div className={classes.mobileHorizontalScrollWrapper}>
            <div className={classes.mobileHorizontalScrollContainer}>

              <Protected allowedRoles={['admin', 'design_manager', 'website_manager', () => userContext.data?.verified]}>
                <Tooltip
                  title={
                    product.published
                      ? 'Unpublish from marketplace'
                      : 'Publish to Marketplace'
                  }
                >
                  <span style={{ marginRight: theme.spacing(1) }}>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      aria-label={
                        product.published
                          ? 'Unpublish from marketplace'
                          : 'Publish to Marketplace'
                      }
                      onClick={(e) => {
                        e.preventDefault();

                        const published = !product.published;
                        const message = published
                          ? 'Product published'
                          : 'Product unpublished';

                        updateDoc(productDoc.ref, { published })
                          .then(() => notificationContext.setContext({ open: true, message }))
                          .catch((e) => {
                            if (e) {
                              Sentry.captureException(e);
                              notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                            }
                          });
                      }}
                    >
                      {product.published ? 'Published' : 'Publish'}
                    </Button>
                  </span>
                </Tooltip>
              </Protected>

              <Protected allowedRoles={['admin', 'design_manager', 'website_manager']}>
                <Tooltip title={product.featured ? 'Remove featured product' : 'Set featured'}>
                  <span style={{ marginRight: theme.spacing(1) }}>
                    <IconButton
                      aria-label={product.featured ? 'Remove featured product' : 'Set featured'}
                      className={classes.editButton}
                      onClick={() => {
                        const featured = !product.featured;
                        const message = featured ? 'Product is now featured' : 'Product is no longer featured';

                        updateDoc(productDoc.ref, { featured })
                          .then(() => notificationContext.setContext({ open: true, message }))
                          .catch((e) => {
                            if (e) {
                              Sentry.captureException(e);
                              notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                            }
                          });
                      }}
                    >
                      {
                        product.featured
                          ? (
                            <IconStar />
                          ) : (
                            <IconStarBorder />
                          )
                      }
                    </IconButton>
                  </span>
                </Tooltip>
              </Protected>


              <Protected allowedRoles={['designer', 'admin', 'design_manager']}>
                <Dialog
                  fullWidth
                  open={addToHomeDialogOpen}
                  maxWidth="lg"
                  PaperProps={{
                    style: { height: '100%', maxHeight: '90vh' }
                  }}
                  onClose={() => setAddToHomeDialogOpen(false)}
                >
                  <DialogContent>
                    <RoomSearch
                      product={product}
                      onComplete={({ home, room, scheduleDate }) => {
                        setAddToHomeDialogOpen(false);
                        notificationContext.setContext({ open: true, message: `Product has been requested for ${simpleLocation(home)} (${room.title}) on ${formatDate(scheduleDate)}` });
                      }}
                    />
                  </DialogContent>
                </Dialog>

                <Tooltip title="Add to home">
                  <span style={{ marginRight: theme.spacing(1) }}>
                    <IconButton
                      data-test="product-add-to-home-button"
                      aria-label="add to home"
                      className={classes.editButton}
                      onClick={() => setAddToHomeDialogOpen(true)}
                    >
                      <IconAdd />
                    </IconButton>
                  </span>
                </Tooltip>
              </Protected>

              <Protected allowedRoles={['designer', 'admin', 'design_manager']}>
                <Dialog
                  fullWidth
                  open={addToRoomSetDialogOpen}
                  maxWidth="lg"
                  PaperProps={{
                    style: { height: '100%', maxHeight: '90vh' }
                  }}
                  onClose={() => setAddToRoomSetDialogOpen(false)}
                >
                  <DialogContent>
                    <RoomSetSearch
                      product={product}
                      onComplete={({ roomSet }) => {
                        setAddToRoomSetDialogOpen(false);
                        notificationContext.setContext({ open: true, message: `Product has been add to room set - ${roomSet.title}` });
                      }}
                    />
                  </DialogContent>
                </Dialog>

                <Tooltip title="Add to room set">
                  <span style={{ marginRight: theme.spacing(1) }}>
                    <IconButton
                      data-test="product-add-to-room-set-button"
                      aria-label="add to room set"
                      className={classes.editButton}
                      onClick={() => setAddToRoomSetDialogOpen(true)}
                    >
                      <IconRoomSet />
                    </IconButton>
                  </span>
                </Tooltip>
              </Protected>

              <Protected allowedRoles={[isOwner, 'admin', 'design_manager', 'designer', 'website_manager', 'customer_support']}>
                <Tooltip title="Edit">
                  <span style={{ marginRight: theme.spacing(1) }}>
                    <IconButton
                      className={classes.editButton}
                      aria-label="edit"
                      onClick={() => {
                        asyncRender(ProductDialog, {
                          userContext,
                          initialValues: product,
                          onSubmit: async (values) => {
                            return await updateDoc(doc(collection(database, 'products'), id), values)
                              .then(() => {
                                if (slugOrID(product) !== slugOrID(values)) {
                                  router.replace(productPath(values));
                                }
                              });
                          } 
                        }).catch((e) => {
                          if (e) {
                            Sentry.captureException(e);
                            notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                          }
                        });
                      }}
                    >
                      <IconEdit />
                    </IconButton>
                  </span>
                </Tooltip>
              </Protected>

              <Protected allowedRoles={['designer', 'admin', 'design_manager']}>
                <Tooltip
                  title="Duplicate"
                >
                  <span style={{ marginRight: theme.spacing(1) }}>
                    <IconButton
                      component={LinkBehavior}
                      aria-label="duplicate"
                      href={routes.productCreate.path}
                      state={{ ...product, primaryPhoto: undefined }}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Protected>

              <Protected allowedRoles={['admin', 'design_manager']}>
                <Tooltip
                  title={
                    product.archived
                      ? 'Remove from archive'
                      : 'Archive'
                  }
                >
                  <span style={{ marginRight: theme.spacing(1) }}>
                    <IconButton
                      aria-label={
                        product.archived
                          ? 'Remove from archive'
                          : 'Archive'
                      }
                      onClick={() => {
                        const archived = !product.archived;
                        const message = archived
                          ? 'Product archived'
                          : 'Product removed from archive';

                        if (!product.archived) {
                          asyncRender(ConfirmDialog, {
                            title: 'Archive   product',
                            content: 'This product will be unpublished and will no longer be visible in search.',
                          })
                            .then(() => {
                              updateDoc(productDoc.ref, { archived, published: false })
                                .then(() => notificationContext.setContext({ open: true, message }))
                                .catch((e) => {
                                  if (e) {
                                    Sentry.captureException(e);
                                    notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                                  }
                                });
                            });
                        } else {
                          updateDoc(productDoc.ref, { archived })
                            .then(() => notificationContext.setContext({ open: true, message }))
                            .catch((e) => {
                              Sentry.captureException(e);
                              notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                            });
                        }
                      }}
                    >
                      {
                        product.archived
                          ? (
                            <UnarchiveIcon />
                          ) : (
                            <ArchiveIcon />
                          )
                      }
                    </IconButton>
                  </span>
                </Tooltip>
              </Protected>

              <Protected allowedRoles={['admin', isOwner, 'design_manager']}>
                <Tooltip title="Permanently delete">
                  <span style={{ marginRight: theme.spacing(1) }}>
                    <IconButton
                      aria-label="permanently delete"
                      className={classes.editButton}
                      onClick={() => {
                        asyncRender(ConfirmDialog, {
                          title: 'Delete product',
                          content: 'Are you sure want to delete this product? This action cannot be undone.',
                          confirmText: 'Yes, Delete',
                        })
                          .then(() => {
                            router.push('/products');
                          })
                          .then(() => {
                            return deleteDoc(productDoc.ref);
                          })
                          .then(() => {
                            notificationContext.setContext({ open: true, message: 'Product deleted' });
                          })
                          .catch((e) => {
                            if (e) {
                              Sentry.captureException(e);
                              notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                            }
                          });
                      }}
                    >
                      <IconDelete />
                    </IconButton>
                  </span>
                </Tooltip>
              </Protected>

              {(product.published || checkRoles(['admin', 'customer_support', 'design_manager', 'designer', 'website_manager'], userContext.roles)) && (
                <Tooltip title="View on Guest House Marketplace">
                  <span style={{ marginRight: theme.spacing(1) }}>
                    <IconButton
                      className={classes.editButton}
                      onClick={() => window.open(makeStoreUrl(`/shop/products/${slugOrID(product)}`), '_blank')}
                    >
                      <IconOpenInNew />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {navigatorShareSupported && (
                <Tooltip title="Share this page">
                  <span style={{ marginRight: theme.spacing(1) }}>
                    <IconButton
                      size="small"
                      aria-label="share"
                      className={classes.editButton}
                      onClick={() => {
                        return navigator.share({
                          title: document.title,
                          url: makeAppUrl(generatePath(routes.product.path, { id })),
                        })
                          .catch(Sentry.captureException);
                      }}
                    >
                      <IconShare />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        className={classes.productInfo}
        spacing={4}
      >
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(3) }}
        >

          <List dense>
            {!!product.msrp && (
              <ListItem>
                <ListItemText
                  primary="MSRP"
                  secondary={formatCurrency(product.msrp, { format: '%s%v', symbol: '$' })}
                />
              </ListItem>
            )}

            {!!product.manufacturer_sku && (
              <ListItem>
                <ListItemText
                  primary="SKU"
                  secondary={<Nl2br text={product.manufacturer_sku} />}
                />
              </ListItem>
            )}

            {!!product.description && (
              <ListItem >
                <ListItemText
                  primary="Description"
                  secondary={
                    <div className={classes.scrollContainer}>
                      <Nl2br text={product.description} />
                    </div>
                  }
                />
              </ListItem>
            )}

            {Array.isArray(product.category) && product.category.length > 0 && (
              <ListItem>
                <ListItemText
                  primary="Category"
                  secondary={flatten([product.category, product.category2, product.category3].filter(Boolean)).map(s => properCase(s, '-', true)).join(', ')}
                />
              </ListItem>
            )}

            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              {product.vendor_url && (
                <ListItem>
                  <ListItemText
                    primary="Vendor URL"
                    secondary={
                      <Link
                        target="_blank"
                        href={product.vendor_url.startsWith('https://') || product.vendor_url.startsWith('http://') ?
                          product.vendor_url : 'https://' + product.vendor_url
                        }
                        rel="external"
                        style={{ wordWrap: 'break-word' }}
                      >
                        {product.vendor_url}
                      </Link>
                    }
                  />
                </ListItem>
              )}
            </Protected>

            {!!product.shippingPolicy && (
              <ListItem>
                <ListItemText
                  primary="Shipping Policy"
                  secondary={
                    <div className={classes.scrollContainer}>
                      <Nl2br text={product.shippingPolicy} />
                    </div>
                  }
                />
              </ListItem>
            )}

            {!!product.returnPolicy && (
              <ListItem>
                <ListItemText
                  primary="Return Policy"
                  secondary={
                    <div className={classes.scrollContainer}>
                      <Nl2br text={product.returnPolicy} />
                    </div>
                  }
                />
              </ListItem>
            )}

            {!!Object.keys(dimensions).length && (
              <ListItem>
                <ListItemText
                  primary="Dimensions"
                  secondary={Object.keys(dimensions).map(d => `${dimensions[d]} in ${String(d).toUpperCase()}`).join(' x ')}
                />
              </ListItem>
            )}

            {!!product.dimensions_visible?.length && (
              <ListItem>
                <ListItemText
                  primary="Dimensions (Visible on Store)"
                  secondary={`${product.dimensions_visible}`}
                />
              </ListItem>
            )}

            {!!product.weight && (
              <ListItem>
                <ListItemText
                  primary="Weight"
                  secondary={`${product.weight} lbs`}
                />
              </ListItem>
            )}

            <Protected allowedRoles={['admin', 'designer', isOwner, 'design_manager', 'website_manager', 'customer_support']}>
              <ListItem>
                <ListItemText
                  data-test="product-inventory"
                  primary="Inventory"
                  secondary={
                    <AvailableForStaging
                      inventory={inventoryExternal.length}
                      inventoryInternal={inventoryInternal.length}
                      style={{
                        marginTop: theme.spacing(.5),
                      }}
                    />
                  }
                />
              </ListItem>
            </Protected>

            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              {(typeof product.available === 'number') && (
                <ListItem>
                  <ListItemText
                    data-test="product-available"
                    primary="Available for Staging"
                    secondary={
                      <AvailableForStaging
                        inventory={availableExternal.length}
                        inventoryInternal={availableInternal.length}
                        style={{
                          marginTop: theme.spacing(.5),
                        }}
                      />
                    }
                  />
                </ListItem>
              )}
            </Protected>

            <Protected allowedRoles={['admin', 'designer', isOwner, 'design_manager', 'website_manager', 'customer_support']}>
              {!!Array.isArray(product.pastHomes) && !!product.pastHomes.length && (
                <ListItem>
                  <ListItemText
                    primary="Past Homes"
                    secondary={(
                      <>
                        {product.pastHomes
                          .filter(({ home }) => home.deinstall_date)
                          .sort((a, b) => sortHomesByDate(a.home, b.home, 'deinstall_date', 'asc'))
                          .map(({ home, room }, i) => {

                            if (!home?.docID && !room?.docID) {
                              return null;
                            }

                            return (
                              <div
                                key={home.docID + '__' + i}
                                style={{ display: 'flex', alignItems: 'center' }}
                              >
                                <RoomLink
                                  room={room}
                                  home={home}
                                />
                                {home.deinstall_date && (<Chip
                                  size="small"
                                  style={{ marginLeft: theme.spacing(1) }}
                                  label={toJsDate(home.deinstall_date).getTime() < Date.now() ? `Deinstalled on ${formatDate(home.deinstall_date)}` : `Deinstalling on ${formatDate(home.deinstall_date)}`}
                                />)}
                              </div>
                            );
                          })}
                      </>
                    )}
                  />
                </ListItem>
              )}
            </Protected>

            <Protected allowedRoles={['admin', 'designer', isOwner, 'design_manager', 'website_manager', 'customer_support']}>
              {!!Array.isArray(product.currentHomes) && !!product.currentHomes.length && (
                <ListItem>
                  <ListItemText
                    primary="Current Homes"
                    secondary={(
                      <>
                        {product.currentHomes.map(({ home, room }, i) => {

                          if (!home?.docID && !room?.docID) {
                            return null;
                          }

                          return (
                            <div key={home.docID + '__' + i}>
                              <RoomLink
                                room={room}
                                home={home}
                              />
                              {(home.install_date || home.deinstall_date) && (
                                <Chip
                                  size="small"
                                  style={{ marginLeft: theme.spacing(1) }}
                                  label={`${formatDate(home.install_date)} - ${formatDate(home.deinstall_date)}`}
                                />
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  />
                </ListItem>
              )}
            </Protected>

            <Protected allowedRoles={['admin', 'designer', isOwner, 'design_manager', 'website_manager', 'customer_support']}>
              {!!Array.isArray(product.futureHomes) && !!product.futureHomes.length && (
                <ListItem>
                  <ListItemText
                    primary="Future Homes"
                    secondary={(
                      <>
                        {product.futureHomes
                          .filter(({ home }) => home.install_date)
                          .sort((a, b) => sortHomesByDate(a.home, b.home, 'deinstall_date', 'asc'))
                          .map(({ home, room }, i) => {
                            if (!home?.docID && !room?.docID) {
                              return null;
                            }

                            return (
                              <div
                                key={home.docID + '__' + i}
                                style={{ display: 'flex', alignItems: 'center' }}
                              >
                                <RoomLink
                                  room={room}
                                  home={home}
                                />
                                {home.install_date && (<Chip
                                  size="small"
                                  style={{ marginLeft: theme.spacing(1) }}
                                  label={`Installing on ${formatDate(home.install_date)}`}
                                />)}
                              </div>
                            );
                          })}
                      </>
                    )}
                  />
                </ListItem>
              )}
            </Protected>
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          {product.owner?.docID && (
            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              <UserWidget
                userId={product.owner.docID}
              />
            </Protected>
          )}

          <Protected allowedRoles={[isOwner]}>
            {pendingRequests && pendingRequests.docs.map((doc, i) => {
              return (
                <ProductRequest
                  key={i}
                  doc={doc}
                />
              );
            })}
          </Protected>

          <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support', 'media_manager']}>
            <div style={{ marginTop: theme.spacing(3) }}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<IconPlus />}
                style={{ marginBottom: theme.spacing(2), marginLeft: 'auto', display: 'flex' }}
                onClick={() => {
                  asyncRender(NoteDialog)
                    .then((text: string) => {
                      if (text) {
                        const note: guesthouse.Note = {
                          created: Timestamp.fromDate(new Date()),
                          text,
                          owner: userContext.data,
                          docID: newId()
                        };

                        return addDoc(collection(database, `products/${product.docID}/notes`), note);
                      }
                    })
                    .catch((e) => {
                      if (e) {
                        Sentry.captureException(e);
                        notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                      }
                    });
                }}
              >
                Add note
              </Button>
              <Notes
                id={product.docID}
                collection="products"
              />
            </div>
          </Protected>
        </Grid>
      </Grid>
      <Protected allowedRoles={['maker', 'admin', 'designer', 'design_manager', 'website_manager', 'customer_support']} >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={md ? '' : 'space-between'}
          style={{ marginTop: theme.spacing(md ? 0 : 5), marginBottom: theme.spacing(3) }}
        >
          <Typography
            variant="h5Alt"
            style={{ marginRight: theme.spacing(2) }}
          >
            Offers
          </Typography>
          <Button
            data-test="product-add-offer"
            variant="contained"
            color="secondary"
            size="small"
            aria-label="add offer"
            startIcon={<IconPlus />}
            onClick={() => {
              const initialValues: Partial<guesthouse.ProductOffer> = {
                soldBy: product.owner,
                pickupOnly: false,
                price: product.msrp,
              };

              asyncRender(EditOfferDialog, { userContext, initialValues })
                .then(async (values) => {
                  const docID = newId();

                  const offer: guesthouse.ProductOffer = {
                    docID,
                    stockQuantity: 0,
                    ...values
                  };

                  if (offer?.dropship) {
                    try {
                      await updateDoc(doc(collection(database, 'products'), id), { dropshippable: true });
                    } catch (e) {
                      Sentry.captureException(e);
                    }
                  }

                  return setDoc(doc(database, `products/${id}/offers/${docID}`),
                    offer);
                })
                .then(() => notificationContext.setContext({ open: true, message: 'New offer added' }))
                .catch((e) => {
                  if (e) {
                    Sentry.captureException(e);
                    notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                  }
                });
            }}
          >
            Add offer
          </Button>
        </Box>
      </Protected>

      <Protected allowedRoles={['maker', 'admin', 'designer', 'design_manager', 'website_manager', 'customer_support']} >
        {
          offersCollection?.docs?.length
            ? (
              <OffersTable
                product={productDoc}
                offers={Array.from(offersCollection.docs)}
                style={{ marginBottom: theme.spacing(12) }}
                renderActions={(props) => {
                  return <ProductOfferActions {...props} />;
                }}
              />
            )
            : (
              <Typography style={{ marginBottom: theme.spacing(12) }}>
                This product has no available offers.
              </Typography>
            )
        }
      </Protected>

      <Protected allowedRoles={['maker', 'admin', 'designer', 'design_manager', 'website_manager', 'customer_support']} >
        <InventoryTable
          product={productDoc}
          inventory={inventoryCollection?.docs ? Array.from(inventoryCollection.docs) : []}
          style={{ marginBottom: theme.spacing(12) }}
          renderActions={(props) => {
            return (
              <Protected allowedRoles={[() => props.inventory.data().offer?.soldBy?.docID === userContext?.user?.uid, 'admin', 'designer', 'design_manager', 'website_manager', 'customer_support']} >
                <InventoryActions {...props} />
              </Protected>
            );
          }}
        />
      </Protected>


      <Typography
        gutterBottom
        variant="h5Alt"
      >
        Photos
      </Typography>
      <PhotoGrid
        allowPrimaryPhoto
        product={product}
        uploadRoles={[isOwner, 'admin', 'design_manager', 'designer', 'website_manager', 'customer_support']}
        collection={`products/${id}/photos`}
        allowPrimaryPhotoDelete={false}
        showProductTags={false}
      />

      <Typography
        gutterBottom
        style={{ marginTop: theme.spacing(12) }}
        variant="h5Alt"
      >
        Tagged Photos
      </Typography>
      <PhotoGrid
        allowPrimaryPhoto
        product={product}
        uploadRoles={[]}
        query={taggedPhotosQuery}
        documentPhotoPath="photo"
        openPhotoDialog={true}
        showProductTags={false}
        renderZeroMessage={() => {
          if (userContext?.roles?.maker) {
            return (
              <div style={{ marginBottom: theme.spacing(12) }}>
                <Typography>
                  This product hasn&apos;t been tagged in any photos yet, but we&apos;ll let you know as soon as photos are ready!
                </Typography>
              </div>
            );
          }

          return (
            <div style={{ marginBottom: theme.spacing(12) }}>
              <Typography>
                This product hasn&apos;t been tagged in any photos yet.
              </Typography>
            </div>
          );
        }}
      />

      <Protected allowedRoles={['admin', 'design_manager', 'website_manager']}>
        <Box
          display="flex"
          alignItems="center"
          style={{ marginTop: theme.spacing(8) }}
        >
          <span style={{ marginRight: theme.spacing(2) }}>
            <Typography
              component="h2"
              variant="h5Alt"
            >
              Web Previews
            </Typography>
          </span>
          <Tooltip title="Update social metadata tags for this product">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                asyncRender(MetadataDialog, { userContext, initialValues: product.metadata })
                  .then((metadata: guesthouse.MetaData) => updateDoc(productDoc.ref, { metadata }))
                  .catch((e) => {
                    if (e) {
                      Sentry.captureException(e);
                      notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                    }
                  });
              }
              }
            >
              Update metadata
            </Button>
          </Tooltip>
        </Box>
        <SocialPreviews
          metadata={productMetaData(product)}
          url={makeStoreUrl(`/shop/products/${slugOrID(product)}`)}
          author={userFullName(product.owner)}
        />
      </Protected>

    </div >
  );
};

export default React.memo(Product);
