import { DARK_CHARCOAL, DARK_ONYX, DARK_TEXT, GREEN_EXTRA_LIGHT } from '@ui/theme/theme';
import makeStyles from '@ui/utils/makeStyles';

export default makeStyles<{ swatchColor: string, isActive: boolean }>()((theme, { swatchColor, isActive }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    height: 101
  },
  cardButton: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    border: isActive ? `1px solid ${DARK_TEXT}` : '1px solid transparent',
    ['&:hover']: {
      backgroundColor: isActive ? GREEN_EXTRA_LIGHT : '#ebebeb !important',
      border: isActive ? `1px solid ${DARK_CHARCOAL}` : '1px solid #ebebeb',
      color: isActive ? DARK_CHARCOAL : DARK_TEXT,
      fill: isActive ? DARK_CHARCOAL : DARK_TEXT,
    }
  },
  containerContentOuter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between' 
  },
  containerContentInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-start'
  },
  unreadDot: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    display: 'inline-block',
    minWidth: theme.spacing(1),
    marginRight: theme.spacing(1.5),
    backgroundColor: swatchColor,
  },
  containerNameText: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'left',
    marginLeft: theme.spacing(1),
  },
  name: {
    lineHeight: 1.25,
    color: DARK_ONYX,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'pre-wrap',
    WebkitLineClamp: 3,
    lineHeight: 1.5,
    color: DARK_TEXT,
    width: '100%',
    height: 40,
  },
  avatar: {
    height: 36,
    width: 36,
    margin: theme.spacing(0, 1)
  },
  newMessage: {
    width: '100%',
    border: '1px solid black',
    borderRadius: 10,
    height: 56,
    padding: theme.spacing(0, 1, 0, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));
