export const STYLES: guesthouse.Style[] = [
  {
    id: 'scandinavian',
    title: 'Scandinavian',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Fstyles%2Fscandinavian-min.jpeg?alt=media',
    imagePosition: 'center 54%',
    imageContrastColor: '#ffffff'
  },
  {
    id: 'minimalist',
    title: 'Minimalist',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Fstyles%2Fminimalist-min.jpeg?alt=media',
    imagePosition: 'center 74%',
    imageContrastColor: '#ffffff'
  },
  {
    id: 'mid-century-modern',
    title: 'Mid-Century Modern',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Fstyles%2Fmidcentury-modern-min.jpeg?alt=media',
    imagePosition: 'center 62%',
    imageContrastColor: '#ffffff'
  },
  {
    id: 'industrial',
    title: 'Industrial',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Fstyles%2Findustrial-min.jpeg?alt=media',
    imagePosition: 'center 58%',
    imageContrastColor: '#ffffff'
  },
  {
    id: 'contemporary',
    title: 'Contemporary',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Fcategories%2Fcontemporary.jpeg?alt=media',
    imagePosition: 'center 70%',
    imageContrastColor: '#ffffff'
  },
  {
    id: 'bohemian',
    title: 'Bohemian',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Fstyles%2Fbohemian-min.jpeg?alt=media',
    imagePosition: 'center 94%',
    imageContrastColor: '#ffffff'
  },
  {
    id: 'coastal',
    title: 'Coastal',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Fstyles%2Fcoastal2-min.jpeg?alt=media',
    imagePosition: 'center 70%',
    imageContrastColor: '#ffffff'
  },
  {
    id: 'global',
    title: 'Global',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Fstyles%2Fglobal-min.jpeg?alt=media',
    imagePosition: 'center 58%',
    imageContrastColor: '#ffffff'
  },
  {
    id: 'traditional',
    title: 'Traditional',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Fstyles%2Ftraditional-min.jpeg?alt=media',
    imagePosition: 'center 73%',
    imageContrastColor: '#ffffff'
  }
];
