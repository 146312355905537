import Typography from '@mui/material/Typography';
import addDays from 'date-fns/addDays';
import formatCurrency from 'format-currency';
import React from 'react';

import filterSortLineItems from '../../utils/filterSortLineItems';
import formatDate from '../../utils/formatDate';
import formatDiscount from '../../utils/formatDiscount';
import toJsDate from '../../utils/toJsDate';
import LineItem from '../line-item/LineItem';
import StagingLineItems from './StagingLineItems';
import useStyles from './StagingQuote.style';

type StagingQuoteProps = {
  className?: string;
  style?: React.CSSProperties;
  deal?: Partial<HubSpot.Deal>;
  addons?: Partial<HubSpot.LineItemProperties>[];
  lineItems?: Partial<HubSpot.LineItem>[];
  total?: number;
  totalLabel?: string;
  subtotal?: number;
  couponDiscountTotal?: number;
  appliedPromo?: guesthouse.Coupon;
  dealDiscount?: number;
  dealDiscountMinimum?: number;
  dealDiscountTotal?: number;
  dealDiscountEligible?: boolean;
}

const StagingQuote: React.FC<StagingQuoteProps> = (props) => {
  const { classes, cx, theme } = useStyles();
  const {
    className,
    style,
    deal,
    lineItems,
    totalLabel,
    total,
    addons,
    subtotal,
    couponDiscountTotal,
    appliedPromo,
    dealDiscount,
    dealDiscountMinimum,
    dealDiscountTotal,
    dealDiscountEligible,
  } = props;

  const stagingLineItems = (lineItems || [])
    .filter(li => li.properties?.hs_sku?.startsWith('STA') || li.properties?.hs_sku === 'BASE');

  const nonStagingLineItems = lineItems?.filter(li => !stagingLineItems.find(l => l === li));
  const stagingTerm = deal?.properties?.staging_term ? Number(deal.properties.staging_term) : 30;

  return (
    <ul
      className={cx(classes.itemizedList, className)}
      style={style}
    >
      {deal &&
        <li style={{ marginBottom: theme.spacing(1), display: 'flex', justifyContent: 'space-between' }}>
          <Typography style={{ fontSize: 18, lineHeight: 1.25, fontWeight: 600, fontFamily: theme.gh_vars.circular }}>
            Staging package
          </Typography>
        </li>
      }

      {filterSortLineItems(stagingLineItems || []).map((lineItem, i) => {
        return (
          <LineItem
            key={i}
            style={{ padding: theme.spacing(1, 0) }}
            showIcon={false}
            lineItem={lineItem.properties}
            renderButton={() => {
              return (
                <Typography variant="body2">
                  x
                  {lineItem.properties.quantity || '1'}
                </Typography>
              );
            }}
          />
        );

      })}

      {(addons?.length > 0 || nonStagingLineItems?.length > 0) ?
        <div style={{ marginTop: theme.spacing(4.5) }}>
          <Typography style={{ fontSize: 18, lineHeight: 1.25, fontWeight: 600, fontFamily: theme.gh_vars.circular }}>
            Add-ons
          </Typography>

          <StagingLineItems
            lineItems={nonStagingLineItems}
            addons={addons}
          />
        </div>
        : null
      }

      {!!subtotal && Boolean(!!couponDiscountTotal || !!dealDiscountTotal) &&
        <li className={classes.discountWrapper}>
          <Typography
            fontFamily={theme.gh_vars.circular}
            style={{ fontSize: 18, fontWeight: 600 }}
          >
            Subtotal
          </Typography>

          <Typography className={classes.discountCurrencyText}>
            {formatCurrency(Number(subtotal), { format: '%s%v', symbol: '$' })}
          </Typography>
        </li>
      }

      {!!couponDiscountTotal &&
        <li className={classes.discountWrapper}>
          <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography
              fontFamily={theme.gh_vars.circular}
              style={{ fontSize: 18, fontWeight: 600, marginRight: theme.spacing(1) }}
            >
              Discounts
            </Typography>

            <Typography
              style={{ color: '#83A5B0', fontFamily: theme.gh_vars.mabryMono, fontSize: 11 }}
            >
              {
                appliedPromo?.description
                  ? appliedPromo?.description : (
                    <span>
                      {formatDiscount(appliedPromo?.staging_discount_amount, appliedPromo?.staging_discount_type)}
                      {' '}
                      off your order
                    </span>
                  )
              }
            </Typography>
          </div>
          <Typography className={classes.discountCurrencyText}>
            -
            {formatCurrency(Number(couponDiscountTotal), { format: '%s%v', symbol: '$' })}
          </Typography>
        </li>
      }

      {!!dealDiscount && dealDiscountEligible && dealDiscountMinimum > 0 &&
        <li className={classes.discountWrapper}>
          <Typography
            fontFamily={theme.gh_vars.circular}
            style={{ fontSize: 18, fontWeight: 600, marginRight: theme.spacing(1) }}
          >
            Deal discount
          </Typography>

          <Typography className={classes.discountCurrencyText}>
            -
            {formatCurrency(Number(dealDiscountTotal), { format: '%s%v', symbol: '$' })}
          </Typography>
        </li>
      }

      <li
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: theme.spacing(0),
          padding: theme.spacing(1, 0, 1, 0),
          marginTop: theme.spacing(2)
        }}
      >
        <Typography
          fontFamily={theme.gh_vars.circular}
          style={{ fontSize: 18, fontWeight: 600 }}
        >
          {totalLabel}
        </Typography>

        <Typography style={{ fontSize: 18, fontWeight: 600 }}>
          {formatCurrency(Number(total), { format: '%s%v', symbol: '$' })}
        </Typography>
      </li>

      {deal?.properties?.staging_date && (
        <LineItem
          style={{
            padding: theme.spacing(1, 0)
          }}
          showIcon={false}
          lineItem={{
            name: `15-day renewal starts on ${formatDate(addDays(toJsDate(deal?.properties?.staging_date), stagingTerm), 'P')}`,
            price: deal?.properties?.amount_fifteen_day_interval
          }}
          renderButton={() => {
            return (
              <Typography
                variant="body2"
              >
                {formatCurrency(deal?.properties?.amount_fifteen_day_interval, { format: '%s%v', symbol: '$' })}
              </Typography>
            );
          }}
        />
      )}

    </ul>
  );
};

StagingQuote.defaultProps = {
  totalLabel: 'Total due today: '
};

export default StagingQuote;
