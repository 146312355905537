import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { A11y, Mousewheel, Navigation, Pagination, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GlobalStyles } from 'tss-react/GlobalStyles';

import useBreakpoints from '../../hooks/useBreakpoints';
import useRefRect from '../../hooks/useRefRect';
import PhotoDots from '../photo-dots/PhotoDots';
import useStyles from './PhotoLightBox.styles';

export type PhotoLightboxSidebarProps = {
  photo?: guesthouse.Photo;
}

export type PhotoLightboxImageProps = {
  photo?: guesthouse.Photo;
}


type PhotoLightBoxProps = {
  photos: guesthouse.Photo[];
  selectedPhotoID: string;
  setSelectedPhotoID: Dispatch<SetStateAction<string>>;
  renderInteractions?: React.FC<PhotoLightBoxProps>;
  renderSidebar?: React.FC<PhotoLightboxSidebarProps>;
  renderImage?: React.FC<PhotoLightboxImageProps>;
}

const PhotoLightBox: React.FC<PhotoLightBoxProps> = (props) => {
  const { photos, selectedPhotoID, setSelectedPhotoID, renderInteractions, renderSidebar, renderImage } = props;
  const [open, setOpen] = useState<boolean>(false);
  const { classes, cx } = useStyles({ renderSidebar: typeof renderSidebar === 'function' });
  const { lg, md } = useBreakpoints();

  const collapseRef = useRef(null);
  const childRef = useRef(null);


  const { height: collapseHeight } = useRefRect(collapseRef?.current);
  const { height: childHeight } = useRefRect(childRef?.current);

  const canExpand = useMemo(() => childHeight > collapseHeight, [collapseHeight, childHeight]);

  useEffect(() => {
    if (open) {
      return;
    }

    if (!canExpand) {
      setOpen(false);
    }
  }, [canExpand]);

  const clickCaretSwitch = () => {
    setOpen(!open);
  };

  const currIndex = useMemo(() => photos.indexOf(photos.find(photo => photo?.docID === selectedPhotoID)), [selectedPhotoID, photos]);

  const sideBarWrapper = typeof renderSidebar === 'function' ? (
    <div
      ref={childRef}
      style={{ width: '100%' }}
    >
      {
        renderSidebar({
          photo: photos[currIndex],
        })
      }
    </div>
  ) : null;

  return (
    <>
      <GlobalStyles
        styles={{
          '#hubspot-messages-iframe-container': {
            zIndex: 1,
          },
          '.swiper-pagination-progressbar-fill.swiper-pagination-progressbar-fill': {
            background: 'white !important',
          },
          '.swiper-pagination-progressbar': {
            background: 'rgba(255, 255, 255, .25) !important',
          },
          '.swiper-virtual': {
            height: '100%',
          },
          '.swiper-pagination.swiper-pagination': {
            bottom: '0 !important'
          },
          '.swiper-button-next': {
            display: 'flex !important',
            borderRadius: '50% !important',
            background: 'rgba(0, 0, 0, .5) !important',
            border: '2px solid white !important'
          },
          '.swiper-button-next:after': {
            color: 'white !important',
          },
          '.swiper-button-prev': {
            display: 'flex !important',
            borderRadius: '50% !important',
            background: 'rgba(0, 0, 0, .5) !important',
            border: '2px solid white !important'
          },
          '.swiper-button-prev:after': {
            color: 'white !important',
          }
        }}
      />
      <Box
        className={classes.root}
      >
        <Box className={classes.photoSectionContainer}>

          <Box className={classes.interactionsContainer}>
            {typeof renderInteractions === 'function' && renderInteractions(props)}
          </Box>
          <Box className={classes.photoOuterContainer}>
            {
              !!photos.length && (
                <Swiper
                  threshold={md ? 250 : 0}
                  virtual={{
                    addSlidesAfter: 2,
                    addSlidesBefore: 2,
                    enabled: true,
                  }}
                  initialSlide={currIndex}
                  style={{
                    height: '100%',
                    maxHeight: 'calc(100vh - 100px)',
                  }}
                  modules={[Virtual, Pagination, Navigation, A11y, Mousewheel]}
                  speed={lg ? 550 : 400}
                  slidesPerView={1}
                  spaceBetween={24}
                  observer={true}
                  observeParents={true}
                  mousewheel={{
                    forceToAxis: true,
                  }}
                  navigation={lg ? true : false}
                  onSlideChange={(swiper) => {
                    const photo = photos[swiper.activeIndex];

                    setSelectedPhotoID(photo?.docID);
                  }}
                >
                  {photos.map((photo, i) => {

                    const height = 500;
                    const width = 500;

                    return (
                      <SwiperSlide
                        key={photo.docID}
                        virtualIndex={i}
                        style={{ width, height }}
                      >
                        {
                          typeof renderImage === 'function'
                            ? renderImage({ photo })
                            : (
                              <img
                                src={photo.large_url}
                                style={{
                                  height: '100%',
                                  width: '100%',
                                  objectFit: 'contain',
                                }}
                              />
                            )
                        }
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
          </Box>
          <PhotoDots
            totalDots={photos.length}
            selectedDotIndex={currIndex}
            className={classes.photoDots}
          />
        </Box>

        {
          typeof renderSidebar === 'function' ? (
            <Box
              className={classes.tagProductsContainer}
              style={{
                backgroundColor: '#F7F7F7',
              }}
            >
              <Box
                className={classes.tagProductsInner}
              >
                <Box
                  className={classes.infoContainer}
                >
                  {
                    (canExpand || open) && (
                      <IconButton
                        aria-label="expand-products"
                        className={classes.caretToggle}
                        size="small"
                        onClick={clickCaretSwitch}
                      >
                        <ExpandMoreIcon
                          className={cx({
                            [classes.caretToggleIcon]: true,
                            [classes.caretToggleIconExpanded]: open,
                          })}
                        />
                      </IconButton>
                    )
                  }
                </Box>

                {
                  lg ? sideBarWrapper : (
                    <Collapse
                      ref={collapseRef}
                      style={{
                        width: '100%',
                      }}
                      timeout="auto"
                      collapsedSize={150}
                      in={open}
                    >
                      {sideBarWrapper}
                    </Collapse>
                  )
                }
              </Box>
            </Box>
          )
            : null
        }
      </Box>
    </>
  );
};

export default PhotoLightBox;
