import userFullName from '@ui/utils/userFullName';
import formatCurrency from 'format-currency';

import properCase from './properCase';

export default function makeOfferLabel(offer: guesthouse.ProductOffer): string {
  let label;

  if (offer?.dropship) {
    label = formatCurrency(offer.price, { format: '%s%v', symbol: '$' }) + ' (Dropship)';
  } else {
    label = formatCurrency(offer.price, { format: '%s%v', symbol: '$' });
  }

  if (offer.sale && offer.salePrice) {
    label = `${formatCurrency(offer.salePrice, { format: '%s%v', symbol: '$' })}, was ${label}`;
  }

  if (offer.soldBy) {
    label += ` - Sold by ${userFullName(offer.soldBy, true)}`;
  }

  label += ` - ${properCase(offer.type)}, ${properCase(offer.condition)} Condition`;

  return label;
}
