import * as Yup from 'yup';

const MetadataSchema = Yup.object().shape({
  pageTitle: Yup.string(),
  metaTitle: Yup.string(),
  metaDescription: Yup.string(),
});

export const initialValues: guesthouse.MetaData = {
  pageTitle: '',
  metaTitle: '',
  metaDescription: '',
};

export default MetadataSchema;
