import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import NoteDialog from '@components/dialogs/NoteDialog';
import PublishMakerDialog from '@components/dialogs/PublishMakerDialog';
import UnpublishMakerDialog from '@components/dialogs/UnpublishMakerDialog';
import EmailHistory from '@components/email-history/EmailHistory';
import Error from '@components/error/Error';
import HomeGrid from '@components/home-grid/HomeGrid';
import Notes from '@components/notes/Notes';
import PaginationController from '@components/pagination-controller/PaginationController';
import ProductGrid from '@components/product-grid/ProductGrid';
import SocialPreviews from '@components/social-previews/SocialPreviews';
import UserWidget from '@components/user-widget/UserWidget';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import useChatMembersHandler from '@hooks/useChatMembersHandler';
import useDocumentBySlugOrID from '@hooks/useDocumentBySlugOrID';
import useEditUserHandler from '@hooks/useEditUserHandler';
import usePaginatedFirebaseQuery from '@hooks/usePaginatedFirebaseQuery';
import useRouter from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import IconPlus from '@mui/icons-material/Add';
import IconEdit from '@mui/icons-material/Edit';
import IconOpenInNew from '@mui/icons-material/OpenInNew';
import IconStar from '@mui/icons-material/Star';
import IconStarBorder from '@mui/icons-material/StarBorder';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import MetadataDialog from '@pages/metadata/MetadataDialog';
import useCommonStyles from '@styles/common.style';
import Protected from '@ui/components/protected/Protected';
import makeStoreUrl from '@ui/utils/makeStoreUrl';
import makerMetaData from '@ui/utils/metadata/makerMetaData';
import slugOrID from '@ui/utils/slugOrID';
import userFullName from '@ui/utils/userFullName';
import newId from '@utils/newId';
import { homePath, productPath, userPath } from '@utils/paths';
import { addDoc, collection, doc, query, Timestamp, updateDoc, where, writeBatch } from 'firebase/firestore';
import React, { useContext, useEffect,useMemo,useState } from 'react';

import useStyles from './User.style';

interface UserParams {
  id: string;
}

const User = () => {
  const { classes } = useStyles();
  const { classes: common } = useCommonStyles();
  const router = useRouter<UserParams>();
  const { theme } = useStyles();
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const userContext = useContext<UserContext>(UserContext);
  const editUserHandler = useEditUserHandler();
  const { setChannel } = useChatMembersHandler();
  const { id: slug } = router.params;
  const { id, loading, doc: user, exists: userDocExists } = useDocumentBySlugOrID<guesthouse.User>(slug, 'users');
  const [homeList, setHomeList] = useState<guesthouse.Home[]>();
  const [productList, setProductList] = useState<guesthouse.Product[]>();

  const productQuery = useMemo(() => query(collection(database, 'products'), where('owner.docID', '==', id)), [id]);
  const homesQuery = useMemo(() => query(collection(database, 'homes'), where('members', 'array-contains', id)), [id]);

  const { 
    collection: productDocs, 
    loading: productsLoading,
    page: productsPage,
    setPage: setProductsPage,
    setFetchAction: setProductsFetchAction
  } = usePaginatedFirebaseQuery(
    productQuery,
    10
  );

  const { 
    collection: homeDocs, 
    loading: homesLoading,
    page: homesPage,
    setPage: setHomesPage,
    setFetchAction: setHomesFetchAction
  } = usePaginatedFirebaseQuery(
    homesQuery,
    10
  );

  useEffect(() => {
    if (homeDocs?.docs.length) {
      const homesArray = [];

      for (const doc of homeDocs.docs) {
        homesArray.push(doc.data());
      }

      setHomeList(homesArray);
    }
  }, [homeDocs]);

  useEffect(() => {
    if (productDocs?.docs.length) {
      const productsArray = [];

      for (const doc of productDocs.docs) {
        productsArray.push(doc.data());
      }

      setProductList(productsArray);
    }
  }, [productDocs]);

  // TODO proper error and skeleton
  if (loading) {
    return null;
  }

  if (!userDocExists) {
    return <Error statusCode={'404'} />;
  }


  const userData = user.data() as guesthouse.User;
  const isMakerRole = userData.roles.maker;

  return (
    <div className={common.contentSpacing}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Typography
              gutterBottom
              component="h1"
              variant="h3Alt"
            >
              {userFullName(userData, true)}
            </Typography>

            <Box
              display="flex"
              alignItems="center"
            >
              {isMakerRole &&
                <Protected allowedRoles={['admin', 'design_manager']}>
                  <Tooltip title={userData.featured ? 'Remove user featured status' : 'Set user as featured'}>
                    <span style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(1) }}>
                      <IconButton
                        aria-label={userData.featured ? 'Remove user featured status' : 'Set user as featured'}
                        onClick={(e) => {
                          e.stopPropagation();

                          if (userData.featured) {
                            updateDoc(user.ref, { featured: false });
                            notificationContext.setContext({ open: true, message: 'Maker is no longer featured' });
                          } else {
                            updateDoc(user.ref, { featured: true });
                            notificationContext.setContext({ open: true, message: 'Maker is now featured' });
                          }
                        }}
                      >
                        {
                          userData.featured
                            ? (
                              <IconStar />
                            ) : (
                              <IconStarBorder />
                            )
                        }
                      </IconButton>
                    </span>
                  </Tooltip>
                </Protected>
              }

              {isMakerRole &&
                <Protected allowedRoles={['admin', 'design_manager']}>
                  <Tooltip title="Verified users can publish their own products on the store">
                    <FormControlLabel
                      style={{ marginRight: theme.spacing(1) }}
                      control={
                        <Switch
                          checked={userData.verified}
                          value={userData.verified}
                          name="verified"
                          color="primary"
                          aria-label={
                            userData.verified
                              ? 'Set verified status'
                              : 'Verified status removed'
                          }
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const verified = event.target.checked;
                            const message = verified
                              ? 'Set verified status'
                              : 'Verified status removed';

                            updateDoc(user.ref, { verified })
                              .then(() => notificationContext.setContext({ open: true, message }))
                              .catch((e) => {
                                Sentry.captureException(e);
                                notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                              });
                          }}
                        />
                      }
                      label="Verified maker"
                    />
                  </Tooltip>
                </Protected>
              }

              {isMakerRole &&
                <Protected allowedRoles={['admin', 'design_manager', 'website_manager']}>
                  <Tooltip title={userData.published ? 'This user is published to the store' : 'This user is not live on the store yet'}>
                    <FormControlLabel
                      style={{ marginRight: theme.spacing(2) }}
                      control={
                        <Switch
                          aria-label={userData.published ? 'This user is published to the store' : 'This user is not live on the store yet'}
                          checked={userData.published}
                          value={userData.published}
                          name="verified"
                          color="primary"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const published = event.target.checked;
                            const message = published
                              ? 'Maker published'
                              : 'Maker unpublished';

                            if (userData.published) {
                              asyncRender(UnpublishMakerDialog)
                                .then(() => {
                                  updateDoc(user.ref, { published });
                                })
                                .then(() => {
                                  const batch = writeBatch(database);

                                  productList.map(product => {
                                    const productRef = doc(collection(database, 'products'), product.docID);

                                    batch.update(productRef, { published });
                                  });
                                  return batch;
                                })
                                .then(batch => batch.commit())
                                .then(() => {
                                  notificationContext.setContext({ open: true, message });
                                })
                                .catch((e) => {
                                  if (e) {
                                    Sentry.captureException(e);
                                    notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                                  }
                                });
                            } else {
                              updateDoc(user.ref, { published })
                                .then(() => {
                                  notificationContext.setContext({ open: true, message });
                                });
                              asyncRender(PublishMakerDialog)
                                .then(() => {
                                  const batch = writeBatch(database);

                                  productList.map(product => {
                                    const productRef = doc(collection(database, 'products'), product.docID);

                                    batch.update(productRef, { published });
                                  });
                                  return batch;
                                })
                                .then(batch => batch.commit())
                                .catch((e) => {
                                  if (e) {
                                    Sentry.captureException(e);
                                    notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                                  }
                                });
                            }
                          }
                          }
                        />
                      }
                      label={userData.published ? 'Published' : 'Unpublished'}
                    />
                  </Tooltip>
                </Protected>
              }

              <Protected allowedRoles={['admin', 'design_manager']}>
                <Tooltip title="Edit">
                  <span style={{ marginRight: theme.spacing(1) }}>
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        editUserHandler(id).then((updatedUser) => {
                          if (slugOrID(updatedUser) !== slugOrID(userData)) {
                            router.replace(userPath(updatedUser));
                          }
                        });
                      }}
                    >
                      <IconEdit />
                    </IconButton>
                  </span>
                </Tooltip>
              </Protected>

              {userData.published && (
                <Tooltip title="View on Guest House marketplace">
                  <span style={{ marginRight: theme.spacing(0) }}>
                    <Button
                      aria-label="View on Guest House marketplace"
                      variant="text"
                      onClick={() => window.open(makeStoreUrl(`/shop/makers/${userData.slug || id}`), '_blank')}
                    >
                      <IconOpenInNew />
                    </Button>

                  </span>
                </Tooltip>
              )}

            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >

        </Grid>
      </Grid>

      <Grid
        container
        className={classes.makerInfo}
        spacing={4}
      >
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <UserWidget
            userId={id}
            showCompany={!userData.company}
            showName={!!userData.company}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >

          <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support', 'media_manager']}>
            <div style={{ marginTop: theme.spacing(3) }}>
              {userData.docID !== userContext.data.docID && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  style={{
                    minWidth: 87,
                    marginBottom: theme.spacing(2),
                    marginLeft: 'auto',
                    display: 'flex'
                  }}
                  onClick={() => setChannel([userData.docID, userContext.data.docID])}
                >
                  Message User
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<IconPlus />}
                style={{ marginBottom: theme.spacing(2), marginLeft: 'auto', display: 'flex' }}
                onClick={() => {
                  asyncRender(NoteDialog)
                    .then(async (text: string) => {
                      if (text) {
                        const note: guesthouse.Note = {
                          created: Timestamp.fromDate(new Date()),
                          text,
                          owner: userContext.data,
                          docID: newId()
                        };

                        await addDoc(collection(database, `users/${userData.docID}/notes`), note);
                      }
                    })
                    .catch((e) => {
                      if (e) {
                        Sentry.captureException(e);
                        notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                      }
                    });
                }}
              >
                Add note
              </Button>
              <Notes
                id={userData.docID}
                collection="users"
              />
            </div>
          </Protected>
        </Grid>
      </Grid>

      {isMakerRole &&
        <>
          <Typography
            gutterBottom
            component="h2"
            variant="h5Alt"
          >
            Products
          </Typography>
          {productList?.length
            ? (
              <>
                <PaginationController
                  page={productsPage}
                  setPage={setProductsPage}
                  setFetchAction={setProductsFetchAction}
                  loading={productsLoading}
                />
                <ProductGrid
                  products={productList}
                  href={productPath}
                />
              </>
            ) : (
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {userData.firstname}
                {' '}
                hasn&apos;t added any products yet.
              </Typography>
            )
          }
        </>
      }

      <>
        <Typography
          gutterBottom
          component="h2"
          variant="h5Alt"
        >
          Homes
        </Typography>
        { homeList?.length
          ? (
            <>
              <PaginationController
                page={homesPage}
                setPage={setHomesPage}
                setFetchAction={setHomesFetchAction}
                loading={homesLoading}
              />
              <HomeGrid
                homes={homeList}
                href={homePath}
              />
            </>
          ) : (
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {userData.firstname}
              {' '}
              isn&apos;t a member of any homes yet.
            </Typography>
          )
        }
      </>


      <Protected allowedRoles={['admin', 'design_manager', 'website_manager']}>
        <Box
          display="flex"
          alignItems="center"
          style={{ marginTop: theme.spacing(8) }}
        >
          <span style={{ marginRight: theme.spacing(2) }}>
            <Typography
              component="h2"
              variant="h5Alt"
            >
              Web Previews
            </Typography>
          </span>
          <Tooltip title="Update social metadata tags for this user">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                asyncRender(MetadataDialog, { userContext, initialValues: userData.metadata })
                  .then((metadata: guesthouse.MetaData) => updateDoc(user.ref, { metadata }))
                  .catch((e) => {
                    if (e) {
                      Sentry.captureException(e);
                      notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                    }
                  });
              }
              }
            >
              Update metadata
            </Button>
          </Tooltip>
        </Box>
        <SocialPreviews
          metadata={makerMetaData(userData)}
          url={makeStoreUrl(`/shop/makers/${slugOrID(userData)}`)}
        />
      </Protected>

      <Protected allowedRoles={['admin', 'design_manager', 'customer_support']}>
        <Box
          display="flex"
          alignItems="center"
          style={{ marginTop: theme.spacing(8), marginBottom: theme.spacing(2) }}
        >
          <Typography
            component="h2"
            variant="h5Alt"
          >
            Email History
          </Typography>
        </Box>
        <EmailHistory
          userID={id}
          style={{ marginBottom: theme.spacing(10) }}
        />
      </Protected>
    </div>
  );
};

export default React.memo(User);
