// ! When new types are added, be sure to add to the Room.d.ts RoomTypes
export const ROOM_TYPES: guesthouse.RoomType[] = [
  {
    id: 'living-room',
    title: 'Living Room',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Froom-types%2Flivingroom-min.jpeg?alt=media&token=1d16d754-56a5-48b9-a8ff-bfc3a3eee484',
    imagePosition: '50% 100%',
    imageContrastColor: '#ffffff',
    metaTitle: 'Modern Living Room Furniture, Décor and Design',
    metaDescription: 'Anything you see in a Guest House home staging is also something you can buy. Shop our modern living room design collection online.'
  },
  {
    id: 'dining-room',
    title: 'Dining Room',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Froom-types%2Fdining-min.jpeg?alt=media&token=aa366e6b-ed7d-4a6b-851a-c72eb7f2fc6c',
    imagePosition: '50% 50%',
    imageContrastColor: '#ffffff'
  },
  {
    id: 'office',
    title: 'Office',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Froom-types%2Foffice-min.jpeg?alt=media&token=aca5c807-51cf-4354-8020-b157b18ea42e',
    imagePosition: '50% 50%',
    imageContrastColor: '#ffffff',
    metaTitle: 'Home Office Furniture and Décor',
    metaDescription: 'If your work from home space needs customization, have a look at the home office furniture and décor on the site.'
  },
  {
    id: 'bedroom',
    title: 'Bedroom',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Froom-types%2Fbedroom-min.jpeg?alt=media&token=1e9720f4-71e7-4c7e-9750-5d9a1cc5f2b1',
    imagePosition: '50% 50%',
    imageContrastColor: '#ffffff'
  },
  {
    id: 'entryway',
    title: 'Entryway',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Froom-types%2Fentryway-min.jpg?alt=media&token=554f7e3f-7443-4511-be50-f436df8cee8d',
    imagePosition: '50% 50%',
    imageContrastColor: '#ffffff'
  },
  {
    id: 'bathroom',
    title: 'Bathroom',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Froom-types%2Fbathroom-min.jpeg?alt=media&token=3bb1991a-bc33-4960-a356-c172506ac544',
    imagePosition: '50% 50%',
    imageContrastColor: '#ffffff'
  },
  {
    id: 'kitchen',
    title: 'Kitchen',
    image: 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Froom-types%2Fkitchen-min.jpeg?alt=media&token=b8455ea9-dad9-45c8-81e2-de422d10e8c1',
    imagePosition: '50% 50%',
    imageContrastColor: '#ffffff'
  },
  {
    id: 'large-patio',
    title: 'Large Patio',
    image: '',
    imagePosition: '50% 50%',
    imageContrastColor: '#ffffff'
  },
  {
    id: 'small-patio',
    title: 'Small Patio',
    image: '',
    imagePosition: '50% 50%',
    imageContrastColor: '#ffffff'
  }
];
