import React, { SVGProps } from 'react';

const IconUserBox: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.333 12.667l.461-.384a.6.6 0 00-.46-.216v.6zm1.667 2l-.46.384a.6.6 0 00.921 0l-.46-.384zm1.667-2v-.6a.6.6 0 00-.461.216l.46.384zm-5-10.734h6.666v-1.2H2.667v1.2zm8.066 1.4v7.334h1.2V3.333h-1.2zm-9.466 7.334V3.333h-1.2v7.334h1.2zm3.066 1.4H2.667v1.2h1.666v-1.2zm2.128 2.216l-1.667-2-.922.768 1.667 2 .922-.768zm2.872-2.216H7.667v1.2h1.666v-1.2zm-2.127.216l-1.667 2 .922.768 1.667-2-.922-.768zm-7.14-1.616a2.6 2.6 0 002.6 2.6v-1.2a1.4 1.4 0 01-1.4-1.4h-1.2zm10.667 0a1.4 1.4 0 01-1.4 1.4v1.2a2.6 2.6 0 002.6-2.6h-1.2zm-1.4-8.734a1.4 1.4 0 011.4 1.4h1.2a2.6 2.6 0 00-2.6-2.6v1.2zM2.667.733a2.6 2.6 0 00-2.6 2.6h1.2a1.4 1.4 0 011.4-1.4v-1.2zM6.881 5.466A.874.874 0 016 6.332v1.2c1.144 0 2.081-.919 2.081-2.066h-1.2zM6 6.332a.874.874 0 01-.882-.866h-1.2c0 1.147.938 2.066 2.082 2.066v-1.2zm-.882-.866c0-.472.39-.866.882-.866V3.4c-1.144 0-2.082.92-2.082 2.066h1.2zM6 4.6c.493 0 .881.394.881.866h1.2A2.074 2.074 0 006 3.4v1.2zm-2.092 5.905C4.195 9.546 5.038 8.881 6 8.881v-1.2c-1.54 0-2.816 1.06-3.242 2.48l1.15.344zM6 8.881c.963 0 1.805.665 2.092 1.624l1.15-.344C8.815 8.741 7.54 7.681 6 7.681v1.2z"
        fill="#080808"
      />
    </svg>
  );
};


export default IconUserBox;
