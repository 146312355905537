import { useMemo } from 'react';


export default function useNavigatorShareSupported() {
  return useMemo(() => {
    if (typeof window !== 'undefined') {
      return navigator?.share;
    }
  }, [typeof window !== 'undefined' && navigator?.share]);
}
