import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  roomGrid: {},
  roomGridItem: {},
  roomPaper: {
    position: 'relative',
    overflow: 'hidden',
    height: 300,
    background: theme.palette.common.white,
    width: '100%',
    minHeight: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  roomContent: {
    minWidth: '100%',
    minHeight: '100%',
    padding: theme.spacing(2),
    position: 'relative',
  },
  action: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    marginTop: theme.spacing(2),
  },
  taggedPhotoChipWrapper: {
    padding: '0 8px 0 8px',
    height: 24,
    textDecoration: 'none',
    position: 'relative',
    borderRadius: 5,
  },
  taggedPhotoChipText: {
    fontFamily: theme.gh_vars.mabryMono,
    fontSize: 11,
    color: theme.palette.common.black,
    paddingBottom: 2,
    whiteSpace: 'nowrap'
  },
  publishedChipWrapper: {
    maxWidth: 80,
    padding: '0 8px 0 8px',
    height: 24,
    textDecoration: 'none',
    position: 'relative',
    borderRadius: 5,
    marginTop: theme.spacing(1),
    alignSelf: 'flex-end'
  },
  publishedChipText: {
    fontFamily: theme.gh_vars.mabryMono,
    fontSize: 11,
    color: theme.palette.common.black,
    paddingBottom: 2,
  },
}));
