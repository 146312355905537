import makeStyles from '../../utils/makeStyles';

export default makeStyles()((theme) => ({
  orderContainer: {
    marginBottom: theme.spacing(1),
  },
  orderTitle: {
    marginBottom: theme.spacing(.5),
    fontSize: 18,
  },
  orderSubtitle: {
    marginBottom: theme.spacing(.5),
    fontSize: 14,
  },
  itemizedList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  itemizedListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(0),
    padding: theme.spacing(1, 0, 1, 0),
    borderBottom: '1px dashed #979797',
    '&:last-of-type': {
      borderBottom: 0,
    }
  },
  itemSubTotal: {
    fontWeight: 500,
    fontSize: 14,
  },
  itemSubTotalDollar: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: theme.gh_vars.mabryMono,
  },
  itemTotalTitle: {
    fontWeight: 700,
    fontSize: 16,
  },
  itemDollarTotal: {
    fontWeight: 700,
    fontSize: 16,
    fontFamily: theme.gh_vars.mabryMono,
  },
  saleListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(0),
    padding: theme.spacing(1, 0, 0, 0),
  },
  couponDescription: {
    color: '#83A5B0',
    fontWeight: 400,
    fontSize: 12,
    fontFamily: theme.gh_vars.mabryMono,
    lineHeight: 1,
    marginLeft: theme.spacing(1)
  },
  subscriptionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 0, 0, 0),
  }
}));
