import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  contentSpacing: {
    padding: theme.spacing(4),
  },
  heroOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundImage: 'linear-gradient(to bottom, rgba(18, 18, 18, 0) 33%, #080808)',
  },
  overflowSection: {
    maxWidth: `calc(100vw - ${theme.spacing(8)})`,
    overflow: 'auto',
  }
}));
