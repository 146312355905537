import makeStyles from '../../utils/makeStyles';

export default makeStyles()((theme) => ({
  itemizedList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    marginBottom: theme.spacing(3),
  },
  itemizedListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(0),
    padding: theme.spacing(1, 0, 1, 0),
    borderBottom: '1px dashed #979797',
  },
  itemizedListKey: {
    fontSize: 14,
  },
  itemizedListValue: {
    fontFamily: theme.gh_vars.mabryMono,
    fontSize: 14,
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(1),
  },
  itemizedListDiscountValue: {
    textDecoration: 'line-through',
  },
  discountWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(0),
    padding: theme.spacing(2, 0),
    borderBottom: '1px dashed #979797'
  },
  discountCurrencyText: {
    fontSize: 13,
    fontWeight: 400,
    fontFamily: theme.gh_vars.mabryMono
  }
}));
