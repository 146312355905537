import useStyles from '@components/social-previews/SearchPreview.style';
import React from 'react';

import { firstValid, hardTruncation, shortEnough, stripHtmlTags, truncatedAtSpace } from './helpers';

const URL_LENGTH = 68;
const TITLE_LENGTH = 63;
const DESCRIPTION_LENGTH = 160;

const googleUrl = (url) => {
  const breadcrumb = url
    .replace(/^[^/]+[/]*/, '')
    .split('/')
    .join(' › ');

  const truncateBreadcrumb = firstValid(shortEnough(URL_LENGTH), hardTruncation(URL_LENGTH));

  return truncateBreadcrumb(breadcrumb);
};

const googleTitle = firstValid(
  shortEnough(TITLE_LENGTH),
  truncatedAtSpace(TITLE_LENGTH - 40, TITLE_LENGTH + 10),
  hardTruncation(TITLE_LENGTH)
);

const googleDescription = firstValid(
  shortEnough(DESCRIPTION_LENGTH),
  truncatedAtSpace(DESCRIPTION_LENGTH - 80, DESCRIPTION_LENGTH + 10),
  hardTruncation(DESCRIPTION_LENGTH)
);

interface SearchPreviewProps {
  style?: React.CSSProperties;
  className?: string;
  title: string;
  url: string;
  description: string;
}

const SearchPreview : React.FC<SearchPreviewProps> = (props) => {
  const { description, title, url, className, style } = props;
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(classes.searchPreview, className)}
      style={style}
    >
      <div className={classes.searchPreviewUrl}>
        {`${googleUrl(url)} ▾`}
      </div>
      <div className={classes.searchPreviewTitle}>
        {googleTitle(title)}
      </div>
      <div className={classes.searchPreviewDescription}>
        {googleDescription(stripHtmlTags(description))}
      </div>
    </div>
  );
};

SearchPreview.defaultProps = {
  title: '',
  url: '',
  description: '',
};

export default SearchPreview;
