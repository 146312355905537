import RoomSetCard from '@components/cards/RoomSetCard';
import Grid from '@mui/material/Grid';
import { useStyles } from '@ui/utils/makeStyles';
import React from 'react';

import RoomSetGridProps, { defaultProps } from './RoomSetGrid.props';

const RoomSetGrid: React.FC<RoomSetGridProps> = (props: RoomSetGridProps) => {
  const { gridProps, gridItemProps, href, imageHeight, roomSets, spacing, onRoomsetClick, afterGrid } = props;
  const { theme } = useStyles();
  
  return (
    <Grid
      container
      spacing={3}
      style={{ marginBottom: theme.spacing(spacing) }}
      {...gridProps}
    >
      {roomSets.map((roomSetData: guesthouse.AlgoliaRoomSet) => {

        return (
          <Grid
            key={roomSetData.docID}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={2}
            style={{
              cursor: onRoomsetClick ? 'pointer' : undefined, 
            }}
            onClick={(e) => {
              if (onRoomsetClick) {
                onRoomsetClick(e, roomSetData);
              }
            }}
            {...gridItemProps}
            data-test={`roomset-grid-${roomSetData.docID}`}
          >
            <RoomSetCard
              roomSet={roomSetData}
              imageHeight={imageHeight || 127}
              href={href}
            />
          </Grid>
        );
      })}
      {afterGrid && (
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
        >
          {afterGrid()}
        </Grid>
      )}
    </Grid>
  );
};

RoomSetGrid.defaultProps = defaultProps;

export default RoomSetGrid;
