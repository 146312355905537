type eventTypes = 'default' | 'photography' | 'install' | 'install (express)' | 'available' | 'tentative booking' | 'deinstall' | 'unavailable' | 'available (confirmed)' | 'available (express)' | 'express staging (confirmed)'

type GoogleCalendarColorType = {
  googleColorId: number;
  hexColor: string;
  eventType?: eventTypes;
}

const eventSortOrder : eventTypes[] = [
  'default',
  'available (confirmed)',
  'available',
  'available (express)',
  'express staging (confirmed)',
  'tentative booking',
  'photography',
  'install',
  'deinstall',
  'unavailable'
];

const compareColors = (a, b) => {

  if (eventSortOrder.indexOf(a.eventType) < eventSortOrder.indexOf(b.eventType)) {
    return -1;
  }
  if (eventSortOrder.indexOf(a.eventType) > eventSortOrder.indexOf(b.eventType)) {
    return 1;
  }
  return 0;
};

export const googleCalendarColors: GoogleCalendarColorType[] = [
  {
    googleColorId: 0,
    hexColor: '#000000',
    eventType: 'default' as const,
  },
  {
    googleColorId: 1,
    hexColor: '#7986cb',
    eventType: 'photography' as const,
  },
  {
    googleColorId: 2,
    hexColor: '#33b679',
    eventType: 'express staging (confirmed)' as const
  },
  {
    googleColorId: 3,
    hexColor: '#8e24aa',
    eventType: 'install (express)' as const
  },
  {
    googleColorId: 4,
    hexColor: '#e67c73',
    eventType: 'install' as const,
  },
  {
    googleColorId: 5,
    hexColor: '#f6c026',
    eventType: 'available' as const,
  },
  {
    googleColorId: 6,
    hexColor: '#f5511d',
    eventType: 'tentative booking' as const
  },
  {
    googleColorId: 7,
    hexColor: '#039be5',
    eventType: 'deinstall' as const, 
  },
  {
    googleColorId: 8,
    hexColor: '#616161',
    eventType: 'unavailable' as const
  },
  {
    googleColorId: 9,
    hexColor: '#3f51b5',
  },
  {
    googleColorId: 10,
    hexColor: '#0b8043',
    eventType: 'available (confirmed)' as const
  },
  {
    googleColorId: 11,
    hexColor: '#d60000',
    eventType: 'available (express)' as const
  }
].sort((a, b) => compareColors(a, b));
