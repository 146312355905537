import { database } from '@app/firebase';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import Typography from '@mui/material/Typography';
import { useStyles } from '@ui/utils/makeStyles';
import { collectionGroup, query, where } from 'firebase/firestore';
import React, { useContext, useMemo } from 'react';

type HomeProductsProps = {
  homeId: string;
}

const HomeProducts: React.FC<HomeProductsProps> = (props: HomeProductsProps) => {
  const { homeId } = props;
  const userContext = useContext<UserContext>(UserContext);
  const { theme } = useStyles();

  const { collection } = useCollection(
    query(collectionGroup(database, 'requests')
      ,where('home.docID', '==', homeId)
      ,where('status', '==', 'RECEIVED')
      ,where('product.owner.docID', '==', userContext.user.uid)
      ,where('controller', '==', true)
    )
  );

  const requests = useMemo(() => {
    if (collection && collection.docs?.length) {
      return collection.docs.map(doc => {
        const request = doc.data() as guesthouse.ProductRequest;

        return request;
      });
    }

    return null;
  }, [collection]);


  if (requests) {
    return (
      <>
        <Typography
          style={{
            fontFamily: theme.gh_vars.circular,
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          In this home:
        </Typography>

        {
          requests.map((request, i) => (
            <span key={i}>
              <Typography
                style={{
                  fontFamily: theme.gh_vars.circular,
                  fontSize: 11,
                  fontWeight: 400,
                }}
              >
                {request.product.title}
                {i < requests.length && ', '}
              </Typography>

            </span>
          ))
        }
      </>
    );
  }

  return null;
};

export default HomeProducts;
