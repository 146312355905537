import { database } from '@app/firebase';
import StagingSubscription from '@components/staging-subscription/StagingSubscription';
import UserContext from '@context/UserContext';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import StagingOrder from '@ui/components/staging-quote/StagingOrder';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import theme from '@ui/theme';
import { ORANGE } from '@ui/theme/theme';
import getDataFromCollection from '@ui/utils/getDataFromCollection';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';

const Billing = () => {
  const userContext = useContext<UserContext>(UserContext);
  const [stagingOrders, setStagingOrders] = useState<guesthouse.StagingOrder[]>();
  const { md } = useBreakpoints();

  useEffect(() => {
    const getOrders = async () => {
      const orderDocs = await getDocs(query(collection(database, 'orders')
        ,where('user.docID', '==', userContext?.user.uid)
        ,where('type', '==', 'staging')
        ,where('status', '==', 'COMPLETE')));

      const orders = getDataFromCollection(orderDocs) as guesthouse.StagingOrder[];

      setStagingOrders(orders);
    };

    getOrders();
  }, [userContext?.user?.uid]);

  return (
    <>
      <div
        style={{ padding: theme.spacing(3) }}
      >
        <Typography
          component="h1"
          variant="h3Alt"
          style={{ marginBottom: theme.spacing(1) }}
        >
          Billing
        </Typography>

        <Typography>
          Questions about your bill or want to cancel your monthly furniture renewal? Email
          {' '}
          <Link href="mailto:billing@guesthouseshop.com">
            billing@guesthouseshop.com
          </Link>
        </Typography>
      </div>

      <div
        style={{
          margin: 'auto',
          textAlign: 'center',
        }}
      >
        {stagingOrders?.length ?
          <>
            {stagingOrders?.map((orderData, i) => {
              return (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    flexDirection: md ? 'row' : 'column',
                    alignItems: 'center',
                    margin: theme.spacing(3, 0)
                  }}
                >
                  <StagingOrder
                    billingRoles={['admin', 'design_manager', 'customer_support', 'realtor']}
                    order={orderData}
                    showHomeAddress={true}
                    style={{
                      borderRadius: theme.shape.borderRadius,
                      backgroundColor: theme.palette.background.paper,
                      marginBottom: 0,
                      padding: theme.spacing(2),
                      width: md ? 400 : '100%'
                    }}
                  />
                  {orderData?.subscriptionID &&
                  <>
                    {md ?
                      <hr
                        style={{
                          width: 80,
                          borderTop: `2px solid ${ORANGE}`,
                        }}
                      />
                      :
                      <hr
                        style={{
                          width: 2,
                          height: 80,
                          display: 'inline-block',
                          border: `2px solid ${ORANGE}`,
                          margin: 0
                        }}
                      />
                    }
                    <StagingSubscription
                      subscriptionID={orderData?.subscriptionID}
                      order={orderData}
                      isBillingPage={true}
                      style={{
                        borderRadius: theme.shape.borderRadius,
                        marginBottom: theme.spacing(1),
                        backgroundColor: theme.palette.background.paper,
                        padding: theme.spacing(2)
                      }}
                    />
                  </>
                }
                </div>
              );
            })}
          </>
          :
          <div>
            <Typography>
              No staging billing history.
            </Typography>
          </div>
        
        }
      </div>
    </>
  );
};

export default Billing;
