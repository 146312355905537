import { functions } from '@app/firebase';
import UserContext from '@context/UserContext';
import useDebounceEffect from '@hooks/useDebounceEffect';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import StagingQuote from '@ui/components/staging-quote/StagingQuote';
import { useStyles } from '@ui/utils/makeStyles';
import { httpsCallable } from 'firebase/functions';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react';
import * as Yup from 'yup';

const getDeal = httpsCallable<guesthouse.functions.GetDealRequest, guesthouse.functions.GetDealResponse>(functions, 'http-getDeal');
const createStagingOrder = httpsCallable<CreateStagingOrderRequest, CreateStagingOrderResponse>(functions, 'http-createStagingOrder');
const createDealHome = httpsCallable<guesthouse.functions.CreateDealHomeRequest, guesthouse.functions.CreateDealHomeResponse>(functions, 'http-createDealHome');

type CreateStagingOrderRequest = { dealId: string, couponCode: string };
type CreateStagingOrderResponse = { order: guesthouse.StagingOrder, purchaseEvent: segment.Order };

type CreateHomeFromDealProps = {
  resolve: (result: guesthouse.functions.CreateDealHomeResponse) => void;
  reject: (e?: Error | boolean) => void;
  saveText?: string;
  cancelText?: string;
  title?: JSX.Element | string;
  content?: React.ReactElement;
  userContext: UserContext;
}

const CreateHomeFromDealSchema = Yup.object().shape({
  dealID: Yup.number()
    .required('Deal ID is required')
    .nullable(),
  couponCode: Yup.string()
    .matches(
      /^[A-Za-z0-9]{1,6}$/,
      'Invalid promo code format'
    )
    .nullable(),
});

export const CreateHomeFromDealDialog: React.FC<CreateHomeFromDealProps> = (props) => {
  const {
    resolve,
    reject,
    userContext,
    title,
    saveText,
    cancelText,
    content,
  } = props;

  const [open, setOpen] = useState(true);
  const [deal, setDeal] = useState<HubSpot.Deal>();
  const [lineItems, setLineItems] = useState<HubSpot.LineItem[]>();
  const [error, setError] = useState<Error>();
  const [dealLoading, setDealLoading] = useState(false);
  const { theme } = useStyles();

  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        onClose={() => {
          setOpen(false);
          reject();
        }}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          {content ? content : null}
          <Formik
            enableReinitialize
            initialValues={{
              dealID: null,
              couponCode: null,
            }}
            validationSchema={CreateHomeFromDealSchema}
            onSubmit={(values) => {
              return createStagingOrder({ dealId: deal.id, couponCode: values.couponCode })
                .then(({ data }) => createDealHome({ order: data.order }))
                .then(({ data }) => {
                  console.log('data', data);
                  resolve(data);
                  setOpen(false);
                })
                .catch((e) => {
                  setError(e);
                });
            }}
          >
            {({
              errors,
              touched,
              isSubmitting,
              values,
            }) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useDebounceEffect(() => {
                if (values.dealID) {
                  setDeal(null);
                  setLineItems(null);
                  setError(null);
                  setDealLoading(true);

                  getDeal({ id: values.dealID })
                    .then(({ data }) => {
                      setDeal(data.deal || {} as HubSpot.Deal);
                      setLineItems(data.lineItems || []);
                      setDealLoading(false);
                    })
                    .catch((e) => {
                      setError(e);
                      setDeal(null);
                      setLineItems(null);
                      setDealLoading(false);
                    });
                } else {
                  setDeal(null);
                  setLineItems(null);
                  setError(null);
                }

              }, 200, [values.dealID]);

              return (
                <Form>

                  <Field
                    fullWidth
                    data-test="deal-id-field"
                    id="dealID"
                    name="dealID"
                    label="Deal ID"
                    type="string"
                    component={TextField}
                    margin="dense"
                    variant="outlined"
                  />

                  <Field
                    fullWidth
                    data-test="coupon-code-field"
                    id="couponCode"
                    name="couponCode"
                    label="Coupon Code"
                    type="string"
                    component={TextField}
                    margin="dense"
                    variant="outlined"
                  />

                  {dealLoading && (
                    <div style={{ textAlign: 'center', marginTop: theme.spacing(2) }}>
                      <CircularProgress />
                    </div>
                  )}

                  {error && (
                    <FormHelperText error>
                      Failed to retrieve deal:
                      {' '}
                      {error.message}
                    </FormHelperText>
                  )}

                  {deal && lineItems && (
                    <>
                      <Typography
                        component="h4"
                        variant="h5Alt"
                        style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
                      >
                        {deal.properties?.staging_address}
                      </Typography>
                      <StagingQuote
                        style={{ marginTop: theme.spacing(2) }}
                        deal={deal}
                        lineItems={lineItems}
                        totalLabel="Total:"
                        total={Number(deal.properties.amount)}
                      />
                    </>
                  )}

                  <div style={{ paddingTop: 20 }}>
                    <SubmitButton
                      fullWidth
                      data-test="create-home-dialog-submit"
                      isSubmitting={isSubmitting}
                      disabled={dealLoading || Boolean(Object.keys(errors).length)}
                      aria-label={saveText}
                      tooltip={makeErrors(errors, touched)?.length && (
                        <TooltipErrors
                          errors={errors}
                          touched={touched}
                        />
                      )}
                    >
                      {saveText}
                    </SubmitButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            size="small"
            onClick={() => {
              setOpen(false);
              reject(false);
            }}
          >
            {cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};

CreateHomeFromDealDialog.defaultProps = {
  title: 'Create Home',
  cancelText: 'Cancel',
  saveText: 'Create',
};
