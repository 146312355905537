import Sentry from '@integrations/Sentry';
import { cloneDeep } from 'lodash';

export const convertPhotoToPrimaryPhoto = (photo: guesthouse.Photo): guesthouse.PrimaryPhoto => {
  let photoCopy: guesthouse.Photo;

  try {
    photoCopy = cloneDeep(photo);
  } catch (e) {
    Sentry.captureException(e);
  }
  
  if (photoCopy?.room?.docID) {
    delete photoCopy.room;
  }

  if (photoCopy?.roomSet?.docID) {
    delete photoCopy.roomSet;
  }

  if (photoCopy?.home?.docID) {
    delete photoCopy.home;
  }

  if (photoCopy?.product?.docID) {
    delete photoCopy.product;
  }

  return photoCopy;
};
