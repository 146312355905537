import Skeleton from '@mui/material/Skeleton';
import { useStyles } from '@ui/utils/makeStyles';
import React from 'react';


const ChannelListContainerSkeleton: React.FC = () => {
  const numItems = 10;
  const { theme } = useStyles();

  return (
    <>
      {new Array(numItems).fill(0).map((_, i) => (
        <Skeleton
          key={i}
          variant="rectangular"
          width="100%"
          height={48}
          style={{ padding: theme.spacing(0, 4), marginBottom: theme.spacing(1) }}
        />
      ))}
    </>
  );
};

export default ChannelListContainerSkeleton;
