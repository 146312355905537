import Sentry from '@integrations/Sentry';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import * as geofire from 'geofire-common';
import throttle from 'lodash/throttle';
import React, { useEffect,useMemo, useRef, useState } from 'react';

import Props from './LocationSearch.props';
import useStyles from './LocationSearch.style';

const LocationSearch: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles();
  const [inputValue, setInputValue] = useState(props.defaultValue);

  useEffect(() => setInputValue(props.defaultValue), [props.defaultValue]);

  const defaultOptions = props.defaultValue
    ? [{ description: props.defaultValue }] as google.maps.places.AutocompletePrediction[]
    : [];

  const autocompleteService = useRef<google.maps.places.AutocompleteService>();
  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>(defaultOptions);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const autoCompleteChange = (event: React.ChangeEvent<HTMLInputElement>, value: google.maps.places.AutocompletePrediction) => {
    if (value) {
      new window.google.maps.places.PlacesService(document.createElement('div'))
        .getDetails(
          {
            placeId: value.place_id
          },
          (result) => {
            const _geoloc = result.geometry.location.toJSON();

            props.onLocationChange({
              place_id: value.place_id,
              address: value.description,
              vicinity: result.vicinity,
              _geoloc: _geoloc,
              geohash: geofire.geohashForLocation([
                _geoloc.lat,
                _geoloc.lng,
              ])
            });
          }
        );
    } else {
      props.onLocationChange({
        place_id: null,
        address: null,
        vicinity: null,
        _geoloc: null,
        geohash: null,
      });
    }
  };

  const getResults = useMemo(
    () =>
      throttle((input, callback) => {
        if (input?.input) {
          try {
            autocompleteService.current.getPlacePredictions(input, callback);
          } catch (e) {
            Sentry.captureException(e);
          }
        }
      }, 200),
    [],
  );

  useEffect(() => {
    let active = true;

    if (typeof window.google === 'undefined') {
      return;
    }

    if (!autocompleteService.current) {
      try {
        autocompleteService.current = new google.maps.places.AutocompleteService();
      } catch (e) {
        Sentry.captureException(e);
        return;
      }
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    getResults({ input: inputValue, ...props.autocompleteRequestOptions }, (results?: google.maps.places.AutocompletePrediction[]) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, getResults]);

  return (
    <Autocomplete
      autoComplete
      includeInputInList
      freeSolo
      style={props.style}
      id={props.id}
      data-test={props.id}
      getOptionLabel={(option: google.maps.places.AutocompletePrediction) => option.description}
      filterOptions={x => x}
      options={options}
      disabled={props.disabled}
      defaultValue={props.defaultValue && defaultOptions[0]}
      renderInput={params => {
        // workaround for MUI setting autocomplete="disabled"
        // which does not work in chrome.
        //
        const _params = {
          ...params,
          inputProps: {
            ...params.inputProps,
            autoComplete: 'off',
          }
        };

        return (
          <>
            <TextField
              error={props.touched && props.error ? true : false}
              {..._params}
              fullWidth
              name={props.name}
              label={props.label}
              variant="outlined"
              margin={props.margin}
              helperText={props.error && props.touched ? props.error : null}
              onChange={handleChange}
              onBlur={props.onBlur}
            />
          </>
        );
      }}
      renderOption={(props, option) => {
        let matches, parts;

        try {
          matches = option.structured_formatting.main_text_matched_substrings;
          parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length]),
          );
        } catch (e) {
          return null;
        }

        return (
          <li {...props}>
            <Box
              display="flex"
              alignItems="center"
            >
              <LocationOnIcon className={classes.icon} />

              <Box>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Box>
            </Box>
          </li>
        );
      }}
      onChange={autoCompleteChange}
    />
  );
};

LocationSearch.defaultProps = {
  id: 'location-search',
  style: {},
  autocompleteRequestOptions: {},
};

export default LocationSearch;
