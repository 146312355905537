import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import React, { useState } from 'react';


type DateTimeDialogArgs = {
  title?: string;
  copy?: string;
  saveText?: string;
  cancelText?: string;
  resolve: (values: Date) => void;
  reject: (e?: Error | boolean) => void;
}

const DateTimeDialog: React.FC<DateTimeDialogArgs> = ({
  resolve,
  reject,
  title = 'Please select a date and time',
  copy = '',
  saveText = 'Save',
  cancelText = 'Cancel',
}) => {
  const [open, setOpen] = useState(true);
  const [date, setDate] = useState<Date>(new Date());

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {copy && (
          <Typography>
            {copy}
          </Typography>
        )}
        <Box
          margin={[2, 0, 0, 0]}
          position="relative"
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDateTimePicker
              displayStaticWrapperAs="desktop"
              value={date}
              onChange={setDate}
            />
          </LocalizationProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            reject(false);
          }}
        >
          {cancelText}
        </Button>
        <Button
          disabled={(!date)}
          onClick={() => {
            setOpen(false);
            resolve(date);
          }}
        >
          {saveText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default DateTimeDialog;
