import OnOff from '@components/on-off/OnOff';
import useDocument from '@hooks/useDocument';
import Typography from '@mui/material/Typography';
import Protected from '@ui/components/protected/Protected';
import formatDate from '@ui/utils/formatDate';
import properCase from '@utils/properCase';
import formatCurrency from 'format-currency';
import React from 'react';
import Stripe from 'stripe';

import useStyles from './StagingSubscription.style';


type StagingSubscriptionProps = {
  className?: string;
  style?: React.CSSProperties;
  subscriptionID?: string;
  order?: guesthouse.StagingOrder;
  isBillingPage?: boolean;
}

const StagingSubscription: React.FC<StagingSubscriptionProps> = (props) => {
  const { className, style, subscriptionID, order, isBillingPage } = props;
  const { doc, loading } = useDocument(`subscriptions/${subscriptionID}`);
  const { classes, cx } = useStyles();
  const { theme } = useStyles();

  if (loading) {
    return null;
  }

  let subscriptionData: Stripe.Subscription;

  if (doc?.exists()) {
    subscriptionData = doc.data() as Stripe.Subscription;
  }

  return (
    <div
      className={cx(classes.orderContainer, className)}
      style={style}
    >
      <Typography
        component="div"
        variant="h5Alt"
        className={classes.orderTitle}
      >
        Subscription information
      </Typography>


      <Protected allowedRoles={['admin', 'customer_support', 'design_manager']}>
        <OnOff
          on={subscriptionData?.status === 'active'}
          direction="ltr"
          offLabel={properCase(subscriptionData?.status) || 'Inactive'}
          style={{
            fontSize: 12,
          }}
          onLabel="Active"
        />


        {!subscriptionData && !isBillingPage && (
        <>
          <Typography style={{ fontSize: 11, marginBottom: theme.spacing(2) }}>
            Due date:
            {' '}
            {formatDate(order.subscriptionStartDate, 'MM/dd/yy')}
            <br />
            Notification attempts:
            {' '}
            {order.subscriptionNoticeAttempts || 0}
          </Typography>
        </>
        )}

        {subscriptionData?.status === 'trialing' && (
        <Typography style={{ fontSize: 11 }}>
          Trial ends:
          {' '}
          {formatDate(new Date(subscriptionData.trial_end * 1000), 'MM/dd/yy')}
        </Typography>
        )}
      </Protected>


      {order?.subscriptionStartDate &&
      <>
        <li className={classes.subscriptionItem}>
          <Typography
            style={{ fontFamily: theme.gh_vars.circular, fontSize: 11 }}
          >
            {order.stripeSubscriptionsDays ? `${order.stripeSubscriptionsDays}-day renewal ` : 'Renewal '} 
            fee:
          </Typography>
          <Typography>
            {formatCurrency(order.deal.properties?.amount_fifteen_day_interval, { format: '%s%v', symbol: '$' })}
          </Typography>
        </li>

        <li className={classes.subscriptionItem}>
          <Typography
            style={{ fontFamily: theme.gh_vars.circular, fontSize: 11 }}
          >
            Initial renewal date:
          </Typography>

          <Typography>
            {formatDate(order?.subscriptionStartDate, 'MM/dd/yy')}
          </Typography>
        </li>

          {subscriptionData?.current_period_end &&
          <li className={classes.subscriptionItem}>
            <Typography
              style={{ fontFamily: theme.gh_vars.circular, fontSize: 11 }}
            >
              Next billing date:
            </Typography>
            <Typography>
              {formatDate(new Date(subscriptionData.current_period_end * 1000), 'MM/dd/yy')}
            </Typography>
          </li>
      }
      </>
      }

      {isBillingPage && subscriptionData?.metadata?.hubspot_deal_amount_monthly &&
        <Typography
          component="div"
          variant="body1"
          color="textSecondary"
          style={{ fontSize: 11 }}
        >
          Amount monthly:
          {' '}
          {formatCurrency(Number(subscriptionData.metadata.hubspot_deal_amount_monthly) / 100, { format: '%s%v', symbol: '$' })}
        </Typography>
      }

      <Protected 
        allowedRoles={['admin', 'customer_support', 'design_manager']}
      >
        {subscriptionData?.id && (
        <Typography
          component="div"
          variant="body1"
          color="textSecondary"
          style={{
            fontSize: 10,
            marginTop: theme.spacing(1),
          }}
        >
          Subscription
          {' '}
          {subscriptionData.id}
        </Typography>
        )}
      </Protected>
    </div>
  );
};

export default StagingSubscription;
