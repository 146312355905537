import FacebookPreview from '@components/social-previews/FacebookPreview';
import SearchPreview from '@components/social-previews/SearchPreview';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useStyles } from '@ui/utils/makeStyles';
import React from 'react';

interface SocialPreviewsProps {
  metadata: guesthouse.MetaData;
  url?: string;
  author?: string;
}

const SocialPreviews : React.FC<SocialPreviewsProps> = (props) => {
  const { metadata, url, author } = props;
  const { theme } = useStyles();

  return (
    <Box maxWidth={700}>
      <FacebookPreview
        title={metadata?.metaTitle}
        description={metadata?.metaDescription}
        url={url}
        image={metadata?.image}
        author={author}
        style={{ margin: theme.spacing(2, 0) }}
      />
      <Divider />
      <SearchPreview
        title={metadata?.pageTitle}
        description={metadata?.metaDescription}
        url={url}
        style={{ margin: theme.spacing(2, 0) }}
      />
    </Box>
  );
};

export default SocialPreviews;
