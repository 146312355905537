import { functions } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import ProductSearchDialog from '@components/dialogs/ProductSearchDialog';
import AppContext from '@context/AppContext';
import UserContext from '@context/UserContext';
import Sentry from '@integrations/Sentry';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import XIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import Protected from '@ui/components/protected/Protected';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import AddFileIcon from '@ui/icons/imaterial/base/Plus in a box.svg';
import SendIcon from '@ui/icons/imaterial/communication/Send.svg';
import EmojiIcon from '@ui/icons/imaterial/emoticons/Emoticon.svg';
import EmojiPicker from 'emoji-picker-react';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { FileUploadButton, ImageDropzone } from 'react-file-utils';
import { ChatAutoComplete, MessageToSend, UploadsPreview, useChannelActionContext, useChannelStateContext, useMessageInputContext } from 'stream-chat-react';

import useStyles from './Input.style';

const updateChannelLastSentBy = httpsCallable(functions, 'http-updateChannelLastSentBy');

export const Input = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEmojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const { insertText, textareaRef } = useMessageInputContext();

  const userContext = useContext<UserContext>(UserContext);
  const appContext = useContext<AppContext>(AppContext);

  const {
    handleSubmit,
    uploadNewFiles,
    isUploadEnabled,
    numberOfUploads,
  } = useMessageInputContext();
  const { sendMessage } = useChannelActionContext();
  const { members, channel, acceptedFiles } = useChannelStateContext();

  const { classes, theme } = useStyles({ numberOfUploads, anchorEl });
  const { sm } = useBreakpoints();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addToChannel = async () => {
    channel.addMembers([userContext.data.docID], { text: `${userContext.data.firstname} ${userContext.data.lastname[0]}. has been added to the chat.` });
  };

  if (channel.data.member_count < 2 && channel.type != 'home') {
    return (
      null
    );
  }

  return (
    <>
      {Object.keys(members).includes(userContext.data.docID) && (
        <ImageDropzone
          accept={acceptedFiles}
          handleFiles={uploadNewFiles}
        >
          {numberOfUploads > 0 && isUploadEnabled && (
            <div style={{ padding: theme.spacing(1, 3, 1, 3), borderTop: '#DDDDDD 1px solid', position: 'relative', bottom: 0, }}>
              <UploadsPreview />
            </div>
          )}

          <div >
            <div
              className={classes.textAreaContainer}
            >
              <div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex',
                  opacity: anchorEl ? 0 : 1,
                }}
              >
                <ChatAutoComplete
                  placeholder={'Send your message...'}
                  handleSubmit={async (e) => {
                    handleSubmit(e);

                    await updateChannelLastSentBy({
                      channelCID: channel.cid,
                      channelType: channel.type,
                      user: userContext.data,
                    });
                  }}
                />
              </div>
            </div>

            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => setEmojiPickerOpen(false)}
            >
              <span
                tabIndex={0}
                style={{
                  opacity: anchorEl ? 0 : 1,
                }}
                className={classes.emojiPicker}
              >

                {isEmojiPickerOpen && (
                <EmojiPicker
                  onEmojiClick={(emojiClick, ) => {
                    insertText(emojiClick.emoji);
                    setEmojiPickerOpen(false);
                    textareaRef.current?.focus(); // returns focus back to the message input element
                  }}
                />
                )}
                <span
                  role="button"
                  onClick={() => setEmojiPickerOpen((isEmojiPickerOpen) => !isEmojiPickerOpen)}
                >
                  <EmojiIcon style={{ height: 24, width: 24 }} />
                </span>
              </span>
            </ClickAwayListener>

            <div
              className={classes.filePicker}
            >
              <IconButton
                aria-label="open attachments menu"
                aria-haspopup="true"
                style={{
                  padding: 0
                }}
                onClick={handleClick}
              >
                {anchorEl ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={64}
                    width={64}
                    className={classes.xBox}
                  >
                    <XIcon style={{ height: 24, width: 24 }} />
                  </Box>
                ) : (
                  <AddFileIcon style={{ height: 24, width: 24, fill: '#000', padding: 0 }} />
                )
                }
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                  vertical: sm ? -5 : -40,
                  horizontal: sm ? 130 : 143,
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                classes={{
                  list: classes.list,
                }}
                onClose={handleClose}
              >
                <MenuItem
                  className={classes.menuItems}
                >
                  <FileUploadButton
                    accepts={acceptedFiles}
                    handleFiles={async (props) => {
                      handleClose();
                      uploadNewFiles(props);
                    }}
                  >
                    Attachment
                  </FileUploadButton>
                </MenuItem>

                <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
                  <Divider
                    style={{
                      width: '90%',
                      margin: theme.spacing(.5, 'auto'),
                    }}
                  />
                  <MenuItem
                    className={classes.menuItems}
                    onClick={() => {
                      asyncRender(ProductSearchDialog, { userContext, appContext })
                        .then(async (value) => {
                          const message: MessageToSend = {
                            attachments: [{
                              type: 'product',
                              docID: value.docID,
                            }]
                          };

                          await sendMessage(message);
                          await updateChannelLastSentBy({
                            channelCID: channel.cid,
                            channelType: channel.type,
                            user: userContext.data,
                          });
                          handleClose();
                        })
                        .catch((e) => Sentry.captureException(e));
                    }}
                  >
                    Product
                  </MenuItem>
                </Protected>
              </Menu>
            </div>


            {sm ?
              <Button
                variant="contained"
                color="secondary"
                size="small"
                aria-label="send message"
                style={{
                  position: 'absolute',
                  bottom: 40,
                  right: 32,
                  opacity: anchorEl ? 0 : 1,
                  fontSize: 14,
                }}
                onClick={async (e) => {
                  handleSubmit(e);

                  await updateChannelLastSentBy({
                    channelCID: channel.cid,
                    channelType: channel.type,
                    user: userContext.data,
                  });
                }}
              >
                Send
              </Button>
              :
              <IconButton
                aria-label="send message"
                className={classes.sendIconButton}
                onClick={async (e) => {
                  handleSubmit(e);

                  await updateChannelLastSentBy({
                    channelCID: channel.cid,
                    channelType: channel.type,
                    user: userContext.data,
                  });
                }}
              >
                <SendIcon style={{ width: 24, height: 24, stroke: '#fff' }} />
              </IconButton>
            }
          </div>
        </ImageDropzone>
      )}

      {!Object.keys(members).includes(userContext.data.docID) && (
        <div
          style={{
            margin: theme.spacing(0, 0, 3, 0),
            padding: theme.spacing(3, 3, 0, 3),
            width: '100%',
            borderTop: '#DDDDDD 1px solid',
            height: 85,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            size="small"
            aria-label="join channel"
            style={{
              position: 'absolute',
              bottom: 44,
              right: 32,
              fontSize: 14,
            }}
            onClick={addToChannel}
          >
            Join Conversation
          </Button>
        </div>
      )}
    </>
  );
};

export default Input;
