import { auth, database, functions } from '@app/firebase';
import { RouterLink } from '@components/link-behavior/LinkBehavior';
import LocationSearch from '@components/location-search/LocationSearch';
import SignupIntroText from '@components/signup-intro-text/SignupIntroText';
import TextFieldPhoneNumber from '@components/textfield-phone-number/TextFieldPhoneNumber';
import UserContext from '@context/UserContext';
import useRouter from '@hooks/useRouter';
import GuestHouseLogoFull from '@images/guesthouse-logo-full';
import GuestHouseLogoIcon from '@images/guesthouse-logo-icon';
import Sentry from '@integrations/Sentry';
import { version as supplierTermsVersion } from '@legal/SupplierAgreement.md';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import routes from '@routes';
import RegisterSchema, { initialValues } from '@schema/RegisterSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors } from '@ui/components/form-errors/FormErrors';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import IconEyeClosed from '@ui/icons/imaterial/base/Eye Crossed.svg';
import IconEyeOpen from '@ui/icons/imaterial/base/Eye Open.svg';
import formatPhone from '@ui/utils/formatPhone';
import makeStoreUrl from '@ui/utils/makeStoreUrl';
import { isError } from '@ui/utils/typescriptHelpers';
import userFullName from '@ui/utils/userFullName';
import { validateEmailNotExists } from '@ui/utils/validators';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { collection, doc, setDoc,Timestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import debounce from 'lodash/debounce';
import React, { useContext, useState } from 'react';
import { generatePath } from 'react-router';

import useStyles from './Register.style';

const setClaims = httpsCallable(functions, 'http-setClaims');
const deleteAuthUser = httpsCallable(functions, 'http-deleteAuthUser');

const validateEmailNotExistsDebounce = debounce(validateEmailNotExists, 500);

const Register = () => {
  const userContext = useContext<UserContext>(UserContext);
  const router = useRouter();
  const { classes, theme } = useStyles();
  const { md } = useBreakpoints();
  const [error, setError] = useState(false);
  const [forceDisable, setForceDisable] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div className={classes.wrapper}>
      <Grid
        container
        spacing={1}
        className={classes.top}
      >
        <Grid
          item
          xs={12}
          md={4}
        >
          {
            md
              ? <GuestHouseLogoFull className={classes.logo} />
              : <GuestHouseLogoIcon className={classes.logo} />
          }
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          style={{ textAlign: 'right' }}
        >
          {md && (
            <Link
              component={RouterLink}
              to={generatePath(routes.login.path)}
              className={classes.register}
            >
              <h3 className={classes.h3}>
                Login
              </h3>
            </Link>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={12}
          md={3}
        >
        </Grid>

        <Grid
          item
          xs={12}
          md={9}
          className={classes.gridForm}
        >
          <Paper className={classes.paper}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={5}
              >
                {md && (
                  <SignupIntroText />
                )}
              </Grid>

              <Grid
                item
                xs={12}
                md={2}
              >
              </Grid>

              <Grid
                item
                xs={12}
                md={5}
              >
                <Typography
                  variant="h3Alt"
                  component="h1"
                  className={classes.formTitle}
                >
                  Sign up
                </Typography>

                <Formik
                  validationSchema={RegisterSchema}
                  initialValues={initialValues}
                  onSubmit={(values: RegisterForm) => {
                    setForceDisable(true);
                    setError(false);
                    const { password, firstname, lastname, location, role } = values;
                    let { email } = values;

                    if (typeof email === 'string' && email.length) {
                      email = email.toLocaleLowerCase();
                    }

                    return createUserWithEmailAndPassword(auth, email, password)
                      .then(async (userCredential) => {
                        const uid = userCredential.user.uid;
                        const now = new Date();
                        const firestoreNow = Timestamp.fromDate(now);

                        const roleData: guesthouse.Roles = {
                          [role]: true,
                          user: true,
                        };

                        const userData: guesthouse.User = {
                          docID: uid,
                          firstname,
                          lastname,
                          location,
                          email,
                          roles: roleData,
                          flags: {},
                          notificationPreferences: {
                            messages: {
                              email: true,
                              sms: true,
                            },
                            timeline_reminders: {
                              email: true,
                              sms: true,
                            }
                          },
                          created: firestoreNow,
                          last_login: firestoreNow,
                          supplierTermsVersion,
                          supplierTermsAccepted: firestoreNow,
                        };

                        if (values.phone) {
                          try {
                            userData.phone = formatPhone(values.phone);
                          } catch (e) {
                            Sentry.captureException(e);
                          }
                        }

                        try {
                          updateProfile(auth.currentUser, {
                            displayName: userFullName(userData),
                          });
                        } catch (e) {
                          Sentry.captureException(e);
                        }

                        let userDocSetError: Error;

                        try {
                          await setDoc(
                            doc(collection(database, 'users'), uid),
                            userData,
                            { merge: true }
                          );
                        } catch (e) {
                          userDocSetError = e;
                          Sentry.captureException(e);
                          throw new Error(userDocSetError.message);
                        } finally {
                          // if for any reason setting the user doc fails, remove from auth table
                          if (userDocSetError) {
                            await deleteAuthUser({ uid });
                          }
                        }

                        try {
                          window.analytics?.track('sign_up', {
                            id: uid,
                            roles: roleData
                          });
                        } catch (e) {
                          Sentry.captureException(e);
                        }

                        return { userCredential, roleData, userData };
                      })
                      .then(async ({ userCredential, roleData, userData }) => {
                        await setClaims({
                          claims: {
                            flags: {},
                            roles: roleData
                          },
                          userId: userData.docID
                        });

                        return { userCredential, roleData, userData };
                      })
                      .then(async ({ userCredential, roleData, userData }) => {
                        // Force token to update after we set roles
                        //
                        await userCredential.user.getIdToken(true)
                          .catch(Sentry.captureException);

                        userContext.setContext({
                          user: userCredential.user,
                          data: userData,
                          roles: roleData,
                        });
                      })
                      .then(() => {
                        if (router.location.state?.next) {
                          router.push(router.location.state.next);
                        } else {
                          router.push(routes.feed.path);
                        }
                      })
                      .catch((error) => {
                        setForceDisable(false);
                        Sentry.captureException(error);
                        setError(error.message);
                      });
                  }}
                >
                  {({
                    errors,
                    values,
                    touched,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                  }) => {
                    return (
                      <form
                        onSubmit={handleSubmit}
                      >
                        <Field
                          fullWidth
                          data-test="firstname"
                          name="firstname"
                          label="First name"
                          type="text"
                          component={TextField}
                          margin="dense"
                          variant="outlined"
                        />

                        <Field
                          fullWidth
                          data-test="lastname"
                          name="lastname"
                          label="Last name"
                          type="text"
                          component={TextField}
                          margin="dense"
                          variant="outlined"
                        />

                        <Field
                          fullWidth
                          data-test="email"
                          name="email"
                          label="Email"
                          type="text"
                          validate={validateEmailNotExistsDebounce}
                          component={TextField}
                          margin="dense"
                          variant="outlined"
                        />

                        <Field
                          fullWidth
                          data-test="phone"
                          name="phone"
                          label="Phone"
                          type="text"
                          component={TextFieldPhoneNumber}
                          margin="dense"
                          variant="outlined"
                          customInput={TextField}
                          onBlur={() => {
                            setFieldTouched('phone', true);
                          }}
                          onValueChange={(values) => {
                            setFieldValue('phone', values.value);
                          }}
                        />
                        {touched.phone && errors.phone &&
                          <FormHelperText error />
                        }

                        <Field
                          fullWidth
                          name="password"
                          data-test="password"
                          label="Password"
                          type={showPassword ? 'text' : 'password'}
                          component={TextField}
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={toggleShowPassword}
                                >
                                  {showPassword ? <IconEyeClosed /> : <IconEyeOpen />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />

                        <LocationSearch
                          name="location"
                          label="Location"
                          margin="dense"
                          disabled={isSubmitting}
                          autocompleteRequestOptions={{
                            types: ['(cities)'],
                            componentRestrictions: {
                              country: 'us'
                            }
                          }}
                          touched={touched.location ? true : false}
                          error={errors.location && errors.location.place_id}
                          onLocationChange={(location) => {
                            setFieldValue('location', location);
                          }}
                          onBlur={() => setFieldTouched('location', true)}
                        />

                        <Box
                          display="flex"
                          alignItems="center"
                          style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(1) }}
                        >
                          <Typography style={{ marginRight: theme.spacing(1) }}>
                            I&apos;m a:
                          </Typography>

                          <ToggleButtonGroup
                            exclusive
                            value={values.role}
                            aria-label="role"
                            onChange={(_, role) => {
                              setFieldTouched('role', true);
                              setFieldValue('role', role);
                            }}
                          >
                            <ToggleButton
                              value="maker"
                              aria-label="Maker"
                              data-test="maker"
                            >
                              Maker
                            </ToggleButton>

                            <ToggleButton
                              value="realtor"
                              aria-label="Realtor"
                              data-test="realtor"
                            >
                              Realtor
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>

                        {touched.role && Boolean(errors.role) && (
                          <FormHelperText error>
                            {errors.role}
                          </FormHelperText>
                        )}

                        {error && (
                          <FormHelperText error>
                            {isError(error) ? error.message : error}
                          </FormHelperText>
                        )}

                        <div style={{ paddingTop: 20 }}>
                          <SubmitButton
                            fullWidth
                            isSubmitting={forceDisable || isSubmitting}
                            disabled={forceDisable || Boolean(Object.keys(errors).length)}
                            aria-label="Continue"
                            data-test="continue"
                            tooltip={!!Object.keys(errors).length && (
                              <div style={{ textAlign: 'center' }}>
                                {makeErrors(errors, touched, false).map((e, i) => (
                                  <div key={i}>
                                    {e}
                                  </div>
                                ))}
                              </div>
                            )}
                          >
                            Continue
                          </SubmitButton>
                        </div>

                        {values.role === 'maker' && (
                          <Box style={{ textAlign: 'center', marginTop: theme.spacing(2) }}>
                            <Typography style={{ color: theme.palette.common.black, fontSize: 14 }}>
                              By clicking &#8220;Continue&#8220; you agree to the
                              {' '}
                            </Typography>
                            <a
                              href={makeStoreUrl('/supplier-agreement')}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Typography style={{ color: theme.palette.common.black, fontSize: 14, textDecoration: 'underline' }}>
                                Guest House Supplier Agreement.
                              </Typography>
                            </a>
                          </Box>)
                        }

                      </form>
                    );
                  }}
                </Formik>

                {!md && (
                  <div style={{ paddingTop: 20, textAlign: 'center' }}>
                    <Link
                      component={RouterLink}
                      to={generatePath(routes.login.path)}
                      className={classes.register}
                    >
                      <h3 className={classes.h3}>
                        Login
                      </h3>
                    </Link>
                  </div>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(Register);
