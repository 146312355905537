import { functions } from '@app/firebase';
import UserContext from '@context/UserContext';
import IconMoreHoriz from '@mui/icons-material/MoreHoriz';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useStyles } from '@ui/utils/makeStyles';
import { httpsCallable } from 'firebase/functions';
import React, { useContext, useState } from 'react';
import { Channel } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';


const adminChatMarkRead = httpsCallable(functions, 'http-adminChatMarkRead');

type AdminChatActionsProps = {
  channel: Channel;
  setTriggerGetChannels: React.Dispatch<React.SetStateAction<boolean>>,
}

const AdminChatActions: React.FC<AdminChatActionsProps> = (props) => {
  const { channel, setTriggerGetChannels } = props;
  const { theme } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const userContext = useContext<UserContext>(UserContext);
  const { setActiveChannel } = useChatContext();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        zIndex: 100
      }}
    >
      <IconButton
        edge="end"
        aria-label="message actions"
        aria-haspopup="true"
        size="small"
        style={{
          width: theme.spacing(3),
          height: theme.spacing(3),
          padding: 0,
        }}
        onClick={handleClick}
      >
        <IconMoreHoriz
          style={{
            width: 20,
            height: 20,
            fill: '#080808',
          }}
        />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <MenuItem
          style={{
            padding: theme.spacing(.5)
          }}
          onClick={async () => {
            await adminChatMarkRead({
              user: userContext.data,
              channelCID: channel.cid,
              channelType: channel.type,
            });

            setTriggerGetChannels(true);

            setActiveChannel(null);

            handleClose();
          }}
        >
          <Typography
            component="div"
            variant="subtitle2"
          >
            Mark read
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AdminChatActions;
