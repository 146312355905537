import PrimaryImage from '@components/primary-image/PrimaryImage';
import Avatar from '@mui/material/Avatar';
import theme from '@ui/theme';
import makeStyles from '@ui/utils/makeStyles';
import staticMap from '@ui/utils/staticMap';
import stringAvatar from '@ui/utils/stringAvatar';
import React from 'react';
import { Channel } from 'stream-chat';

import useOtherMembers from './useOtherMembers';

type ChatAvatarsProps = {
  channel?: Channel;
  home?: guesthouse.Home;
  styleHomeImage?: React.CSSProperties;
  styleAvatar?: React.CSSProperties;
}

const useStyles = makeStyles()({
  smallAvatar: {
    height: 16,
    width: 16,
    fontSize: 8,
  },
  mediumAvatar: {
    height: 20,
    width: 20,
    fontSize: 10,
  },
  image: {
    objectFit: 'cover',
    overflow: 'hidden',
  },
  imageWrapper: {
    position: 'relative',
    width: 44,
    backgroundBlendMode: 'darken',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  homeImageWrapper: {
    position: 'relative',
    width: 44,
    height: 37,
    backgroundBlendMode: 'darken',
    borderRadius: 5,
    overflow: 'hidden',
  },
});

const ChatAvatars = (props: ChatAvatarsProps) => {
  const { channel, home, styleHomeImage, styleAvatar } = props;
  const { classes } = useStyles();

  const channelType = channel?.type;
  const chatMembers = useOtherMembers(channel);
  const chatMemberCount = chatMembers.length;

  if (!channel) {
    return null;
  }

  return (
    <>
      {channelType !== 'home' && (
        <div
          className={classes.imageWrapper}
          style={styleAvatar}
        >
          {chatMemberCount === 1 &&
            <Avatar
              {...stringAvatar(chatMembers[0].user.name)}
              src={chatMembers[0].user.image}
              style={{ height: 36, width: 36 }}
            />
          }
          {chatMemberCount === 2 &&
            <div style={{ display: 'flex', flexDirection: 'column', width: 34 }}>
              <Avatar
                {...stringAvatar(chatMembers[0].user.name)}
                src={chatMembers[0].user.image}
                className={classes.mediumAvatar}
                style={{ alignSelf: 'flex-start' }}
              />
              <Avatar
                {...stringAvatar(chatMembers[1].user.name)}
                src={chatMembers[1].user.image}
                className={classes.mediumAvatar}
                style={{ alignSelf: 'flex-end' }}
              />
            </div>
          }
          {chatMemberCount === 3 &&
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar
                  {...stringAvatar(chatMembers[0].user.name)}
                  src={chatMembers[0].user.image}
                  className={classes.smallAvatar}
                  style={{ marginRight: theme.spacing(0.25) }}
                />
                <Avatar
                  {...stringAvatar(chatMembers[1].user.name)}
                  src={chatMembers[1].user.image}
                  className={classes.smallAvatar}
                />
              </div>
              <Avatar
                {...stringAvatar(chatMembers[2].user.name)}
                src={chatMembers[2].user.image}
                className={classes.smallAvatar}
              />
            </div>
          }
          {chatMemberCount === 4 &&
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar
                  {...stringAvatar(chatMembers[0].user.name)}
                  src={chatMembers[0].user.image}
                  className={classes.smallAvatar}
                  style={{ margin: theme.spacing(0, 0.25, 0.25, 0) }}
                />
                <Avatar
                  {...stringAvatar(chatMembers[1].user.name)}
                  src={chatMembers[1].user.image}
                  className={classes.smallAvatar}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <Avatar
                  {...stringAvatar(chatMembers[2].user.name)}
                  src={chatMembers[2].user.image}
                  className={classes.smallAvatar}
                  style={{ marginRight: theme.spacing(0.25) }}
                />
                <Avatar
                  {...stringAvatar(chatMembers[3].user.name)}
                  src={chatMembers[3].user.image}
                  className={classes.smallAvatar}
                />
              </div>
            </div>
          }
          {chatMemberCount > 4 &&
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar
                  {...stringAvatar(chatMembers[0].user.name)}
                  src={chatMembers[0].user.image}
                  className={classes.smallAvatar}
                  style={{ margin: theme.spacing(0, 0.25, 0.25, 0) }}
                />
                <Avatar
                  {...stringAvatar(chatMembers[1].user.name)}
                  src={chatMembers[1].user.image}
                  className={classes.smallAvatar}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <Avatar
                  {...stringAvatar(chatMembers[2].user.name)}
                  src={chatMembers[2].user.image}
                  className={classes.smallAvatar}
                  style={{ marginRight: theme.spacing(0.25) }}
                />
                <Avatar
                  className={classes.smallAvatar}
                  style={{ backgroundColor: theme.palette.common.black, fontSize: 11 }}
                >
                  {`+${(chatMemberCount - 3)}`}
                </Avatar>
              </div>
            </div>
          }
        </div>
      )}
      {channelType === 'home' && (
        <div
          className={classes.homeImageWrapper}
          style={{
            width: 44,
            height: 37,
            ...styleHomeImage,
          }}
        >
          {home && (
            <PrimaryImage
              item={home}
              fallback={staticMap(home.address)}
              className={classes.image}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ChatAvatars;
