import HomeGridSkeleton from '@components/home-grid/HomeGrid.skeleton';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useStyles } from '@ui/utils/makeStyles';
import React from 'react';

const CalendarsSkeleton = () => {
  const { theme } = useStyles();

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Skeleton
            variant="text"
            width={500}
            height={50}
          />
        </Grid>
      </Grid>
      <HomeGridSkeleton />
    </>
  );
};

export default React.memo(CalendarsSkeleton);
