import { motion } from 'framer-motion';
import { omit } from 'lodash';
import React from 'react';

const TOTAL_DURATION = 3;

interface SplashAnimationProps extends React.SVGProps<SVGSVGElement> {
  onAnimationComplete?: (definition : any) => void;
}

export default function SplashAnimation(props: SplashAnimationProps) {
  const svgProps = omit(props, 'onAnimationComplete');

  return (
    <svg
      width="1005px"
      height="116px"
      viewBox="0 0 1005 116"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...svgProps}
    >
      <title>
        {'Guesthouse Logo'}
      </title>

      <motion.g
        id="Page-1"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <motion.g id="Guesthouse-Logo">
          <motion.rect
            id="Background"
            fill="#FFFFFF"
            x={0}
            y={0}
            width={1005}
            height={116}
          />

          <motion.g
            id="Wordmark"
            transform="translate(-400, 6.189944)"
            fill="#171D1D"
            animate={{
              translateX: [
                -400,
                -400,
                -400,
                -400,
                -400,
                -400,
                -400,
                162.217877,
                162.217877,
                162.217877,
              ],
              opacity: [1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
            }}
            transition={{
              duration: TOTAL_DURATION,
            }}
            onAnimationComplete={props.onAnimationComplete}
          >
            <motion.path
              d="M787.535409,60.1545624 C788.116488,52.6201117 794.245935,44.5046555 804.892804,44.5046555 C816.563121,44.5046555 822.10774,51.8938547 822.396405,60.1545624 L787.535409,60.1545624 Z M839.465135,67.1117318 C839.465135,44.7914339 826.628911,30.4450652 804.746597,30.4450652 C786.808123,30.4450652 770.178014,45.2271881 770.178014,67.5437616 C770.178014,91.4543762 787.242995,105.368715 806.497332,105.368715 C823.562313,105.368715 834.794009,95.3687151 838.587893,83.0484171 L824.293348,78.556797 C821.961533,85.5139665 816.705579,90.877095 806.497332,90.877095 C795.99667,90.877095 787.535409,83.1973929 787.096788,72.4711359 L839.022765,72.4711359 C839.168972,72.1806331 839.465135,69.8640596 839.465135,67.1117318 L839.465135,67.1117318 Z"
              id="e"
            />

            <motion.path
              d="M738.064669,60.4014698 L727.327103,58.2157924 C722.974641,57.3385222 720.218455,54.5679979 720.218455,50.7739917 C720.218455,46.3988877 724.425462,42.6048816 730.3742,42.6048816 C739.810129,42.6048816 742.853496,49.169412 743.435316,53.2558416 L757.656343,49.169412 C756.492702,42.1699955 750.107599,29.3296089 730.3742,29.3296089 C715.720538,29.3296089 704.401152,39.8343573 704.401152,52.2323597 C704.401152,62.0060495 710.93171,70.1751597 723.407277,72.945684 L733.857661,75.2813221 C739.519219,76.4472667 742.12995,79.3640027 742.12995,83.1580089 C742.12995,87.5331129 738.501034,91.327119 731.534111,91.327119 C722.538276,91.327119 717.749449,85.6361098 717.167629,79.22154 L702.513966,83.3042206 C703.382967,91.327119 711.22262,104.748603 731.679566,104.748603 C749.674964,104.748603 758.379888,92.785487 758.379888,81.9920643 C758.379888,71.487316 751.416695,63.3182058 738.064669,60.4014698"
              id="s"
            />

            <motion.path
              d="M672.986323,73.7208104 C672.986323,84.1380031 667.238642,89.6281955 657.763039,89.6281955 C648.520196,89.6281955 642.656135,84.1380031 642.656135,73.7208104 L642.656135,32.122905 L627.094972,32.122905 L627.094972,74.8397355 C627.094972,94.3314107 640.741492,104.748603 657.763039,104.748603 C674.90472,104.748603 688.547486,94.3314107 688.547486,74.8397355 L688.547486,32.122905 L672.986323,32.122905 L672.986323,73.7208104 Z"
              id="u"
            />

            <motion.path
              d="M576.045745,89.5755734 C565.637412,89.5755734 556.110566,81.6984951 556.110566,66.9641221 C556.110566,52.2334982 565.637412,44.6451086 576.045745,44.6451086 C586.597225,44.6451086 595.977156,52.2334982 595.977156,66.9641221 C595.977156,81.8447141 586.597225,89.5755734 576.045745,89.5755734 M576.045745,29.3296089 C554.788335,29.3296089 539.106145,45.2299845 539.106145,66.9641221 C539.106145,88.8482278 554.788335,104.748603 576.045745,104.748603 C597.446302,104.748603 613.128492,88.8482278 613.128492,66.9641221 C613.128492,45.2299845 597.446302,29.3296089 576.045745,29.3296089"
              id="o"
            />

            <motion.polygon
              id="H"
              points="506.078239 44.1071639 457.462581 44.1071639 457.462581 1.39664804 439.944134 1.39664804 439.944134 104.748603 457.462581 104.748603 457.462581 60.5769383 506.078239 60.5769383 506.078239 104.748603 523.743017 104.748603 523.743017 1.39664804 506.078239 1.39664804"
            />

            <motion.path
              d="M366.43473,80.8443338 L366.43473,47.9043178 L381.142738,47.9043178 L381.142738,32.8896944 L366.43473,32.8896944 L366.43473,11.1731844 L351.150525,11.1731844 L351.150525,21.8122888 C351.150525,28.0796204 347.805585,32.8896944 340.232701,32.8896944 L336.592179,32.8896944 L336.592179,47.9043178 L349.691323,47.9043178 L349.691323,83.6127487 C349.691323,96.8779138 357.847888,104.748603 370.947031,104.748603 C377.064455,104.748603 380.263475,103.583546 381.284916,103.145245 L381.284916,89.1533245 C380.555315,89.2994249 377.790315,89.7377261 375.463074,89.7377261 C369.199731,89.7377261 366.43473,87.1116654 366.43473,80.8443338"
              id="t"
            />

            <motion.path
              d="M306.500423,60.4014698 L295.762858,58.2157924 C291.410395,57.3385222 288.654209,54.5679979 288.654209,50.7739917 C288.654209,46.3988877 292.861216,42.6048816 298.809954,42.6048816 C308.245883,42.6048816 311.28925,49.169412 311.87107,53.2558416 L326.092097,49.169412 C324.928457,42.1699955 318.543354,29.3296089 298.809954,29.3296089 C284.156292,29.3296089 272.836906,39.8343573 272.836906,52.2323597 C272.836906,62.0060495 279.367465,70.1751597 291.843031,72.945684 L302.293416,75.2813221 C307.954973,76.4472667 310.565704,79.3640027 310.565704,83.1580089 C310.565704,87.5331129 306.936788,91.327119 299.973594,91.327119 C290.97403,91.327119 286.188933,85.6361098 285.603383,79.22154 L270.949721,83.3042206 C271.818721,91.327119 279.658375,104.748603 300.11532,104.748603 C318.110718,104.748603 326.815642,92.785487 326.815642,81.9920643 C326.815642,71.487316 319.852449,63.3182058 306.500423,60.4014698"
              id="s"
            />

            <motion.path
              d="M206.041481,59.2355252 C206.630912,51.6512619 212.804819,43.4821518 223.539255,43.4821518 C235.297638,43.4821518 240.885893,50.9202034 241.180608,59.2355252 L206.041481,59.2355252 Z M223.391898,29.3296089 C205.308472,29.3296089 188.547486,44.2094612 188.547486,66.6735769 C188.547486,90.7422722 205.750545,104.748603 225.156411,104.748603 C242.351912,104.748603 253.672001,94.6824901 257.495742,82.2807387 L243.092479,77.759423 C240.738535,84.7625886 235.444996,90.1611744 225.156411,90.1611744 C214.569332,90.1611744 206.041481,82.4306994 205.603187,71.6335277 L257.937815,71.6335277 C258.085173,71.3411043 258.379888,69.0092151 258.379888,66.2386908 C258.379888,43.7708261 245.442644,29.3296089 223.391898,29.3296089 L223.391898,29.3296089 Z"
              id="e"
            />

            <motion.path
              d="M160.416491,73.7208104 C160.416491,84.1380031 154.665055,89.6281955 145.193207,89.6281955 C135.94661,89.6281955 130.086302,84.1380031 130.086302,73.7208104 L130.086302,32.122905 L114.52514,32.122905 L114.52514,74.8397355 C114.52514,94.3314107 128.167906,104.748603 145.193207,104.748603 C162.334888,104.748603 175.977654,94.3314107 175.977654,74.8397355 L175.977654,32.122905 L160.416491,32.122905 L160.416491,73.7208104 Z"
              id="u"
            />

            <motion.path
              d="M82.6099133,37.6923998 L99.1620112,31.4315268 C93.5982175,14.4067398 77.4827908,0 52.2913787,0 C25.3419884,0 0,19.7924369 0,53.6998884 C0,87.7532026 24.0206815,107.541899 51.1168837,107.541899 C51.2185227,107.541899 51.3088684,107.534419 51.406743,107.534419 L51.3954498,107.541899 C106.687061,107.541899 98.7065179,53.1164379 98.7065179,53.1164379 L98.4279518,53.1164379 L91.7273072,53.1164379 L47.6046919,53.1164379 L47.6046919,68.3946134 L81.8758539,68.3946134 C80.998747,77.2735336 73.0897278,91.5344108 52.1445668,91.5344108 C34.2749264,91.5344108 17.8696404,78.8742824 17.8696404,53.6998884 C17.8696404,28.0841665 35.445657,16.1533513 52.2913787,16.1533513 C68.8397122,16.1533513 79.0939571,26.0495698 82.6099133,37.6923998 Z"
              id="G"
            />
          </motion.g>

          <motion.rect
            id="Mask"
            fill="#FFFFFF"
            x={0}
            y={0}
            width={1005}
            height={116}
            animate={{
              width: [600, 600, 600, 600, 600, 600, 600, 100, 100, 100],
            }}
            transition={{
              duration: TOTAL_DURATION,
            }}
          />

          <motion.g
            id="Icon"
            transform="translate(451, 2.000000)"
            fill="#171D1D"
            animate={{
              translateX: [
                451,
                451,
                451,
                451,
                451,
                451,
                451,
                3,
                3,
                3,
              ],
              opacity: [1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
            }}
            transition={{
              duration: TOTAL_DURATION,
            }}
          >
            <motion.path
              d="M-0.000746380729,77.1048612 C-0.000746380729,94.5716571 17.9310506,108.908565 40.072435,109.390949 L40.072435,0.461862498 C17.9310506,0.944246711 -0.000746380729,15.2811543 -0.000746380729,32.7479501 L-0.000746380729,77.1048612 Z"
              id="L"
              animate={{
                translateX: [-30, 0, 0, 0],
                opacity: [0, 1, 1, 1],
              }}
              transition={{
                delay: .5,
                bounce: 1,
              }}
            />

            <motion.path
              d="M54.9015273,20.928789 C54.9015273,32.233033 64.0484231,41.3945936 75.3299679,41.3945936 C86.6077807,41.3945936 95.7546765,32.233033 95.7546765,20.928789 C95.7546765,9.62828438 86.6077807,0.462984321 75.3299679,0.462984321 C64.0484231,0.462984321 54.9015273,9.62828438 54.9015273,20.928789 Z"
              id="TR"
              animate={{
                translateY: [-30, 0, 0, 0],
                opacity: [0, 1, 1, 1],
              }}
              transition={{
                delay: 0.7,
                bounce: 1,
              }}
            />

            <motion.path
              d="M55.57327,109.389827 C77.7183862,108.907443 95.6427194,94.5742746 95.6427194,77.1037394 L95.6427194,57.4905208 L55.57327,57.4905208 L55.57327,109.389827 Z"
              id="BR"
              animate={{
                translateX: [30, 0, 0, 0],
                translateY: [15, 0, 0, 0],
                opacity: [0, 1, 1, 1],
              }}
              transition={{
                delay: 1.1,
                bounce: 1,
              }}
            />
          </motion.g>
        </motion.g>
      </motion.g>
    </svg>
  );
}
