import { functions } from '@app/firebase';
import { httpsCallable } from 'firebase/functions';
import referralCodes from 'referral-codes';

type ValidateCodeRequest = {code: string, collection: string};
const validateCode = httpsCallable<ValidateCodeRequest, boolean>(functions, 'http-validateCode');

export const generatePromoCode = async (config: referralCodes.GenerateConfig = {}, ignoreValidation?: boolean) => {
  let code = '';
  let unique = false;

  while (!unique) {
    code = referralCodes.generate({
      length: 6,
      count: 1,
      charset: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      ...config,
    })[0];

    if (!ignoreValidation) {
      const { data } = await validateCode({ code, collection: 'coupons' });

      unique = data;
    } else {
      unique = true;
    }

  }

  return code;
};
