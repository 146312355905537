import { RouterLink } from '@components/link-behavior/LinkBehavior';
import GuesthouseLogo from '@images/qr-icon.png';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import theme from '@ui/theme';
import React from 'react';
import { Message, MessageActionsArray, MessageStatus, useMessageContext } from 'stream-chat-react';

const CustomMessage = () => {
  const { message } = useMessageContext();
  const messageActions : MessageActionsArray = ['edit', 'delete', 'react'];

  if (message.message_type === 'system') {
    return (
      <div 
        style={{
          backgroundColor: theme.palette.common.white,
          borderRadius: 20,
          margin: theme.spacing(2),
          padding: theme.spacing(1, 2),
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >          
        <Avatar
          src={GuesthouseLogo}
          style={{ height: 24, width: 24, padding: theme.spacing(0.5) }}
        />
        <Typography style={{ fontSize: 12, marginLeft: theme.spacing(2), padding: 0, lineHeight: 2, flex: 1 }}>
          {message.text}
        </Typography>
        <div>
          {message.action_label && (
            <Link
              component={RouterLink}
              to={message.action_path}
            >
              <Typography style={{ fontSize: 12, fontWeight: 600, whiteSpace: 'nowrap', marginLeft: theme.spacing(2) }}>
                {message.action_label as string}
              </Typography>
            </Link>
          )}
        </div>
      </div>
    );
  }
  
  return (
    <>
      <Message
        message={message}
        messageActions={messageActions}
      />
      <MessageStatus />
    </>
  );
};

export default CustomMessage;
