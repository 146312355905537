import CalendarRecurrenceDialog from '@components/dialogs/CalendarRecurrenceDialog';
import LocationSearch from '@components/location-search/LocationSearch';
import UserSearch from '@components/user-search/UserSearch';
import { googleCalendarColors } from '@data';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import theme from '@ui/theme';
import toJsDate from '@ui/utils/toJsDate';
import { isError } from '@ui/utils/typescriptHelpers';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState }  from 'react';
import * as Yup from 'yup';

const CalendarEventForm = ({ initialValues, onSubmit, error, submitText='Add Event', selected_date, disabled = false }) => {
  const [openCustomRecurrence, setOpenCustomRecurrence] = useState<boolean>();
  const [recurrenceSelectedDays, setRecurrenceSelectedDays] = useState<string[]>([]);
  const [recurrenceEndDate, setRecurrenceEndDate] = useState<Date>(null);

  const handleCloseRecurrenceDialog = () => {
    setOpenCustomRecurrence(false);
  };

  const calendarEventSchema = Yup.object().shape({
    summary: Yup.string()
      .nullable(),
    description: Yup.string()
      .nullable(),
    location: Yup.string()
      .nullable(),
    start_time: Yup.date()
      .required('Start time is required'),
    end_time: Yup.date()
      .required('End time is required'),
    colorId: Yup.number()
      .nullable(),
    recurring_event: Yup.boolean()
      .nullable(),
    recurrence_end_date: Yup.date()
      .nullable(),
    recurrence_days: Yup.array(Yup.string())
      .nullable()
  });
  
  return (
    <Formik
      validationSchema={calendarEventSchema}
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
      }) => {
        return (
          <form
            style={{
              width: '100%'
            }}
            onSubmit={handleSubmit}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <FormControl
                fullWidth
                variant="outlined"
                margin="normal"
                error={touched.type && Boolean(errors.event_color)}
              >
                <InputLabel id="type-label">
                  Event Type
                </InputLabel>
                <Select
                  label="Event Type"
                  value={values.colorId}
                  onChange={(event) => {
                    setFieldValue('colorId', event.target.value);
                  }}
                >
                  {googleCalendarColors
                    .map((colorObj, i) => {
                      if (!colorObj.eventType) {
                        return;
                      }
                      
                      return (
                        <MenuItem
                          key={i}
                          value={colorObj.googleColorId}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            <div
                              style={{
                                height: 25,
                                width: 25,
                                backgroundColor: colorObj.hexColor,
                                borderRadius: '50%',
                                display: 'inline-block'
                              }}
                            />
                            <Typography
                              style={{
                                textTransform: 'capitalize',
                                marginLeft: theme.spacing(1.5)
                              }}
                            >
                              {colorObj.eventType}
                            </Typography>
                          </div>
                        </MenuItem>
                      );
                    })
              }
                </Select>
              </FormControl>


              <Field
                fullWidth
                name="summary"
                label="Title"
                type="summary"
                component={TextField}
                margin="dense"
                variant="outlined"
                data-test="summary-field"
              />

              <Field
                fullWidth
                multiline
                name="description"
                label="Description"
                type="description"
                component={TextField}
                margin="dense"
                variant="outlined"
                data-test="description-field"
              />

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label="Start Time"
                  orientation="landscape"
                  views={['hours', 'minutes']}
                  value={toJsDate(values?.start_time) || null}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      margin: 'normal'
                    }
                  }}
                  onChange={(date) => {
                    if (date === null) {
                      setFieldValue('start_time', undefined);
                    } else {
                      setFieldValue('start_time', new Date(selected_date?.toDateString().concat(' ' + date.toTimeString())));
                    }
                  }}
                />
                <TimePicker
                  label="End Time"
                  orientation="landscape"
                  views={['hours', 'minutes']}
                  value={toJsDate(values?.end_time) || null}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      margin: 'normal'
                    }
                  }}
                  onChange={(date) => {
                    if (date === null) {
                      setFieldValue('end_time', undefined);
                    } else {
                      setFieldValue('end_time', new Date(selected_date?.toDateString().concat(' ' + date.toTimeString())));
                    }
                  }}
                />
              </LocalizationProvider >

              <UserSearch
                multiple={true}
                label="Guests"
                defaultValue={values?.attendees}
                freeSolo={true}
                style={{
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
                onChange={(e, value: guesthouse.User[]) => {
                  setFieldValue('attendees', value);
                }}
              />

              <LocationSearch
                name="location"
                label="Location"
                margin="dense"
                disabled={isSubmitting}
                defaultValue={values?.location}
                autocompleteRequestOptions={{
                  componentRestrictions: {
                    country: 'us'
                  }
                }}
                onLocationChange={(location) => {
                  setFieldValue('location', location?.address);
                }}
              />

              <Dialog
                open={openCustomRecurrence}
                PaperProps={{
                  style: {
                    padding: theme.spacing(3)
                  }
                }}
                onClose={() => setOpenCustomRecurrence(false)}
              >
                <CalendarRecurrenceDialog 
                  closeHandler={handleCloseRecurrenceDialog}
                  setFieldValue={setFieldValue}
                  recurrenceSelectedDays={recurrenceSelectedDays}
                  setRecurrenceSelectedDays={setRecurrenceSelectedDays}
                  recurrenceEndDate={recurrenceEndDate}
                  setRecurrenceEndDate={setRecurrenceEndDate}
                />
              </Dialog>

              <FormControlLabel
                control={
                  <Switch
                    checked={Boolean(recurrenceSelectedDays.length)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setOpenCustomRecurrence(true);
                      } else {
                        setRecurrenceSelectedDays([]);
                        setRecurrenceEndDate(null);
                      }
                    }}
                  />
                }
                label="Recurring Event"
              />
            </div>

            {error && (
              <FormHelperText error>
                {isError(error) ? error.message : error}
              </FormHelperText>
            )}

            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length) || disabled}
                aria-label={submitText}
                data-test="calendar-event-submit-button"
                tooltip={makeErrors(errors, touched)?.length && (
                  <TooltipErrors
                    errors={errors}
                    touched={touched}
                  />
                )}
              >
                {submitText}
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(CalendarEventForm);
