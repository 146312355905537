import 'stream-chat-react/dist/css/index.css';

import { database } from '@app/firebase';
import useRouter from '@hooks/useRouter';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import theme from '@ui/theme';
import { DARK_TEXT, ERROR_ALT, GREEN_DARK, GREEN_LIGHT, ORANGE_ALT, YELLOW } from '@ui/theme/theme';
import { caclulateChatAge } from '@utils/calculateChatSLAs';
import lastMessageTime from '@utils/lastMessageTime';
import { channelPath } from '@utils/paths';
import { collection, doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useMemo, useState } from 'react';
import { ChannelPreviewUIComponentProps, useChatContext } from 'stream-chat-react';

import ChatAvatars from '../in-app-messaging/ChatAvatars';
import useOtherMembers from '../in-app-messaging/useOtherMembers';
import useStyles from './AdminChannelListItem.style';
import AdminChatActions from './AdminChatActions';


type AdminChannelListItemAdditionalProps = {
  setTriggerGetChannels: React.Dispatch<React.SetStateAction<boolean>>,
}

const AdminChannelListItem: React.ComponentType<ChannelPreviewUIComponentProps<GHStreamGenerics> & AdminChannelListItemAdditionalProps> = (props) => {
  const { channel, latestMessage, setActiveChannel, watchers, setTriggerGetChannels } = props;
  const { channel: activeChannel } = useChatContext<GHStreamGenerics>();
  const { md } = useBreakpoints();
  const [home, setHome] = useState<guesthouse.Home>();
  const router = useRouter();

  const swatchColor = useMemo(() => {
    if (channel.id) {
      const hoursSinceMessage = caclulateChatAge(channel);

      if (hoursSinceMessage > 72) {
        return ERROR_ALT;
      }
      if (hoursSinceMessage > 48) {
        return ORANGE_ALT;
      }
      if (hoursSinceMessage > 24) {
        return YELLOW;
      }
      if (hoursSinceMessage > 8) {
        return GREEN_LIGHT;
      }

      return GREEN_DARK;
    }
  }, [channel]);

  // @ts-ignore
  const isActive = useMemo(() => channel?.cid === activeChannel?.cid, [channel, activeChannel]);

  const { classes } = useStyles({ swatchColor, isActive });

  const otherMembers = useOtherMembers(channel);

  const onSelectChannel = () => {
    if (setActiveChannel) {
      router.replace(channelPath(channel));
      setActiveChannel(channel, watchers);
    }
  };

  const latestMessageTime = useMemo(() => {
    if (channel.state.last_message_at) {
      return lastMessageTime(channel.state.last_message_at);
    }
  }, [channel.state.last_message_at]);

  useEffect(() => {
    async function getHome(docID) {
      const homeDoc = await getDoc(doc(collection(database, 'homes'), docID));

      if (homeDoc.exists()) {
        setHome(homeDoc.data() as guesthouse.Home);
      }
    }
    if (channel.type === 'home' && channel.data.home_id) {
      getHome(channel.data.home_id);
    }
  }, [channel.type]);

  const shouldIgnore = useMemo(() => {
    let channelMessages = [];

    if (channel?.state?.messages?.length) {
      channelMessages = [...channel.state.messages];
    }
    const dateEightHoursAgo = new Date(new Date().getTime() - (8 * 60 * 60 * 1000));
    let mostRecentMessage = channelMessages.pop();

    while (mostRecentMessage?.type === 'system' && channelMessages?.length > 0) {
      mostRecentMessage = channelMessages.pop();
    }
    if (mostRecentMessage?.created_at > dateEightHoursAgo) {
      return true;
    }
    return false;
  }, [channel]);

  if (shouldIgnore) {
    return null;
  }

  return (
    <div
      className={classes.container}
    >
      <Button
        variant={md && isActive ? 'outlined' : 'text'}
        className={classes.cardButton}
        onClick={onSelectChannel}
      >
        <div 
          className={classes.containerContentOuter}
        >

          <div
            className={classes.containerContentInner}
          >
            <div
              className={classes.unreadDot}
            />

            {otherMembers && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minWidth={72}
                height={74}
              >
                <ChatAvatars
                  channel={channel}
                  home={home}
                  styleHomeImage={{
                    height: 48,
                    width: 72,
                  }}
                  styleAvatar={{
                    height: 48,
                    width: 72,
                  }}
                />
                <Typography
                  component="div"
                  variant="caption2"
                  style={{
                    marginTop: theme.spacing(1),
                    color: DARK_TEXT,
                  }}
                >
                  {latestMessageTime}
                </Typography>
              </Box>
            )}
            <div
              className={classes.containerNameText}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom={theme.spacing(.5)}
                width="100%"
              >
                <Typography
                  variant="h6"
                  className={classes.name}
                >
                  {channel.type !== 'home' && otherMembers.map((member, i) => {
                    if (otherMembers.length > 1) {
                      if (i === otherMembers.length - 1) {
                        return (
                          member.user.name.split(' ')[0]
                        );
                      } else if (i === otherMembers.length - 2) {
                        return (
                          member.user.name.split(' ')[0] + ' & '
                        );
                      }
                      return (
                        member.user.name.split(' ')[0] + ', '
                      );
                    } else {
                      return (
                        member.user.name
                      );
                    }
                  })
                  }
                  {channel.type === 'home' && channel.data.name}
                </Typography>
                <AdminChatActions
                  channel={channel}
                  setTriggerGetChannels={setTriggerGetChannels}
                />
              </Box>
              <Typography
                variant="bodyMedium"
                component="div"
                className={classes.text}
              >
                {typeof latestMessage === 'object' && latestMessage?.props?.source && latestMessage.props.source}
                {typeof latestMessage === 'string' && latestMessage.includes('Attachment') && 'Attachment...'}
              </Typography>
            </div>
          </div>
        </div>
      </Button>
    </div>
  );
};

export default AdminChannelListItem;
