import HomeGrid from '@components/home-grid/HomeGrid';
import HomeGridSkeleton from '@components/home-grid/HomeGrid.skeleton';
import RoomGrid from '@components/room-grid/RoomGrid';
import SearchResults from '@components/search-results/SearchResults';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import useCenterPoint from '@hooks/useCenterPoint';
import useProductAvailability from '@hooks/useProductAvailability';
import algolia from '@integrations/Algolia';
import Sentry from '@integrations/Sentry';
import CommentIcon from '@mui/icons-material/Comment';
import HelpIcon from '@mui/icons-material/Help';
import IconSearch from '@mui/icons-material/Search';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import ProductOffer from '@ui/components/product-offer/ProductOffer';
import formatDate from '@ui/utils/formatDate';
import toJsDate from '@ui/utils/toJsDate';
import createProductRequests from '@utils/createProductRequests';
import makeIndexName from '@utils/makeIndexName';
import properCase from '@utils/properCase';
import React, { useContext, useMemo, useState } from 'react';

import Props, { defaultProps } from './RoomSearch.props';
import useStyles from './RoomSearch.style';

const homesInstallDateDescIndex = algolia.initIndex(makeIndexName('homes_install_date_desc'));

const RoomSearch: React.FC<Props> = (props: Props) => {
  const { product } = props;
  const [query, setQuery] = useState('');
  const [home, setHome] = useState<guesthouse.Home>(props.home);
  const { classes, theme } = useStyles();
  const [scheduleDate, setScheduleDate] = useState<Date>(toJsDate(props.home?.install_date) || new Date());
  const [scheduleTime, setScheduleTime] = useState<Date>(toJsDate(props.home?.install_date) || new Date());
  const userContext = useContext<UserContext>(UserContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const [coords, radius] = useCenterPoint(home);
  const { availableInternal, availableExternal } = useProductAvailability(scheduleDate, product.docID, coords, radius, true);
  const [selectedInternal, setSelectedInternal] = useState<guesthouse.ProductInventory[]>([]);
  const [selectedExternal, setSelectedExternal] = useState<guesthouse.ProductInventory[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submittingIndex, setSubmittingIndex] = useState<number>();

  const scheduled = useMemo(() => new Date(`${scheduleDate.toDateString()} ${scheduleTime.toTimeString()}`), [scheduleDate.toDateString(), scheduleTime.toTimeString()]);

  const requestAll = async (room, index) => {
    setIsSubmitting(true);
    setSubmittingIndex(index);

    return Promise.all([
      createProductRequests('external', 'individual', home, room, selectedExternal, scheduled, userContext.data),
      createProductRequests('internal', 'individual', home, room, selectedInternal, scheduled, userContext.data)
    ]);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
      >
        {
          home
            ? (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                style={{
                  marginTop: theme.spacing(2),
                }}
              >
                <Typography
                  variant="h4Alt"
                  component="h2"
                  style={{
                    marginRight: theme.spacing(2),
                  }}
                >
                  Request product for staging
                </Typography>
                <Chip
                  label={home.address}
                  onDelete={() => setHome(undefined)}
                />
              </Box>
            )
            : (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                style={{
                  marginTop: theme.spacing(2),
                }}
              >
                <Typography
                  variant="h4Alt"
                  component="h2"
                >
                  Request product for staging
                </Typography>
              </Box>
            )
        }
      </Box>
      {!home && (
        <Alert
          severity="info"
          style={{ margin: theme.spacing(2, 0) }}
        >
          Please select a home to continue
        </Alert>
      )}
      {
        home && (
          <>
            {!home.install_date && (
              <Alert
                severity="error"
                style={{ margin: theme.spacing(2, 0) }}
              >
                This home does not have an install date! Are you sure you should be scheduling products for delivery?
              </Alert>
            )}

            {(home.install_date || home.deinstall_date) && (
              <Alert
                severity="info"
                style={{ margin: theme.spacing(2, 0) }}
              >
                {home.install_date && `Installing on ${formatDate(toJsDate(home.install_date))}`}
                {home.install_date && home.deinstall_date && (
                  <>
                    &nbsp;&mdash;&nbsp;
                  </>
                )}
                {home.deinstall_date && `De-installing on ${formatDate(toJsDate(home.deinstall_date))}`}
              </Alert>
            )}

            <Alert
              severity={(availableInternal.length || availableExternal.length) ? 'info' : 'error'}
              style={{ margin: theme.spacing(2, 0) }}
            >
              Based on current inventory and staging, there will be
              {' '}
              <strong>
                {String(availableInternal.length)}
              </strong>
              {' '}
              available from Guest House, and
              {' '}
              <strong>
                {String(availableExternal.length)}
              </strong>
              {' '}
              available from the maker on
              {' '}
              <strong>
                {formatDate(scheduleDate)}
              </strong>
            </Alert>

            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StaticDatePicker
                    disablePast
                    displayStaticWrapperAs="mobile"
                    value={scheduleDate}
                    onChange={setScheduleDate}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box position="relative">
                    <StaticTimePicker
                      displayStaticWrapperAs="mobile"
                      value={scheduleTime}
                      onChange={setScheduleTime}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>

            </Grid>
          </>
        )
      }

      {!home && (
        <TextField
          fullWidth
          className={classes.searchField}
          placeholder="Search homes"
          variant="outlined"
          margin="dense"
          InputProps={{
            className: classes.searchFieldInput,
            startAdornment: (
              <InputAdornment position="start">
                <IconSearch className={classes.searchFieldIcon} />
              </InputAdornment>
            ),
          }}
          value={query}
          data-test="room-search-text-field"
          onChange={(e) => {
            setQuery(e.target.value);
            setHome(undefined);
          }}
        />
      )}

      {!home && (
        <Box margin={theme.spacing(2, 0, 0, 0)}>
          <SearchResults
            index={homesInstallDateDescIndex}
            query={query}
            aroundLatLng={coords ? coords?.join(',') : undefined}
            aroundRadius={radius ? radius : undefined}
            renderResults={({ hits: results }) => {
              if (!results.length) {
                return null;
              }

              return (

                <HomeGrid
                  spacing={4}
                  homes={results as guesthouse.AlgoliaHome[]}
                  onHomeClick={(_, home) => {
                    setHome(home);
                    setQuery('');
                    if (home.install_date) {
                      setScheduleDate(toJsDate(home.install_date) || new Date());
                    }
                  }}
                />

              );
            }}
            renderNoResults={() => {
              return (
                <>
                  <div style={{ marginBottom: theme.spacing(4) }}>
                    <Typography>
                      No homes matched your search
                    </Typography>
                  </div>
                </>
              );
            }}
            renderLoading={() => {
              return (
                <HomeGridSkeleton />
              );
            }}
          />
        </Box>
      )}

      {home && !!availableInternal?.length && (
        <>
          <Box
            display="flex"
            alignItems="center"
            style={{ margin: theme.spacing(2, 0, 1) }}
          >
            <Typography
              variant="h6Alt"
              style={{ marginRight: theme.spacing(1) }}
            >
              Select Guest House inventory
            </Typography>
            <Tooltip title="In transit inventory may not be used.">
              <HelpIcon />
            </Tooltip>
          </Box>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableCell width="110">
                  Status
                </TableCell>
                <TableCell>
                  Warehouse
                </TableCell>
                <TableCell width="100">
                  Type
                </TableCell>
                <TableCell width="100">
                  Condition
                </TableCell>
                <TableCell width="120">
                  Price
                </TableCell>
                <TableCell width="100">
                  Notes
                </TableCell>
                <TableCell width="100" />
              </TableHead>
              <TableBody>
                {availableInternal.filter(item => !item.roomSetId).map((availableInternal, i) => {
                  return (
                    <TableRow key={availableInternal.docID}>
                      <TableCell>
                        {properCase(availableInternal.status, '_')}
                      </TableCell>
                      <TableCell>
                        {availableInternal?.warehouse?.address}
                      </TableCell>
                      <TableCell>
                        {availableInternal?.offer?.type && properCase(availableInternal.offer.type)}
                      </TableCell>
                      <TableCell>
                        {availableInternal?.offer?.condition && properCase(availableInternal.offer.condition)}
                      </TableCell>
                      <TableCell>
                        {availableInternal?.offer && (
                          <ProductOffer offer={availableInternal.offer} />
                        )}
                      </TableCell>
                      <TableCell>
                        {availableInternal?.offer?.notes && (
                          <Tooltip title={availableInternal.offer.notes}>
                            <CommentIcon
                              style={{
                                verticalAlign: 'middle'
                              }}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          data-test={`room-search-internal-select-${i}`}
                          color="secondary"
                          size="small"
                          disabled={availableInternal.status === 'IN_TRANSIT' || isSubmitting}
                          variant={selectedInternal.find(si => availableInternal.docID === si.docID) ? 'outlined' : 'contained'}
                          onClick={() => {
                            if (selectedInternal.find(si => availableInternal.docID === si.docID)) {
                              setSelectedInternal(selectedInternal.filter(si => si.docID !== availableInternal.docID));
                            } else {
                              setSelectedInternal([...selectedInternal, availableInternal]);
                            }
                          }}
                        >
                          {
                            selectedInternal.find(si => availableInternal.docID === si.docID)
                              ? 'Remove'
                              : 'Select'
                          }
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}


      {home && !!availableExternal?.length && (
        <>
          <Box
            display="flex"
            alignItems="center"
            style={{ margin: theme.spacing(2, 0, 1) }}
          >
            <Typography
              variant="h6Alt"
              style={{ marginRight: theme.spacing(1) }}
            >
              Select maker inventory
            </Typography>
            <Tooltip title="Using maker inventory will notify the maker of this request. They may accept or deny this request. In transit inventory may not be used.">
              <HelpIcon />
            </Tooltip>
          </Box>
          <Table size="small">
            <TableHead>
              <TableCell width="110">
                Status
              </TableCell>
              <TableCell>
                Warehouse
              </TableCell>
              <TableCell width="100">
                Type
              </TableCell>
              <TableCell width="100">
                Condition
              </TableCell>
              <TableCell width="120">
                Price
              </TableCell>
              <TableCell width="100">
                Notes
              </TableCell>
              <TableCell width="100">

              </TableCell>
            </TableHead>
            <TableBody>
              {availableExternal.map((availableExternal, i) => {
                return (
                  <TableRow key={availableExternal.docID}>
                    <TableCell>
                      {properCase(availableExternal.status, '_')}
                    </TableCell>
                    <TableCell>
                      {availableExternal?.warehouse?.address}
                    </TableCell>
                    <TableCell>
                      {availableExternal?.offer?.type && properCase(availableExternal.offer.type)}
                    </TableCell>
                    <TableCell>
                      {availableExternal?.offer?.condition && properCase(availableExternal.offer.condition)}
                    </TableCell>
                    <TableCell>
                      {availableExternal?.offer && (
                        <ProductOffer offer={availableExternal.offer} />
                      )}
                    </TableCell>
                    <TableCell>
                      <Tooltip title={availableExternal?.offer?.notes || 'No notes.'}>
                        <CommentIcon
                          style={{
                            verticalAlign: 'middle'
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Button
                        data-test={`room-search-external-select-${i}`}
                        color="secondary"
                        size="small"
                        disabled={availableExternal.status === 'IN_TRANSIT' || isSubmitting}
                        variant={selectedExternal.find(si => availableExternal.docID === si.docID) ? 'outlined' : 'contained'}
                        onClick={() => {
                          if (selectedExternal.find(si => availableExternal.docID === si.docID)) {
                            setSelectedExternal(selectedExternal.filter(si => si.docID !== availableExternal.docID));
                          } else {
                            setSelectedExternal([...selectedExternal, availableExternal]);
                          }
                        }}
                      >
                        {
                          selectedExternal.find(si => availableExternal.docID === si.docID)
                            ? 'Remove'
                            : 'Select'
                        }
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}

      {home && (
        <>
          <Box
            display="flex"
            alignItems="center"
            style={{ margin: theme.spacing(2, 0, 1) }}
          >
            <Typography
              variant="h6Alt"
              style={{ marginRight: theme.spacing(1) }}
            >
              Select room
            </Typography>
          </Box>

          <RoomGrid
            home={home}
            allowAdd={false}
            cardStyle={{
              backgroundColor: '#f7f7f7',
            }}
            renderAction={({ home, room, index }) => {
              return (
                <Box
                  display="grid"
                  gap={5}
                >
                  <SubmitButton
                    data-test="room-search-add-to-room-button"
                    variant={(!selectedExternal.length && !selectedInternal.length) ? 'text' : 'contained'}
                    color="secondary"
                    size="small"
                    disabled={!selectedExternal.length && !selectedInternal.length || isSubmitting}
                    isSubmitting={isSubmitting && index === submittingIndex}
                    onClick={() => {
                      return requestAll(room, index)
                        .then(() => {
                          if (typeof props.onComplete === 'function') {
                            props.onComplete({
                              home: home,
                              room: room,
                              scheduleDate: scheduled,
                            });
                          }
                          setIsSubmitting(false);
                        })
                        .catch((e) => {
                          setIsSubmitting(false);
                          Sentry.captureException(e);
                          notificationContext.setContext({ open: true, message: 'There was a problem creating the request', severity: 'error' });
                        });
                    }}
                  >
                    {!!selectedInternal.length && !selectedExternal.length && `Add ${selectedInternal.length} from warehouse`}
                    {!selectedInternal.length && !!selectedExternal.length && `Request ${selectedExternal.length} from maker`}
                    {!!selectedInternal.length && !!selectedExternal.length && `Request ${selectedExternal.length} from maker, add ${selectedInternal.length} from warehouse`}
                    {!selectedInternal.length && !selectedExternal.length && 'Select inventory to continue'}
                  </SubmitButton>
                </Box>
              );
            }}
          />
        </>
      )}
    </>
  );
};

RoomSearch.defaultProps = defaultProps;

export default RoomSearch;
