import 'stream-chat-react/dist/css/index.css';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Counter from '@ui/components/counter/Counter';
import React, {  } from 'react';

import useStyles from './CountsTile.style';

type CountsTileProps = {
  count: number;
  swatchColor: string;
  subtitle: string;
  style?: React.CSSProperties;
}


const CountsTile: React.FC<CountsTileProps> = ({ count, swatchColor, subtitle, style = {} }) => {
  const { theme, classes } = useStyles({ swatchColor });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-around"
      className={classes.wrapper}
      style={{
        backgroundColor: theme.palette.secondary.main,
        ...style
      }}
    >
      <Typography
        variant="h3"
        component="div"
        style={{
          fontWeight: 450,
        }}
      >
        <Counter
          to={count}
          format={{ format: '%v', symbol: '0', minFraction: 0, maxFraction: 0 }}
          style={{
            marginLeft: 3,
          }}
        />
      </Typography>
      
      <Box
        height={15}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <div
          className={classes.dot}
        />
        <Typography
          variant="caption1"
          component="div"
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default CountsTile;
