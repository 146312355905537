
import * as Yup from 'yup';

export type ProductFormValues = {
  title?: string;
  slug?: string;
  description?: string;
  msrp?: number;
  dibs?: boolean;
  height?: number;
  width?: number;
  length?: number;
  category?: string[];
  category2?: string[];
  category3?: string[];
  color?: string[];
  style?: string[];
  owner?: Partial<guesthouse.User>;
  dimensional_units: 'feet' | 'inches' | 'meters' | 'centimeters';
  dimensions_visible?: string;
  shippingPolicy?: string;
  returnPolicy?: string;
  shipping_price_per_order?: number;
  shipping_price_per_product?: number;
  sku?: string;
};

export const initialValues: ProductFormValues = {
  category: [],
  category2: [],
  color: [],
  style: [],
  dimensional_units: 'inches',
};

const ProductSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required'),
  description: Yup.string(),
  dibs: Yup.boolean(),
  category: Yup.array(Yup.string())
    .min(1, 'At least one category is required')
    .required('At least one category is required'),
  category2: Yup.array(Yup.string())
    .min(1, 'At least one subcategory is required')
    .required('At least one subcategory is required')
});

export default ProductSchema;
