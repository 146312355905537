import React, { Fragment } from 'react';

const Nl2br = ({ text }) => {
  return text.split('\n').map((item, key) => {
    return (
      <Fragment key={key}>
        {item}
        <br />
      </Fragment>
    );
  });
};

export default Nl2br;
