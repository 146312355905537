import makeStyles from '../../utils/makeStyles';

export default makeStyles()((theme) => ({
  notificationPreference: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px #DDDDDD solid',
    padding: theme.spacing(2, 0)
  }
}));
