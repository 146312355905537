import { database } from '@app/firebase';
import OnOff, { OnOffProps } from '@components/on-off/OnOff';
import useCollection from '@hooks/useCollection';
import properCase from '@utils/properCase';
import { collection, doc, getDoc, query, where } from 'firebase/firestore';
import React,  { useEffect, useState } from 'react';
import Stripe from 'stripe';

interface HomeSubscriptionProps extends OnOffProps {
  home: Partial<guesthouse.Home>;
}

const HomeSubscription = (props: HomeSubscriptionProps) => {
  const { home, ...rest } = props;
  const [subscription, setSubscription] = useState<Stripe.Subscription>();

  const { collection: orders } = useCollection(query(collection(database, 'orders')
    ,where('home.docID', '==', home.docID)
    ,where('type', '==', 'staging')
    ,where('status', '==', 'COMPLETE')));
  
  useEffect(() => {
    const getSubscription = async () => {
      if (orders?.docs.length > 0) {
        for (const order of orders.docs) {
          const orderData = order.data();
          
          if (!orderData.subscriptionID) {
            continue;
          }

          const subscription = await getDoc(doc(collection(database, 'subscriptions'), orderData?.subscriptionID));
          const subscriptionData = subscription.data() as Stripe.Subscription;

          setSubscription(subscriptionData);
        }
      }
    };

    getSubscription();
  }, [orders]);

  return (
    <OnOff
      on={(subscription?.status === 'active' || subscription?.status === 'trialing')}
      direction="rtl"
      offLabel={properCase(subscription?.status) || 'Inactive'}
      onLabel={properCase(subscription?.status)}
      {...rest}
    />
  );
};

export default HomeSubscription;
