import ProductGrid from '@components/product-grid/ProductGrid';
import ProductGridSkeleton from '@components/product-grid/ProductGrid.skeleton';
import { CATEGORIES, COLORS, STYLES } from '@data';
import { ScrollElement } from '@hooks/useInfiniteScroll';
import useProductFilterSearch from '@hooks/useProductFilterSearch';
import SvgIconSearch from '@icons/IconSearch';
import Sentry from '@integrations/Sentry';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StopIcon from '@mui/icons-material/Stop';
import { InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Protected from '@ui/components/protected/Protected';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import checkRoles from '@ui/utils/checkRoles';
import { addFilter, initPagedResults, removeFilter, removeSubcategoryFilters, UseFacetSearchOptions } from '@utils/algoliaSearch';
import properCase from '@utils/properCase';
import startOfDay from 'date-fns/startOfDay';
import React, { useState } from 'react';

import IconFilters from '../../icons/iconFilters';
import useStyles from './ProductSearchDialog.styles';

type ProductSearchDialogProps = {
  title: string;
  userContext: UserContext;
  appContext: AppContext;
  showFilters?: boolean;
  extraFacetFilters?: UseFacetSearchOptions<guesthouse.Product>['extraFacetFilters'];
  resolve: (product: guesthouse.Product) => void;
  reject: (e?: Error | boolean) => void;
}

const ProductSearchDialog: React.FC<ProductSearchDialogProps> = (props) => {
  const { title, userContext, appContext, showFilters = true, extraFacetFilters, resolve, reject } = props;
  const { md } = useBreakpoints();
  const { classes, theme, cx } = useStyles();

  const [open, setOpen] = useState<boolean>(true);
  const [searchString, setSearchString] = useState('');
  const [openFilters, setOpenFilters] = useState(false);

  const {
    startDate,
    setStartDate,
    setAvailableTotal,
    published,
    setPublished,
    inventoryOpen,
    setInventoryOpen,
    inventoryInternal,
    setInventoryInternal,
    inventoryExternal,
    setInventoryExternal,
    categoriesOpen,
    setCategoriesOpen,
    setPage,
    products,
    setProducts,
    filters,
    clearFilters,
    stylesOpen,
    setStylesOpen,
    colorsOpen,
    setColorsOpen,
    appliedFilters,
    results,
    startDateObject,
    endOfContainerElement,
  } = useProductFilterSearch(userContext, appContext, {
    queryString: searchString,
    setSearchContext: false,
    useRouterQuery: false,
    extraFacetFilters,
  });

  return (
    <Dialog
      disableEnforceFocus
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={() => {
        setOpen(false);
        reject(false);
      }}
    >
      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        <div
          style={{ display: 'flex', flexDirection: 'row', position: 'relative', minHeight: 'calc(100vh - 64px)', }}
        >
          {openFilters && (
            <div
              className={cx({
                [classes.filtersContainer]: true,
                [classes.filtersContainerOpen]: openFilters,
              })}
            >
              <div className={classes.filtersContainerHeader}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    style={{ color: theme.palette.common.black }}
                    size="large"
                    onClick={() => setOpenFilters(false)}
                  >
                    <CloseIcon style={{ height: 30, width: 30 }} />
                  </IconButton>
                  <Typography
                    fontFamily={theme.gh_vars.circular}
                    style={{ fontSize: 24, display: 'inline-block' }}
                  >
                    Filter results
                  </Typography>
                </div>
                <Typography
                  fontFamily={theme.gh_vars.circular}
                  style={{ fontSize: 16, marginTop: theme.spacing(2), marginRight: theme.spacing(2) }}
                >
                  {results.nbHits}
                  {' '}
                  Items
                </Typography>
              </div>
              <div className={classes.filtersContainerInner}>
                <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ padding: md ? theme.spacing(0.5, 3) : theme.spacing(0.5, 2), marginBottom: theme.spacing(2) }}
                  >
                    <Typography
                      className={classes.filterText}
                    >
                      Date needed
                    </Typography>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {startDate ?
                        <IconButton
                          style={{ color: theme.palette.common.black, padding: 0, marginRight: md ? theme.spacing(1) : theme.spacing(1) }}
                          size="large"
                          onClick={() => {
                            setStartDate(null);
                            setAvailableTotal(undefined);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                        : null}

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disablePast
                          value={startDateObject || null}
                          slotProps={{
                            textField: {
                              style:{
                                backgroundColor: '#fff',
                                width: md ? 160 : 130,
                                borderRadius: theme.shape.borderRadius,
                                margin: 0
                              },
                              variant: 'outlined',
                              margin: 'dense',
                              helperText: '',
                              placeholder: '__/__/____',
                              size: 'small',
                              inputProps: {
                                ['data-test']: 'install_date'
                              }
                            },
                            inputAdornment: { position: 'start' }
                          }}
                          onChange={(date) => {
                            if (date) {
                              setAvailableTotal('1');
                              setStartDate(String(startOfDay(date).getTime() / 1000));
                            } else {
                              setAvailableTotal(undefined);
                              setStartDate(null);
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ padding: md ? theme.spacing(0.5, 3) : theme.spacing(0.5, 2) }}
                  >
                    <Tooltip title={published ? 'Show all products' : 'Only show published products'}>
                      <Typography style={{ fontFamily: theme.gh_vars.circular, fontSize: 14, fontWeight: 600 }}>
                        Published
                      </Typography>
                    </Tooltip>
                    <Switch
                      className={classes.filterSwitch}
                      checked={Boolean(Number(published))}
                      value={Boolean(Number(published))}
                      name="verified"
                      color="primary"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.target.checked) {
                          setPublished('1');
                        } else {
                          setPublished('');
                        }
                      }}
                    />
                  </Box>
                </Protected>

                <Protected allowedRoles={['admin', 'design_manager', 'designer', 'website_manager', 'customer_support']}>
                  <ButtonBase
                    className={classes.filterButtonBase}
                    onClick={() => setInventoryOpen(!inventoryOpen)}
                  >
                    Inventory
                    <KeyboardArrowDownIcon
                      className={cx({
                        [classes.chevronIcon]: true,
                        [classes.chevronIconFlipped]: inventoryOpen
                      })}
                    />
                  </ButtonBase>

                  <Collapse in={inventoryOpen}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.subfilterButtonBase}
                    >
                      <Typography className={classes.subFilterText}>
                        Guest House
                      </Typography>
                      <Switch
                        className={classes.filterSwitch}
                        checked={!!inventoryInternal}
                        value={!!inventoryInternal}
                        name="verified"
                        color="primary"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          if (event.target.checked) {
                            setInventoryInternal('1');
                          } else {
                            setInventoryInternal(undefined);
                          }
                        }}
                      />
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.subfilterButtonBase}
                    >
                      <Typography className={classes.subFilterText}>
                        Maker
                      </Typography>
                      <Switch
                        className={classes.filterSwitch}
                        checked={!!inventoryExternal}
                        value={!!inventoryExternal}
                        name="verified"
                        color="primary"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          if (event.target.checked) {
                            setInventoryExternal('1');
                          } else {
                            setInventoryExternal(undefined);
                          }
                        }}
                      />
                    </Box>
                  </Collapse>
                </Protected>

                <ButtonBase
                  className={classes.filterButtonBase}
                  onClick={() => setCategoriesOpen(!categoriesOpen)}
                >
                  Categories
                  <KeyboardArrowDownIcon
                    className={cx({
                      [classes.chevronIcon]: true,
                      [classes.chevronIconFlipped]: categoriesOpen
                    })}
                  />
                </ButtonBase>

                <Collapse in={categoriesOpen}>
                  {CATEGORIES.map((category, i) => {
                    return (
                      <div key={i}>
                        <ButtonBase
                          className={classes.subfilterButtonBase}
                          onClick={() => {
                            if (filters.category.values?.includes(category.id)) {
                              removeFilter(filters.category.setter, filters.category.values, category.id);
                              removeSubcategoryFilters(filters.category, filters.category2, category.id);
                            } else {
                              setPage(0);
                              setProducts(initPagedResults());

                              addFilter(filters.category.setter, filters.category.values, category.id);
                            }
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                          >
                            <Typography className={classes.subFilterText}>
                              {category.title}
                            </Typography>

                            <Switch
                              className={classes.filterSwitch}
                              checked={!!filters.category.values?.includes(category.id)}
                              value={!!filters.category.values?.includes(category.id)}
                              name="verified"
                              color="primary"
                            />
                          </Box>
                        </ButtonBase>


                        <Collapse in={filters.category.values?.includes(category.id)}>
                          <div className={classes.subcategoryCollapseWrapper}>

                            {filters.category.values?.includes(category.id) && category.subcategories?.map((subcategory, i) => {
                              return (
                                <FormControlLabel
                                  key={i}
                                  style={{ width: '100%', marginRight: 0 }}
                                  classes={{
                                    root: classes.formControlRoot,
                                    label: classes.formControlLabel,
                                  }}
                                  control={
                                    <Checkbox
                                      checked={!!filters.category2.values?.includes(subcategory.id)}
                                      checkedIcon={
                                        <StopIcon
                                          style={{
                                            color: theme.palette.tertiary.main,
                                            backgroundColor: theme.palette.tertiary.main,
                                            transform: 'scale(0.75)',
                                            borderRadius: 4
                                          }}
                                        />
                                      }
                                      style={{
                                        color: '#83a5b0',
                                      }}
                                      onChange={() => {
                                        if (filters.category2.values?.includes(subcategory.id)) {
                                          removeFilter(filters.category2.setter, filters.category2.values, subcategory.id);
                                        } else {
                                          setPage(0);
                                          setProducts(initPagedResults());

                                          addFilter(filters.category2.setter, filters.category2.values, subcategory.id);
                                        }
                                      }}
                                    />
                                  }
                                  label={
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      width="100%"
                                    >
                                      <Typography
                                        className={classes.subcategoryText}
                                        style={{ width: '100%', flex: 1, }}
                                      >
                                        {properCase(subcategory.id, '-', true)}
                                      </Typography>
                                      <Typography
                                        className={classes.subcategoryText}
                                        style={{ position: 'relative', right: -11, width: 'auto' }}
                                      >
                                        {' '}
                                        (
                                        {filters.category2.counts && filters.category2?.counts[subcategory.id] ? filters.category2?.counts[subcategory.id] : 0}
                                        )
                                        {' '}
                                      </Typography>
                                    </Box>
                                  }
                                />
                              );
                            })}
                          </div>
                        </Collapse>
                      </div>
                    );
                  })}
                </Collapse>

                <ButtonBase
                  className={classes.filterButtonBase}
                  onClick={() => setStylesOpen(!stylesOpen)}
                >
                  Style
                  <KeyboardArrowDownIcon
                    className={cx({
                      [classes.chevronIcon]: true,
                      [classes.chevronIconFlipped]: stylesOpen
                    })}
                  />
                </ButtonBase>

                <Collapse in={stylesOpen}>
                  {STYLES.map((style, i) => {
                    return (
                      <Box
                        key={i}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        className={classes.subfilterButtonBase}
                      >
                        <Typography className={classes.subFilterText}>
                          {style.title}
                        </Typography>
                        <Switch
                          className={classes.filterSwitch}
                          checked={!!filters.style.values?.includes(style.id)}
                          value={!!filters.style.values?.includes(style.id)}
                          name="verified"
                          color="primary"
                          onChange={() => {
                            if (filters.style.values?.includes(style.id)) {
                              removeFilter(filters.style.setter, filters.style.values, style.id);
                            } else {
                              setPage(0);
                              setProducts(initPagedResults());

                              addFilter(filters.style.setter, filters.style.values, style.id);
                            }
                          }}
                        />
                      </Box>
                    );
                  })}
                </Collapse>

                <ButtonBase
                  className={classes.filterButtonBase}
                  onClick={() => setColorsOpen(!colorsOpen)}
                >
                  Color
                  <KeyboardArrowDownIcon
                    className={cx({
                      [classes.chevronIcon]: true,
                      [classes.chevronIconFlipped]: colorsOpen
                    })}
                  />
                </ButtonBase>

                <Collapse in={colorsOpen}>
                  {COLORS.map((color, i) => {
                    return (
                      <Box
                        key={i}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        className={classes.subfilterButtonBase}
                      >
                        <Typography className={classes.subFilterText}>
                          {color.title}
                        </Typography>
                        <Switch
                          className={classes.filterSwitch}
                          checked={!!filters.color.values?.includes(color.id)}
                          value={!!filters.color.values?.includes(color.id)}
                          name="verified"
                          color="primary"
                          onChange={() => {
                            if (filters.color.values?.includes(color.id)) {
                              removeFilter(filters.color.setter, filters.color.values, color.id);
                            } else {
                              setPage(0);
                              setProducts(initPagedResults());

                              addFilter(filters.color.setter, filters.color.values, color.id);
                            }
                          }}
                        />
                      </Box>
                    );
                  })}
                </Collapse>
              </div>
              <div className={classes.filterContainerButtons}>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  style={{ width: '100%', marginBottom: theme.spacing(2), border: '2px solid' }}
                  onClick={() => {
                    clearFilters();
                  }}
                >
                  <Typography
                    fontFamily={theme.gh_vars.circular}
                    style={{ fontSize: 14, lineHeight: 2 }}
                  >
                    Clear filters
                  </Typography>
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  style={{ width: '100%', marginBottom: theme.spacing(6), border: '2px solid' }}
                  onClick={() => setOpenFilters(false)}
                >
                  <Typography
                    fontFamily={theme.gh_vars.circular}
                    style={{ fontSize: 14, lineHeight: 2 }}
                  >
                    Apply filters
                  </Typography>
                </Button>
              </div>
            </div>
          )}

          <div
            style={{ width: '100%' }}
          >
            <Box
              display="grid"
              gap={theme.spacing(1)}
              className={classes.searchFilterBox}
              alignItems="center"
              width="100%"
              style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(3) }}
            >
              <TextField
                fullWidth
                // @ts-ignore
                id="search"
                placeholder="Search products..."
                variant="outlined"
                className={classes.searchTextField}
                InputProps={{
                  className: classes.searchFieldInput,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SvgIconSearch className={classes.searchFieldIcon} />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchString(e.target.value)}
              />
              {showFilters && (
                <Button
                  size="small"
                  variant="outlined"
                  className={classes.filterButton}
                  onClick={() => setOpenFilters(true)}
                >
                  Filter
                  {appliedFilters ?
                    <div className={classes.count}>
                      {appliedFilters}
                    </div>
                    : <IconFilters />
                  }
                </Button>
              )}
            </Box>

            <Typography
              fontFamily={theme.gh_vars.circular}
              style={{ fontSize: 16, lineHeight: 1.6, marginBottom: theme.spacing(1.5) }}
            >
              {results.nbHits}
              {' '}
              {`${results.nbHits > 1 ? 'Items' : 'Item'}`}
            </Typography>

            {
              products.hasOwnProperty('0') &&
                products[0].status === 'complete' &&
                products[0].results.length === 0
                ? (
                  <Typography>
                    {checkRoles(['maker'], userContext.roles) ? 'You haven\'t added any products yet.' : 'No products found'}
                  </Typography>
                ) : Object.keys(products).map(n => {
                  const page = products[n];

                  if (appContext.skeleton || page.status === 'loading') {
                    return (
                      <ProductGridSkeleton
                        key={n}
                        numItems={24}
                        spacing={2}
                      />
                    );
                  } else if (page.status === 'complete' && page.results?.length) {
                    return (
                      <ProductGrid
                        key={n}
                        href={null}
                        products={page.results}
                        showMaker={!checkRoles(['maker'], userContext.roles)}
                        showLocation={!checkRoles(['maker'], userContext.roles)}
                        spacing={2}
                        gridItemProps={{
                          xs: 12,
                        }}
                        stagingDate={startDateObject}
                        onProductClick={(_, product) => {
                          try {
                            setOpen(false);
                            resolve(product);
                          } catch (e) {
                            if (e) {
                              Sentry.captureException(e);
                            }
                          }
                        }}
                      />
                    );
                  } else {
                    return null;
                  }
                })
            }

            <ScrollElement ref={endOfContainerElement} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ProductSearchDialog.defaultProps = {
  title: 'Add a product',
};

export default ProductSearchDialog;
