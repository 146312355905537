
import { SearchResponse } from '@algolia/client-search';
import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import HomeRowCard from '@components/cards/HomeRowCard';
import HomeRowCardHeaders from '@components/cards/HomeRowCardHeaders';
import { CreateHomeFromDealDialog } from '@components/dialogs/CreateHomeFromDealDialog';
import ErrorComponent from '@components/error/Error';
import HomeGrid from '@components/home-grid/HomeGrid';
import { LinkBehavior } from '@components/link-behavior/LinkBehavior';
import AppContext from '@context/AppContext';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import useAlgoliaSearch from '@hooks/useAlgoliaSearch';
import useCenterPoint from '@hooks/useCenterPoint';
import useRouter from '@hooks/useRouter';
import { TimelineData } from '@hooks/useTimeline';
import algolia from '@integrations/Algolia';
import Sentry from '@integrations/Sentry';
import IconPlus from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import routes from '@routes';
import useCommonStyles from '@styles/common.style';
import Protected from '@ui/components/protected/Protected';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import IconGrid from '@ui/icons/imaterial/base/Grid.svg';
import IconListRound from '@ui/icons/imaterial/base/List Round.svg';
import checkRoles from '@ui/utils/checkRoles';
import slugOrID from '@ui/utils/slugOrID';
import makeIndexName from '@utils/makeIndexName';
import { homePath } from '@utils/paths';
import endOfDay from 'date-fns/endOfDay';
import { collection, doc, updateDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';

import HomesSkeleton from './Homes.skeleton';
import useStyles from './Homes.style';
import OrdersQuickView from './OrdersQuickView';
import TimelineQuickView from './TimelineQuickView';

const homesDeinstallDateDescIndex = algolia.initIndex(makeIndexName('homes_deinstall_date_desc'));
const homesDeinstallDateAscIndex = algolia.initIndex(makeIndexName('homes_deinstall_date_asc'));
const homesInstallDateDescIndex = algolia.initIndex(makeIndexName('homes_install_date_desc'));
const homesInstallDateAscIndex = algolia.initIndex(makeIndexName('homes_install_date_asc'));

const renderActions = (home: guesthouse.Home, timelineData: TimelineData) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { md, lg } = useBreakpoints();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { theme } = useStyles();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userContext = useContext<UserContext>(UserContext);
  const internalUser = checkRoles(['admin', 'design_manager', 'designer'], userContext.roles);

  const todo = internalUser ? timelineData.internalReview : timelineData.clientReview;

  if (todo.length > 0) {
    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {md && (
          <Typography
            component="div"
            style={{
              fontFamily: 'MabryMono, monospace',
              fontSize: 11,
              marginRight: 4
            }}
          >
            {`${todo.length} new ${todo.length > 1 ? 'tasks' : 'task'}`}
          </Typography>
        )}
        <div
          style={{
            width: 20,
            minWidth: 20,
            height: 20,
            minHeight: 20,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(.5),
            borderRadius: '50%',
            backgroundColor: '#AD6556',
            textAlign: 'center',
            color: '#fff',
            lineHeight: '20px',
          }}
        >
          {todo.length}
        </div>
        {
          lg && (
            <Protected allowedRoles={['admin', 'design_manager', 'designer', 'website_manager', 'customer_support']}>
              <TimelineQuickView home={home} />
              <OrdersQuickView home={home} />
            </Protected>
          )
        }
      </Box>
    );
  } else if (timelineData.upcoming.length > 0) {
    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {
          md && (
            <Typography
              component="div"
              style={{
                fontFamily: 'MabryMono, monospace',
                fontSize: 11,
                marginRight: 4
              }}
            >
              {timelineData.upcoming.length}
              {' '}
              upcoming
            </Typography>
          )
        }
        {
          lg && (
            <Protected allowedRoles={['admin', 'design_manager', 'designer', 'website_manager', 'customer_support']}>
              <TimelineQuickView home={home} />
              <OrdersQuickView home={home} />
            </Protected>
          )
        }
      </Box>
    );
  } else {
    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {
          md
            ? (
              <Typography
                component="div"
                style={{
                  fontFamily: 'MabryMono, monospace',
                  fontSize: 11,
                  marginRight: 4
                }}
              >
                You&apos;re all caught up &#128077;
              </Typography>
            ) : (
              <div
                style={{
                  position: 'relative',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                &#128077;
              </div>
            )
        }
        {
          lg && (
            <Protected allowedRoles={['admin', 'design_manager', 'designer', 'website_manager', 'customer_support']}>
              <TimelineQuickView home={home} />
              <OrdersQuickView home={home} />
            </Protected>
          )
        }
      </Box>
    );
  }
};

const Homes = () => {
  const { classes: common } = useCommonStyles();
  const { classes, theme } = useStyles();
  const userContext = useContext<UserContext>(UserContext);
  const appContext = useContext<AppContext>(AppContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const { md } = useBreakpoints();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [coords, radius] = useCenterPoint();
  const router = useRouter();
  const now = endOfDay(new Date());
  const [myHomes, setMyHomes] = useState(Boolean(userContext?.data?.app_preferences?.views?.my_homes_only));
  const [homeStatusView, setHomeStatusView] = useState<HomeStatusView>(appContext.homeStatusView);

  const externalUser = checkRoles(['realtor', 'maker'], userContext.roles) && !checkRoles(['admin', 'designer', 'design_manager', 'website_manager', 'customer_support'], userContext.roles);

  const sharedFilters = [
    (myHomes || externalUser) && `members:${userContext.user.uid}`,
  ];

  const { results: allHomes, loading: l1, error: e1 } = useAlgoliaSearch<guesthouse.Home>({
    index: homesInstallDateDescIndex,
    filters: [
      ...sharedFilters
    ].filter(Boolean).join(' AND '),
    numericFilters: '',
    facetFilters: '',
    query: '',
    aroundLatLng: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? coords?.join(',') : undefined,
    aroundRadius: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? radius : undefined,
    perPage: 50,
    debounce: 0,
  });

  const { results: planningHomesResults, loading: l2, error: e2 } = useAlgoliaSearch<guesthouse.AlgoliaHome>({
    index: homesInstallDateAscIndex,
    filters: [
      ...sharedFilters,
      `install_date_timestamp > ${Math.floor(now.getTime() / 1000)}`,
      'status:planning'
    ].filter(Boolean).join(' AND '),
    numericFilters: '',
    facetFilters: '',
    query: '',
    aroundLatLng: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? coords?.join(',') : undefined,
    aroundRadius: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? radius : undefined,
    perPage: 500,
    debounce: 0,
  });

  const { results: fullyPlannedHomesResults, loading: l3, error: e3 } = useAlgoliaSearch<guesthouse.AlgoliaHome>({
    index: homesInstallDateAscIndex,
    filters: [
      ...sharedFilters,
      `install_date_timestamp > ${Math.floor(now.getTime() / 1000)}`,
      'status:fully_planned'
    ].filter(Boolean).join(' AND '),
    numericFilters: '',
    facetFilters: '',
    query: '',
    aroundLatLng: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? coords?.join(',') : undefined,
    aroundRadius: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? radius : undefined,
    perPage: 500,
    debounce: 0,
  });

  const { results: onMarketHomesResults, loading: l4, error: e4 } = useAlgoliaSearch<guesthouse.AlgoliaHome>({
    index: homesInstallDateDescIndex,
    filters: [
      ...sharedFilters,
      `install_date_timestamp < ${Math.floor(now.getTime() / 1000)}`,
      'status:on_market'
    ].filter(Boolean).join(' AND '),
    numericFilters: '',
    facetFilters: '',
    query: '',
    aroundLatLng: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? coords?.join(',') : undefined,
    aroundRadius: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? radius : undefined,
    perPage: 500,
    debounce: 0,
  });

  const { results: upcomingDeinstallHomesResults, loading: l5, error: e5 } = useAlgoliaSearch<guesthouse.AlgoliaHome>({
    index: homesDeinstallDateAscIndex,
    filters: [
      ...sharedFilters,
      `deinstall_date_timestamp > ${Math.floor(now.getTime() / 1000)}`,
    ].filter(Boolean).join(' AND '),
    numericFilters: '',
    facetFilters: '',
    query: '',
    aroundLatLng: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? coords?.join(',') : undefined,
    aroundRadius: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? radius : undefined,
    perPage: 500,
    debounce: 0,
  });

  const { results: inactiveHomesResults, loading: l6, error: e6 } = useAlgoliaSearch<guesthouse.AlgoliaHome>({
    index: homesDeinstallDateDescIndex,
    filters: [
      ...sharedFilters,
      `deinstall_date_timestamp < ${Math.floor(now.getTime() / 1000)}`,
    ].filter(Boolean).join(' AND '),
    numericFilters: '',
    facetFilters: '',
    query: '',
    aroundLatLng: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? coords?.join(',') : undefined,
    aroundRadius: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? radius : undefined,
    debounce: 0,
  });

  useEffect(() => {
    updateDoc(doc(collection(database, 'users'), userContext.user.uid), { 'app_preferences.views.my_homes_only': myHomes })
      .catch(Sentry.captureException);
  }, [myHomes]);

  const selectActiveHomesResults = (homeStatusView: HomeStatusView): SearchResponse<guesthouse.AlgoliaHome> => {
    if (homeStatusView === 'planning') {
      return planningHomesResults;
    } else if (homeStatusView === 'fully_planned') {
      return fullyPlannedHomesResults;
    } else if (homeStatusView === 'on_market') {
      return onMarketHomesResults;
    } else if (homeStatusView === 'upcoming_deinstalls') {
      return upcomingDeinstallHomesResults;
    } else if (homeStatusView === 'inactive') {
      return inactiveHomesResults;
    }
  };

  const handleHomeStatusViewChange = (event) => {
    const homeStatusView: HomeStatusView = event.target.value;

    setHomeStatusView(homeStatusView);
    appContext.setContext({ ...appContext, homeStatusView });
  };

  const loading = l1 || l2 || l3 || l4 || l5 || l6;
  const error = e1 || e2 || e3 || e4 || e5 || e6;

  const planningHomes = planningHomesResults?.hits || [];
  const fullyPlannedHomes = fullyPlannedHomesResults?.hits || [];
  const onMarketHomes = onMarketHomesResults?.hits || [];
  const upcomingDeinstallHomes = upcomingDeinstallHomesResults?.hits || [];
  const inactiveHomes = inactiveHomesResults?.hits || [];

  if (error) {
    return (
      <div className={common.contentSpacing}>
        <ErrorComponent />
      </div>
    );
  }

  if (loading || appContext.skeleton) {
    return (
      <div className={common.contentSpacing}>
        <HomesSkeleton />
      </div>
    );
  }

  return (
    <div className={common.contentSpacing}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Box
            display="flex"
            alignItems="flex-end"
          >
            <Typography
              component="h1"
              variant="h3Alt"
              style={{ marginRight: theme.spacing(2) }}
            >
              Homes
            </Typography>

            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              <ButtonGroup
                ref={anchorRef}
                size="small"
                variant="contained"
                color="secondary"
                aria-label="split button"
              >

                <Button
                  component={LinkBehavior}
                  href={routes.homeCreate.path}
                  startIcon={<IconPlus />}
                  aria-label="add home"
                  data-test="homes-add-home-button"
                >
                  Add home
                </Button>
                {
                  checkRoles(['admin', 'design_manager', 'customer_support'], userContext.roles) && (
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: theme.spacing(1) }}
                      aria-controls={open ? 'add-home-options-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-label="select add home option"
                      data-test="select-add-home-option"
                      aria-haspopup="menu"
                      onClick={() => setOpen(true)}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  )
                }
              </ButtonGroup>
              <Popper
                transition
                disablePortal
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={() => setOpen(false)}>
                        <MenuList id="add-home-options-menu">
                          <MenuItem
                            aria-label="add home from deal"
                            data-test="add-home-from-deal"
                            onClick={() => {
                              setOpen(false);
                              asyncRender(CreateHomeFromDealDialog)
                                .then(({ home }) => {
                                  if (slugOrID(home)) {
                                    return router.push(homePath(home));
                                  } else {
                                    throw new Error('Failed to create home from deal.');
                                  }
                                })
                                .then(() => notificationContext.setContext({ open: true, message: 'Home created' }))
                                .catch((e) => {
                                  if (e) {
                                    Sentry.captureException(e);
                                    notificationContext.setContext({ open: true, severity: 'error', message: 'Something went wrong' });
                                  }
                                });
                            }}
                          >
                            Add home from deal
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Protected>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              className={classes.justMyHomes}
            >
              <Box
                display="flex"
                alignItems="center"
              >
                <Typography
                  component="div"
                  fontFamily={theme.gh_vars.circular}
                  style={{
                    marginRight: theme.spacing(1.25),
                    paddingBottom: 2,
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                >
                  Just my homes
                </Typography>
                <Switch
                  checked={myHomes}
                  onChange={() => {
                    setMyHomes(!myHomes);
                  }}
                />
              </Box>

              <Box
                display="flex"
                alignItems="center"
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    updateDoc(doc(collection(database, 'users'), userContext.user.uid),
                      { 'app_preferences.views.home_card': 'list' })
                      .catch(Sentry.captureException);
                  }}
                >
                  <IconListRound />
                </IconButton>

                <IconButton
                  size="small"
                  onClick={() => {
                    updateDoc(doc(collection(database, 'users'), userContext.user.uid),
                      { 'app_preferences.views.home_card': 'grid' })
                      .catch(Sentry.captureException);
                  }}
                >
                  <IconGrid />
                </IconButton>
              </Box>
            </Box>
          </Protected>
        </Grid>
      </Grid>


      <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: theme.spacing(3)
          }}
        >
          <FormControl>
            <Select
              value={homeStatusView}
              label=""
              style={{
                width: 200
              }}
              onChange={handleHomeStatusViewChange}
            >
              <MenuItem value={'planning'}>
                <Typography>
                  {`In-planning (${planningHomes.length})`}
                </Typography>
              </MenuItem>
              <MenuItem value={'fully_planned'}>
                <Typography>
                  {`Fully Planned (${fullyPlannedHomes.length})`}
                </Typography>
              </MenuItem>
              <MenuItem value={'on_market'}>
                {`On Market (${onMarketHomes.length})`}
              </MenuItem>
              <MenuItem value={'upcoming_deinstalls'}>
                {`Upcoming Deinstalls (${upcomingDeinstallHomes.length})`}
              </MenuItem>
              <MenuItem value={'inactive'}>
                {`Inactive (${inactiveHomes.length})`}
              </MenuItem>
            </Select>
          </FormControl>

          <Link
            variant="body2"
            style={{
              fontFamily: theme.gh_vars.circular,
              marginLeft: theme.spacing(3)
            }}
            component={LinkBehavior}
            href={'/homes/all'}
          >
            View all homes
          </Link>
        </div>
      </Protected>

      <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
        <div
          style={{
            marginBottom: theme.spacing(5),
            width: '100%'
          }}
        >
          {
              (userContext?.data?.app_preferences?.views?.home_card || 'grid') === 'grid'
                ? (
                  <>
                    <HomeGrid
                      homes={selectActiveHomesResults(homeStatusView)?.hits}
                      href={homePath}
                      showSubscription={checkRoles(['admin', 'design_manager', 'designer', 'website_manager', 'customer_support'], userContext.roles)}
                    />
                  </>
                ) : (
                  <>
                    {md && (
                      <HomeRowCardHeaders
                        renderActionsHeader={typeof renderActions === 'function'}
                      />
                    )}

                    {selectActiveHomesResults(homeStatusView)?.hits.map(home => {
                      return (
                        <HomeRowCard
                          key={home.docID}
                          home={home}
                          href={(home) => homePath(home)}
                          renderActions={renderActions}
                          style={{
                            marginBottom: theme.spacing(1)
                          }}
                        />
                      );
                    })}
                  </>
                )
            }
        </div>
      </Protected>

      {externalUser &&
        <div
          style={{
            marginBottom: theme.spacing(5),
            width: '100%'
          }}
        >
          {
              (userContext?.data?.app_preferences?.views?.home_card || 'grid') === 'grid'
                ? (
                  <>
                    <HomeGrid
                      homes={allHomes?.hits}
                      href={homePath}
                      showSubscription={checkRoles(['admin', 'design_manager', 'designer', 'website_manager', 'customer_support'], userContext.roles)}
                    />
                  </>
                ) : (
                  <>
                    {md && (
                      <HomeRowCardHeaders
                        renderActionsHeader={typeof renderActions === 'function'}
                      />
                    )}

                    {allHomes?.hits.map(home => {
                      return (
                        <HomeRowCard
                          key={home.docID}
                          home={home}
                          href={(home) => homePath(home)}
                          renderActions={renderActions}
                          style={{
                            marginBottom: theme.spacing(1)
                          }}
                        />
                      );
                    })}
                  </>
                )
            }
        </div>
        }
    </div>
  );
};

export default React.memo(Homes);
