
export default function getLocationKeys(location: guesthouse.Location): guesthouse.Location {
  return {
    address: location?.address,
    place_id: location?.place_id,
    vicinity: location?.vicinity,
    _geoloc: location?._geoloc,
    geohash: location?.geohash,
  };
}
