import { database } from '@app/firebase';
import HomeForm from '@forms/Home';
import useRouter from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { HomeFormValues, initialValues } from '@schema/HomeSchema';
import useCommonStyles from '@styles/common.style';
import newId from '@utils/newId';
import { homePath } from '@utils/paths';
import { collection, doc, setDoc, Timestamp  } from 'firebase/firestore';
import React, { useRef, useState } from 'react';

const HomeCreate = () => {
  const { classes: common } = useCommonStyles();
  const router = useRouter();
  const [error, setError] = useState<string | boolean>();

  const uid = useRef(newId());

  return (
    <div className={common.contentSpacing}>
      <Grid container>
        <Typography
          gutterBottom
          component="h1"
          variant="h4Alt"
        >
          New home
        </Typography>
      </Grid>

      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <HomeForm
            submitText="Submit"
            error={error}
            initialValues={initialValues}
            onSubmit={async (values: HomeFormValues) => {
              setError(false);

              const { install_date, deinstall_date, ...rest } = values;

              const home: Partial<guesthouse.Home> = {
                docID: uid.current,
                created: Timestamp.fromDate(new Date()),
                members: [],
                ...rest,
              };

              if (install_date) {
                home.install_date = Timestamp.fromDate(install_date);
              }

              if (deinstall_date) {
                home.deinstall_date = Timestamp.fromDate(deinstall_date);
              }

              return await setDoc(doc(collection(database, 'homes'), home.docID), home)
                .then(() => router.push(homePath(home)))
                .catch((e) => {
                  Sentry.captureException(e);
                  setError(e.message);
                });
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
        >
        </Grid>

      </Grid>
    </div>
  );
};

export default React.memo(HomeCreate);
