import { v4 as uuidv4 } from 'uuid';

const downloadFile = async (url, filename = null) => {
  const blob = await fetch(url).then(r => r.blob());
  const _url = URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = _url;
  a.download = filename ? filename : `${uuidv4()}.${blob.type.replace('image/', '')}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default downloadFile;
