import toJsDate from '@ui/utils/toJsDate';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import min from 'date-fns/min';

const calculateDaysOnMarket = (home: Partial<guesthouse.AlgoliaHome> | Partial<guesthouse.Home>, now: Date = new Date()): number => {
  if (!home.install_date) {
    return 0;
  }

  // cast is valid because of above check + toJsDate returns Date when input is truthy
  const installDate = toJsDate(home.install_date) as Date;

  if (installDate > now) {
    return 0;
  }

  const dateSinceInstalled = home.deinstall_date
    ? min([toJsDate(home.deinstall_date) as Date, now])
    : now;

  return differenceInCalendarDays(dateSinceInstalled, installDate);
};

export default calculateDaysOnMarket;
