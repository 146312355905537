import makeStyles from '../../utils/makeStyles';

export default makeStyles()((theme) => ({
  wrapper: {
    width: '100%',
    fontFamily: theme.gh_vars.mabryMono,
    fontSize: 13,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(3),
      maxWidth: 320,
      width: '100%',
    },
  },
  tag: {
    background: theme.palette.common.white,
    borderRadius: '50%',
    fill: theme.palette.common.black,
    color: theme.palette.common.black,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    background: 'transparent'
  },
}));
