import { database, functions } from '@app/firebase';
import { RouterLink } from '@components/link-behavior/LinkBehavior';
import CONFIG from '@config';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import Sentry from '@integrations/Sentry';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ROUTES from '@routes';
import useCommonStyles from '@styles/common.style';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import Protected from '@ui/components/protected/Protected';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import { useStyles } from '@ui/utils/makeStyles';
import toJsDate from '@ui/utils/toJsDate';
import makeAppUrl from '@utils/makeAppUrl';
import newId from '@utils/newId';
import format from 'date-fns/format';
import { collection, doc, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import formatCurrency from 'format-currency';
import React, { useContext, useState } from 'react';
import { generatePath } from 'react-router';

const createLoginLink = httpsCallable<void, {url: string}>(functions, 'stripe-createLoginLink');

const makePaymentRedirectUrl = (name: string) => {
  if (CONFIG.environment === 'local') {
    return new URL(name, 'http://' + CONFIG.hosting.host + ':' + CONFIG.hosting.port).toString();
  }
  return makeAppUrl(name);
};

const Payments = () => {
  const { classes: common } = useCommonStyles();
  const { theme } = useStyles();
  const userContext = useContext<UserContext>(UserContext);
  const { sm } = useBreakpoints();
  const [loginLinkLoading, setLoginLinkLoading] = useState(false);
  let transferQuery = query(collection(database, 'transfers'), orderBy('created', 'desc'));

  if (userContext?.roles?.maker) {
    transferQuery = query(transferQuery, where('metadata.maker_id', '==', userContext.user.uid));
  }

  const { collection: transfers } = useCollection(transferQuery);

  const setupPayments = () => {
    const paymentToken = newId();
    const paymentRedirectUrl = makePaymentRedirectUrl('/payments/authorize');

    const stripeAuthURL = `https://connect.stripe.com/express/oauth/authorize?client_id=${CONFIG.stripe.connect_client_id}&state=${paymentToken}&suggested_capabilities[]=transfers&stripe_user[email]=${encodeURIComponent(userContext.user.email)}&redirect_uri=${paymentRedirectUrl}`;

    updateDoc(doc(collection(database, 'users'), userContext.user.uid), { paymentToken })
      .then(() => window.location.href = stripeAuthURL)
      .catch(Sentry.captureException);
  };

  const gotoDashboard = async () => {
    setLoginLinkLoading(true);
    try {
      const { data } = await createLoginLink();

      window.open(data.url);
    } catch (e) {
      Sentry.captureException(e);
    }
    setLoginLinkLoading(false);
  };

  return (
    <div className={common.contentSpacing}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Box
            display="flex"
            alignItems="flex-end"
          >
            <Typography
              component="h1"
              variant="h3Alt"
            >
              Payments
            </Typography>

            <Protected allowedRoles={[() => !!userContext.data.paymentAccountId]}>
              <SubmitButton
                type="button"
                style={{ margin: theme.spacing(0, 2) }}
                isSubmitting={loginLinkLoading}
                aria-label="View Stripe Dashboard"
                onClick={() => gotoDashboard()}
              >
                View Stripe Dashboard
              </SubmitButton>
            </Protected>
          </Box>
        </Grid>
      </Grid>

      {!userContext.data.paymentAccountId && (
        <Typography
          gutterBottom
          component="div"
          variant="body2"
          style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(5) }}
        >
          Before you can sell on Guest House, you need to configure payments so we know where to send your money.
        </Typography>
      )}

      {userContext.data.paymentAccountId && !(transfers?.docs?.length > 0) && (
        <Typography
          gutterBottom
          component="div"
          variant="body2"
          style={{ marginBottom: theme.spacing(4.5) }}
        >
          You&apos;re all set up to receive payments from Guest House. You don&apos;t have any transfers yet, but when you do they will show up here.
        </Typography>
      )}

      {!userContext.data.paymentAccountId && (
        <Button
          color="primary"
          variant="outlined"
          fullWidth={!sm ? false : false}
          style={{
            marginBottom: theme.spacing(4),
            marginTop: theme.spacing(4),
          }}
          onClick={() => setupPayments()}
        >
          Setup Payments
        </Button>
      )}

      {userContext.data.paymentAccountId && (
        <>
          {transfers && !!transfers.docs.length && (
            <TableContainer style={{ marginTop: theme.spacing(3) }}>
              <Table aria-label="orders">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Transfer
                    </TableCell>

                    <TableCell>
                      Order
                    </TableCell>

                    <TableCell>
                      Date
                    </TableCell>

                    <TableCell>
                      Amount
                    </TableCell>

                    <TableCell>
                      Description
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    transfers.docs.map(transfer => {
                      const transferData = transfer.data();

                      return (
                        <TableRow key={transfer.id}>
                          <TableCell
                            component="th"
                            scope="order"
                          >
                            {transfer.id}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="order"
                          >
                            {transferData.metadata?.order_id && (
                              <Link
                                component={RouterLink}
                                to={generatePath(ROUTES.order.path, { id: transferData.metadata.order_id })}
                              >
                                {transferData.metadata.order_id}
                              </Link>
                            )}
                          </TableCell>

                          <TableCell>
                            {format(toJsDate(transferData.created), 'MM/dd/yyyy')}
                          </TableCell>

                          <TableCell>
                            {formatCurrency(transferData.amount / 100, { format: '%s%v', symbol: '$' })}
                          </TableCell>

                          <TableCell>
                            {transferData.description}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Typography
            variant="caption1"
            display="block"
            style={{
              marginTop: theme.spacing(5),
              marginBottom: theme.spacing(5)
            }}
          >
            Account ID:
            {' '}
            {userContext.data.paymentAccountId}
          </Typography>

        </>
      )}

    </div>
  );
};

export default React.memo(Payments);
