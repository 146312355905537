
import * as Yup from 'yup';

export const initialValues: Partial<guesthouse.ProductOffer> = {
  pickupOnly: false,
  type: 'new',
  condition: 'excellent',
};

const ProductOfferSchema = Yup.object().shape({
  title: Yup.string(),
  description: Yup.string(),
  price: Yup.number()
    .moreThan(0, 'Price must be greater than zero')
    .typeError('Price must be a valid number')
    .required('Price is required'),
  sale: Yup.boolean(),
  salePrice: Yup.number().when('sale', {
    is: true,
    then: Yup.number()
      .moreThan(0, 'Sale price must be greater than zero')
      .typeError('Sale price must be a valid number')
      .required('Sale price is required'),
  }),
  soldBy: Yup.object()
    .required('Sold by is required'),
  pickupOnly: Yup.boolean(),
  pickupLocation: Yup.object().when('pickupOnly', {
    is: true,
    then: Yup.object({
      address: Yup.string().nullable(),
      place_id: Yup.string().nullable().required('Warehouse location is required'),
      vicinity: Yup.string().nullable(),
      _geoloc: Yup.object().shape({
        lat: Yup.number(),
        lng: Yup.number(),
      }).nullable()
    })
  }),
  dropship: Yup.boolean(),
  shippingPricePerProduct: Yup.number()
    .min(0, 'Shipping price must cannot be negative')
    .typeError('Price must be a valid number'),
  shippingPricePerOrder: Yup.number()
    .min(0, 'Shipping price must cannot be negative')
    .typeError('Price must be a valid number'),
});

export default ProductOfferSchema;
