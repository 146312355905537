import { useEffect, useMemo, useState } from 'react';

const initDomRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
  toJSON() {
    return JSON.stringify({
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
      x: 0,
      y: 0
    });
  }
};

export default function useRefRect(el : HTMLElement) : DOMRect {
  const [domRect, setDomRect] = useState<DOMRect>(initDomRect);

  const setElementHeight = (element) => {
    const h = element.getBoundingClientRect();

    setDomRect(h);
  };

  const resize : ResizeObserverCallback = (entries) => {
    if (entries[0]?.target) {
      setElementHeight(entries[0].target);
    }
  };

  const observer = useMemo(() => {
    if (typeof window !== 'undefined') {
      return new ResizeObserver(resize);
    }
  }, []);

  useEffect(() => {
    setDomRect(initDomRect);

    if (typeof window === 'undefined') {
      return;
    }

    if (el) {
      observer?.observe(el);
      setElementHeight(el);
    }

    return () => {
      if (el) {
        observer?.unobserve(el);
      }
    };
  }, [el]);

  return domRect;
}
