type renderActionProps = {
  room: guesthouse.Room; 
  home: guesthouse.Home;
}

type RoomSearchCompleteInfo = {
  home: guesthouse.HomeLite;
  room: guesthouse.Room;
  scheduleDate: Date;
}

type Props = {
    home?: guesthouse.Home;
    product: guesthouse.Product;
    renderAction?: (props: renderActionProps) => JSX.Element | null | string;
    onComplete?: (info: RoomSearchCompleteInfo) => void;
}

export const defaultProps: Partial<Props> = {
  renderAction: () => null,
};

export default Props;
