import { database } from '@app/firebase';
import { LinkBehavior } from '@components/link-behavior/LinkBehavior';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import Chip from '@mui/material/Chip';
import makeStyles from '@ui/utils/makeStyles';
import { productPath } from '@utils/paths';
import { collectionGroup, query as firestoreQuery, where } from 'firebase/firestore';
import React, { useContext, useMemo } from 'react';

const useStyles = makeStyles()((theme) => ({
  productTag: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    background: 'rgba(0, 0, 0, .5)',
    color: theme.palette.common.white,
    cursor: 'pointer',
  },
}));

type ProductTagsProps = {
  photoId: string;
}

const ProductTags: React.FC<ProductTagsProps> = (props) => {
  const { photoId } = props;
  const { classes } = useStyles();

  const userContext = useContext<UserContext>(UserContext);

  let query = firestoreQuery(collectionGroup(database, 'productTags')
    ,where('photo.docID', '==', photoId));

  if (userContext.roles?.maker) {
    query = firestoreQuery(query, where('product.owner.docID', '==', userContext.user.uid));
  }

  const { collection } = useCollection(query);

  const productTags: guesthouse.ProductTag[] = useMemo(() => {

    if (collection?.docs?.length) {
      return collection.docs.map(pt => pt.data() as guesthouse.ProductTag);
    }

    return [];

  }, [collection]);

  if (productTags.length) {
    return (
      <div>
        {
          productTags.map((tagData, i) => {
            return (
              <Chip
                key={i}
                label={tagData.product.title}
                color="primary"
                size="small"
                className={classes.productTag}
                component={LinkBehavior}
                href={productPath(tagData.product)}
              />
            );
          })
        }
      </div>
    );
  }

  return null;
};

export default ProductTags;
