import UserSearch from '@components/user-search/UserSearch';
import { STAGING_TIMELINE_ITEMS } from '@data';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TimelineItemSchema, { initialValues as _initialValues } from '@schema/TimelineItemSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import Protected from '@ui/components/protected/Protected';
import { useStyles } from '@ui/utils/makeStyles';
import toJsDate from '@ui/utils/toJsDate';
import { isError } from '@ui/utils/typescriptHelpers';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';

const getTimelineItemByName = (timelineData: Partial<guesthouse.TimelineItem>[], name: guesthouse.TimelineProductNames) => {
  return timelineData.find(tli => tli.name === name);
};

const TimelineItemForm = ({ onSubmit, initialValues = _initialValues, error, submitText = 'Update' }) => {
  const { theme } = useStyles();

  return (
    <Formik
      validationSchema={TimelineItemSchema}
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.name && Boolean(errors.name)}
            >
              <InputLabel id="name-label">
                Item
              </InputLabel>
              <Select
                data-test="name"
                labelId="name-label"
                id="name"
                value={getTimelineItemByName(STAGING_TIMELINE_ITEMS, values.name)?.name}
                label="Item"
                onChange={(e) => {
                  const name = e.target.value as guesthouse.TimelineProductNames;
                  const tli = getTimelineItemByName(STAGING_TIMELINE_ITEMS, name);

                  setFieldValue('name', tli?.name);
                  setFieldValue('sku', tli?.sku);

                  if (name !== 'other') {
                    setFieldValue('title', tli?.title);
                  }
                }}
              >
                <MenuItem value="">
                  <em>
                    &nbsp;
                  </em>
                </MenuItem>
                {Object.keys(STAGING_TIMELINE_ITEMS).map(l => {
                  const lineItem = STAGING_TIMELINE_ITEMS[l];

                  return (
                    <MenuItem
                      key={lineItem.name}
                      value={lineItem.name}
                    >
                      {lineItem.title}
                    </MenuItem>
                  );
                })}
              </Select>
              {touched.name && Boolean(errors.name) && (
                <FormHelperText error>
                  {errors.name}
                </FormHelperText>
              )}
            </FormControl>

            {values.sku === 'OTH' && (
              <Field
                fullWidth
                data-test="title"
                name="title"
                label="Title"
                type="text"
                component={TextField}
                margin="normal"
                variant="outlined"
              />
            )}

            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              <UserSearch
                defaultValue={values.owner}
                useCompany={true}
                label="Owner"
                style={{
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(1),
                }}
                onChange={(_, value) => {
                  setFieldValue('owner', value);
                  setFieldTouched('owner');
                }}
              />
            </Protected>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Due Date"
                value={toJsDate(values?.due_date) || null}
                disablePast={values?.name === 'deinstall_date' ? true : false}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    margin: 'normal',
                    helperText: '',
                    style: { width: '100%' },
                    inputProps: {
                      ['data-test']: 'due_date'
                    }
                  }
                }}
                onChange={(date) => {
                  if (date === null) {
                    setFieldValue('due_date', undefined);
                  } else {
                    setFieldValue('due_date', date);
                  }
                }}
              />
              <DateTimePicker
                label="Completed Date"
                value={toJsDate(values?.completed_date) || null}
                disablePast={values?.name === 'deinstall_date' ? true : false}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    margin: 'normal',
                    helperText: '',
                    style: { width: '100%' },
                    inputProps: {
                      ['data-test']: 'completed_date'
                    }
                  }
                }}
                onChange={(date) => {
                  if (date === null) {
                    setFieldValue('completed_date', undefined);
                  } else {
                    setFieldValue('completed_date', date);
                  }
                }}
              />
            </LocalizationProvider>

            {error && (
              <>
                <FormHelperText error>
                  {isError(error) ? error.message : error}
                </FormHelperText>
                <FormHelperText>
                  Need help? Contact
                  {' '}
                  <Link href="mailto:support@guesthouseshop.com">
                    support@guesthouseshop.com
                  </Link>
                </FormHelperText>
              </>
            )}

            {values.name === 'deinstall_date' &&
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: '0.5px solid #000',
                  borderRadius: '5px',
                  padding: theme.spacing(1.5),
                  marginTop: theme.spacing(2),
                  textAlign: 'center'
                }}
              >
                <Typography>
                  Note: Setting a deinstall date will cancel the home&apos;s subscription one day after the date set.
                </Typography>
              </div>
            }

            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                data-test="timeline-item-submit-button"
                disabled={Boolean(Object.keys(errors).length)}
                aria-label={submitText}
                tooltip={
                  makeErrors(errors, touched)?.length ? 
                    (
                      <TooltipErrors
                        errors={errors}
                        touched={touched}
                      />
                    ) 
                    : 
                    null
                  }
              >
                {submitText}
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(TimelineItemForm);
