import * as Yup from 'yup';

export type RoomFormValues = {
  title?: string;
  sqft?: number;
  description?: string;
  notes?: string;
  type?: guesthouse.RoomTypeID;
};

export const initialValues: RoomFormValues = {};

const RoomSchema = Yup.object().shape({
  title: Yup.string()
    .required('Room title is required'),
  sqft: Yup.string(),
  notes: Yup.string(),
  type: Yup.string(),
});

export default RoomSchema;
