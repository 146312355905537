import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import formatCurrency from 'format-currency';
import React from 'react';

type LineItemPriceProps = {
  className?: string;
  style?: React.CSSProperties;
  lineItem: Partial<HubSpot.LineItemProperties>
}

const LineItemPrice: React.FC<LineItemPriceProps> = (props) => {
  const { lineItem, className, style } = props;

  if (lineItem.discount || lineItem.hs_discount_percentage) {
    return (
      <Box
        display="flex"
        className={className}
        style={style}
      >
        {Number(lineItem.quantity) > 1 && (
          <Typography variant="body2">
            {lineItem.quantity}
            x
            &nbsp;
          </Typography>
        )}

        <Typography
          variant="body2"
          style={{ textDecoration: 'line-through', }}
        >
          {`${formatCurrency(`${Number(lineItem.price)}`, { format: '%s%v', symbol: '$', minFraction: 2, maxFraction: 2 })}`}
        </Typography>
        &nbsp;
        <Typography variant="body2">
          {`${formatCurrency(lineItem.amount, { format: '%s%v', symbol: '$', minFraction: 2, maxFraction: 2 })}`}
        </Typography>

      </Box>
    );
  }


  return (
    <Box
      display="flex"
      className={className}
      style={style}
    >
      {Number(lineItem.quantity) > 1 && (
        <Typography variant="body2">
          {lineItem.quantity}
          x
          &nbsp;
        </Typography>
      )}
      <Typography variant="body2">
        {`${formatCurrency(`${Number(lineItem.price)}`, { format: '%s%v', symbol: '$', minFraction: 2, maxFraction: 2 })}`}
      </Typography>
    </Box>
  );
};

export default LineItemPrice;
