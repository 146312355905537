import { database, functions } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import UserDialog from '@components/dialogs/UserDialog';
import Error from '@components/error/Error';
import { RouterLink } from '@components/link-behavior/LinkBehavior';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import useEditUserHandler from '@hooks/useEditUserHandler';
import Sentry from '@integrations/Sentry';
import IconPlus from '@mui/icons-material/Add';
import IconEdit from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import useCommonStyles from '@styles/common.style';
import formatDate from '@ui/utils/formatDate';
import formatPhone from '@ui/utils/formatPhone';
import { useStyles } from '@ui/utils/makeStyles';
import userFullName from '@ui/utils/userFullName';
import { userPath } from '@utils/paths';
import properCase from '@utils/properCase';
import copy from 'copy-to-clipboard';
import { collection, doc, orderBy, query, setDoc, Timestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { auth } from 'firebase-admin';
import React, { useContext, useMemo } from 'react';

import Skeleton from './UserManagement.skeleton';

const createStreamUser = httpsCallable(functions, 'http-createStreamUser');
const createUser = httpsCallable<{email: string, password: string}, auth.UserRecord>(functions, 'http-createUser');
const setClaims = httpsCallable<guesthouse.functions.SetClaimsRequest, guesthouse.functions.SetClaimsResponse>(functions, 'http-setClaims');

const listKeysFromDoc = (obj = {}, filterOut = []): string => {
  return Object.keys(obj).filter(k => obj[k]).filter(i => !filterOut.includes(i)).join(', ');
};

const getFullName = (params): string => {
  // params.getValue() was not returning the necessary value, so grabbed directly
  return `${params.row.firstname || ''} ${params.row.lastname || ''}`;
};

type EditUserButtonProps = {
  userId: string;
}

const EditUserButton: React.FC<EditUserButtonProps> = ({ userId }) => {
  const editUserHandler = useEditUserHandler();

  return (
    <IconButton
      size="small"
      onClick={() => editUserHandler(userId)}
    >
      <IconEdit />
    </IconButton>
  );
};

const columns: GridColDef[] = [
  {
    width: 100,
    field: 'docID',
    headerName: ' ',
    filterable: false,
    sortable: false,
    headerClassName: 'v-hidden',
    renderCell: (params) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const notificationContext = useContext<NotificationContext>(NotificationContext);

      let initials = '';

      try {
        initials = params.getValue(params.id, 'firstname')[0] + params.getValue(params.id, 'lastname')[0];
      } catch (e) {
        // do nothing
      }

      return (
        <Tooltip title={params.getValue(params.id, 'docID') || ''}>
          <Button
            onClick={() => {
              copy(String(params.getValue(params.id, 'docID')));
              notificationContext.setContext({ open: true, message: 'Copied to clipboard' });
            }}
          >
            <Avatar src={String(params.getValue(params.id, 'photoURL'))}>
              {initials || ''}
            </Avatar>
          </Button>
        </Tooltip>
      );
    }
  },
  {
    flex: 1,
    field: 'name',
    headerName: 'Name',
    type: 'string',
    // Because the value is nested within a Link & unavailable to GridColDef, valueGetter
    // provides way to access "value" after format is applied in getFullName
    valueGetter: getFullName,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      // Basic comparison that returns a digit value to indicate sort order
      return getFullName(cellParams1).localeCompare(getFullName(cellParams2));
    },
    renderCell: (params) => {
      return (
        <Link
          component={RouterLink}
          to={userPath({ docID: String(params.getValue(params.id, 'id')) })}
        >
          {`${params.getValue(params.id, 'firstname') || ''} ${params.getValue(params.id, 'lastname') || ''}`}
        </Link>
      );
    }
  },
  {
    flex: 1.5,
    field: 'company',
    headerName: 'Company',
  },
  {
    flex: 2,
    field: 'email',
    headerName: 'Email',
    renderCell: (params) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const notificationContext = useContext<NotificationContext>(NotificationContext);

      return (
        <Tooltip title={'Copy to clipboard'}>
          <Link
            role="button"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();

              copy(String(params.getValue(params.id, 'email')));
              notificationContext.setContext({ open: true, message: 'Copied to clipboard' });
            }}
          >
            {params.getValue(params.id, 'email')}
          </Link>
        </Tooltip>
      );
    }
  },
  {
    flex: 1,
    field: 'location',
    headerName: 'Location',
  },
  {
    flex: 1,
    field: 'created',
    headerName: 'Created',
    type: 'date',
    filterable: false,
    valueFormatter: (params: GridValueFormatterParams) => {
      // @ts-ignore
      return formatDate(params.value);
    }
  },
  {
    flex: 1,
    field: 'last_login',
    headerName: 'Last Login',
    type: 'date',
    filterable: false,
    valueFormatter: (params: GridValueFormatterParams) => {
      // @ts-ignore
      return formatDate(params.value);
    }
  },
  {
    flex: 2,
    field: 'regions',
    headerName: 'Regions',
  },
  {
    flex: .75,
    field: 'roles',
    headerName: 'Roles',
  },
  {
    width: 100,
    field: 'id',
    headerName: ' ',
    filterable: false,
    sortable: false,
    hideSortIcons: true,
    resizable: false,
    headerClassName: 'v-hidden',
    renderCell: (params) => {
      return (
        <EditUserButton
          userId={String(params.value)}
        />
      );
    }
  },
];


const Users = () => {
  const { classes: common } = useCommonStyles();
  const { theme } = useStyles();
  const userContext = useContext<UserContext>(UserContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);

  const { collection: users, loading, error } = useCollection(
    query(collection(database, 'users')
      ,orderBy('created', 'desc'))
  );

  const userDocs: guesthouse.User[] = useMemo(() => {
    if (users?.docs) {
      return users.docs
        .map(user => {
          const userData = user.data() as guesthouse.User;

          if (!userData.docID) {
            userData.docID = user.id;
          }

          // @ts-ignore
          userData.id = user.id;

          // @ts-ignore
          userData.flags = listKeysFromDoc(userData.flags);

          // @ts-ignore
          userData.regions = (userData.regions || []).map(s => properCase(s, '_')).join(', ');

          // @ts-ignore
          userData.roles = listKeysFromDoc(userData.roles, ['user']);

          // @ts-ignore
          userData.location = userData.location?.address;

          return userData;
        }).filter(userData => {
          return userData.email;
        });
    }

    return [];
  }, [users?.docs]);

  if (error) {
    return (
      <div className={common.contentSpacing}>
        <Error />
      </div>
    );
  }

  if (loading) {
    return (
      <div className={common.contentSpacing}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div className={common.contentSpacing}>
      <Grid
        container
        style={{ marginBottom: theme.spacing(2) }}
      >
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(1) }}
        >
          <Box
            display="flex"
            alignItems="center"
            height="100%"
          >
            <Typography
              component="h1"
              variant="h3Alt"
              style={{ marginRight: theme.spacing(2) }}
            >
              User Management
            </Typography>

            <Button
              size="small"
              variant="contained"
              color="secondary"
              aria-label="add user"
              data-test="add-user"
              startIcon={<IconPlus />}
              onClick={() => {
                asyncRender(UserDialog, {
                  userContext,
                  initialValues: {
                    regions: [],
                    roles: {},
                    flags: {},
                  },
                  onSubmit: (values: RegisterForm) => {
                    const {
                      password,
                      flags,
                      roles,
                      firstname,
                      lastname,
                      website,
                      instagram_handle,
                      company,
                      location,
                      regions,
                      productCategory,
                      notificationPreferences,
                      shipping_policy,
                      return_policy,
                      shipping_price_per_product,
                      shipping_price_per_order,
                      preferred_warehouse
                    } = values;

                    let { email } = values;

                    if (email?.length && email !== email.toLocaleLowerCase()) {
                      email = email.toLocaleLowerCase();
                    }

                    if (values.phone) {
                      try {
                        values.phone = formatPhone(values.phone);
                      } catch (e) {
                        Sentry.captureException(e);
                      }
                    }

                    return createUser({
                      email,
                      password
                    })
                      .then(async ({ data: user }) => {
                        const now = new Date();
                        const userRoles: Partial<guesthouse.Roles> = { user: true, ...roles };
                        const userData: Partial<guesthouse.User> = {
                          docID: user.uid,
                          firstname,
                          lastname,
                          website,
                          instagram_handle,
                          company,
                          location,
                          regions,
                          email,
                          roles: userRoles,
                          flags: flags,
                          created: Timestamp.fromDate(now),
                          last_login: Timestamp.fromDate(now),
                          notificationPreferences,
                          shipping_policy,
                          return_policy,
                          shipping_price_per_product,
                          shipping_price_per_order,
                          preferred_warehouse
                        };

                        if (Array.isArray(productCategory)) {
                          userData.productCategory = productCategory;
                        }

                        if (values.phone) {
                          try {
                            userData.phone = formatPhone(values.phone);
                          } catch (e) {
                            Sentry.captureException(e);
                          }
                        }

                        try {
                          const claimsResponse = await setClaims({ claims: { flags, regions, roles: userRoles }, userId: user.uid });
                          const { status } = claimsResponse.data;

                          if (status !== 'success') {
                            notificationContext.setContext({ open: true, severity: 'error', message: 'There was an error updating the user claims' });
                          }
                        } catch (e) {
                          Sentry.captureException(e);
                        }

                        await createStreamUser(userData);

                        await setDoc(
                          doc(collection(database, 'users'), user.uid),
                          userData,
                          { merge: true }
                        );

                        return userData;
                      })
                      .then((userData) => {
                        notificationContext.setContext({ open: true, message: `${userFullName(userData, true)} has been created.` });
                      })
                      .catch(e => {
                        if (e) {
                          Sentry.captureException(e);
                          notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                        }
                      });
                  }
                });
              }}
            >
              Add user
            </Button>
          </Box>
        </Grid>

      </Grid>

      <div className={common.overflowSection}>
        <DataGrid
          disableSelectionOnClick
          autoHeight
          rows={userDocs}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default React.memo(Users);
