import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  cardContainerInner: {
    backgroundColor: '#FFFFFF',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #EBEBEB',
    padding: theme.spacing(2),
    height: '100%',
    '& a': {
      textDecoration: 'none !important'
    },
  },
  productPaper: {
    position: 'relative',
    overflow: 'hidden',
    boxShadow: 'none !important',
    borderRadius: 5,
    height: 144,
    [theme.breakpoints.up('md')]: {
      height: 196,
    },
  },
  productImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  dibs: {
    minWidth: 62,
    height: 24,
    borderRadius: 5,
    backgroundColor: '#EDC687',
    top: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 8px',
    fontFamily: theme.gh_vars.mabryMono,
    fontSize: 11,
    textDecoration: 'none',
  },
  productTitleContainer: {
    maxWidth: '100%',
    marginTop: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  productTitle: {
    fontFamily: theme.gh_vars.circular,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.2,
  },
  maker: {
    fontFamily: theme.gh_vars.circular,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
    fontSize: 12,
    fontWeight: 500,
    color: '#080808',
    height: 18,
  },
  location: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: theme.gh_vars.mabryMono,
    fontSize: 11,
    color: '#666666',
    marginLeft: theme.spacing(1),
    width: '100%',
  }
}));
