import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';

import userFullName from '../..//utils/userFullName';
import makeStoreUrl from '../../utils/makeStoreUrl';
import slugOrID from '../../utils/slugOrID';
import ProductOffer from '../product-offer/ProductOffer';
import useStyles from './TaggedProduct.style';

type TaggedProductProps = {
  product: guesthouse.Product;
  defaultProductImage?: string;
  className?: string;
  style?: React.CSSProperties;
  href?: (product: guesthouse.Product) => string;
  renderActions?: (product: guesthouse.Product) => React.ReactElement;
  onClick?: (e, product: guesthouse.Product) => void;
}

const TaggedProduct: React.FC<TaggedProductProps> = (props) => {
  const { product, defaultProductImage, renderActions, href, className, style, onClick } = props;
  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(classes.productListItem, className)}
      style={style}
      onClick={(e) => typeof onClick === 'function' && onClick(e, product)}
    >
      <Link
        href={href(product)}
        style={{
          textDecoration: 'none'
        }}
      >

        <Box
          className={classes.productImageContainer}
        >
          <img
            src={product?.primaryPhoto?.thumbnail_url || defaultProductImage}
            alt="Home photo"
            style={{
              objectFit: 'cover',
              width: 68,
              height: 68,
            }}
          />
        </Box>

      </Link>
      <Box
        className={classes.productInfoContainer}
      >
        <Link
          href={href(product)}
          style={{
            textDecoration: 'none'
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              className={classes.productTitle}
            >
              {product.title}
            </Typography>
            <Typography
              className={classes.offerPrice}
            >
              <ProductOffer offer={product.offers} />
            </Typography>
          </Box>
        </Link>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height="23px"
        >
          <Link
            href={href(product)}
            style={{
              textDecoration: 'none'
            }}
          >
            <Typography
              className={classes.company}
            >
              {userFullName(product.owner, true)}
            </Typography>
          </Link>
          {typeof renderActions === 'function' && (
            <Box className={classes.actions}>
              {renderActions(product)}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

TaggedProduct.defaultProps = {
  href: (product) => makeStoreUrl(`/shop/products/${slugOrID(product)}`)
};

export default TaggedProduct;
