import PrimaryImage from '@components/primary-image/PrimaryImage';
import { STAGING_PRODUCTS } from '@data';
import useAlgoliaSearch from '@hooks/useAlgoliaSearch';
import useDebounceEffect from '@hooks/useDebounceEffect';
import algolia from '@integrations/Algolia';
import Sentry from '@integrations/Sentry';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useStyles } from '@ui/utils/makeStyles';
import userFullName from '@ui/utils/userFullName';
import makeIndexName from '@utils/makeIndexName';
import React, { useEffect, useState } from 'react';

type ProductSearchProps = {
  onChange: (event: React.ChangeEvent<{}>, product: guesthouse.Product | Partial<guesthouse.Product>[]) => void;
  defaultValue?: Partial<guesthouse.Product>;
  label?: string;
  style?: React.CSSProperties;
  variant?: 'standard' | 'filled' | 'outlined';
  TextFieldInputStyle?: React.CSSProperties;
  multiple?: boolean;
  autoFocus?: boolean;
}

const searchStagingProducts = (searchQuery: string) => {
  const ret = [];
  const normalizedSearchQuery = searchQuery?.toLowerCase();

  for (const product of STAGING_PRODUCTS) {
    const normalizedProductTitle = product.title.toLowerCase();

    const queryInTitle = normalizedProductTitle.indexOf(normalizedSearchQuery) > -1;
    const checkTitleInQuery = searchQuery?.length > product.title.length;
    const titleInQuery = normalizedSearchQuery?.indexOf(normalizedProductTitle);

    if (queryInTitle) {
      ret.push(product);
    }

    if (checkTitleInQuery && titleInQuery) {
      ret.push(product);
    }
  }

  return ret;
};

const stagingProductSKUs = STAGING_PRODUCTS.map(product => product.sku);

const defaultProps = {
  label: 'Find Product',
  variant: 'outlined' as ProductSearchProps['variant'],
  multiple: false,
  autoFocus: false,
};

const ProductSearch: React.FC<ProductSearchProps> = (props: ProductSearchProps) => {
  const { theme } = useStyles();
  const [options, setOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState<string>();

  const productsIndex = algolia.initIndex(makeIndexName('products'));

  const { results: allProducts, loading } = useAlgoliaSearch<guesthouse.Product>({
    index: productsIndex,
    numericFilters: '',
    facetFilters: '',
    query: searchQuery,
    // TODO: revert back after long-term fix to nested data issue in Algolia
    perPage: 12,
  });

  useEffect(() => {
    algolia.clearCache();
  }, []);

  useDebounceEffect(() => {
    const ret = searchStagingProducts(searchQuery).concat(allProducts?.hits || []);

    setOptions(ret);
  }, 200, [allProducts?.hits, searchQuery]);

  return (
    <Autocomplete
      style={props.style}
      sx={{
        '& .MuiAutocomplete-endAdornment': {
          top: props.variant === 'filled' ? 'calc(50% - 5px)' : 'calc(50% - 14px)'
        }
      }}
      multiple={props.multiple}
      defaultValue={props.defaultValue}
      filterOptions={(x) => x}
      getOptionLabel={(option: Partial<guesthouse.Product>) => option.title}
      renderOption={(props, option) => {
        let Icon: React.FC<React.SVGProps<SVGSVGElement>>;

        if (option.image) {
          Icon = option.image;
        }

        return (
          <li
            {...props}
            key={option.docID || option.sku}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              {!Icon && <PrimaryImage
                item={option}
                style={{
                  height: 50,
                  width: 50,
                  marginRight: theme.spacing(1.5),
                  marginTop: theme.spacing(1.5),
                }}
              />}
              {Icon && (
                <Icon
                  height={50}
                  width={50}
                  style={{
                    marginRight: theme.spacing(1.5),
                  }}
                />
              )}
              <Box>
                <Typography
                  fontFamily={theme.gh_vars.circular}
                  fontWeight={500}
                >
                  {option.title}
                </Typography>

                <Typography
                  fontFamily={theme.gh_vars.circular}
                  variant="body2"
                  color="textSecondary"
                  style={{ fontSize: 12 }}
                >
                  {stagingProductSKUs.includes(option.sku) ? option.description : userFullName(option.owner, true)}
                </Typography>
              </Box>
            </Box>
          </li>
        );
      }}
      options={options}
      loading={loading}
      renderInput={params => (
        // @ts-ignore
        <TextField
          autoFocus={props.autoFocus}
          {...params}
          fullWidth
          label={props.label}
          variant={props.variant}
          sx={{
            '& .MuiInputLabel-root:not(.Mui-focused)': {
              paddingTop: props.variant === 'filled' && !params.inputProps.onFocusCapture ? 1.2 : 0
            }
          }}
          InputProps={{
            style: props.TextFieldInputStyle,
            disableUnderline: true,
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress
                  color="inherit"
                  size={20}
                /> : null}

                {params.InputProps.endAdornment}
              </>
            ),
          }}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      )}
      onChange={(e, product) => {
        try {
          return props.onChange(e, product);
        } catch (e) {
          Sentry.captureException(e);
          return product;
        }
      }}
    />
  );
};

ProductSearch.defaultProps = defaultProps;

export default ProductSearch;
