import ProductSearch from '@components/product-search/ProductSearch';
import UserSearch from '@components/user-search/UserSearch';
import { MAX_USES_TYPES, ORDER_APPLICATION_TYPES } from '@data';
import useMuiMenuHooks from '@hooks/useMuiMenuHooks';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PromoCodeSchema, { initialValues as _initialValues, PromoCodeValues } from '@schema/PromoCodeSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import CircleRefresh from '@ui/icons/imaterial/arrows/Refresh 01.svg';
import IconPercent from '@ui/icons/imaterial/business-and-finance/Discount.svg';
import IconFixed from '@ui/icons/imaterial/business-and-finance/Dollar Coin.svg';
import theme from '@ui/theme';
import toJsDate from '@ui/utils/toJsDate';
import { isError } from '@ui/utils/typescriptHelpers';
import { customCode } from '@ui/utils/validators';
import { generatePromoCode } from '@utils/generatePromoCode';
import endOfDay from 'date-fns/endOfDay';
import { Field, Formik, FormikHelpers } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import React, { useEffect, useState } from 'react';

import TextFieldPrice from '../components/textfield-price/TextFieldPrice';


type PromoCodeFormProps = {
  initialValues: PromoCodeValues;
  error: Error | string;
  submitText: string;
  /**
   * will this form batch create or batch edit promo's?
   */
  batch?: 'create' | 'edit';
  onSubmit: (values: PromoCodeValues, formikHelpers: FormikHelpers<PromoCodeValues>) => void | Promise<any>;
}

const PromoCodeForm = ({ onSubmit, initialValues = _initialValues, error, submitText = 'Continue', batch }: PromoCodeFormProps) => {
  const [initialValue, setInitialValue] = useState('');

  const {
    anchorEl: stagingAnchorEl,
    open: stagingOpen,
    handleClick: stagingHandleClick,
    handleClose: stagingHandleClose,
  } = useMuiMenuHooks();

  const {
    anchorEl: retailAnchorEl,
    open: retailOpen,
    handleClick: retailHandleClick,
    handleClose: retailHandleClose,
  } = useMuiMenuHooks();


  return (
    <Formik
      validationSchema={PromoCodeSchema}
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched
      }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (!initialValue && !batch) {
            if (values.code) {
              setInitialValue(values.code);
            }

            if (!values.code) {
              setFieldTouched('code', true);
              generatePromoCode()
                .then(code => {
                  setFieldValue('code', code);
                  setInitialValue(code);
                });
            }
          }
        }, [initialValue]);

        const handleRefreshCode = () => {
          setFieldTouched('code', true);
          generatePromoCode()
            .then(code => {
              setFieldValue('code', code);
            });
        };

        return (
          <form onSubmit={handleSubmit}>
            {!batch && <Field
              fullWidth
              autoFocus
              name="code"
              label="Code"
              type="text"
              validate={(value) => customCode(value, initialValue)}
              component={FormikTextField}
              margin="normal"
              variant="outlined"
              value={values.code ?? initialValue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Generate new unique, random code">
                      <IconButton
                        aria-label="generate new code"
                        onClick={handleRefreshCode}
                      >
                        <CircleRefresh />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )
              }}
              onValueChange={(values) => {
                setFieldValue('code', values);
                setFieldTouched('code', true);
              }}
            />}

            <Field
              fullWidth
              name="description"
              label="Description"
              type="text"
              component={FormikTextField}
              margin="normal"
              variant="outlined"
              onChange={(e) => {
                setFieldValue('description', e.target.value);
                setFieldTouched('description', true);
              }}
            />

            <Field
              fullWidth
              name="staging_discount_amount"
              label="Staging Discount"
              type="text"
              disabled={!values.staging_discount_type}
              component={TextFieldPrice}
              margin="normal"
              variant="outlined"
              customInput={TextField}
              value={values?.staging_discount_amount || ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {!values.staging_discount_type ? '' : values.staging_discount_type === 'fixed' ? '$' : '%'}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="change discount type"
                      edge="end"
                      onClick={stagingHandleClick}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onValueChange={(values) => {
                const { value } = values;

                setFieldValue('staging_discount_amount', Number(value));
              }}
            />

            <Field
              fullWidth
              name="retail_discount_amount"
              label="Retail Discount"
              type="text"
              disabled={!values.retail_discount_type}
              component={TextFieldPrice}
              margin="normal"
              variant="outlined"
              customInput={TextField}
              value={values?.retail_discount_amount || ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {!values.retail_discount_type ? '' : values.retail_discount_type === 'fixed' ? '$' : '%'}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="change discount type"
                      edge="end"
                      onClick={retailHandleClick}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onValueChange={(values) => {
                const { value } = values;

                setFieldValue('retail_discount_amount', Number(value));
              }}
            />

            <Field
              fullWidth
              name="min_order_amount"
              label="Minimum Order Amount"
              type="text"
              component={TextFieldPrice}
              margin="normal"
              variant="outlined"
              customInput={TextField}
              value={values?.min_order_amount || ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
              onValueChange={(values) => {
                const { value } = values;

                setFieldValue('min_order_amount', Number(value));
              }}
            />

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.order_application && Boolean(errors.order_application)}
            >
              <InputLabel id="category-label">
                Order Application
              </InputLabel>
              <Select
                id="order_application"
                value={values.order_application || []}
                label="Order Application"
                onChange={(e) => {
                  setFieldTouched('order_application', true);
                  setFieldValue('order_application', e.target.value);
                }}
              >
                {ORDER_APPLICATION_TYPES.map(type => (
                  <MenuItem
                    key={type.id}
                    value={type.id}
                  >
                    {type.title}
                  </MenuItem>
                ))}
              </Select>
              {touched.order_application && Boolean(errors.order_application) && (
                <FormHelperText error>
                  {errors.order_application}
                </FormHelperText>
              )}
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Expiration Date"
                value={toJsDate(values?.expires) || null}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    margin: 'normal',
                    helperText: '',
                    style: { width: '100%' }
                  }
                }}
                onChange={(date) => {
                  if (date === null) {
                    setFieldValue('expires', undefined);
                  } else {
                    setFieldValue('expires', endOfDay(date));
                  }
                }}
              />
            </LocalizationProvider>

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.max_uses_type && Boolean(errors.max_uses_type)}
            >
              <InputLabel>
                Max Uses Type
              </InputLabel>
              <Select
                id="max_uses_type"
                value={values.max_uses_type || ''}
                label="Max Uses Type"
                onChange={(e) => {
                  setFieldTouched('max_uses_type', true);
                  setFieldValue('max_uses_type', e.target.value);
                }}
              >
                {MAX_USES_TYPES.map(type => (
                  <MenuItem
                    key={type.id}
                    value={type.id}
                  >
                    {type.title}
                  </MenuItem>
                ))}
              </Select>
              {touched.max_uses_type && Boolean(errors.max_uses_type) && (
                <FormHelperText error>
                  {errors.max_uses_type}
                </FormHelperText>
              )}
            </FormControl>

            <Field
              fullWidth
              name="max_uses"
              label="Maximum Uses"
              type="text"
              component={TextFieldPrice}
              margin="normal"
              variant="outlined"
              customInput={TextField}
              value={values?.max_uses || ''}
              onValueChange={(values) => {
                const { value } = values;

                setFieldValue('max_uses', Number(value));
              }}
            />

            {!batch && (
              <>
                <UserSearch
                  defaultValue={values.users_included}
                  useCompany={true}
                  multiple={true}
                  label="Included Users"
                  style={{
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(1),
                  }}
                  onChange={(e, value: guesthouse.User[]) => {
                    setFieldValue('users_included', value);
                    setFieldTouched('users_included');
                  }}
                />

                <UserSearch
                  defaultValue={values.users_excluded}
                  useCompany={true}
                  multiple={true}
                  label="Excluded Users"
                  style={{
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(1),
                  }}
                  onChange={(e, value: guesthouse.User[]) => {
                    setFieldValue('users_excluded', value);
                    setFieldTouched('users_excluded');
                  }}
                />

                <ProductSearch
                  defaultValue={values.products_included}
                  multiple={true}
                  label="Included Products"
                  style={{
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(1),
                  }}
                  onChange={(e, value: guesthouse.Product[] | guesthouse.StagingProduct[]) => {
                    value.forEach(product => {
                      delete product.currentHomes;
                      delete product.pastHomes;
                      delete product.futureHomes;
                      delete product.image;
                      return product;
                    });
                    setFieldValue('products_included', value);
                    setFieldTouched('products_included');
                  }}
                />

                <ProductSearch
                  defaultValue={values.products_excluded}
                  multiple={true}
                  label="Excluded Products"
                  style={{
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(1),
                  }}
                  onChange={(e, value: guesthouse.Product[] | guesthouse.StagingProduct[]) => {
                    value.forEach(product => {
                      delete product.currentHomes;
                      delete product.pastHomes;
                      delete product.futureHomes;
                      delete product.image;
                      return product;
                    });
                    setFieldValue('products_excluded', value);
                    setFieldTouched('products_excluded');
                  }}
                />
              </>
            )}

            {error && (
              <>
                <FormHelperText error>
                  {isError(error) ? error.message : error}
                </FormHelperText>
                <FormHelperText>
                  Need help? Contact
                  {' '}
                  <Link href="mailto:support@guesthouseshop.com">
                    support@guesthouseshop.com
                  </Link>
                </FormHelperText>
              </>
            )}
            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                data-test="home-submit-button"
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length)}
                aria-label={submitText}
                tooltip={makeErrors(errors, touched)?.length && (
                  <TooltipErrors
                    errors={errors}
                    touched={touched}
                  />
                )}
              >
                {submitText}
              </SubmitButton>
            </div>
            <Menu
              anchorEl={retailAnchorEl}
              open={retailOpen}
              onClose={retailHandleClose}
              onClick={retailHandleClose}
            >
              <MenuItem
                onClick={() => {
                  setFieldTouched('retail_discount_type', true);
                  setFieldValue('retail_discount_type', 'fixed');
                }}
              >
                <ListItemIcon>
                  <IconFixed fontSize="small" />
                </ListItemIcon>
                Fixed
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFieldTouched('retail_discount_type', true);
                  setFieldValue('retail_discount_type', 'percent');
                }}
              >
                <ListItemIcon>
                  <IconPercent fontSize="small" />
                </ListItemIcon>
                Percent
              </MenuItem>
            </Menu>

            <Menu
              anchorEl={stagingAnchorEl}
              open={stagingOpen}
              onClose={stagingHandleClose}
              onClick={stagingHandleClose}
            >
              <MenuItem
                onClick={() => {
                  setFieldTouched('staging_discount_type', true);
                  setFieldValue('staging_discount_type', 'fixed');
                }}
              >
                <ListItemIcon>
                  <IconFixed fontSize="small" />
                </ListItemIcon>
                Fixed
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFieldTouched('staging_discount_type', true);
                  setFieldValue('staging_discount_type', 'percent');
                }}
              >
                <ListItemIcon>
                  <IconPercent fontSize="small" />
                </ListItemIcon>
                Percent
              </MenuItem>
            </Menu>

          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(PromoCodeForm);
