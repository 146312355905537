import UserContext from '@context/UserContext';
import { useContext, useEffect, useState } from 'react';
import { ChannelMembership } from 'stream-chat';

export default function useOtherMembers(channel): ChannelMembership<GHStreamGenerics>[] {
  const userContext = useContext<UserContext>(UserContext);
  const [otherMembers, setOtherMembers] = useState<ChannelMembership<GHStreamGenerics>[]>([]);

  const getOtherMembers = () => {
    if (channel) {
      const members = Object.values(channel.state.members);
      const otherMembers: ChannelMembership<GHStreamGenerics>[] = members.filter((member: ChannelMembership) => member?.user.id !== userContext.user.uid);

      setOtherMembers(otherMembers);
    }
  };

  useEffect(() => {
    getOtherMembers();
  }, [channel]);

  useEffect(() => {
    if (channel) {
      channel.on('member.added', getOtherMembers);
      channel.on('member.removed', getOtherMembers);
      channel.on('member.updated', getOtherMembers);

      return () => {
        channel.off('member.added', getOtherMembers);
        channel.off('member.removed', getOtherMembers);
        channel.off('member.updated', getOtherMembers);
      };
    }
  }, [channel]);

  return otherMembers;
}
