import { differenceInCalendarDays, differenceInMinutes, getMonth,subDays } from 'date-fns';

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const lastMessageTime = (date: Date, currentDate = Date.now()) => {
  const timeDiff = differenceInMinutes(currentDate, new Date(date).getTime());
  const timeDiffDays = differenceInCalendarDays(currentDate, new Date(date).getTime());

  if (timeDiffDays > 365) {
    return 'more than a year';
  } else if (timeDiffDays >= 7) {
    const tempDate = subDays(currentDate, timeDiffDays);

    return monthNames[getMonth(tempDate)] + ' ' + tempDate.getUTCDate();
  } else if (timeDiff > 1440) {
    return Math.floor(timeDiff / (60 * 24)).toString() + (Math.floor(timeDiff / (60 * 24)) === 1 ? ' day' :' days');
  } else if (timeDiff > 60) {
    if (Math.floor(timeDiff / 60) < 2) {
      return Math.floor(timeDiff / 60).toString() + ' hour';
    }
    return Math.floor(timeDiff / 60).toString() + ' hours';
  }
  if (timeDiff < 1) {
    return 'Just now';
  } else if (timeDiff < 60) {
    return timeDiff.toString() + ' mins ago';
  }
};

export default lastMessageTime;
