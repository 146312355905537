import { Timestamp } from 'firebase/firestore';

import newId from './newId';


type OfferTypes = 'internal' | 'dropship' | 'dibs';

export type OfferRequiredFields = {
  price: number;
  shippingPricePerProduct?: number;
  shippingPricePerOrder?: number;
  soldBy: guesthouse.User;
  /**
   * If dibs type should be returned, salePrice must be included
   */
  salePrice?: number;
  type?: guesthouse.ProductOfferType;
  condition?: guesthouse.ProductOfferCondition;
}

interface GenerateOffersProps {
  offerTypes: OfferTypes[];
  offerOptions: OfferRequiredFields;
}

const generateOffers = (props: GenerateOffersProps): guesthouse.ProductOffer[] => {
  const { offerTypes, offerOptions } = props;
  const ret: guesthouse.ProductOffer[] = [];

  if (offerTypes.includes('internal')) {
    const internalOffer: guesthouse.ProductOffer = {
      docID: newId(),
      type: 'used',
      price: offerOptions.price,
      sale: false,
      shippingPricePerOrder: offerOptions.shippingPricePerOrder ?? 0,
      shippingPricePerProduct: offerOptions.shippingPricePerProduct ?? 0,
      soldBy: offerOptions.soldBy,
      pickupOnly: true,
      dropship: false,
      classification: 'internal',
      created: Timestamp.fromDate(new Date()),
    };

    ret.push(internalOffer);
  }

  if (offerTypes.includes('dropship')) {
    const dropshipOffer: guesthouse.ProductOffer = {
      docID: newId(),
      type: 'new',
      condition: 'excellent',
      price: offerOptions.price,
      sale: false,
      shippingPricePerOrder: offerOptions.shippingPricePerOrder ?? 0,
      shippingPricePerProduct: offerOptions.shippingPricePerProduct ?? 0,
      soldBy: offerOptions.soldBy,
      pickupOnly: false,
      dropship: true,
      classification: 'dropship',
      created: Timestamp.fromDate(new Date()),
    };

    if (offerOptions.salePrice) {
      dropshipOffer.salePrice = offerOptions.salePrice;
      dropshipOffer.sale = true;
    }

    ret.push(dropshipOffer);
  }

  if (offerTypes.includes('dibs')) {
    const dibsOffer: guesthouse.ProductOffer = {
      docID: newId(),
      type: offerOptions.type || 'used',
      price: offerOptions.price,
      sale: false,
      shippingPricePerOrder: offerOptions.shippingPricePerOrder ?? 0,
      shippingPricePerProduct: offerOptions.shippingPricePerProduct ?? 0,
      soldBy: offerOptions.soldBy,
      pickupOnly: true,
      dropship: false,
      classification: 'dibs',
      created: Timestamp.fromDate(new Date()),
    };

    if (offerOptions.condition) {
      dibsOffer.condition = offerOptions.condition;
    }

    if (offerOptions.salePrice) {
      dibsOffer.salePrice = offerOptions.salePrice;
      dibsOffer.sale = true;
    }

    ret.push(dibsOffer);
  }

  return ret;
};

export default generateOffers;
