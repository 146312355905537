import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import { RegionDialog } from '@components/dialogs/RegionDialog';
import Error from '@components/error/Error';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import Sentry from '@integrations/Sentry';
import IconPlus from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useCommonStyles from '@styles/common.style';
import { collection as firestoreCollection, doc as firestoreDoc, query as firestoreQuery, setDoc,Timestamp } from 'firebase/firestore';
import React, { useContext } from 'react';

import { DefaultFirestoreConverter } from '../../../types/DefaultFirestoreConverter';
import RegionActions from './RegionActions';
import RegionsSkeleton from './Regions.skeleton';

const Regions = () => {
  const { classes: common, theme } = useCommonStyles();
  const userContext = useContext<UserContext>(UserContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);

  const query = firestoreQuery(firestoreCollection(database, 'regions').withConverter(new DefaultFirestoreConverter<guesthouse.Region>()));

  const { collection, loading, error } = useCollection<guesthouse.Region>(query);

  if (error) {
    return (
      <div className={common.contentSpacing}>
        <Error />
      </div>
    );
  }

  if (loading) {
    return (
      <div className={common.contentSpacing}>
        <RegionsSkeleton />
      </div>
    );
  }

  const docs = collection && collection.docs;

  let title = '0 Regions';

  if (docs && docs.length) {
    title = `${docs.length} Region${docs.length > 1 ? 's' : ''}`;
  }

  return (
    <div className={common.contentSpacing}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Box
            display="flex"
            alignItems="center"
          >
            <Typography
              component="h1"
              variant="h3Alt"
              style={{ marginRight: theme.spacing(1) }}
            >
              {title}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{ marginLeft: theme.spacing(2) }}
              startIcon={<IconPlus />}
              onClick={() => {
                asyncRender(RegionDialog, { userContext })
                  .then((result) => {
                    if (result) {
                      const region: guesthouse.Region = {
                        ...result,
                        created: Timestamp.fromDate(new Date()),
                      };

                      return setDoc(
                        firestoreDoc(firestoreCollection(database, 'regions'), result.docID),
                        region
                      );
                    }
                  })
                  .catch((e) => {
                    if (e) {
                      Sentry.captureException(e);
                      notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                    }
                  });
              }}
            >
              Add Region
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >

        </Grid>
      </Grid>

      {docs?.length
        ? (
          <TableContainer>
            <Table aria-label="regions table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    Center Point
                  </TableCell>
                  <TableCell align="right">
                    Radius
                  </TableCell>
                  <TableCell align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collection.docs.map((doc) => {
                  const region = doc.data();

                  return (
                    <TableRow key={region.name}>
                      <TableCell>
                        {region.name}
                      </TableCell>
                      <TableCell>
                        {region.centerPoint?.address}
                      </TableCell>
                      <TableCell align="right">
                        {region.aroundRadius}
                      </TableCell>
                      <TableCell
                        width="100"
                        height="60"
                        align="center"
                      >
                        <RegionActions region={doc} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )
        : (
          <Typography>
            There&apos;s nothing here yet.
          </Typography>
        )}
    </div>
  );
};

export default React.memo(Regions);
