import CONFIG from '@config';
import Backend from '@layouts/backend/Backend';
import Blank from '@layouts/blank/Blank';
import FullCenter from '@layouts/full-center/FullCenter';
import Registration from '@layouts/registration/Registration';
import _404 from '@pages/404/404';
import Billing from '@pages/billing/Billing';
import CalendarPage from '@pages/calendar/Calendar';
import CalendarsPage from '@pages/calendars/Calendars';
import ConfirmEmail from '@pages/confirm-email/ConfirmEmail';
import Feed from '@pages/feed/Feed';
import Home from '@pages/home/Home';
import HomeCreate from '@pages/home-create/HomeCreate';
import Homes from '@pages/homes/Homes';
import HomesAll from '@pages/homes/HomesAll';
import InventoryPage from '@pages/inventory/Inventory';
import Login from '@pages/login/Login';
import Messages from '@pages/messages/Messages';
import NewPassword from '@pages/new-password/NewPassword';
import Order from '@pages/order/Order';
import Orders from '@pages/orders/Orders';
import Payments from '@pages/payments/Payments';
import Photos from '@pages/photos/Photos';
import CompactPriceSheetPage from '@pages/price-sheet/CompactPriceSheet';
import PriceSheetPage from '@pages/price-sheet/PriceSheet';
import Pricing from '@pages/pricing/Pricing';
import Product from '@pages/product/Product';
import ProductCreate from '@pages/product-create/ProductCreate';
import ProductCreateMaker from '@pages/product-create-maker/ProductCreateMaker';
import Products from '@pages/products/Products';
import Profile from '@pages/profile/Profile';
import Promo from '@pages/promo/Promo';
import Promos from '@pages/promos/Promos';
import Regions from '@pages/regions/Regions';
import Register from '@pages/register/Register';
import ResetPassword from '@pages/reset-password/ResetPassword';
import Room from '@pages/room/Room';
import RoomCreate from '@pages/room-create/RoomCreate';
import RoomSet from '@pages/roomset/RoomSet';
import RoomSets from '@pages/roomsets/RoomSets';
import Search from '@pages/search/Search';
import SendLink from '@pages/send-link/SendLink';
import ReceiptStaging from '@pages/staging/Receipt';
import User from '@pages/user/User';
import UserManagement from '@pages/user-management/UserManagement';
import Warehouses from '@pages/warehouses/Warehouses';
import IconUSDCircleSolid from '@ui/icons/drawer-icons/atm-dollar-filled.svg';
import IconCameraSolid from '@ui/icons/drawer-icons/burst-mode-solid.svg';
import IconGridSolid from '@ui/icons/drawer-icons/grid-01-solid.svg';
import IconHomeSolid from '@ui/icons/drawer-icons/home-solid.svg';
import IconChairSolid from '@ui/icons/drawer-icons/king-bed-solid.svg';
import IconWarehouse from '@ui/icons/drawer-icons/museum.svg';
import IconWarehouseSolid from '@ui/icons/drawer-icons/museum-solid.svg';
import IconMapSolid from '@ui/icons/drawer-icons/search-map-solid.svg';
import IconCartSolid from '@ui/icons/drawer-icons/shopping-cart-01-solid.svg';
import IconUsersSolid from '@ui/icons/drawer-icons/users-solid.svg';
import IconGrid from '@ui/icons/imaterial/base/Grid 01.svg';
import IconMap from '@ui/icons/imaterial/base/Search Map.svg';
import PromosIconSolid from '@ui/icons/imaterial/base/Tag Filled.svg';
import PromosIcon from '@ui/icons/imaterial/base/Tag.svg';
import IconUSDCircle from '@ui/icons/imaterial/business-and-finance/ATM Dollar.svg';
import IconPricing from '@ui/icons/imaterial/business-and-finance/Bag of Cash.svg';
import IconCart from '@ui/icons/imaterial/business-and-finance/Shopping Cart 01.svg';
import MessageFilled from '@ui/icons/imaterial/communication/Message Text Filled.svg';
import Message from '@ui/icons/imaterial/communication/Message Text.svg';
import IconUsers from '@ui/icons/imaterial/communication/Users.svg';
import IconHome from '@ui/icons/imaterial/home/Home.svg';
import IconChair from '@ui/icons/imaterial/home/King Bed.svg';
import LampIcon from '@ui/icons/imaterial/home/Lamp.svg';
import IconRoomSets from '@ui/icons/imaterial/home/Outside Table.svg';
import IconCamera from '@ui/icons/imaterial/media/Burst Mode.svg';
import IconCalendar from '@ui/icons/imaterial/time-and-date/Calendar.svg';

export type AppRoute = {
  path: string;
  title: string;
  roles?: Array<'*' | '@' | '!' | guesthouse.RoleTypes> 
  flags?: Array<'*' | guesthouse.FlagTypes> 
  Page: React.FunctionComponent;
  Layout: React.FunctionComponent;
  Icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  IconActive?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  /**
   * Does this route show in the main navigation
   */
  nav?: boolean;
  /**
   * Order in main navigation
   */
  order?: number;
}

const NAVBAR_ORDER = [
  'messages',         // 1
  'feed',             // 2
  'homes',            // 3
  'products',         // 4
  'inventory',        // 5
  'photos',           // 6
  'orders',           // 7
  'warehouses',       // 8
  'regions',          // 9
  'pricing',          // was 20, now below regions / warehouses
  'payments',         // 10
  'promos',           // 12
  'user-management',  // 14
  'calendars',        // 15
];


/**
 * Order matters below
 * 
 * Role ['*'] - public route
 * Role ['@'] - all logged in users
 * Role ['!'] - only logged out users
 */
const ROUTES : { [key: string] : AppRoute } = {
  login: {
    path: '/',
    Page: Login,
    Layout: Registration,
    title: 'Login',
    roles: ['*'],
  },
  register: {
    path: '/register',
    Page: Register,
    Layout: Registration,
    title: 'Register',
    roles: ['*'],
  },
  calendars: {
    path: '/calendars',
    Page: CalendarsPage,
    Layout: Backend,
    title: 'Calendars',
    nav: true,
    order: NAVBAR_ORDER.indexOf('calendars'),
    roles: ['admin'],
    Icon: IconCalendar,
    IconActive: IconCalendar,
    flags: ['calendars']
  },
  calendar: {
    path: '/calendars/:id',
    Page: CalendarPage,
    Layout: Backend,
    title: ' Calendar',
    roles: ['admin'],
    flags: ['calendars']
  },
  profile: {
    path: '/profile',
    Page: Profile,
    Layout: Backend,
    title: 'Profile',
    roles: ['@'],
  },
  messages: {
    path: '/messages',
    Page: Messages,
    Layout: Backend,
    title: 'Messages',
    nav: true,
    order: NAVBAR_ORDER.indexOf('messages'),
    roles: ['@'],
    Icon: Message,
    IconActive: MessageFilled,
  },
  feed: {
    path: '/feed',
    Page: Feed,
    Layout: Backend,
    title: 'Feed',
    roles: ['@'],
    nav: true,
    order: NAVBAR_ORDER.indexOf('feed'),
    Icon: IconGrid,
    IconActive: IconGridSolid,
  },
  search: {
    path: '/search',
    Page: Search,
    Layout: Backend,
    title: 'Search',
    roles: ['@'],
  },
  receiptStaging: {
    path: '/staging/:orderID/receipt',
    Page: ReceiptStaging,
    Layout: Blank,
    title: 'Order Receipt',
    roles: ['@'],
    nav: false,
  },
  homesAll: {
    path: '/homes/all',
    Page: HomesAll,
    Layout: Backend,
    title: 'All Homes',
    roles: ['@'],
    nav: false,
  },
  homeCreate: {
    path: '/homes/new',
    Page: HomeCreate,
    Layout: Backend,
    title: 'New Home',
    roles: ['designer', 'admin', 'design_manager', 'website_manager', 'customer_support', 'media_manager'],
  },
  priceSheet: {
    path: '/homes/:id/price-sheet',
    Page: PriceSheetPage,
    Layout: Blank,
    title: 'Price Sheet',
    roles: ['@']
  },
  compactPriceSheet: {
    path: '/homes/:id/compact-price-sheet',
    Page: CompactPriceSheetPage,
    Layout: Blank,
    title: 'Compact Price Sheet',
    roles: ['@']
  },
  roomCreate: {
    path: '/homes/:homeId/rooms/new',
    Page: RoomCreate,
    Layout: Backend,
    title: 'New Room',
    roles: ['designer', 'admin', 'design_manager', 'website_manager', 'customer_support'],
  },
  // used implicitly by PhotoGrid
  roomPhoto: {
    path: '/homes/:homeId/rooms/:id/:tab/:photoID',
    Page: Room,
    Layout: Backend,
    title: 'Room Photo',
    roles: ['@']
  },
  roomTab: {
    path: '/homes/:homeId/rooms/:id/:tab',
    Page: Room,
    Layout: Backend,
    title: 'Room',
    roles: ['@']
  },
  room: {
    path: '/homes/:homeId/rooms/:id',
    Page: Room,
    Layout: Backend,
    title: 'Room',
    roles: ['@']
  },
  // used implicitly by PhotoGrid
  homePhoto: {
    path: '/homes/:id/:tab/:photoID',
    Page: Home,
    Layout: Backend,
    title: 'Home Photo',
    roles: ['@']
  },
  homeTab: {
    path: '/homes/:id/:tab',
    Page: Home,
    Layout: Backend,
    title: 'Home',
    roles: ['@']
  },
  home: {
    path: '/homes/:id',
    Page: Home,
    Layout: Backend,
    title: 'Home',
    roles: ['@']
  },
  homes: {
    path: '/homes',
    Page: Homes,
    Layout: Backend,
    title: 'Homes',
    roles: ['@'],
    nav: true,
    order: NAVBAR_ORDER.indexOf('homes'),
    Icon: IconHome,
    IconActive: IconHomeSolid,
  },
  warehouses: {
    path: '/warehouses',
    Page: Warehouses,
    Layout: Backend,
    title: 'Warehouses',
    roles: ['admin', 'design_manager'],
    nav: true,
    order: NAVBAR_ORDER.indexOf('warehouses'),
    Icon: IconWarehouse,
    IconActive: IconWarehouseSolid,
  },
  inventory: {
    path: '/inventory',
    Page: InventoryPage,
    Layout: Backend,
    title: 'Inventory',
    roles: ['admin'],
    nav: true,
    order: NAVBAR_ORDER.indexOf('inventory'),
    Icon: LampIcon,
    IconActive: LampIcon,
  },
  regions: {
    path: '/regions',
    Page: Regions,
    Layout: Backend,
    title: 'Regions',
    roles: ['admin', 'design_manager'],
    nav: true,
    order: NAVBAR_ORDER.indexOf('regions'),
    Icon: IconMap,
    IconActive: IconMapSolid,
  },
  productCreate: {
    path: '/products/new',
    Page: ProductCreate,
    Layout: Backend,
    title: 'Products',
    roles: ['maker', 'designer', 'admin', 'design_manager', 'website_manager', 'customer_support']
  },
  productCreateMaker: {
    path: '/products-maker/new',
    Page: ProductCreateMaker,
    Layout: Backend,
    title: 'Products',
    roles: ['maker', 'designer', 'admin', 'design_manager', 'website_manager', 'customer_support']
  },
  productPhoto: {
    path: '/products/:id/:photos/:photoID',
    Page: Product,
    Layout: Backend,
    title: 'Product Photo',
    roles: ['maker', 'realtor', 'designer', 'admin', 'design_manager', 'website_manager', 'customer_support'],
  },
  products: {
    path: '/products',
    Page: Products,
    Layout: Backend,
    title: 'Products',
    roles: ['maker', 'designer', 'admin', 'design_manager', 'website_manager', 'customer_support'],
    nav: true,
    order: NAVBAR_ORDER.indexOf('products'),
    Icon: IconChair,
    IconActive: IconChairSolid,
  },
  product: {
    path: '/products/:id',
    Page: Product,
    Layout: Backend,
    title: 'Product',
    roles: ['maker', 'realtor', 'designer', 'admin', 'design_manager', 'website_manager', 'customer_support'],
  },
  roomSets: {
    path: '/roomsets',
    Page: RoomSets,
    Layout: Backend,
    title: 'Room Sets',
    roles: ['admin', 'design_manager'],
    nav: true,
    order: 5,
    Icon: IconRoomSets,
    IconActive: IconRoomSets
  },
  roomSet: {
    path: '/roomsets/:id',
    Page: RoomSet,
    Layout: Backend,
    title: 'Room Set',
    roles: ['admin', 'design_manager'],
  },
  photo: {
    path: '/photos/:photoID',
    Page: Photos,
    Layout: Backend,
    title: 'Photo',
    roles: ['maker', 'realtor', 'designer', 'admin', 'design_manager', 'website_manager', 'customer_support', 'media_manager'],
  },
  photos: {
    path: '/photos',
    Page: Photos,
    Layout: Backend,
    title: 'Photos',
    roles: ['maker', 'realtor', 'designer', 'admin', 'design_manager', 'website_manager', 'customer_support', 'media_manager'],
    nav: true,
    order: NAVBAR_ORDER.indexOf('photos'),
    Icon: IconCamera,
    IconActive: IconCameraSolid,
  },
  user: {
    path: '/users/:id',
    Page: User,
    Layout: Backend,
    title: 'User',
    roles: ['designer', 'admin', 'design_manager', 'website_manager', 'customer_support']
  },
  userManagement: {
    path: '/user-management',
    Page: UserManagement,
    Layout: Backend,
    title: 'User Mgmt',
    roles: ['admin', 'design_manager'],
    nav: true,
    order: NAVBAR_ORDER.indexOf('user-management'),
    Icon: IconUsers,
    IconActive: IconUsersSolid,
  },
  orders: {
    path: '/orders',
    Page: Orders,
    Layout: Backend,
    title: 'Orders',
    roles: ['maker', 'admin', 'design_manager', 'customer_support'],
    nav: true,
    order: NAVBAR_ORDER.indexOf('orders'),
    Icon: IconCart,
    IconActive: IconCartSolid,
  },
  order: {
    path: '/orders/:id',
    Page: Order,
    Layout: Backend,
    title: 'Order',
    roles: ['maker', 'admin', 'design_manager', 'customer_support'],
  },
  payments: {
    path: '/payments',
    Page: Payments,
    Layout: Backend,
    title: 'Payments',
    roles: ['maker'],
    nav: true,
    order: NAVBAR_ORDER.indexOf('payments'),
    Icon: IconUSDCircle,
    IconActive: IconUSDCircleSolid,
  },
  resetPassword: {
    path: '/reset-password',
    Page: ResetPassword,
    Layout: Registration,
    title: 'Reset Password',
    roles: ['!'],
  },
  newPassword: {
    path: '/new-password',
    Page: NewPassword,
    Layout: Registration,
    title: 'New Password',
    roles: ['!'],
  },
  confirmEmail: {
    path: '/confirm-email',
    Page: ConfirmEmail,
    Layout: Registration,
    title: 'Confirm Email',
    roles: ['!'],
  },
  sendLink: {
    path: '/send-link',
    Page: SendLink,
    Layout: Registration,
    title: 'Send Sign-in Link',
    roles: ['!'],
  },
  channel: {
    path: '/messages/:channelID',
    Page: Messages,
    Layout: Backend,
    title: 'Channel',
    roles: ['@'],
  },
  promos: {
    path: '/promos',
    Page: Promos,
    Layout: Backend,
    title: 'Promotions',
    roles: ['admin', 'design_manager', 'customer_support', 'website_manager'],
    nav: true,
    order: NAVBAR_ORDER.indexOf('promos'),
    Icon: PromosIcon,
    IconActive: PromosIconSolid,
  },
  promo: {
    path: '/promos/:code',
    Page: Promo,
    Layout: Backend,
    title: 'Promotion',
    roles: ['admin', 'design_manager', 'customer_support', 'website_manager'],
  },
  billing: {
    path: '/billing',
    Page: Billing,
    Layout: Backend,
    title: 'Billing',
    roles: ['@']
  },
  pricing: {
    path: '/pricing',
    Page: Pricing,
    Layout: Backend,
    title: 'Pricing',
    roles: ['pricing'],
    nav: true,
    order: NAVBAR_ORDER.indexOf('pricing'),
    Icon: IconPricing,
    IconActive: IconPricing,
  },
  default: {
    path: '*',
    Page: _404,
    Layout: FullCenter,
    title: 'Not Found',
  },
};

// sendLoginLink with emulators auto-sends /emulator/action rather than /
if (CONFIG.auth?.url) {
  ROUTES.emulatorAction = { ...ROUTES.login, path: '/emulator/action' };
}

export function isLoginLandingPage(path: string): boolean {
  return CONFIG.auth?.url
    ? path === '/' || path === '/emulator/action'
    : path === '/';
}

export default ROUTES;
