export default function properCase(str : string, separator  = ' ', and = null) : string {
  if (!str) {
    return '';
  }
  
  try {
    if (and) {
      const regex = new RegExp(`${separator}and${separator}`, 'g');

      return String(str).replace(regex, `${separator}&${separator}`).split(separator)
        .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ');
    } else {
      return String(str).split(separator)
        .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ');
    }
  } catch (e) {
    return str;
  }
}
