import TextFieldPrice from '@components/textfield-price/TextFieldPrice';
import UserSearch from '@components/user-search/UserSearch';
import UserContext from '@context/UserContext';
import { CATEGORIES, COLORS, STYLES } from '@data';
import useMuiMenuHooks from '@hooks/useMuiMenuHooks';
import Sentry from '@integrations/Sentry';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import ProductEditSchema, { initialValues as _initialValues } from '@schema/ProductEditSchema';
import ProductSchemaMaker from '@schema/ProductSchemaMaker';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import Protected from '@ui/components/protected/Protected';
import theme from '@ui/theme';
import { isError } from '@ui/utils/typescriptHelpers';
import debouncePromise from '@utils/debouncePromise';
import { checkSlug } from '@utils/scannerNextChar';
import convert from 'convert';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useContext, useEffect, useState } from 'react';
import slugify from 'slugify';

const debounceCheckSlug = debouncePromise(checkSlug, 300);

const convertDimensionalUnitToAbbreviation = (type: string): 'ft' | 'in' | 'cm' | 'm' => {
  switch (type) {
    case 'feet':
      return 'ft';
    case 'centimeters':
      return 'cm';
    case 'meters':
      return 'm';
    default:
      return 'in';
  }
};

const ProductEditForm = ({ onSubmit, initialValues = _initialValues, error, submitText = 'Continue' }) => {
  const userContext = useContext<UserContext>(UserContext);
  const [slug, setSlug] = useState('');
  const [selectedDimensionalUnits, setSelectedDimensionalUnits] = useState<'in' | 'ft' | 'cm' | 'm'>(convertDimensionalUnitToAbbreviation(initialValues.dimensional_units));

  const {
    anchorEl: dimensionsAnchorEl,
    open: dimensionsOpen,
    handleClick: dimensionsHandleClick,
    handleClose: dimensionsHandleClose,
  } = useMuiMenuHooks();

  return (
    <Formik
      validationSchema={userContext?.roles?.maker ? ProductSchemaMaker : ProductEditSchema}
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={onSubmit}
    >
      {({
        errors,
        values,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (values.title) {
            const slug = slugify(values.title, {
              strict: true,
              lower: true,
            });

            checkSlug(slug, 'products')
              .then(existingSlugs => {

                if (initialValues.slug?.length && initialValues.slug === slug) {
                  setFieldValue('slug', slug);
                  return;
                }

                if (existingSlugs?.length) {
                  let n = 1;
                  let incrememtedSlug = slug;

                  while (existingSlugs.indexOf(incrememtedSlug) > -1) {
                    incrememtedSlug = `${slug}-${n}`;
                    n += 1;
                  }
                  setFieldValue('slug', incrememtedSlug);
                  setSlug(incrememtedSlug);
                }
              });
          }
        }, []);

        return (
          <form onSubmit={handleSubmit}>
            <Field
              autoFocus
              fullWidth
              data-test="title"
              name="title"
              label="Title"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
              onChange={async (e) => {
                setFieldValue('title', e.target.value);
                setFieldTouched('title', true);

                const slug = slugify(e.target.value, {
                  strict: true,
                  lower: true,
                });

                if (initialValues.slug?.length && initialValues.slug === slug) {
                  setFieldValue('slug', slug);
                  return;
                }

                let existingSlugs = [];

                try {
                  existingSlugs = await debounceCheckSlug(slug, 'products');
                } catch (e) {
                  Sentry.captureException(e);
                  return;
                }

                if (existingSlugs.length) {
                  let n = 1;
                  let incrememtedSlug = slug;

                  while (existingSlugs.indexOf(incrememtedSlug) > -1) {
                    incrememtedSlug = `${slug}-${n}`;
                    n += 1;
                  }

                  setFieldValue('slug', incrememtedSlug);
                } else {
                  setFieldValue('slug', slug);
                }
              }}
            />

            <Field
              fullWidth
              disabled
              data-test="slug"
              name="slug"
              label="URL"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
              value={slug || values.slug}
              InputProps={{
                startAdornment: (
                  <span>
                    {'https://www.guesthouseshop.com/shop/products/'}
                    &lrm;
                  </span>
                ),
              }}
            />

            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              <Field
                fullWidth
                name="vendor_url"
                label="Vendor URL"
                type="text"
                component={TextField}
                margin="normal"
                variant="outlined"
              />
            </Protected>

            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              <UserSearch
                defaultValue={values.owner}
                useCompany={true}
                filterRoles={['maker']}
                label="Maker"
                style={{
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(1),
                }}
                onChange={(_, value) => {
                  setFieldValue('owner', value);
                  setFieldTouched('owner');
                }}
              />
            </Protected>
            <Field
              fullWidth
              multiline
              data-test="description"
              name="description"
              label="Description"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
              rows={3}
            />

            <Field
              fullWidth
              data-test="msrp"
              name="msrp"
              label="MSRP"
              type="text"
              component={TextFieldPrice}
              margin="normal"
              variant="outlined"
              customInput={TextField}
              defaultValue={values.msrp}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                )
              }}
              onValueChange={(values) => {
                const { value } = values;

                setFieldValue('msrp', Number(value));
              }}
            />

            <Field
              fullWidth
              multiline
              data-test="manufacturer_sku"
              name="manufacturer_sku"
              label="SKU"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
            />

            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.dibs}
                    name="dibs"
                    color="primary"
                    onChange={(_, value) => {
                      setFieldValue('dibs', value);
                      setFieldTouched('dibs');
                    }}
                  />
                }
                label="Dibs"
              />
            </Protected>

            <Field
              fullWidth
              multiline
              data-test="shippingPolicy"
              name="shippingPolicy"
              label="Shipping Policy"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
            />

            <Field
              fullWidth
              multiline
              data-test="returnPolicy"
              name="returnPolicy"
              label="Return Policy"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
            />

            <Field
              fullWidth
              data-test="dimensions_visible"
              name="dimensions_visible"
              label="Dimensional Units (Visible)"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
            />

            <Box
              display="flex"
              justifyContent="space-between"
            >
              <Field
                fullWidth
                data-test="width"
                name="width"
                label="Width"
                type="number"
                component={TextField}
                margin="normal"
                variant="outlined"
                value={values.width ? Number(convert(Number(values.width), 'in').to(selectedDimensionalUnits).toFixed(2)) : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {selectedDimensionalUnits}
                    </InputAdornment>
                  )
                }}
                style={{ marginRight: theme.spacing(1) }}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  const inches = convert(value, selectedDimensionalUnits).to('in').toFixed(2);

                  setFieldTouched('width', true);
                  setFieldValue('width', inches);
                }}
              />
              <Field
                fullWidth
                data-test="length"
                name="length"
                label="Length"
                type="number"
                component={TextField}
                margin="normal"
                variant="outlined"
                value={values.length ? Number(convert(Number(values.length), 'in').to(selectedDimensionalUnits).toFixed(2)) : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {selectedDimensionalUnits}
                    </InputAdornment>
                  )
                }}
                style={{ marginRight: theme.spacing(1) }}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  const inches = convert(value, selectedDimensionalUnits).to('in').toFixed(2);

                  setFieldTouched('length', true);
                  setFieldValue('length', inches);
                }}
              />
              <Field
                fullWidth
                data-test="height"
                name="height"
                label="Height"
                type="number"
                component={TextField}
                margin="normal"
                variant="outlined"
                value={values.height ? Number(convert(Number(values.height), 'in').to(selectedDimensionalUnits).toFixed(2)) : ''}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        {selectedDimensionalUnits}
                      </InputAdornment>
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="change dimensional units"
                          edge="end"
                          onClick={dimensionsHandleClick}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </InputAdornment>
                    </>
                  )
                }}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  const inches = convert(value, selectedDimensionalUnits).to('in').toFixed(2);

                  setFieldTouched('height', true);
                  setFieldValue('height', inches);
                }}
              />
            </Box>

            <Field
              fullWidth
              data-test="weight"
              name="weight"
              label="Weight"
              type="number"
              component={TextField}
              margin="normal"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    lb
                  </InputAdornment>
                )
              }}
            />

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.category && Boolean(errors.category)}
            >
              <InputLabel id="category-label">
                Category
              </InputLabel>
              <Select
                data-test="category"
                labelId="category-label"
                id="category"
                value={values.category || []}
                label="Category"
                onChange={(e) => {
                  const categoryArray = [];

                  if (e.target.value) {
                    categoryArray.push(e.target.value);
                  }
                  setFieldTouched('category', true);
                  setFieldValue('category', categoryArray);
                  setFieldValue('category2', undefined);
                  setFieldValue('category3', undefined);
                }}
              >
                <MenuItem value="">
                  <em>
                    &nbsp;
                  </em>
                </MenuItem>
                {CATEGORIES.map(category => (
                  <MenuItem
                    key={category.id}
                    value={category.id}
                  >
                    {category.title}
                  </MenuItem>
                ))}
              </Select>
              {touched.category && Boolean(errors.category) && (
                <FormHelperText error>
                  {errors.category}
                </FormHelperText>
              )}
            </FormControl>

            {CATEGORIES.map(category => {
              if (values.category.includes(category.id) && category.subcategories) {
                return (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={touched.category2 && Boolean(errors.category2)}
                  >
                    <InputLabel id="category-label">
                      Category 2
                    </InputLabel>
                    <Select
                      data-test="category2"
                      labelId="category-label"
                      id="category2"
                      value={values.category2 || []}
                      label="Category 2"
                      onChange={(e) => {
                        const category2Array = [];

                        if (e.target.value) {
                          category2Array.push(e.target.value);
                        }
                        setFieldTouched('category2', true);
                        setFieldValue('category2', category2Array);
                        setFieldValue('category3', undefined);
                      }}
                    >
                      <MenuItem value="">
                        <em>
                          &nbsp;
                        </em>
                      </MenuItem>
                      {category.subcategories.map(category2 => {
                        return (
                          <MenuItem
                            key={category2.id}
                            value={category2.id}
                          >
                            {category2.title}
                          </MenuItem>
                        );
                      })}

                    </Select>
                    {touched.category2 && Boolean(errors.category2) && (
                      <FormHelperText error>
                        {errors.category2}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }
            })}

            {CATEGORIES.map(category => {
              return values.category2 && category.subcategories && category.subcategories.map(subcategory => {
                if (subcategory.subcategories && values.category2.includes(subcategory.id)) {
                  return (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      error={touched.category3 && Boolean(errors.category3)}
                    >
                      <InputLabel id="category-label">
                        Category 3
                      </InputLabel>
                      <Select
                        data-test="category3"
                        labelId="category-label"
                        id="category3"
                        value={values.category3 || []}
                        label="Category 3"
                        onChange={(e) => {
                          const category3Array = [];

                          if (e.target.value) {
                            category3Array.push(e.target.value);
                          }
                          setFieldTouched('category3', true);
                          setFieldValue('category3', category3Array);
                        }}
                      >
                        <MenuItem value="">
                          <em>
                            &nbsp;
                          </em>
                        </MenuItem>
                        {subcategory.subcategories.map(category3 => {
                          return (
                            <MenuItem
                              key={category3.id}
                              value={category3.id}
                            >
                              {category3.title}
                            </MenuItem>
                          );
                        })}

                      </Select>
                      {touched.category3 && Boolean(errors.category3) && (
                        <FormHelperText error>
                          {errors.category3}
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                }
              });
            })}

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.style && Boolean(errors.style)}
            >
              <InputLabel id="style-label">
                Styles
              </InputLabel>
              <Select
                multiple
                data-test="style"
                labelId="style-label"
                id="style"
                value={values.style || []}
                label="Styles"
                renderValue={selected => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      {(selected as string[]).map(value => (
                        <Chip
                          key={value}
                          label={value}
                          style={{ margin: 2 }}
                        />
                      ))}
                    </div>
                  );
                }}
                onChange={(e) => {
                  setFieldTouched('style', true);
                  setFieldValue('style', e.target.value);
                }}
              >
                {STYLES.map(style => (
                  <MenuItem
                    key={style.id}
                    value={style.id}
                  >
                    {style.title}
                  </MenuItem>
                ))}
              </Select>
              {touched.style && Boolean(errors.style) && (
                <FormHelperText error>
                  {errors.style}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.color && Boolean(errors.color)}
            >
              <InputLabel id="color-label">
                Colors
              </InputLabel>
              <Select
                multiple
                data-test="color"
                labelId="color-label"
                id="color"
                value={values.color || []}
                label="Colors"
                renderValue={selected => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      {(selected as string[]).map(value => (
                        <Chip
                          key={value}
                          label={value}
                          style={{ margin: 2 }}
                        />
                      ))}
                    </div>
                  );
                }}
                onChange={(e) => {
                  setFieldTouched('color', true);
                  setFieldValue('color', e.target.value);
                }}
              >
                {COLORS.map(color => (
                  <MenuItem
                    key={color.id}
                    value={color.id}
                  >
                    {color.title}
                  </MenuItem>
                ))}
              </Select>
              {touched.color && Boolean(errors.color) && (
                <FormHelperText error>
                  {errors.color}
                </FormHelperText>
              )}
            </FormControl>
            {error && (
              <>
                <FormHelperText error>
                  {isError(error) ? error.message : error}
                </FormHelperText>
                <FormHelperText>
                  Need help? Contact
                  {' '}
                  <Link href="mailto:support@guesthouseshop.com">
                    support@guesthouseshop.com
                  </Link>
                </FormHelperText>
              </>
            )}
            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                data-test="product-form-submit"
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length)}
                aria-label={submitText}
                tooltip={makeErrors(errors, touched)?.length && (
                  <TooltipErrors
                    errors={errors}
                    touched={touched}
                  />
                )}
              >
                {submitText}
              </SubmitButton>
            </div>

            <Menu
              anchorEl={dimensionsAnchorEl}
              open={dimensionsOpen}
              onClose={dimensionsHandleClose}
              onClick={dimensionsHandleClose}
            >
              <MenuItem
                onClick={() => {
                  setFieldValue('dimensional_units', 'inches');
                  setSelectedDimensionalUnits('in');
                }}
              >
                Inches
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFieldValue('dimensional_units', 'feet');
                  setSelectedDimensionalUnits('ft');
                }}
              >
                Feet
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFieldValue('dimensional_units', 'centimeters');
                  setSelectedDimensionalUnits('cm');
                }}
              >
                Centimeters
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFieldValue('dimensional_units', 'meters');
                  setSelectedDimensionalUnits('m');
                }}
              >
                Meters
              </MenuItem>
            </Menu>
          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(ProductEditForm);
