import asyncRender from '@components/async/asyncRender';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import { WarehouseDialog } from '@components/dialogs/WarehouseDialog';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import Sentry from '@integrations/Sentry';
import IconMoreHoriz from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { deleteDoc,QueryDocumentSnapshot, setDoc } from 'firebase/firestore';
import React, { useContext, useState } from 'react';


type WarehouseActionsProps = {
  warehouse: QueryDocumentSnapshot<guesthouse.Warehouse>
}

const WarehouseActions: React.FC<WarehouseActionsProps> = (props) => {
  const { warehouse } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const userContext = useContext<UserContext>(UserContext);
  const warehouseData = warehouse.data();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        edge="end"
        aria-label="actions"
        aria-haspopup="true"
        size="large"
        onClick={handleClick}
      >
        <IconMoreHoriz />
      </IconButton>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            asyncRender(WarehouseDialog, {
              title: 'Edit Warehouse',
              userContext,
              initialValues: warehouseData,
            })
              .then((result) => setDoc(warehouse.ref, result))
              .then(() => notificationContext.setContext({ open: true, message: 'Succesfully updated warehouse' }))
              .catch((e) => {
                if (e) {
                  Sentry.captureException(e);
                  notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                }
              });

            handleClose();
          }}
        >
          Edit
        </MenuItem>


        <MenuItem
          onClick={() => {
            asyncRender(ConfirmDialog, {
              title: 'Are you sure you want to delete this warehouse?',
              confirmText: 'Yes, Delete',
            })
              .then(() => deleteDoc(warehouse.ref))
              .then(() => notificationContext.setContext({ open: true, message: 'Succesfully deleted warehouse' }))
              .catch((e) => {
                if (e) {
                  Sentry.captureException(e);
                  notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                }
              });

            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

export default WarehouseActions;
