import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  avatarGridItem: {
    marginBottom: 75,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 100,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 150,
    }
  },
  avatarBox: {
    width: 200,
    minHeight: 200,
    top: 225,
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      top: 200
    }
  },
  avatarProgress: {
    position: 'absolute',
    zIndex: 10,
    left: -3,
    top: -3
  },
  coverBox: {
    position: 'relative'
  },
  coverImg: {
    '&:hover': {
      cursor: 'move',
    }
  },
  coverProgress: {
    width: '100%',
    position: 'absolute',
    zIndex: 10,
    left: 0,
    top: 0,
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
    height: theme.shape.borderRadius,
  },
  actionButton: {
    background: 'rgba(0, 0, 0, .5) !important',
  },
  actionIcon: {
    fill: 'rgba(255, 255, 255, .7) !important',
  }
}));
