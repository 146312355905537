import { database } from '@app/firebase';
import useAlgoliaSearch from '@hooks/useAlgoliaSearch';
import algolia from '@integrations/Algolia';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useStyles } from '@ui/utils/makeStyles';
import userFullName from '@ui/utils/userFullName';
import makeIndexName from '@utils/makeIndexName';
import { collection, doc, getDoc } from 'firebase/firestore';
import Sentry from 'integrations/Sentry';
import React, { useEffect, useState } from 'react';

type UserSearchProps = {
  onChange: (event: React.ChangeEvent<{}>, user: guesthouse.User | Partial<guesthouse.User>[]) => void;
  defaultValue?: Partial<guesthouse.User> | Partial<guesthouse.User>[];
  filterRoles?: string[];
  label?: string;
  style?: React.CSSProperties;
  useCompany?: boolean;
  showAvatar?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  TextFieldInputStyle?: React.CSSProperties;
  multiple?: boolean;
  autoFocus?: boolean;
  freeSolo?: boolean;
}

const defaultProps = {
  label: 'Find User',
  useCompany: false,
  variant: 'outlined' as UserSearchProps['variant'],
  autoFocus: false,
};

const UserSearch: React.FC<UserSearchProps> = (props: UserSearchProps) => {
  const { theme } = useStyles();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState<string>();

  const usersIndex = algolia.initIndex(makeIndexName('users'));

  const { results: allUsers, loading } = useAlgoliaSearch<guesthouse.User>({
    index: usersIndex,
    filters: props.filterRoles?.map(filter => `roles.${filter}:true`).filter(Boolean).join(' OR '),
    numericFilters: '',
    facetFilters: '',
    query: searchQuery,
    perPage: 50,
  });

  const { showAvatar, multiple } = props;

  useEffect(() => {
    algolia.clearCache();
  }, []);

  useEffect(() => {
    setOptions(allUsers?.hits || []);
  }, [allUsers?.hits]);


  return (
    <Autocomplete
      open={open}
      multiple={multiple}
      style={props.style}
      freeSolo={props.freeSolo}
      defaultValue={props.defaultValue}
      filterOptions={(x) => x}
      getOptionLabel={(option) => {
        const optionLabel = (option) => {
          if (props.useCompany && option.company) {
            return option.company;
          } else if (option.firstname && option.lastname) {
            return `${option.firstname} ${option.lastname}`;
          } else if (option.email) {
            return option.email;
          } else {
            return option;
          }
        };

        return optionLabel(option);
      }}
      renderOption={(props, option) => {
        return (
          <li
            {...props}
            key={option.docID}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              {showAvatar &&
                <Avatar
                  src={option.photoURL}
                  style={{ height: 24, width: 24, marginRight: theme.spacing(1.5) }}
                />
              }
              <Box>
                <Typography
                  fontFamily={theme.gh_vars.circular}
                  fontWeight={500}
                >
                  {userFullName(option)}
                </Typography>
                {option.company && (
                  <Typography
                    fontFamily={theme.gh_vars.circular}
                    color="textSecondary"
                    style={{ fontSize: 12 }}
                  >
                    {option.company}
                  </Typography>
                )}
              </Box>
            </Box>
          </li>
        );
      }}
      options={options}
      loading={loading}
      renderInput={params => (
        // @ts-ignore
        <TextField
          autoFocus={props.autoFocus}
          {...params}
          fullWidth
          label={props.label}
          variant={props.variant}
          InputProps={{
            style: props.TextFieldInputStyle,
            disableUnderline: true,
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress
                  color="inherit"
                  size={20}
                /> : null}

                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      )}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(e, value) => {
        try {
          if (Array.isArray(value) && !props.freeSolo) {
            return props.onChange(e, value);
          }
          if (value?.docID) {
            return getDoc(doc(collection(database, 'users'), value.docID))
              .then(d => d.data() as guesthouse.User)
              .then(user => {
                if (user.docID) {
                  props.onChange(e, user);
                }
              });
          } else {
            const formatValue = value.map((value) => {
              return value;
            });

            props.onChange(e, formatValue);
          }
        } catch (e) {
          Sentry.captureException(e);
          return value;
        }
      }}
    />
  );
};

UserSearch.defaultProps = defaultProps;

export default UserSearch;
