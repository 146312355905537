import React from 'react';

const SvgIconCalendar = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0V12.5M2 0V2.5M2 16V5M4 5H2M0 5H2M9 15H5M14 13H12M10 13H12M12 0V10.5M12 16V13"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);

export default SvgIconCalendar;
