import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()({
  callout: {
    marginBottom: 60,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  h3: {
    fontWeight: 600,
  },
});
