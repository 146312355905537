import Sentry from '@integrations/Sentry';
import FormHelperText from '@mui/material/FormHelperText';
import EmailSchema, { initialValues } from '@schema/EmailSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { Field, FieldValidator, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react';

interface EmailFormProps {
  onSubmit?: (email?: string) => void;
  validate?: FieldValidator;
}

const EmailForm : React.FC<EmailFormProps> = (props) => {
  const { onSubmit, validate } = props;
  const [error, setError] = useState(false);

  return (
    <Formik
      validationSchema={EmailSchema}
      initialValues={initialValues}
      onSubmit={async (values: EmailForm) => {
        setError(false);
        const { email } = values;

        try {
          if (typeof onSubmit === 'function') {
            await onSubmit(email);
          }
        } catch (e) {
          Sentry.captureException(e);
          setError(e.message);
        }
      }}
    >
      {({
        errors,
        handleSubmit,
        isSubmitting
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field
              fullWidth
              name="email"
              label="Email"
              type="email"
              component={TextField}
              margin="dense"
              variant="outlined"
              data-test="email-field"
              validate={validate}
            />

            {error && (
              <FormHelperText error>
                {error}
              </FormHelperText>
            )}

            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length)}
                aria-label="Continue"
                data-test="email-continue"
              >
                Continue
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default EmailForm;
