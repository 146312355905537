import React, { CSSProperties } from 'react';

import IconExpandArrows from '../../icons/imaterial/arrows/Move.svg';
import IconBath from '../../icons/imaterial/home/Bathtub.svg';
import IconBed from '../../icons/imaterial/home/Bed.svg';
import useStyles from './HomeIcons.style';

interface HomeIconsProps {
  home: Partial<guesthouse.Home>;
  style?: CSSProperties;
  className?: string;
}

const HomeIcons: React.FC<HomeIconsProps> = (props) => {
  const { home, style, className } = props;
  const { classes, cx } = useStyles();
  const styleObj: CSSProperties = { ...style };

  const icons = [
    home.beds && {
      icon: IconBed,
      text: `${home.beds} beds`
    },
    home.sqft && {
      icon: IconExpandArrows,
      text: `${home.sqft} Sq Ft`
    },
    home.baths && {
      icon: IconBath,
      text: `${home.baths} baths`
    }
  ].filter(i => i);

  return (
    <div
      className={cx(classes.iconGroup, className)}
      style={styleObj}
    >
      {icons.map((icon) => {
        return (
          <div
            key={icon.text}
            className={classes.iconWrapper}
          >
            <icon.icon className={classes.iconSvg} />

            <span className={classes.iconText}>
              {icon.text}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default HomeIcons;
