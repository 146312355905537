import Sentry from '@integrations/Sentry';
import { onSnapshot, Query, QuerySnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';

interface UseCollectionReturn<T> {
  error: Error | boolean
  loading: boolean
  collection: QuerySnapshot<T> | undefined
}

function useCollection<T = Object>(query: Query<T>, forceChange: any = 0): UseCollectionReturn<T> {
  const [error, setError] = useState<Error | boolean>(false);
  const [loading, setLoading] = useState(true);
  const [snapshot, setSnapshot] = useState<QuerySnapshot<T>>();

  useEffect(
    () => {
      if (query) {
        setLoading(true);
        return onSnapshot(query,
          snap => {
            setLoading(false);
            setSnapshot(snap);
          },
          err => {
            if (err.message.indexOf('insufficient permissions') > -1) {
              console.error('Insufficient permissions to access collection', query);
            }
            Sentry.captureException(err, {
              extra: {
                query,
              }
            }); 
            setError(err);
          }
        );
      } else {
        setSnapshot(undefined);
      }
    }, [forceChange]
  );

  return {
    error,
    loading,
    collection: snapshot,
  };
}

export default useCollection;
