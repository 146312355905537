import makeStyles from '@ui/utils/makeStyles';
import rgba from 'polished/lib/color/rgba';

export default makeStyles()((theme) => ({
  wrapper: {
    position: 'relative',
    margin: 25,
    [theme.breakpoints.up('md')]: {
      margin: 50,
    }
  },
  logo: {
    width: 'auto',
    height: 40,
    margin: '0 auto',
    display: 'block',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      height: 27,
      display: 'inline-block',
    }
  },
  paper: {
    backgroundColor: rgba(theme.palette.common.white, .95),
    padding: theme.spacing(5, 2.5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5),
    }
  },
  top: {
    [theme.breakpoints.up('lg')]: {
      marginBottom: '160px !important',
    }
  },
  register: {
    textDecoration: 'none',
  },
  h3: {
    fontWeight: 600,
  },
  gridForm: {
    minHeight: 544,
  },
  formTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}));
