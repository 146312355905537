export default function productMetaData(product: guesthouse.Product): guesthouse.MetaData {
  const metaData: guesthouse.MetaData = {
    h1: product?.metadata?.h1 || product.title,
    pageTitle: product?.metadata?.pageTitle || `${product?.title} | Guest House`,
    metaTitle: product?.metadata?.metaTitle || `${product?.title} | Guest House`,
    metaDescription: product?.metadata?.metaDescription || `Find home accents like our ${product.title} and hundreds of other curated home décor and furniture items at Guest House.`,
    image: product?.primaryPhoto?.medium_url || 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Fguesthouse-social-default.jpg?alt=media',
  };

  return metaData;
}
