import UserContext from '@context/UserContext';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import routes from '@routes';
import IconMessageText from '@ui/icons/imaterial/communication/Message Text Filled.svg';
import { channelPath } from '@utils/paths';
import React, { useEffect } from 'react';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useChatContext } from 'stream-chat-react';

import useRouter from '../../hooks/useRouter';
import useStyles from './MessagesButton.style';

const MessagesButton: React.FC = () => {
  const location = useLocation();
  const router = useRouter();
  const userContext = useContext<UserContext>(UserContext);
  const { classes } = useStyles();
  const { client, channel } = useChatContext();

  const [href, setHref] = useState<string | null>(null);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);

  const handleClick = () => {
    if (location.pathname.includes('/messages')) {
      if (href) {
        if (href.includes('/messages')) {
          router.push(routes.feed.path);
        } else {
          router.push(href);
        }
        setHref(null);
      } else {
        if (location.state) {
          router.goBack();
        } else {
          router.push(routes.feed.path);
        }
      }
    } else {
      setHref(location.pathname);
      if (channel) {
        router.push(channelPath(channel));
      } else {
        router.push(routes.messages.path);
      }
    }
  };

  useEffect(() => {
    if (client) {
      const listener = client.on((e) => {
        if (e.total_unread_count !== undefined) {
          setUnreadMessages(e.total_unread_count);
        }
      });

      return listener.unsubscribe;
    }
  }, [client]);

  useEffect(() => {
    // @ts-ignore
    if (userContext.streamUser?.me?.unread_count >= 0) {
      // @ts-ignore
      setUnreadMessages(userContext.streamUser.me.unread_count);
    }
  }, [userContext.streamUser]);

  return (
    <Tooltip
      title="Open messages"
    >
      <IconButton
        size="medium"
        color="primary"
        className={classes.messagesButton}
        aria-label="Open messages"
        onClick={handleClick}
      >
        <Badge
          badgeContent={unreadMessages.toString()}
          invisible={!unreadMessages}
          classes={{
            badge: classes.badge,
          }}
        >
          <IconMessageText style={{ fill: '#080808' }} />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default MessagesButton;
