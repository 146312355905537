import PriceSheet from '@components/price-sheet/PriceSheet';
import useRouter from '@hooks/useRouter';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { GlobalStyles } from 'tss-react/GlobalStyles';

interface PriceSheetPageParams {
  id: string;
}

const PriceSheetPage = () => {
  const router = useRouter<PriceSheetPageParams>();
  const [container] = useState(document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
  }, []);

  const { id } = router.params;

  return ReactDOM.createPortal(
    <div>
      <GlobalStyles
        styles={{
          '#price-sheet-print-button': {
            display: 'none',
          },
          '#profile-menu': {
            display: 'none',
          }
        }}
      />
      <PriceSheet
        homeId={id}
        lazyLoadImages={false}
      />
    </div>,
    container
  );
};

export default React.memo(PriceSheetPage);
