import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  smCardWrapper: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1.5),
  },
  imageAvatarWrapper: {
    width: 100,
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      width: 200,
    }
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    minWidth: 100,
    height: 75,
    '& img': {
      borderRadius: 5,
    },
    [theme.breakpoints.up('md')]: {
      height: '100%',
      width: 84,
      minWidth: 64,
    },
  },
  avatarWrapper: {
    marginTop: theme.spacing(1.5),
    display: 'inline-flex',
    justifyContent: 'flex-start'
  },
  avatar: {
    height: 24,
    marginRight: theme.spacing(.5),
    [theme.breakpoints.up('md')]: {
      height: 32,
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
      marginRight: theme.spacing(1)
    },
  },
  smAddressActionStatusWrapper: {
    width: '100%',
    marginBottom: theme.spacing(.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  shortAddress: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: theme.spacing(.5)
  },
  cityState: {
    width: '100%',
    height: 17,
    fontSize: 14,
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  addresses: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  installDate: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600,
    color: '#171E1F',
    marginRight: theme.spacing(.5)
  },
  lgAddressesWrapper: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    alignItems: 'start',
    justifyContent: 'start',
    height: '70%',
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(.5)
  },
  verticalCenter: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    textAlign: 'left',
    fontSize: 12,
    fontWeight: 600,
    color: '#171E1F',
  },
  lgCardContainer: {
    backgroundColor: '#FFFFFF',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    height: 80
  }
}));
