import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';


type ConfirmDialogArgs = {
  title?: JSX.Element | string;
  content?: JSX.Element | string;
  cancelText?: string;
  confirmText?: string;
  resolve: (value: boolean) => void;
  reject: (e?: Error | boolean) => void;
}

const ConfirmDialog: React.FC<ConfirmDialogArgs> = ({
  resolve,
  reject,
  title = '',
  content = '',
  cancelText = 'Cancel',
  confirmText = 'Confirm',
}) => {
  const [open, setOpen] = useState(true);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        {title}
      </DialogTitle>

      {content && (
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      )}

      <DialogActions>
        <Button
          autoFocus
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            setOpen(false);
            reject(false);
          }}
        >
          {cancelText}
        </Button>

        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => {
            setOpen(false);
            resolve(true);
          }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
