import { database } from '@app/firebase';
import { RouterLink } from '@components/link-behavior/LinkBehavior';
import { LinkBehavior } from '@components/link-behavior/LinkBehavior';
import useCollection from '@hooks/useCollection';
import IconPlus from '@mui/icons-material/Add';
import IconArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import routes from '@routes';
import Protected from '@ui/components/protected/Protected';
import { roomPath } from '@utils/paths';
import { collection as firestoreCollection, orderBy, query } from 'firebase/firestore';
import React from 'react';
import { generatePath } from 'react-router';

import Props, { defaultProps, renderActionProps } from './RoomGrid.props';
import PhotoGridSkeleton from './RoomGrid.skeleton';
import useStyles from './RoomGrid.style';

const DefaultViewButton = ({ room, home }: renderActionProps) => {
  return (
    <Link
      component={RouterLink}
      to={roomPath(home, room)}
    >
      <Button
        size="small"
        variant="text"
        color="secondary"
        endIcon={<IconArrowRightAlt />}
      >
        View
      </Button>
    </Link>
  );
};

const RoomGrid: React.FC<Props> = (props) => {
  const { classes, theme } = useStyles();

  const { collection: rooms, loading, error } = useCollection(
    query(firestoreCollection(database
      ,`homes/${props.home.docID}/rooms`)
    ,orderBy('created', 'desc'))
  );

  if (loading && !error) {
    return (<PhotoGridSkeleton {...props} />);
  }

  return (
    <Grid
      container
      spacing={4}
      {...props.gridProps}
    >
      {props.allowAdd && (
        <Protected allowedRoles={['designer', 'admin', 'design_manager', 'website_manager', 'customer_support']}>
          <Grid
            key={'new-room'}
            item
            className={classes.roomGridItem}
            xs={12}
            md={6}
            lg={6}
            xl={4}
            {...props.gridItemProps}
          >

            <Paper
              className={classes.roomPaper}
              style={props.cardStyle}
            >
              <Button
                size="small"
                variant="contained"
                color="secondary"
                component={LinkBehavior}
                href={generatePath(routes.roomCreate.path, {
                  homeId: props.home.docID,
                })}
                startIcon={<IconPlus />}
                aria-label="add room"
                data-test="room-grid-add-room"
              >
                Add room
              </Button>
            </Paper>
          </Grid>
        </Protected>
      )}

      {rooms && rooms.docs
        .map((room, index) => {
          const roomData = room.data() as guesthouse.Room;

          return (
            <Grid
              key={roomData.docID}
              item
              className={classes.roomGridItem}
              xs={12}
              md={6}
              lg={6}
              xl={4}
              {...props.gridItemProps}
              data-test={`room-grid-${roomData.docID}`}
            >
              <Paper
                className={classes.roomPaper}
                style={props.cardStyle}
              >
                <div className={classes.roomContent}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      component="h1"
                      variant="h5Alt"
                      style={{
                        // whiteSpace: 'nowrap',
                        // overflow: 'hidden',
                        // textOverflow: 'ellipsis',
                        alignSelf: 'flex-start'
                      }}
                    >
                      {roomData.title}
                    </Typography>

                    <Box
                      display="flex"
                      flexDirection="column"
                      width={120}
                      marginLeft={2}
                    >
                      <div
                        className={classes.taggedPhotoChipWrapper}
                        style={{
                          backgroundColor: roomData.primaryPhoto ? '#E3F1D1' : '#E0BFB0',
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                          className={classes.taggedPhotoChipText}
                        >
                          {roomData.primaryPhoto ? 'Primary Photo' : 'No Primary Photo'}
                        </Box>
                      </div>

                      <div
                        className={classes.publishedChipWrapper}
                        style={{
                          backgroundColor: roomData.published ? '#E3F1D1' : 'lightgray',
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                          className={classes.publishedChipText}
                        >
                          {roomData.published ? 'Published' : 'Hidden'}
                        </Box>
                      </div>
                    </Box>
                  </Box>

                  {roomData.sqft && (
                    <Typography
                      style={{
                        marginTop: theme.spacing(2),
                      }}
                    >
                      {roomData.sqft}
                      {' '}
                      SQFT
                    </Typography>
                  )}

                  <div className={classes.description}>
                    {roomData.description}
                  </div>

                  <div className={classes.action}>
                    {props.renderAction({ room: roomData, home: roomData.home, index })}
                  </div>
                </div>
              </Paper>
            </Grid>
          );
        })}
    </Grid>
  );
};

RoomGrid.defaultProps = defaultProps;
RoomGrid.defaultProps.renderAction = ({ room, home }) => (
  <DefaultViewButton
    room={room}
    home={home}
  />
);

export default RoomGrid;
