import Typography from '@mui/material/Typography';
import React from 'react';
import { EventComponentProps } from 'stream-chat-react';

import useStyles from './CustomSystemMessges.style';

const CustomSystemMessage: React.FC<EventComponentProps> = (props) => {
  const { message } = props;
  const { text, type } = message;

  const { classes } = useStyles();

  if (type === 'system') {
    return (
      <Typography
        className={classes.customSystemMessage}
      >
        {text}
      </Typography>
    );
  }

  return null;

};

export default CustomSystemMessage;
