import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  makerMessage: {
    padding: theme.spacing(5),
    background: '#B2B9B0 !important',
    marginBottom: theme.spacing(5),
  },
  makerMessageText: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: `${theme.spacing(2)} !important`,
    }
  },
  userPaper: {
    position: 'relative',
    overflow: 'hidden',
    height: 300,
    cursor: 'pointer',
    background: '#F7F7F7 !important',
  },
  userImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }
}));
