import 'stream-chat-react/dist/css/index.css';

import UserContext from '@context/UserContext';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Counter from '@ui/components/counter/Counter';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import { ERROR_ALT, GREEN_LIGHT, ORANGE_ALT, YELLOW } from '@ui/theme/theme';
import { calculateChatSLAs, CalculateChatSLAsOutput } from '@utils/calculateChatSLAs';
import { getUnreadChannelsAdmin } from '@utils/getUnreadChannelsAdmin';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Channel } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';

import useStyles from './AdminViewStats.style';
import CountsTile from './CountsTile';


const AdminViewStats: React.FC = () => {
  const { theme, classes } = useStyles();
  const { channel, client } = useChatContext();
  const userContext = useContext<UserContext>(UserContext);
  const [channels, setChannels] = useState<Channel[]>([]);
  const { xl } = useBreakpoints();

  const {
    countMessagesUnresponded,
    countMessagesGT8HourOld,
    countMessagesGT24HourOld,
    countMessagesGT48HourOld,
    countMessagesGT72HourOld,
  } = useMemo<CalculateChatSLAsOutput>(() => {
    return calculateChatSLAs(channels);
  }, [channels, `${channel}`]);
  
  useEffect(() => {
    if (userContext?.data?.docID) {
      getUnreadChannelsAdmin(client, setChannels);
    }
  }, [JSON.stringify(userContext?.data), `${channel}`]);


  return (
    <div
      className={classes.statsContainer}
    >
      <Box
        height={60}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        borderRadius={theme.spacing(1)}
        style={{
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <Typography
          component="div"
          variant="h4"
          fontFamily={theme.gh_vars.circular}
          className={classes.totalTitle}
        >
          <>
            Total Messages in Need of Response:
          </>
          <Counter
            to={countMessagesUnresponded}
            format={{ format: '%v', symbol: '0', minFraction: 0, maxFraction: 0 }}
            style={{
              marginLeft: theme.spacing(1),
            }}
          />
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <CountsTile
          count={countMessagesGT8HourOld}
          swatchColor={GREEN_LIGHT}
          subtitle="8 hr+"
        />
        <CountsTile
          count={countMessagesGT24HourOld}
          swatchColor={YELLOW}
          subtitle="1 day+"
          style={{
            marginLeft: xl ? theme.spacing(1.5) : theme.spacing(.5)
          }}
        />
        <CountsTile
          count={countMessagesGT48HourOld}
          swatchColor={ORANGE_ALT}
          subtitle="2 days+"
          style={{
            marginLeft: xl ? theme.spacing(1.5) : theme.spacing(.5)
          }}
        />
        <CountsTile
          count={countMessagesGT72HourOld}
          swatchColor={ERROR_ALT}
          subtitle="3 days+"
          style={{
            marginLeft: xl ? theme.spacing(1.5) : theme.spacing(.5)
          }}
        />
      </Box>
    </div>
  );
};

export default AdminViewStats;
