import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useStyles } from '@ui/utils/makeStyles';
import React from 'react';

const PromosSkeleton = () => {
  const numItems = 20;
  const { theme } = useStyles();

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Skeleton
            variant="rectangular"
            width={500}
            height={47}
          />
        </Grid>
      </Grid>

      {new Array(numItems).fill(0).map((_, i) => {
        return (
          <Skeleton
            key={i}
            variant="rectangular"
            width="100%"
            height={47}
            style={{ marginBottom: 5 }}
          />
        );
      })}
    </>
  );
};

export default React.memo(PromosSkeleton);
