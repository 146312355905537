import formatCurrency from 'format-currency';

export default function formatDiscount(amount : number, type: 'percent' | 'fixed', maxFraction = 2) : string {
  switch (type) {
    case 'percent':
      return `${amount * 100}%`; 
    case 'fixed':
      return formatCurrency(amount, { format: '%s%v', symbol: '$', minFraction: maxFraction, maxFraction });
    default:
      return '';
  }
}
