import React, { forwardRef,memo, useEffect } from 'react';

export const useInfiniteScroll = (ref, cb, options = {
  rootMargin: '400px 0px',
  threshold: 0,
}) => {
  const handleIntersection = ([entry]) => {
    if (entry.isIntersecting && entry.intersectionRatio > 0) {
      cb();
    }
  };

  const observer = new IntersectionObserver(handleIntersection, options);

  useEffect(() => {
    if (observer) {
      observer.disconnect();
    }

    if (ref?.current) {
      observer.observe(ref.current);
      
      return () => {
        if (ref?.current) {
          observer.unobserve(ref.current);
        }
      };
    }

  }, [observer, ref]);
};

const SE = (props, ref) => (
  <div 
    ref={ref}
    style={{ background: 'transparent', width: '100%', height: 1 }}
    {...props}
  />
);

export const ScrollElement = memo(forwardRef(SE), () => true);
