import { SearchOptions, SearchResponse } from '@algolia/client-search';
import { SearchIndex } from 'algoliasearch';
import { ParsedUrlQuery } from 'querystring';


export type Filter = {
  id: string;
  title: string;
  options: guesthouse.Category[];
  values: string | string[];
  counts: Record<string, number>;
  setter: React.Dispatch<React.SetStateAction<string | string[]>>;
}

export type Filters = {
  [key: string]: Filter;
}

export type UseFacetSearchOptions<T> = {
  query: ParsedUrlQuery;
  initResults: SearchResponse<T>;
  searchOptions: Partial<SearchOptions>;
  extraFacetFilters?: { [key: string]: string | string[] };
  extraNumericFilters?: string[],
  filters?: string;
  startDate?: Date;
  useRouter?: boolean;
}

export type PagedResults<T> = {
  [key: number]: {
    results?: T[];
    status: 'loading' | 'error' | 'complete';
    end?: boolean;
  };
}

export interface UseFilterSearchOptions<T> {
  queryString?: string;
  setSearchContext?: boolean;
  useRouterQuery?: boolean;
  extraFacetFilters?: UseFacetSearchOptions<T>['extraFacetFilters'],
}

export const algoliaSearchInventory = async (index: SearchIndex, q, searchOptions): Promise<SearchResponse<guesthouse.AlgoliaInventory>> => {
  const results = await index.search<guesthouse.AlgoliaInventory>(q, searchOptions);

  return results;
};

export const algoliaSearchRoomSets = async (index: SearchIndex, q, searchOptions): Promise<SearchResponse<guesthouse.AlgoliaRoomSet>> => {
  const results = await index.search<guesthouse.AlgoliaRoomSet>(q, searchOptions);

  return results;
};

export const addFilter = (setter: React.Dispatch<React.SetStateAction<string | string[]>>, values: string[] | string, option: string) => {  
  if (Array.isArray(values)) {
    setter([...values, option]);
  } else {
    setter([values, option].filter(i => i));
  }
};

export const removeFilter = (setter: React.Dispatch<React.SetStateAction<string | string[]>>, values: string[] | string, option: string) => {
  if (Array.isArray(values)) {
    setter(values.filter(i => i !== option));
  } else {
    setter(undefined);
  }
};

export const removeFilters = (setter: React.Dispatch<React.SetStateAction<string | string[]>>, values: string[] | string, options: string[]) => {
  if (Array.isArray(values)) {
    setter(values.filter(i => !options.includes(i)));
  } else {
    setter(undefined);
  }
};

export const removeSubcategoryFilters = (parentCategory: Filter, category2: Filter, option: string,) => {
  const removeCategory2 = [];

  parentCategory.options.map(category => {
    if (category.id === option && category.subcategories) {
      category.subcategories.map(subcategory => {
        if (subcategory.id && category2.values?.includes(subcategory.id)) {
          removeCategory2.push(subcategory.id);
        }
      });
    }
  });

  removeCategory2 && removeFilters(category2.setter, category2.values, removeCategory2);
};
  
export const emptyInitResults = {
  hits: [],
  page: 0,
  nbHits: 0,
  nbPages: 0,
  hitsPerPage: 0,
  processingTimeMS: 0,
  query: '',
  params: '',
  exhaustiveNbHits: false,
  useRouter: true,
};

export function initPagedResults<T>(): PagedResults<T> {
  return {
    0: {
      status: 'loading',
    }
  };
}
