import { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

const TextFieldPrice: React.FC<NumberFormatProps<TextFieldProps>> = (props) => {

  return (
    <NumberFormat
      {...props}
      allowEmptyFormatting
      thousandSeparator={true}
      isNumericString={true}
      style={{ flex: 1,
        color: '#999',
        border: 0,
        fontSize: 16,
        lineHeight: 1.5,
      }}
    />
  );
};

export default TextFieldPrice;
