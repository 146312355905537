import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  fullPageWrapper: {
    height: '100vh',
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(8, 2, 5),
    '@media print': {
      minHeight: '100vh',
      width: 900,
      pageBreakAfter: 'always',
    }
  },
  fullPageLogo: {
    maxWidth: 250,
    margin: theme.spacing(0, 1, 8)
  },
  fullPageHeader: {
    '@media print': {
      fontSize: 70,
    }
  },
  fullPageQRCode: {
    margin: theme.spacing(4, 0)
  },
  fullPageSubhead: {
    marginBottom: theme.spacing(2),
  },
  fullPageCopy: {
    fontFamily: theme.gh_vars.mabryMono,
    color: theme.palette.common.black,
    maxWidth: 390,
    fontSize: 14,
  },
  room: {
    marginBottom: theme.spacing(10),
    padding: theme.spacing(5),
    '@media print': {
      minHeight: '100vh',
      pageBreakAfter: 'always',
      marginBottom: theme.spacing(5),
    }
  },
  compactRoom: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  roomHeader: {
    marginBottom: theme.spacing(3),
    '@media print': {
      marginBottom: theme.spacing(2),
    }
  },
  logo: {
    width: 40,
    height: 40,
    display: 'none',
    '@media print': {
      display: 'block',
    }
  },
  actions: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
    '@media print': {
      display: 'none',
    }
  },
  tableRow: {
    '&:last-of-type th': {
      borderBottom: 0,
    },
    '&:last-of-type td': {
      borderBottom: 0,
    }
  },
  tableFooterCell: {
    paddingTop: theme.spacing(3),
    border: 0,
    background: 'white',
    width: '100%',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  }
}));
