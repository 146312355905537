import { RouterLink } from '@components/link-behavior/LinkBehavior';
import useDocument from '@hooks/useDocument';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Routes from '@routes';
import Protected from '@ui/components/protected/Protected';
import { useStyles } from '@ui/utils/makeStyles';
import simpleLocation from '@ui/utils/simpleLocation';
import React, { useMemo } from 'react';
import { generatePath } from 'react-router';


type UserWidgetProps = {
  userId: string; // TODO - Make this guesthouse.User
  showName?: boolean;
  showCompany?: boolean;
}

const UserWidget: React.FC<UserWidgetProps> = (props: UserWidgetProps) => {
  const { userId, showName, showCompany } = props;
  const { doc: userDoc } = useDocument(`users/${userId}`);
  const { theme } = useStyles();
  
  const user = userDoc?.data() as guesthouse.User;

  const makerPath = useMemo(() => generatePath(Routes.user.path, { id: userId }), [userId]);

  if (user?.docID) {
    const instagramLink = `https://www.instagram.com/${user.instagram_handle}`;

    return (
      <>
        <Box
          display="flex"
          alignItems="center"
        >
          {user.photoURL && (
            <Avatar
              component={RouterLink}
              to={makerPath}
              src={user.photoURL}
              style={{ width: 100, height: 100, marginRight: 10 }}
            />
          )}

          <div>
            {showCompany && user.company && (
              <div>
                <Link
                  variant="h6Alt"
                  component={RouterLink}
                  to={makerPath}
                >
                  {user.company}
                </Link>
              </div>
            )}

            {showName && user.firstname && (
              <Link
                fontFamily={theme.gh_vars.circular}
                color="textSecondary"
                component={RouterLink}
                to={makerPath}
              >
                {user.firstname}
                {' '}
                {user.lastname}
              </Link>
            )}
          </div>
        </Box>

        <List dense>
          {user.location && (
            <ListItem>
              <ListItemText
                primary="City"
                secondary={simpleLocation(user.location)}

              />
            </ListItem>
          )}

          <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
            {user.phone && (
              <ListItem>
                <ListItemText
                  primary="Phone"
                  secondary={user.phone}

                />
              </ListItem>
            )}

            {user.email && (
              <ListItem>
                <ListItemText
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  primary="Email"
                  secondary={user.email}

                />
              </ListItem>
            )}
          </Protected>

          {user.website && (
            <ListItem
              component="a"
              href={user.website}
              target="_blank"
            >
              <ListItemText
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                primary="Website"
                secondary={user.website}
              />
            </ListItem>
          )}

          {user.instagram_handle && (
            <ListItem
              component="a"
              href={instagramLink}
              target="_blank"
            >
              <ListItemText
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                primary="Instagram"
                secondary={instagramLink}
              />
            </ListItem>
          )}
        </List>
      </>
    );
  }

  return null;
};

UserWidget.defaultProps = {
  showName: true,
  showCompany: true
};

export default UserWidget;
