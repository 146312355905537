import makeStyles from '../../utils/makeStyles';

export default makeStyles()((theme) => ({
  chipWrapper: {
    width: 84,
    height: 24,
    textDecoration: 'none',
    position: 'relative',
    borderRadius: 5,
  },
  chipText: {
    fontFamily: theme.gh_vars.mabryMono,
    fontSize: 11,
    color: theme.palette.common.black,
    paddingBottom: 2,
  },
}));
