import theme from '@ui/theme';
import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()({
  productImage: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
    borderRadius: 8,
  },
  promoDescription: {
    color: '#83A5B0',
    fontFamily: theme.gh_vars.mabryMono,
    fontSize: 11,
    marginLeft: theme.spacing(1)
  }
});
