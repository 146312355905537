import HomeCard from '@components/cards/HomeCard';
import HomeProducts from '@components/home-products/HomeProducts';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Protected from '@ui/components/protected/Protected';
import { useStyles } from '@ui/utils/makeStyles';
import React from 'react';

import Props, { defaultProps } from './HomeGrid.props';


const HomeGrid: React.FC<Props> = (props: Props) => {
  const { theme } = useStyles();
  
  return (
    <Grid
      container
      spacing={3}
      style={{ marginBottom: theme.spacing(props.spacing) }}
      {...props.gridProps}
    >
      {props.homes.map((home) => {

        if (!home.docID) {
          console.warn('Warning: home.docID was not found but required for key');
        }

        let homeData: guesthouse.Home | guesthouse.AlgoliaHome;

        if (home.designer?.docID || home.client?.docID || home.realtor?.docID) {
          homeData = home as guesthouse.Home;
        } else {
          homeData = home as guesthouse.AlgoliaHome;
        }

        return (
          <Grid
            key={homeData.docID}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={2}
            style={{
              cursor: props.onHomeClick ? 'pointer' : undefined, 
            }}
            onClick={(e) => {
              if (props.onHomeClick) {
                props.onHomeClick(e, homeData);
              }
            }}
            {...props.gridItemProps}
            data-test={`home-grid-${homeData.docID}`}
          >
            <HomeCard
              home={homeData}
              imageHeight={props.imageHeight || 127}
              href={props.href}
              showSubscription={props.showSubscription}
            />
            <Protected allowedRoles={['maker']}>
              <Box style={{ padding: theme.spacing(1) }}>
                <HomeProducts homeId={homeData.docID} />
              </Box>
            </Protected>
          </Grid>
        );
      })}
      {props.afterGrid && (
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
        >
          {props.afterGrid()}
        </Grid>
      )}
    </Grid>
  );
};

HomeGrid.defaultProps = defaultProps;

export default HomeGrid;
