import { auth, database } from '@app/firebase';
import { User as FirebaseUser } from 'firebase/auth';
import { getIdTokenResult } from 'firebase/auth';
import { collection, doc as firestoreDoc, DocumentReference, getDoc,getDocs, query, where } from 'firebase/firestore';

const getUserData = async (user: FirebaseUser) => {
  let ref: DocumentReference;
  let data : guesthouse.User;
  let flags;
  let regions;
  let roles;

  if (user && auth?.currentUser) {
    const idToken = await getIdTokenResult(auth.currentUser, true);

    flags = idToken.claims.flags && idToken.claims.flags as guesthouse.Flags;
    regions = idToken.claims.regions;
    roles = idToken.claims.roles && idToken.claims.roles as guesthouse.Roles;

    if (regions && regions.length > 0) {
      const { docs } = await getDocs(query(collection(database, 'regions'), where('docID', 'in', regions)));

      regions = docs
        .map(doc => {
          const data = doc.data() as guesthouse.Region;

          return data;
        });
    }

    const doc = await getDoc(firestoreDoc(collection(database, 'users'), user.uid));

    ref = doc.ref;
    data = doc.data() as guesthouse.User;
  }

  return { data, flags, regions, roles, ref };
};

export default getUserData;
