import { Dispatch, SetStateAction } from 'react';
import { Channel, ChannelFilters, ChannelSortBase, StreamChat } from 'stream-chat';

/**
 * 
 * @returns filters for messages > 1hr old, sent by external users, and which haven't been marked red by an admin
 */
export const getAdminViewFilters = (): ChannelFilters<GHStreamGenerics> => {
  return {
    resolved_gh_sla: { $eq: false },
    last_sent_by_external_user: true,
  };
};

export const getUnreadChannelsAdmin = async (client: StreamChat, setChannels: Dispatch<SetStateAction<Channel[]>>) => {
  const filter: ChannelFilters = {
    resolved_gh_sla: { $eq: false },
    last_sent_by_external_user: true,
  };
  const sort: ChannelSortBase[] = [{ last_message_at: -1 }];
  const options = {
    state: true
  };

  const channelsData = await client.queryChannels(filter, sort, options);

  if (channelsData.length) {
    const channelsInUse = channelsData.filter(channel => {
      return channel.state.messages?.length > 0;
    });

    setChannels([...channelsInUse]);
  }
};
