import round from 'lodash.round';

const _sale = (dealDiscount: number, dealDiscountEligible: boolean, addonDiscountAmount: number, dealTotal: number, addonTotal: number, discountAmount: number, deal: Partial<HubSpot.Deal>, useLineItemTotal: boolean) => {
  if (dealDiscount && dealDiscountEligible && deal.properties.total_order_discount_fixed) {
    return round(discountAmount + addonDiscountAmount + dealDiscount, 2);
  }

  // The addon totals are calculated differently depending on if this util is being use in OrderPreview.tsx or StagingCheckout.tsx
  if (dealDiscount && dealDiscountEligible && deal.properties.total_order_discount_percent && useLineItemTotal) {
    return round(discountAmount + addonDiscountAmount + (dealDiscount * (dealTotal + addonTotal - addonDiscountAmount)), 2);
  }

  if (dealDiscount && dealDiscountEligible && deal.properties.total_order_discount_percent && !useLineItemTotal) {
    return round(discountAmount + addonDiscountAmount + (dealDiscount * (dealTotal + addonTotal)), 2);
  }

  return round(discountAmount + addonDiscountAmount, 2);
};

export default function stagingOrderTotals(
  deal?: Partial<HubSpot.Deal>,
  lineItems?: Partial<HubSpot.LineItem>[],
  addons?: Partial<HubSpot.LineItemProperties>[],
  useLineItemTotal?: boolean,
  coupon?: guesthouse.Coupon,
  productsIncluded?: Partial<guesthouse.ProductLite>[],
  productsExcluded?: Partial<guesthouse.ProductLite>[],
  options?: {
    stagingTerm?: number,
  }
): guesthouse.OrderTotals {
  const discountAmount: number = (lineItems || []).reduce((acc, li) => {
    if (li.properties.discount) {
      acc += Number(li.properties.discount) * Number(li.properties.quantity);
    }
    if (li.properties.hs_discount_percentage) {
      acc += (Number(li.properties.hs_discount_percentage) / 100) * Number(li.properties.price) * Number(li.properties.quantity);
    }
    return acc;
  }, 0);

  const addonDiscountAmount: number = (addons || []).reduce((acc, addon) => {
    if (addon.discount) {
      acc += Number(addon.discount) * Number(addon.quantity);
    }
    if (addon.hs_discount_percentage) {
      acc += (Number(addon.hs_discount_percentage) / 100) * Number(addon.price) * Number(addon.quantity);
    }
    return acc;
  }, 0);

  const dealDiscount = Number(deal?.properties?.total_order_discount_fixed) || (Number(deal?.properties?.total_order_discount_percent) / 100);
  const dealDiscountMinimum = Number(deal?.properties?.total_order_discount_minimum) || 0;

  let dealTotal = Number(deal?.properties?.amount || 0);

  if (useLineItemTotal) {
    dealTotal = (lineItems || []).reduce((accumulator, lineItem) => {
      let discount = 0;

      if (lineItem.properties.discount) {
        discount += Number(lineItem.properties.discount) * Number(lineItem.properties.quantity);
      }
      if (lineItem.properties.hs_discount_percentage) {
        discount += (Number(lineItem.properties.hs_discount_percentage) / 100) * Number(lineItem.properties.price) * Number(lineItem.properties.quantity);
      }

      const lineItemTotal = Number(lineItem.properties.price) * Number(lineItem.properties.quantity || 1);

      return accumulator + lineItemTotal - discount;
    }, 0) || 0;
  }

  if (options?.stagingTerm === 60) {
    dealTotal = dealTotal * 1.4;
  }

  let couponDiscount = 0;

  const validProductSKUs = (productsIncluded || []).map(product => product.sku);
  const invalidProductSKUs = (productsExcluded || []).map(product => product.sku);

  if (coupon?.staging_discount_amount && !addons?.length) {
    if (!validProductSKUs.length && !invalidProductSKUs.length) {
      if (coupon.staging_discount_type === 'fixed') {
        couponDiscount += coupon.staging_discount_amount;
      }
      if (coupon.staging_discount_type === 'percent') {
        couponDiscount += coupon.staging_discount_amount * dealTotal;
      }
    }

    if (!!lineItems?.length && (validProductSKUs.length || invalidProductSKUs.length)) {
      for (const lineItem of lineItems) {
        const liSKU = lineItem.properties.hs_sku;
        const validOnIncluded = !!validProductSKUs.filter(sku => {
          if (sku === 'STA' && liSKU === 'BASE') {
            return true;
          }

          return liSKU.includes(sku);
        })?.length;
        const invalidOnExcluded = !!invalidProductSKUs.filter(sku => {
          if (sku === 'STA' && liSKU === 'BASE') {
            return true;
          }

          return liSKU.includes(sku);
        })?.length;

        if (validOnIncluded && !invalidOnExcluded) {
          if (coupon.staging_discount_type === 'fixed') {
            couponDiscount += coupon.staging_discount_amount * Number(lineItem.properties.quantity);
          }

          if (coupon.staging_discount_type === 'percent') {
            couponDiscount += Number(lineItem.properties.price) * coupon.staging_discount_amount * Number(lineItem.properties.quantity);
          }
        }
      }
    }
  }

  const addonTotal = (addons || []).reduce((accumulator, addon) => accumulator + Number(addon.price), 0) || 0;

  if (coupon?.staging_discount_amount && addons?.length) {
    if (!validProductSKUs.length && !invalidProductSKUs.length) {
      if (coupon.staging_discount_type === 'fixed') {
        couponDiscount += coupon.staging_discount_amount;
      }
      if (coupon.staging_discount_type === 'percent') {
        couponDiscount += coupon.staging_discount_amount * (dealTotal + addonTotal);
      }
    }

    if ((coupon.order_application === 'global' || coupon.order_application === 'no_shipping') && (validProductSKUs.length || invalidProductSKUs.length)) {
      for (const addon of addons) {
        const addonSKU = addon.hs_sku;
        const validOnIncluded = !!validProductSKUs.filter(sku => addonSKU.includes(sku)).length;
        const invalidOnExcluded = !!invalidProductSKUs.filter(sku => addonSKU.includes(sku)).length;

        if (validOnIncluded && !invalidOnExcluded) {
          if (coupon.staging_discount_type === 'fixed') {
            couponDiscount += coupon.staging_discount_amount;
          }

          if (coupon.staging_discount_type === 'percent') {
            couponDiscount += Number(addon.price) * coupon.staging_discount_amount;
          }
        }
      }
    }
  }

  const stagingBasePrice = lineItems?.filter(li => li.properties.hs_sku?.startsWith('BASE') || li.properties.hs_sku?.startsWith('STA'))
    .reduce((accumulator, lineItem) => accumulator + (Number(lineItem.properties.price) * Number(lineItem.properties.quantity || 1)), 0) || 0;

  if (coupon?.min_order_amount && stagingBasePrice < coupon.min_order_amount) {
    couponDiscount = 0;
  }

  // HubSpot applies discounts, so to display the correct amount in subtotal
  // the HubSpot discounts are added to subtotal, then removed from total
  couponDiscount = round(couponDiscount, 2);
  const subtotal = round(dealTotal + addonTotal + discountAmount, 2);

  const dealDiscountEligible = subtotal > dealDiscountMinimum;
  const sale = _sale(dealDiscount, dealDiscountEligible, addonDiscountAmount, dealTotal, addonTotal, discountAmount, deal, useLineItemTotal);
  const shippingTotal = 0;
  const taxTotal = 0;
  const stagingTotal = dealTotal;
  const total = round(subtotal - sale - couponDiscount + shippingTotal + taxTotal, 2);

  return {
    subtotal,
    sale,
    shippingTotal,
    taxTotal,
    stagingBasePrice,
    total,
    stagingTotal,
    addonTotal,
    couponDiscount,
    dealDiscountEligible,
    dealDiscount,
    dealDiscountMinimum,
  };
}
