import { AvailableForStagingDynamic } from '@components/available-for-staging/AvailableForStaging';
import PrimaryImage from '@components/primary-image/PrimaryImage';
import useCenterPoint from '@hooks/useCenterPoint';
import useProductAvailability from '@hooks/useProductAvailability';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Protected from '@ui/components/protected/Protected';
import simpleLocation from '@ui/utils/simpleLocation';
import userFullName from '@ui/utils/userFullName';
import { userPath } from '@utils/paths';
import React from 'react';
import { Link } from 'react-router-dom';

import ProductCardProps from './ProductCard.props';
import useStyles from './ProductCard.style';


type CardPaperProps = {
  product: guesthouse.Product;
}

const CardPaper: React.FC<CardPaperProps> = ({ product }) => {
  const { classes } = useStyles();

  return (
    <>
      <Paper
        className={classes.productPaper}
      >
        <PrimaryImage
          item={product}
          className={classes.productImage}
        />
      </Paper>
      <div
        className={classes.productTitleContainer}
      >
        <Typography

          className={classes.productTitle}
        >
          {product.title}
        </Typography>
      </div>
    </>
  );
};

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  onClick,
  href,
  gridItemProps,
  showMaker,
  showLocation,
  stagingDate,
  style,
  className,
  externalLink,
  dateNeededFilter
}) => {
  const { classes, theme } = useStyles();
  const [coords, radius] = useCenterPoint();
  const { availableInternal, availableExternal } = useProductAvailability(stagingDate, product?.docID, coords, radius);

  if (dateNeededFilter === true && availableExternal?.length === 0 && availableInternal?.length === 0) {
    return null;
  }

  if (!product) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={299}
        style={{
          margin: 0,
          borderRadius: theme.shape.borderRadius,
          cursor: onClick ? 'pointer' : undefined,
          ...style
        }}
        className={className}
      />
    );
  }

  return (
    <Box
      style={{
        cursor: onClick ? 'pointer' : undefined,
        height: '100%',
        ...style
      }}
      className={className}
      onClick={(e) => {
        if (onClick) {
          onClick(e, product);
        }
      }}
      {...gridItemProps}
    >
      <Box
        className={classes.cardContainerInner}
      >
        {typeof href === 'function' && (
          <Link
            aria-label={product.title}
            to={href(product)}
          >
            <CardPaper
              product={product}
            />
          </Link>
        )}

        {externalLink && (
          <a
            target="_blank"
            href={externalLink}
            style={{
              textDecoration: 'none',
              display: 'block',
            }}
            rel="noreferrer"
          >
            <CardPaper
              product={product}
            />
          </a>
        )}

        {typeof href !== 'function' && !externalLink && (
          <CardPaper
            product={product}
          />
        )}

        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          style={{
            marginBottom: theme.spacing(1),
            height: 21,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
          }}
        >
          {product?.owner && (
            <>
              {showMaker && (
                <Link
                  to={userPath(product.owner)}
                >
                  <Typography
                    component="div"
                    className={classes.maker}
                  >
                    {userFullName(product.owner, true)}
                  </Typography>
                </Link>
              )}
              {showLocation && (
                <Typography
                  component="span"
                  className={classes.location}
                >
                  {product.owner.location && simpleLocation(product.owner.location)}
                </Typography>
              )}
            </>
          )}
        </Box>


        <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
          <AvailableForStagingDynamic
            availableExternal={availableExternal}
            availableInternal={availableInternal}
          />
        </Protected>
      </Box>
    </Box>
  );
};

export default ProductCard;
