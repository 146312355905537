import { RouterLink } from '@components/link-behavior/LinkBehavior';
import AppContext from '@context/AppContext';
import useDocument from '@hooks/useDocument';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import UserAvatar from '@ui/components/user-avatar/UserAvatar';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import IconX from '@ui/icons/imaterial/base/X.svg';
import userFullName from '@ui/utils/userFullName';
import { userPath } from '@utils/paths';
import properCase from '@utils/properCase';
import React, { useContext } from 'react';

import useStyles from './UserWidgetAlt.style';

type UserWidgetAltProps = {
  className?: string;
  style?: React.CSSProperties;
  userID: string;
  userType?: string;
  onDelete?: (user: guesthouse.User) => void;
  renderAction?: (user: guesthouse.User) => React.ReactElement;
}

const UserWidgetAlt: React.FC<UserWidgetAltProps> = (props) => {
  const { className, style, userID, userType, onDelete, renderAction } = props;
  const { doc: user, loading } = useDocument(`users/${userID}`);
  const appContext = useContext<AppContext>(AppContext);
  const { classes, cx } = useStyles({});
  const { sm } = useBreakpoints();
  const userData = user?.data() as guesthouse.User;

  if (loading || appContext.skeleton) {
    return (
      <Skeleton
        variant="rectangular"
        className={cx(classes.root, className)}
        style={style}
      />
    );
  }

  if (userData?.docID) {
    return (
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        className={cx(classes.root, className)}
        style={style}
      >
        <div
          className={classes.avatarTextWrapper}
        >
          <Link
            component={RouterLink}
            to={userPath(userData)}
            style={{
              textDecoration: 'none',
            }}
          >
            <UserAvatar
              user={userData}
              size={!sm ? 40 : 60}
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            />
          </Link>

          <div
            className={classes.nameWrapper}
          >
            <Link
              component={RouterLink}
              to={userPath(userData)}
              style={{
                textDecoration: 'none',
              }}
            >
              {userType && (
                <Typography
                  component="div"
                  variant="overline"
                  color="textSecondary"
                  className={classes.overline}

                >
                  {properCase(userType)}
                </Typography>
              )}
              <Typography
                component="div"
                variant="body2"
                className={classes.text}
              >
                {userFullName(userData)}
              </Typography>
              <Typography
                component="div"
                className={classes.text}
                color="textSecondary"
              >
                {userData.company}
              </Typography>
            </Link>

          </div>
        </div>

        {typeof onDelete === 'function' && (
          <div className={classes.deleteButton}>
            <Tooltip title="Remove user">
              <IconButton
                size="small"
                aria-label="Remove user"

                onClick={() => onDelete(userData)}
              >
                <IconX />
              </IconButton>
            </Tooltip>
          </div>
        )}

        {typeof renderAction === 'function' && renderAction(userData)}

      </Box>
    );
  }

  return null;

};

export default UserWidgetAlt;
