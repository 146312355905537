import ProductDefault from '@images/product-default.png';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type PrimaryImageProps = {
  item: Partial<guesthouse.Home | guesthouse.Product | guesthouse.Room>;
  alt?: string;
  size?: string;
  fallback?: string;
  lazyLoadImages?: boolean;
  className?: string;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
}

const PrimaryImageSkeleton = ({ className, style }) => (
  <Skeleton
    variant="rectangular"
    style={{ margin: 0, ...style }}
    className={className}
  />
);

const PrimaryImage : React.FC<PrimaryImageProps> = (props: PrimaryImageProps) => {
  const {
    item,
    alt,
    size,
    fallback,
    lazyLoadImages,
    style,
    wrapperStyle,
    className,
  } = props;


  const primaryPhoto = item?.primaryPhoto;

  let photo, url;

  if (primaryPhoto) {
    photo = primaryPhoto;
    url = photo[`${size}_url`];
  } else if (fallback) {
    url = fallback;
  }

  if (lazyLoadImages) {
    return (
      <LazyLoadImage
        src={url}
        effect="opacity"
        threshold={100}
        width="100%"
        height="100%"
        alt={alt}
        style={style}
        className={className}
        placeholder={<PrimaryImageSkeleton
          key="skeleton"
          className={className}
          style={style}
        />}
        wrapperProps={{ style: { display: 'block', height: '100%', ...wrapperStyle } }}
      />
    );
  } else {
    return (
      <img
        src={url}
        width="100%"
        height="100%"
        style={style}
        className={className}
        alt={alt}
      />
    );
  }
};

PrimaryImage.defaultProps = {
  size: 'medium',
  lazyLoadImages: true,
  style: {},
  fallback: ProductDefault,
  wrapperStyle: {},
};

export default React.memo(PrimaryImage);
