import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CommentIcon from '@mui/icons-material/Comment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import userFullName from '@ui/utils/userFullName';
import { userPath } from '@utils/paths';
import properCase from '@utils/properCase';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import formatCurrency from 'format-currency';
import React from 'react';
import { Link } from 'react-router-dom';


type OffersTableProps = {
  product: QueryDocumentSnapshot<guesthouse.Product>
  offers: QueryDocumentSnapshot<guesthouse.ProductOffer>[];
  style?: React.CSSProperties
  renderActions?: (props: RenderActionsProps) => React.ReactElement
}

type RenderActionsProps = {
  product: QueryDocumentSnapshot<guesthouse.Product>
  offer: QueryDocumentSnapshot<guesthouse.ProductOffer>
}

const OffersTable: React.FC<OffersTableProps> = (props) => {
  const { product, offers, style, renderActions } = props;

  return (
    <TableContainer>
      <Table
        aria-label="offers table"
        style={style}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              Classification
            </TableCell>
            <TableCell>
              Type
            </TableCell>
            <TableCell>
              Condition
            </TableCell>
            <TableCell>
              Notes
            </TableCell>
            <TableCell>
              Price
            </TableCell>
            <TableCell>
              Sale
            </TableCell>
            <TableCell>
              Sale Price
            </TableCell>
            <TableCell>
              Shipping Price
            </TableCell>
            <TableCell>
              Stock Quantity
            </TableCell>
            <TableCell>
              Sold By
            </TableCell>
            <TableCell>
              Drop Ship
            </TableCell>
            <TableCell>
              Pickup Only
            </TableCell>
            <TableCell>
              Pickup Location
            </TableCell>
            {renderActions && (
            <TableCell align="center">
              Actions
            </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {offers.map((offer) => {
            const offerData = offer.data();

            return (
              <TableRow key={offer.id}>
                <TableCell>
                  {properCase(offerData.classification)}
                </TableCell>
                <TableCell>
                  {properCase(offerData.type)}
                </TableCell>
                <TableCell>
                  {properCase(offerData.condition)}
                </TableCell>
                <TableCell>
                  {offerData?.notes && (
                  <Tooltip title={offerData.notes}>
                    <CommentIcon
                      style={{
                        verticalAlign: 'middle'
                      }}
                    />
                  </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  {formatCurrency(offerData.price, { format: '%s%v', symbol: '$' })}
                </TableCell>
                <TableCell>
                  {offerData.sale && (<CheckCircleIcon />)}
                </TableCell>
                <TableCell>
                  {offerData.sale ? formatCurrency(offerData.salePrice, { format: '%s%v', symbol: '$' }) : null}
                </TableCell>
                <TableCell>
                  {
                  offerData.pickupOnly || offerData.classification === 'internal' ? (
                    <em>
                      NA
                    </em>
                  ) : (
                    <>
                      {formatCurrency(offerData.shippingPricePerOrder, { format: '%s%v', symbol: '$' })}
                      {' '}
                      /
                      {' '}
                      {formatCurrency(offerData.shippingPricePerProduct, { format: '%s%v', symbol: '$' })}
                    </>
                  )
                }
                </TableCell>
                <TableCell>
                  {offerData.dropship ? (
                    <em>
                      NA
                    </em>
                  ) : (
                    <>
                      {offerData.stockQuantity}
                    </>
                  )}
                </TableCell>
                <TableCell>
                  {offerData.soldBy && (
                    <Link to={userPath(offerData.soldBy)}>
                      {userFullName(offerData.soldBy, true)}
                    </Link>)
                  }
                </TableCell>
                <TableCell>
                  {offerData.dropship && (<CheckCircleIcon />)}
                </TableCell>
                <TableCell>
                  {offerData.pickupOnly && (<CheckCircleIcon />)}
                </TableCell>
                <TableCell>
                  {offerData.pickupOnly ? offerData.pickupLocation?.address : null}
                </TableCell>
                {props.renderActions && (
                <TableCell
                  width="100"
                  height="60"
                  align="center"
                >
                  {props.renderActions({ offer: offer, product })}
                </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OffersTable;
