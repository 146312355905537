import { SearchOptions, SearchResponse } from '@algolia/client-search';
import UserContext from '@context/UserContext';
import { CATEGORIES, COLORS, STYLES } from '@data';
import useRouter from '@hooks/useRouter';
import algolia from '@integrations/Algolia';
import Sentry from '@integrations/Sentry';
import checkRoles from '@ui/utils/checkRoles';
import { Filters } from '@utils/algoliaSearch';
import { UseFacetSearchOptions } from '@utils/algoliaSearch';
import getPlatform from '@utils/getPlatform';
import makeBooleanFilter from '@utils/makeBooleanFilter';
import makeFacetFilters from '@utils/makeFacetFilters';
import makeIndexName from '@utils/makeIndexName';
import makeNumericFilter from '@utils/makeNumericFilter';
import qs from 'querystring';
import { useContext, useEffect, useState } from 'react';

import useDebounceEffect from './useDebounceEffect';

const index = algolia.initIndex(makeIndexName('products'));

const categories2 = [];

CATEGORIES.map(category => {
  category.subcategories && category.subcategories.map(category2 => {
    categories2.push(category2);
  });
});

const categories3 = [];

categories2 && categories2.map(category2 => {
  category2.subcategories && category2.subcategories.map(category3 => {
    categories3.push(category3);
  });
});

export default function useFacetSearch(options: UseFacetSearchOptions<guesthouse.Product>) {
  const [results, setResults] = useState<SearchResponse<guesthouse.Product>>(options.initResults);
  const [q, setQuery] = useState<string>(options.query?.q?.toString());
  const [startDate, setStartDate] = useState<string | string[]>(options.query.startDate);
  const [color, setColor] = useState<string | string[]>(options.query.color);
  const [style, setStyle] = useState<string | string[]>(options.query.style);
  const [category, setCategory] = useState<string | string[]>(options.query.category);
  const [category2, setCategory2] = useState<string | string[]>(options.query.category2);
  const [category3, setCategory3] = useState<string | string[]>(options.query.category3);
  const [price, setPrice] = useState<string | string[]>(options.query.price);
  const [availableTotal, setAvailableTotal] = useState<string | string[]>(options.query.availableTotal);
  const [published, setPublished] = useState<string | string[]>(options.query.published);
  const [inventoryExternal, setInventoryExternal] = useState<string | string[]>(options.query.inventoryExternal);
  const [inventoryInternal, setInventoryInternal] = useState<string | string[]>(options.query.inventoryInternal);
  const [dibs, setDibs] = useState<string | string[]>(options.query.dibs);
  const [dropshippable, setDropshippable] = useState<string | string[]>(options.query.dropshippable);
  const router = useRouter();
  const userContext = useContext<UserContext>(UserContext);

  const doSearch = () => {
    const obj = {
      q,
      color,
      style,
      category,
      category2,
      category3,
      price,
      startDate,
      availableTotal,
      published,
      inventoryExternal,
      inventoryInternal,
      dibs,
      dropshippable
    };

    for (const k in obj) {
      if (!obj[k] || (Array.isArray(obj[k]) && !obj[k].length)) {
        delete obj[k];
      }
    }

    const search = qs.stringify(obj);

    const url = Object.keys(obj).length ? `${router.pathname}?${search}` : router.pathname;

    if (options.useRouter) {
      router.push(url);
    }

    let facetFilters;

    if (checkRoles(['maker'], userContext.roles)) {
      facetFilters = makeFacetFilters({
        color,
        style,
        category,
        category2,
        category3,
        ...options.extraFacetFilters,
      });
    } else {
      facetFilters = makeFacetFilters({
        color,
        style,
        category,
        category2,
        category3,
        archived: 'false',
        // dibs products are not returned in filtered search results by default per op team's request
        dibs: dibs ? 'true' : 'false',
        ...options.extraFacetFilters,
      });
    }

    const numericFilters = makeNumericFilter({
      price,
    }) as unknown as (string | string[])[];

    if (availableTotal && startDate) {
      numericFilters.push([`available > ${availableTotal}`, `availableInternal >= ${availableTotal}`]);
      numericFilters.push([`availablilityStart_timestamp < ${startDate}`]);
      numericFilters.push([`availablilityEnd_timestamp > ${startDate}`]);
    }

    if (inventoryExternal) {
      numericFilters.push([`inventory >= ${inventoryExternal}`]);
    }
    if (inventoryInternal) {
      numericFilters.push([`inventoryInternal >= ${inventoryInternal}`]);
    }

    const booleanFilters = makeBooleanFilter({
      published,
      dibs,
      dropshippable
    });

    const currentPlatform = getPlatform();

    const searchOptions: SearchOptions = {
      facetingAfterDistinct: true,
      distinct: 1,
      facetFilters,
      // @ts-ignore
      numericFilters: numericFilters,
      filters: booleanFilters,
      facets: [
        'color',
        'style',
        'category',
        'category2'
      ],
      analyticsTags: [currentPlatform],
      ...options.searchOptions,
    };

    index.search<guesthouse.Product>(q, searchOptions)
      .then(setResults)
      .catch(Sentry.captureException);
  };

  useEffect(doSearch, []);
  useDebounceEffect(doSearch, 1000, [
    JSON.stringify(options.searchOptions),
    q,
    color,
    style,
    category,
    category2,
    category3,
    price,
    availableTotal,
    published,
    startDate,
    inventoryExternal,
    inventoryInternal,
    dibs,
    dropshippable
  ]);

  const filters: Filters = {
    category: {
      id: 'category',
      title: 'Categories',
      options: CATEGORIES,
      values: category,
      counts: results.facets?.category,
      setter: setCategory,
    },
    category2: {
      id: 'category2',
      title: 'Categories2',
      options: categories2,
      values: category2,
      counts: results.facets?.category2,
      setter: setCategory2,
    },
    category3: {
      id: 'category3',
      title: 'Categories3',
      options: categories3,
      values: category3,
      counts: results.facets?.category3,
      setter: setCategory3,
    },
    color: {
      id: 'color',
      title: 'Color',
      options: COLORS,
      values: color,
      counts: results.facets?.color,
      setter: setColor,
    },
    style: {
      id: 'style',
      title: 'Style',
      options: STYLES,
      values: style,
      counts: results.facets?.style,
      setter: setStyle,
    },
    price: {
      id: 'price',
      title: 'Price',
      values: price,
      counts: results.facets?.price,
      setter: setPrice,
      options: [
        {
          id: '0 TO 25',
          title: 'Under $25',
        },
        {
          id: '25 TO 250',
          title: '$25 - $250',
        },
        {
          id: '250 TO 500',
          title: '$250 - $500',
        },
        {
          id: 'price > 500',
          title: 'Over $500',
        }
      ]
    }
  };

  return {
    results,
    filters,
    query: q,
    setQuery,
    color,
    style,
    category,
    category2,
    category3,
    startDate,
    setStartDate,
    availableTotal,
    setAvailableTotal,
    published,
    setPublished,
    inventoryExternal,
    setInventoryExternal,
    inventoryInternal,
    setInventoryInternal,
    dibs,
    setDibs,
    dropshippable,
    setDropshippable
  };
}
