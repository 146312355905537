import PrimaryImage from '@components/primary-image/PrimaryImage';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { roomSetPath } from '@utils/paths';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React from 'react';
import { Link } from 'react-router-dom';

import RequestedRoomSetActions from './RequestedRoomSetActions';

type RequestedRoomSetRowProps = {
  room: guesthouse.Room;
  roomSet: guesthouse.RoomSet;
  requests: QueryDocumentSnapshot<guesthouse.ProductRequest>[];
}

const RequestedRoomSetRow : React.FC<RequestedRoomSetRowProps> = ({ room, roomSet, requests }) => {
  return (
    <TableRow key={roomSet.docID}>
      <TableCell
        component="th"
        scope="row"
      >
        <PrimaryImage
          item={roomSet}
          style={{ width: 75, height: 75, borderRadius: 8, display: 'block', margin: 0 }}
        />
      </TableCell>

      <TableCell
        component="th"
        scope="row"
      >
        <Link
          to={roomSetPath(roomSet)}
          style={{ textDecoration: 'none' }}
        >
          {roomSet.title}
        </Link>
      </TableCell>

      <TableCell>
        {roomSet.setNumber}
      </TableCell>

      <TableCell align="right">
        <RequestedRoomSetActions 
          room={room}
          roomSet={roomSet}
          requests={requests}
        />
      </TableCell>
    </TableRow>
  );
};

export default RequestedRoomSetRow;
