import { database } from '@app/firebase';
import { formatInventoryType } from '@components/cards/RoomSetCard';
import { ROOM_TYPES } from '@data';
import useCenterPoint from '@hooks/useCenterPoint';
import useGeoCollection from '@hooks/useGeoCollection';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import { isError } from '@ui/utils/typescriptHelpers';
import { collection } from 'firebase/firestore';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { DefaultFirestoreConverter } from '../../types/DefaultFirestoreConverter';

const inventoryTypeOptions : guesthouse.RoomSetInventory[] = ['guesthouse', 'rental_cort'];

const RoomSetForm = ({ initialValues, userContext, onSubmit, error, submitText = 'Add Room Set', disabled = false }) => {
  const [coords, radius] = useCenterPoint(userContext?.activeRegion?.centerPoint);
  const [warehousesData, setWarehousesData] = useState<guesthouse.Warehouse[]>();

  const roomSetSchema = Yup.object().shape({
    title: Yup.string()
      .required('Room set title is required'),
    roomType: Yup.string()
      .required('Room type is required'),
    setNumber: Yup.string()
      .required('Set Number is required'),
    assignedWarehouse: Yup.object()
      .required('Assigned warehouse is required'),
    inventoryType: Yup.string()
      .required('Inventory type is required')
  });

  const { docs: warehouseDocs } = useGeoCollection<guesthouse.Warehouse>(
    collection(database, 'warehouses').withConverter(new DefaultFirestoreConverter<guesthouse.Warehouse>()),
    {
      center: coords,
      radius,
    }
  );

  useEffect(() => {
    if (warehouseDocs?.length) {
      setWarehousesData(warehouseDocs.map((warehouse) => {
        return warehouse.data() as guesthouse.Warehouse;
      }));
    }
  }, [warehouseDocs]);

  return (
    <Formik
      validationSchema={roomSetSchema}
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={onSubmit}
    >
      {({
        touched,
        values,
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched
      }) => {
        return (
          <form
            style={{
              width: '100%'
            }}
            onSubmit={handleSubmit}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Field
                fullWidth
                name="title"
                label="Title"
                type="title"
                component={TextField}
                margin="dense"
                variant="outlined"
                data-test="title-field"
              />

              <Field
                fullWidth
                name="setNumber"
                label="Set Number"
                type="setNumber"
                component={TextField}
                margin="dense"
                variant="outlined"
                data-test="set-number"
              />

              <FormControl
                fullWidth
                variant="outlined"
                margin="normal"
              >
                <InputLabel id="room-type-label">
                  Room Type
                </InputLabel>
                <Select
                  data-test="room-type"
                  labelId="room-type-label"
                  id="room-type"
                  value={values.roomType || []}
                  label="Room Type"
                  onChange={(e) => {
                    setFieldTouched('roomType', true);
                    setFieldValue('roomType', e.target.value);
                  }}
                >
                  {ROOM_TYPES.map(type => (
                    <MenuItem
                      key={type.id}
                      value={type.id}
                    >
                      {type.title}
                    </MenuItem>
                  ))}
                </Select>
                {touched.type && Boolean(errors.type) && (
                  <FormHelperText error>
                    {error.type}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                margin="normal"
              >
                <InputLabel id="inventory-type-label">
                  Inventory Type
                </InputLabel>
                <Select
                  data-test="inventory-type"
                  labelId="inventory-type-label"
                  id="inventory-type"
                  value={values.inventoryType || []}
                  label="Inventory Type"
                  onChange={(e) => {
                    setFieldTouched('inventoryType', true);
                    setFieldValue('inventoryType', e.target.value);
                  }}
                >
                  {
                    inventoryTypeOptions.map((type) => (
                      <MenuItem
                        key={type}
                        value={type}
                      >
                        {formatInventoryType(type)}
                      </MenuItem>
                    ))
                  }
                </Select>
                {touched.type && Boolean(errors.type) && (
                  <FormHelperText error>
                    {error.type}
                  </FormHelperText>
                )}
              </FormControl>

              {warehousesData?.length &&
                <Autocomplete
                  fullWidth
                  id="assigned-warehouse"
                  defaultValue={initialValues?.assignedWarehouse}
                  options={warehousesData}
                  getOptionLabel={(option: Partial<guesthouse.Warehouse>) => option.name || ''}
                  renderInput={(params) => {
                    return (
                      <Field
                        {...params}
                        fullWidth
                        name="assignedWarehouse"
                        label="Assigned Warehouse"
                        type="text"
                        component={TextField}
                        margin="dense"
                        variant="outlined"
                      />
                    );
                  }}
                  onChange={(e, value) => {
                    setFieldValue('assignedWarehouse', value);
                  }}
                />
              }

            </div>

            {error && (
              <FormHelperText error>
                {isError(error) ? error.message : error}
              </FormHelperText>
            )}

            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length) || disabled}
                aria-label={submitText}
                data-test="add-calendar-submit-button"
                tooltip={makeErrors(errors, touched)?.length && (
                  <TooltipErrors
                    errors={errors}
                    touched={touched}
                  />
                )}
              >
                {submitText}
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(RoomSetForm);
