import { database } from '@app/firebase';
import { collectionGroup, deleteDoc, getDocs, query, QueryDocumentSnapshot, updateDoc, where, writeBatch } from 'firebase/firestore';

export const batchUpdateRequests = async (snap: QueryDocumentSnapshot<guesthouse.ProductRequest>, data: Partial<guesthouse.ProductRequest>) => {
  const request = snap.data();

  if (request.batchID) {
    await getDocs(query(collectionGroup(database, 'requests')
      ,where('batchID', '==', request.batchID)))
      .then(collection => {
        const batch = writeBatch(database);

        if (collection?.docs?.length) {
          collection.docs.forEach(doc => {
            batch.update(doc.ref, data);
          });
        }
        return batch;
      })
      .then(batch => batch.commit());
  } else {
    // @ts-ignore https://github.com/firebase/firebase-js-sdk/issues/5853
    await updateDoc(snap.ref, data);
  }
};

export const batchDeleteRequests = async (snap: QueryDocumentSnapshot<guesthouse.ProductRequest>) => {
  const request = snap.data();

  if (request.batchID) {
    await getDocs(query(collectionGroup(database, 'requests')
      ,where('batchID', '==', request.batchID)))
      .then(collection => {
        const batch = writeBatch(database);

        if (collection?.docs?.length) {
          collection.docs.forEach(doc => {
            batch.delete(doc.ref);
          });
        }
        return batch;
      })
      .then(batch => batch.commit());
  } else {
    await deleteDoc(snap.ref);
  }
};

export const batchUpdateRequestInventory = async (snap: QueryDocumentSnapshot<guesthouse.ProductRequest>, data: Partial<guesthouse.ProductInventory>) => {
  const request = snap.data();

  if (request.batchID) {
    await getDocs(query(collectionGroup(database, 'requests')
      ,where('batchID', '==', request.batchID)))
      .then(collection => {
        const batch = writeBatch(database);

        if (collection?.docs?.length) {
          collection.docs.forEach(doc => {
            const inventory = doc.ref.parent.parent;

            batch.update(inventory, data);
          });
        }
        return batch;
      })
      .then(batch => batch.commit());
  } else {
    // TODO this fails
    // @ts-ignore
    await updateDoc(snap.ref, data);
  }
};
