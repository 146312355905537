import { database, storage } from '@app/firebase';
import UploadContext from '@context/UploadContext';
import Sentry from '@integrations/Sentry';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { convertPhotoToPrimaryPhoto } from '@ui/utils/convertPhotoToPrimaryPhoto';
import getLocationKeys from '@utils/getLocationKeys';
import { collection, deleteDoc, doc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useContext, useEffect } from 'react';

import useStyles from './UploadList.style';

const UploadList = () => {
  const uploadContext = useContext<UploadContext>(UploadContext);
  const { classes } = useStyles();
  const { uploads } = uploadContext;

  useEffect(() => {
    // const total = Object.keys(uploads).length;
    const uploading = Object.keys(uploads).filter(k => uploads[k].status === 'Uploading');
    const pending = Object.keys(uploads).filter(k => uploads[k].status === 'Pending');

    if (pending.length && uploading.length < 3) {
      const id = pending[0]; // next id
      const upload = uploads[id];

      uploadContext.setContext({
        key: id,
        state: {
          status: 'Uploading',
        }
      });
      if (upload.file.type.startsWith('image')) {
        const imageRef = ref(storage, `images/${upload.collection}/${id}.jpg`);
        const uploadTask = uploadBytesResumable(imageRef, upload.file);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            uploadContext.setContext({
              key: id,
              state: {
                progress,
              }
            });
          },
          (error) => {
            deleteDoc(doc(collection(database, upload.collection), id))
              .catch(Sentry.captureException);
            Sentry.captureException(error);

            uploadContext.setContext({
              key: id,
              state: {
                status: 'Error',
              }
            });
          },
          () => {
            uploadContext.setContext({
              key: id,
              state: null,
            });

            const photo: guesthouse.PrimaryPhoto = {
              docID: id,
              created: Timestamp.fromDate(new Date()),
              status: 'PROCESSING',
              notificationSent: false,
              filename: upload.file.name,
              height: upload.height,
              width: upload.width,
              aspectRatio: upload.aspectRatio,
              primary: true,
              collection: undefined,
              download_url: undefined,
              large_url: undefined,
              medium_url: undefined,
              thumbnail_url: undefined,
              error: undefined,
              ...getLocationKeys(upload.home),
            };

            if (upload.collection.startsWith('products') && !upload.product.primaryPhoto) {
              photo.collection = 'products';
              const productID = upload.product.docID;

              try {
                updateDoc(doc(collection(database, 'products'), productID), { primaryPhoto: photo });
              } catch (e) {
                Sentry.captureException(`Failed to add product primary photo: ${e.message}`);
              }
            }

            if (upload.collection === 'rooms' && !upload.room.primaryPhoto) {
              photo.collection = 'rooms';
              
              const roomID = upload.room.docID;

              try {
                updateDoc(doc(collection(database, 'rooms'), roomID), {
                  primaryPhoto: convertPhotoToPrimaryPhoto(photo as guesthouse.Photo)
                });
              } catch (e) {
                Sentry.captureException(`Failed to add room primary photo: ${e.message}`);
              }
            }

            if (upload.collection === 'roomsets' && !upload.roomSet.primaryPhoto) {
              photo.collection = 'roomsets';
              
              const roomSetID = upload.roomSet.docID;

              try {
                updateDoc(doc(collection(database, 'roomsets'), roomSetID), {
                  primaryPhoto: convertPhotoToPrimaryPhoto(photo as guesthouse.Photo)
                });
              } catch (e) {
                Sentry.captureException(`Failed to add room set primary photo: ${e.message}`);
              }
            }

            setDoc(doc(collection(database, upload.collection), id), photo, { merge: true })
              .catch(Sentry.captureException);
          }
        );
      }
      if (upload.file.type === 'application/pdf') {
        const pdfRef = ref(storage, `images/${upload.collection}/${id}.jpg`);
        const uploadTask = uploadBytesResumable(pdfRef, upload.file);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            uploadContext.setContext({
              key: id,
              state: {
                progress,
              }
            });
          },
          (error) => {
            deleteDoc(doc(collection(database, upload.collection), id))
              .catch(Sentry.captureException);
            Sentry.captureException(error);

            uploadContext.setContext({
              key: id,
              state: {
                status: 'Error',
              }
            });
          },
          () => {
            uploadContext.setContext({
              key: id,
              state: null,
            });

            getDownloadURL(uploadTask.snapshot.ref)
              .then((url) => {
                const pdf: Partial<guesthouse.Moodboard> = {
                  docID: id,
                  created: Timestamp.fromDate(new Date()),
                  status: 'COMPLETE',
                  download_url: url,
                  notificationSent: false,
                  filename: upload.file.name,
                  home: upload.home,
                  roomSet: upload.roomSet,
                  ...getLocationKeys(upload.home),
                };

                setDoc(doc(collection(database, upload.collection), id), pdf, { merge: true })
                  .catch(Sentry.captureException);

              });

          }
        );
      }
    }
  }, [uploads]);

  if (!Object.keys(uploads).length) {
    return null;
  }

  return (
    <Paper
      className={classes.container}
      elevation={10}
    >
      <List className={classes.list}>
        {Object.keys(uploads).map(k => {
          const upload = uploads[k];

          return (
            <ListItem key={k}>
              <ListItemAvatar>
                <Avatar
                  src={upload.preview?.toString()}
                  style={{ borderRadius: 4 }}
                />
              </ListItemAvatar>

              <ListItemText
                primary={upload.file.name}
                secondary={upload.status}
              />

              <ListItemSecondaryAction>
                {upload.status !== 'Error' && (
                  <CircularProgress
                    variant="determinate"
                    value={upload.progress}
                    size={24}
                    thickness={4}
                  />
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

export default UploadList;
