import { GridProps } from '@mui/material/Grid';

export type renderActionProps = {
  room: guesthouse.Room; 
  home: guesthouse.HomeLite;
  index?: number;
}

type Props = {
    allowAdd?: boolean;
    home: guesthouse.HomeLite;
    gridItemProps?: GridProps;
    gridProps?: GridProps;
    cardStyle?: React.CSSProperties;
    renderAction?: (props: renderActionProps) => JSX.Element | null | string;
}

const defaultProps: Partial<Props> = {
  allowAdd: true,
  gridProps: {},
  gridItemProps: {},
};

export default Props;
export { defaultProps };
