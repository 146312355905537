import ProductCard from '@components/cards/ProductCard';
import Grid from '@mui/material/Grid';
import React from 'react';

import Props, { defaultProps } from './ProductGrid.props';
import useStyles from './ProductGrid.style';

const ProductGrid: React.FC<Props> = (props: Props) => {
  const { theme, classes } = useStyles();

  return (
    <Grid
      container
      spacing={4}
      style={{
        marginBottom: theme.spacing(props.spacing),
      }}
      {...props.gridProps}
    >
      {props.products.map((product) => {
        if (!product.availableInternal) {
          product.availableInternal = 0;
        }

        if (!product.docID) {
          console.warn('Warning: product.docID was not found but required for key');
        }

        return (
          <Grid
            key={product.docID}
            item
            className={classes.gridItem}
            xs={6}
            sm={6}
            md={4}
            lg={3}
            xl={2}
          >
            <ProductCard
              key={product.docID}
              product={product}
              href={props?.href}
              showMaker={props?.showMaker}
              showLocation={props?.showLocation}
              stagingDate={props?.stagingDate}
              dateNeededFilter={props.dateNeededFilter}
              onClick={props?.onProductClick}
            />
          </Grid>
        );
      })}
      {props.afterGrid && (
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
        >
          {props.afterGrid()}
        </Grid>
      )}
    </Grid>
  );
};

ProductGrid.defaultProps = defaultProps;

export default ProductGrid;
