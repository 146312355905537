import { GridProps } from '@mui/material/Grid';
import { SyntheticEvent } from 'react';

type Props = {
  spacing?: number;
  afterGrid?: () => JSX.Element | null;
  gridItemProps?: GridProps;
  gridProps?: GridProps;
  users?: guesthouse.User[];
  href?: (user: guesthouse.User) => string;
  onUserClick?: (e: SyntheticEvent<HTMLDivElement>, user: guesthouse.User) => void;
}

const defaultProps: Partial<Props> = {
  spacing: 12,
  gridProps: {},
  gridItemProps: {},
  users: [],
};

export default Props;
export { defaultProps };
