import { useMemo } from 'react';

import { highestOffer } from '../components/product-offer/ProductOffer';
import { offerPrice } from '../utils/offerPrice';

export type ProductRequestTotals = {
  originalPrice: number;
  salePrice: number;
  quantity: number;
}

export const calculateProductRequestTotals = (requests: guesthouse.ProductRequest[], controllerRequests?: boolean) : ProductRequestTotals => {
  if (requests && requests.length) {
    return requests.reduce((sum, request) => {

      if (request.inventory?.offer) {
        const { salePrice, originalPrice, } = offerPrice(request.inventory.offer);

        return {
          originalPrice: sum.originalPrice + (controllerRequests ? originalPrice * request.quantity : originalPrice),
          salePrice: sum.salePrice + (controllerRequests ? salePrice * request.quantity : salePrice),
          quantity: sum.quantity + (controllerRequests ? request.quantity : 1),
        };

      // patches old style product request which were created before we remodeled inventory.
      // we should be able to remove this at some point in the future, when those homes are 
      // distant enough in the past.
      //
      } else if (request.product?.offers) {
        const { salePrice, originalPrice } = offerPrice(highestOffer(request.product.offers));

        return {
          originalPrice: sum.originalPrice + (controllerRequests ? originalPrice * request.quantity : originalPrice),
          salePrice: sum.salePrice + (controllerRequests ? salePrice * request.quantity : salePrice),
          quantity: sum.quantity + (controllerRequests ? request.quantity : 1),
        };
      }

      return sum;
    }, {
      originalPrice: 0,
      salePrice: 0,
      quantity: 0,
    });
  }

  return {
    originalPrice: 0,
    salePrice: 0,
    quantity: 0,
  };
};

export default function useProductRequestTotals(requests: guesthouse.ProductRequest[]): ProductRequestTotals {
  return useMemo<ProductRequestTotals>(() => calculateProductRequestTotals(requests), [requests]);
}
