export const DISCOUNT_TYPES = [
  {
    id: 'percent',
    title: 'Percent'
  },
  {
    id: 'fixed',
    title: 'Fixed'
  }
];

export const ORDER_APPLICATION_TYPES = [
  {
    id: 'no_shipping',
    title: 'No Shipping'
  },
  {
    id: 'shipping_only',
    title: 'Shipping Only'
  },
  {
    id: 'global',
    title: 'Global'
  }
];

export const MAX_USES_TYPES = [
  {
    id: 'global',
    title: 'Global'
  },
  {
    id: 'per_user',
    title: 'Per User'
  }
];
