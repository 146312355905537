import UserContext from '@context/UserContext';
import ProductOfferForm from '@forms/ProductOffer';
import ProductOfferMakerForm from '@forms/ProductOfferMaker';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import checkRoles from '@ui/utils/checkRoles';
import React, { useState } from 'react';


type ProductOfferDialogArgs = {
  title?: string;
  userContext: UserContext;
  initialValues?: guesthouse.ProductOffer;
  resolve: (values: guesthouse.ProductOffer) => void;
  reject: (e?: Error | boolean) => void;
}

const ProductOfferDialog: React.FC<ProductOfferDialogArgs> = ({
  resolve,
  reject,
  userContext,
  title = 'Offer Information',
  initialValues
}) => {
  const [open, setOpen] = useState(true);

  const internal = checkRoles(['admin', 'design_manager', 'designer', 'website_manager', 'customer_support'], userContext.roles);

  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          reject();
        }}
      >
        <DialogTitle>
          {title}
        </DialogTitle>

        <DialogContent>
          {
                  internal
                    ? (
                      <ProductOfferForm
                        initialValues={initialValues}
                        submitText="Save"
                        onSubmit={(values) => {
                          resolve(values);
                          setOpen(false);
                        }}
                      />
                    ) : (
                      <ProductOfferMakerForm
                        initialValues={initialValues}
                        submitText="Save"
                        onSubmit={(values) => {
                          resolve(values);
                          setOpen(false);
                        }}
                      />
                    )
                }

        </DialogContent>

        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            size="small"
            onClick={() => {
              setOpen(false);
              reject(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};

export default ProductOfferDialog;
