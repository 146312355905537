import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  fullCenter: {
    minWidth: '100vw',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: theme.typography.fontFamily,
    flexDirection: 'column',
  },
}));
