import makeStyles from '../../utils/makeStyles';

export default makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
    borderBottom: '1px dashed #979797',
    '&:last-of-type': {
      borderBottom: '0px',
    }
  }
}));
