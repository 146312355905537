import Sentry from '@integrations/Sentry';
import { DocumentSnapshot, endAt, getDocs, onSnapshot,orderBy as queryOrderBy,Query, query as firestoreQuery, QuerySnapshot, startAt } from 'firebase/firestore';
import * as geofire from 'geofire-common';
import get from 'lodash.get';
import { useEffect, useState } from 'react';

type GeoLocationConfig = {
  /**
   * Center point in the format [lat, lng]
   */
  center?: number[];
  /**
   * Radius in meters
   */
  radius?: number;
  /**
   * Order by to use for database query
   */
  orderBy?: string;
  /**
   * Path to use when retrieving lat/lng value from document
   */
  geolocPath?: string;
}

type DocumentType = guesthouse.Location | Record<string, guesthouse.Location | any>

function useGeoCollection<T extends DocumentType>(query: Query<T>, config?: GeoLocationConfig, forceChange: any = 0) {
  const { 
    center, 
    radius, 
    orderBy = 'geohash', 
    geolocPath
  } = config;
  const [error, setError] = useState<Error | boolean>(false);
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState<DocumentSnapshot<T>[]>([]);

  useEffect(
    () => {
      if (center && radius) {
        setLoading(true);
        
        const bounds = geofire.geohashQueryBounds(center, radius);
        const promises = [];
  
        for (const b of bounds) {
          const q : Promise<QuerySnapshot<T>> = getDocs(firestoreQuery(query
            ,queryOrderBy(orderBy)
            ,startAt(b[0])
            ,endAt(b[1])));
        
          promises.push(q);
        }
        
        // Collect all the query results together into a single list
        Promise.all<QuerySnapshot>(promises).then((snapshots) => {
          const matchingDocs = [];
        
          for (const snap of snapshots) {            
            for (const doc of snap.docs) {
              const data = doc.data() as T;

              let lat, lng;

              if (geolocPath) {
                const geoloc = get(data, geolocPath);

                [lat, lng] = [geoloc.lat, geoloc.lng].map(Number);
              } else {
                [lat, lng] = [data._geoloc.lat, data._geoloc.lng].map(Number);
              }

              const p1 = [lat, lng];
              const p2 = center.map(Number);
        
              // We have to filter out a few false positives due to GeoHash
              // accuracy, but most will match
              const distanceInKm = geofire.distanceBetween(p1, p2);
              const distanceInM = distanceInKm * 1000;
  
              if (distanceInM <= radius) {
                matchingDocs.push(doc);
              }
            }
          }
        
          return matchingDocs;
        })
          .then(docs => {
            setDocs(docs);
            setLoading(false);
          })
          .catch(err => {
            if (err.message.indexOf('insufficient permissions') > -1) {
              console.error('Insufficient permissions to access collection', query);
            }
            Sentry.captureException(err, {
              extra: {
                query,
              }
            }); 
            setError(err);
            setLoading(false);
          });
      } else {
        setLoading(true);
        const unsubscribe = onSnapshot(query,
          snap => {
            if (snap?.docs?.length) {
              setDocs(snap.docs);
            } else {
              setDocs([]);
            }
            setLoading(false);
          },
          err => {
            if (err.message.indexOf('insufficient permissions') > -1) {
              console.error('Insufficient permissions to access collection', query);
            }
            Sentry.captureException(err, {
              extra: {
                query,
              }
            }); 
            setError(err);
            setLoading(false);
          }
        );
  
        return () => unsubscribe();
      }
    }, [forceChange, JSON.stringify(config)]
  );

  return {
    error,
    loading,
    docs,
  };
}

export default useGeoCollection;
