import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import NoteDialog from '@components/dialogs/NoteDialog';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import DefaultImage from '@images/product-default.png';
import Sentry from '@integrations/Sentry';
import IconDelete from '@mui/icons-material/Delete';
import IconEdit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Nl2br from '@ui/components/nl2br/Nl2br';
import Protected from '@ui/components/protected/Protected';
import UserAvatar from '@ui/components/user-avatar/UserAvatar';
import getDataFromCollection from '@ui/utils/getDataFromCollection';
import toJsDate from '@ui/utils/toJsDate';
import getTimeZone from '@utils/getTimeZone';
import { formatToTimeZone } from 'date-fns-timezone';
import { collection as firestoreCollection, deleteDoc, Query, query, setDoc, where } from 'firebase/firestore';
import React, { useContext } from 'react';

import useStyles from './Notes.style';


type NotesProps = {
  id: string;
  collection: string;
  type?: guesthouse.Note['type'];
  subCollection?: string,
  subCollParentID?: string,
}

const Notes: React.FC<NotesProps> = (props) => {
  const { id, collection, type, subCollection, subCollParentID } = props;
  const { classes, theme } = useStyles();
  const userContext = useContext<UserContext>(UserContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);

  let ref = props.subCollection ? 
    firestoreCollection(database, `${collection}/${id}/${subCollection}/${subCollParentID}/notes`) : 
    firestoreCollection(database, `${collection}/${id}/notes`) as Query;

  if (type) {
    ref = query(ref, where('type', '==', type));
  }

  const { collection: notesCollection, loading } = useCollection(ref);
  const notes = getDataFromCollection(notesCollection) as guesthouse.Note[];
  
  if (loading) {
    return null;
  }

  if (notes?.length) {
    return (
      <>
        {
          notes.map((notesData, i) => {
            return (
              <Box
                key={`${notesData.text}_${notesData.type}`}
                className={classes.noteWrapper}
                display="flex"
                alignItems="flex-end"
              >
                <UserAvatar
                  size={50}
                  user={notesData.owner}
                  fallback={DefaultImage}
                />

                <div className={classes.bubble}>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={classes.bubbleActions}
                  >
                    <Protected allowedRoles={[() => userContext.user.uid === notesData.owner?.docID]}>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => {
                          asyncRender(NoteDialog, { title: 'Update Note', text: notesData.text })
                            .then(async (text) => {
                              const updatedNote = { 
                                owner: notesData?.owner,
                                text,
                                created: notesData?.created,
                                updated: new Date() 
                              };

                              await setDoc(notesCollection.docs[i].ref, updatedNote, { merge: true });
                            })
                            .then(() => notificationContext.setContext({ open: true, message: 'Succesfully updated note' }))
                            .catch((e) => {
                              if (e) {
                                Sentry.captureException(e);
                                notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                              }
                            });
                        }}
                      >
                        <IconEdit />
                      </IconButton>
                    </Protected>

                    <Protected allowedRoles={['admin', 'design_manager', () => userContext.user.uid === notesData.owner?.docID]}>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          asyncRender(ConfirmDialog, {
                            title: 'Are you sure you want to delete this note?',
                            confirmText: 'Yes, Delete',
                          })
                            .then(async () => await deleteDoc(notesCollection.docs[i].ref))
                            .then(() => notificationContext.setContext({ open: true, message: 'Succesfully deleted note' }))
                            .catch((e) => {
                              if (e) {
                                Sentry.captureException(e);
                              }
                            });
                        }}
                      >
                        <IconDelete />
                      </IconButton>
                    </Protected>
                  </Box>

                  <Typography
                    variant="body2"
                    style={{ marginRight: theme.spacing(3), marginBottom: theme.spacing(1) }}
                  >
                    <Nl2br text={notesData.text} />
                  </Typography>

                  <Typography variant="caption1">
                    {notesData.owner && `${notesData.owner.firstname} ${notesData.owner.lastname} | `}

                    {formatToTimeZone(toJsDate(notesData.created), 'MMM D, YYYY, h:mm A (z)', { timeZone: getTimeZone() })}
                  </Typography>
                </div>
              </Box>
            );
          })
        }
      </>
    );
  }

  return null;
};

export default Notes;
