import * as React from 'react';

function SvgIconSearch(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 250 250"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.264 42.264c39.019-39.019 102.271-39.019 141.29 0 35.889 35.888 38.762 92.298 8.618 131.479L238 219.569l-18.429 18.429-45.826-45.827c-39.178 30.145-95.584 27.273-131.48-8.617-39.02-39.019-39.02-102.28 0-141.29zm120.484 16.154c-28.977-26.545-73.994-25.786-102.056 2.276-28.84 28.833-28.84 75.59 0 104.43 28.84 28.835 75.593 28.835 104.432.002 28.835-28.84 28.835-75.6.001-104.433z"
        fill="#000"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgIconSearch;
