import { ParsedUrlQuery } from 'querystring';

const makeNumericFilterValue = (key: string, value: string) : string => {
  if (key === 'availableTotal' || key === 'startDate') {
    return value;
  } else if (value?.toUpperCase().includes(' TO ')) {
    return `${key}:${value}`;
  } else {
    return `${key} ${value}`;
  }
};

const makeNumericFilter = (obj: ParsedUrlQuery): ReadonlyArray<readonly string[]> => {
  const numericFilter = Object.keys(obj)
    .filter((k) => Array.isArray(obj[k]) ? obj[k].length : obj[k])
    .map((k) => {
      const v = obj[k];

      if (Array.isArray(v)) {
        return v.map(i => {
          return makeNumericFilterValue(k, i);
        });
      } else {
        return [makeNumericFilterValue(k, v)];
      }
    });

  return numericFilter;
};

export default makeNumericFilter;
