import React from 'react';

export const defaultAppContext: AppContext = {
  skeleton: false,
  ready: false,
  navOpen: false,
  homeStatusView: 'planning',
  setContext: () => {}
};

export default React.createContext<AppContext>(defaultAppContext);
