import PrimaryImage from '@components/primary-image/PrimaryImage';
import useDocument from '@hooks/useDocument';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Protected from '@ui/components/protected/Protected';
import simpleLocation from '@ui/utils/simpleLocation';
import userFullName from '@ui/utils/userFullName';
import { productPath, userPath } from '@utils/paths';
import properCase from '@utils/properCase';
import { QueryDocumentSnapshot, updateDoc } from 'firebase/firestore';
import React from 'react';
import { Link } from 'react-router-dom';

import RequestedProductActions from './RequestedProductActions';


type RequestedProductRowProps = {
  request: QueryDocumentSnapshot<guesthouse.ProductRequest>
}

const RequestedProductRow : React.FC<RequestedProductRowProps> = ({ request }) => {
  const { doc: inventoryDoc } = useDocument(request.ref.parent.parent.path);

  const requestData = request.data() as guesthouse.ProductRequest;
  const inventoryItem = inventoryDoc?.data() as guesthouse.ProductInventory;

  if (requestData.product) {
    const product = requestData.product;

    if (product) {
      return (
        <TableRow key={requestData.product.docID}>
          <TableCell
            component="th"
            scope="row"
          >
            <PrimaryImage
              item={requestData.product}
              style={{ width: 75, height: 75, borderRadius: 8, display: 'block', margin: 0 }}
            />
          </TableCell>

          <TableCell
            component="th"
            scope="row"
          >
            <Link
              to={productPath(product)}
              style={{ textDecoration: 'none' }}
            >
              {product.title}
            </Link>
          </TableCell>

          <TableCell>
            {requestData.quantity}
          </TableCell>

          <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
            <TableCell>
              {inventoryItem?.warehouse && simpleLocation(inventoryItem.warehouse)}
            </TableCell>
          </Protected>

          <TableCell scope="row">
            {product.owner && (
              <Link
                to={userPath(product.owner)}
                style={{ textDecoration: 'none' }}
              >
                {userFullName(product.owner, true)}
              </Link>
            )}
          </TableCell>

          <Protected
            allowedRoles={['admin', 'designer', 'design_manager']}
            renderUnauthorized={() => {
              return (
                <TableCell align="right">
                  {requestData.status.split('_').map(s => properCase(s)).join(' ')}
                </TableCell>
              );
            }}
          >
            <TableCell align="right">
              <Checkbox
                checked={requestData.pricesheet}
                onChange={(e) => {
                  updateDoc(request.ref, {
                    pricesheet: e.target.checked,
                  });
                }}
              />
            </TableCell>

            <TableCell align="right">
              {requestData.status.split('_').map(s => properCase(s)).join(' ')}
            </TableCell>

            {!requestData.roomSetRequest && 
              <TableCell align="right">
                <RequestedProductActions request={request} />
              </TableCell>
            }
          </Protected>
        </TableRow>
      );
    }
  }

  return null;
};

export default RequestedProductRow;
