import makeStyles from '../../utils/makeStyles';

const DOT_SIZE = 10;
const DOT_SPACING = 5;

export default makeStyles()(() => ({
  dotWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    padding: 20
  },
  dot: {
    width: 0,
    height: 0,
    background: 'rgba(255, 255, 255, .5)',
    borderRadius: '50%',
    transition: 'all .2s',
    position: 'absolute'
  },
  dotMinus4: {
    width: DOT_SIZE / 5,
    height: DOT_SIZE / 5,
    left: `calc(50% - ${(DOT_SIZE + DOT_SPACING / 5) * 4}px)`,
    transform: 'translate(-50%)'
  },
  dotMinus3: {
    width: DOT_SIZE / 2.5,
    height: DOT_SIZE / 2.5,
    left: `calc(50% - ${(DOT_SIZE + DOT_SPACING / 2.5) * 3}px)`,
    transform: 'translate(-50%)'
  },
  dotMinus2: {
    width: DOT_SIZE / 1.5,
    height: DOT_SIZE / 1.5,
    left: `calc(50% - ${(DOT_SIZE + DOT_SPACING / 1.5) * 2}px)`,
    transform: 'translate(-50%)'
  },
  dotMinus1: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    left: `calc(50% - ${DOT_SIZE + DOT_SPACING}px)`,
    transform: 'translate(-50%)'
  },
  dotSelected: {
    background: 'white',
    width: DOT_SIZE,
    height: DOT_SIZE,
    left: '50%',
    transform: 'translate(-50%)'
  },
  dotPlus1: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    left: `calc(50% + ${DOT_SIZE + DOT_SPACING}px)`,
    transform: 'translate(-50%)'
  },
  dotPlus2: {
    width: DOT_SIZE / 1.5,
    height: DOT_SIZE / 1.5,
    left: `calc(50% + ${(DOT_SIZE + DOT_SPACING / 1.5) * 2}px)`,
    transform: 'translate(-50%)'
  },
  dotPlus3: {
    width: DOT_SIZE / 2.5,
    height: DOT_SIZE / 2.5,
    left: `calc(50% + ${(DOT_SIZE + DOT_SPACING / 2.5) * 3}px)`,
    transform: 'translate(-50%)'
  },
  dotPlus4: {
    width: DOT_SIZE / 5,
    height: DOT_SIZE / 5,
    left: `calc(50% + ${(DOT_SIZE + DOT_SPACING / 5) * 4}px)`,
    transform: 'translate(-50%)'
  }
}));
