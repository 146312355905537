
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';


type PublishMakerDialogArgs = {
  resolve: (values: boolean) => void;
  reject: (e?: Error | boolean) => void;
}

const PublishMakerDialog: React.FC<PublishMakerDialogArgs> = ({
  resolve,
  reject,
}) => {
  const [open, setOpen] = useState(true);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        Maker Published
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Would you also like to publish all products for this maker?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => {
            setOpen(false);
            reject(false);
          }}
        >
          No
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            setOpen(false);
            resolve(true);
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PublishMakerDialog;
