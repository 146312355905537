import FormHelperText from '@mui/material/FormHelperText';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import { isError } from '@ui/utils/typescriptHelpers';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React  from 'react';
import * as Yup from 'yup';

const AddCalendarForm = ({ initialValues, onSubmit, error, submitText='Add Calendar', disabled = false }) => {
  const calendarEventSchema = Yup.object().shape({
    title: Yup.string()
      .required('Calendar title is required'),
    googleCalendarId: Yup.string()
      .required('Google calendar id is required'),
  });
  
  return (
    <Formik
      validationSchema={calendarEventSchema}
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <form
            style={{
              width: '100%'
            }}
            onSubmit={handleSubmit}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Field
                fullWidth
                name="title"
                label="Title"
                type="title"
                component={TextField}
                margin="dense"
                variant="outlined"
                data-test="title-field"
              />

              <Field
                fullWidth
                name="googleCalendarId"
                label="Google Calendar ID"
                type="googleCalendarId"
                component={TextField}
                margin="dense"
                variant="outlined"
                data-test="google-calendar-id-field"
              />

            </div>

            {error && (
              <FormHelperText error>
                {isError(error) ? error.message : error}
              </FormHelperText>
            )}

            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length) || disabled}
                aria-label={submitText}
                data-test="add-calendar-submit-button"
                tooltip={makeErrors(errors, touched)?.length && (
                  <TooltipErrors
                    errors={errors}
                    touched={touched}
                  />
                )}
              >
                {submitText}
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(AddCalendarForm);
