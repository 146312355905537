import { useState } from 'react';

export default function useMuiMenuHooks() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () =>  setAnchorEl(null);

  return {
    anchorEl,
    open,
    handleClick,
    handleClose,
  };
}
