import { auth } from '@app/firebase';
import UserContext from '@context/UserContext';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import FormErrors, { makeErrors } from '@ui/components/form-errors/FormErrors';
import IconEyeClosed from '@ui/icons/imaterial/base/Eye Crossed.svg';
import IconEyeOpen from '@ui/icons/imaterial/base/Eye Open.svg';
import { isError } from '@ui/utils/typescriptHelpers';
import { browserLocalPersistence, setPersistence, signInWithEmailAndPassword, UserCredential } from 'firebase/auth';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react';
import * as Yup from 'yup';

type PasswordDialogArgs = {
  title?: string;
  userContext: UserContext;
  resolve: (values: UserCredential) => void;
  reject: (e?: Error | boolean) => void;
}

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required')
    .nullable(),
});

const PasswordDialog: React.FC<PasswordDialogArgs> = ({
  resolve,
  reject,
  title = 'Please enter your password to continue',
  userContext,
}) => {
  const [open, setOpen] = useState(true);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        onClose={() => {
          setOpen(false);
          reject();
        }}
      >
        <DialogTitle>
          {title}
        </DialogTitle>

        <DialogContent>
          <Formik
            validationSchema={PasswordSchema}
            initialValues={{ password: '' }}
            onSubmit={(values) => {
              return setPersistence(auth, browserLocalPersistence)
                .then(() => signInWithEmailAndPassword(auth, userContext.user.email, values.password))
                .then((userCredential) => {
                  setOpen(false);
                  resolve(userCredential);
                })
                .catch((e) => {
                  switch (e.code) {
                    case 'auth/wrong-password':
                      setError('Incorrect password');
                      break;
                    default:
                      setError(e.message);
                      break;
                  }
                });
            }}
          >
            {({
              errors,
              touched,
              isSubmitting,
            }) => {
              return (
                <Form>
                  <Field
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    component={TextField}
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleShowPassword}
                          >
                            {showPassword ? <IconEyeClosed /> : <IconEyeOpen />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />

                  <FormErrors
                    errors={errors}
                    touched={touched}
                  />


                  {error && (
                    <FormHelperText error>
                      {isError(error) ? error.message : error}
                    </FormHelperText>
                  )}

                  <div style={{ paddingTop: 20 }}>
                    <SubmitButton
                      fullWidth
                      isSubmitting={isSubmitting}
                      disabled={Boolean(Object.keys(errors).length)}
                      aria-label="Confirm"
                      tooltip={!!Object.keys(errors).length && (
                      <div style={{ textAlign: 'center' }}>
                        {makeErrors(errors, touched, false).map((e, i) => (
                          <div key={i}>
                            {e}
                          </div>
                        ))}
                      </div>
                      )}
                    >
                      Confirm
                    </SubmitButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            size="small"
            onClick={() => {
              setOpen(false);
              reject(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};

export default PasswordDialog;
