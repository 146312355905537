import IconUserBox from '@icons/IconUserBox';
import SvgGuesthouseLogoIcon from '@images/guesthouse-logo-icon';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

type AvailableForStagingDynamicProps = {
  availableInternal: guesthouse.ProductInventory[],
  availableExternal: guesthouse.ProductInventory[],
  style?: React.CSSProperties;
  className?: string;
}

type AvailableForStagingProps = {
  inventory?: number,
  inventoryInternal?: number,
  style?: React.CSSProperties;
  className?: string;
}

const AvailableForStaging: React.FC<AvailableForStagingProps> = (props) => {
  const {
    inventory,
    inventoryInternal,
    style,
    className
  } = props;
  
  return (
    <Box
      display="flex"
      className={className}
      style={style}
    >
      <Tooltip title={`Maker Stock Quantity: ${inventory}`}>
        <div
          style={{ 
            display: 'flex', 
            alignItems: 'center',
          }}
        >
          <IconUserBox
            style={{
              width: 16,
              height: 'auto',
            }}
          />

          <span
            data-test="Maker Stock Quantity"
            style={{ lineHeight: 1, paddingLeft: 4, fontSize: 12 }}
          >
            {inventory}
          </span>
        </div>
      </Tooltip>

      <span style={{ display: 'inline-flex', paddingRight: 17 }}/>

      <Tooltip title={`Guest House Stock Quantity: ${inventoryInternal}`}>
        <div
          style={{ 
            display: 'flex', 
            alignItems: 'center' 
          }}
        >
          <SvgGuesthouseLogoIcon
            style={{
              width: 13,
              height: 'auto',
            }}
          />

          <span
            data-test="Guest House Stock Quantity"
            style={{ lineHeight: 1, paddingLeft: 4, fontSize: 12 }}
          >
            {inventoryInternal}
          </span>
        </div>
      </Tooltip>
    </Box>
  );
};

AvailableForStaging.defaultProps = {
  inventory: 0,
  inventoryInternal: 0,
};

export const AvailableForStagingDynamic: React.FC<AvailableForStagingDynamicProps> = (props) => {
  const {
    className,
    style,
    availableInternal,
    availableExternal,
  } = props;


  return (
    <AvailableForStaging
      inventory={availableExternal.length}
      inventoryInternal={availableInternal.length}
      className={className}
      style={style}
    />
  );
};

export default AvailableForStaging;
