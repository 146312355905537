import React from 'react';

import useStyles from './FullCenter.style';

type FullCenterProps = {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

const FullCenter : React.FC<FullCenterProps> = (props) => {
  const { className, style, children } = props;
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(classes.fullCenter, className)}
      style={style}
    >
      { children }
    </div>
  );
};

export default FullCenter;
