import { database } from '@app/firebase';
import NotificationContext from '@context/NotificationContext';
import Sentry from '@integrations/Sentry';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Protected from '@ui/components/protected/Protected';
import { useStyles } from '@ui/utils/makeStyles';
import { collection,doc, updateDoc } from 'firebase/firestore';
import React, { useContext } from 'react';

type HomeStatusButtonProps = {
  home: guesthouse.Home;
}

const HomeStatusButton: React.FC<HomeStatusButtonProps> = (props) => {
  const { home } = props;
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const { theme } = useStyles();

  const updateHomeStatus = async (home: guesthouse.Home) => {
    if (home.status !== 'fully_planned') {
      try {
        await updateDoc(doc(collection(database, 'homes'), home.docID), { status: 'fully_planned' });
        notificationContext.setContext({ open: true, message: 'This home has been marked as fully planned.', severity: 'info' });
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  };

  if (home.status !== 'planning') {
    return null;
  }

  return (
    <Protected allowedRoles={['admin']}>
      <Box
        padding={theme.spacing(2,.5, 4, .5)}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          fullWidth
          component="div"
          variant="contained"
          color="primary"
          onClick={async () => {
            await updateHomeStatus(home);
          }}
        >
          Mark as planned
        </Button>
      </Box>
    </Protected>
  );
};

export default HomeStatusButton;
