import { database } from '@app/firebase';
import toJsDate from '@ui/utils/toJsDate';
import { collection, collectionGroup, query, where } from 'firebase/firestore';
import { useMemo } from 'react';

import { DefaultFirestoreConverter } from '../../types/DefaultFirestoreConverter'; 
import useCollection from './useCollection';
import useGeoCollection from './useGeoCollection';

/**
 * Take a list of product requests, and an item of inventory, return the first active product request found
 */
export const checkAvailableInventory = (productRequests: guesthouse.ProductRequest[], scheduleDate: Date, inventory: guesthouse.ProductInventory): guesthouse.ProductRequest | undefined => {
  const conflict = productRequests
    .filter(productRequest => {
      // support old product requests where there is no inventory ID
      if (!productRequest.inventory?.docID) {
        return true;
      }

      if (productRequest.inventory?.docID && productRequest.inventory.docID === inventory.docID) {
        return true;
      }

      return false;
    })
    .find(productRequest => {
      if (productRequest.scheduleDate && (toJsDate(productRequest.scheduleDate) >= scheduleDate)) {
        return true;
      }

      if (!productRequest.pickupDate && !productRequest.home.deinstall_date) {
        return true;
      }

      if (productRequest.pickupDate && (toJsDate(productRequest.pickupDate) >= scheduleDate)) {
        return true;
      }

      if (productRequest.home.deinstall_date && (toJsDate(productRequest.home.deinstall_date) > scheduleDate)) {
        return true;
      }

      return false;
    });

  return conflict;
};

type UseProductAvailabilityReturnValue = {
  availableInternal: guesthouse.ProductInventory[],
  availableExternal: guesthouse.ProductInventory[],
  inventoryInternal: guesthouse.ProductInventory[],
  inventoryExternal: guesthouse.ProductInventory[],
  loading: boolean;
}

export default function useProductAvailability(scheduleDate: Date = new Date(), productId: string, coords: number[], radius: number, includeInTransit?: boolean): UseProductAvailabilityReturnValue {  
  const { docs: inventoryDocs, loading: inventoryDocsLoading } = useGeoCollection<guesthouse.ProductInventory>(
    query<guesthouse.ProductInventory>(
      collection(database, `products/${productId}/inventory`).withConverter(new DefaultFirestoreConverter<guesthouse.ProductInventory>()),
      where('status', includeInTransit ? 'in' : '==', includeInTransit ? ['IN_STOCK', 'IN_TRANSIT'] : 'IN_STOCK')
    ),
    {
      center: coords,
      radius: radius,
      orderBy: 'warehouse.geohash',
      geolocPath: 'warehouse._geoloc'
    },
    productId
  );

  const { collection: productRequestCollection, loading: productRequestDocsLoading } = useCollection<guesthouse.ProductRequest>(
    query<guesthouse.ProductRequest>(
      collectionGroup(database, 'requests').withConverter(new DefaultFirestoreConverter<guesthouse.ProductRequest>()),
      where('product.docID', '==', productId),
      where('productDeinstalled', '==', false)
    ),
    productId
  );

  const inventory: guesthouse.ProductInventory[] = useMemo(() => {
    if (inventoryDocs?.length) {
      return inventoryDocs.map(i => i.data() as guesthouse.ProductInventory);
    }

    return [];
  }, [inventoryDocs, inventoryDocsLoading]);

  const productRequests: guesthouse.ProductRequest[] = useMemo(() => {
    if (productRequestCollection?.docs.length) {
      return productRequestCollection?.docs.map(i => i.data() as guesthouse.ProductRequest);
    }

    return [];
  }, [productRequestCollection?.docs, productRequestDocsLoading]);

  const { availableInternal, availableExternal, inventoryInternal, inventoryExternal } = useMemo(() => {
    const inventoryInternal = inventory.filter(i => i?.warehouse?.guesthouseManaged === true);
    const inventoryExternal = inventory.filter(i => i?.warehouse?.guesthouseManaged !== true);

    const availableInternal = inventoryInternal
      .filter(i => {
        const conflict = checkAvailableInventory(productRequests, scheduleDate, i);

        if (conflict) {
          return false;
        }
        return true;
      });

    const availableExternal = inventoryExternal
      .filter(i => {
        const conflict = checkAvailableInventory(productRequests, scheduleDate, i);

        if (conflict) {
          return false;
        }
        return true;
      });

    return { availableInternal, availableExternal, inventoryInternal, inventoryExternal };
  }, [inventory, productRequests, scheduleDate.toString()]);

  const loading = useMemo(() => {
    if (inventoryDocsLoading || productRequestDocsLoading) {
      return true;
    }
    return false;
  }, [inventoryDocsLoading, productRequestDocsLoading]);

  return { availableInternal, availableExternal, inventoryInternal, inventoryExternal, loading };
}
