import makeStyles from '@ui/utils/makeStyles';

type stylesProps = {
  numberOfUploads: number,
  anchorEl: string,
}

export default makeStyles<stylesProps>()((theme, { numberOfUploads, anchorEl }) => ({
  list: {
    width: 270,
    borderRadius: 10,
  },
  menuItems: {
    fontFamily: theme.gh_vars.circular,
    fontSize: 14,
    fontWeight: 500
  },
  textAreaContainer: {
    margin: theme.spacing(0, 0, 3.9, 0),
    padding: theme.spacing(numberOfUploads > 0 ? 0 : 1.5, .75, 0, .75),
    width: '100%',
    borderTop: numberOfUploads > 0 ? '' : '#DDDDDD 1px solid',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 0, 3, 0),
      padding: theme.spacing(numberOfUploads > 0 ? 0 : 3, 3, 0, 3),
    },
  },
  emojiPicker: {
    position: 'absolute',
    left: 45,
    bottom: 1,
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      left: 70,
      bottom: 39
    },
  },
  filePicker: {
    position: 'absolute',
    left: 10,
    bottom: -2,
    width: 24,
    height: 45,
    display: 'inline-flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      left: 40,
      bottom: 36,
    },
  },
  xBox: {
    backgroundColor: theme.palette.common.black,
    borderRadius: 10,
    color: theme.palette.common.white,
    bottom: 33,
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      bottom: 'unset',
      right: 10,
    }
  },
  sendIconButton: {
    position: 'absolute',
    bottom: 48,
    right: 12,
    height: 36,
    width: 36,
    backgroundColor: '#080808 !important',
    borderRadius: 5,
    display: anchorEl ? 'none' : null,
  }
}));
