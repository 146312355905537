import { database } from '@app/firebase';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useStyles } from '@ui/utils/makeStyles';
import { isError } from '@ui/utils/typescriptHelpers';
import userFullName from '@ui/utils/userFullName';
import { collection, doc, getDoc } from 'firebase/firestore';
import formatCurrency from 'format-currency';
import React, { useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';

type PayoutDialogReturnValue = {
  calculationType: 'percent' | 'fixed';
  calculationAmount: number;
  userPayout: number;
  paymentDescription: string;
  userData: guesthouse.User
}

type PayoutDialogArgs = {
  title?: JSX.Element | string;
  content?: JSX.Element | string;
  cancelText?: string;
  confirmText?: string;
  eligibleForPayout: number;
  calculationType?: 'percent' | 'fixed';
  calculationAmount?: number;
  defaultPaymentDescription?: string;
  userData: guesthouse.User;
  resolve: (value: PayoutDialogReturnValue) => void;
  reject: (e?: Error | boolean) => void;
}

export const disabledMessagePayOut = 'User does not have an active payment account set up.';


const PayoutDialog: React.FC<PayoutDialogArgs> = ({
  resolve,
  reject,
  title = 'Confirm Payment',
  content = '',
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  eligibleForPayout = 0,
  calculationType = 'percent',
  calculationAmount = 0,
  defaultPaymentDescription = '',
  userData,
}) => {
  const { theme } = useStyles();

  const [open, setOpen] = useState(true);
  const [productPayoutPercentage, setProductPayoutPercentage] = useState(calculationType === 'percent' ? calculationAmount : calculationAmount / eligibleForPayout);
  const [userPayout, setUserPayout] = useState(calculationType === 'fixed' ? calculationAmount : calculationAmount * eligibleForPayout);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [paymentDescription, setPaymentDescription] = useState(defaultPaymentDescription);
  const [user, setUser] = useState<guesthouse.User>();

  const percentDifference = useMemo(() => {
    return Number((userPayout / eligibleForPayout) * 100).toFixed(2);
  }, [eligibleForPayout, userPayout]);

  useEffect(() => {
    const fetchUser = async (userData: guesthouse.User) => {
      const userDoc = await getDoc(doc(collection(database, 'users'), userData.docID));

      if (userDoc.exists()) {
        setUser(userDoc.data() as guesthouse.User);
      }
    };

    fetchUser(userData);
  }, [userData]);

  useEffect(() => {
    if (user && !user?.paymentAccountId) {
      setError(new Error('User does not have an active payment account set up.'));
    }

    if (user?.paymentAccountId) {
      setError(undefined);
    }
  }, [user]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        {content}

        <Alert
          icon={<InfoOutlinedIcon
            style={{
              height: 22,
              width: 22,
            }}
          />}
          style={{ margin: theme.spacing(0, 0, 2) }}
        >
          Total eligible for payout:
          {' '}
          <strong>
            {formatCurrency(eligibleForPayout, { format: '%s%v', symbol: '$' })}
          </strong>
        </Alert>

        <FormControl
          fullWidth
          margin="dense"
        >
          <NumberFormat
            fullWidth
            id="amount"
            label="Payout Amount"
            variant="outlined"
            value={userPayout}
            customInput={TextField}
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix="$"
            onValueChange={(e) => {
              const newAmount = Number(e?.floatValue?.toFixed(2));

              setUserPayout(newAmount);
              if (productPayoutPercentage) {
                setProductPayoutPercentage(newAmount / eligibleForPayout);
              }
            }}
          />
        </FormControl>

        <FormControl
          fullWidth
          margin="dense"
        >
          <NumberFormat
            fullWidth
            id="percentage"
            label="Payout Percentage"
            variant="outlined"
            customInput={TextField}
            suffix="%"
            decimalScale={2}
            value={productPayoutPercentage * 100}
            onValueChange={(e) => {
              const newPercentage = e.floatValue / 100;

              setProductPayoutPercentage(newPercentage);
              setUserPayout(eligibleForPayout * newPercentage);
            }}
          />
        </FormControl>

        <FormControl
          fullWidth
          margin="dense"
        >
          <TextField
            multiline
            fullWidth
            id="paymentDescription"
            label="Payment Description"
            rows="2"
            variant="outlined"
            value={paymentDescription}
            onChange={(e) => {
              setPaymentDescription(e.target.value);
            }}
          />
        </FormControl>

        <Alert
          severity="info"
          style={{ margin: theme.spacing(2, 0, 0) }}
        >
          This action will pay
          {' '}
          {userFullName(user)}
          {' '}
          a sum of
          {' '}
          <strong>
            {formatCurrency(userPayout, { format: '%s%v', symbol: '$' })}
            {' '}
            (
            {!isNaN(userPayout) && percentDifference}
            %)
          </strong>
        </Alert>

        <Typography style={{ marginBottom: theme.spacing(3) }}>

        </Typography>

        {error && (
          <FormHelperText error>
            {isError(error) ? error.message : error}
          </FormHelperText>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            setOpen(false);
            reject(false);
          }}
        >
          {cancelText}
        </Button>

        <Button
          size="small"
          variant="contained"
          color="secondary"
          disabled={loading || !userPayout || userPayout <= 0 || !user?.paymentAccountId}
          onClick={() => {
            setLoading(true);
            setError(undefined);
            setOpen(false);
            resolve({
              calculationType,
              calculationAmount,
              userPayout,
              paymentDescription,
              userData: user,
            });
          }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PayoutDialog;
