import makeStyles from '@ui/utils/makeStyles';

export default makeStyles<{ renderSidebar?: boolean }>()((theme, { renderSidebar }) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  photoSectionContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      width: renderSidebar ? '70%' : '100%',
    },
    [theme.breakpoints.up('xl')]: {
      width: renderSidebar ? '75%' : '100%',
    }
  },
  interactionsContainer: {
    backgroundColor: 'black',
    zIndex: 9,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    top: 0,
    padding: '24px 10vw',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      padding: theme.spacing(1),
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  indicatorBar: {
    width: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      width: '40%',
      marginTop: 0,
    },
  },
  photoOuterContainer: {
    overflowX: 'hidden',
    flex: 1,
    position: 'relative',
    width: '100%',
    height: '100%',
    marginBottom: renderSidebar ? 250 : 0,
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
    }
  },
  photoInnerContainer: {
    minWidth: '100%',
    maxHeight: 390,
    minHeight: 300,
    overflow: 'hidden',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
  backChevronContainer: {
    display: 'none',
    zIndex: 10,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 20,
    border: '1px solid #fff',
    '&:disabled': {
      border: '1px solid #808080',
    },
    '&:disabled svg': {
      color: '#808080',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'inline-flex',
      left: -90,
      width: 60,
      height: 60,
    },
  },
  backChevron: {
    marginLeft: 7,
    fill: theme.palette.common.white,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 11,
      fontSize: 30,
      fontWeight: 400,
    },
  },
  forwardChevronContainer: {
    display: 'none',
    zIndex: 10,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 20,
    border: '1px solid #fff',
    '&:disabled': {
      border: '1px solid #808080',
    },
    '&:disabled svg': {
      color: '#808080',
    },
    cursor: 'pointer',
    [theme.breakpoints.up('lg')]: {
      display: 'inline-flex',
      right: -90,
      width: 60,
      height: 60,
    },
  },
  forwardChevron: {
    marginLeft: 2,
    fill: theme.palette.common.white,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 4,
      fontSize: 30,
      fontWeight: 400,
    },
  },
  tagProductsContainer: {
    width: '100%',
    backgroundColor: '#F7F7F7',
    minHeight: 197,
    overflow: 'scroll',
    position: 'absolute',
    borderRadius: '10px 10px 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: 0,
    zIndex: 100,
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      width: '30%',
      minWidth: '30%',
      position: 'static',
      minHeight: '100%',
      overflow: 'scroll',
      borderRadius: 0,
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('xl')]: {
      width: '25%',
      minWidth: '25%',
    }
  },
  tagProductsInner: {
    width: '100%',
    maxHeight: 'calc(100vh - 80px)',
    padding: theme.spacing(0, 3, 0),
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
      maxHeight: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
  },
  infoContainer: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(3),
      justifyContent: 'space-between',
    },
  },
  caretToggle: {
    marginRight: -5,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  caretToggleIcon: {
    transition: '.2s transform',
    transform: 'rotateZ(180deg)'
  },
  caretToggleIconExpanded: {
    transform: 'rotateZ(0deg)'
  },
  tags: {
    width: '100%',
  },
  photoDots: {
    position: 'relative',
    top: renderSidebar ? -230 : 0,
    [theme.breakpoints.up('lg')]: {
      top: 'unset',
    },
  }
}));
