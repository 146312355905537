import * as Yup from 'yup';

export type ProductMakerFormValues = {
  title?: string;
  slug?: string;
  description?: string;
  msrp?: number;
  dibs?: boolean;
  category?: string[];
  category2?: string[];
  category3?: string[];
  color?: string[];
  style?: string[];
  owner?: Partial<guesthouse.User>;
  height?: number;
  length?: number;
  width?: number;
  dimensional_units: 'feet' | 'inches' | 'meters' | 'centimeters';
};

export const initialValues: ProductMakerFormValues = {
  category: [],
  category2: [],
  color: [],
  style: [],
  dimensional_units: 'inches',
};

const ProductSchemaMaker = Yup.object().shape({
  title: Yup.string()
    .required('Title is required'),
  description: Yup.string(),
  dibs: Yup.boolean(),
  msrp: Yup.number()
    .moreThan(0, 'Price must be greater than zero')
    .typeError('Price must be a valid number')
    .required('Price is required'),
  category: Yup.array(Yup.string())
    .min(1, 'At least one category is required')
    .required('At least one category is required'),
  category2: Yup.array(Yup.string())
    .min(1, 'At least one subcategory is required')
    .required('At least one subcategory is required'),
  shippingPolicy: Yup.string()
    .required('Shipping policy is required'),
  returnPolicy: Yup.string()
    .required('Return policy is required'),
  width: Yup.number()
    .min(.01, 'Width cannot be zero')
    .required('Width is required'),
  length: Yup.number()
    .min(.01, 'Length cannot be zero')
    .required('Length is required'),
  height: Yup.number()
    .min(.01, 'Height cannot be zero')
    .required('Height is required'),
  weight: Yup.number()
    .min(1, 'Weight cannot be zero')
    .required('Weight is required'),

});

export default ProductSchemaMaker;
