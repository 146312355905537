import React from 'react';

import LineItem from '../line-item/LineItem';
import LineItemPrice from '../line-item/LineItemPrice';

type StagingLineItemsProps = {
  lineItems?: Partial<HubSpot.LineItem>[];
  addons?: Partial<HubSpot.LineItemProperties>[];
}

const StagingLineItems: React.FC<StagingLineItemsProps> = (props) => {
  const { lineItems, addons } = props;

  return (
    <>
      {lineItems?.length > 0 && lineItems.map((lineItem, i) => {
        if (lineItem.properties.hs_sku === 'STA') {
          return null;
        }

        return (
          <LineItem
            key={i}
            showIcon={false}
            lineItem={lineItem.properties}
            renderButton={() => {
              return (
                <LineItemPrice lineItem={lineItem.properties} />
              );
            }}
          />
        );
      })}

      {addons?.length > 0 && addons.map((addon, i) => {
        return (
          <LineItem
            key={i}
            showIcon={false}
            lineItem={addon}
            renderButton={() => {
              return (
                <LineItemPrice lineItem={addon} />
              );
            }}
          />
        );
      })}
    </>
  );
};

export default StagingLineItems;
