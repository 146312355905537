import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

type NoteDialogArgs = {
  title?: string;
  text?: string;
  resolve: (value?: string) => void;
}

const NoteDialog: React.FC<NoteDialogArgs> = ({ resolve, title = 'Add note', text = '' }) => {
  const [note, setNote] = useState(text);
  const [open, setOpen] = useState(true);

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      onClose={() => {
        resolve();
        setOpen(false);
      }}
    >
      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        <TextField
          multiline
          fullWidth
          value={note}
          placeholder="Write your note..."
          variant="outlined"
          onChange={e => setNote(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button
          size="small"
          onClick={() => {
            resolve();
            setOpen(false);
          }}
        >
          Cancel
        </Button>

        <Button
          size="small"
          onClick={() => {
            resolve(note);
            setOpen(false);
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoteDialog;
