import UserContext from '@context/UserContext';
import TimelineItemForm from '@forms/TimelineItem';
import Sentry from '@integrations/Sentry';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TimelineItemValues } from '@schema/TimelineItemSchema';
import React, { useState } from 'react';


type TimelineItemDialogProps = {
  title: string;
  userContext: UserContext;
  initialValues: TimelineItemValues;
  resolve: (values: TimelineItemValues) => void;
  reject: (e?: Error | boolean) => void;
}

const TimelineItemDialog: React.FC<TimelineItemDialogProps> = (props) => {
  const { title, userContext, initialValues, resolve, reject } = props;
  const [open, setOpen] = useState<boolean>(true);
  const [error, setError] = useState<Error>(null);

  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        disableEnforceFocus
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          {title}
        </DialogTitle>

        <DialogContent>
          <TimelineItemForm
            initialValues={initialValues}
            error={error}
            onSubmit={(values) => {
              try {
                setOpen(false);
                resolve(values);
                return values;
              } catch (e) {
                setError(e.message);
                if (e) {
                  Sentry.captureException(e);
                }
              }
            }}
          />
        </DialogContent>

        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            size="small"
            onClick={() => {
              setOpen(false);
              reject(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};

TimelineItemDialog.defaultProps = {
  title: 'Edit timeline',
};

export default TimelineItemDialog;
