import { functions } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import EmailDialog from '@components/dialogs/EmailDialog';
import NotificationContext from '@context/NotificationContext';
import Sentry from '@integrations/Sentry';
import IconMoreHoriz from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Protected from '@ui/components/protected/Protected';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import React, { useContext, useState } from 'react';

type StagingOrderActionsProps = {
  orderDoc?: QueryDocumentSnapshot<guesthouse.StagingOrder>
  orderData?: guesthouse.StagingOrder;
}

const sendStagingOrderReceipt = httpsCallable(functions, 'http-sendStagingOrderReceipt');

const StagingOrderActions: React.FC<StagingOrderActionsProps> = (props) => {
  let { orderData } = props;
  const { orderDoc } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const notificationContext = useContext<NotificationContext>(NotificationContext);

  if (!orderData) {
    orderData = orderDoc.data() as guesthouse.StagingOrder;
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        edge="end"
        aria-label="actions"
        aria-haspopup="true"
        size="large"
        onClick={handleClick}
      >
        <IconMoreHoriz />
      </IconButton>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <Protected allowedRoles={['admin', 'design_manager', 'customer_support']}>
          <MenuItem
            onClick={() => {
              asyncRender(EmailDialog, {
                title: 'Send order receipt',
                confirmText: 'Yes, Send',
                initialValues: {
                  email: orderData?.user?.email || orderDoc.data()?.user?.email,
                },
                onSubmit: (email) => sendStagingOrderReceipt({ orderID: orderData.docID || orderDoc.id, email })
              })
                .then(() => notificationContext.setContext({ open: true, message: 'Succesfully sent order receipt' }))
                .catch((e) => {
                  if (e) {
                    Sentry.captureException(e);
                    notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                  }
                });

              handleClose();
            }}
          >
            Send Receipt
          </MenuItem>
        </Protected>
      </Menu>
    </div>
  );
};

export default StagingOrderActions;
