import { database } from '@app/firebase';
import UserContext from '@context/UserContext';
import ProductForm, { ProductFormAdditionalValues } from '@forms/Product';
import useRouter from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { initialValues } from '@schema/ProductSchema';
import useCommonStyles from '@styles/common.style';
import generateOffers from '@utils/generateOffers';
import newId from '@utils/newId';
import { productPath } from '@utils/paths';
import { endOfDay } from 'date-fns';
import { collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import React, { useContext, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';


const ProductCreate = () => {
  const { classes: common } = useCommonStyles();
  const router = useRouter();
  const [error, setError] = useState<string | boolean>();
  const userContext = useContext<UserContext>(UserContext);

  const uid = useRef(newId());
  const location = useLocation();
  const duplicateProduct = location.state as guesthouse.Product;

  if (duplicateProduct) {
    delete duplicateProduct.docID;
    delete duplicateProduct.currentHomes;
    delete duplicateProduct.futureHomes;
    delete duplicateProduct.pastHomes;
    delete duplicateProduct.stagedHomes;
    delete duplicateProduct.availability;
    delete duplicateProduct.offers;
    duplicateProduct.featured = false;
    duplicateProduct.inStock = false;
  }

  const productCreationSubmitHandler = async (productData: Partial<guesthouse.Product>, additionalValues: ProductFormAdditionalValues) => {
    const { price, purchasePrice: purchase_price, dropship, arrivalDate: arrival_date, purchaseDate: purchase_date, warehouse, quantity, shippingPricePerOrder, shippingPricePerProduct } = additionalValues;

    setError(false);
    let inferredOwner: guesthouse.User = null;

    if (userContext?.roles?.maker) {
      inferredOwner = userContext.data;
    }

    const product: Partial<guesthouse.Product> = {
      ...productData,
      archived: false,
      published: false,
      docID: uid.current,
      created: Timestamp.fromDate(new Date()),
      owner: (productData.owner || inferredOwner) as guesthouse.User,
      inventory: 0,
      inventoryInternal: 0,
      inventoryTotal: 0,
      available: 0,
      availableInternal: 0,
      availableTotal: 0,
      dibs: false,
    };

    let productCreated = false;

    try {
      await setDoc(doc(collection(database, 'products'), product.docID), product);

      productCreated = true;
    } catch (e) {
      Sentry.captureException(e);
      setError(e.message);
    }

    let offers: guesthouse.ProductOffer[] = [];

    if (productCreated) {
      if (dropship) {
        const newOffers = generateOffers({
          offerTypes: ['dropship', 'internal'],
          offerOptions: {
            price,
            shippingPricePerOrder,
            shippingPricePerProduct,
            soldBy: product.owner,
          }
        });

        offers = offers.concat(newOffers);
      } else {
        const [newOffer] = generateOffers({
          offerTypes: ['internal'],
          offerOptions: {
            price,
            shippingPricePerOrder,
            shippingPricePerProduct,
            soldBy: product.owner,
          }
        });

        offers.push(newOffer);
      }

      for (const offer of offers) {
        try {
          await setDoc(
            doc(
              collection(doc(collection(database, 'products'), product.docID), 'offers'),
              offer.docID
            ),
            offer);
        } catch (e) {
          Sentry.captureException(e);
          setError(e.message);
        }
      }
    }

    const dropshipOffer = offers.find(offer => offer.classification === 'dropship');

    if (dropshipOffer) {
      const productLite = { ...product };

      delete productLite.pastHomes;
      delete productLite.currentHomes;
      delete productLite.futureHomes;

      const dropshipInventory: guesthouse.ProductInventory = {
        docID: newId(),
        status: 'DROPSHIP',
        product: productLite as guesthouse.ProductLite,
        offer: dropshipOffer,
        purchase_price,
        purchase_date,
        created: Timestamp.fromDate(new Date()),
      };

      try {
        await setDoc(
          doc(
            collection(doc(collection(database, 'products'), product.docID),'inventory'),
            dropshipInventory.docID
          ),
          dropshipInventory);
      } catch (e) {
        Sentry.captureException(e);
        setError(e.message);
      }
    }

    const internalOffer = offers.find(offer => offer.classification === 'internal');

    if (internalOffer && quantity > 0) {
      const productLite = { ...product };

      delete productLite.pastHomes;
      delete productLite.currentHomes;
      delete productLite.futureHomes;

      const internalInventory: Partial<guesthouse.ProductInventory> = {
        status: arrival_date <= endOfDay(new Date()) ? 'IN_STOCK' : 'IN_TRANSIT',
        product: productLite as guesthouse.ProductLite,
        offer: internalOffer,
        purchase_price,
        purchase_date,
        warehouse,
        quantity: 1,
        arrival_date,
        created: Timestamp.fromDate(new Date()),
      };

      const internalInventoryItems = Array.from({ length: quantity }, () => ({
        ...internalInventory,
        docID: newId(),
      }));

      if (internalInventoryItems.length) {
        for (const inventory of internalInventoryItems) {
          try {
            await setDoc(
              doc(
                collection(doc(collection(database, 'products'), product.docID), 'inventory'),
                inventory.docID
              ),
              inventory);
          } catch (e) {
            Sentry.captureException(e);
            setError(e.message);
          }
        }
      }
    }

    router.push(productPath(product));
  };

  return (
    <div className={common.contentSpacing}>
      <Typography
        component="h1"
        variant="h3Alt"
      >
        Add Product
      </Typography>

      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <ProductForm
            submitText="Submit"
            initialValues={duplicateProduct ? {
              ...duplicateProduct,
            } :
              {
                ...initialValues,
                owner: userContext.roles.maker ? userContext.data : undefined,
              }}
            error={error}
            onSubmit={productCreationSubmitHandler}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
        >
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(ProductCreate);
