import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  orderContainer: {
    marginBottom: theme.spacing(1),
  },
  orderTitle: {
    marginBottom: theme.spacing(1),
    fontSize: 18,
    marginRight: theme.spacing(2),
  },
  subscriptionItem: {
    display: 'flex',
    justifyContent: 'space-between',
  }
}));
