import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    border: '1px solid #EBEBEB',
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    height: 198,
    backgroundBlendMode: 'darken',
    borderRadius: 5,
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      height: 312,
    }
  },
  image: {
    objectFit: 'cover',
    overflow: 'hidden',
  },
  address: {
    fontSize: 16,
    fontFamily: theme.gh_vars.circular,
    fontWeight: 600,
    color: '#080808',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: theme.spacing(1),
  },
  cityState: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(.75),
    height: 25,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
    fontStyle: 'normal',
    fontFamily: theme.gh_vars.circular,
    fontWeight: 400,
    color: '#666666',
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
      marginTop: theme.spacing(.25),
    },
  },
  installDates: {
    width: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: 19,
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(.75),
    fontSize: 11,
    color: '#080808',
    fontFamily: theme.gh_vars.mabryMono,
  },
}));
