import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  statsContainer: {
    height: 169,
    width: '100%',
    padding: theme.spacing(0, 4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      height: 177,
    }
  },
  totalTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginLeft: 'auto',
    whiteSpace: 'nowrap',
  },
}));
