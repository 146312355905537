import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import PromoCodeDialog from '@components/dialogs/PromoCodeDialog';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import Sentry from '@integrations/Sentry';
import IconMoreHoriz from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import chunkArray from '@ui/utils/chunkArray';
import toJsDate from '@ui/utils/toJsDate';
import multiEditDefault from '@utils/multiEditDefault';
import { DocumentSnapshot, writeBatch } from 'firebase/firestore';
import React, { useContext, useMemo, useState } from 'react';

type PromoActionsBatchProps = {
  promoDocs: DocumentSnapshot<guesthouse.Coupon>[];
}

const PromoActionsBatch: React.FC<PromoActionsBatchProps> = (props) => {
  const { promoDocs } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const userContext = useContext<UserContext>(UserContext);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const multiEditInitialValues = useMemo(() => {
    const initialValues = multiEditDefault(promoDocs.map(promo => promo.data() as guesthouse.Coupon));

    if (initialValues.retail_discount_type === 'percent') {
      initialValues.retail_discount_amount *= 100;
    }

    if (initialValues.staging_discount_type === 'percent') {
      initialValues.staging_discount_amount *= 100;
    }

    if (initialValues.expires) {
      initialValues.expires = toJsDate(initialValues.expires);
    }

    return initialValues;
  }, [promoDocs]);

  return (
    <div>
      <IconButton
        edge="end"
        aria-label="actions"
        aria-haspopup="true"
        size="large"
        onClick={handleClick}
      >
        <IconMoreHoriz />
      </IconButton>

      <Menu
        keepMounted
        id="profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >

        <MenuItem
          onClick={() => {
            asyncRender(PromoCodeDialog, {
              userContext,
              initialValues: multiEditInitialValues,
              title: promoDocs.length > 1 ? 'Edit Promo Codes' : 'Edit Promo Code',
              batch: 'edit',
            })
              .then(async (values) => {
                if (promoDocs.length) {
                  const chunks = chunkArray(promoDocs);

                  for (const chunk of chunks) {
                    const batchUpdate = writeBatch(database);

                    for (const doc of chunk) {
                      if (values.retail_discount_type === 'percent' && values.retail_discount_amount > 1) {
                        values.retail_discount_amount /= 100;
                      }

                      if (values.staging_discount_type === 'percent' && values.staging_discount_amount > 1) {
                        values.staging_discount_amount /= 100;
                      }

                      if (!values.max_uses || values.max_uses <= 0) {
                        values.max_uses = Infinity;
                      }

                      if (values.code) {
                        delete values.code;
                      }

                      if (!values.expires) {
                        values.expires = null;
                      }

                      values.updated = new Date();

                      batchUpdate.update(doc.ref, values);
                    }

                    try {
                      await batchUpdate.commit();
                    } catch (e) {
                      Sentry.captureException(e.message);
                    }
                  }
                }
              })
              .then(() => notificationContext.setContext({ open: true, message: 'Promo\'s updated' }))
              .catch((e) => {
                if (e) {
                  Sentry.captureException(e);
                  notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                }
              });

            handleClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            asyncRender(ConfirmDialog, {
              title: 'Are you sure you want to delete these promo\'s?',
              confirmText: 'Yes, Delete',
            })
              .then(async () => {
                if (promoDocs.length) {
                  const chunks = chunkArray(promoDocs);

                  for (const chunk of chunks) {
                    const batch = writeBatch(database);

                    for (const doc of chunk) {
                      batch.delete(doc.ref);
                    }

                    try {
                      await batch.commit();
                    } catch (e) {
                      Sentry.captureException(e.message);
                    }
                  }
                }
              })
              .then(() => notificationContext.setContext({ open: true, message: 'Promos deleted' }))
              .catch((e) => {
                if (e) {
                  Sentry.captureException(e);
                  notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                }
              });

            handleClose();
          }}
        >
          Remove
        </MenuItem>
      </Menu>
    </div>
  );
};

export default React.memo(PromoActionsBatch);
