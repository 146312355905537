import { database, functions } from '@app/firebase';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import Sentry from '@integrations/Sentry';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import LineItem from '@ui/components/line-item/LineItem';
import StagingLineItems from '@ui/components/staging-quote/StagingLineItems';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import IconAffirm from '@ui/icons/affirm/black_logo-white_bg.svg';
import checkRoles from '@ui/utils/checkRoles';
import getDataFromCollection from '@ui/utils/getDataFromCollection';
import makeStoreUrl from '@ui/utils/makeStoreUrl';
import { useStyles } from '@ui/utils/makeStyles';
import stagingOrderTotals from '@ui/utils/stagingOrderTotals';
import newId from '@utils/newId';
import { collection, doc, query, setDoc, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import formatCurrency from 'format-currency';
import React, { useContext, useEffect, useState } from 'react';
import slugify from 'slugify';

import { DefaultFirestoreConverter } from '../../../types/DefaultFirestoreConverter';

interface MoreServicesTabProps {
  home: guesthouse.Home;
  homeID: string;
}

type GetProductsResponse = HubSpot.Product[];
const getProducts = httpsCallable<guesthouse.functions.GetProductsOptions, GetProductsResponse>(functions, 'http-getProducts');

const MoreServicesTab: React.FC<MoreServicesTabProps> = (props) => {
  const { home, homeID } = props;
  const { theme } = useStyles();
  const { md, lg } = useBreakpoints();

  const userContext = useContext<UserContext>(UserContext);

  const [addons, setAddons] = useState<Partial<HubSpot.LineItemProperties>[]>([]);
  const [addonTotal, setAddonTotal] = useState<number>(0);
  const [availableAddons, setAvailableAddons] = useState<Partial<HubSpot.LineItemProperties>[]>([]);
  const [hsProducts, setHsProducts] = useState<Partial<HubSpot.LineItemProperties>[]>([]);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);

  let ordersQuery = query(collection(database, 'orders').withConverter(new DefaultFirestoreConverter<guesthouse.StagingOrder>())
    ,where('home.docID', '==', homeID)
    ,where('status', '==', 'COMPLETE'));

  if (!checkRoles(['admin', 'design_manager', 'designer', 'customer_support'], userContext.roles)) {
    ordersQuery = query(collection(database, 'orders')
      ,where('home.docID', '==', homeID)
      ,where('status', '==', 'COMPLETE')
      ,where('user.docID', '==', userContext.user.uid));
  }

  const { collection: ordersCollection, loading: loadingOrdersCollection } = useCollection(ordersQuery);
  const orders = getDataFromCollection<guesthouse.StagingOrder>(ordersCollection);

  useEffect(() => {
    setLoadingProducts(true);
    getProducts({ sqft: home.sqft, in_app_checkout: true })
      .then(({ data }) => {
        setHsProducts(data.map(p => p.properties));
        setLoadingProducts(false);
      })
      .catch(Sentry.captureException);
  }, []);

  useEffect(() => {
    const allExistingAddons: Partial<HubSpot.LineItemProperties>[] = orders.reduce((acc, orderData) => {

      acc.push(...orderData.addons);
      acc.push(...(orderData.lineItems?.map(li => li.properties) ?? []));

      return acc;
    }, []);

    const availableAddons = hsProducts.filter(product => !allExistingAddons.find(a => a.hs_sku?.startsWith(product.hs_sku)));

    setAvailableAddons(availableAddons);
  }, [orders, hsProducts]);


  const onAddClick = (e, product: Partial<HubSpot.LineItemProperties>) => {
    e.preventDefault();

    setAddons(addons => [...addons, product]);
  };

  const onRemoveClick = (e, product: Partial<HubSpot.LineItemProperties>) => {
    e.preventDefault();

    setAddons(addons => addons.filter(a => a.hs_sku !== product.hs_sku));
  };

  useEffect(() => {
    const addonTotal = addons.reduce((accumulator, addon) => accumulator + Number(addon.price), 0);

    setAddonTotal(addonTotal);
  }, [addons]);

  if (loadingOrdersCollection || loadingProducts) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress/>
      </div>
    );
  }

  return (
    <>
      {addons?.length > 0 &&
        <div
          style={{
            borderRadius: theme.shape.borderRadius,
            marginBottom: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2),
          }}
        >
          <Typography
            variant="h5Alt"
            style={{
              fontSize: 18,
              marginBottom: theme.spacing(3)
            }}
          >
            Complete your order
          </Typography>

          <StagingLineItems
            addons={addons}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(3)
            }}
          >
            <Typography style={{ color: theme.palette.common.black, fontSize: 16, fontWeight: 600 }}>
              Total due:
            </Typography>
            <Typography style={{ color: theme.palette.common.black, fontSize: 16, fontWeight: 600 }}>
              {formatCurrency(addonTotal, { format: '%s%v', symbol: '$' })}
            </Typography>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: md ? 'row' : 'column',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconAffirm style={{ height: 24, width: 60, marginRight: theme.spacing(2) }} />
              <Typography style={{ color: theme.palette.common.black, fontSize: 14, position: 'relative', top: 7, fontWeight: 600 }}>
                Buy now, pay over time.
              </Typography>
            </div>

            <Button
              data-test="addons-checkout-button"
              variant="contained"
              style={{ backgroundColor: theme.palette.common.black, color: theme.palette.common.white, width: lg ? 366 : md ? 200 : '100%', height: 48, borderRadius: 10, marginTop: md ? 0 : theme.spacing(2) }}
              onClick={async () => {
                const {
                  subtotal,
                  sale,
                  shippingTotal,
                  taxTotal,
                  total,
                } = stagingOrderTotals({}, [], addons);

                const id = newId();
                const order: Partial<guesthouse.StagingOrder> = {
                  addons,
                  home,
                  docID: id,
                  type: 'addon',
                  status: 'PENDING',
                  user: userContext.data,
                  subtotal,
                  sale,
                  shippingTotal,
                  taxTotal,
                  total,
                };

                await setDoc(doc(collection(database, 'orders'), order.docID), order)
                  .then(() => {
                    window.open(makeStoreUrl(`/home-staging/add-on/${order.docID}`), '_blank').focus();
                  });
              }}
            >
              Checkout
            </Button>
          </div>
        </div>
      }

      <div
        style={{
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(3)
        }}
      >
        <div>
          <Typography style={{ fontSize: 18, color: theme.palette.common.black, marginBottom: theme.spacing(3), fontWeight: 600 }}>
            Additional Services
          </Typography>
        </div>
        {!(availableAddons.length > 0) &&
          <div>
            {'Trouble loading Additional Services right now. Please try again later.'}
          </div>
        }
        {availableAddons.length > 0 && availableAddons.map((item, i) => {
          if (item.hs_sku === 'STA') {
            return null;
          }
          return (
            <LineItem
              key={`${item.hs_object_id}:${i}`}
              lineItem={item}
              renderButton={(lineItem) => {
                return addons.find(a => a.hs_sku === lineItem.hs_sku)
                  ? (
                    <Button
                      aria-label={`Remove ${lineItem.name}`}
                      data-test={`remove-${slugify(lineItem.name, { lower: true })}`}
                      variant="outlined"
                      color="secondary"
                      size="xs"
                      style={{ minWidth: 100 }}
                      onClick={(e) => typeof onRemoveClick === 'function' && onRemoveClick(e, lineItem)}
                    >
                      Remove
                    </Button>
                  ) : (
                    <Button
                      aria-label={`Add ${lineItem.name}`}
                      data-test={`add-${slugify(lineItem.name, { lower: true })}`}
                      variant="contained"
                      color="secondary"
                      size="xs"
                      style={{ minWidth: 100 }}
                      onClick={(e) => typeof onAddClick === 'function' && onAddClick(e, lineItem)}
                    >
                      {formatCurrency(lineItem.price, { format: '%s%v', symbol: '$', minFraction: 0, maxFraction: 0 })}
                    </Button>
                  );
              }
                }
            />
          );
        })}
      </div>

    </>
  );
};

export default MoreServicesTab;
