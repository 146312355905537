
import UserContext from '@context/UserContext';
import HomeForm from '@forms/Home';
import Sentry from '@integrations/Sentry';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { HomeFormValues } from '@schema/HomeSchema';
import React, { useState } from 'react';


type HomeDialogArgs = {
  title?: string;
  userContext: UserContext;
  initialValues?: HomeFormValues;
  onSubmit: (values: guesthouse.Home) => Promise<unknown>;
  resolve: (values: HomeFormValues) => void;
  reject: (e?: Error | boolean) => void;
}

const HomeDialog: React.FC<HomeDialogArgs> = ({
  resolve,
  reject,
  userContext,
  title = 'Update listing information',
  onSubmit,
  initialValues
}) => {
  const [open, setOpen] = useState(true);
  const [error, setError] = useState(null);

  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          {title}
        </DialogTitle>

        <DialogContent>
          <HomeForm
            initialValues={initialValues}
            error={error}
            submitText="Save"
            onSubmit={(values) => {
              return onSubmit(values)
                .then((values) => {
                  setOpen(false);
                  return values;
                })
                .then(resolve)
                .catch(e => {
                  setError(e.message);
                  Sentry.captureException(e);
                });
            }}
          />
        </DialogContent>

        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            size="small"
            onClick={() => {
              setOpen(false);
              reject(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};


export default HomeDialog;
