import { database } from '@app/firebase';
import HomeGridSkeleton from '@components/home-grid/HomeGrid.skeleton';
import RoomSetGrid from '@components/roomset-grid/RoomSetGrid';
import SearchResults from '@components/search-results/SearchResults';
import algolia from '@integrations/Algolia';
import Sentry from '@integrations/Sentry';
import IconSearch from '@mui/icons-material/Search';
import { Box, CircularProgress, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import theme from '@ui/theme';
import toJsDate from '@ui/utils/toJsDate';
import createProductRequests from '@utils/createProductRequests';
import makeIndexName from '@utils/makeIndexName';
import makeNumericFilter from '@utils/makeNumericFilter';
import { collection, doc, getDoc,getDocs, query as firestoreQuery, setDoc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react';

import useStyles from './RoomSetSearch.style';

const roomSetIndex = algolia.initIndex(makeIndexName('roomsets'));

type RoomSetSearchAddToRoomProps = {
  home: guesthouse.Home,
  room: guesthouse.Room,
  user: guesthouse.User,
  setAddToRoomSetDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const RoomSetSearchAddToRoom = (props: RoomSetSearchAddToRoomProps) => {
  const { home, room, user, setAddToRoomSetDialogOpen } = props;
  const { classes } = useStyles();
  const [query, setQuery] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const requestAll = async (algoliaRoomSet: guesthouse.AlgoliaRoomSet) => {
    setIsSubmitting(true);
    const roomSetDoc = await getDoc(doc(collection(database, 'roomsets'), algoliaRoomSet.docID));
    const roomSet = roomSetDoc.data() as guesthouse.RoomSet;
    const inventoryDocs = await getDocs(firestoreQuery(collection( database, `roomsets/${algoliaRoomSet.docID}/roomset_inventory`)));
    const moodboardDocs = await getDocs(firestoreQuery(collection(database, `roomsets/${algoliaRoomSet.docID}/moodboards`)));

    const groupByProduct = (inventory: guesthouse.ProductInventory[]) => {
      return inventory.reduce((acc, obj) => {
        const { product } = obj;
  
        acc[product.title] = acc[product.title] || [];
        acc[product.title].push(obj);
        return acc;
      }, {});
    };

    const inventory: guesthouse.ProductInventory[] = inventoryDocs.docs.map(inventory => {
      return inventory.data() as guesthouse.ProductInventory;
    });

    const internalInventory = inventory.filter(inventory => {
      return inventory.warehouse.guesthouseManaged === true;
    });
    const externalInventory = inventory.filter(inventory => {
      return inventory.warehouse.guesthouseManaged === false;
    });

    const internalInventoryGroups = groupByProduct(internalInventory);
    const externalInventoryGroups = groupByProduct(externalInventory);

    const moodboards = moodboardDocs.docs.map(moodboard => {
      return moodboard.data() as guesthouse.Moodboard;
    });

    const scheduledDate = home.install_date ? toJsDate(home.install_date) : new Date();

    const updatedRoomSet: guesthouse.RoomSet = {
      ...roomSet,
      stagedRooms: [
        ...(roomSet?.stagedRooms || []),
        room.docID
      ]
    };
    
    try {
      for (const product of Object.keys(internalInventoryGroups)) {
        const selectedInventory = internalInventoryGroups[product];

        await createProductRequests('internal', 'roomset', home, room, selectedInventory, scheduledDate, user);
      }

      for (const product of Object.keys(externalInventoryGroups)) {
        const selectedInventory = externalInventoryGroups[product];

        await createProductRequests('external', 'roomset', home, room, selectedInventory, scheduledDate, user);
      }
      
      await updateDoc(doc(collection(database, 'roomsets'), roomSet.docID), { ...updatedRoomSet });

      for (const moodboard of moodboards) {
        await setDoc(doc(collection(database, `homes/${home?.docID}/moodboards`), moodboard.docID), moodboard, { merge: true });
      }
    } catch (e) {
      Sentry.captureException(e);
    }

    setIsSubmitting(false);
  };

  const numericFilters = makeNumericFilter({

  }) as unknown as (string | string[])[];
  
  if (home.install_date) {
    const requiredAvailabilityDate = toJsDate(home.install_date)?.getTime() / 1000;

    numericFilters.push([`availabilityStart_timestamp < ${requiredAvailabilityDate}`]);
  } else {
    const requiredAvailabilityDate = new Date().getTime() / 1000;

    numericFilters.push([`availabilityStart_timestamp < ${requiredAvailabilityDate}`]);
  }

  return (
    <>
      <Box margin={theme.spacing(2, 0, 0, 0)}>
        <TextField
          fullWidth
          className={classes.searchField}
          placeholder="Search room sets"
          variant="outlined"
          margin="dense"
          disabled={isSubmitting}
          InputProps={{
            className: classes.searchFieldInput,
            startAdornment: (
              <InputAdornment position="start">
                <IconSearch className={classes.searchFieldIcon} />
              </InputAdornment>
            ),
          }}
          value={query}
          data-test="room-set-search-text-field"
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />      
        {isSubmitting ?
          <div className={classes.loadingIndicatorContainer}>
            <CircularProgress />
          </div>
          :
          <SearchResults
            index={roomSetIndex}
            query={query}
            numericFilters={numericFilters}
            renderResults={({ hits: results }) => {
              if (!results?.length) {
                return null;
              }

              return (
                <RoomSetGrid
                  spacing={4}
                  roomSets={results as guesthouse.AlgoliaRoomSet[]}
                  onRoomsetClick={async (_, roomSet) => {
                    setQuery('');

                    await requestAll(roomSet);
                    setAddToRoomSetDialogOpen(false);
                  }}
                />

              );
            }}
            renderNoResults={() => {
              return (
                <>
                  <div style={{ marginBottom: theme.spacing(4) }}>
                    <Typography>
                      No room sets matched your search
                    </Typography>
                  </div>
                </>
              );
            }}
            renderLoading={() => {
              return (
                <HomeGridSkeleton />
              );
            }}
          />
          }
      </Box>
    </>
  );
};

export default RoomSetSearchAddToRoom;
