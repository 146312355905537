import { database } from '@app/firebase';
import RoomForm from '@forms/Room';
import useDocument from '@hooks/useDocument';
import useRouter from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { initialValues, RoomFormValues } from '@schema/RoomSchema';
import useCommonStyles from '@styles/common.style';
import newId from '@utils/newId';
import { roomPath } from '@utils/paths';
import { collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import React, { useRef, useState } from 'react';


interface RoomCreateParams {
  homeId: string;
}

const RoomCreate = () => {
  const { classes: common } = useCommonStyles();
  const router = useRouter<RoomCreateParams>();
  const [error, setError] = useState<string | boolean>();

  const uid = useRef(newId());

  const { homeId } = router.params;

  const { doc: home, loading } = useDocument(`homes/${homeId}`);

  if (loading || !home) {
    return null;
  }

  const homeData = home.data() as guesthouse.Home;
  const address = homeData.address;

  return (
    <div className={common.contentSpacing}>
      <div>
        <Typography
          gutterBottom
          component="h1"
          variant="h4Alt"
        >
          Add Room
        </Typography>

        {address && (
          <Typography
            gutterBottom
            component="h1"
            variant="h5Alt"
          >
            {address}
          </Typography>
        )}
      </div>

      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <RoomForm
            submitText="Submit"
            error={error}
            initialValues={initialValues}
            homeSlug={homeData.slug || homeId}
            homeID={homeId}
            onSubmit={async (values: RoomFormValues) => {
              setError(false);

              const room: Partial<guesthouse.Room> = {
                docID: uid.current,
                created: Timestamp.fromDate(new Date()),
                home: homeData,
                published: homeData.published,
                ...values,
              };

              return await setDoc(doc(collection(database, `homes/${homeId}/rooms`), room.docID), room)
                .then(() => router.push(roomPath(room.home, room)))
                .catch((e) => {
                  Sentry.captureException(e);
                  setError(e.message);
                });
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
        >
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(RoomCreate);
