import IconPlus from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';
import { useDropzone } from 'react-dropzone';

import Props, { defaultProps } from './ImageUploader.props'; 
import useStyles from './ImageUploader.style';

const ImageUploader: React.FC<Props> = (props) => {
  const { onDrop, buttonText, multiple, style, accept, boxShadow } = props;
  const { classes } = useStyles();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    multiple,
    accept,
  });

  return (
    <Box
      {...getRootProps()}
      className={classes.dropzone}
      style={style}
      boxShadow={boxShadow}
    >
      <input {...getInputProps()} />

      {
        isDragActive
          ? (
            <p>
              Drop &apos;em
            </p> 
          )
          : (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<IconPlus />}
              aria-label={buttonText}
            >
              {buttonText}
            </Button>
          )
      }

      {props.children}
    </Box>
  );
};

ImageUploader.defaultProps = defaultProps;

export default ImageUploader;
