import 'stream-chat-react/dist/css/index.css';

import UserContext from '@context/UserContext';
import Switch from '@mui/material/Switch';
import Protected from '@ui/components/protected/Protected';
import checkRoles from '@ui/utils/checkRoles';
import { useStyles } from '@ui/utils/makeStyles';
import React, { useContext } from 'react';


const MessagesAdminSwitch: React.FC = () => {
  const userContext = useContext(UserContext);
  const { theme } = useStyles();

  if (!checkRoles(['admin'],userContext.roles)) {
    return null;
  }

  return (
    <>
      <Protected allowedRoles={['admin']}>
        <Switch
          checked={userContext.isAdminView}
          value={userContext.isAdminView}
          name="Admin View"
          color="primary"
          sx={{
            position: 'relative',
            left: theme.spacing(2),
          }}
          onChange={() => {
            if (userContext.isAdminView) {
              userContext.setContext({ ...userContext, isAdminView: false });
            } else {
              userContext.setContext({ ...userContext, isAdminView: true });
            }
          }}
        />
      </Protected>
    </>
  );
};

export default MessagesAdminSwitch;
