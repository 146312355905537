import { Channel } from 'stream-chat';

export interface CalculateChatSLAsOutput {
  countMessagesUnresponded: number;
  countMessagesGT8HourOld: number;
  countMessagesGT24HourOld: number;
  countMessagesGT48HourOld: number;
  countMessagesGT72HourOld: number;
}

/**
 * 
 * @returns a psuedo hour value associated with hourly ranges, see file for details
 */
export const caclulateChatAge = (channel: Channel): number => {
  const { data } = channel;
  const now = Date.now();
  const oneHour = 60 * 60 * 1000;
  const eightHours = 8 * oneHour;
  const eightHoursAgo = now - eightHours;
  const twentyFourHours = 24 * oneHour;
  const twentyFourHoursAgo = now - twentyFourHours;
  const fortyEightHours = 2 * twentyFourHours;
  const fortyEightHoursAgo = now - fortyEightHours;
  const seventyTwoHours = 3 * twentyFourHours;
  const seventyTwoHoursAgo = now - seventyTwoHours;
      
  if (data?.last_message_at && typeof data?.last_message_at === 'string') {
    const { last_message_at } = data;
    let messages = [];

    if (channel?.state?.messages?.length) {
      messages = [...channel.state.messages];
    }

    const mostRecentMessage = messages.pop();
    let lastMessageAtMilli: number;

    if (mostRecentMessage?.type === 'system') {
      const mostRecentNonSystemMessage = messages?.pop();

      lastMessageAtMilli = new Date(mostRecentNonSystemMessage.created_at).getTime();
    } else {
      lastMessageAtMilli = new Date(last_message_at as string).getTime();
    }

    // If the last message is more than 72 hours old
    // return a value larger than 72
    if (lastMessageAtMilli < seventyTwoHoursAgo) {
      return 73;
    }

    // If the last message is more than 48 hours old
    // return a value larger than 48
    if (lastMessageAtMilli < fortyEightHoursAgo) {
      return 49;
    }

    // If the last message is more than 24 hours old
    // return a value larger than 24
    if (lastMessageAtMilli < twentyFourHoursAgo) {
      return 25;
    }

    // If the last message is more than 8 hours old
    // return a value larger than 8
    if (lastMessageAtMilli < eightHoursAgo) {
      return 9;
    }
  }
};

export const calculateChatSLAs = (channels: Channel[]): CalculateChatSLAsOutput => {
  const ret: CalculateChatSLAsOutput = {
    countMessagesUnresponded: 0,
    countMessagesGT72HourOld: 0,
    countMessagesGT48HourOld: 0,
    countMessagesGT24HourOld: 0,
    countMessagesGT8HourOld: 0,
  };

  if (channels?.length) {
    ret.countMessagesUnresponded = channels.length;
    const now = Date.now();
    const oneHour = 60 * 60 * 1000;
    const eightHours = 8 * oneHour;
    const eightHoursAgo = now - eightHours;
    const twentyFourHours = 24 * oneHour;
    const twentyFourHoursAgo = now - twentyFourHours;
    const fortyEightHours = 2 * twentyFourHours;
    const fortyEightHoursAgo = now - fortyEightHours;
    const seventyTwoHours = 3 * twentyFourHours;
    const seventyTwoHoursAgo = now - seventyTwoHours;

    
    for (const channel of channels) {
      const { data } = channel;
      
      if (data?.last_message_at && typeof data?.last_message_at === 'string' && channel.type !== 'system') {
        const { last_message_at } = data;
        let messages = [];
    
        if (channel?.state?.messages?.length) {
          messages = [...channel.state.messages];
        }

        const mostRecentMessage = messages.pop();
        let lastMessageAtMilli: number;

        if (mostRecentMessage?.type === 'system') {
          const mostRecentNonSystemMessage = messages?.pop();

          lastMessageAtMilli = new Date(mostRecentNonSystemMessage.created_at).getTime();
        } else {
          lastMessageAtMilli = new Date(last_message_at as string).getTime();
        }

        if (lastMessageAtMilli < seventyTwoHoursAgo) {
          ret.countMessagesGT72HourOld = ret.countMessagesGT72HourOld + 1;
          continue;
        }

        if (lastMessageAtMilli < fortyEightHoursAgo) {
          ret.countMessagesGT48HourOld = ret.countMessagesGT48HourOld + 1;
          continue;
        }

        if (lastMessageAtMilli < twentyFourHoursAgo) {
          ret.countMessagesGT24HourOld = ret.countMessagesGT24HourOld + 1;
          continue;
        }

        if (lastMessageAtMilli < eightHoursAgo) {
          ret.countMessagesGT8HourOld = ret.countMessagesGT8HourOld + 1;
          continue;
        }
      }
    }
  }


  return ret;
};
