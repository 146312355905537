import UserContext from '@context/UserContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { ClickAwayListener } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Protected from '@ui/components/protected/Protected';
import theme from '@ui/theme';
import checkRoles from '@ui/utils/checkRoles';
import stringAvatar from '@ui/utils/stringAvatar';
import userFullName from '@ui/utils/userFullName';
import { userPath } from '@utils/paths';
import React, { useContext, useRef,useState } from 'react';
import { Link } from 'react-router-dom';


type UserMessageCardProps = {
  user: Partial<guesthouse.User>
  handleRemoveChatMember: (user: Partial<guesthouse.User>) => void;
}

const UserMessageCard: React.FC<UserMessageCardProps> = (props) => {
  const { firstname, lastname, company, photoURL } = props.user;
  const { handleRemoveChatMember } = props;
  const userContext = useContext<UserContext>(UserContext);
  const anchorRef = useRef(null);

  const [open, setOpen] = useState<boolean>(false);

  const isPermissiableRole = checkRoles(['admin', 'designer', 'design_manager', 'website_manager', 'customer_support', 'media_manager'], userContext.roles);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: theme.spacing(1.5),
        borderRadius: 12,
        marginRight: 12,
        height: 40
      }}
    >
      <Link
        to={isPermissiableRole && userPath(props.user) || '/messages'}
        style={{
          textDecoration: 'none',
          cursor: !isPermissiableRole && 'default',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {photoURL ?
          <Avatar
            src={photoURL}
            {...stringAvatar(userFullName(props.user))}
            style={{ height: 24, width: 24, marginRight: theme.spacing(1) }}
          />
          : (
            <Avatar
              {...stringAvatar(userFullName(props.user))}
              style={{ height: 24, width: 24, marginRight: theme.spacing(1), fontSize: 12 }}
            />
          )
        }
        <div
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography style={{ fontWeight: 600, fontSize: 12, lineHeight: 1.25, whiteSpace: 'nowrap' }}>
            {firstname + ' ' + lastname}
          </Typography>
          {company &&
            <Typography style={{ fontWeight: 400, fontSize: 10, color: '#666', lineHeight: 1.25, whiteSpace: 'nowrap' }}>
              {company}
            </Typography>
          }
        </div>
      </Link>

      <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
        <IconButton
          ref={anchorRef}
          onClick={() => setOpen(!open)}
        >
          <ArrowDropDownIcon />
        </IconButton>
        <Menu
          open={open}
          anchorEl={anchorRef.current}
          PaperProps={{
            style: {
              height: 48,
              width: 48
            }
          }}
        >
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <MenuItem
              style={{
                padding: 0,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Tooltip
                title="Remove user from chat."
              >
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => handleRemoveChatMember(props.user)}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </MenuItem>
          </ClickAwayListener>
        </Menu>
      </Protected>
    </div>
  );
};

export default UserMessageCard;
