import { auth } from '@app/firebase';
import { database } from '@app/firebase';
import { LinkBehavior } from '@components/link-behavior/LinkBehavior';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import useRouter from '@hooks/useRouter';
import useUserInfo from '@hooks/useUserInfo';
import Sentry from '@integrations/Sentry';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import routes from '@routes';
import Protected from '@ui/components/protected/Protected';
import makeStoreUrl from '@ui/utils/makeStoreUrl';
import { signOut } from 'firebase/auth';
import { collection,doc, updateDoc } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useChatContext } from 'stream-chat-react';

import useStyles from './ProfileMenu.style';

const ProfileMenu = () => {
  const userContext = useContext<UserContext>(UserContext);
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState(null);
  const [regionsOpen, setRegionsOpen] = useState(null);
  const { classes } = useStyles();
  const { client } = useChatContext();
  const notificationContext = useContext<NotificationContext>(NotificationContext);

  const { initials } = useUserInfo();
  const photoURL = userContext.user.photoURL;
  const userRegions = userContext?.regions;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRegionsClick = () => {
    setRegionsOpen(!regionsOpen);
  };

  return (
    <div className={classes.profileMenu}>
      <Button
        aria-controls="profile-menu"
        data-test="profile-menu-button"
        aria-haspopup="true"
        style={{
          padding: 0,
        }}
        onClick={handleClick}
      >
        {
          photoURL
            ? <Avatar src={photoURL} />
            : (
              <Avatar>
                {initials}
              </Avatar>
            )
        }

      </Button>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >

        <List
          component="nav"
          style={{ width: 200 }}
        >
          <ListItem
            button
            dense
            data-test="profile-menu-profile"
            component={LinkBehavior}
            href={routes.profile.path}
            onClick={handleClose}
          >
            <ListItemText primary="Profile" />
          </ListItem>
          {userRegions && userRegions.length > 1 ? (
            <Protected allowedRoles={['admin', 'design_manager', 'designer']}>
              <ListItem
                button
                dense
                data-test="profile-menu-regions-button"
                onClick={handleRegionsClick}
              >
                <ListItemText primary="Regions" />
                {regionsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                unmountOnExit
                in={regionsOpen}
                timeout="auto"
              >
                <List
                  disablePadding
                  component="div"
                  data-test="profile-menu-regions-list"
                >
                  {userRegions.map(region => (
                    <ListItem
                      key={region.docID}
                      button
                      dense
                      data-test={`profile-menu-region-${region.docID}`}
                      className={classes.nested}
                      onClick={async () => {
                        try {
                          await updateDoc(doc(collection(database, 'users'), userContext.user.uid), { activeRegion: region.docID });
                        } catch (e) {
                          Sentry.captureException(e);
                          notificationContext.setContext({ message: 'Failed to set new active region', severity: 'error', open: true });
                        }

                        handleClose();
                        setRegionsOpen(false);
                      }}
                    >
                      <ListItemText primary={region.name} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </Protected>
          ) : null}

          <ListItem
            button
            dense
            data-test="profile-menu-help"
            component="a"
            target="_blank"
            href={makeStoreUrl('/support')}
            onClick={handleClose}
          >
            <ListItemText primary="Get help" />
          </ListItem>

          <ListItem
            button
            dense
            data-test="profile-menu-billing"
            component="a"
            href={('/billing')}
            onClick={handleClose}
          >
            <ListItemText primary="Billing" />
          </ListItem>

          <ListItem
            button
            dense
            data-test="profile-menu-logout"
            onClick={() => {
              // TODO - Create logout route
              signOut(auth).then(() => {
                client.disconnectUser();
                userContext.setContext({});
                router.push(routes.login.path);
              });
              handleClose();
            }}
          >
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Menu>
    </div>
  );
};

export default ProfileMenu;
