import UserContext from '@context/UserContext';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import FormErrors, { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react';
import * as Yup from 'yup';

type EmailDialogArgs = {
  title?: string;
  userContext: UserContext;
  initialValues?: EmailForm;
  saveText?: string;
  cancelText?: string;
  onSubmit: (email: string) => Promise<void>;
  resolve: (email: string) => void;
  reject: (e?: Error | boolean) => void;
}

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email address is required'),
});

const EmailDialog: React.FC<EmailDialogArgs> = ({
  resolve,
  reject,
  saveText,
  cancelText,
  title,
  userContext,
  onSubmit,
  initialValues = { email: '' },
}) => {
  const [open, setOpen] = useState(true);
  const [error, setError] = useState(null);

  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        onClose={() => {
          setOpen(false);
          reject();
        }}
      >
        <DialogTitle>
          {title}
        </DialogTitle>

        <DialogContent>
          <Formik
            validationSchema={EmailSchema}
            initialValues={initialValues}
            onSubmit={(values) => {
              return onSubmit(values.email)
                .then(() => resolve(values.email))
                .then(() => setOpen(false))
                .catch((e) => {
                  setError(e);
                });
            }}
          >
            {({
              errors,
              touched,
              isSubmitting,
            }) => {
              return (
                <Form>
                  <Field
                    fullWidth
                    id="email"
                    name="email"
                    label="Email Address"
                    type="text"
                    component={TextField}
                    margin="dense"
                    variant="outlined"
                  />

                  <FormErrors
                    errors={errors}
                    touched={touched}
                  />


                  {error && (
                    <FormHelperText error>
                      {error}
                    </FormHelperText>
                  )}

                  <div style={{ paddingTop: 20 }}>
                    <SubmitButton
                      fullWidth
                      data-test="product-add-inventory-submit"
                      isSubmitting={isSubmitting}
                      disabled={Boolean(Object.keys(errors).length)}
                      aria-label="Confirm"
                      tooltip={makeErrors(errors, touched)?.length && (
                        <TooltipErrors
                          errors={errors}
                          touched={touched}
                        />
                      )}
                    >
                      {saveText}
                    </SubmitButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            size="small"
            onClick={() => {
              setOpen(false);
              reject(false);
            }}
          >
            {cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};

EmailDialog.defaultProps = {
  title: 'Please enter your email to continue',
  saveText: 'Continue',
  cancelText: 'Cancel',
};

export default EmailDialog;
