import { database } from '@app/firebase';
import UserContext from '@context/UserContext';
import useCollection from '@hooks/useCollection';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import FormErrors, { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import makeOfferLabel from '@utils/makeOfferLabel';
import { collection, DocumentSnapshot } from 'firebase/firestore';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useMemo, useState } from 'react';

import { DefaultFirestoreConverter } from '../../../types/DefaultFirestoreConverter';


type PriceSelectProps = {
  resolve: (values: guesthouse.ProductOffer) => void;
  reject: (e?: Error | boolean) => void;
  saveText?: string;
  cancelText?: string;
  title?: JSX.Element | string;
  content?: React.ReactElement;
  userContext: UserContext;
  offer: DocumentSnapshot<guesthouse.ProductOffer>;
  product: DocumentSnapshot<guesthouse.Product>;
}

export const OfferSelectDialog : React.FC<PriceSelectProps> = (props) => {
  const {
    resolve,
    reject,
    offer,
    product,
    userContext,
    title,
    saveText,
    cancelText,
    content,
  } = props;

  const [open, setOpen] = useState(true);

  const { collection: offersCollection, loading: offersLoading } = useCollection<guesthouse.ProductOffer>(
    collection(database, `products/${product.id}/offers`).withConverter(new DefaultFirestoreConverter<guesthouse.ProductOffer>())
  );

  const offersData = useMemo(() => {
    if (offersCollection?.docs?.length) {
      return offersCollection.docs.filter(p => p.id !== offer.id).map(p => p.data());
    }
    return [];
  }, [offersLoading, offersCollection?.docs?.length]);

  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        onClose={() => {
          setOpen(false);
          reject();
        }}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          {content ? content : null}
          <Formik
            enableReinitialize
            initialValues={{
              offer: null,
            }}
            onSubmit={(values) => {
              resolve(values.offer);
              setOpen(false);
            }}
          >
            {({
              errors,
              touched,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <Form>
                  <Autocomplete
                    fullWidth
                    id="offer"
                    options={offersData}
                    getOptionLabel={makeOfferLabel}
                    renderInput={(params) => {
                      return (
                        <Field
                          {...params}
                          fullWidth
                          name="offer"
                          label="Price"
                          type="text"
                          component={TextField}
                          margin="dense"
                          variant="outlined"
                        />
                      );
                    }}
                    onChange={(e, value) => {
                      setFieldValue('offer', value);
                    }}
                  />

                  <FormErrors
                    errors={errors}
                    touched={touched}
                  />

                  <div style={{ paddingTop: 20 }}>
                    <SubmitButton
                      fullWidth
                      data-test="offer-select-submit"
                      isSubmitting={isSubmitting}
                      disabled={Boolean(Object.keys(errors).length)}
                      aria-label={saveText}
                      tooltip={makeErrors(errors, touched)?.length && (
                        <TooltipErrors
                          errors={errors}
                          touched={touched}
                        />
                      )}
                    >
                      {saveText}
                    </SubmitButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            size="small"
            onClick={() => {
              setOpen(false);
              reject(false);
            }}
          >
            {cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};

OfferSelectDialog.defaultProps = {
  title: 'Select Price',
  cancelText: 'Cancel',
  saveText: 'Confirm',
};
