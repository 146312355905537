import Sentry from '@integrations/Sentry';

const trackingUrl = (carrier: guesthouse.Carrier, trackingNumber = '') => {
  switch (carrier) {
    case 'USPS':
      return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`;

    case 'UPS':
      return `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}`;

    case 'FedEx':
      return `https://www.fedex.com/Tracking?action=track&tracknumbers=${trackingNumber}`;

    case 'DHL':
      return `http://www.dhl.com/en/express/tracking.html?AWB=${trackingNumber}`;

    default:
      Sentry.captureMessage(`Warning: Could not generate tracking URL for ${carrier} / ${trackingNumber}`);
      return '';
  }
};

export default trackingUrl;
