
import * as Yup from 'yup';

export type ProductFormValues = {
  title?: string;
  slug?: string;
  description?: string;
  msrp?: number;
  dibs?: boolean;
  height?: number;
  width?: number;
  length?: number;
  sku?: string;
  category?: string[];
  category2?: string[];
  category3?: string[];
  color?: string[];
  style?: string[];
  owner?: Partial<guesthouse.User>;
  dimensional_units: 'feet' | 'inches' | 'meters' | 'centimeters';
  dimensions_visible?: string;
  price?: number;
  shippingPolicy?: string;
  returnPolicy?: string;
  dropship?: boolean;
  shipping_price_per_order?: number;
  shipping_price_per_product?: number;
  purchase_date?: guesthouse.Date;
  quantity?: number;
  warehouse?: string;
  dropshippable?: boolean;
};

export const initialValues: ProductFormValues = {
  category: [],
  category2: [],
  color: [],
  style: [],
  dimensional_units: 'inches',
  purchase_date: new Date(),
};

const ProductSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required'),
  description: Yup.string(),
  dibs: Yup.boolean(),
  price: Yup.number()
    .moreThan(0, 'Price must be greater than zero')
    .typeError('Price must be a valid number')
    .required('Guest House Price required'),
  category: Yup.array(Yup.string())
    .min(1, 'At least one category is required')
    .required('At least one category is required'),
  category2: Yup.array(Yup.string())
    .min(1, 'At least one subcategory is required')
    .required('At least one subcategory is required'),
  msrp: Yup.number()
    .moreThan(0, 'MSRP must be greater than zero')
    .typeError('MSRP must be a valid number')
    .required('MSRP is required.'),
  warehouse: Yup.string().when('quantity', {
    is: (quantity) => quantity >= 1,
    then: Yup.string().required('When quantity is selected, you must choose a warehouse.'),
    otherwise: Yup.string(),
  })
});

export default ProductSchema;
