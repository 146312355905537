import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useStyles } from '@ui/utils/makeStyles';
import React from 'react';

import Props, { defaultProps } from './PhotoGrid.props';

interface PhotoGridSkeletonProps extends Props {
  numItems?: number;
}

const PhotoGridSkeleton: React.FC<PhotoGridSkeletonProps> = (props: PhotoGridSkeletonProps) => {
  const { gridItemProps, numItems } = props;
  const { theme } = useStyles();

  return (
    <>
      {new Array(numItems).fill(0).map((_, i) => {
        return (
          <Grid
            key={i}
            item
            xs={12}
            md={6}
            lg={4}
            xl={3}
            {...gridItemProps}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height={400}
              style={{ borderRadius: theme.shape.borderRadius }}
            />
          </Grid>
        );
      })}
    </>
  );
};

PhotoGridSkeleton.defaultProps = {
  ...defaultProps,
  numItems: 4,
};

export default PhotoGridSkeleton;
