import AppContext from '@context/AppContext';
import useAlgoliaSearch, { UseAlgoliaSearchOptions } from '@hooks/useAlgoliaSearch';
import algolia from '@integrations/Algolia';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect } from 'react';

const numResults = (r) => r.reduce((a, c) => a + c.hits.length, 0);

const noResults = () => (
  <Typography>
    No results
  </Typography>
);

type SearchResultsProps = UseAlgoliaSearchOptions & {
  renderResults?: (results) => JSX.Element | null;
  renderLoading?: () => JSX.Element | null;
  renderNoResults?: () => JSX.Element | null;
}

const SearchResults: React.FC<SearchResultsProps> = (props) => {
  const {
    index,
    query,
    filters,
    numericFilters,
    facetFilters,
    aroundLatLng,
    aroundRadius,
    page,
    perPage,
    renderResults,
    renderLoading,
    renderNoResults,
  } = props;

  const { loading, error, results } = useAlgoliaSearch({
    index,
    query,
    filters,
    numericFilters,
    facetFilters,
    aroundLatLng,
    aroundRadius,
    page,
    perPage
  });

  const appContext = useContext<AppContext>(AppContext);

  useEffect(() => {
    algolia.clearCache();
  }, []);

  if (loading || appContext.skeleton) {
    return renderLoading();
  }

  if (error) {
    return (
      <div>
        {error.message}
      </div>
    );
  }

  if (!results) {
    return null;
  }

  /**
   *  TODO - this is starting to feel hacky...
   *  possible split into two components SearchResults and SearchResultsMulti
   *  for searching multiple indexes with algolia.multipleQueries()
   * 
   *  TODO (again) - definitely need to refactor this now with requiring ts ignore
   *  need typings for results
   */
  // for single search
  // @ts-ignore
  if (Array.isArray(results.hits) && results.hits.length) {
    return renderResults(results);
  }

  // for multi search
  // @ts-ignore
  if (Array.isArray(results.results) && numResults(results.results)) {
    // @ts-ignore
    return renderResults(results.results);
  }

  return renderNoResults();
};

SearchResults.defaultProps = {
  query: '',
  filters: '',
  page: 0,
  // TODO: revert back after long-term fix to nested data issue in Algolia
  perPage: 20,
  renderNoResults: noResults
};


export default SearchResults;
