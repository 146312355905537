import MoodboardPreview from '@components/moodboards/MoodboardPreview';
import UserContext from '@context/UserContext';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import makeStyles from '@ui/utils/makeStyles';
import userFullName from '@ui/utils/userFullName';
import React, { useState } from 'react';


const useStyles = makeStyles()({
  document: {
    width: '100%',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
  },
});

type MoodboardReviewDialogProps = {
  title: string;
  home: guesthouse.Home;
  moodboard: guesthouse.Moodboard;
  userContext: UserContext;
  resolve: (approved?: boolean, moodboard?: guesthouse.Moodboard) => void;
  reject: (e?: Error | boolean) => void;
}


const MoodboardReviewDialog: React.FC<MoodboardReviewDialogProps> = (props) => {
  const { title, home, moodboard, userContext, resolve } = props;
  const [open, setOpen] = useState<boolean>(true);
  const { classes, theme } = useStyles();

  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        disableEnforceFocus
        fullWidth
        open={open}
        maxWidth="md"
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          {title}
        </DialogTitle>

        <DialogContent
          style={{
            paddingBottom: theme.spacing(3),
          }}
        >
          <Typography
            component="h3"
            variant="h6Alt"
            style={{ fontSize: 14, marginBottom: theme.spacing(1) }}
          >
            Mood Board Overview
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginBottom: theme.spacing(3) }}
          >
            This mood board represents the actual furniture and art that will be installed in your listing. Please contact our designer if you have any feedback.
          </Typography>

          <MoodboardPreview
            key={moodboard.docID}
            className={classes.document}
            moodboard={moodboard}
          />
        </DialogContent>

        <DialogActions style={{ justifyContent: 'center', paddingTop: theme.spacing(3) }}>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => {
              window.open(home.designer ? `mailto:${home.designer.email}` : 'designteam@guesthouseshop.com');
            }}
          >
            Contact
            {home.designer ? ` ${userFullName(home.designer)}` : ''}
          </Button>
          <Button
            fullWidth
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => {
              resolve(true, moodboard);
              setOpen(false);
            }}
          >
            Approve Mood Boards
          </Button>
        </DialogActions>
      </Dialog>
    </UserContext.Provider>
  );
};

MoodboardReviewDialog.defaultProps = {
  title: 'Review mood boards',
};

export default MoodboardReviewDialog;
