import useCenterPoint from '@hooks/useCenterPoint';
import useFacetSearchRoomSets from '@hooks/useFacetSearchRoomSets';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';
import checkRoles from '@ui/utils/checkRoles';
import { emptyInitResults, initPagedResults } from '@utils/algoliaSearch';
import { PagedResults, UseFilterSearchOptions } from '@utils/algoliaSearch';
import { useEffect, useMemo,useRef, useState } from 'react';

import useRouter from './useRouter';

export default function useRoomSetFilterSearch(userContext: UserContext, appContext: AppContext, options: UseFilterSearchOptions<guesthouse.AlgoliaRoomSet> = {
  useRouterQuery: true,
  extraFacetFilters: {},
}) {
  const router = useRouter();
  const [roomSets, setRoomSets] = useState<PagedResults<guesthouse.AlgoliaRoomSet>>(initPagedResults());
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(48);
  const [appliedFilters, setAppliedFilters] = useState(0);
  const [numResults, setNumResults] = useState<number>(undefined);
  const [roomTypesOpen, setRoomTypesOpen] = useState(!!router.query.roomType?.toString());
  const endOfContainerElement = useRef<HTMLDivElement>();
  const [coords, radius] = useCenterPoint();

  const query = options.useRouterQuery
    ? router.query
    : { q: options.queryString };

  const { extraFacetFilters } = options;

  const {
    setQuery,
    results,
    filters,
    startDate,
    setStartDate,
  } = useFacetSearchRoomSets({
    query: router.query,
    initResults: emptyInitResults,
    searchOptions: {
      page: page,
      hitsPerPage: perPage,
      aroundLatLng: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? coords?.join(',') : undefined,
      aroundRadius: checkRoles(['admin', 'design_manager', 'designer'], userContext.roles) ? radius : undefined,
    },
    extraFacetFilters,
    useRouter: options.useRouterQuery,
  });

  const startDateObject = useMemo(() => {
    if (startDate) {
      return new Date(Number(startDate?.toString()) * 1000);
    }
  }, [startDate]);

  const clearFilters = () => {
    Object.keys(filters).map(filter => {
      filters[filter].setter(undefined);
    });
    setStartDate(null);
  };

  useEffect(() => {
    if (!options.useRouterQuery) {
      setQuery(options?.queryString);
    } else {
      setQuery(router.query.q?.toString());
    }
  }, [options.useRouterQuery, router.query?.q, options.queryString]);

  useEffect(() => {
    setNumResults(undefined);
    setPage(0);
  }, [JSON.stringify(query)]);

  useEffect(() => {
    const resultPage = results?.page ?? 0;
    const end = resultPage >= ((results?.nbPages ?? 0) - 1);
    const hits = results?.hits;

    // not actually completed yet, dont update room sets
    if (hits === undefined) {
      return;
    }

    // nbHits gives results from page [n, end], is only equal to the total on n=0
    if (resultPage === 0) {
      setNumResults(results.nbHits);
    }
    setRoomSets((prevState: PagedResults<guesthouse.AlgoliaRoomSet>): PagedResults<guesthouse.AlgoliaRoomSet> => {
      const newPage: PagedResults<guesthouse.AlgoliaRoomSet> = {
        [resultPage]: {
          results: hits,
          status: 'complete',
          end: end,
        }
      };

      // if we're fetching a new set of results, clear the whole results
      return (resultPage === 0)
        ? newPage
        : { ...prevState, ...newPage };
    });
  }, [results]);

  useEffect(() => {
    let tempCount = 0;

    Object.keys(filters).map(filter => {
      filters[filter].values ? tempCount += filters[filter].values?.length : null;
    });
    if (startDate) {
      tempCount += 1;
    }
    setAppliedFilters(tempCount);
  }, [filters, startDate]);

  const getPage = () => {
    if (roomSets[page] && roomSets[page].status === 'loading') {
      return;
    }

    if (roomSets[page] && roomSets[page].end === true) {
      return;
    }

    const nextPage = page + 1;

    setRoomSets(prevState => ({
      ...prevState,
      [nextPage]: {
        status: 'loading',
      },
    }));
    setPage(nextPage);
  };

  useInfiniteScroll(endOfContainerElement, getPage, {
    rootMargin: '400px 0px',
    threshold: 0,
  });

  return {
    startDate,
    setStartDate,
    roomTypesOpen,
    setRoomTypesOpen,
    page,
    setPage,
    perPage,
    setPerPage,
    roomSets,
    setRoomSets,
    filters,
    clearFilters,
    appliedFilters,
    numResults,
    results,
    startDateObject,
    endOfContainerElement,
  };
}
