import { CircularProgress, Typography } from '@mui/material';
import theme from '@ui/theme';
import React from 'react';

interface ItemCountProps {
  numResults: number | undefined;
}

const ItemCount = (props: ItemCountProps) => {
  const { numResults } = props;

  return (
    <div style={{ marginLeft: theme.spacing(2), marginBottom: theme.spacing(.5) }}>
      {
        numResults === undefined
          ?
            <CircularProgress 
              style={{
                height: 20,
                width: 20
              }}
            />
          :
            <Typography
              fontFamily={theme.gh_vars.circular}
              style={{ fontSize: 16, lineHeight: 1.6 }}
            >
              {numResults}
              {' '}
              {`${numResults === 1 ? 'Item' : 'Items'}`}
            </Typography>
      }
    </div>
  );
};

export default ItemCount;
