import { orderProductTotal } from './offerPrice';

export const MAKER_PAYOUT = .7;

const splitPayment = (orderProduct : Pick<guesthouse.OrderProduct, 'offer' | 'quantity' | 'discount'>, payoutPercentage : number = MAKER_PAYOUT) => {
  const PAYOUT = payoutPercentage;
  const COMISSION = 1 - payoutPercentage;

  const { productTotal, shippingTotal } = orderProductTotal(orderProduct);
  const makerPayout = (productTotal * PAYOUT) + shippingTotal;
  const guesthouseComission = productTotal * COMISSION;

  return {
    guesthouseComission: Math.round(guesthouseComission * 100),
    makerPayout: Math.round(makerPayout * 100),
  };
};

export default splitPayment;
