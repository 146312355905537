import Skeleton from '@mui/material/Skeleton';
import React from 'react';

type HomeCardSkeletonProps = {
  className?: string;
  style?: React.CSSProperties;
}

const HomeCardSkeleton: React.FC<HomeCardSkeletonProps> = (props) => {
  const { className, style } = props;

  return (
    <Skeleton
      variant="rectangular"
      width="100%"
      height={276}
      className={className}
      style={style}
    />
  );
};

export default HomeCardSkeleton;
