import UserContext from '@context/UserContext';
import MetadataForm from '@forms/Metadata';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';


const MetadataDialog = ({ resolve, reject, userContext, initialValues }) => {
  const [open, setOpen] = useState(true);

  return (
    <UserContext.Provider value={userContext}>
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        onClose={() => {
          resolve();
          setOpen(false);
        }}
      >
        <DialogTitle>
          Update metadata
        </DialogTitle>
        <DialogContent>
          <MetadataForm
            initialValues={initialValues}
            error={false}
            onSubmit={(metadata) => {
              setOpen(false);
              resolve(metadata);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              reject();
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

    </UserContext.Provider>
  );
};

export default MetadataDialog;
