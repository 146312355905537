import HomeSubscription from '@components/home-subscription/HomeSubscription';
import PrimaryImage from '@components/primary-image/PrimaryImage';
import useDocument from '@hooks/useDocument';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HomeStatusChip from '@ui/components/cards/HomeStatusChip';
import formatDate from '@ui/utils/formatDate';
import shortAddress from '@ui/utils/shortAddress';
import staticMap from '@ui/utils/staticMap';
import { parseAddress } from 'parse-address';
import React, { CSSProperties, useMemo } from 'react';
import { Link } from 'react-router-dom';

import useStyles from './HomeCard.style';

interface HomeCardProps {
  home?: guesthouse.HomeLite;
  imageHeight?: string | number;
  style?: CSSProperties;
  className?: string;
  onClick?: (home: guesthouse.HomeLite) => void;
  installDatesStyle?: CSSProperties;
  href?: (home: guesthouse.HomeLite) => string;
  showSubscription?: boolean;
}

const Card: React.FC<HomeCardProps> = (props) => {
  const {
    className,
    home,
    onClick,
    style,
    installDatesStyle,
    imageHeight,
    showSubscription
  } = props;
  const { classes, cx, theme } = useStyles();

  const { doc: homeDoc } = useDocument(`homes/${home.docID}`);

  const fullHome = useMemo(() => {
    if (homeDoc?.exists()) {
      return homeDoc.data() as guesthouse.Home;
    }
  }, [homeDoc]);

  const parsed = useMemo(() => {
    if (home.address) {
      return parseAddress(home.address?.replace(', USA', ''));
    }
  }, [home]);


  const short = useMemo(() => {
    if (home.address) {
      return shortAddress(home.address);
    }

    return '';
  }, [home]);

  return (
    <div
      className={cx(classes.container, className)}
      style={style}
      onClick={() => typeof onClick === 'function' && onClick(home)}
    >
      <div
        className={classes.imageWrapper}
        tabIndex={0}
        style={{
          height: imageHeight,
        }}
      >
        <PrimaryImage
          item={fullHome}
          fallback={staticMap(home.address)}
          className={classes.image}
        />
        {(home || fullHome).status && (
          <HomeStatusChip 
            status={(home || fullHome).status}
            style={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          />
        )}
      </div>
      <address
        className={classes.address}
        style={{ fontStyle: 'normal' }}
      >
        {short}
        {home.unit && `, Unit ${home.unit}`}
      </address>
      <address className={classes.cityState}>
        {parsed && parsed.city && parsed.state && (
          <>
            {`${parsed.city}, ${parsed.state}`}
          </>
        )}
      </address>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {home.install_date && (
          <Typography
            className={classes.installDates}
            style={installDatesStyle}
            component="span"
          >
            {!home.deinstall_date && (
              `${formatDate(home.install_date, 'MM/dd/yy')} - `
            )}
            {home.deinstall_date && (
              `${formatDate(home.install_date, 'MM/dd/yy')} - ${formatDate(home.deinstall_date, 'MM/dd/yy')}`
            )}
          </Typography>
        )}
      </Box>

      {showSubscription && (
        <HomeSubscription
          direction="ltr"
          style={{ fontSize: 11, marginTop: theme.spacing(1) }}
          home={home}
        />
      )}
    </div>
  );

};

const HomeCard: React.FC<HomeCardProps> = (props) => {
  const {
    className,
    home,
    onClick,
    style,
    installDatesStyle,
    imageHeight,
    href,
    showSubscription
  } = props;

  if (typeof href === 'function') {
    return (
      <Link 
        to={href(home)}
        style={{
          textDecoration: 'none',
          display: 'block',
        }}
      >
        <Card 
          className={className}
          home={home}
          style={style}
          installDatesStyle={installDatesStyle}
          imageHeight={imageHeight}
          showSubscription={showSubscription}
          onClick={onClick}
        />
      </Link>

    );
  }
  return (
    <Card 
      className={className}
      home={home}
      style={style}
      installDatesStyle={installDatesStyle}
      imageHeight={imageHeight}
      onClick={onClick}
    />
  );
};

HomeCard.defaultProps = {
  className: null,
  home: {} as guesthouse.HomeLite,
  onClick: null,
  showSubscription: false,
};

export default HomeCard;
