import UserSearch from '@components/user-search/UserSearch';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useStyles } from '@ui/utils/makeStyles';
import React, { useState } from 'react';

type UserSearchDialogArgs = {
  title?: string;
  filterRoles?: string[];
  resolve: (values?: guesthouse.User) => void;
  reject: (e?: Error | boolean) => void;
}

const UserSearchDialog: React.FC<UserSearchDialogArgs> = ({
  resolve,
  reject,
  title = 'Search for a user',
  filterRoles = undefined
}) => {
  const [user, setUser] = useState<guesthouse.User>();
  const [open, setOpen] = useState(true);
  const { theme } = useStyles();

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      onClose={() => {
        reject(false);
        setOpen(false);
      }}
    >
      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        <UserSearch
          style={{ marginTop: theme.spacing(1) }}
          filterRoles={filterRoles}
          onChange={(_e, user: guesthouse.User) => setUser(user)}
        />
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => {
            reject(false);
            setOpen(false);
          }}
        >
          Cancel
        </Button>

        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            resolve(user);
            setOpen(false);
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserSearchDialog;
