import rgba from 'polished/lib/color/rgba';

import stringToColor from './stringToColor';

export default function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: rgba(stringToColor(name), .7),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
