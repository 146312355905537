import 'stream-chat-react/dist/css/index.css';

import { database } from '@app/firebase';
import useRouter from '@hooks/useRouter';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import XIcon from '@ui/icons/imaterial/base/X.svg';
import theme from '@ui/theme';
import lastMessageTime from '@utils/lastMessageTime';
import { channelPath } from '@utils/paths';
import { collection, doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useMemo, useState } from 'react';
import { ChannelPreviewUIComponentProps } from 'stream-chat-react';

import useStyles from './ChannelListItem.style';
import ChatAvatars from './ChatAvatars';
import useOtherMembers from './useOtherMembers';


const ChannelListItem: React.ComponentType<ChannelPreviewUIComponentProps<GHStreamGenerics>> = (props) => {
  const { active, channel, latestMessage, setActiveChannel, unread, watchers } = props;
  const { md } = useBreakpoints();
  const { classes } = useStyles();
  const [home, setHome] = useState<guesthouse.Home>();
  const router = useRouter();

  const otherMembers = useOtherMembers(channel);


  const onSelectChannel = () => {
    if (setActiveChannel) {
      router.replace(channelPath(channel));
      setActiveChannel(channel, watchers);
    }
  };

  const latestMessageTime = useMemo(() => {
    if (channel.state.last_message_at) {
      return lastMessageTime(channel.state.last_message_at);
    }
  }, [channel.state.last_message_at]);

  useEffect(() => {
    async function getHome(docID) {
      const homeDoc = await getDoc(doc(collection(database, 'homes'), docID));

      if (homeDoc.exists()) {
        setHome(homeDoc.data() as guesthouse.Home);
      }
    }
    if (channel.type === 'home' && channel.data.home_id) {
      getHome(channel.data.home_id);
    }
  }, [channel.type]);

  if (channel.type !== 'home' && channel?.state?.messages.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: theme.spacing(1)
        }}
      >
        <Button
          className={classes.newMessage}
          variant="outlined"
          color="primary"
          onClick={onSelectChannel}
        >
          <Typography style={{ fontFamily: theme.gh_vars.circular, fontSize: 12, fontWeight: 500 }}>
            New message
          </Typography>
          <IconButton
            onClick={() => {
              setActiveChannel(null);
              channel.delete();
            }}
          >
            <XIcon style={{ stroke: '#000' }} />
          </IconButton>
        </Button>
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.spacing(1)
      }}
    >
      {!!unread &&
        <span className={classes.unreadDot} />
      }
      <Button
        variant={md && active ? 'outlined' : 'text'}
        style={{
          width: '100%',
          backgroundColor: !unread ? '#EBEBEB' : theme.palette.common.white,
          padding: theme.spacing(.8),
          border: active ? '1px solid #080808' : '1px solid transparent'
        }}
        onClick={onSelectChannel}
      >
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {otherMembers &&
              <ChatAvatars
                channel={channel}
                home={home}
              />
            }
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', marginLeft: theme.spacing(1) }}>
              <Typography
                className={classes.nameAndText}
                style={{ color: !unread ? '#666' : '#000', lineHeight: 1.25 }}
              >
                {channel.type !== 'home' && otherMembers.map((member, i) => {
                  if (otherMembers.length > 1) {
                    if (i === otherMembers.length - 1) {
                      return (
                        member.user.name.split(' ')[0]
                      );
                    } else if (i === otherMembers.length - 2) {
                      return (
                        member.user.name.split(' ')[0] + ' & '
                      );
                    }
                    return (
                      member.user.name.split(' ')[0] + ', '
                    );
                  } else {
                    return (
                      member.user.name
                    );
                  }
                })
                }
                {channel.type === 'home' && channel.data.name}
              </Typography>
              <Typography
                className={classes.nameAndText}
                style={{ color: !unread ? '#666' : '#000', lineHeight: 1.5, height: 18, }}
              >
                {typeof latestMessage === 'object' && latestMessage?.props?.source && latestMessage.props.source}
                {typeof latestMessage === 'string' && latestMessage.includes('Attachment') && 'Attachment...'}
              </Typography>
            </div>
          </div>

          <Typography
            style={{
              color: !unread ? '#666' : '#000',
              lineHeight: 1.25,
              fontWeight: 400,
              width: 70,
              fontSize: 12,
              fontFamily: theme.gh_vars.circular,
            }}
          >
            {latestMessageTime}
          </Typography>
        </div>
      </Button>
    </div>
  );
};

export default ChannelListItem;
