import { database } from '@app/firebase';
import HomeGrid from '@components/home-grid/HomeGrid';
import HomeGridSkeleton from '@components/home-grid/HomeGrid.skeleton';
import { LinkBehavior } from '@components/link-behavior/LinkBehavior';
import PhotoGrid from '@components/photo-grid/PhotoGrid';
import ProductGrid from '@components/product-grid/ProductGrid';
import ProductGridSkeleton from '@components/product-grid/ProductGrid.skeleton';
import SearchResults from '@components/search-results/SearchResults';
import UserGrid from '@components/user-grid/UserGrid';
import UserGridSkeleton from '@components/user-grid/UserGrid.skeleton';
import UserContext from '@context/UserContext';
import useUserInfo from '@hooks/useUserInfo';
import client from '@integrations/Algolia';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton/';
import Typography from '@mui/material/Typography';
import routes from '@routes';
import useCommonStyles from '@styles/common.style';
import Protected from '@ui/components/protected/Protected';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import isAdminOrDesigner from '@utils/isAdminOrDesigner';
import makeFacetFilters from '@utils/makeFacetFilters';
import makeIndexName from '@utils/makeIndexName';
import { homePath, productPath, userPath } from '@utils/paths';
import { collectionGroup, orderBy, Query,query as firestoreQuery,where } from 'firebase/firestore';
import React, { useContext } from 'react';
import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import useStyles from './Feed.style';

const homes = client.initIndex(makeIndexName('homes'));
const products = client.initIndex(makeIndexName('products'));
const users = client.initIndex(makeIndexName('makers'));

const MakerMessage = () => {
  const { company, firstname } = useUserInfo();
  const { classes, theme } = useStyles();

  return (
    <Paper className={classes.makerMessage}>
      <Typography
        gutterBottom
        component="h1"
        variant="h2"
      >
        Welcome,
        {' '}
        {company ? company : firstname}
        !
      </Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          md={9}
          className={classes.makerMessageText}
        >
          <Box
            display="flex"
            alignItems="center"
            height="100%"
            style={{ paddingRight: theme.spacing(3) }}
          >
            <Typography style={{ color: theme.palette.common.black }}>
              Get started by adding products to your account. This allows our designers to feature you inside our upcoming homes.
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
        >
          <Button
            size="large"
            variant="contained"
            color="secondary"
            component={LinkBehavior}
            href={routes.productCreate.path}
          >
            Get started
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};


const Feed = () => {
  const { classes: common } = useCommonStyles();
  const { theme } = useStyles();
  const userContext = useContext<UserContext>(UserContext);
  const { xl } = useBreakpoints();

  let productsPerPage = 8;
  let homesPerPage = 8;

  if (xl) {
    productsPerPage = 12;
    homesPerPage = 12;
  }

  let documentPhotoPath;
  let photosQuery = firestoreQuery(collectionGroup(database, 'photos')
    ,where('type', 'in', ['home', 'room'])
    ,orderBy('created', 'desc'));

  if (userContext?.roles?.maker) {
    photosQuery = firestoreQuery(collectionGroup(database, 'productTags')
      ,where('product.owner.docID', '==', userContext.user.uid)
      ,orderBy('created', 'desc'));
    documentPhotoPath = 'photo';
  }

  if (userContext?.roles?.realtor) {
    photosQuery = firestoreQuery(photosQuery, where('members', 'array-contains', userContext?.user?.uid));
  }

  const facetFilters = makeFacetFilters({
    archived: 'false',
  });

  const [aroundLatLng, aroundRadius] = useMemo(() => {
    if (userContext?.activeRegion?.centerPoint) {
      return [
        `${userContext.activeRegion?.centerPoint?._geoloc.lat},${userContext.activeRegion?.centerPoint?._geoloc.lng}`,
        userContext.activeRegion?.aroundRadius * 1000
      ];
    }
    return [];
  }, [userContext.activeRegion]);

  // bring in all recent results
  const query = '';

  return (
    <div className={common.contentSpacing}>
      <Protected allowedRoles={['maker']}>
        <MakerMessage />
      </Protected>

      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Box
            display="flex"
            alignItems="flex-end"
          >
            <Typography
              component="h1"
              variant="h3Alt"
            >
              Feed
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
        </Grid>
      </Grid>

      <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
        <SearchResults
          index={products}
          query={query}
          perPage={productsPerPage}
          // @ts-ignore
          facetFilters={facetFilters}
          aroundLatLng={aroundLatLng}
          aroundRadius={aroundRadius}
          renderResults={(r) => {
            const results = r.hits;

            if (!results.length) {
              return null;
            }

            return (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  style={{ marginBottom: theme.spacing(1), position: 'relative', zIndex: 2 }}
                >
                  <Typography
                    component="h1"
                    variant="h5Alt"
                  >
                    Recent Products
                  </Typography>
                </Box>
                <ProductGrid
                  products={results}
                  href={productPath}
                  afterGrid={() => {
                    return (
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        style={{ marginBottom: theme.spacing(1), position: 'relative', zIndex: 2 }}
                      >
                        <Link to={generatePath(routes.products.path)}>
                          See More
                        </Link>
                      </Box>
                    );
                  }}
                />
              </>
            );
          }}
          renderNoResults={() => null}
          renderLoading={() => {
            return (
              <>
                <Skeleton
                  variant="text"
                  width={200}
                  height={30}
                  style={{ marginTop: 0 }}
                />
                <ProductGridSkeleton />
              </>
            );
          }}
        />
      </Protected>

      <SearchResults
        index={homes}
        query={query}
        perPage={homesPerPage}
        aroundLatLng={aroundLatLng}
        aroundRadius={aroundRadius}
        filters={isAdminOrDesigner(userContext) ? '' : `members:${userContext.user.uid}`}
        renderResults={({ hits: results }) => {
          if (!results.length) {
            return null;
          }
          return (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                style={{ marginBottom: theme.spacing(1), position: 'relative', zIndex: 2 }}
              >
                <Typography
                  component="h1"
                  variant="h5Alt"
                >
                  Recent Homes
                </Typography>
              </Box>
              <HomeGrid
                homes={results}
                afterGrid={() => {
                  return (
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      style={{ marginBottom: theme.spacing(1), position: 'relative', zIndex: 2 }}
                    >
                      <Link to={homePath()}>
                        See More
                      </Link>
                    </Box>
                  );
                }}
                href={homePath}
              />
            </>
          );
        }}
        renderNoResults={() => {
          if (userContext?.roles?.maker) {
            return (
              <div style={{ marginBottom: theme.spacing(12) }}>
                <Typography
                  gutterBottom
                  component="h1"
                  variant="h5Alt"
                >
                  Recent Homes
                </Typography>
                <Typography gutterBottom>
                  You&apos;re not in any homes yet. Upload products to be featured.
                </Typography>
              </div>
            );
          }

          if (userContext?.roles?.realtor) {
            return (
              <div style={{ marginBottom: theme.spacing(12) }}>
                <Typography
                  gutterBottom
                  component="h1"
                  variant="h5Alt"
                >
                  Recent Homes
                </Typography>
                <Typography>
                  You&apos;re not a member of any homes yet. We&apos;ll keep you posted!
                </Typography>
              </div>
            );
          }

          return null;
        }}
        renderLoading={() => {
          return (
            <>
              <Skeleton
                variant="text"
                width={200}
                height={30}
                style={{ marginTop: 0 }}
              />
              <HomeGridSkeleton />
            </>
          );
        }}
      />

      <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
        <SearchResults
          index={users}
          query={query}
          perPage={12}
          aroundLatLng={aroundLatLng}
          aroundRadius={aroundRadius}
          renderResults={({ hits: results }) => {
            if (!results.length) {
              return null;
            }
            return (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  style={{ marginBottom: theme.spacing(1), position: 'relative', zIndex: 2 }}
                >
                  <Typography
                    component="h1"
                    variant="h5Alt"
                  >
                    Recent Users
                  </Typography>
                </Box>
                <UserGrid
                  users={results}
                  href={userPath}
                  afterGrid={() => {
                    return (
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        style={{ marginBottom: theme.spacing(1), position: 'relative', zIndex: 2 }}
                      >
                        <Link to={generatePath(routes.userManagement.path)}>
                          See More
                        </Link>
                      </Box>
                    );
                  }}
                />

              </>
            );
          }}
          renderNoResults={() => null}
          renderLoading={() => {
            return (
              <>
                <Skeleton
                  variant="text"
                  width={200}
                  height={30}
                  style={{ marginTop: 0 }}
                />
                <UserGridSkeleton />
              </>
            );
          }}
        />
      </Protected>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        style={{ marginBottom: theme.spacing(1), position: 'relative', zIndex: 2 }}
      >
        <Typography
          component="h1"
          variant="h5Alt"
        >
          Recent Photos
        </Typography>
      </Box>
      <PhotoGrid
        useInfiniteScroll={false}
        query={photosQuery as Query<guesthouse.Photo>}
        perPage={24}
        uploadRoles={[]}
        documentPhotoPath={documentPhotoPath}
        afterGrid={(photos) => {
          if (photos && photos.length) {
            return (
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                style={{ marginBottom: theme.spacing(1), position: 'relative', zIndex: 2 }}
              >
                <Link to={generatePath(routes.photos.path)}>
                  See More
                </Link>
              </Box>
            );
          }

          return null;
        }}
        renderZeroMessage={() => {
          if (userContext?.roles?.maker) {
            return (
              <div style={{ marginBottom: theme.spacing(12) }}>
                <Typography>
                  You&apos;re not in any homes yet. Upload products to be featured.
                </Typography>
              </div>
            );
          }

          return (
            <div style={{ marginBottom: theme.spacing(12) }}>
              <Typography>
                No photos have been uploaded yet, but we&apos;ll let you know as soon as photos are ready!
              </Typography>
            </div>
          );
        }}
      />

    </div>
  );
};

export default React.memo(Feed);
