import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';

type CalendarDialogProps = {
  closeHandler: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  recurrenceSelectedDays: string[];
  setRecurrenceSelectedDays: React.Dispatch<React.SetStateAction<string[]>>;
  recurrenceEndDate: Date;
  setRecurrenceEndDate: React.Dispatch<React.SetStateAction<Date>>;
}

const days = [
  {
    labelName: 'S',
    value: 'SU'
  },
  {
    labelName: 'M',
    value: 'MO'
  },
  {
    labelName: 'T',
    value: 'TU'
  },
  {
    labelName: 'W',
    value: 'WE'
  },
  {
    labelName: 'T',
    value: 'TH'
  },
  {
    labelName: 'F',
    value: 'FR'
  },
  {
    labelName: 'S',
    value: 'SA'
  }
];

const CalendarRecurrenceForm = (props: CalendarDialogProps) => {
  const { closeHandler, setFieldValue, recurrenceSelectedDays, setRecurrenceSelectedDays, recurrenceEndDate, setRecurrenceEndDate } = props;
  
  return (
    <>
      <DialogTitle>
        Custom Reccurence
      </DialogTitle>

      <DialogContent >
        {days.map((day, i) => {
          return (
            <FormControlLabel 
              key={day.value}
              control={<Checkbox 
                onChange={(e) => {
                  const updatedDays = [...recurrenceSelectedDays];
  
                  if (e.target.checked) {
                    updatedDays.push(days[i].value);
  
                  } else {
                    const index = updatedDays.indexOf(days[i].value);
  
                    if (index > -1) { 
                      updatedDays.splice(index, 1);
                    }
                  }
                  setRecurrenceSelectedDays(updatedDays);
                }}
              />}
              label={day.labelName}
            />
          );
        })}

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="End Date"
            value={recurrenceEndDate}
            slotProps={{
              textField: {
                variant: 'outlined',
                margin: 'normal'
              }
            }}
            onChange={setRecurrenceEndDate}
          />
        </LocalizationProvider>
      </DialogContent>


      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: 12
        }}
      >
        <Button 
          variant="contained"
          color="secondary"
          style={{
            width: 140
          }}
          onClick={() => {
            closeHandler();
          }}
        >        
          Cancel
        </Button>

        <Button 
          variant="contained"
          color="primary"
          style={{
            width: 140
          }}
          disabled={!recurrenceEndDate || !recurrenceSelectedDays?.length}
          onClick={() => {
            setFieldValue('recurring_event', true);
            setFieldValue('recurrence_end_date', recurrenceEndDate);
            setFieldValue('recurrence_days', recurrenceSelectedDays);
            closeHandler();
          }}
        >
          <Typography>
            Done
          </Typography>
        </Button>
      </div>
    </>
  );
};

export default React.memo(CalendarRecurrenceForm);
