import Sentry from '@integrations/Sentry';

const getTimeZone = () : string => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    Sentry.captureException(e);
    return 'America/Denver';
  }
};

export default getTimeZone;
