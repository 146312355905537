import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DefaultIcon from '@ui/icons/imaterial/base/Grid 01.svg';
import LayersIcon from '@ui/icons/imaterial/base/Layers.svg';
import TableIcon from '@ui/icons/imaterial/editor/Table 11.svg';
import DoorsIcon from '@ui/icons/imaterial/home/Doors.svg';
import KingBedIcon from '@ui/icons/imaterial/home/King Bed.svg';
import EditIcon from '@ui/icons/imaterial/media/Brush.svg';
import CameraIcon from '@ui/icons/imaterial/media/Camera 01.svg';
import FilmIcon from '@ui/icons/imaterial/media/Film Play.svg';
import MagicStickIcon from '@ui/icons/imaterial/misc/Magic Stick.svg';
import WheelIcon from '@ui/icons/imaterial/summer-and-beach/Wheel.svg';
import TruckIcon from '@ui/icons/imaterial/transportation/Truck.svg';
import React, { useMemo } from 'react';

import useStyles from './LineItem.style';


interface LineItemProps {
  className?: string;
  style?: React.CSSProperties;
  lineItem: Partial<HubSpot.LineItemProperties>;
  renderButton?: (lineItem: Partial<HubSpot.LineItemProperties>) => React.ReactElement;
  showIcon?: boolean;
}

type StagingProduct = {
  sku: string;
  image: React.FC<React.SVGProps<SVGSVGElement>>
}

export const STAGING_PRODUCTS: StagingProduct[] = [
  {
    sku: 'STA',
    image: KingBedIcon,
  },
  {
    sku: 'FLO',
    image: TableIcon
  },
  {
    sku: 'VID',
    image: FilmIcon
  },
  {
    sku: 'MAT',
    image: LayersIcon
  },
  {
    sku: 'DRO',
    image: WheelIcon
  },
  {
    sku: 'PHO',
    image: CameraIcon
  },
  {
    sku: 'TWI',
    image: CameraIcon
  },
  {
    sku: 'TPE',
    image: EditIcon
  },
  {
    sku: 'CLE',
    image: MagicStickIcon
  },
  {
    sku: 'MVR',
    image: TruckIcon
  },
  {
    sku: 'PLUS',
    image: DoorsIcon
  }
];

const LineItem: React.FC<LineItemProps> = (props) => {
  const { classes, cx, theme } = useStyles();
  const { lineItem, renderButton, showIcon, className, style } = props;

  const ItemIcon = useMemo(() => {
    if (!showIcon) {
      return null;
    }

    const Icon = STAGING_PRODUCTS.find(product => lineItem.hs_sku?.startsWith(product.sku))?.image;

    if (!Icon) {
      return DefaultIcon;
    }

    return Icon;
  }, [showIcon, lineItem?.hs_sku]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      className={cx(classes.root, className)}
      style={style}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        style={{ paddingRight: theme.spacing(2) }}
      >
        {
          ItemIcon && (
            <div
              style={{
                backgroundColor: theme.palette.common.white,
                borderRadius: theme.shape.borderRadius,
                height: 48,
                width: 48,
                marginRight: theme.spacing(2),
                padding: 12
              }}
            >
              <ItemIcon style={{ height: 24, width: 24 }} />
            </div>
          )
        }
        <Box
          display="flex"
          flexDirection="column"
        >
          <Typography
            fontFamily={theme.gh_vars.circular}
            style={{
              color: 'black',
              fontSize: 16,
              fontWeight: 500,
              lineHeight: 1.25,
              marginBottom: lineItem.description ? theme.spacing(0.5) : 0,
            }}
          >
            {lineItem.name}
          </Typography>
          <Typography
            fontFamily={theme.gh_vars.circular}
            style={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: 1.25,
              color: '#7b7b7b'
            }}
          >
            {lineItem.description}
          </Typography>
        </Box>
      </Box>
      {
        typeof renderButton === 'function' && (
          <Box>
            {renderButton(lineItem)}
          </Box>
        )
      }
    </Box>
  );
};

LineItem.defaultProps = {
  showIcon: true,
};

export default LineItem;
