import LocationSearch from '@components/location-search/LocationSearch';
import Sentry from '@integrations/Sentry';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import RegionSchema, { initialValues as _initialValues } from '@schema/RegionSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import { isError } from '@ui/utils/typescriptHelpers';
import debouncePromise from '@utils/debouncePromise';
import { checkSlug } from '@utils/scannerNextChar';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import slugify from 'slugify';

const debounceCheckSlug = debouncePromise(checkSlug, 300);

const RegionForm = ({ onSubmit, initialValues = _initialValues, error, submitText = 'Continue' }) => {
  return (
    <Formik
      validationSchema={RegionSchema}
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field
              fullWidth
              name="name"
              label="Name"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
              onChange={async (e) => {
                setFieldValue('name', e.target.value);
                setFieldTouched('name', true);

                const slug = slugify(e.target.value, {
                  replacement: '_',
                  strict: true,
                  lower: true,
                });

                let existingSlugs = [];

                try {
                  existingSlugs = await debounceCheckSlug(slug, 'regions', 'docID');
                } catch (e) {
                  Sentry.captureException(e);
                  return;
                }

                if (existingSlugs.length) {
                  let n = 1;
                  let incrememtedSlug = slug;

                  while (existingSlugs.indexOf(incrememtedSlug) > -1) {
                    incrememtedSlug = `${slug}-${n}`;
                    n += 1;
                  }

                  setFieldValue('docID', incrememtedSlug);
                } else {
                  setFieldValue('docID', slug);
                }
              }}
            />

            <LocationSearch
              margin="normal"
              disabled={isSubmitting}
              label="Center Point"
              defaultValue={initialValues.centerPoint?.address}
              autocompleteRequestOptions={{
                types: ['(cities)'],
                componentRestrictions: {
                  country: 'us'
                }
              }}
              onLocationChange={(location) => {
                setFieldValue('centerPoint', location, true);
              }}
            />

            <Field
              fullWidth
              name="aroundRadius"
              label="Around Radius"
              type="number"
              component={TextField}
              margin="normal"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    km
                  </InputAdornment>
                )
              }}
            />

            {error && (
              <FormHelperText error>
                {isError(error) ? error.message : error}
              </FormHelperText>
            )}
            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length)}
                aria-label={submitText}
                tooltip={makeErrors(errors, touched)?.length && (
                  <TooltipErrors
                    errors={errors}
                    touched={touched}
                  />
                )}
              >
                {submitText}
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(RegionForm);
