import Typography from '@mui/material/Typography';
import React from 'react';

const _404 = () => {
  return (
    <div>
      <Typography
        gutterBottom
        variant="h3Alt"
        component="h1"
      >
        404
      </Typography>

      <Typography>
        Page not found.
      </Typography>
    </div>
  );
};

export default React.memo(_404);
