
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';


type UnpublishMakerDialogArgs = {
  resolve: (values: boolean) => void;
  reject: (e?: Error | boolean) => void;
}

const UnpublishMakerDialog: React.FC<UnpublishMakerDialogArgs> = ({
  resolve,
  reject,
}) => {
  const [open, setOpen] = useState(true);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        Unpublish Maker
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          All products from this maker will be unpublished from the store.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => {
            setOpen(false);
            reject(false);
          }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            setOpen(false);
            resolve(true);
          }}
        >
          Unpublish Maker
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnpublishMakerDialog;
