import CONFIG from '@config';

const makeAppUrl = (url: string) => {
  let protocol = 'https://';

  if (CONFIG.environment === 'local') {
    protocol = 'http://';
  }

  return new URL(url, protocol + CONFIG.hosts.cms).toString();
};

export default makeAppUrl;
