import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Protected from '@ui/components/protected/Protected';
import { useStyles } from '@ui/utils/makeStyles';
import React from 'react';

import Props, { defaultProps } from './UserGrid.props';

interface UserGridSkeletonProps extends Props {
  numItems?: number;
}

const UserGridSkeleton: React.FC<UserGridSkeletonProps> = (props: UserGridSkeletonProps) => {
  const { gridItemProps, gridProps, numItems } = props;
  const { theme } = useStyles();

  return (
    <Grid
      container
      spacing={4}
      style={{ marginBottom: theme.spacing(props.spacing) }}
      {...gridProps}
    >
      {new Array(numItems).fill(0).map((_, i) => (
        <Grid
          key={i}
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          xl={2}
          {...gridItemProps}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Skeleton
              variant="circular"
              width="100%"
              style={{ margin: 0, paddingTop: '100%' }}
            />

            <Skeleton
              variant="rectangular"
              width="75%"
              height={20}
              style={{ marginTop: theme.spacing(2), marginBottom: 0, borderRadius: theme.shape.borderRadius }}
            />

            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              <Skeleton
                variant="rectangular"
                width="65%"
                height={15}
                style={{ marginTop: theme.spacing(1), marginBottom: 0, borderRadius: theme.shape.borderRadius }}
              />
            </Protected>

            <Skeleton
              variant="rectangular"
              width="45%"
              height={7}
              style={{ marginTop: theme.spacing(1), marginBottom: 0, borderRadius: theme.shape.borderRadius }}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

UserGridSkeleton.defaultProps = {
  ...defaultProps,
  numItems: 6,
};

export default UserGridSkeleton;
