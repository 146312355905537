import { database } from '@app/firebase';
import PhotoGrid from '@components/photo-grid/PhotoGrid';
import UserContext from '@context/UserContext';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useCommonStyles from '@styles/common.style';
import { collectionGroup, orderBy,Query, query,where } from 'firebase/firestore';
import React, { useContext } from 'react';

const Photos = () => {
  const { classes: common, theme } = useCommonStyles();
  const userContext = useContext<UserContext>(UserContext);

  let photosQuery = query(collectionGroup(database, 'photos')
    ,where('type', 'in', ['home', 'room'])
    ,orderBy('created', 'desc'));
  let documentPhotoPath = '';

  if (userContext?.roles?.maker) {
    photosQuery = query(collectionGroup(database, 'productTags')
      ,where('product.owner.docID', '==', userContext.user.uid)
      ,orderBy('created', 'desc'));
    documentPhotoPath = 'photo';
  }

  if (userContext?.roles?.realtor) {
    photosQuery = query(photosQuery, where('members', 'array-contains', userContext.user.uid));
  }

  return (
    <div className={common.contentSpacing}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <Box
            display="flex"
            alignItems="flex-end"
          >
            <Typography
              component="h1"
              variant="h3Alt"
            >
              Photos
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
        >
        </Grid>
      </Grid>

      <PhotoGrid
        useInfiniteScroll
        perPage={24}
        query={photosQuery as Query<guesthouse.Photo>}
        uploadRoles={[]}
        documentPhotoPath={documentPhotoPath}
        renderZeroMessage={() => {
          return (
            <Typography>
              Theres nothing here yet, but we&apos; notify you as soon as new photos are uploaded!
            </Typography>
          );
        }}
      />
    </div>
  );
};

export default React.memo(Photos);
