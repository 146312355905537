import { RouterLink } from '@components/link-behavior/LinkBehavior';
import SignupIntroText from '@components/signup-intro-text/SignupIntroText';
import LoginForm from '@forms/Login';
import useRouter from '@hooks/useRouter';
import GuestHouseLogoFull from '@images/guesthouse-logo-full';
import GuestHouseLogoIcon from '@images/guesthouse-logo-icon';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Routes from '@routes';
import routes from '@routes';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import React from 'react';
import { generatePath } from 'react-router';

import useStyles from './Login.style';

const Login = () => {
  const { classes, theme } = useStyles();
  const { md } = useBreakpoints();
  const router = useRouter();

  return (
    <div className={classes.wrapper}>
      <Grid
        container
        spacing={1}
        className={classes.top}
      >
        <Grid
          item
          xs={12}
          md={4}
        >
          {
            md
              ? <GuestHouseLogoFull className={classes.logo} />
              : <GuestHouseLogoIcon className={classes.logo} />
          }

        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          style={{ textAlign: 'right' }}
        >
          {md && (
            <Link
              component={RouterLink}
              to={generatePath(Routes.register.path)}
              className={classes.register}
            >
              <h3
                className={classes.h3}
                data-test="sign-up"
              >
                Sign Up
              </h3>
            </Link>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={12}
          lg={3}
        >
        </Grid>

        <Grid
          item
          xs={12}
          lg={9}
          className={classes.gridForm}
        >
          <Paper className={classes.paper}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={5}
              >
                {md && (
                  <SignupIntroText />
                )}
              </Grid>

              <Grid
                item
                xs={12}
                md={2}
              >
              </Grid>

              <Grid
                item
                xs={12}
                md={5}
              >
                <Typography
                  variant="h3Alt"
                  component="h1"
                  className={classes.formTitle}
                >
                  Log In
                </Typography>

                <LoginForm
                  onLoginSuccess={() => {
                    if (router.location.state?.next) {
                      router.push(router.location.state.next);
                    } else {
                      router.push(routes.feed.path);
                    }
                  }}
                />

                {!md && (
                  <div style={{ paddingTop: 20, textAlign: 'center' }}>
                    <Link
                      component={RouterLink}
                      to={generatePath(Routes.register.path)}
                      className={classes.register}
                    >
                      <h3 className={classes.h3}>
                        Sign Up
                      </h3>
                    </Link>
                  </div>
                )}

                <Typography
                  align="center"
                  style={{ marginTop: theme.spacing(2) }}
                >
                  <Link
                    component={RouterLink}
                    to={Routes.sendLink.path}
                    data-test="send-link"
                  >
                    Send me a link to login
                  </Link>
                </Typography>

                <Typography
                  align="center"
                  style={{ marginTop: theme.spacing(2) }}
                >
                  <Link
                    component={RouterLink}
                    to={Routes.resetPassword.path}
                    data-test="reset-password"
                  >
                    Lost your password?
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(Login);
