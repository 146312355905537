import { database } from '@app/firebase';
import { LinkBehavior } from '@components/link-behavior/LinkBehavior';
import PrimaryImage from '@components/primary-image/PrimaryImage';
import QRCode from '@components/qrcode/QRCode';
import NotificationContext from '@context/NotificationContext';
import useCollection from '@hooks/useCollection';
import useDocument from '@hooks/useDocument';
import GuesthouseLogoFull from '@images/guesthouse-logo-full';
import IconPlus from '@mui/icons-material/Add';
import IconPrint from '@mui/icons-material/Print';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import routes from '@routes';
import ProductOffer from '@ui/components/product-offer/ProductOffer';
import getDataFromCollection from '@ui/utils/getDataFromCollection';
import userFullName from '@ui/utils/userFullName';
import { collectionGroup, query, where } from 'firebase/firestore';
import React, { useContext } from 'react';
import { generatePath } from 'react-router';
import { GlobalStyles } from 'tss-react/GlobalStyles';

import useStyles from './PriceSheet.style';

type RoomProps = {
  homeId: string;
  roomId: string;
  lazyLoadImages?: boolean;
}

const Room: React.FC<RoomProps> = (props: RoomProps) => {
  const { homeId, roomId, lazyLoadImages } = props;
  const { classes } = useStyles();
  const { doc: room } = useDocument(`homes/${homeId}/rooms/${roomId}`);
  const { collection: requests } = useCollection(
    query(collectionGroup(database, 'requests'),
      where('room.docID', '==', roomId),
      where('pricesheet', '==', true)
    )
  );

  if (!room) {
    return null;
  }

  const { title } = room.data();

  if (requests && requests.docs && requests.docs.length) {

    return (
      <div className={classes.compactRoom}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.roomHeader}
        >
          <div>
            <Typography
              gutterBottom
              component="h2"
              variant="h4"
            >
              {title}
            </Typography>
          </div>
        </Box>

        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 50 }}>
                  Product
                </TableCell>

                <TableCell style={{ width: '25%' }}></TableCell>

                <TableCell style={{ width: '25%' }}>
                  Maker
                </TableCell>

                <TableCell style={{ width: '25%' }}>
                  Price
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {
                requests.docs.map((request) => {
                  const requestData = request.data() as guesthouse.ProductRequest;
                  const product = requestData.product;

                  if (!requestData.controller) {
                    return null;
                  }

                  return (
                    <TableRow
                      key={request.id}
                      className={classes.tableRow}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                      >
                        <PrimaryImage
                          item={requestData.product}
                          style={{ width: 50, height: 50, borderRadius: 8, display: 'block', margin: 0 }}
                          size="thumbnail"
                          lazyLoadImages={lazyLoadImages}
                        />
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                      >
                        {product.title}
                        {requestData.quantity > 1 ? (
                          <Typography
                            component="div"
                            color="textSecondary"
                            variant="caption"
                          >
                            Quantity:
                            {' '}
                            {requestData.quantity}
                          </Typography>
                        ) : null}
                      </TableCell>

                      <TableCell scope="row">
                        {userFullName(product.owner, true)}
                      </TableCell>

                      <TableCell scope="row">
                        <ProductOffer
                          style={{ display: 'inline-block' }}
                          offer={requestData.product.offers}
                          showOriginal={false}
                        />
                        {requestData.quantity > 1 ? ' each' : ''}
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>

          </Table>
        </TableContainer>
      </div>
    );

  }
  return null;
};

Room.defaultProps = {
  lazyLoadImages: true,
};

type PriceSheetProps = {
  homeId: string;
  onPrint?: () => void;
  lazyLoadImages?: boolean;
}

const CompactPriceSheet: React.FC<PriceSheetProps> = (props: PriceSheetProps) => {
  const { homeId, onPrint, lazyLoadImages } = props;
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const { classes, theme } = useStyles();
  const { doc: home } = useDocument(`homes/${homeId}`);
  const { collection: roomsCollection, loading: loadingRooms } = useCollection(query(collectionGroup(database, 'rooms'), 
    where('home.docID', '==', homeId)
  ));
  const priceSheetUrl = `/homes/${homeId}/compact-price-sheet?noload`;

  const homeData = home.data() as guesthouse.Home;

  const rooms = getDataFromCollection(roomsCollection) as guesthouse.Room[];

  if (!home || !home.data()) {
    return null;
  }

  if (!loadingRooms && !rooms?.length) {
    return (
      <Box style={{ padding: theme.spacing(4), textAlign: 'center' }}>
        <Typography gutterBottom>
          This home needs at least one room before we can generate a price sheet.
        </Typography>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          component={LinkBehavior}
          href={generatePath(routes.roomCreate.path, { homeId })}
          startIcon={<IconPlus />}
          aria-label="add room"
          data-test="price-sheet-add-room"
        >
          Add room
        </Button>
      </Box>
    );
  }

  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            minWidth: 0,
            minHeight: 0,
            margin: 0,
            padding: 0,
          },
          html: {
            minWidth: 0,
            minHeight: 0,
            margin: 0,
            padding: 0,
          },
          '#main': {
            minWidth: 0,
            minHeight: 0,
          }
        }}
      />
      <div className={classes.actions}>
        <IconButton
          id="price-sheet-print-button"
          size="large"
          onClick={() => {
            notificationContext.setContext({ open: true, message: 'Generating printable version...' });

            const printWindow = open(priceSheetUrl);

            setTimeout(() => {
              printWindow.print();
              typeof onPrint === 'function' && onPrint();
            }, 4000);
          }}
        >
          <IconPrint fontSize="small" />
        </IconButton>
      </div>

      <Box
        className={classes.fullPageWrapper}
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        justifyContent="space-between"
      >
        <Box>
          <GuesthouseLogoFull className={classes.fullPageLogo} />

          <Typography
            className={classes.fullPageHeader}
            variant="h1"
          >
            Scan to shop the furnishings
          </Typography>
        </Box>

        <QRCode
          size={200}
          icon={true}
          text={`http://guesthouseshop.com/shop/homes/${homeData.slug}?&utm_source=in-home&utm_medium=qr`}
          className={classes.fullPageQRCode}
        />

        <Box>
          <Typography
            className={classes.fullPageSubhead}
            component="h2"
            variant="h4"
          >
            Scan the QR code to browse
            {' '}
            <br></br>
            {' '}
            all products in this home
          </Typography>

          <Typography
            className={classes.fullPageCopy}
            style={{ fontFamily: theme.gh_vars.mabryMono, color: theme.palette.common.black }}
          >
            When we stage a home, you can buy an item, a room, or even the entire interior. It&apos;s the easiest, most affordable way to shop products from trending brands and local makers.
          </Typography>
        </Box>
      </Box>

      {rooms && rooms.map((room, i) => {
        return (
          <Room
            key={i}
            roomId={room.docID}
            homeId={homeId}
            lazyLoadImages={lazyLoadImages}
          />
        );
      })}
    </>
  );
};

CompactPriceSheet.defaultProps = {
  lazyLoadImages: true,
};

export default CompactPriceSheet;
