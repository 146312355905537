import Sentry from '@integrations/Sentry';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';

import useStyles from './MoodboardFull.style';

type MoodboardFullProps = {
  className?: string;
  style?: React.CSSProperties;
  moodboard: guesthouse.Moodboard;
}

const MoodboardFull: React.FC<MoodboardFullProps> = (props) => {
  const { moodboard, className, style } = props;
  const [numPages, setNumPages] = useState(null);
  const { classes } = useStyles();
  const ref = useRef(null);
  const [width, setWidth] = useState();

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div
      ref={ref}
      className={className}
      style={style}
    >
      <Document
        className={classes.document}
        style={style}
        file={moodboard.download_url}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={Sentry.captureException}
      >
        {
          [...new Array(numPages).fill(0)].map((_, i) => {
            return (
              <Page
                key={i}
                pageNumber={i + 1}
                className={classes.page}
                width={width}
              />
            );
          })
        }
      </Document>
    </div>
  );
};

export default MoodboardFull;
