import 'stream-chat-react/dist/css/index.css';
import './messages.scss';

import { database } from '@app/firebase';
import ChannelListContainer from '@components/in-app-messaging/ChannelListContainer';
import ChannelListItem from '@components/in-app-messaging/ChannelListItem';
import ChatAvatars from '@components/in-app-messaging/ChatAvatars';
import CustomAttachment from '@components/in-app-messaging/CustomAttachment';
import CustomSystemMessage from '@components/in-app-messaging/CustomSystemMessage';
import Input from '@components/in-app-messaging/Input';
import Message from '@components/in-app-messaging/Message';
import MessageSearchItem from '@components/in-app-messaging/MessageSearchItem';
import UserMessageCard from '@components/in-app-messaging/UserMessageCard';
import AdminChannelListItem from '@components/in-app-messaging-admin/AdminChannelListItem';
import { RouterLink } from '@components/link-behavior/LinkBehavior';
import UserSearch from '@components/user-search/UserSearch';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import useDebounceEffect from '@hooks/useDebounceEffect';
import useRouter from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Protected from '@ui/components/protected/Protected';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import ArrowLeftIcon from '@ui/icons/imaterial/arrows/Arrow Left.svg';
import PlusCircleIcon from '@ui/icons/imaterial/base/Plus Circle.svg';
import SearchIcon from '@ui/icons/imaterial/base/Search.svg';
import XIcon from '@ui/icons/imaterial/base/X.svg';
import MessagePlusIcon from '@ui/icons/imaterial/communication/Message Plus.svg';
import IconHome from '@ui/icons/imaterial/home/Home.svg';
import theme from '@ui/theme';
import checkRoles from '@ui/utils/checkRoles';
import { isArrayEqual } from '@ui/utils/isArrayEqual';
import addStreamUserToChannel from '@utils/addStreamUsertoChannel';
import { getAdminViewFilters } from '@utils/getUnreadChannelsAdmin';
import newId from '@utils/newId';
import { homePath } from '@utils/paths';
import removeStreamUserFromChannel from '@utils/removeStreamUserFromChannel';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ChannelFilters, ChannelMembership, ChannelSort } from 'stream-chat';
import { Channel, ChannelList, MessageInput, MessageList, SimpleReactionsList, useChatContext, Window } from 'stream-chat-react';

import AdminViewStats from '../../components/in-app-messaging-admin/AdminViewStats';
import MessagesAdminSwitch from '../../components/in-app-messaging-admin/MessagesAdminSwitch';
import useStyles from './Messages.style';

interface ChannelParams {
  channelID: string;
}
const GHChannelList = ChannelList<GHStreamGenerics>;

const Messages: React.FC = () => {
  const { md, lg } = useBreakpoints();
  const [channelMembers, setChannelMembers] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [messageSearchQuery, setMessageSearchQuery] = useState<string>();
  const [searchResults, setSearchResults] = useState([]);
  const [openAddMember, setOpenAddMember] = useState(false);
  const [otherChatMembers, setOtherChatMembers] = useState<ChannelMembership[]>([]);
  const [triggerGetChannels, setTriggerGetChannels] = useState(null);
  
  const { channel, client, setActiveChannel } = useChatContext<GHStreamGenerics>();
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const router = useRouter<ChannelParams>();

  const userContext = useContext<UserContext>(UserContext);
  const { classes } = useStyles({ isAdminView: userContext.isAdminView });

  const filters: ChannelFilters<GHStreamGenerics> = useMemo(() => {
    if (userContext.isAdminView) {
      return getAdminViewFilters();
    } else {
      if (checkRoles(['admin'], userContext.roles)) {
        return {
          $or: [
            { type: 'home' },
            { members: { $in: [userContext?.user?.uid || userContext?.data?.docID], }, },
          ],
        };
      } else {
        return { members: { $in: [userContext?.user?.uid] } };
      }
    }
  }, [userContext?.roles, userContext?.user, userContext?.isAdminView, `${channel?.data}`]);

  const options = useMemo(() => {
    if (userContext.isAdminView) {
      return {
        state: true
      };
    }

    return {};
  }, [userContext?.roles, userContext?.user, userContext.isAdminView]);

  const sort: ChannelSort = [{ last_updated: -1 }];


  const handleOpenMobileChannelList = () => {
    setActiveChannel(null);
  };

  const addChatMember = async (user: guesthouse.User) => {
    const success = await addStreamUserToChannel(user, channel, notificationContext);

    if (success) {
      setChannelMembers(channelMembers => [...channelMembers, user]);
      setOpenAddMember(false);
    }
  };

  const handleRemoveChatMember = async (user: guesthouse.User) => {
    const success = await removeStreamUserFromChannel(user, channel);

    if (success) {
      setChannelMembers(channelMembers.filter(member => member.docID !== user.docID));
    }
  };

  useEffect(() => {
    const fetchMembers = async (channel) => {
      const { members } = await channel.queryMembers({});
      const users: guesthouse.User[] = [];

      if (members?.length) {
        for (const member of members) {
          const user = await getDocs(query(collection(database, 'users')
            ,where('docID', '==', member.user_id)));

          if (user.docs.length) {
            users.push(user.docs[0].data() as guesthouse.User);
          }
        }
      }
      if (!isArrayEqual(channelMembers, users)) {
        setChannelMembers(users);
      }
    };

    channel && fetchMembers(channel);
  }, [channel]);

  useEffect(() => {
    if (channel) {
      const members = Object.values(channel.state.members);
      const otherMembers = members.filter((member: ChannelMembership) => member?.user.id !== userContext.user?.uid);

      if (!isArrayEqual(otherChatMembers, otherMembers)) {
        setOtherChatMembers(otherMembers);
      }
    }
  }, [channel, channelMembers]);

  useDebounceEffect(() => {
    if (messageSearchQuery?.length) {
      const messageSearch = async () => {
        const { results } = await client.search(
          filters,
          messageSearchQuery,
          { limit: 50 }
        );

        setSearchResults(results);
      };

      messageSearch();
    } else {
      setSearchResults([]);
    }
  }, 200, [messageSearchQuery]);

  const renderChannels = useMemo(() => {
    if (userContext?.streamUser && !renderChannels) {
      return true;
    }

    return false;
  }, [userContext?.streamUser]);

  useEffect(() => {
    const getSetChannel = async () => {
      const { channelID } = router.params;
      const filter = {
        cid: { $eq: channelID }
      };
      let channel;

      try {
        const channels = await client.queryChannels(filter, {}, { limit: 1 });

        channel = channels[0];
      } catch (e) {
        Sentry.captureException(e);
      }
      if (channel) {
        setActiveChannel(channel);
      }
    };

    if (router.params?.channelID && client.user?.id) {
      getSetChannel();
    }
  }, [client.user?.id]);

  if (!client.user?.id) {
    return (
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress style={{ color: theme.palette.common.black }} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div
        className={classes.channelListContainer}
        style={{ display: md ? 'flex' : !md && channel ? 'none' : 'flex' }}
      >
        <div className={userContext.isAdminView ? classes.adminChannelListHeader : classes.channelListHeader}>
          {!openSearch ?
            <>
              {!userContext.isAdminView && (
                <Typography
                  style={{
                    fontSize: 24,
                    lineHeight: 1.25,
                    fontWeight: 500,
                    fontFamily: theme.gh_vars.circular
                  }}
                >
                  Messages
                </Typography>
              )}

              {userContext.isAdminView && (
                <Typography
                  style={{
                    fontSize: 24,
                    lineHeight: 1.25,
                    fontWeight: 500,
                    fontFamily: theme.gh_vars.circular
                  }}
                >
                  Administrator SLA View
                </Typography>
              )}

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {!userContext.isAdminView && (
                  <>
                    <Tooltip title="Search conversation history">
                      <IconButton
                        style={{ color: theme.palette.common.black }}
                        onClick={() => setOpenSearch(true)}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>
                    <Protected allowedRoles={['admin', 'designer', 'design_manager', 'customer_support']}>
                      <Tooltip title="Start new channel">
                        <IconButton
                          style={{ marginLeft: theme.spacing(2), stroke: theme.palette.common.black, color: theme.palette.common.white }}
                          onClick={async () => {
                            const existingChannel = await client.queryChannels({
                              type: 'messaging',
                              members: { $eq: [userContext.user.uid] },
                              member_count: 1,
                              new_message: true
                            }, {}, { limit: 1 });

                            if (existingChannel?.length) {
                              setActiveChannel(existingChannel[0]);
                            }
                            const channel = client.channel('messaging', newId(), {
                              members: [userContext.user.uid],
                              new_message: true,
                              last_sent_by_external_user: false,
                              resolved_gh_sla: false,
                            });

                            await channel.create();
                            setActiveChannel(channel);
                          }
                      }
                        >
                          <MessagePlusIcon />
                        </IconButton>
                      </Tooltip>
                    </Protected>
                  </>
                )}
                {md && (
                  <MessagesAdminSwitch/>
                )}
              </div>
            </>
            :
            <TextField
              autoFocus
              fullWidth
              variant="standard"
              placeholder="Search message history..."
              InputProps={{
                style: { height: 50, borderRadius: 10, backgroundColor: theme.palette.common.white },
                disableUnderline: true,
                startAdornment: (
                  <SearchIcon style={{ margin: theme.spacing(0, 2) }} />
                ),
                endAdornment: (
                  <IconButton
                    style={{ marginRight: theme.spacing(1) }}
                    onClick={() => {
                      setOpenSearch(false);
                      setMessageSearchQuery('');
                    }}
                  >
                    <XIcon />
                  </IconButton>
                )
              }}
              onChange={(e) => setMessageSearchQuery(e.target.value)}
            />
          }
        </div>

        {userContext.isAdminView && (
          <AdminViewStats/>
        )}

        {openSearch && messageSearchQuery?.length ?
          <div>
            {!!searchResults?.length &&
              <Typography
                style={{
                  padding: theme.spacing(0, 4.5, 1.5),
                  lineHeight: 1.25,
                  fontWeight: 400,
                  fontSize: 12,
                  fontFamily: theme.gh_vars.circular
                }}
              >
                Search results
              </Typography>
            }
            {searchResults.map(result => {
              return (
                <MessageSearchItem
                  key={result.message.id}
                  message={result.message}
                />
              );
            })}
          </div>
          :
          <>
            {userContext.isAdminView && (
              <Typography
                component="div"
                variant="h4"
                style={{
                  marginBottom: theme.spacing(2),
                  padding: theme.spacing(0, 4)
                }}
              >
                Need attention
              </Typography>
            )}
            <div className={classes.channelListContent}>
              <GHChannelList
                setActiveChannelOnMount={false}
                filters={filters}
                sort={sort}
                options={options}
                // because it's HOC, first parent's pass through to nothing,
                // second passes through to props, HOCProps are passed automatically
                List={ChannelListContainer()({
                  setTriggerGetChannels,
                  triggerGetChannels,
                })}
                Preview={userContext.isAdminView ? ((props) => (
                  <AdminChannelListItem
                    setTriggerGetChannels={setTriggerGetChannels}
                    {...props}
                  />
                )
                ) : ChannelListItem}
              />
            </div>
          </>
        }
      </div>

      <div
        className={classes.messagesContainer}
        style={{
          width: lg ? 'calc(67vw - 113px)' : md ? '67vw' : '100%',
          display: md ? 'flex' : !md && channel ? 'flex' : 'none'
        }}
      >
        {channel &&
          <>
            <div className={classes.messagesHeader}>

              {!md &&
                <IconButton
                  style={{ marginRight: theme.spacing(3), stroke: '#000' }}
                  onClick={handleOpenMobileChannelList}
                >
                  <ArrowLeftIcon />
                </IconButton>
              }

              {md &&
                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll', flex: 1 }}>
                  {!!channelMembers.length && channelMembers.map((user) => {
                    return (
                      <UserMessageCard
                        key={user.docID}
                        user={user}
                        handleRemoveChatMember={handleRemoveChatMember}
                      />
                    );
                  })}
                </div>
              }

              {openAddMember &&
                <ClickAwayListener onClickAway={() => setOpenAddMember(false)}>
                  <div className={classes.userSearchContainer}>
                    <UserSearch
                      autoFocus={true}
                      filterRoles={undefined}
                      showAvatar={true}
                      label={null}
                      variant="standard"
                      TextFieldInputStyle={{ height: 40 }}
                      onChange={(_e, user: guesthouse.User) => addChatMember(user)}
                    />
                  </div>
                </ClickAwayListener>
              }

              {!md && !openAddMember && !!otherChatMembers?.length &&
                <div className={classes.groupChatCard}>
                  <ChatAvatars channel={channel} />
                  <div style={{ marginLeft: theme.spacing(1) }}>
                    {otherChatMembers.length > 1 &&
                      <Typography style={{ fontSize: 12, fontWeight: 500 }}>
                        Group message
                      </Typography>
                    }
                    <Typography style={{ fontSize: 10, color: theme.palette.text.secondary }}>
                      {otherChatMembers.map((member, i) => {
                        if (otherChatMembers.length > 1) {
                          if (i === otherChatMembers.length - 1) {
                            return (
                              member.user.name.split(' ')[0]
                            );
                          } else if (i === otherChatMembers.length - 2) {
                            return (
                              member.user.name.split(' ')[0] + ' & '
                            );
                          }
                          return (
                            member.user.name.split(' ')[0] + ', '
                          );
                        } else {
                          return (
                            member.user.name
                          );
                        }
                      })
                      }
                    </Typography>
                  </div>
                </div>
              }

              <div style={{ display: 'flex', flexDirection: 'row', marginLeft: theme.spacing(3) }}>
                <Protected allowedRoles={['admin', 'designer', 'design_manager', 'customer_support']}>
                  <Tooltip title="Add users">
                    <IconButton
                      style={{ width: 40, height: 40, stroke: theme.palette.common.black, color: theme.palette.common.white }}
                      onClick={() => setOpenAddMember(true)}
                    >
                      <PlusCircleIcon />
                    </IconButton>
                  </Tooltip>
                </Protected>

                {channel?.data?.type === 'home' &&
                  <Link
                    component={RouterLink}
                    to={homePath({ docID: channel.id })}
                  >
                    <IconButton
                      style={{ width: 40, height: 40, color: theme.palette.common.black }}
                    >
                      <IconHome />
                    </IconButton>
                  </Link>
                }
              </div>

            </div>

            <div className={classes.messagesContent}>
              {Object.keys(client.activeChannels).length > 0 && (
                <Channel
                  channel={channel}
                  MessageSystem={CustomSystemMessage}
                  Attachment={CustomAttachment}
                  ReactionsList={SimpleReactionsList}
                >
                  <Window>
                    <MessageList Message={Message} />
                    <MessageInput
                      Input={Input}
                      grow={true}
                    />
                  </Window>
                </Channel>
              )}
            </div>
          </>
        }

      </div>
    </div>
  );
};

export default Messages;
