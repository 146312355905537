import 'stream-chat-react/dist/css/index.css';

import { database } from '@app/firebase';
import UserContext from '@context/UserContext';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import theme from '@ui/theme';
import lastMessageTime from '@utils/lastMessageTime';
import { collection, doc, getDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Channel, ChannelMembership, MessageResponse } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';

import useStyles from './ChannelListItem.style';
import ChatAvatars from './ChatAvatars';

type MessageSearchItemProps = {
  message: MessageResponse;
}

const MessageSearchItem = (props: MessageSearchItemProps) => {
  const { message } = props;
  const { unread, active } = props.message.channel;
  const { client, setActiveChannel } = useChatContext<GHStreamGenerics>();
  const userContext = useContext<UserContext>(UserContext);
  const { md } = useBreakpoints();
  const { classes } = useStyles();
  const [otherMembers, setOtherMembers] = useState<ChannelMembership[]>([]);
  const [fullChannel, setFullChannel] = useState<Channel<GHStreamGenerics>>();
  const [home, setHome] = useState<guesthouse.Home>();

  useEffect(() => {
    const getFullChannel = async () => {
      const tempChannel = await client.queryChannels({
        type: props.message.channel.type,
        id: props.message.channel.id
      }, {}, { limit: 1 });

      setFullChannel(tempChannel[0]);
    };

    getFullChannel();
  }, [props.message.channel]);

  useEffect(() => {
    async function getHome(docID) {
      const homeDoc = await getDoc(doc(collection(database, 'homes'), docID));

      if (homeDoc.exists()) {
        setHome(homeDoc.data() as guesthouse.Home);
      }
    }
    // @ts-ignore
    if (props.message.channel.type === 'home' && typeof props.message.channel.data?.home_id === 'string') {
      // @ts-ignore
      getHome(props.message.channel.data.home_id);
    }
  }, [props.message.channel.type]);

  const onSelectChannel = () => {
    if (setActiveChannel) {
      setActiveChannel(fullChannel);
    }
  };

  const latestMessageTime = useMemo(() => {
    if (message.created_at) {
      return lastMessageTime(message.updated_at as unknown as Date);
    }
  }, [props.message.channel.last_message_at]);

  useEffect(() => {
    if (fullChannel) {
      const members = Object.values(fullChannel?.state?.members);
      const otherMembers = members.filter((member: ChannelMembership) => member?.user.id !== userContext.user.uid);

      setOtherMembers(otherMembers);
    }
  }, [fullChannel?.state?.members]);

  if (!fullChannel) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: unread ? theme.spacing(0, 4, 0, 1.5) : theme.spacing(0, 4),
        marginBottom: theme.spacing(1)
      }}
    >
      {!!unread &&
        <span className={classes.unreadDot} />
      }
      <Button
        variant={md && active ? 'outlined' : 'text'}
        style={{
          width: '100%',
          backgroundColor: !unread ? '#EBEBEB' : theme.palette.common.white,
          padding: theme.spacing(.8),
          border: active ? '1px solid #080808' : '1px solid transparent'
        }}
        onClick={onSelectChannel}
      >
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {otherMembers &&
              <ChatAvatars
                channel={fullChannel}
                home={home}
              />
            }
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', marginLeft: theme.spacing(1) }}>
              <Typography
                className={classes.nameAndText}
                style={{ color: !unread ? '#666' : '#000', lineHeight: 1.25 }}
              >
                {props.message.channel.type !== 'home' && otherMembers.map((member, i) => {
                  if (otherMembers.length > 1) {
                    if (i === otherMembers.length - 1) {
                      return (
                        member.user.name.split(' ')[0]
                      );
                    } else if (i === otherMembers.length - 2) {
                      return (
                        member.user.name.split(' ')[0] + ' & '
                      );
                    }
                    return (
                      member.user.name.split(' ')[0] + ', '
                    );
                  } else {
                    return (
                      member.user.name
                    );
                  }
                })
                }
                {fullChannel?.type === 'home' && fullChannel?.data.name}
              </Typography>
              <Typography
                className={classes.nameAndText}
                style={{ color: !unread ? '#666' : '#000', lineHeight: 1.5, height: 18, }}
              >
                {fullChannel?.lastMessage().text}
              </Typography>
            </div>
          </div>

          <Typography
            style={{
              color: !unread ? '#666' : '#000',
              lineHeight: 1.25,
              fontWeight: 400,
              width: 70,
              fontSize: 12,
              fontFamily: theme.gh_vars.circular,
            }}
          >
            {latestMessageTime}
          </Typography>
        </div>
      </Button>
    </div>
  );
};

export default MessageSearchItem;
