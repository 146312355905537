import LocationSearch from '@components/location-search/LocationSearch';
import UserSearch from '@components/user-search/UserSearch';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import ProductOfferSchema, { initialValues as _initialValues } from '@schema/ProductOfferSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import FormErrors, { makeErrors, TooltipErrors } from '@ui/components/form-errors/FormErrors';
import Protected from '@ui/components/protected/Protected';
import { useStyles } from '@ui/utils/makeStyles';
import { Field, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import React from 'react';

import TextFieldPrice from '../components/textfield-price/TextFieldPrice';

const ProductOfferForm = ({ onSubmit, initialValues = _initialValues, submitText = 'Continue' }) => {
  const { theme } = useStyles();

  return (
    <Formik
      validationSchema={ProductOfferSchema}
      initialValues={initialValues}
      enableReinitialize={false}
      onSubmit={onSubmit}
    >
      {({
        errors,
        values,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => {

        return (
          <form onSubmit={handleSubmit}>
            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              <UserSearch
                autoFocus={true}
                defaultValue={values.soldBy}
                useCompany={true}
                filterRoles={['maker']}
                label="Sold By"
                style={{
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(1),
                }}
                onChange={(_, value) => {
                  setFieldValue('soldBy', value, true);
                  setFieldTouched('soldBy', true);
                }}
              />
            </Protected>
            <Field
              fullWidth
              data-test="price"
              name="price"
              label="Price"
              type="text"
              component={TextFieldPrice}
              margin="normal"
              variant="outlined"
              customInput={TextField}
              defaultValue={values.price}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                )
              }}
              onValueChange={(values) => {
                const { value } = values;

                setFieldValue('price', Number(value));
              }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={values.sale}
                  name="sale"
                  color="primary"
                  onChange={(_, value) => {
                    setFieldValue('sale', value);
                    setFieldTouched('sale');
                  }}
                />
              }
              label="Sale"
            />
            {values.sale ?
              <Field
                fullWidth
                name="salePrice"
                label="Sale Price"
                type="text"
                component={TextFieldPrice}
                margin="normal"
                variant="outlined"
                customInput={TextField}
                defaultValue={values.salePrice}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  )
                }}
                onValueChange={(values) => {
                  const { value } = values;

                  setFieldValue('salePrice', Number(value));
                }}
              /> : null}

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.type && Boolean(errors.type)}
            >
              <InputLabel id="type-label">
                Type
              </InputLabel>
              <Select
                data-test="type"
                labelId="type-label"
                id="type"
                value={values.type}
                label="Type"
                onChange={(e) => {
                  setFieldTouched('type', true);
                  setFieldValue('type', e.target.value, true);
                }}
              >
                <MenuItem
                  key="new"
                  value="new"
                >
                  New
                </MenuItem>

                <MenuItem
                  key="used"
                  value="used"
                >
                  Used
                </MenuItem>

                <MenuItem
                  key="damaged"
                  value="damaged"
                >
                  Damaged
                </MenuItem>

                <MenuItem
                  key="refurbished"
                  value="refurbished"
                >
                  Refurbished
                </MenuItem>
              </Select>
              {touched.condition && Boolean(errors.condition) && (
                <FormHelperText error>
                  {errors.type}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              margin="normal"
              error={touched.condition && Boolean(errors.condition)}
            >
              <InputLabel id="condition-label">
                Condition
              </InputLabel>
              <Select
                data-test="condition"
                labelId="condition-label"
                id="condition"
                value={values.condition}
                label="Condition"
                onChange={(e) => {
                  setFieldTouched('condition', true);
                  setFieldValue('condition', e.target.value, true);
                }}
              >
                <MenuItem
                  key="excellent"
                  value="excellent"
                >
                  Excellent
                </MenuItem>

                <MenuItem
                  key="good"
                  value="good"
                >
                  Good
                </MenuItem>

                <MenuItem
                  key="fair"
                  value="fair"
                >
                  Fair
                </MenuItem>

                <MenuItem
                  key="poor"
                  value="poor"
                >
                  Poor
                </MenuItem>
              </Select>
              {touched.condition && Boolean(errors.condition) && (
                <FormHelperText error>
                  {errors.condition}
                </FormHelperText>
              )}
            </FormControl>

            <Field
              fullWidth
              multiline
              data-test="notes"
              name="notes"
              label="Notes"
              type="text"
              component={TextField}
              margin="normal"
              variant="outlined"
              rows={3}
            />

            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="pickupOnly"
              Label={{ label: 'Pickup Only' }}
            />

            {values.pickupOnly && (
              <LocationSearch
                label="Pickup Location"
                defaultValue={initialValues.pickupLocation?.address}
                style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}
                onLocationChange={(location) => {
                  setFieldValue('pickupLocation', location, true);
                }}
              />
            )}

            {!values.pickupOnly && (
              <Field
                fullWidth
                data-test="shippingPricePerProduct"
                name="shippingPricePerProduct"
                label="Shipping Price (Per Product)"
                type="text"
                component={TextFieldPrice}
                margin="normal"
                variant="outlined"
                customInput={TextField}
                defaultValue={values.shippingPricePerProduct}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="This price will be applied once for each item in the cart"
                      >
                        <HelpOutlineIcon style={{ color: 'rgba(0,0,0,0.54)', height: 16, width: 16 }} />
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
                onValueChange={(values) => {
                  const { value } = values;

                  setFieldValue('shippingPricePerProduct', Number(value));
                }}
              />
            )}

            {!values.pickupOnly && (
              <Field
                fullWidth
                data-test="shippingPricePerOrder"
                name="shippingPricePerOrder"
                label="Shipping Price (Per Order)"
                type="text"
                component={TextFieldPrice}
                margin="normal"
                variant="outlined"
                customInput={TextField}
                defaultValue={values.shippingPricePerOrder}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="This price will be applied one time per order that includes any amount of this product"
                      >
                        <HelpOutlineIcon style={{ color: 'rgba(0,0,0,0.54)', height: 16, width: 16 }} />
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
                onValueChange={(values) => {
                  const { value } = values;

                  setFieldValue('shippingPricePerOrder', Number(value));
                }}
              />
            )}

            <FormErrors
              errors={errors}
              touched={touched}
            />

            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                data-test="product-offer-form-submit"
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors).length)}
                aria-label={submitText}
                tooltip={makeErrors(errors, touched)?.length && (
                  <TooltipErrors
                    errors={errors}
                    touched={touched}
                  />
                )}
              >
                {submitText}
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default React.memo(ProductOfferForm);
