import Sentry from '@integrations/Sentry';
import toJsDate from '@ui/utils/toJsDate';

export default function sortHomesByDate(a: guesthouse.HomeLite, b: guesthouse.HomeLite, dateField: string, direction: 'asc' | 'desc' ) {

  if (a[dateField] && b[dateField] && direction === 'asc') {
    try {
      return toJsDate(a[dateField]).valueOf() - toJsDate(b[dateField]).valueOf();
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  if (a[dateField] && b[dateField] && direction === 'desc') {
    try {
      return toJsDate(b[dateField]).valueOf() - toJsDate(a[dateField]).valueOf();
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  return 0;
}
