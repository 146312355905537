import React from 'react';
import { DropEvent, FileRejection } from 'react-dropzone';

type Props = {
    onDrop?<T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) : void;
    buttonText?: string;
    multiple?: boolean;
    style?: React.CSSProperties;
    accept?: string | string[];
    boxShadow?: number;
    children?: React.ReactNode;
}

export const defaultProps: Partial<Props> = {
  buttonText: 'Upload Photo',
  multiple: false,
  onDrop: console.log,
  accept: 'image/jpeg, image/png',
  boxShadow: 0,
};

export default Props;
