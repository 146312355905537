import { AsyncComponentProps } from '@components/async/asyncRender';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';

import PricingTierForm from './PricingTierForm';

interface PricingTierDialogArgs extends AsyncComponentProps<guesthouse.DbPricingTier>  {
  pricingTier?: guesthouse.DbPricingTier
  resolve: (pricingTier?: guesthouse.DbPricingTier) => void;
  reject: (e: Error) => void;
}

const PricingTierDialog: React.FC<PricingTierDialogArgs> = ({ pricingTier, resolve, reject }) => {
  const [open, setOpen] = useState(true);

  const action = pricingTier ? 'Edit' : 'Create';

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {action}
          {' '}
          Pricing Tier
        </DialogTitle>
        <DialogContent>
          <PricingTierForm
            pricingTier={pricingTier}
            resolve={() => {
              handleClose();
              resolve(pricingTier);
            }}
            reject={(e) => {
              handleClose();
              reject(e);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PricingTierDialog;
