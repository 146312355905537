import makeStyles from '@ui/utils/makeStyles';
import transparentize from 'polished/lib/color/transparentize';

export default makeStyles()((theme) => ({
  rowHasRequests: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  historyBox: {
    margin: theme.spacing(0, 0, 2, 0),
    padding: theme.spacing(2),
    background: theme.palette.tertiary.light,
    borderRadius: theme.shape.borderRadius,
  },
  historyRow: {
    '& td': {
      borderBottomColor: transparentize(.5, theme.palette.tertiary.main),
    },
    '& th': {
      borderBottomColor: transparentize(.5, theme.palette.tertiary.main),
    }
  },
}));
